import React from 'react';
import ReactDOM from 'react-dom';
import './assets/main.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { msalInstance, loginRequest, tokenRequest } from './auth/authProvider';

import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import PageSpinner from './components/global/pageSpinner';
import LoginError from './components/global/LoginError';
import { AuthContextProvider } from "./context/authContext";

const {REACT_APP_API_URL} = process.env;

const httpLink = createHttpLink({
  uri: REACT_APP_API_URL
});

export const authFlow = (success, fail) => {
  const accounts = msalInstance.getAllAccounts();
  return msalInstance.acquireTokenSilent({
    ...tokenRequest,
    account: accounts[0],
  })
    .then(tokenResponse => {
      localStorage.setItem('authToken', tokenResponse.accessToken);
      success({
        headers: {
          authorization: tokenResponse ? `Bearer ${tokenResponse.accessToken}` : "",
        }
      });
    })
    .catch(error => {
      // fallback to interaction when silent call fails
      console.log("acquireTokenSilent>>>>", error)
      return msalInstance.acquireTokenRedirect({
        ...tokenRequest,
        account: accounts[0],
      }).then(tokenResponse => {
        console.log('accessToken acquired at: ' + new Date().toString());
        console.log('tokenResponse:', tokenResponse);
        localStorage.setItem('authToken', tokenResponse.accessToken);
        success({
          headers: {
            authorization: tokenResponse ? `Bearer ${tokenResponse.accessToken}` : "",
          }
        });
      }).catch(error => {
        // fallback to acquire token popup when acquire token redirect call fails
        console.log("acquireTokenRedirect>>>>", error)
        return msalInstance.acquireTokenPopup({
          ...tokenRequest,
          account: accounts[0],
        }).then(tokenResponse => {
          console.log('accessToken acquired at: ' + new Date().toString());
          console.log('tokenResponse:', tokenResponse);
          localStorage.setItem('authToken', tokenResponse.accessToken);
          success({
            headers: {
              authorization: tokenResponse ? `Bearer ${tokenResponse.accessToken}` : "",
            }
          });
        }).catch(error => {
          fail(error);
        });
      });
    })
}

const errorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
  console.log('networkError', networkError);
  console.log('graphQLError', graphQLErrors);
  if (networkError && (networkError.statusCode === 400)) {
    console.log('Removing localStorage token');
    localStorage.removeItem('authToken');
    if (graphQLErrors && graphQLErrors[0].message === 'Context creation failed: Not Authorized') {
       msalInstance.loginRedirect(loginRequest);
       //return new Promise((success, fail) => authFlow(success, fail))
    }
  }
})

const asyncAuthLink = setContext(
  request => {
    if (localStorage.getItem('authToken')) {
      console.log('token exists already');
      return {
        headers: {
          authorization: `Bearer ${localStorage.getItem('authToken')}`,
        }
      }
    }
    return new Promise((success, fail) => authFlow(success, fail));
  }
)

const link = ApolloLink.from([
  errorLink,
  asyncAuthLink,
  httpLink,
]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          notificationSearch: {
            merge(existing, incoming){
              return incoming
            }
          }
        }
      }
    }
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        authenticationRequest={loginRequest}
        interactionType={InteractionType.Redirect}
        errorComponent={LoginError}
        loadingComponent={PageSpinner}>
        <AuthContextProvider>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </AuthContextProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
