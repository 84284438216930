/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import {useHistory, useParams} from 'react-router-dom';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {gql, useMutation, useLazyQuery} from '@apollo/client';
import {omit} from 'lodash';
import {AuthContext} from "../../context/authContext";
import {countries} from "../../constants/countryList";
import {createUserTypeAndRoleObject} from "../../utils";
import {
    ABS_LOCATION_TYPE_ID,
    DUTYHOLDER_LOCATION_TYPE_ID,
    TEMP_DUTYHOLDER_ID
} from "../../constants";

const Validation = Yup.object().shape({
    location_name: Yup.string().label("location_name").required("Location Name is required"),
    location_address_line_1: Yup.string().label("location_address_line_1:").required("Address Line 1 is required"),
    location_address_city: Yup.string().label("location_address__city").required("City is required"),
    location_address_post_zip_code: Yup.string().label("location_address_post_zip_code").required("Zip/Post Code is required"),
    location_address_country: Yup.string().label("location_address_country").required("Country is required"),
});

const CREATE_LOCATION = gql`
    mutation AddLocation($loc: LocationInput!, $dhId: String) {
        createLocation(loc: $loc, dhId: $dhId) {
            _id
            location_name
            location_address_line_1
            location_address_line_2
            location_address_city
            location_address_country
            location_address_post_zip_code
            location_notes
            success
            status_code
            message
        }
    }
`;

const UPDATE_LOCATION = gql`
    mutation UpdateLocation($id: String!, $userId: String, $loc: LocationInput!) {
        updateLocation(_id: $id, userId:$userId, loc: $loc) {
            location_name
            location_address_line_1
            location_address_line_2
            location_address_city
            location_address_country
            location_address_post_zip_code
            location_notes
            success
            status_code
            message
        }
    }
`;

const LOCATION_QUERY = gql`
query Location($locationId: ID!){
    location(_id: $locationId) {
        location_name
        location_address_line_1
        location_address_line_2
        location_address_city
        location_address_country
        location_address_post_zip_code
        location_notes
    }
}
`;

const AddEditLocation = () => {
    const authContext = useContext(AuthContext);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
    const history = useHistory();

    const [countryList, setCountryList] = useState([]);

    const { featureName = "", featureId = "", formMode, locationId = "" } = useParams();

    const [formInitValues, setFormInitValues] = useState({
        location_name: '',
        location_address_line_1: '',
        location_address_line_2: '',
        location_address_city: '',
        location_address_country: '',
        location_address_post_zip_code: '',
        location_notes: '',
    });

    const [location, {data: locationData}] = useLazyQuery(LOCATION_QUERY);
    const [createLocation, {data: createLocationData}] = useMutation(CREATE_LOCATION);
    const [updateLocation, {data: updateLocationData}] = useMutation(UPDATE_LOCATION);

    useEffect(() => {
    if (authContext && authContext.user) {
        let result = createUserTypeAndRoleObject(authContext);
        setUserTypeAndRoleObject(result);
    }
    }, [authContext]);

    useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
    }, [userTypeAndRoleObject]);
        
    useEffect(() => {
        if (featureName !== "" && featureId !== "" && locationId !== "" && formMode === "edit") {
            location({ variables: { locationId: locationId }, errorPolicy: 'all' })
        }
        setCountryList(countries);
    }, []);

    useEffect(() => {
        if (locationData) {
            setFormInitValues(locationData.location)
        }
    }, [locationData]);

    useEffect(() => {
        if (createLocationData && createLocationData.createLocation) {
            const {success, message} = createLocationData.createLocation;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push(getBackLink());
            }      
        }
    }, [createLocationData]);

    useEffect(() => {
        if (updateLocationData) {
            const {success, message} = updateLocationData.updateLocation;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push(getBackLink());
            }     
        }
    }, [updateLocationData]);

    const getBackLink = () => {
        if (featureName !== "" && featureId !== "") {
            let backLink = `/${featureName}/${featureId}/locations`
            return backLink;
        }
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Location</h1>
            </div>

            <Formik
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={Validation}
                onSubmit={(values, actions) => {
                    const { _id: userId = "" } = authContext.user ? authContext.user : {};

                    if (formMode === 'add') {
                        const tempValues = { ...values };
                        tempValues.location_added_id = userId;

                        if (featureName === "abs" && featureId === TEMP_DUTYHOLDER_ID) {
                            tempValues.location_type_id = ABS_LOCATION_TYPE_ID
                        }

                        if (featureName === "dutyholder") {
                            tempValues.location_type_id = DUTYHOLDER_LOCATION_TYPE_ID
                        }

                        tempValues.location_added_id = userId;

                        let dhParam = (featureName === "abs" || featureName === "dutyholder")
                            && featureId !== "" && featureId !== TEMP_DUTYHOLDER_ID
                            ? { dhId: featureId } : {};

                        createLocation({
                            variables: {
                                loc: tempValues,
                                ...dhParam
                            }
                        })
                    }

                    if (formMode === 'edit') {
                        updateLocation({ variables: { id: locationId, userId: userId, loc: values } })
                    }
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                    {/*
                    <div className="my-4">
                        <Link to={`/${featureName}/${featureId}/locations`} 
                        className="button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>
                    </div>
                    */}
                        <div className="mt-6">
                            <label htmlFor="location_name" className="block mb-1 text-blue-900 font-semibold">Location
                                Name*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="location_name"
                                id="location_name"
                                placeholder="Location Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.location_name}
                            />
                            <FormError touched={touched.location_name} message={errors.location_name}/>
                        </div>

                        <div>
                            <label htmlFor="location_address_line_1"
                                   className="block mb-1 text-blue-900 font-semibold">Address Line 1*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="location_address_line_1"
                                id="location_address_line_1"
                                placeholder="Address Line 1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.location_address_line_1}
                            />
                            <FormError touched={touched.location_address_line_1}
                                       message={errors.location_address_line_1}/>
                        </div>

                        <div className="mb-5">
                            <label htmlFor="location_address_line_2" className="block mb-1 text-blue-900 font-semibold">Address
                                Line 2</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="location_address_line_2"
                                id="location_address_line_2"
                                placeholder="Address Line 2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.location_address_line_2}
                            />
                        </div>

                        <div>
                            <label htmlFor="location_address_city"
                                   className="block mb-1 text-blue-900 font-semibold">City*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="location_address_city"
                                id="location_address_city"
                                placeholder="City"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.location_address_city}
                            />
                            <FormError touched={touched.location_address_city}
                                       message={errors.location_address_city}/>
                        </div>

                        <div>
                            <label htmlFor="location_address_post_zip_code"
                                   className="block mb-1 text-blue-900 font-semibold">Zip/Post Code*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="location_address_post_zip_code"
                                id="location_address_post_zip_code"
                                placeholder="Zip/Post Code"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.location_address_post_zip_code}
                            />
                            <FormError touched={touched.location_address_post_zip_code}
                                       message={errors.location_address_post_zip_code}/>
                        </div>

                        <div>
                            <label htmlFor="location_address_country"
                                   className="block mb-1 text-blue-900 font-semibold">Country*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="location_address_country"
                                id="location_address_country"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.location_address_country}
                            >
                                <option value = {""}>Select Country</option>
                                {countryList.map(country =>
                                    <option value={country.name} key={country.id}>{country.name}</option>
                                )}
                            </select>
                            <FormError touched={touched.location_address_country}
                                       message={errors.location_address_country}/>
                        </div>

                        <div className="mb-4">
                            <label htmlFor="location_notes"
                                   className="block mb-1 text-blue-900 font-semibold">Notes</label>
                            <textarea
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="location_notes"
                                id="location_notes"
                                placeholder="Notes"
                                onChange={handleChange}
                                defaultValue={values.location_notes}
                            />
                        </div>

                        <button type="submit" className="mt-3 button-red capitalize">{formMode} Location</button>

                    </form>
                )}
            </Formik>

        </div>
    )

}

export default AddEditLocation;
