/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
import ReactDiffViewer from 'react-diff-viewer';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageSpinner from "../global/pageSpinner";
import {omit} from "lodash";
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";

const COMPETENCY_HISTORY_DIFF_QUERY = gql`
query CompetencyHistoryDiff($compHisId:String, $parentId: String){
    competency_history_diff(_id:$compHisId, parentId: $parentId){
        _id
        comp_code
        comp_title
        comp_notes
        history_parent_record_id
        history_modifying_user_id {
            _id
            user_fname
            user_lname
            user_email
            user_job_title
            user_office_tel
            user_mobile
            user_profile_image
        }
        history_modified_date
        history_modified_date_timestamp
    }
}`;

function CompetencyHistoryDiff(props) {
    const authContext = useContext(AuthContext);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
    const history = useHistory();
    
    const [compHistDiff, setCompHistDiff] = useState([]);
    const [user, setUser] = useState({});
    const { id="", parentId = "" } = useParams();

    const [competency_history_diff, {loading, error, data:compHistDiffData}] = useLazyQuery(COMPETENCY_HISTORY_DIFF_QUERY);

    useEffect(() => {
    if (authContext && authContext.user){
        let result = createUserTypeAndRoleObject(authContext);
        setUserTypeAndRoleObject(result);
    }
    }, [authContext]);

    useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
    }, [userTypeAndRoleObject]);
    

    useEffect(() => {
        if(id !=="" && parentId !==""){
            competency_history_diff({variables: {compHisId:id, parentId:parentId}, errorPolicy: 'all'});
        }     
    },[]);

    useEffect(() => {
        if(compHistDiffData && compHistDiffData.competency_history_diff){       
           setCompHistDiff(compHistDiffData.competency_history_diff);
           let user = compHistDiffData.competency_history_diff.find(his => his.history_modifying_user_id !== null);
           if(user){
            setUser(user.history_modifying_user_id);
           }     
        }
    }, [compHistDiffData]);


    if (loading) {
        return (
          <PageSpinner/>
        )
    }
    
    if (error) {
        return (
          <span>Something went wrong retrieving the diff data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="mb-6 pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Competency History Change</h1>
            </div>
            <Link to="/user-competency/competencies/histories" className="mr-2 button-red"><FontAwesomeIcon
                icon={['fas', 'angle-left']} /> Back</Link>

            <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Modifying User</h3>

            <img src={user.user_profile_image} alt={`${user.user_fname?user.user_fname:''}  ${user.user_lname?user.user_lname:''}`}
                className="mt-6 rounded-full w-24 max-w-full h-auto align-middle" />

            <div className="w-full lg:w-1/2">

                <div className="my-6 pb-6 border-b-2 border-gray-200">
                    <h2 className="font-bold text-2xl text-blue-900">{user.user_fname} {user.user_lname}</h2>
                    <h3 className="mb-3 font-bold text-red-900">{user.user_job_title}</h3>

                    <p>Email: {user.user_email}</p>
                    <p>Tel: {user.user_office_tel}</p>
                    <p>Mobile: {user.user_mobile}</p>
                </div>
            </div>

            <div className="mt-6">
                <ReactDiffViewer
                    oldValue={JSON.stringify(omit(compHistDiff[1], ['__typename', 'history_modifying_user_id']), null, 2)}
                    newValue={JSON.stringify(omit(compHistDiff[0], ['__typename', 'history_modifying_user_id']), null, 2)}
                    splitView={true}
                />
            </div>

        </div>
    );
}

export default CompetencyHistoryDiff;
