/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext} from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';
import {AuthContext} from "../../context/authContext";
import {parseJSONSafely, createUserTypeAndRoleObject} from "../../utils";

const {REACT_APP_FILE_MANAGER_API} = process.env;

const USER_COMPETENCY_QUERY = gql`
query UserCompetency($userCompId:ID!){
    userCompetency(_id: $userCompId){
      _id
      user_id{
        _id
        user_fname
        user_lname
        user_location
      }
      user_comp_evidence
      approval_codes
      user_comp_catId{ 
        _id
        comp_cat_code
        comp_cat_title
      }
      user_comp_compId{ 
        _id
        comp_code
        comp_title
      }
      user_comp_level{
        _id
        sysvar_title 
      }
      user_comp_expiry
      user_comp_approverId{ 
        _id
        user_fname
        user_lname
      }
      user_comp_status
      user_comp_date_gained
      user_comp_attachment
      user_comp_attmnts_dest_path
    }
}`;

const LOCATION_BY_ID_QUERY = gql`
query Location($locationId:ID!){
    location(_id: $locationId){
      location_name
      location_address_line_1
      location_address_line_2
      location_address_city
      location_address_country
      location_address_post_zip_code
      location_notes
      location_added_date
      location_added_id
      location_archived
      location_status
    }
}`;

const ViewUserCompetency = () => {
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const [userCompAtachments, setUserCompAttachments] = useState([]);
  const [userComp, setUserCompetency] = useState({});
  const [userLocation, setUserLocation] = useState({});
  
  const { userCompId = "" } = useParams();

  const authContext = useContext(AuthContext);
  const history = useHistory();

  const [userCompetency, { data: userCompetencyData }] = useLazyQuery(USER_COMPETENCY_QUERY);
  const [location, { data: locationData }] = useLazyQuery(LOCATION_BY_ID_QUERY);

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
  }, [userTypeAndRoleObject]);

  useEffect(() => {
    userCompetency({ variables: { userCompId }, errorPolicy: 'all' });
  }, [])

  useEffect(() => {
    if (userCompetencyData && userCompetencyData.userCompetency) {
      let userCompList = userCompetencyData.userCompetency;
      let attachment = userCompList.user_comp_attachment && userCompList.user_comp_attachment !== "" ? userCompList.user_comp_attachment : "[]";
      let attachmentList = parseJSONSafely(attachment);
      let formatedUserComp = formatUserCompetencyData(userCompList);
      setUserCompetency(formatedUserComp);
      setUserCompAttachments(attachmentList);
      const { user_location_id: locId } = formatedUserComp;

      if (locId && locId !== "") {
        location({ variables: {locationId: locId }, errorPolicy: 'all' });
      }

    }
  }, [userCompetencyData])

  useEffect(() => {
    if (locationData && locationData.location) {
      setUserLocation(locationData.location);
    }
  }, [locationData])


  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 100, 40, 24],
    header: function (currentPage, pageCount) {
      var headerObj;
      if (currentPage % 2) {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['75%', '25%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: userComp.user_fullname, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: "Competency Details", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      } else {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['25%', '75%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: userComp.user_fullname, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: "Competency Details", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      }

      return headerObj;
    },
    footer: function (currentPage, pageCount) {

      var footerObj;

      if (currentPage % 2) {
        footerObj = {
          table: {
            widths: [30, '*', 100, 28],
            body: [
              [
                { text: ' ', border: [false, false, false, false] },
                { text: 'COMPETENCY DETAILS', style: 'footerText', border: [false, false, false, false] },
                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                { text: ' ', border: [true, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      } else {
        footerObj = {
          table: {
            widths: [30, 100, '*', 28],
            body: [
              [
                { text: ' ', border: [false, false, true, false] },
                { text: currentPage.toString(), border: [true, false, false, false] },
                { text: `Copyright © by ABS. All Rights Reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                { text: ' ', border: [false, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      }

      return footerObj;

    },
    info: {
      title: userComp.user_fullname + 'Competency Details',
    },
    content: [

      { text: userComp.user_fullname, style: 'title' },
      { text: "Location:", style: 'redTitle', bold: true },
      { text: (userLocation.location_name ? 
               userLocation.location_address_line_1 + '\n' +
               userLocation.location_address_line_2 + '\n' +
               userLocation.location_address_city + '\n' +
               userLocation.location_address_post_zip_code + '\n' +
               userLocation.location_address_country + '.'  :"Location Not Available."), 
        style: '', 
        bold: false 
      },
      {
        table: {
          widths: ['30%', '70%'],
          headerRows: 0,
          body: [
            [{ text: 'Competency', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, userComp.competency_title],
            [{ text: 'Category', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, userComp.competency_cat_title],
            [{ text: 'Level', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, userComp.competency_level],
            [{ text: 'Date Gained', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, userComp.date_gained],
            [{ text: 'Expiry Date', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, userComp.expiry_date],
           
            [{ text: 'Evidence', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{ text: userComp.evidence, alignment: 'left', colSpan: 2 }, {}],
          
            [{ text: 'Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, userComp.expiry_status],
            [{ text: 'Approval Code', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, userComp.competency_code],
            [{ text: 'Approval By', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, userComp.approver_fullname],

            [{ text: 'Attachments', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{
              text: [
                {
                  text: userCompAtachments.length ? userCompAtachments.map(att => {
                    return (
                      "File Name: \n" +
                      att.name + '\n' +
                      "File Description: \n" +
                      att.description + '\n \n'
                    )
                  }) : "No Attachments to Display"
                },
              ], colSpan: 2
            }, {}],
          ]
        },
        style: 'standardTable',
      },
    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      title: {
        marginTop: 10,
        font: 'Montserrat',
        fontSize: 14,
        bold: true,
        color: '#002a4e'
      },
      redTitle: {
        marginBottom: 10,
        font: 'Montserrat',
        color: '#b40c1e'
      },
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1.2
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }

  const pdfTab = () => {
    pdfMake.createPdf(docDefinition).open();
  }   

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">User Competency Detail</h1>
      </div>

      <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>

      <span className="mr-2 button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      <div className="w-full lg:w-1/2">

        <div className="my-6">
          <h2 className="font-bold text-2xl text-blue-900">{userComp.user_fullname}</h2>
          <h3 className="font-bold text-red-900">{"Location:"}</h3>
          {userLocation.location_name ?
            <div>
              <p className="font-bold text-gray-900">{userLocation.location_name}</p>
              <p className="font-bold text-gray-900">{userLocation.location_address_line_1}</p>
              <p className="font-bold text-gray-900">{userLocation.location_address_line_2}</p>
              <p className="font-bold text-gray-900">{userLocation.location_address_city}</p>
              <p className="font-bold text-gray-900">{userLocation.location_address_post_zip_code}</p>
              <p className="font-bold text-gray-900">{userLocation.location_address_country}.</p>
            </div>
            : <p className="pt-2">{"Location Not Available."}</p>
          }
        </div>

        <table className="w-full mb-5 table-auto">
          <tbody>
            <tr>
              <th className="p-3 text-left bg-white border">Competency</th>
              <td className="p-3 border">{userComp.competency_title}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Category</th>
              <td className="p-3 border">{userComp.competency_cat_title}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Level</th>
              <td className="p-3 border">{userComp.competency_level}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Date Gained</th>
              <td className="p-3 border">{userComp.date_gained}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Expiry Date</th>
              <td className="p-3 border">{userComp.expiry_date}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border" colSpan="2">Evidence</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">{userComp.evidence}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Status</th>
              <td className="p-3 border">{userComp.expiry_status}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Approval Code</th>
              <td className="p-3 border">{userComp.competency_code}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Approved By</th>
              <td className="p-3 border">{userComp.approver_fullname}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border" colSpan="2">Attachments</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">{createFileLinks(userCompAtachments, userComp)}</td>
            </tr>
          </tbody>
        </table>

        <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>

        <span className="mr-2 button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      </div>

    </div>
  )
}

function formatUserCompetencyData(userCompetency) {
  let newUserCompeteny = {};
  if (userCompetency) {
    const { user_fname: userFname = "", user_lname: userLname = "", user_location: userLocation = "" } = userCompetency.user_id ? userCompetency.user_id : {};
    const {  comp_cat_code = "Not Available", comp_cat_title = "Not Available" } = userCompetency.user_comp_catId ? userCompetency.user_comp_catId : {};
    const { comp_title = "Not Available", comp_code = "Not Available" } = userCompetency.user_comp_compId ? userCompetency.user_comp_compId : {};
    const { sysvar_title: comp_level = "Not Available" } = userCompetency.user_comp_level ? userCompetency.user_comp_level : {};
    const { user_fname: approverFname = "", user_lname: approverLname = "" } = userCompetency.user_comp_approverId ? userCompetency.user_comp_approverId : {};
    let expiryDate = new Date(userCompetency.user_comp_expiry);
    let dateGained = new Date(userCompetency.user_comp_date_gained);

    let options = { year: 'numeric', month: 'long', day: 'numeric' };

    let expiryDateString = expiryDate.toLocaleDateString('en-GB', options);
    let dateGainedString = dateGained.toLocaleDateString('en-GB', options);

    let expiryStatus = getExpirationStatus(userCompetency.user_comp_expiry);

    let newUserComp = {
      id: userCompetency._id,
      user_fullname: (userFname + " " + userLname),
      user_location_id: userLocation,
      competency_cat_code: comp_cat_code,
      competency_cat_title: comp_cat_title,
      competency_code: comp_code,
      competency_title: comp_title,
      competency_level: comp_level,
      approval_code: userCompetency.approval_codes ? userCompetency.approval_codes : 'Not Available',
      evidence: userCompetency.user_comp_evidence ? userCompetency.user_comp_evidence : 'Not Available',
      expiry_date: expiryDateString,
      date_gained: dateGainedString,
      approver_fullname: (approverFname + " " + approverLname),
      expiry_status: expiryStatus,
      user_comp_attmnts_dest_path: userCompetency.user_comp_attmnts_dest_path
    };

    newUserCompeteny = newUserComp;
  }
  return newUserCompeteny;
}
  
function getExpirationStatus(compExpiryDate) {
  let currentDate = new Date();
  let expiryDate = new Date(compExpiryDate);
  let dateDifference = expiryDate - currentDate;
  let remainingDays = dateDifference / (1000 * 3600 * 24);

  if (remainingDays > 90) {
    return 'Valid';
  }

  if (remainingDays > 0 && remainingDays < 90) {
    return 'Expiring Soon';
  }

  if (remainingDays < 0) {
    return 'Expired';
  }
}

function createFileLinks(attachments, userComp) {
  let linkList = "";
  let fileDestPath = userComp.user_comp_attmnts_dest_path ? userComp.user_comp_attmnts_dest_path : "";
  if (attachments && attachments.length && fileDestPath !== "") {
    let fullPath = `${REACT_APP_FILE_MANAGER_API}/download-file?path=/${fileDestPath}/`;
    let numberOfAttachments = attachments.length;
    let newAttachments = <React.Fragment>{attachments.map((att, index) =>
      <React.Fragment key={index}>
        <div className="text-blue-900 font-sans text-sm font-bold">File Name:</div>
        <a href={fullPath + att.name} className="mb-1 hover:underline" key={index} target={"_blank"} rel="noopener noreferrer" download={true}>
          {att.name}{((index + 1) < numberOfAttachments) ? <br /> : ""}
        </a>
        <div className="text-blue-900 font-sans text-sm font-bold">File Description:</div>
        <div>
          {att.description}
        </div>
        {((index + 1) < numberOfAttachments) ? <br /> : ""}
      </React.Fragment>
    )}
    </React.Fragment>
    linkList = newAttachments;
  } else {
    linkList = <Link to="#" className="hover:underline">No File Attachment</Link>
  }
  return linkList;
}

export default ViewUserCompetency;
