/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { omit } from 'lodash';
import { AuthContext } from "../../context/authContext";
import FileUpload from '../global/FileUpload';
import { parseJSONSafely, updateAttachmentData, createUserTypeAndRoleObject } from "../../utils";

const { REACT_APP_FILE_MANAGER_API } = process.env;

const Validation = Yup.object().shape({
    user_id: Yup.string().label("user_id").required("User is required"),
    user_comp_catId: Yup.string().label("user_comp_catId").required("Category is required"),
    user_comp_compId: Yup.string().label("user_comp_compId").required("Competency is required"),
    user_comp_level: Yup.string().label("user_comp_level").required("Level is required"),
    user_comp_date_gained: Yup.date().label("user_comp_date_gained").required("Date Gained is required"),
    user_comp_evidence: Yup.string().label("user_comp_evidence").required("Evidence is required"),
    user_comp_expiry: Yup.date().label("user_comp_expiry").required("Expiry is required"),
})

const CREATE_USER_COMPETENCY = gql`
    mutation CreateUserCompetency($userComp: CreateUserCompetency!) {
        createUserCompetency(userComp: $userComp) {
            _id
            user_comp_expiry
            user_comp_date_gained
            user_comp_evidence
            success
            status_code
            message
        }
    }
`;

const UPDATE_USER_COMPETENCY = gql`
    mutation UpdateUserCompetency($userCompId:String!,$userId: String!, $userComp:UpdateUserCompetencyInput!) {
        updateUserCompetency(_id: $userCompId, userId: $userId, userComp: $userComp) {
            user_comp_expiry
            user_comp_date_gained
            user_comp_evidence
            success
            status_code
            message
        }
    }
`;

const USER_QUERY = gql`
query {
    abs_users{
        _id
        user_fname
        user_lname
    }
}`;

const USER_COMPETENCY_QUERY = gql`
query UserCompetency($userCompId: ID!){
    userCompetency(_id: $userCompId) {
        _id
        user_id{ 
          _id
          user_fname
          user_lname
        }
        user_comp_evidence
        user_comp_compId{ 
          _id
          comp_title
        }
        user_comp_catId{ 
         _id
          comp_cat_title
        }
        user_comp_level{
            _id
            sysvar_title 
        }
        user_comp_expiry
        user_comp_status
        user_comp_date_gained
        user_comp_attachment       
    }
}`;

const COMPETENCY_CATEGORY_QUERY = gql`
query CompetencyCategories($isArchived:Boolean!){
    competencyCategories(isArchived: $isArchived){
        _id
        comp_cat_code
        comp_cat_title
        comp_cat_notes
        comp_cat_archived
    }
}`;

const COMPETENCY_QUERY = gql`
query CompetenciesByCatId($isArchived:Boolean!, $catId: String!){
    competenciesByCatId(isArchived: $isArchived, catId: $catId) {
        _id
        comp_code
        comp_title
        comp_notes
        comp_levels{
            _id
            sysvar_title
        }
    }
}`;


const AddEditUserCompetency = (props) => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [showHelp, setShowHelp] = useState(false);
    const [categories, setCategories] = useState([]);
    const [competencies, setCompetencies] = useState([]);
    const [compLevels, setCompLevels] = useState([]);
    const [userList, setUsers] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState('Select Category');
    const [selectedCompetencyId, setSelectedCompetencyId] = useState('Select Competency');
    const [formInitValues, setFormInitValues] = useState({
        user_id: "",
        user_comp_catId: "",
        user_comp_compId: "",
        user_comp_level: "",
        user_comp_date_gained: "",
        user_comp_evidence: "",
        user_comp_expiry: "",
        user_comp_attachment: "[]"
    });
    const { formMode, userCompId = "" } = useParams();

    const [createUserCompetency, { data: createUserCompetencyData }] = useMutation(CREATE_USER_COMPETENCY);
    const [updateUserCompetency, { data: updateUserCompetencyData }] = useMutation(UPDATE_USER_COMPETENCY);
    const [competencyCategories, { data: compCatsData }] = useLazyQuery(COMPETENCY_CATEGORY_QUERY);
    const [competenciesByCatId, { data: competenciesData }] = useLazyQuery(COMPETENCY_QUERY);
    const [userCompetency, { data: userCompetencyData }] = useLazyQuery(USER_COMPETENCY_QUERY);
    const [abs_users, { data: usersData }] = useLazyQuery(USER_QUERY);

    useEffect(() => {
    if (authContext && authContext.user){
        let result = createUserTypeAndRoleObject(authContext);
        setUserTypeAndRoleObject(result);
    }
    }, [authContext]);

    useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
    }, [userTypeAndRoleObject]);
    
    useEffect(() => {
        competencyCategories({ variables: { isArchived: false }, errorPolicy: 'all' });
        abs_users();
        if (formMode === "edit" && userCompId !== "") {
            userCompetency({ variables: { userCompId }, errorPolicy: 'all' });
        };
    }, []);

    useEffect(() => {
        if (compCatsData && compCatsData.competencyCategories) {
            let newCats = compCatsData.competencyCategories.filter(compCat => compCat.comp_cat_archived !== true)
            setCategories(newCats);
        }
    }, [compCatsData]);

    useEffect(() => {
        if (competenciesData && competenciesData.competenciesByCatId) {
          let competencyList =  competenciesData.competenciesByCatId;
            setCompetencies(competencyList);
            let userCompCompId = formInitValues.user_comp_compId;
            if (userCompCompId !== "") {
                let competency = competencyList.find(c => c._id === userCompCompId);
                if (competency) {
                    let levels = competency.comp_levels;
                    setCompLevels(levels);
                }
            }
        }
    }, [competenciesData]);

    useEffect(() => {
        if (usersData && usersData.abs_users) {
            setUsers(usersData.abs_users);
        }
    }, [usersData]);

    useEffect(() => {
        if (userCompetencyData && userCompetencyData.userCompetency) {
            let userCompetency = userCompetencyData.userCompetency;

            let comp_catId = userCompetency.user_comp_catId ? userCompetency.user_comp_catId._id : "";
            let userCompCompId = userCompetency.user_comp_compId ? userCompetency.user_comp_compId._id : "";

            let userCompLevel = userCompetency.user_comp_level && userCompetency.user_comp_level._id ? userCompetency.user_comp_level._id : "";

            let attachment = userCompetency.user_comp_attachment && userCompetency.user_comp_attachment !== "" ? userCompetency.user_comp_attachment : "[]";
            
            setFormInitValues({
                user_id: userCompetency.user_id ? userCompetency.user_id._id : "",
                user_comp_catId: comp_catId,
                user_comp_level: userCompLevel,
                user_comp_compId: userCompetency.user_comp_compId ? userCompetency.user_comp_compId._id : "",
                user_comp_date_gained: new Date(userCompetency.user_comp_date_gained),
                user_comp_evidence: userCompetency.user_comp_evidence,
                user_comp_expiry: new Date(userCompetency.user_comp_expiry),
                user_comp_attachment: attachment
            });

            if (userCompCompId !== "") {
                setSelectedCompetencyId(userCompCompId);
            }

            if (comp_catId !== "") {
                competenciesByCatId({ variables: { isArchived: false, catId: comp_catId }, errorPolicy: 'all' });
                setSelectedCategoryId(comp_catId);
            }
        }

    }, [userCompetencyData]);

    useEffect(() => {
        if (createUserCompetencyData && createUserCompetencyData.createUserCompetency) {
            const {success, message} = createUserCompetencyData.createUserCompetency;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push('/user-competencies');
            }         
        }
    }, [createUserCompetencyData, history]);

    useEffect(() => {
        if (updateUserCompetencyData) {
            const {success, message} = updateUserCompetencyData.updateUserCompetency;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push('/user-competencies');
            }      
        }
    }, [updateUserCompetencyData, history]);


    function handleCategoryChange(event) {
        let value = event.target.value;
        setSelectedCategoryId(value)
        if (value !== 'Select Category') {
            competenciesByCatId({ variables: { isArchived: false, catId: value }, errorPolicy: 'all' });
        }
    }

    function handleCompetencyChange(event) {
        let value = event.target.value;
        setSelectedCompetencyId(value)
        if (value !== 'Select Competency') {
            let competency = competencies.find(c => c._id === value);
            if (competency) {
                 let levels = competency.comp_levels;
                setCompLevels(levels);
            }
        }
    }

    let tempUserCompAttachFileList = [];
    const handleUserCompAttachFilesDescription = (item) => {
        let index = tempUserCompAttachFileList.findIndex(f => f.name === item.name);
        if (index >= 0) {
            tempUserCompAttachFileList[index] = item;
        } else {
            tempUserCompAttachFileList.push(item);
        }
    };

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} User Competency <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                <div>
                    <p>Enter the details into the required fields, including adding attachments by clicking on 'Attach Files'.</p>
                    <p>Once complete, click '<span className="capitalize">{formMode}</span> User Competency'.</p>
                </div>
                }
                
            </div>

            <Formik
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={Validation}
                onSubmit={(values, actions) => {
                    const { _id: userId = "" } = authContext.user;
                    let tempValues = { ...values };

                    tempValues.user_comp_attachment = updateAttachmentData(tempValues.user_comp_attachment, tempUserCompAttachFileList);

                    let user = userList.find(u => u._id === tempValues.user_id);
                    const {user_fname, user_lname } = user ? user : {};
                    let fullName = `${user_fname}${user_lname}`;
                    tempValues.user_fullname = fullName;

                    if (formMode === 'add') {
                        tempValues.user_comp_added_id = userId;
                        tempValues.user_comp_added_date = new Date();
                        createUserCompetency({ variables: { userComp: tempValues } })
                    }

                    if (formMode === 'edit') {
                        updateUserCompetency({ variables: { userCompId, userId: userId, userComp: tempValues } })
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="my-4">
                            <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>
                        </div>

                        <div>
                            <label htmlFor="user_id" className="block mb-1 text-blue-900 font-semibold">User*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="user_id"
                                id="user_id"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.user_id}
                            >
                                <option value = {""}>Select User</option>
                                {userList.map(user =>
                                    <option value={user._id} key={user._id}>{user.user_fname} {user.user_lname}</option>
                                )}
                            </select>
                            <FormError touched={touched.user_id} message={errors.user_id} />
                        </div>

                        <div>
                            <label htmlFor="user_comp_catId" className="block mb-1 text-blue-900 font-semibold">Category*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="user_comp_catId"
                                id="user_comp_catId"
                                onChange={(event) => { 
                                    handleCategoryChange(event); 
                                    handleChange(event);
                                    setFieldValue("user_comp_compId", ""); 
                                    setFieldValue("user_comp_level", ""); 
                                }}
                                onBlur={handleBlur}
                                value={values.user_comp_catId}
                            >
                                <option value = {""}>Select Category</option>
                                {categories.map(category =>
                                    <option value={category._id} key={category._id}>{category.comp_cat_title}</option>
                                )}
                            </select>
                            <FormError touched={touched.user_comp_catId} message={errors.user_comp_catId} />
                        </div>

                        <div>
                            <label htmlFor="user_comp_compId" className="block mb-1 text-blue-900 font-semibold">Competency*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="user_comp_compId"
                                id="user_comp_compId"
                                onChange={(event) => { 
                                    handleCompetencyChange(event); 
                                    handleChange(event) ;
                                    setFieldValue("user_comp_level", ""); 
                                }}
                                onBlur={handleBlur}
                                value={selectedCategoryId === "Select Category" ? "Select Competency" : values.user_comp_compId}
                                disabled={selectedCategoryId === "Select Category"}
                            >
                                <option value = {""}>Select Competency</option>
                                {competencies.map(competency =>
                                    <option value={competency._id} key={competency._id}>{competency.comp_title}</option>
                                )}
                            </select>
                            <FormError touched={selectedCategoryId === 'Select Category' ? '' : touched.user_comp_compId}
                                message={selectedCategoryId === 'Select Category' ? '' : errors.user_comp_compId} />
                        </div>

                        <div>
                            <label htmlFor="user_comp_level" className="block mb-1 text-blue-900 font-semibold">Level*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="user_comp_level"
                                id="user_comp_level"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={selectedCompetencyId === "Select Competency" ? "Select Level" : values.user_comp_level}
                                disabled={selectedCategoryId === "Select Category" || selectedCompetencyId === "Select Competency"}
                            >
                                <option value = {""}>Select Level</option>
                                {compLevels.map(level =>
                                    <option value={level._id} key={level._id}>{level.sysvar_title}</option>
                                )}
                            </select>
                            <FormError touched={selectedCategoryId === 'Select Category' ? '' : touched.user_comp_level}
                                message={selectedCategoryId === 'Select Category' ? '' : errors.user_comp_level} />
                        </div>

                        <div>
                            <label htmlFor="user_comp_date_gained" className="block mb-1 text-blue-900 font-semibold">Date Gained*</label>
                            <DatePicker
                                id="user_comp_date_gained"
                                selected={values.user_comp_date_gained}
                                dateFormat="dd MMMM Y"
                                name="user_comp_date_gained"
                                onChange={date => setFieldValue('user_comp_date_gained', date)}
                                onBlur={handleBlur}
                                value={values.user_comp_date_gained}
                                className="form-control block w-full md:w-1/2"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={new Date()}
                                autoComplete="off"
                            />
                            <FormError touched={touched.user_comp_date_gained} message={errors.user_comp_date_gained} />
                        </div>

                        <div>
                            <label htmlFor="user_comp_expiry" className="block mb-1 text-blue-900 font-semibold">Expiry Date*</label>
                            <DatePicker
                                id="user_comp_expiry"
                                selected={values.user_comp_expiry}
                                dateFormat="dd MMMM Y"
                                name="user_comp_expiry"
                                onChange={date => setFieldValue('user_comp_expiry', date)}
                                onBlur={handleBlur}
                                value={values.user_comp_expiry}
                                className="form-control block w-full md:w-1/2"
                                showMonthDropdown
                                showYearDropdown
                                minDate={new Date()}
                                autoComplete="off"
                            />
                            <FormError touched={touched.user_comp_expiry} message={errors.user_comp_expiry} />
                        </div>

                        <div>
                            <label htmlFor="user_comp_evidence" className="block mb-1 text-blue-900 font-semibold">Evidence*</label>
                            <textarea
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="user_comp_evidence"
                                id="user_comp_evidence"
                                placeholder="Evidence"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.user_comp_evidence}
                            />
                            <FormError touched={touched.user_comp_evidence} message={errors.user_comp_evidence} />
                        </div>


                        <div className="mb-4">
                            <label htmlFor="user_comp_attachment" className="block mb-1 text-blue-900 font-semibold">Attachment</label>

                            {authContext.user && authContext.user.user_home_dir &&
                                <FileUpload
                                    onAttach={(files) => setFieldValue('user_comp_attachment', JSON.stringify(files))}
                                    id='fileManager'
                                    buttonText='Attach Files'
                                    apiURL={REACT_APP_FILE_MANAGER_API}
                                    toolbarText='Attach'
                                    homeDir={`/${authContext.user.user_home_dir}/`}
                                    showDescription={true}
                                    handleFileDescription={handleUserCompAttachFilesDescription}
                                    tempFileList={tempUserCompAttachFileList}
                                    {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.user_comp_attachment) ? parseJSONSafely(values.user_comp_attachment) : [] })}
                                />
                            }

                        </div>

                        <button type="submit" className="mt-3 button-red capitalize">{formMode} User Competency</button>

                    </form>
                )}
            </Formik>

        </div>
    )

}

export default AddEditUserCompetency;
