/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Redirect, Route } from "react-router-dom";
import {gql, useLazyQuery} from '@apollo/client';

const CHECK_USER_PERMISSION = gql`
query CheckUserPermission($dhId: ID, $instId: ID, $seceId: ID){
    checkUserPermission(dhId: $dhId, instId: $instId, seceId: $seceId) {
        result
        success
        status
        status_code
        message
    }
}`;

function ProtectedRoute({ component: Component, computedMatch: ComputedMatch, ...restOfProps }) {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [statusCode, setStatusCode] = useState(0);

    const { params: { id = '', assetGroupId = '', installationId = '', seceId ='' } } = ComputedMatch ? ComputedMatch : {};

    const [checkUserPermission, { error, loading, data: permissionData }] = useLazyQuery(CHECK_USER_PERMISSION);

    useEffect(() => {
        checkUserPermission({ variables: { dhId: id, instId: installationId, seceId: seceId }, errorPolicy: 'all' });
    }, [id, assetGroupId, installationId, seceId]);

    useEffect(() => {
        if (permissionData && permissionData.checkUserPermission) {
            const { result, status_code } = permissionData.checkUserPermission;
            setIsAuthorized(result);
            setStatusCode(status_code);
        }
    }, [permissionData]);

    useEffect(() => {
        if (permissionData && permissionData.checkUserPermission) {
            const { result } = permissionData.checkUserPermission;
            setIsAuthorized(result);
        }
    }, [permissionData]);

    if (statusCode === 0 || loading) {
        return (
            <div></div>
        )
    }

    if (error) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }
    
    if (statusCode > 0) {
        return (
            <Route
                {...restOfProps}
                render={(props) =>
                    isAuthorized ? <Component {...props} /> : <Redirect to="/access-permissions" />
                }
            />
        );
    } 
}

export default ProtectedRoute;