/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { useParams, useHistory } from 'react-router-dom';
import InfoModal from '../global/infoModal';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import { createUserTypeAndRoleObject } from "../../utils/index";

const validation = (formMode, infoMatCount) => {
    let validation = {};
    let sharedValidationObject = {
        im_dh: Yup.string().label("im_dh").required("Duty Holder is required"),
        im_stage1: Yup.string().label("im_stage1").required("Stage 1 is required").max(50, "Cannot be more than 50 characters"),
        im_stage2: Yup.string().label("im_stage2").required("Stage 2 is required").max(50, "Cannot be more than 50 characters"),
        im_stage3: Yup.string().label("im_stage3").required("Stage 3 is required").max(50, "Cannot be more than 50 characters"),
        im_stage4: Yup.string().label("im_stage4").required("Stage 4 is required").max(50, "Cannot be more than 50 characters"),
        im_stage5: Yup.string().label("im_stage5").required("Stage 5 is required").max(50, "Cannot be more than 50 characters"),
        im_rogi: Yup.string().label("im_rogi").required("ROGI is required").max(50, "Cannot be more than 50 characters"),

        info_mat_with_instId_active: Yup.boolean().default(infoMatCount > 0),
        im_installation: Yup.string()
            .label("im_installation")
            .required("Installation is required")
            .when('info_mat_with_instId_active', {
                is: true,
                then: Yup.string().test("im_installation", "Info Matrix with same installation is active", function (value) {
                    const { path, createError } = this;
                    return createError({ path, message: "Info Matrix with same installation is active" });
                }),
            }),
    };
    let formModeAddValidationObject = {

    };
    validation = Yup.object().shape({
        ...sharedValidationObject,
        ...(formMode === 'add' && formModeAddValidationObject)
    })
    return validation;
}

const DUTY_HOLDERS_QUERY = gql`
    query {
        dutyHolders {
            _id
            dh_name
            dh_status
            dh_added_date
            dh_archived
        }
}`;

const INSTALLATIONS_BY_DH_ID_QUERY = gql`
query installationsByDutyHolderId($dhId: ID){
    installationsByDutyHolderId(dhId: $dhId) {
      _id
      installation_title
      installation_archived
  }
}`;

const INFO_MATRIX_BY_ID_QUERY = gql`
query InfoMatrix($imId: ID!){
    infoMatrix(_id: $imId) {
        _id
        dh_id{
          _id
          dh_name
        }
        installation_id{
          _id
          installation_title
        }
        im_stage1
        im_stage2
        im_stage3
        im_stage4
        im_stage5
        im_rogi
    }
}`;

const INFOMATRIX_COUNT_QUERY = gql`
query InfoMatrixCount($instlId: String!){
    infoMatrixCount(instlId: $instlId)
}`;

const CREATE_INFO_MATRIX = gql`
mutation CreateInfoMatrix($im: CreateInfoMatrix!){
    createInfoMatrix(im: $im) {
            _id
            success
            status_code
            message
        }
}`;

const UPDATE_INFO_MATRIX = gql`
mutation UpdateInfoMatrix($imId: String!, $userId: String, $im: UpdateInfoMatrixInput!){
    updateInfoMatrix(_id: $imId, userId: $userId, im: $im) {
            _id
            success
            status_code
            message
    }
}`;

const rogiValues = [
    { id: 1, value: "Detailed in Scheme" },
    { id: 2, value: "Not Detailed in Scheme" },
]

const AddEditInfoMatrix = () => {
    const [selectedDutyHolderId, setSelectedDutyHolderId] = useState('Select Duty Holder');
    const [infoMatCount, setInfoMatCount] = useState(undefined);
    const [selectedInstId, setSelectedInstId] = useState('Select Installation');
    const [dutyHolderList, setDutyHolderList] = useState([]);
    const [installList, setInstallList] = useState([]);
    const [rogiList, setRogiList] = useState([]);
    const [drDnInstallation, setDrDnInstallation] = useState({});

    const [formInitValues, setFormInitValues] = useState({
        im_dh: "",
        im_installation: "",
        im_stage1: "",
        im_stage2: "",
        im_stage3: "",
        im_stage4: "",
        im_stage5: "",
        im_rogi: ""
    });

    const { formMode = "", imId = "" } = useParams();

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);
    const history = useHistory();

    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [installationsByDutyHolderId, { data: installsData }] = useLazyQuery(INSTALLATIONS_BY_DH_ID_QUERY);
    const [dutyHolders, { data: dhData }] = useLazyQuery(DUTY_HOLDERS_QUERY);
    const [infoMatrix, { data: imData }] = useLazyQuery(INFO_MATRIX_BY_ID_QUERY);
    const [infoMatrixCount, { data: imCountData }] = useLazyQuery(INFOMATRIX_COUNT_QUERY);

    const [createInfoMatrix, { data: createImData }] = useMutation(CREATE_INFO_MATRIX);
    const [updateInfoMatrix, { data: updateImData }] = useMutation(UPDATE_INFO_MATRIX);

    useEffect(() => {
        if (authContext && authContext.user) {
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        const { installation } = navContext;
        const { id: prevId } = drDnInstallation;
    
        if (prevId && installation.id && prevId !== installation.id) {
          history.push('/');
        }
        setDrDnInstallation(installation);
    }, [navContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);

    useEffect(() => {
        setRogiList(rogiValues);
        dutyHolders({ variables: {}, errorPolicy: 'all' });
        if (formMode === "edit" && imId !== "") {
            infoMatrix({ variables: { imId: imId }, errorPolicy: 'all' });
        }
    }, []);

    useEffect(() => {
        if (dhData && dhData.dutyHolders) {
            let list = dhData.dutyHolders;
            let newDutyHolderList = list.filter(dh => dh.dh_status === "approved" && dh.dh_archived !== true);
            setDutyHolderList(newDutyHolderList)
        }
    }, [dhData]);

    useEffect(() => {
        if (selectedDutyHolderId !== 'Select Duty Holder') {
            installationsByDutyHolderId({ variables: { dhId: selectedDutyHolderId }, errorPolicy: 'all' });
        }
    }, [selectedDutyHolderId]);

    useEffect(() => {
        if (installsData && installsData.installationsByDutyHolderId) {
            let list = installsData.installationsByDutyHolderId;
            let newInstallList = list.filter(install => install.installation_archived !== true);
            setInstallList(newInstallList)
        }
    }, [installsData]);

    useEffect(() => {
        if (imData && imData.infoMatrix) {
            let infoMatrix = imData.infoMatrix;

            let dhId = infoMatrix.dh_id ? infoMatrix.dh_id._id : "";
            let instId = infoMatrix.installation_id ? infoMatrix.installation_id._id : "";

            let initValue = {
                im_dh: dhId,
                im_installation: instId,
                im_stage1: infoMatrix.im_stage1,
                im_stage2: infoMatrix.im_stage2,
                im_stage3: infoMatrix.im_stage3,
                im_stage4: infoMatrix.im_stage4,
                im_stage5: infoMatrix.im_stage5,
                im_rogi: infoMatrix.im_rogi
            }
            setSelectedDutyHolderId(dhId);
            setSelectedInstId(instId);
            setFormInitValues(initValue);
        }
    }, [imData]);

    useEffect(() => {
        if (imCountData) {
            if (formMode === "edit" && selectedInstId === formInitValues.im_installation) {
                setInfoMatCount(0);
            } else {
                setInfoMatCount(imCountData.infoMatrixCount);
            }
        }
    }, [imCountData]);

    const handleDutyHolderChange = (event) => {
        let value = event.target.value;
        setSelectedDutyHolderId(value);
    };

    const handleInstallationChange = (event) => {
        let value = event.target.value;
        setSelectedInstId(value);
        infoMatrixCount({ variables: { instlId: value }, errorPolicy: 'all' });
    };

    useEffect(() => {
        if (createImData && createImData.createInfoMatrix) {
            const { success, message } = createImData.createInfoMatrix;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push("/info-matrixes");
            }
        }
    }, [createImData]);

    useEffect(() => {
        if (updateImData && updateImData.updateInfoMatrix) {
            const { success, message } = updateImData.updateInfoMatrix;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push("/info-matrixes");
            }
        }
    }, [updateImData]);

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Duty Holder Info Matrix</h1>
            </div>

            <Formik
                enableReinitialize
                initialValues={formInitValues}
                validationSchema={validation(formMode, infoMatCount)}
                onSubmit={(values, actions) => {
                    const { _id: userId = "" } = authContext.user;

                    if (formMode === "add" && userId !== "") {

                        let infoMatrix = {
                            dh_id: values.im_dh,
                            installation_id: values.im_installation,
                            im_stage1: values.im_stage1,
                            im_stage2: values.im_stage2,
                            im_stage3: values.im_stage3,
                            im_stage4: values.im_stage4,
                            im_stage5: values.im_stage5,
                            im_rogi: values.im_rogi,
                            im_added_id: userId,
                            im_added_date: new Date()
                        };

                        createInfoMatrix({ variables: { im: infoMatrix } })
                    }

                    if (formMode === "edit" && imId !== "" && userId !== "") {

                        let infoMatrix = {
                            dh_id: values.im_dh,
                            installation_id: values.im_installation,
                            im_stage1: values.im_stage1,
                            im_stage2: values.im_stage2,
                            im_stage3: values.im_stage3,
                            im_stage4: values.im_stage4,
                            im_stage5: values.im_stage5,
                            im_rogi: values.im_rogi,
                        };

                        updateInfoMatrix({ variables: { imId: imId, userId: userId, im: infoMatrix } });
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>

                        {/*
                        <div className="my-4">
                            <Link to="/info-matrixes" className="button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>
                        </div>
                        */}

                        <div className="mt-6">
                            <label htmlFor="im_dh" className="block mb-1 text-blue-900 font-semibold">Duty Holder*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="im_dh"
                                id="im_dh"
                                onChange={(event) => { handleDutyHolderChange(event); handleChange(event) }}
                                onBlur={handleBlur}
                                value={values.im_dh}
                            >
                                <option value={""}>Select Duty Holder</option>
                                {dutyHolderList.map(dh =>
                                    <option key={dh._id} value={dh._id}>{dh.dh_name}</option>
                                )}
                            </select>
                            <FormError touched={touched.im_dh} message={errors.im_dh} />
                        </div>

                        <div>
                            <label htmlFor="im_installation" className="block mb-1 text-blue-900 font-semibold">Installation*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="im_installation"
                                id="im_installation"
                                onChange={(event) => { handleInstallationChange(event); handleChange(event) }}
                                onBlur={handleBlur}
                                value={values.im_installation}
                            >
                                <option value={""}>Select Installation</option>
                                {installList.map(install =>
                                    <option key={install._id} value={install._id}>{install.installation_title}</option>
                                )}
                            </select>
                            <FormError touched={touched.im_installation} message={errors.im_installation} />
                        </div>

                        <div>
                            <label htmlFor="im_stage1" className="block mb-1 text-blue-900 font-semibold w-full md:w-1/2">
                                Stage 1*

                                <span className="float-right">
                                    <InfoModal
                                        theme="dark"
                                    >
                                        <h3 className="mb-2 text-xl text-blue-900 font-bold">Level 1</h3>
                                        <p className="font-normal">Performance Standard satisfied, but Verifier may suggest an improvement to the system or may request additional information to demonstrate compliance with a Performance Standard.</p>
                                    </InfoModal>
                                </span>
                            </label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="im_stage1"
                                id="im_stage1"
                                placeholder="Stage 1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.im_stage1}
                            />
                            <FormError touched={touched.im_stage1} message={errors.im_stage1} />
                        </div>

                        <div>
                            <label htmlFor="im_stage2" className="block mb-1 text-blue-900 font-semibold w-full md:w-1/2">
                                Stage 2*

                                <span className="float-right">
                                    <InfoModal
                                        theme="dark"
                                    >
                                        <h3 className="mb-2 text-xl text-blue-900 font-bold">Level 2</h3>
                                        <p className="font-normal">Single Performance Standard failure with no significant threat to the installation.</p>
                                    </InfoModal>
                                </span>
                            </label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="im_stage2"
                                id="im_stage2"
                                placeholder="Stage 2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.im_stage2}
                            />
                            <FormError touched={touched.im_stage2} message={errors.im_stage2} />
                        </div>

                        <div>
                            <label htmlFor="im_stage3" className="block mb-1 text-blue-900 font-semibold w-full md:w-1/2">
                                Stage 3*

                                <span className="float-right">
                                    <InfoModal
                                        theme="dark"
                                    >
                                        <h3 className="mb-2 text-xl text-blue-900 font-bold">Level 3</h3>
                                        <p className="font-normal">Fundamental weakness of the SECE Assurance system that involves multiple failures of a Performance Standard (s); or presents a significant threat to the integrity of the installation</p>
                                    </InfoModal>
                                </span>
                            </label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="im_stage3"
                                id="im_stage3"
                                placeholder="Stage 3"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.im_stage3}
                            />
                            <FormError touched={touched.im_stage3} message={errors.im_stage3} />
                        </div>

                        <div>
                            <label htmlFor="im_stage4" className="block mb-1 text-blue-900 font-semibold w-full md:w-1/2">
                                Stage 4*

                                <span className="float-right">
                                    <InfoModal
                                        theme="dark"
                                    >
                                        <h3 className="mb-2 text-xl text-blue-900 font-bold">Letter of Reservation</h3>
                                        <p className="font-normal">A letter of reservation will be issued by the Verifier when there is a Regulatory compliance concern with regards to the 'Verification scheme' that is required to be brought to the attention of the duty holder's senior management. Examples include poor identification of SECEs, inadequate Performance Standards, Assurance processes or Verification activities (nature and frequency).</p>
                                    </InfoModal>
                                </span>
                            </label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="im_stage4"
                                id="im_stage4"
                                placeholder="Stage 4"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.im_stage4}
                            />
                            <FormError touched={touched.im_stage4} message={errors.im_stage4} />
                        </div>

                        <div>
                            <label htmlFor="im_stage5" className="block mb-1 text-blue-900 font-semibold w-full md:w-1/2">
                                Stage 5*

                                <span className="float-right">
                                    <InfoModal
                                        theme="dark"
                                    >
                                        <h3 className="mb-2 text-xl text-blue-900 font-bold">Letter of Concern</h3>
                                        <p className="font-normal">A letter of concern will be issued by the Verifier when there is a fundamental concern with regards to the 'management of Verification' that is required to be brought to the attention of the duty holder's senior management. Examples include mismanagement for findings, the cumulative effect of open or overdue findings, failing to assist or provide access to allow the Verifier to complete the scheme activity requirements.</p>
                                    </InfoModal>
                                </span>
                            </label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="im_stage5"
                                id="im_stage5"
                                placeholder="Stage 5"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.im_stage5}
                            />
                            <FormError touched={touched.im_stage5} message={errors.im_stage5} />
                        </div>

                        <div>
                            <label htmlFor="im_rogi" className="block mb-1 text-blue-900 font-semibold"> ROGI*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="im_rogi"
                                id="im_rogi"
                                onChange={(event) => { handleChange(event) }}
                                onBlur={handleBlur}
                                value={values.im_rogi}
                            >
                                <option value={""}>Select ROGI</option>
                                {rogiList.map(rg =>
                                    <option key={rg.id} value={rg.value}>{rg.value}</option>
                                )}
                            </select>
                            <FormError touched={touched.im_rogi} message={errors.im_rogi} />
                        </div>

                        <button type="submit" className="mt-3 button-red capitalize">{formMode} Info Matrix Details</button>

                    </form>
                )}
            </Formik>

        </div>
    )
}

export default AddEditInfoMatrix;
