/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useQuery} from '@apollo/client';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { AuthContext } from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";

const BREADCRUMB_QUERY = gql`
    query Breadcrumb ($payload: String!){
        breadcrumb (payload: $payload){
            type
            name
            link
        }
    }`;

export default function BreadCrumb(props) {
  const {payload} = props;
  const [breadcrumb, setBreadcrumb] = useState([])
  const {data} = useQuery(BREADCRUMB_QUERY, {
    variables: {payload},
    errorPolicy: 'all'
  });

  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
}, [authContext]);


  useEffect(() => {

    if(data && data.breadcrumb) {
      setBreadcrumb(data.breadcrumb)
    }

  }, [data])

  return (
    <div className="mb-6">
    {breadcrumb.map((crumb, idx) => (
      <div key={idx} className="inline">
          
          {(idx < (breadcrumb.length - 1)) ? (
            <Tippy content={<span>{crumb.type}</span>}>
              {userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS" && idx === 0 ? 
              <span className="breadcrumb-name font-sans font-bold text-xs text-red-900">{crumb.name}</span>
              :
              <Link to={crumb.link} className="breadcrumb-name font-sans font-bold text-xs text-red-900">{crumb.name}</Link>
              }
            </Tippy>
          ) :
            <span className="breadcrumb-name font-sans font-bold text-xs text-red-900">{crumb.name}</span>
          }
          
        {(idx < (breadcrumb.length - 1)) && (
            <FontAwesomeIcon className="mx-2 text-gray-200" icon={['fas', 'angle-right']} />
        )}
      </div>
    ))}
    </div>
  )
}