/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import { useQueryString, formatDateToLocaleDateString, capitaliseFirstCase } from '../../utils'

const BARRIER_HISTORIES_QUERY = gql`
        query BarrierHistory($dhId: String!, $filter: String){
            barrier_histories_by_duty_holder_id(dhId: $dhId, filter: $filter) {
                _id
                dh_id {
                    _id
                }
                installation_id{
                  _id
                }
                bar_title
                bar_notes
                bar_order_no
                bar_child_id
                bar_added_date
                bar_added_id{
                  _id
                }
                bar_archived
                bar_status
                history_parent_record_id
                history_modifying_user_id {
                    _id
                    user_fname
                    user_lname
                    user_email
                }
                history_modified_date
                history_modified_date_timestamp
            }
        }
  `;

function BarrierHistories() {
  const [showHelp, setShowHelp] = useState(false);
  const [barrierHistories, setBarrierHistories] = useState([]);

  const { id: dhId, assetGroupId, installationId, barId } = useParams();

  const queryString = useQueryString();
  const filters = queryString.get('filters');

  const [barrier_histories_by_duty_holder_id, { loading, error, data }] = useLazyQuery(BARRIER_HISTORIES_QUERY);

  useEffect(() => {
    let localFilter = filters ? filters : JSON.stringify({ installation_id: installationId, ...(barId && { history_parent_record_id: barId }) });
    barrier_histories_by_duty_holder_id({ variables: { ...(dhId && dhId !== "" && { dhId: dhId }), ...({ filter: localFilter }) }, errorPolicy: 'all' });
  }, [])

  useEffect(() => {
    if (data && data.barrier_histories_by_duty_holder_id) {
      let hisList = data.barrier_histories_by_duty_holder_id;
      let formatedHisList = formatBarrierHistoryData(hisList);
      let reversedHistoryList = formatedHisList.reverse();
      return setBarrierHistories(reversedHistoryList);
    }
  }, [data])


  const columns = React.useMemo(
    () => [
      {
        Header: 'Barrier Name',
        accessor: 'bar_title',
      },
      {
        Header: 'Modified Date',
        accessor: "history_modified_date"
      },
      {
        Header: 'Modifying User',
        accessor: "modifying_user",
      },
      {
        Header: 'Status',
        accessor: "status",
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: bar => (
          <div className="text-right">
            <Link
              to={{
                pathname: `/dutyholder/${bar.value.dhId}/barriers/view-diff/${bar.value.id}/${bar.value.parentId}`,
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              View Change
            </Link>
          </div>
        )
      },
    ],
    []
  )

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 mb-6 border-b-2 border-gray-200">

        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Barrier History<FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)} /></h1>
        {showHelp &&
          <p className="mt-3">A table detailing changes to Barriers.</p>
        }

      </div>

        <Link to={`/dutyholder/${dhId}/asset-group/${assetGroupId}/installation/${installationId}/barriers`} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>


      <Table columns={columns} data={barrierHistories} />
    </div>
  )
}

function formatBarrierHistoryData(histories) {
  let historyList = []

  if (histories && histories.length) {

    histories.forEach(his => {
      const { _id, dh_id, bar_title, bar_status, history_modified_date, history_modifying_user_id, history_parent_record_id } = his ? his : {};
      const { _id: dhId = "" } = dh_id ? dh_id : {};
      const { user_fname = "", user_lname = "" } = history_modifying_user_id ? history_modifying_user_id : {}
      let userFullName = (user_fname !== "" && user_lname !== "") ? `${user_fname} ${user_lname}` : "N/A";

      let newHistory = {
        id: _id,
        parentId: history_parent_record_id,
        dhId: dhId,
        bar_title: bar_title && bar_title !== "" ? bar_title : "N/A",
        history_modified_date: history_modified_date && history_modified_date !== "" ? formatDateToLocaleDateString(history_modified_date) : "N/A",
        modifying_user: userFullName,
        status: bar_status && bar_status !== "" ? capitaliseFirstCase(bar_status) : "N/A"
      }

      historyList.push(newHistory);
    });
  }

  return historyList;
}

export default BarrierHistories;