/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import Table from '../global/Table';
import TableDropDown from '../global/TableDropDown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import PageSpinner from "../global/pageSpinner";
import BreadCrumb from "../global/Breadcrumb";
import {AuthContext} from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import {capitaliseFirstCase, createUserTypeAndRoleObject} from "../../utils/index";
import {NOTIFICATION_STATUS_APPROVED, NOTIFICATION_STATUS_REJECT, NOTIFICATION_STATUS_REVISION} from "../../constants";
import Comment from '../global/Comment';
import {sysObjects} from "../../constants/sysObjectList";

const dropdownListItems = ({dutyHolderId, assetGroupId, installationId, barrierId}) => (
    [
      {
        id: 1,
        name: 'SECEs',
        link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/seces`
      },
      {
        id: 2,
        name: 'Reference Activities',
        link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/reference-activities`
      }
    ]
);

const BARRIERS_BY_INST_ID_QUERY = gql`
    query BarrierByInstallationId ($installationId: ID!){
        barrierByInstallationId (installationId: $installationId){
            _id
            bar_ref
            bar_title
            bar_archived
            bar_archived_id
            bar_archived_date
            installation_id{
              _id
            }
            bar_status
            bar_order_no
        }
    }`;

const UPDATE_BARRIER = gql`
    mutation UpdateBarrier($barId: String!, $userId: String, $bar: UpdateBarrierInput!){
        updateBarrier(_id: $barId, userId: $userId, bar: $bar){
            _id
        }
    }`;

function Barriers(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [barrierList, setBarrierList] = useState([]);
  const [showArchivedBarriers, setShowArchivedBarriers] = useState(false);
  const [drDnInstallation, setDrDnInstallation] = useState({});

  const {id, assetGroupId, installationId} = useParams();
  const {location: {state = ""}, match: {url = ""}} = props ? props : {};

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const history = useHistory();

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
      const { installation } = navContext;
      const { id: prevId } = drDnInstallation;

      if (prevId && installation.id && prevId !== installation.id) {
          history.push('/');
      }
      setDrDnInstallation(installation);
  }, [navContext]);

  const [barrierByInstallationId, {
    loading: barByInstIdLoading,
    error: barByInstIdError,
    data: barByInstIdData,
    refetch: refetchBarByInstId
  }] = useLazyQuery(BARRIERS_BY_INST_ID_QUERY);

  const [updateBarrier, {data: updateBarrierData}] = useMutation(UPDATE_BARRIER);

  useEffect(() => {
    if (state === "archived") {
      setShowArchivedBarriers(true);
    };
  }, []);

  useEffect(() => {
      barrierByInstallationId({variables: {installationId: installationId}, errorPolicy: 'all'});
  }, [showArchivedBarriers]);


  useEffect(() => {
    if (barByInstIdData && barByInstIdData.barrierByInstallationId) {
      const {_id: userId = ""} = authContext.user ? authContext.user : {};
      let tempBarrierList = formatBarriersData(barByInstIdData.barrierByInstallationId, showArchivedBarriers, userId, state, userTypeAndRoleObject);
      setBarrierList(tempBarrierList);
    }
  }, [barByInstIdData])

  useEffect(() => {
    if (updateBarrierData && updateBarrierData.updateBarrier) {
        refetchBarByInstId();
    }
  }, [updateBarrierData])

  const archiveManager = (barId, isArchived, archivedId, archivedDate, userId) => {
    if (barId !== "") {
      const payload = {
        bar_archived: isArchived ? false : true,
        bar_archived_id: isArchived ? archivedId : userId,
        bar_archived_date: isArchived ? archivedDate : new Date()
      }
      updateBarrier({variables: {barId: barId, userId: userId, bar: payload}});
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Order No.',
        accessor: 'bar_order_no',
      },
      {
        Header: 'Barrier',
        accessor: 'bar_title',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { _id, bar_archived, bar_archived_id, bar_archived_date, user_id, state, bar_status, userTypeAndRoleObject } }) => {

          return (
            <div className="text-right">

              <Comment sysObjName={sysObjects.barrier}
                sysObjRecordId={_id}
                buttonName={"Comments"}
                buttonClassName={"button-red mb-1 mr-1 text-xs"} />

                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>

                  {bar_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" && 
                  (bar_status === NOTIFICATION_STATUS_APPROVED || 
                   bar_status === NOTIFICATION_STATUS_REJECT || 
                   bar_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/edit/${_id}`,
                        state: { formMode: 'edit', archivedStatus: state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>
                  }
                </div>


              {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                (bar_status === NOTIFICATION_STATUS_APPROVED ||
                  bar_status === NOTIFICATION_STATUS_REJECT ||
                  bar_status === NOTIFICATION_STATUS_REVISION) &&
                <Link
                  to="#"
                  className="button-red mb-1 mr-1 text-xs"
                  onClick={() => {
                    archiveManager(_id, bar_archived, bar_archived_id, bar_archived_date, user_id)
                  }}
                >
                  {bar_archived ? 'Unarchive' : 'Archive'}
                </Link>
              }

              {bar_status !== NOTIFICATION_STATUS_REJECT &&
                <TableDropDown title="More" items={dropdownListItems({
                  dutyHolderId: id,
                  assetGroupId: assetGroupId,
                  installationId: installationId,
                  barrierId: _id
                })} />}

              {bar_status === NOTIFICATION_STATUS_REJECT && <Link
                to="#"
                className="button-red mr-1 text-xs"
                style={{ textDecorationLine: 'line-through' }}
              >
                More
              </Link>
              }

            </div>
          )
        }
      },
    ],
    []
  )

  if (barByInstIdLoading) {
    return (
      <PageSpinner/>
    )
  }

  if (barByInstIdError) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Barriers <FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)}/></h1>

        {showHelp &&
        <div>
          {showArchivedBarriers === false ?
          <div>
            <p className="mt-3">A table of active Barriers, each entry can be viewed, edited and archived.  Click 'More' for additional options.</p>
            <p>Add a new Barrier by clicking 'Add Barrier'.</p>
          </div>
          :
            <div>
              <p className="mt-3">A table of archived Barriers, each entry can be viewed and unarchived.  Click 'More' for additional options.</p>
            </div>
          }
        </div>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <BreadCrumb payload={JSON.stringify({
        dh_id: id,
        ag_id: assetGroupId,
        installation_id: installationId,
        last_crumb: 'Barriers'
      })}/>

      <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installations`}
            className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
                                                                        className="text-white"/> Back</Link>

      {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" &&
        <div className="inline">
          {assetGroupId !== undefined && installationId !== undefined &&
          <Link
            to={{
              pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/add`,
              state: {formMode: 'add', archivedStatus: state}
            }}
            className="button-red mr-2 mb-2 py-3 px-5"
          >
            Add Barrier
          </Link>
          }
          {assetGroupId !== undefined && installationId === undefined &&
          <Link
            to={{
              pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/barrier/add`,
              state: {formMode: 'add', archivedStatus: state}
            }}
            className="button-red mr-2 mb-2 py-3 px-5"
          >
            Add Barrier
          </Link>
          }
        </div>
      }

      <Link to={{
        pathname: showArchivedBarriers ? url : (url + "/archived"),
        state: showArchivedBarriers ? "" : "archived"
      }}
            className="button-red mr-2 mb-2 py-3 px-5"
            onClick={() => {
              setShowArchivedBarriers(!showArchivedBarriers)
            }}
            style={{backgroundColor: showArchivedBarriers ? '#a70000' : ''}}
      >
        {showArchivedBarriers ? "Active Barriers" : "Archived Barriers"}

      </Link>

      <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barriers/histories`} className="button-red mb-2 mr-2 py-3 px-5">History</Link>


      {barrierList.length !== 0 ?
        <Table columns={columns} data={barrierList}/>
        : <h4 className="mt-4">There are currently no Barriers</h4>}

    </div>
  )
}

function formatBarriersData(barrierList, showArchivedBarriers, userId, state, userTypeAndRoleObject) {
  let newBarrierList = [];

  if (barrierList && barrierList.length && userId !== "") {
    let tempBarrierList = barrierList.filter(bar => showArchivedBarriers ? bar.bar_archived === true : bar.bar_archived !== true);
    tempBarrierList.forEach(bar => {
      let formatedStatus = capitaliseFirstCase(bar.bar_status);
      let newBarrier = { ...bar, status: formatedStatus, user_id: userId, state: state, userTypeAndRoleObject: userTypeAndRoleObject };
      newBarrierList.push(newBarrier);
    })
  }
  ;

  return newBarrierList;
}

export default Barriers;