import {PublicClientApplication, BrowserCacheLocation } from "@azure/msal-browser";
const {
    REACT_APP_URL,
    REACT_APP_CLIENT_ID,
    REACT_APP_NAMES_SIGNUPSIGNIN,
    REACT_APP_NAMES_FORGOTPASSWORD,
    REACT_APP_AUTHORITY,
    REACT_APP_KNOWN_AUTHORITIES,
    REACT_APP_READ_SCOPE,
    REACT_APP_WRITE_SCOPE } = process.env;

export const b2cPolicies = {
    names: {
        signUpSignIn: REACT_APP_NAMES_SIGNUPSIGNIN,
        forgotPassword: REACT_APP_NAMES_FORGOTPASSWORD
    },
    authorities: {
        signUpSignIn: {
            authority: `${REACT_APP_AUTHORITY}/${REACT_APP_NAMES_SIGNUPSIGNIN}`,
        },
        forgotPassword: {
            authority: `${REACT_APP_AUTHORITY}/${REACT_APP_NAMES_FORGOTPASSWORD}`,
        },
    },
    knownAuthorities: [REACT_APP_KNOWN_AUTHORITIES]
}

// Msal Configurations
const msalConfig = {
    auth: {
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        clientId: REACT_APP_CLIENT_ID,
        redirectUri: REACT_APP_URL,
        knownAuthorities: b2cPolicies.knownAuthorities,
        validateAuthority: false,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: false
    }
};

export const loginRequest = {
    scopes: [
        'openid',
        'profile',
        REACT_APP_READ_SCOPE,
        REACT_APP_WRITE_SCOPE
    ],
};

export const tokenRequest = {
    scopes: [
        REACT_APP_READ_SCOPE,
        REACT_APP_WRITE_SCOPE
    ],
    //redirectUri: window.location.origin + '/auth.html'
}

// Options, May need to be removed
//const options = {
//    loginType: InteractionType.Redirect,
//    tokenRefreshUri: window.location.origin + '/auth.html'
//}

export const msalInstance = new PublicClientApplication(msalConfig);