/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from "../global/pageSpinner";
import ReactDiffViewer from 'react-diff-viewer';
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {omit} from "lodash";
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils";

const USER_HISTORY_DIFF_QUERY = gql`
      query UserhistoryDiff($id: String!, $parentId: String!){
          user_history_diff(_id: $id, parentId: $parentId) {
              user_ms_oid
              user_duty_holder
              user_type {
                  _id
                  user_type_display_name
              }
              user_duty_holder_location
              user_email
              user_fname
              user_lname
              user_job_title
              user_location
              user_office_tel
              user_mobile
              user_child_id
              user_added_date
              user_added_id
              user_archived
              user_notes
              user_status
              history_modified_date
              history_modifying_user_id {
                  _id
              }
          }
      }
  `;

  const USER_QUERY = gql`
      query User($modifyingUserId: ID!){
          user(_id: $modifyingUserId) {
              user_location
              user_ms_oid
              user_email
              user_fname
              user_job_title
              user_lname
              user_mobile
              user_notes
              user_office_tel
              user_profile_image
              user_type {
                  _id
                  user_type_display_name
              }
          }
      }
  `;

const UserHistoryDiff = () => {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [userHistoryDiff, setUserHistoryDiff] = useState([{}, {}]);
  const [userObj, setUserObj] = useState({})

  const { id = "", parentId = "" } = useParams();

  const [user_history_diff, { loading, error, data}] = useLazyQuery(USER_HISTORY_DIFF_QUERY);
  const [user, { data: userData}] = useLazyQuery(USER_QUERY);

  useEffect(() => {
  if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
  }
  }, [authContext]);

  useEffect(() => {
  if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
  }
  }, [userTypeAndRoleObject]);

  useEffect(() => {
    user_history_diff({ variables: { id, parentId }, errorPolicy: 'all' });
  }, [])

  useEffect(() => {
    if (data && data.user_history_diff) {
      let histDif = data.user_history_diff;
      let firstHistDif = histDif[0];
      const { history_modifying_user_id } = firstHistDif ? firstHistDif : {};
      let modUserId = history_modifying_user_id && history_modifying_user_id._id ? history_modifying_user_id._id : "";

      setUserHistoryDiff(histDif);

      if (modUserId !== "") {
        user({ variables: { modifyingUserId: modUserId }, errorPolicy: 'all' });
      }
    }
  }, [data])

  useEffect(() => {
    if(userData && userData.user) {
      setUserObj(userData.user)
    }
  }, [userData])


  if (loading) {
    return (
      <PageSpinner/>
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the diff data</span>
    )
  }


  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">User History Change</h1>
      </div>

      {/*
      <Link to={dhId && dhId !== "" ? `/dutyHolder/${dhId}/users/histories` : "/users/histories"} className="mr-2 button-red"><FontAwesomeIcon
        icon={['fas', 'angle-left']} /> Back</Link> */}

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Modifying User</h3>

      <img src={userObj.user_profile_image && userObj.user_profile_image} alt={`${userObj.user_fname && userObj.user_fname}  ${userObj.user_lname && userObj.user_lname}`}
        className="mt-6 rounded-full w-24 max-w-full h-auto align-middle" />

      <div className="w-full lg:w-1/2">

        <div className="my-6 pb-6 border-b-2 border-gray-200">
          <h2 className="font-bold text-2xl text-blue-900">{userObj.user_fname && userObj.user_fname} {userObj.user_lname && userObj.user_lname}</h2>
          <h3 className="mb-3 font-bold text-red-900">{userObj.user_job_title && userObj.user_job_title}</h3>

          <p>Email: {userObj.user_email && userObj.user_email}</p>
          <p>Tel: {userObj.user_office_tel && userObj.user_office_tel}</p>
          <p>Mobile: {userObj.user_mobile && userObj.user_mobile}</p>
        </div>
      </div>

      <div className="mt-6">
        <ReactDiffViewer
          oldValue={JSON.stringify(omit(userHistoryDiff[1], ['__typename', 'history_modifying_user_id']), null, 2)}
          newValue={JSON.stringify(omit(userHistoryDiff[0], ['__typename', 'history_modifying_user_id']), null, 2)}
          splitView={true}
        />
      </div>

    </div>
  )

}

export default UserHistoryDiff;
