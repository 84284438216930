/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useContext} from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';

import {AuthContext} from "../../context/authContext";
import { NavContext } from '../../context/navContext';

import {parseJSONSafely, createUserTypeAndRoleObject} from "../../utils";

const {REACT_APP_FILE_MANAGER_API} = process.env;

const ASSETGROUP_QUERY = gql`
query AssetGroup($assetGroupId: ID!){
    assetGroup(_id: $assetGroupId) {
        dh_id{
            _id
            dh_name
        }
        ag_attachments
        ag_attmnts_dest_path
        ag_title
        ag_notes
        ag_status
    }
}
`;

const ASSETGROUP_VIEW_PAGE_DATA_QUERY = gql`
query AssetGroupViewData($agId: ID!, $instId: ID) {
  assetGroupViewData(agId: $agId, instId: $instId) {
        assetGroup{
          _id
          ag_title
        }
        installation{
          _id
          installation_title
        }
        barriers{
          _id
          bar_title
          installation_id{
            _id
          }
        }
        seces{
          _id
          sece_title
          sece_client_ref
          installation_id{
            _id
          }
          bar_id{
            _id
          }
        }
        performanceStandards{
          _id
          ps_title
          ps_ref
          installation_id{
            _id
          }
          bar_id{
            _id
          }
          sece_id{
            _id
          }
        }
    }
}`;

const ViewAssetGroup = (props) => {
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const [assetGroupObject, setAssetGroupObject] = useState({});
  const [agViewDataList, setAgViewDataList] = useState([]);
  const [agAttachments, setAgAttachments] = useState([]);

  const [drDnDutyholder, setDrDnDutyholder] = useState({});
  const [drDnInstallation, setDrDnInstallation] = useState({});
  const [drDnSece, setDrDnSece] = useState({});

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const history = useHistory();

  const { id, assetGroupId = "" } = useParams();
  const { location: { state = "" } } = props ? props : {};

  const [assetGroup, { loading: agLoading, error: agError, data: agData }] = useLazyQuery(ASSETGROUP_QUERY);
  const [assetGroupViewData, { loading: agViewDataLoading, error: agViewDataError, data: agViewData }] = useLazyQuery(ASSETGROUP_VIEW_PAGE_DATA_QUERY);

  useEffect(() => {
    if (id !== "" && assetGroupId !== "") {
      assetGroup({ variables: { assetGroupId: assetGroupId }, errorPolicy: 'all' });
      assetGroupViewData({ variables: { 
        agId: assetGroupId,
        instId:  drDnInstallation.id ? drDnInstallation.id : ""
       }, errorPolicy: 'all' });
    }
  }, []);

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    const { dutyHolder, installation, sece } = navContext;
    const { id: dutyHolderId } = dutyHolder ?? {};
    const { id: prevId } = drDnInstallation;

    if (prevId && installation.id && prevId !== installation.id) {
        if (dutyHolderId && dutyHolderId !== "") {
            history.push(`/dutyholder/${dutyHolderId}/asset-groups`);
        } else {
            history.push('/');
        }      
    }

    setDrDnDutyholder(dutyHolder);
    setDrDnInstallation(installation);
    setDrDnSece(sece);
  }, [navContext]);

  useEffect(() => {
    if (id !== "" && assetGroupId !== "") {
      assetGroup({ variables: { assetGroupId: assetGroupId }, errorPolicy: 'all' });
      assetGroupViewData({ variables: { 
        agId: assetGroupId,
        instId:  drDnInstallation.id ? drDnInstallation.id : ""
      }, errorPolicy: 'all' });
    }
  }, [drDnDutyholder, drDnInstallation, drDnSece]);

  useEffect(() => {
    if (agData && agData.assetGroup) {
      let assetGroup = agData.assetGroup;
      let attachments = assetGroup.ag_attachments && assetGroup.ag_attachments !== "" ? assetGroup.ag_attachments : "[]";
      let attachmentList = parseJSONSafely(attachments) ? parseJSONSafely(attachments) : [];
      setAgAttachments(attachmentList);
      setAssetGroupObject(assetGroup);
    }
  }, [agData]);

  useEffect(() => {
    if (agViewData && agViewData.assetGroupViewData) {
      setAgViewDataList(agViewData.assetGroupViewData);
    }
  }, [agViewData]);

  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 100, 40, 24],
    header: function (currentPage, pageCount) {
      var headerObj;
      if (currentPage % 2) {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['75%', '25%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: assetGroupObject.dh_id && assetGroupObject.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: assetGroupObject.ag_title + ' Details', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      } else {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['25%', '75%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: assetGroupObject.dh_id && assetGroupObject.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: assetGroupObject.ag_title + ' Details', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      }

      return headerObj;
    },
    footer: function (currentPage, pageCount) {

      var footerObj;

      if (currentPage % 2) {
        footerObj = {
          table: {
            widths: [30, '*', 100, 28],
            body: [
              [
                { text: ' ', border: [false, false, false, false] },
                { text: 'ASSET GROUP DETAILS', style: 'footerText', border: [false, false, false, false] },
                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                { text: ' ', border: [true, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      } else {
        footerObj = {
          table: {
            widths: [30, 100, '*', 28],
            body: [
              [
                { text: ' ', border: [false, false, true, false] },
                { text: currentPage.toString(), border: [true, false, false, false] },
                { text: `Copyright © by ABS. All Rights Reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                { text: ' ', border: [false, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      }

      return footerObj;

    },
    info: {
      title: assetGroupObject.dh_id && assetGroupObject.dh_id.dh_name + ' - ' + assetGroupObject.ag_title + ' - Details',
    },
    content: [

      {
        table: {
          widths: ['25%', '*'],
          headerRows: 0,
          body: [
            [{ text: 'Asset Group', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'] }, assetGroupObject.ag_title],
            [{ text: 'Duty Holder', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'] }, assetGroupObject.dh_id && assetGroupObject.dh_id.dh_name],
            [{ text: 'Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'] }, assetGroupObject.ag_status && assetGroupObject.ag_status],
          ]
        },
        style: 'standardTable',
      },
      ...(agViewDataList.length > 0 ? agViewDataList.map((item, index) =>
      ({
        table: {
          widths: ['100%'],
          headerRows: 0,
          body: [
            [{ text: 'Installation', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff']}],
            [{ text: item&&item.installation&&item.installation.installation_title  }],
            [{ text: 'Barriers', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff']}],
            [{ text: item.barriers.length > 0 ? item.barriers.map((bar, index) =>
              ({ text: `${bar && bar.bar_title} \n` }),
            ) : {text: "N/A"}
            }],
            [{ text: 'SECEs', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff']}],
            [{ text: item.seces.length > 0 ? item.seces.map((sece, index) =>
              ({ text: `${sece && sece.sece_client_ref} - ${sece && sece.sece_title} \n` }),
            ) : {text: "N/A"}
          }],
          [{ text: 'Performance Standards', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff']}],
            [{ text: item.performanceStandards.length > 0 ? item.performanceStandards.map((ps, index) =>
              ({ text:  `${ps && ps.ps_ref} - ${ps && ps.ps_title} \n` }),
            ) : {text: "N/A"}
          }]
        ]
        },
        style: 'standardTable',
      })
      ) : ""),
      {
        table: {
          widths: ['100%'],
          headerRows: 0,
          body: [
            [{ text: 'Attachments', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
            [{
              text: [
                {
                  text: agAttachments.length ? agAttachments.map(att => {
                    return (
                      "File Name: \n" +
                      att.name + '\n' +
                      "File Description: \n" +
                      att.description + '\n \n'
                    )
                  }) : "No Attachments to Display"
                },
              ]
            }],
          ]
        },
        style: 'standardTable',
      }

    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1.2
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }

  const pdfTab = () => {
    pdfMake.createPdf(docDefinition).open();
  }

  const displayBackLink = (state, id) => {
    if (state && state.origin && state.origin === "notifications") {
      return (
        <Link to={{
          pathname: `/notifications`
        }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
      )
    };
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-groups` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  }

  if (agLoading || agViewDataLoading) {
    return (
      <PageSpinner />
    )
  }

  if (agError || agViewDataError) {
    return (
      <span>Something went wrong retrieving the asset group data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{assetGroupObject.ag_title}</h1>
      </div>

      {displayBackLink(state, id)}
      <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      <div className="w-full lg:w-1/2">

        <div className="my-6 pb-6 border-b-2 border-gray-200">
          <h2 className="font-bold text-2xl text-blue-900">{assetGroupObject.ag_title}</h2>
          <h3 className="mb-3 font-bold text-red-900">{assetGroupObject.dh_id && assetGroupObject.dh_id.dh_name}</h3>

          {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" && 
            <p>{assetGroupObject.ag_notes}</p>
          }

          <p className="mt-4"><strong>Status:</strong> {assetGroupObject.ag_status}</p>
        </div>

        {agViewDataList.length > 0 ? agViewDataList.map((item, index) =>
          <table className="w-full mb-10 table-auto" key = {index}>
            <tbody>
              <tr>
                <th className="p-3 text-left bg-blue-900 text-white">Installation</th>
              </tr>
              <tr>
                <td className="p-3 border"><Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${item&&item.installation&&item.installation._id}/view`} className="font-sans text-blue-900 hover:underline">{item&&item.installation&&item.installation.installation_title}</Link></td>
              </tr>
              <tr>
                <th className="p-3 text-left bg-blue-900 text-white">Barriers</th>
              </tr>
              <tr>
                <td className="p-3 border">
                  {item && item.barriers && item.barriers.length > 0 ? item.barriers.map((bar, index) =>
                    <React.Fragment key={index}>
                      <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${item&&item.installation&&item.installation._id}/barrier/view/${bar && bar._id}`} className="font-sans text-blue-900 hover:underline">{bar && bar.bar_title}</Link>
                      <br />
                    </React.Fragment>
                  ) : "N/A"
                  }
                </td>
              </tr>
              <tr>
                <th className="p-3 text-left bg-blue-900 text-white">SECEs</th>
              </tr>
              <tr>
                <td className="p-3 border">
                  {item && item.seces && item.seces.length > 0 ? item.seces.map((sece, index) =>
                    <React.Fragment key={index}>
                      <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${item&&item.installation&&item.installation._id}/barrier/${sece && sece.bar_id && sece.bar_id._id}/sece/view/${sece && sece._id}`} className="font-sans text-blue-900 hover:underline">{sece && sece.sece_client_ref} - {sece && sece.sece_title}</Link>
                      <br />
                    </React.Fragment>
                  ) : "N/A"
                  }
                </td>
              </tr>
              <tr>
                <th className="p-3 text-left bg-blue-900 text-white">Performance Standards</th>
              </tr>
              <tr>
                <td className="p-3 border">
                  {item && item.performanceStandards && item.performanceStandards.length > 0 ? item.performanceStandards.map((ps, index) =>
                    <React.Fragment key={index}>
                      <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${item&&item.installation&&item.installation._id}/barrier/${ps && ps.bar_id && ps.bar_id._id}/sece/${ps && ps.sece_id && ps.sece_id._id}/performance-standard/view/${ps && ps._id}`} className="font-sans text-blue-900 hover:underline">{ps && ps.ps_ref} - {ps && ps.ps_title}</Link>
                      <br />
                    </React.Fragment>
                  ) : "N/A"
                  }
                </td>
              </tr>
            </tbody>
          </table>) :
          <table className="w-full mb-10 table-auto">
            <tbody>
              <tr>
                <th className="p-3 text-left bg-blue-900 text-white">Installations</th>
              </tr>
              <tr>
                <td className="p-3 border">{"No Data To Display"}</td>
              </tr>
            </tbody>
          </table>
        }

        <table className="w-full mb-10 table-auto">
          <tbody>
            <tr>
              <th className="p-3 text-left bg-blue-900 text-white">Attachments</th>
            </tr>
            <tr>
              <td className="p-3 border">
                {createFileLinks(agAttachments, assetGroupObject)}
              </td>
            </tr>
          </tbody>
        </table>

        {displayBackLink(state, id)}
        <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      </div>

    </div>
  )

}

function createFileLinks(attachments, assetGroupObject) {
  let linkList = "";
  let fileDestPath = assetGroupObject.ag_attmnts_dest_path ? assetGroupObject.ag_attmnts_dest_path : "";
  if (attachments && attachments.length && fileDestPath !== "") {
    let fullPath = `${REACT_APP_FILE_MANAGER_API}/download-file?path=/${fileDestPath}/`;
    let numberOfAttachments = attachments.length;

    let newAttachments = <React.Fragment>{attachments.map((att, index) =>
      <React.Fragment key={index}>
        <div className="text-blue-900 font-sans text-sm font-bold">File Name:</div>
        <a href={fullPath + att.name} className="mb-1 hover:underline" key={index} target={"_blank"} rel="noopener noreferrer" download={true}>
          {att.name}{((index + 1) < numberOfAttachments) ? <br /> : ""}
        </a>
        <div className="text-blue-900 font-sans text-sm font-bold">File Description:</div>
        <div>
          {att.description}
        </div>
        {((index + 1) < numberOfAttachments) ? <br /> : ""}
      </React.Fragment>
    )}
    </React.Fragment>
    linkList = newAttachments;
  } else {
    linkList = <Link to="#" className="hover:underline">No File Attachment</Link>
  }
  return linkList;
}

export default ViewAssetGroup;
