/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { omit } from 'lodash';
import { AuthContext } from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils";

import makeAnimated from 'react-select/animated';

const compCodeRegExp = /^([A-Z]{3})([-]{1})([A-Z]{1,10})$/

const validation = (compsByCodeCount, compsByNameCount) => {
    const validation = Yup.object().shape({
        comp_code_exist: Yup.boolean().default(compsByCodeCount > 0),
        comp_code: Yup.string()
            .matches(compCodeRegExp, 'Must follow the format ABC-EF..')
            .label("comp_code")
            .required("Competency Code is required")
            .when('comp_code_exist', {
                is: true,
                then: Yup.string().test("comp_code", "Code already exists", function (value) {
                    const { path, createError } = this;
                    return createError({ path, message: "Code already exists" });
                }),
            }),
        comp_name_exist: Yup.boolean().default(compsByNameCount > 0),
        comp_title: Yup.string()
            .label("comp_title")
            .required("Name is required")
            .when('comp_name_exist', {
                is: true,
                then: Yup.string().test("comp_title", "Name already exists", function (value) {
                    const { path, createError } = this;
                    return createError({ path, message: "Name already exists" });
                }),
            }),
        comp_cat_id: Yup.string().label("comp_cat_id").required("Category is required"),
        comp_levels: Yup.string().label("comp_levels").required("Level is required"),
    })
    return validation;
}

const CREATE_COMPETENCY = gql`
    mutation CreateCompetency($comp: CreateCompetency!) {
        createCompetency(comp: $comp) {
            _id
            comp_code
            comp_title
            comp_notes
            success
            status_code
            message
        }
    }
`;

const UPDATE_COMPETENCY = gql`
    mutation UpdateCompetency($compId:String!, $userId: String, $comp:UpdateCompetencyInput!) {
        updateCompetency(_id: $compId, userId: $userId, comp: $comp) {
            comp_code
            comp_title
            comp_notes
            success
            status_code
            message
        }
    }
`;

const COMPETENCYCATEGORY_QUERY = gql`
query CompetencyCategories($isArchived:Boolean!){
    competencyCategories(isArchived: $isArchived){
        _id
        comp_cat_code
        comp_cat_title
        comp_cat_notes
    }
}`;

const COMPETENCY_QUERY = gql`
query Competency($compId: ID!){
    competency(_id: $compId) {
        comp_code
        comp_title
        comp_notes
        comp_level
        comp_levels{
          _id
          sysvar_title
        }
        comp_cat_id{ 
          _id
          comp_cat_title
        }
    }
}`;

const SYSTEM_VARIABLES_TYPE_QUERY = gql`
query SystemVariableTypes($isArchived:Boolean!){
    systemVariableTypes(isArchived:$isArchived) {
        _id
        sysvartype_title
    }
}`;

const SYSTEM_VARIABLES_QUERY = gql`
query SystemVariablesBySysVarTypeId($isArchived:Boolean!, $sysVarTypeId: String!){
    systemVariablesBySysVarTypeId(isArchived:$isArchived, sysVarTypeId: $sysVarTypeId) {
        _id
        sysvar_title
    }
}`;

const COMPETENCIES_BY_CODE_QUERY = gql`
query CompetenciesByCode($code: String!){
    competenciesByCode(code: $code)
}`;

const COMPETENCIES_BY_NAME_QUERY = gql`
query CompetenciesByName($name: String!){
    competenciesByName(name: $name)
}`;

const AddEditCompetency = (props) => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [showHelp, setShowHelp] = useState(false);

    const [categories, setCategories] = useState([]);
    const [levels, setLevels] = useState([]);

    const [compsByCodeCount, setCompsByCodeCount] = useState(undefined);
    const [enteredCode, setEnteredCode] = useState(undefined);

    const [compsByNameCount, setCompsByNameCount] = useState(undefined);
    const [enteredName, setEnteredName] = useState(undefined);

    const [createCompetency, { data: createCompetencyData }] = useMutation(CREATE_COMPETENCY);
    const [updateCompetency, { data: updateCompetencyData }] = useMutation(UPDATE_COMPETENCY);

    const [competencyCategories, { data: compCatsData }] = useLazyQuery(COMPETENCYCATEGORY_QUERY);
    const [competency, { data: competencyData }] = useLazyQuery(COMPETENCY_QUERY);
    const [systemVariableTypes, { data: sysVarTypesData }] = useLazyQuery(SYSTEM_VARIABLES_TYPE_QUERY);
    const [systemVariablesBySysVarTypeId, { data: sysVarsData }] = useLazyQuery(SYSTEM_VARIABLES_QUERY);

    const [competenciesByCode, { data: competenciesByCodeData }] = useLazyQuery(COMPETENCIES_BY_CODE_QUERY);
    const [competenciesByName, { data: competenciesByNameData }] = useLazyQuery(COMPETENCIES_BY_NAME_QUERY);

    const { formMode, compId = "" } = useParams();
    const { location: { state = "" } } = props ? props : {};


    const [formInitValues, setFormInitValues] = useState({
        comp_code: "",
        comp_title: "",
        comp_cat_id: "",
        comp_level: "",
        comp_levels: [],
        comp_notes: ""
    });

    useEffect(() => {
        if (authContext && authContext.user){
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);
    

    useEffect(() => {
        competencyCategories({ variables: { isArchived: false }, errorPolicy: 'all' });
        systemVariableTypes({ variables: { isArchived: false }, errorPolicy: 'all' });
        if (formMode === "edit" && compId !== "") {
            competency({ variables: { compId }, errorPolicy: 'all' });
        }
    }, []);

    useEffect(() => {
        if (compCatsData && compCatsData.competencyCategories) {
            setCategories(compCatsData.competencyCategories);
        }
    }, [compCatsData]);

    useEffect(() => {
        if (sysVarTypesData && sysVarTypesData.systemVariableTypes) {
            let sysVarTypeId = sysVarTypesData.systemVariableTypes.find(sys => sys.sysvartype_title === "Competency Level")
            if (sysVarTypeId && sysVarTypeId._id) {
                systemVariablesBySysVarTypeId({ variables: { isArchived: false, sysVarTypeId: sysVarTypeId._id }, errorPolicy: 'all' });
            }
        }
    }, [sysVarTypesData]);

    useEffect(() => {
        if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeId) {
            let levelData = [];
            sysVarsData.systemVariablesBySysVarTypeId.forEach(level => {
                let temVal = { value: level._id, label: level.sysvar_title };
                levelData.push(temVal);
            });
            setLevels(levelData)
        }
    }, [sysVarsData]);

    useEffect(() => {
        if (competencyData && competencyData.competency) {
            let competency = competencyData.competency;

            let returnedLevels = competency.comp_levels && competency.comp_levels.length ? competency.comp_levels : []
            let formatedLevels = [];

            returnedLevels.forEach(level => {
                let temVal = { value: level._id, label: level.sysvar_title };
                formatedLevels.push(temVal);
            });

            setFormInitValues({
                comp_cat_id: competency.comp_cat_id ? competency.comp_cat_id._id : "",
                comp_code: competency.comp_code,
                comp_level: competency.comp_level,
                comp_levels: formatedLevels,
                comp_notes: competency.comp_notes,
                comp_title: competency.comp_title
            });
        }
    }, [competencyData])

    useEffect(() => {
        if (createCompetencyData && createCompetencyData.createCompetency) {
            const {success, message} = createCompetencyData.createCompetency;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push('/user-competency/competencies');
            }        
        }
    }, [createCompetencyData, history]);

    useEffect(() => {
        if (updateCompetencyData && updateCompetencyData.updateCompetency) {
            const {success, message} = updateCompetencyData.updateCompetency;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push('/user-competency/competencies');
            }          
        }
    }, [updateCompetencyData, history]);

    useEffect(() => {
        if (competenciesByCodeData) {
            if (formMode === "edit" && enteredCode === formInitValues.comp_code) {
                setCompsByCodeCount(0);
            } else {
                setCompsByCodeCount(competenciesByCodeData.competenciesByCode);
            }
        }
    }, [competenciesByCodeData]);

    useEffect(() => {
        if (competenciesByNameData) {
            if (formMode === "edit" && enteredName === formInitValues.comp_title) {
                setCompsByNameCount(0);
            } else {
                setCompsByNameCount(competenciesByNameData.competenciesByName);
            }
        }
    }, [competenciesByNameData]);

    const displayBackLink = (state) => {
        return (
            <Link to={{
                pathname: "/user-competency/competencies" + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    }

    const handleCompCodeChange = (event) => {
        let code = event.target.value;
        setEnteredCode(code);
        competenciesByCode({ variables: { code: code }, errorPolicy: 'all' });
    }

    const handleCompNameChange = (event) => {
        let name = event.target.value;
        setEnteredName(name);
        competenciesByName({ variables: { name: name }, errorPolicy: 'all' });
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Competency <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                <div>
                    <p>Enter the details into the required fields, including adding attachments by clicking on 'Attach Files'.</p>
                    <p>Once complete, click '<span className="capitalize">{formMode}</span> Competency'.</p>
                </div>
                }
                
            </div>

            <Formik
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={validation(compsByCodeCount, compsByNameCount)}
                onSubmit={(values, actions) => {
                    const { _id: userId = "" } = authContext.user;
                    const tempValues = { ...values };

                    let compLevelIds = formatBackToIdList(tempValues.comp_levels);

                    tempValues.comp_levels = compLevelIds;

                    if (formMode === 'add') {
                        tempValues.comp_added_id = userId;
                        tempValues.comp_added_date = new Date();
                        createCompetency({ variables: { comp: tempValues } })
                    }

                    if (formMode === 'edit') {
                        updateCompetency({ variables: { compId, userId: userId, comp: tempValues } })
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="my-4">
                            {displayBackLink(state)}
                        </div>

                        <div>
                            <label htmlFor="comp_code" className="block mb-1 text-blue-900 font-semibold">Code*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="comp_code"
                                id="comp_code"
                                placeholder="Code"
                                onChange={(event) => { handleCompCodeChange(event); handleChange(event) }}
                                onBlur={handleBlur}
                                value={values.comp_code}
                            />
                            <FormError touched={touched.comp_code} message={errors.comp_code} />
                        </div>

                        <div>
                            <label htmlFor="comp_title" className="block mb-1 text-blue-900 font-semibold">Name*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="comp_title"
                                id="comp_title"
                                placeholder="Name"
                                onChange={(event) => { handleCompNameChange(event); handleChange(event) }}
                                onBlur={handleBlur}
                                value={values.comp_title}
                            />
                            <FormError touched={touched.comp_title} message={errors.comp_title} />
                        </div>

                        <div>
                            <label htmlFor="comp_cat_id" className="block mb-1 text-blue-900 font-semibold">Category*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="comp_cat_id"
                                id="comp_cat_id"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.comp_cat_id}
                            >
                                <option value = {""}>Select Category</option>
                                {categories.map(cat =>
                                    <option value={cat._id} key={cat._id}>{cat.comp_cat_title}</option>
                                )}
                            </select>
                            <FormError touched={touched.comp_cat_id} message={errors.comp_cat_id} />
                        </div>

                        <div>
                            <label htmlFor="comp_levels" className="block mb-1 text-blue-900 font-semibold">Levels*</label>
                            <Select
                                id="comp_levels"
                                name="comp_levels"
                                aria-label="Levels"
                                className="w-full md:w-1/2"
                                classNamePrefix="react-select"
                                options={levels}
                                isMulti
                                isSearchable
                                components={makeAnimated()}
                                closeMenuOnSelect={false}
                                placeholder="Select Levels"
                                onChange={levels => setFieldValue('comp_levels', (levels ? levels : []))}
                                onBlur={levels => setFieldTouched('comp_levels', (levels ? levels : []))}
                                value={values.comp_levels}
                            />
                            <FormError touched={touched.comp_levels} message={errors.comp_levels} />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="comp_notes" className="block mb-1 text-blue-900 font-semibold">Notes*</label>
                            <textarea
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="comp_notes"
                                id="comp_notes"
                                placeholder="Notes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.comp_notes}
                            />
                        </div>

                        <button type="submit" className="mt-3 button-red capitalize">{formMode} Competency</button>

                    </form>
                )}
            </Formik>

        </div>
    )

}

function formatBackToIdList(items) {
    let formatedList = [];
    if (items && items.length) {
        items.forEach(item => {
            let id = item.value;
            formatedList.push(id);
        });
    }
    return formatedList;
}

export default AddEditCompetency;