/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from "../global/pageSpinner";
import ReactDiffViewer from 'react-diff-viewer';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {omit} from "lodash";

const ASSET_GROUP_HISTORY_DIFF_QUERY = gql`
query AssetGroupHistoryDiff($dhId: String!, $id: String!, $parentId: String!){
    asset_group_history_diff(dhId: $dhId, _id: $id, parentId: $parentId) {
        ag_title
        ag_notes
        ag_child_id
        ag_added_date
        ag_added_id
        ag_status
        history_parent_record_id
        history_modifying_user_id {
            _id
            user_fname
            user_lname
            user_email
        }
        history_modified_date
        history_modified_date_timestamp
    }
}
`;

const USER_QUERY = gql`
query User($modifyingUserId: ID!){
    user(_id: $modifyingUserId) {
        user_location
        user_ms_oid
        user_email
        user_fname
        user_job_title
        user_lname
        user_mobile
        user_notes
        user_office_tel
        user_profile_image
        user_type {
            _id
            user_type_display_name
        }
    }
}
`;

const AssetGroupHistoryDiff = () => {
  const [assetGroupHistoryDiff, setAssetGroupHistoryDiff] = useState([{}, {}]);
  const [modifyingUserId, setModifyingUserId] = useState('');
  const [userObj, setUserObj] = useState({})

  const {id = '', hisId = '', parentId = ''} = useParams();

  const [asset_group_history_diff, { loading, error, data}] = useLazyQuery(ASSET_GROUP_HISTORY_DIFF_QUERY);
  const [user, {data: userData }] = useLazyQuery(USER_QUERY);

  useEffect(() => {
    if (id !== "" && hisId !== "" && parentId !== "") {
      asset_group_history_diff({ variables: { dhId: id, id: hisId, parentId: parentId }, errorPolicy: 'all' });
    }
  }, [])

  useEffect(() => {
    if (data && data.asset_group_history_diff) {
      let hisDiff = data.asset_group_history_diff;
      let firstHisDiff = hisDiff[0];
      let modUserId = firstHisDiff && firstHisDiff.history_modifying_user_id ? firstHisDiff.history_modifying_user_id._id : "";
      setAssetGroupHistoryDiff(hisDiff)
      setModifyingUserId(modUserId)
    }
  }, [data])

  useEffect(() => {
    if (modifyingUserId !== "") {
      user({ variables: { modifyingUserId }, errorPolicy: 'all' });
    }
  }, [modifyingUserId])

  useEffect(() => {
    if(userData && userData.user) {
      setUserObj(userData.user)
    }

  }, [userData])


  if (loading) {
    return (
      <PageSpinner/>
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the diff data</span>
    )
  }


  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Asset Group History Change</h1>
      </div>
      <Link to={`/dutyholder/${id}/asset-groups/histories`} className="mr-2 button-red"><FontAwesomeIcon
        icon={['fas', 'angle-left']}/> Back</Link>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Modifying User</h3>

      <img src={userObj.user_profile_image} alt={`${userObj.user_fname}  ${userObj.user_lname}`}
           className="mt-6 rounded-full w-24 max-w-full h-auto align-middle"/>

      <div className="w-full lg:w-1/2">

        <div className="my-6 pb-6 border-b-2 border-gray-200">
          <h2 className="font-bold text-2xl text-blue-900">{userObj.user_fname} {userObj.user_lname}</h2>
          <h3 className="mb-3 font-bold text-red-900">{userObj.user_job_title}</h3>

          <p>Email: {userObj.user_email}</p>
          <p>Tel: {userObj.user_office_tel}</p>
          <p>Mobile: {userObj.user_mobile}</p>
        </div>
      </div>

      <div className="mt-6">
        <ReactDiffViewer
          oldValue={JSON.stringify(omit(assetGroupHistoryDiff[1], ['__typename', 'history_modifying_user_id']), null, 2)}
          newValue={JSON.stringify(omit(assetGroupHistoryDiff[0], ['__typename', 'history_modifying_user_id']), null, 2)}
          splitView={true}
        />
      </div>

    </div>
  )

}

export default AssetGroupHistoryDiff;
