import React, {useContext, useState, useEffect, useCallback} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import {useParams, useHistory} from 'react-router-dom';
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AuthContext} from "../../context/authContext";
import {gql, useMutation, useQuery} from '@apollo/client';
import {createUserTypeAndRoleObject} from "../../utils";

import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../global/ImageCropCanvasUtils'

const validation = (isInValidFileSize, isFileReadError) => {
  let validation = Yup.object().shape({
    us_image: Yup.string().label("us_image").required("Signature Image is required"),
    file_size_check: Yup.boolean().default(isInValidFileSize),
    file_size: Yup.string()
      .when('file_size_check', {
        is: true,
        then: Yup.string().test("file_size", "The file size is too large and cannot be uploaded. Please reduce the size of the file and try again. Max cropped file size is 60kb", function (value) {
          const { path, createError } = this;
          return createError({ path, message: "The file size is too large and cannot be uploaded. Please reduce the size of the file and try again. Max cropped file size is 60kb" });
        }),
      }),
    file_read_error_check: Yup.boolean().default(isFileReadError),
    file_read_error: Yup.string()
      .when('file_read_error_check', {
        is: true,
        then: Yup.string().test("file_read_error", "There was a problem reading the image file. Please reupload a valid imafe file and try again", function (value) {
          const { path, createError } = this;
          return createError({ path, message: "The file size is too large and cannot be uploaded. Please reupload a valid imafe file and try again" });
        }),
      }),
  });
  return validation;
}

const CREATE_SIGNATURE = gql`
    mutation AddSignature($sig: CreateUserSignature!) {
        createUserSignature(us: $sig) {
            _id
            us_added_date
            us_added_id
            us_image
            success
            status_code
            message
        }
    }
`;

const UPDATE_SIGNATURE = gql`
    mutation UpdateSignature($id: String!, $userId: String!, $sig: UpdateUserSignatureInput!) {
        updateUserSignature(_id: $id, userId: $userId, us: $sig) {
            _id
            us_added_date
            us_added_id
            us_image
            success
            status_code
            message
        }
    }
`;

const SIGNATURE_QUERY = gql`
    query Signature($userId: ID!){
        signature(_id: $userId) {
            _id
            us_added_date
            us_added_id
            us_archived
            us_image
        }
    }
`;

const USER_QUERY = gql`
    query User($userId: ID!){
        user(_id: $userId) {
            user_location
            user_ms_oid
            user_email
            user_fname
            user_job_title
            user_lname
            user_mobile
            user_notes
            user_office_tel
            user_profile_image
            user_type {
                _id
                user_type_display_name
            }
            user_duty_holder{
               _id
            }
            user_duty_holder_location{
              _id
            }
        }
    }
`;

const UserSignature = () => {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [signatureImage, setSignatureImage] = useState('');
  const [signatureId, setSignatureId] = useState(undefined);
  const [userFullName, setUserFullName] = useState('');
  const [isInValidFileSize, setIsInValidFileSize] = useState(false);
  const [isFileReadError, setIsFileReadError] = useState(false);
  const [croppedBase64Image, setCroppedBase64Image] = useState("");

  const [imageSrc, setImageSrc] = useState(null)
  const [showCropBox, setShowCropBox] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(0.5)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const {userId = ''} = useParams();

  useEffect(() => {
  if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
  }
  }, [authContext]);

  useEffect(() => {
  if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
  }
  }, [userTypeAndRoleObject, history]);

  const [createSignature, {data: createSignatureData}] = useMutation(CREATE_SIGNATURE);
  const [updateSignature, {data: updateSignatureData}] = useMutation(UPDATE_SIGNATURE);
  
  const {data: signatureData} = useQuery(SIGNATURE_QUERY, {
    variables: {userId},
    errorPolicy: 'all'
  });
  const {data: userData} = useQuery(USER_QUERY, {
    variables: {userId},
    errorPolicy: 'all'
  });

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
      )

      await reFormatImageData(croppedImage, setIsInValidFileSize, setCroppedBase64Image, setIsFileReadError);
      setCroppedImage(croppedImage);

    } catch (e) {
      alert.error(e)
    }
    setShowCropBox(false);
  }, [imageSrc, croppedAreaPixels])

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)

      setShowCropBox(true);
      setImageSrc(imageDataUrl);
      setIsInValidFileSize(false);
      setIsFileReadError(false);
    }
  }

  useEffect(() => {
    setSignatureImage(undefined);
    if (signatureData && signatureData.signature) {
      setSignatureImage(signatureData.signature.us_image);
      setSignatureId(signatureData.signature._id)
    }
  }, [signatureData])

  useEffect(() => {
    if (createSignatureData && createSignatureData.createUserSignature) {
      const { success, message } = createSignatureData.createUserSignature;
      if (!success) {
        alert(message);
      }
      if (success) {
        setSignatureImage(createSignatureData.createUserSignature.us_image);
        setSignatureId(createSignatureData.createUserSignature._id);
      }
    }
  }, [createSignatureData])

  useEffect(() => {
    if (updateSignatureData && updateSignatureData.updateUserSignature) {
      const { success, message } = updateSignatureData.updateUserSignature;
      if (!success) {
        alert(message);
      }
      if (success) {
        setSignatureImage(updateSignatureData.updateUserSignature.us_image);
        setSignatureId(updateSignatureData.updateUserSignature._id);
      }
    }
  }, [updateSignatureData])

  useEffect(() => {
    if (userData && userData.user) {
      setUserFullName(`${userData.user.user_fname} ${userData.user.user_lname}`)
    }
  }, [userData])

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-3 border-b-2 border-gray-200">
        <h1
          className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{signatureImage ? 'Edit' : 'Add'} Signature
          for {userFullName}</h1>
      </div>

      <Formik
        initialValues={{ us_image: "", file_size: "", file_read_error: "" }}
        validationSchema={validation(isInValidFileSize, isFileReadError)}
        onSubmit={(values, { resetForm }) => {
          resetForm({});
          if (croppedImage && croppedBase64Image !== "") {
            const sig = {
              us_user_id: userId,
              us_added_id: authContext.user._id,
              us_image: croppedBase64Image
            };

            if (!signatureImage) {
              createSignature({ variables: { sig: sig } })
            }

            if (signatureId) {
              updateSignature({ variables: { id: signatureId, userId: authContext.user._id, sig: sig } })
            }
            setCroppedImage(null);
            setCroppedBase64Image("");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          setFieldTouched,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>

            {/*
            <div className="my-4">
              <Link to={dhId && dhId !== "" ? `/dutyHolder/${dhId}/users` : "/users"} className="button-red"><FontAwesomeIcon icon={['fas', 'angle-left']}
                className="text-white" /> Back</Link>
            </div>
            */}

            {signatureImage &&
              <>
                <label className="block text-blue-900 font-semibold">Current Signature:</label>

                <img src={signatureImage} alt={`${userFullName} Signature`}
                  className="mt-1 mb-4 w-250 max-w-full h-auto" />
              </>
            }

            <div className="mt-6">
              <label htmlFor="us_image" className="block text-blue-900 font-semibold">Signature Image</label>
              <p className="mb-1 text-sm">Please make sure you signature fits inside the box.</p>

              <div className="block w-full md:w-1/2">

                <div>
                  <label htmlFor="profile-image-field" className="sr-only">Profile Image</label>
                  <input className="form-control block w-full md:w-1/2" type="file" id="profile-image-field" onChange={onFileChange} accept="image/*" />
                  <FormError touched={touched.us_image} message={errors.us_image} />
                  {isInValidFileSize && <FormError touched={touched.file_size} message={errors.file_size} />}
                </div>

                {showCropBox && (
                  <div className="mt-2">
                    <div style={{ position: 'relative', width: '100%', maxWidth: '800px', height: '600px' }}>
                      <Cropper
                        image={imageSrc}
                        crop={crop}
                        minZoom={0.5}
                        zoom={zoom}
                        aspect={4 / 3}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        cropSize={{ width: 400, height: 300 }}
                        restrictPosition={false}
                      />
                    </div>
                    <div className="my-3">
                      <input
                        type="range"
                        value={zoom}
                        min={0.5}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e) => setZoom(e.target.value)}
                      />
                      <br />
                      <button type="button"
                        onClick={() => { showCroppedImage(); setFieldValue('us_image', "true") }}
                        className="mt-3 button-red capitalize"
                      >
                        Crop Image
                      </button>
                    </div>

                  </div>
                )}

                {croppedImage && (
                  showCropBox === false && (
                    <div style={{ marginTop: '15px' }}>
                      <img src={croppedImage} alt="Signature" />
                    </div>
                  )
                )}

              </div>

            </div>

            <button type="submit" className="mt-3 button-red capitalize">{signatureImage ? 'Edit' : 'Add'} Signature</button>

          </form>
        )}
      </Formik>

    </div>
  )
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

const reFormatImageData = async (croppedImage, setIsInValidFileSize, setCroppedBase64Image, setIsFileReadError) => {
  if (croppedImage && croppedImage !== "") {
    let blob = await fetch(croppedImage).then(r => r.blob());
    const { size } = blob ? blob : {};
    if (size > 60000) {
      setIsInValidFileSize(true);
    }

    let reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = () => {
      let userSignature = reader.result;
      setCroppedBase64Image(userSignature);
    };

    reader.onerror = (error) => {
      setIsFileReadError(true);
      alert("There was a problem reading the image file", error);
    };
  }
}

export default UserSignature;
