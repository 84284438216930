/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import Sanitiser from "../global/sanitiser";
import DatePicker from 'react-datepicker';
import { Link , useHistory, useParams} from 'react-router-dom';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import TextEditor from '../../components/global/TextEditor';
import Table from '../global/Table';
import {omit} from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from '../global/pageSpinner';
import {AuthContext} from "../../context/authContext";
import {createLocationFrom} from "../findings/ViewFinding";
import {NavContext} from '../../context/navContext';

import {NATURE_SYSTEM_VARIABLE_TYPE_ID, 
        FREQUENCY_SYSTEM_VARIABLE_TYPE_ID, 
        PHASE_SYSTEM_VARIABLE_TYPE_ID, 
        STATEMENT_SYSTEM_VARIABLE_TYPE_ID, 
        ACTIVITY_STATUS_SYSTEM_VARIABLE_TYPE_ID, 
        ANSWERS_SYSTEM_VARIABLE_TYPE_ID, 
        QUESTIONS_SYSTEM_VARIABLE_TYPE_ID, 
        CONCLUSIVE_STATEMENT_FOR_SAVE_ID,
        NOTIFICATION_STATUS_OPEN,
        //ACTIVITY_STATUS_SYS_VAR_COMPLETED_ID,
        NOTIFICATION_STATUS_PENDING,
        NOTIFICATION_STATUS_APPROVED,
        FINDING_ORIGIN_REPORT,
        REPORT_ACTION_SAVE, 
        SINGLE_VA_FINDING_TYPE,
        REPORT_ACTION_REVIEW} from '../../constants/';

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import FileUpload from '../global/FileUpload';
import FileUploadAlt from '../global/FileUploadAlt';

import {
    parseJSONSafely, 
    updateAttachmentDataNotStringified, 
    confidenceLevelManager, 
    formatDateToLocaleDateString, 
    truncateString
} from "../../utils";

const {REACT_APP_FILE_MANAGER_API} = process.env;

const validation = (formMode, tempFindingListLength, reportAction) => {
    let validation = {};
    let verifyValObject = {
        report_barrier: Yup.string().label("report_barrier").required("Barrier is required"),
        //report_nature: Yup.string().label("report_nature").required("Nature is required"),
        //report_freq: Yup.string().label("report_freq").required("Frequency is required"),
        report_comp_sample: Yup.string().label("report_comp_sample").required("Completed Sample is required"),
        report_current_status: Yup.string().label("report_current_status").required("Current Status is required"),
            
        //is_review_report_action: Yup.boolean().default(reportAction === REPORT_ACTION_REVIEW),
        //report_current_status: Yup.string()
        //    .label("report_current_status")
        //    .required("Current Status is required")
        //    .when('is_review_report_action', {
        //        is: true,
        //        then: Yup.string().test("report_current_status", "Select \"Completed\" when forwarding for review", function (value) {
        //            const { path, createError } = this;
        //            return value !== ACTIVITY_STATUS_SYS_VAR_COMPLETED_ID ? createError({ path, message: "Select \"Completed\" when forwarding for review" }) : true;
        //        }),
        //    }),

        report_phase: Yup.string().label("report_phase").required("Phase is required"),

        report_prior_norm: Yup.number().min(0, "Must be a positive number").required("Prior Norm is required"),
        report_time_taken: Yup.number().min(0, "Must be a positive number").required("Time Taken is required"),

        report_justification: Yup.string().when(['report_prior_norm', 'report_time_taken'], {
            is: (report_prior_norm, report_time_taken) => report_prior_norm !== report_time_taken,
            then: Yup.string().label("report_justification").required("Justification is required"),
        }),

        report_verification_details: Yup.string().label("report_verification_details").required("Details of Verification Completed is required"),
        report_conclusive_statement: Yup.string().label("report_conclusive_statement").required("Conclusive Statement is required"),

        //report_findings_count: Yup.boolean().default(tempFindingListLength > 0),

        //report_findings: Yup.number()
        //    .when(['report_findings_count', 'report_conclusive_statement'], {
        //        is: (report_findings_count, report_conclusive_statement) => report_findings_count === false && report_conclusive_statement !== CONCLUSIVE_STATEMENT_FOR_SAVE_ID,
        //        then: Yup.number().test("report_findings", "Report must include at least one finding", function (value) {
        //            const { path, createError } = this;
        //            return createError({ path, message: "Report must include at least one finding" });
        //        }),
        //}),

        report_bck_acts_0: Yup.string().label("report_bck_acts_0").required("Answer is required"),
        report_bck_acts_1: Yup.string().label("report_bck_acts_0").required("Answer is required"),
        report_bck_acts_2: Yup.string().label("report_bck_acts_0").required("Answer is required"),
        report_bck_acts_3: Yup.string().label("report_bck_acts_0").required("Answer is required"),
        report_bck_acts_4: Yup.string().label("report_bck_acts_0").required("Answer is required"),
    }
    let fullValidationObject = {
        ...verifyValObject,
        report_dh: Yup.string().label("report_dh").required("Duty Holder is required"),
        //report_ag: Yup.string().label("report_ag").required("Asset Group is required"),
        report_installation: Yup.string().label("report_installation").required("Installation is required"),
        //report_barrier: Yup.string().label("report_barrier").required("Barrier is required"),
        report_sece: Yup.string().label("report_sece").required("SECE is required")
    }

    if (formMode === "verify") {
        validation = Yup.object().shape(verifyValObject);
    } else {
        validation = Yup.object().shape(fullValidationObject);
    }

    return validation;
}

const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
            _id
            sysvar_title
            sysvar_archived
            sysvartype_id{
                _id
                sysvartype_title
            }
        }
}`;

const VERIFICATION_SCHEDULE_ID_QUERY = gql`
query VerificationSchedule($vsId: ID!){
    verificationSchedule(_id: $vsId){
        _id
        vs_title
        vs_periodicity{
          _id
          sysvar_title
        }
        schedule_type_id{
          _id
          sysvar_title
        }
        dh_id{
          _id
          dh_name
        }
        installation_ids{
          _id
          installation_code
        }
        sece_ids{
          _id
          sece_title
          sece_ref
          sece_client_ref
          sece_status
        }
        va_ids{
          _id
          va_short_desc
          va_ref
          va_client_ref
          va_status
          sece_id {
            _id
            sece_client_ref
            sece_title
          }
          ps_id {
            _id
            ps_ref
            ps_title
          }
        }
        ra_ids{
          _id
          ra_title
        }
        verifier_ids{
          _id
          user_fname
          user_lname
        }
        vs_start_date_time
        vs_end_date_time
        vs_added_date
        vs_added_id{
        _id
        }

        vs_archived
    }
}`;

const VA_BY_ID_QUERY = gql`
query VerificationActivity($vaId: ID!){
    verificationActivity(_id: $vaId){
        _id
        dh_id{
            _id
            dh_name
        }
        sece_id{
            _id        
        } 
        ps_id {
            _id
            ps_ref
            ps_title
            ps_desc
            ps_criteria
        }
        va_ref 
        va_client_ref  
        va_short_desc
        va_long_desc
        va_type
        va_onshore_offshore
        va_freq
        va_baseline_sample
        va_class
        va_legislation
        va_child_id
        va_added_date
        va_added_id
        va_archived
        va_archived_id
        va_archived_date
        va_status
    }
}`;

const SECE_BY_ID_QUERY = gql`
query Sece($seceId: ID!){
    sece(_id: $seceId){
        _id
        dh_id{
            _id
        }
        ag_id{
            _id
            ag_title
        }
        bar_id{
            _id
            bar_title
        }
        installation_id{
            _id
            installation_title
        }
        sece_title
        sece_ref
        sece_client_ref
        sece_criticality
        sece_owner
        sece_order_no
        sece_attachment
        sece_archived
        sece_status
    }
}`;

const REPORT_BY_ID_QUERY = gql`
query Report($rptId: ID!){
    report(_id: $rptId) {
      _id
      dh_id{
        _id
        dh_name
      }
      ag_id{
        _id
        ag_title
      }
      installation_id{
        _id
        installation_title
      }
      barrier_id{
        _id
        bar_title 
      }
      sece_id{
        _id
        sece_title
      }
      report_ref
      report_comp_sample
      report_verifier_id{
        _id
        user_fname
        user_lname
      }
      report_previous_verifier_id{
        _id
        user_fname
        user_lname
      }
      report_current_status{
       _id
      }
      report_previous_status{
        _id
        sysvar_title
       }
      report_previously_completed
      report_previous_findings
      report_phase
      report_prior_norm
      report_time_taken
      report_discrepancy
      report_justification
      report_linked_doc
      rpt_ld_attmnt_dest_path
      report_verification_details
      report_conclusive_statement{
        _id
      }
      report_attachments
      rpt_attmnt_dest_path
      linked_va_ids{
       _id
      }
      interacting_sece_ids{
        _id
      }
      report_synopsis
      report_bg_activities
      rpt_ba_attmnt_dest_path
      report_abs_ivb
      report_archived
      report_status
      report_added_id {
        _id
      }
      report_added_date
      report_closed_date
      report_previous_activity{
        report_id
        previously_completed
        previous_verifier
        previous_status
        previous_findings{
          _id
          finding_ref
        }
      }
  }
}`;

const REPORTS_BY_VS_ID_QUERY = gql`
query ReportsByVerSchId($vsId: ID!) {
    reportsByVerSchId(vsId: $vsId){
        _id
        va_id{
            _id
        }
        report_current_status{
        _id
        }
        report_status
        report_conclusive_statement{_id}
    }
}`;

const DUTY_HOLDERS_QUERY = gql`
    query {
        dutyHolders {
            _id
            dh_name
            dh_status
            dh_added_date
            dh_archived
        }
}`;

const ASSETGROUPS_BY_DH_ID_QUERY = gql`
query AssetGroupsByDutyHolderId($dhId: ID){
    assetGroupsByDutyHolderId(dhId: $dhId) {
      _id
      ag_title
      ag_archived
      ag_status
  }
}`;

const BARRIERS_BY_DH_ID_QUERY = gql`
query BarrierByDutyHolderId($dhId: ID){
    barrierByDutyHolderId(dhId: $dhId) {
       _id
       bar_title
       bar_archived
       bar_status
    }
}`;

const INSTALLATIONS_BY_DH_ID_QUERY = gql`
query installationsByDutyHolderId($dhId: ID){
    installationsByDutyHolderId(dhId: $dhId) {
      _id
      installation_title
      installation_archived
      installation_status
  }
}`;

const SECES_BY_DH_ID_QUERY = gql`
query SecesByDutyHolderId($dhId: ID){
  secesByDutyHolderId(dhId: $dhId) {
       _id
       sece_title
       sece_archived
       sece_status
    }
}`;

const INTERACTING_SECES_QUERY = gql`
query InteractingSeces($vaId: ID!){
    interactingSeces(vaId: $vaId){
        _id
       sece_client_ref
       sece_title
    }
}`;

const CREATE_REPORT = gql`
    mutation CreateReport($rpt: CreateReport!) {
        createReport(rpt: $rpt) {
            _id
            report_conclusive_statement{_id}
            success
            status_code
            message
        }
    }
`;

const UPDATE_REPORT = gql`
    mutation UpdateReport($rptId: String!, $userId: String, $rpt: UpdateReportInput!) {
        updateReport(_id: $rptId, userId: $userId, rpt: $rpt) {
            _id
            report_conclusive_statement{_id}
            success
            status_code
            message
        }
    }
`;

const CONFIDENCE_LEVEL_QUERY = gql`
query ReportConfidenceLevel($vsId: ID!, $vaId: ID!){
    reportConfidenceLevel(vsId: $vsId, vaId: $vaId){
        vaCompleted
        vaFindingsOpen
        vaOneMLFindingRaised
        vaMoreThanTwoMLFindingsRaised
        psVaChanges
        seceModified
    }
}`;

const CREATE_FINDING = gql`
    mutation CreateFinding($fnd: CreateFinding!) {
        createFinding(fnd: $fnd) {
            _id
        }
    }
`;

const DIRECTLY_LINKED_VA_QUERY = gql`
query DirectlyLinkedActivities($vsId: ID, $vaId: ID){
    directlyLinkedActivities(vsId: $vsId, vaId: $vaId){
        id
        va_client_ref
        va_short_desc
        sece_client_ref
        sece_title
        ps_ref
        ps_title
        current_status
    }
}`;

const FINDINGS_BY_REPORT_ID_QUERY = gql`
query FindingsByReportId($reportId: ID!, $fndOrigin: String){
    findingsByReportId(reportId: $reportId, fndOrigin: $fndOrigin){
     _id
     finding_synopsis
     finding_current_status{
        _id
     }
     finding_ref
     finding_status
     finding_origin
     report_id {
          _id
     }

     finding_level_summary
     finding_infomatrix_level

     finding_summary
     finding_action

     finding_date
     finding_status
     finding_added_date
  }
}`;

function AddEditReport(props) {
    //const [synopsisCharsLeft, setSynopsisCharsLeft] = useState(150);
    //const [synopsisMaxChar, setSynopsisMaxChar] = useState(150);
    //const [attachmentList, setAttachmentList] = useState([]);

    const [enableValidation, setEnableValidation] = useState(false);

    const [shouldSpin, setShouldSpin] = useState(true);
    const [currentUserName, setCurrentUserName] = useState("");
    const [currentUserId, setCurrentUserId] = useState("");
    const [reportAction, setReportAction] = useState(REPORT_ACTION_SAVE);

    const [selectedDutyHolderId, setSelectedDutyHolderId] = useState('Select Duty Holder');

    const [verScheduleObject, setVerScheduleObject] = useState({});
    const [verActivityObject, setVerActivityObject] = useState({});
    const [vaPerfStand, setVaPerfStand] = useState({});
    
    const [dutyHolderObject, setDutyHolderObject] = useState({});
    const [assetGroupObject, setAssetGroupObject] = useState({});
    const [barrierObject, setBarrierObject] = useState({});
    const [installationObject, setInstallationObject] = useState({});
    const [seceObject, setSeceObject] = useState({});

    const [reportObject, setReportObject] = useState({});
    const [reportList, setReportList] = useState([]);

    const [dutyHolderList, setDutyHolderList] = useState([]);
    const [assetGroupList, setAssetGroupList] = useState([]);
    const [barrierList, setBarrierList] = useState([]);
    const [installationList, setInstallationList] = useState([]);
    const [seceList, setSeceList] = useState([]);

    const [backGroundActivityList, setBackGroundActivityList] = useState([]);

    const [linkedActivityIdList, setLinkedActivityIdList] = useState([]);
    const [linkedActivityList, setLinkedActivityList] = useState([]);
    const [selectedLinkedActivityList, setSelectedLinkedActivityList] = useState([]);

    const [interactingSeceList, setInteractingSeceList] = useState([]);
    const [selectedInteractingSeceList, setSelectedInteractingSeceList] = useState([]);

    const [natureList, setNatureList] = useState([]);
    const [frequencyList, setFrequencyList] = useState([]);  
    const [phaseList, setPhaseList] = useState([]);
    const [statementList, setStatementList] = useState([]);
    //const [statusList, setStatusList] = useState([])
    const [activityStatusList, setActivityStatusList] = useState([])

    const [bckGrdQuestionList, setBckGrdQuestionList] = useState([]);
    const [answerOptionList, setAnswerOptionList] = useState([]);

    const [linkedDocList, setLinkedDocList] = useState([]);

    const [confidenceLevelVariableData, setConfidenceLevelVariableData] = useState(null);

    const [tempFindingList, setTempFindingList] = useState([]);
    const [findingList, setFindingList] = useState([]);

    const [previousReportStatusId, setPreviousReportStatusId] = useState("");
    const [previousReportStatusTitle, setPreviousReportStatusTitle] = useState("");

    const [reportHasVerifier, setReportHasVerifier] = useState(false);

    const [drDnInstallation, setDrDnInstallation] = useState({});

    const [formInitValues, setFormInitValues] = useState({
        report_dh: "",
        report_ag: "",
        report_installation: "",
        report_barrier: "",
        report_sece: "",
        report_nature: "",
        report_freq: "",
        report_comp_sample: "",
        report_verifier: "",
        report_previous_verifier: "",
        report_current_status: "",
        report_phase: "",
        report_prior_norm: 0,
        report_time_taken: 0,
        report_discrepancy: "",
        report_justification: "",
        report_verification_details: "",
        report_conclusive_statement: "",
        report_attachments: "[]",
        report_synopsis: "",
        report_bck_acts_0: "",
        report_bck_acts_1: "",
        report_bck_acts_2: "",
        report_bck_acts_3: "",
        report_bck_acts_4: "",
        report_action: "",
        report_status: "",
        report_findings: 0,
        report_added_date: "",
        report_closed_date: "",
        report_abs_ivb: true,
        report_previously_completed: "",
        report_previous_findings: []
    });

    const [linkedDocformInitValues, setLinkedDocFormInitValues] = useState({
        ld_name: "",
        ld_description: "",
        ld_requirement: "",
        ld_attachement: []
    });

    const { formMode = "", rptId = ""} = useParams(); //form add or edit state passed from link
    const { location: {
         state : {
             actId = "", 
             verId = "", 
             status = "",
             origin = ""
            } = {} } = {} } = props ? props : {};

    const history = useHistory();

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);

    const [directlyLinkedActivities, {data: linkedVaData }] = useLazyQuery(DIRECTLY_LINKED_VA_QUERY);
    const [systemVariablesBySysVarTypeIds, {data: sysVarsData}] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);
    const [verificationSchedule, {error:vsError, data: verSchData}] = useLazyQuery(VERIFICATION_SCHEDULE_ID_QUERY);
    const [verificationActivity, {error:vaError, data: verActData}] = useLazyQuery(VA_BY_ID_QUERY);
    const [sece, {error:seceError, data: seceData}] = useLazyQuery(SECE_BY_ID_QUERY);
    const [interactingSeces, {data: interactingSecesData}] = useLazyQuery(INTERACTING_SECES_QUERY);

    const [reportConfidenceLevel, {data: confidLevelData}] = useLazyQuery(CONFIDENCE_LEVEL_QUERY);

    const [dutyHolders, {data: dutyHoldersData}] = useLazyQuery(DUTY_HOLDERS_QUERY);
    const [assetGroupsByDutyHolderId, {data: assetGroupsData}] = useLazyQuery(ASSETGROUPS_BY_DH_ID_QUERY);
    const [barrierByDutyHolderId, {data: barriersData}] = useLazyQuery(BARRIERS_BY_DH_ID_QUERY);
    const [installationsByDutyHolderId, {data: installsData}] = useLazyQuery(INSTALLATIONS_BY_DH_ID_QUERY);
    const [secesByDutyHolderId, {data: secesData}] = useLazyQuery(SECES_BY_DH_ID_QUERY);

    const [report, {data: reportData}] = useLazyQuery(REPORT_BY_ID_QUERY);
    const [reportsByVerSchId, {data: reportsByVsData}] = useLazyQuery(REPORTS_BY_VS_ID_QUERY);
    
    const [createReport, { loading: createReportLoading, error: createReportError, data: createReportData }] = useMutation(CREATE_REPORT);
    const [updateReport, {loading: updateReportLoading, error: updateReportError, data: updateReportData}] = useMutation(UPDATE_REPORT);

    const [createFinding, {data: createFindingData}] = useMutation(CREATE_FINDING);
    const [findingsByReportId, {data: findingData}] = useLazyQuery(FINDINGS_BY_REPORT_ID_QUERY);

    const sysVarTypeIds = [NATURE_SYSTEM_VARIABLE_TYPE_ID,
                           FREQUENCY_SYSTEM_VARIABLE_TYPE_ID,
                           PHASE_SYSTEM_VARIABLE_TYPE_ID,
                           STATEMENT_SYSTEM_VARIABLE_TYPE_ID, 
                           ACTIVITY_STATUS_SYSTEM_VARIABLE_TYPE_ID,
                           //STATUS_SYSTEM_VARIABLE_TYPE_ID, 
                           ANSWERS_SYSTEM_VARIABLE_TYPE_ID,
                           QUESTIONS_SYSTEM_VARIABLE_TYPE_ID];

    useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        } catch (error) { window.scrollTo(0, 0); };
        
        systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });
        
        if (rptId !== "") {
            findingsByReportId({ variables: { reportId: rptId, fndOrigin: FINDING_ORIGIN_REPORT }, errorPolicy: 'all' });
        }

        if (actId !== "") {
           // Here we are trying to get any findings raised
           const key = `va-id-${actId}`;
           const stringifiedFindings = localStorage.getItem(key);
           const tempValue = stringifiedFindings && stringifiedFindings !== "" ? stringifiedFindings : "[]";
           let findingList = parseJSONSafely(tempValue);
           setTempFindingList(findingList);

           hydrateFormValuesFromLocalStoarge(actId);
        }
        
        if (((formMode === "verify")||(formMode === "verify-edit")) && actId !== "") {
            verificationActivity({ variables: { vaId: actId }, errorPolicy: 'all' }); 
            interactingSeces({ variables: { vaId: actId }, errorPolicy: 'all' });
            reportConfidenceLevel({ variables: { vaId: actId }, errorPolicy: 'all' });
        }

        if (((formMode === "verify")||(formMode === "verify-edit")) && verId !== "") {
            reportsByVerSchId({ variables: { vsId: verId }, errorPolicy: 'all' }); //Place to work at. The Vs need to be called after the Report.
            verificationSchedule({ variables: { vsId: verId }, errorPolicy: 'all' });
            reportConfidenceLevel({ variables: {vsId: verId, vaId: actId }, errorPolicy: 'all' });
            directlyLinkedActivities({ variables: {vsId: verId, vaId: actId }, errorPolicy: 'all' });
        }   

        if ((formMode === "add") || (formMode === "edit")) {        
            dutyHolders({ variables: {}, errorPolicy: 'all' });
        }

        if (((formMode === "edit")||(formMode === "verify-edit")) && rptId !== "" && origin !== "FindingForm") {        
            report({ variables: { rptId: rptId }, errorPolicy: 'all' });
            setLinkedDocFormInitValues({...linkedDocformInitValues});// temporary measure to surpress react warning 
        } 
    }, []);

    useEffect(() => {
        if (authContext && authContext.user) {
            const { _id: userId = "", user_fname: userFname = "", user_lname: userLname = ""} = authContext.user;
            setCurrentUserName(`${userFname} ${userLname}`);
            setCurrentUserId(userId);
        }
    }, [authContext]);

     useEffect(() => {
         const { installation } = navContext;
         const { id: prevId } = drDnInstallation;

         if (prevId && installation.id && prevId !== installation.id) {
             history.push('/');
         }
         setDrDnInstallation(installation);
       }, [navContext]);

    useEffect(() => {
        if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
            formatSysVarDataAndUpdateStates(sysVarsData.systemVariablesBySysVarTypeIds,
                setNatureList,
                setFrequencyList,
                setPhaseList,
                setStatementList,
                setActivityStatusList,
                setBckGrdQuestionList,
                setAnswerOptionList,
                NATURE_SYSTEM_VARIABLE_TYPE_ID,
                FREQUENCY_SYSTEM_VARIABLE_TYPE_ID,
                PHASE_SYSTEM_VARIABLE_TYPE_ID,
                STATEMENT_SYSTEM_VARIABLE_TYPE_ID,
                ACTIVITY_STATUS_SYSTEM_VARIABLE_TYPE_ID,
                QUESTIONS_SYSTEM_VARIABLE_TYPE_ID,
                ANSWERS_SYSTEM_VARIABLE_TYPE_ID);
        }
    }, [sysVarsData]);

    useEffect(() => {
        if (reportsByVsData && reportsByVsData.reportsByVerSchId) {
            setReportList(reportsByVsData.reportsByVerSchId);
        }
    }, [reportsByVsData]);

    useEffect(() => {
        if (verSchData && verSchData.verificationSchedule) {
            formatVsAndUpdateState(verSchData.verificationSchedule,
                reportList,
                activityStatusList,
                setVerScheduleObject,
                setLinkedActivityList,
                setInteractingSeceList);
        }
    }, [verSchData]);

    useEffect(() => {
        if (verActData && verActData.verificationActivity) {
            let verAct = verActData.verificationActivity;
            let dutyHolder = verAct.dh_id ? verAct.dh_id : {};
            const {_id = ""} = verAct.sece_id ? verAct.sece_id : {};
            const { ps_id = {}} = verAct ? verAct : {};
            setVaPerfStand(ps_id);
            setDutyHolderObject(dutyHolder);
            setVerActivityObject(verAct);

            if (_id !== "") {
                sece({ variables: { seceId: _id }, errorPolicy: 'all' });
            }        
        }
    }, [verActData]);

    //Hook for handling interacting seces
    useEffect(() => {
        if (interactingSecesData && interactingSecesData.interactingSeces) {
            let list = formatInteractingSeces(interactingSecesData.interactingSeces)
            setInteractingSeceList(list);
        }
    }, [interactingSecesData]);

    //Hook for handling confidence level query response.
    useEffect(() => {
        if (confidLevelData && confidLevelData.reportConfidenceLevel) {
            setConfidenceLevelVariableData(confidLevelData.reportConfidenceLevel);
        }
    }, [confidLevelData]);

    useEffect(() => {
        if (seceData && seceData.sece) {

            let sece = seceData.sece;
            let dholder = sece.dh_id ? sece.dh_id : {};
            let assetGroup = sece.ag_id ? sece.ag_id : {};
            let barrier = sece.bar_id ? sece.bar_id : {};
            let installation = sece.installation_id ? sece.installation_id : {};

            let dholderId = dholder._id ? dholder._id : "";
            let assetGroupId = assetGroup._id ? assetGroup._id : "";
            let installationId = installation._id ? installation._id : "";
            let barrrierId = barrier._id ? barrier._id : "";
            let seceId = sece._id ? sece._id : "";

            setAssetGroupObject(assetGroup);
            setBarrierObject(barrier);
            setInstallationObject(installation);
            setSeceObject(sece);
            setShouldSpin(false);

            if (formMode !== "add") {
                setFormInitValues({
                    ...formInitValues,
                    report_dh: dholderId,
                    report_ag: assetGroupId,
                    report_installation: installationId,
                    report_barrier: barrrierId,
                    report_sece: seceId
                })
            }
        }
    }, [seceData]);

    useEffect(() => {
        if (selectedDutyHolderId !== 'Select Duty Holder') {
            fetchMultiSelectData(selectedDutyHolderId);
        }
    }, [selectedDutyHolderId]);

    useEffect(() => {
        if (dutyHoldersData && dutyHoldersData.dutyHolders) {
            let list = dutyHoldersData.dutyHolders;
            let newDutyHolderList = list.filter(dh => dh.dh_status === "approved" && dh.dh_archived !== true);
            setDutyHolderList(newDutyHolderList);
        }
    }, [dutyHoldersData]);

    useEffect(() => {
        if (assetGroupsData && assetGroupsData.assetGroupsByDutyHolderId) {
            let list = assetGroupsData.assetGroupsByDutyHolderId;
            let newAssetGroupList = list.filter(ag => ag.ag_status === "approved" && ag.ag_archived !== true);
            setAssetGroupList(newAssetGroupList);
        }
    }, [assetGroupsData]);

    useEffect(() => {
        if (barriersData && barriersData.barrierByDutyHolderId) {
            let list = barriersData.barrierByDutyHolderId;
            let newBarrierList = list.filter(bar => bar.bar_archived !== true);
            setBarrierList(newBarrierList);
        }
    }, [barriersData]);

    useEffect(() => {
        if (installsData && installsData.installationsByDutyHolderId) {
            let list = installsData.installationsByDutyHolderId;
            let newInstallList = list.filter(install => install.installation_archived !== true);
            setInstallationList(newInstallList);
        }
    }, [installsData]);

    useEffect(() => {
        if (secesData && secesData.secesByDutyHolderId) {
            let list = secesData.secesByDutyHolderId;
            let newSeceList = list.filter(sece => sece.sece_archived !== true);
            setSeceList(newSeceList);
        }
    }, [secesData]);

    useEffect(() => {
        if (reportData && reportData.report) {
            let report = reportData.report;

            let rptId = report._id;

            let dhId = report.dh_id ? report.dh_id._id : "";
            let agId = report.ag_id ? report.ag_id._id : "";
            let barId = report.barrier_id ? report.barrier_id._id : "";
            let instId = report.installation_id ? report.installation_id._id : "";
            let seceId = report.sece_id ? report.sece_id._id : "";

            let hasVerifier = report.report_verifier_id ? true: false;

            let verifier = report.report_verifier_id ? `${report.report_verifier_id.user_fname} ${report.report_verifier_id.user_lname}`: "N/A";

            let linkedDocsString = report.report_linked_doc ? report.report_linked_doc : "[]";
            let linkedDocList = parseJSONSafely(linkedDocsString);

            let reportAttachments = report.report_attachments && report.report_attachments !== "" ? report.report_attachments : "[]";

            let bgdActString = report.report_bg_activities ? report.report_bg_activities : "[]";
            let bgdActList = parseJSONSafely(bgdActString);
            let bgdActProps = createBackgndActForEdit(bgdActList);

            let linkedVaIds = report.linked_va_ids ? report.linked_va_ids : [];
            let interactingSeceIds = report.interacting_sece_ids ? report.interacting_sece_ids : [];

            let selectedInteractingSeces = createSelectedInteractingSeces(interactingSeceIds, interactingSeceList);

            let reportCurrentStatus = report.report_current_status ? report.report_current_status._id : "";
            
            const {
                previous_findings,
                previous_status,
                previous_verifier,
                previously_completed } = report.report_previous_activity ?? {};

            var previouslyCompletedDateIso = "";
            if (previously_completed && previously_completed !== "") {
                previouslyCompletedDateIso = new Date(previously_completed);
            }
            let previouslyCompletedDate = formatDateToLocaleDateString(previouslyCompletedDateIso);
            
            let previouslyCompleted = previouslyCompletedDate && previouslyCompletedDate !== "" ? previouslyCompletedDate : "N/A";
            let previousFindings = previous_findings && previous_findings.length ? previous_findings : [];
            let previousVerifier = previous_verifier ? previous_verifier: "N/A";
            let reportPreviousStatus = previous_status && previous_status !== "" ? previous_status : "N/A";
           
            let formatedReport = {
                report_dh: dhId,
                report_ag: agId,
                report_installation: instId,
                report_barrier: barId,
                report_sece: seceId,
                report_nature: report.report_nature ?? "",
                report_freq: report.report_freq ?? "",
                report_comp_sample: report.report_comp_sample ?? "",
                report_verifier: verifier,
                report_previous_verifier: previousVerifier,
                report_current_status: reportCurrentStatus,
                report_phase: report.report_phase ?? "",
                report_prior_norm: report.report_prior_norm ?? 0,
                report_time_taken: report.report_time_taken ?? 0,
                report_discrepancy: report.report_discrepancy ?? "",
                report_justification: report.report_justification ?? "",
                report_verification_details: report.report_verification_details ?? "",
                report_conclusive_statement: report.report_conclusive_statement && report.report_conclusive_statement._id ? report.report_conclusive_statement._id : "",

                report_attachments: reportAttachments,
                report_synopsis: report.report_synopsis ?? "",

                report_status: report.report_status ?? NOTIFICATION_STATUS_OPEN,
                report_added_id: report.report_added_id ?? "",

                report_added_date: report.report_added_date && report.report_added_date !== "" ? new Date(report.report_added_date) : new Date(),

                report_closed_date: report.report_closed_date && report.report_closed_date !== "" ? new Date(report.report_closed_date) : new Date(),
                report_abs_ivb: report.report_abs_ivb !== null ? report.report_abs_ivb : true,

                report_previously_completed: previouslyCompleted,
                report_previous_findings: previousFindings,

                ...bgdActProps
            };

            setPreviousReportStatusTitle(reportPreviousStatus);
            setPreviousReportStatusId(reportCurrentStatus);
            setLinkedDocList(linkedDocList);

            setLinkedActivityIdList(linkedVaIds);
            setSelectedInteractingSeceList(selectedInteractingSeces);

            setReportHasVerifier(hasVerifier);

            //Check that this is not overiding the initial form values.
            setFormInitValues(prevState => ({...prevState, ...formatedReport})); 

            setBackGroundActivityList(bgdActList);
            setReportObject(report);

            if (rptId !== "") {
                findingsByReportId({ variables: { reportId: rptId, fndOrigin: FINDING_ORIGIN_REPORT }, errorPolicy: 'all' });
            }
        }
    }, [reportData]);

    useEffect(() => {
        if (findingData && findingData.findingsByReportId) {
            setFindingList(findingData.findingsByReportId);
        }
    }, [findingData]);

    useEffect(() => {
        if (verScheduleObject.hasOwnProperty("_id") && reportObject.hasOwnProperty("_id")) {
            let linkedVaIds = reportObject.linked_va_ids;
            let selectedLinkedVas = createSelectedLinkedVas(linkedVaIds, linkedActivityList);

            let interactingSeceIds = reportObject.interacting_sece_ids;
            let selectedInteractingSeces = createSelectedInteractingSeces(interactingSeceIds, interactingSeceList);

            setSelectedLinkedActivityList(selectedLinkedVas);
            setSelectedInteractingSeceList(selectedInteractingSeces);
        }
    }, [verScheduleObject, reportObject]);

    useEffect(() => {
        if (createReportData && createReportData.createReport) {
            const { success, message } = createReportData.createReport;
            
            if (!success) {
                alert(message);
            }

            if (success) {
                let report = createReportData.createReport;
                let reportId = report._id;
                let concluStatement = report.report_conclusive_statement ? report.report_conclusive_statement : "";
                let isSaveOption = (concluStatement === CONCLUSIVE_STATEMENT_FOR_SAVE_ID);

                if (tempFindingList.length) {
                    tempFindingList.forEach(fnd => {
                        let tempFnd = { ...fnd, report_id: reportId };
                        createFinding({ variables: { fnd: tempFnd } });
                    })
                }

                // Here we try to clear the local storage once we are through with saving the findings
                if (actId && actId !== "") {
                    const vaIdkey = `va-id-${actId}`;
                    const key = `report-form-${actId}`;
                    localStorage.removeItem(vaIdkey);
                    localStorage.removeItem(key);
                }

                if (formMode === "verify" && verId !== "" && isSaveOption) {
                    history.push(`/verification/${verId}/activity-register`);
                }

                if (formMode === "verify" && !isSaveOption) {
                    //history.push("/reporting/findings/add", { formMode: "add" });
                    history.push(`/verification/${verId}/activity-register`);
                }

                if (formMode === "add") {
                    history.push('/reporting');
                }
            }
        }
    }, [createReportData]);

    useEffect(() => {
        if (createFindingData && createFindingData.createFinding) {
           console.log("Finding Created>>>>>>", createFindingData.createFinding);
        }
    }, [createFindingData]);

    useEffect(() => {
        if (updateReportData && updateReportData.updateReport) {
            const { success, message } = updateReportData.updateReport;

            if (!success) {
                alert(message);
            }

            if (success) {
                let report = updateReportData.updateReport;
                let reportId = report._id;
                let conStatement = report.report_conclusive_statement ? report.report_conclusive_statement : "";
                let isSaveOption = (conStatement === CONCLUSIVE_STATEMENT_FOR_SAVE_ID);
                console.log("isSaveOption>>>>", isSaveOption);
                if (tempFindingList.length) {
                    tempFindingList.forEach(fnd => {
                        let tempFnd = { ...fnd, report_id: reportId };
                        createFinding({ variables: { fnd: tempFnd } });
                    })
                }

                // Here we try to clear the local storage once we are through with saving the findings
                if (actId && actId !== "") {
                    const vaIdkey = `va-id-${actId}`;
                    const key = `report-form-${actId}`;
                    localStorage.removeItem(vaIdkey);
                    localStorage.removeItem(key);
                }

                if (formMode === "verify-edit" && verId !== "") {
                    history.push(`/verification/${verId}/activity-register`);
                }

                //if (formMode === "verify-edit" && verId !=="" && isSaveOption) {
                //    history.push(`/verification/${verId}/activity-register`);
                //}
                //if (formMode === "verify-edit" && !isSaveOption) {
                //    history.push("/reporting/findings/add", { formMode: "add" });
                //} 

                if (formMode === "add") {
                    history.push('/reporting');
                }
            }
        }
    }, [updateReportData]);

    // useEffect(() => {
    //     if (installationObject && installationObject.hasOwnProperty("_id")) {
    //         const { _id: instId = "" } = installationObject;
    //         if (instId !== "") {
    //            directlyLinkedActivities({ variables: { instId: instId }, errorPolicy: 'all' });
    //         }
    //     }
    // }, [installationObject]);

    useEffect(() => {
        if (linkedVaData && linkedVaData.directlyLinkedActivities) {
            let linkedVa = linkedVaData.directlyLinkedActivities;
            let selectedLinkedVas = createSelectedLinkedVas(linkedActivityIdList, linkedVa);
            setSelectedLinkedActivityList(selectedLinkedVas);
            setLinkedActivityList(linkedVa);
        }
    }, [linkedVaData]);

    const fetchMultiSelectData = (dutyholderId) => {
        assetGroupsByDutyHolderId({ variables: { dhId: dutyholderId }, errorPolicy: 'all' });
        barrierByDutyHolderId({ variables: { dhId: dutyholderId }, errorPolicy: 'all' });
        installationsByDutyHolderId({ variables: { dhId: dutyholderId }, errorPolicy: 'all' });
        secesByDutyHolderId({ variables: { dhId: dutyholderId }, errorPolicy: 'all' });
    }

    //const handleAddAttachment = (attachments) => {
    //    if (attachments && attachments.length) {
    //        let list = []
    //        attachments.forEach(att =>{
    //            let attachment = {
    //                name:att.name,
    //                path:att.path,
    //              description: "Not Available"
    //        };
    //      list.push(attachment);
    //       });  
    //        setAttachmentList(list);
    //    }  
    //};

    const getConfidenceLevel = (confidenceLevelVariableData, criticality) => {
        let confidenceLevel = "";
        if (confidenceLevelVariableData && criticality && criticality !== "") {
            let tempValue = confidenceLevelManager(confidenceLevelVariableData, criticality);
            confidenceLevel = tempValue.value;
        } 
        return confidenceLevel;
    }

    const handleDutyHolderChange = (event) => {
        let value = event.target.value;
        setSelectedDutyHolderId(value);
    };

    //word count for synopsis
    //const handleWordCount = (event, synopsisMaxChar) => {
    //    const charCount = event.target.value.length;
    //    const maxChar = synopsisMaxChar;
    //    const charLength = maxChar - charCount;
    //    setSynopsisCharsLeft(charLength);
    //}

    const handleBackLink = (formMode, verId) => {
        let backLink = "";
        if ((formMode === "verify" || "verify-edit") && verId !== "") {
            backLink = `/verification/${verId}/activity-register`
            return backLink;
        }
        backLink = '/reporting'
        return backLink;
    }

    const handleBgActAnswerChange = (question, answerEvent) => {
        let answerId = answerEvent.target.value;

        let questionId = question._id ? question._id : "";
        if (questionId !== "") {
            let bckGrdActList = backGroundActivityList;
            let bckGrdAct = bckGrdActList.find(act => act.question_id === questionId);
            if (bckGrdAct) {
                let index = bckGrdActList.findIndex(act => act.question_id === questionId);
                bckGrdActList[index] = { ...bckGrdAct, answer_id: answerId };
                setBackGroundActivityList([...bckGrdActList])
            } else {
                let newBckGrdAct = {
                    question_id: questionId,
                    answer_id: answerId,
                    evidence: []
                }
                setBackGroundActivityList([...bckGrdActList, newBckGrdAct])
            }
        }
        // if (answerId === "" && questionId !== "") {

        //     let bckGrdActList = backGroundActivityList.filter(act => act.question_id !== questionId);
        //     setBackGroundActivityList([...bckGrdActList])
        // }
    }

    const handleAddBgActEvidence = (files, questionId) => {
        if (files && questionId !== "") {
            let bckGrdActList = backGroundActivityList;
            let bckGrdAct = bckGrdActList.find(act => act.question_id === questionId);
            if (bckGrdAct) {
                let index = bckGrdActList.findIndex(act => act.question_id === questionId);
                bckGrdActList[index] = { ...bckGrdAct, evidence: files };
                setBackGroundActivityList([...bckGrdActList])
            } else {
                let newBckGrdAct = {
                    question_id: questionId,
                    evidence: files
                }
                setBackGroundActivityList([...bckGrdActList, newBckGrdAct])
            }
        }
    }

    const handleAddLinkedActivity = (vaid) => {
        let value = vaid;
        if (value && value !== "") {
            let selectedActList = selectedLinkedActivityList;
            let isSelected = selectedActList.some(act => act.id === value);
            if (!isSelected) {
                let selectedAct = linkedActivityList.find(act => act.id === value);
                setSelectedLinkedActivityList([...selectedActList, selectedAct])
            } else {
                let fileteredSelections = selectedActList.filter(act => act.id !== value);
                setSelectedLinkedActivityList([...fileteredSelections])
            }
        }
    }

    const handleRemoveLinkedActivity = (act) => {
        let actId = act.id;
        let activities = selectedLinkedActivityList.filter(acty => acty.id !== actId);
        setSelectedLinkedActivityList([...activities])
    }

    const handleAddInteractingSece = (vaid) => {
        let value = vaid;
        if (value && value !== "") {
            let selectedSeceList = selectedInteractingSeceList;
            let isSelected = selectedSeceList.some(sece => sece.id === value);
            if (!isSelected) {
                let selectedSece = interactingSeceList.find(sece => sece.id === value);
                setSelectedInteractingSeceList([...selectedSeceList, selectedSece])
            } else {
                let fileteredSelections = selectedSeceList.filter(sece => sece.id !== value);
                setSelectedInteractingSeceList([...fileteredSelections])
            }
        }
    }

    const handleRemoveInteractingSece = (sece) => {
        let seceId = sece.id;
        let seces = selectedInteractingSeceList.filter(sece => sece.id !== seceId);
        setSelectedInteractingSeceList([...seces])
    }

    const handleLinkedDocFormData = (linkedDoc) => {
        if (linkedDoc) {
            setLinkedDocList([...linkedDocList, linkedDoc]);      
        }
    }

    const handleRemoveLinkedDoc = (index) => {
        let list = linkedDocList;
        list.splice(index, 1);
        setLinkedDocList([...list]);
    }

    const handleInstallationChange = (event) => {
        let value = event.target.value;
        if (value && value !== "") {
            directlyLinkedActivities({variables: {instId : value}, errorPolicy: 'all' });
        }
    }

    const toggleModal = () => {
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }
    const toggleActivitiesModal = () => {
        const modal = document.querySelector('.actmodal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }
    const toggleSecesModal = () => {
        const modal = document.querySelector('.secemodal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }
    
    const toggleLinkedDocModal = () => {
        const modal = document.querySelector('.linkdocmodal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    //These Adds the ability to update the attachments with there description
    let tempLinkDocAttachFileList = [];
    const handleLinkDocAttachFilesDescription = (item) => {
        let index = tempLinkDocAttachFileList.findIndex(f => f.name === item.name);
        if (index >= 0) {
            tempLinkDocAttachFileList[index] = item;
        } else {
            tempLinkDocAttachFileList.push(item);
        }
    };

    //Here we are saving the form values to the local storage
    const saveFormValuesToLocalStoarge = (actId, values) => {
        if (actId !== "") {
            const key = `report-form-${actId}`;
            let savedPayload = localStorage.getItem(key);
            if (savedPayload && savedPayload !== "") {
                localStorage.removeItem(key);
            }

            const payload = {
                values: values,
                linkedDocList: linkedDocList,
                backGroundActivityList: backGroundActivityList,
                selectedLinkedActivityList:selectedLinkedActivityList,
                selectedInteractingSeceList: selectedInteractingSeceList
            }

            const stringifiedPayload = JSON.stringify(payload);           
            localStorage.setItem(key, stringifiedPayload);
        }
    }

    //Here we are restoring the form values from the local storage
    const hydrateFormValuesFromLocalStoarge = (actId) => {
        if (actId !== "") {
            const key = `report-form-${actId}`;

            let stringifiedPayload = localStorage.getItem(key);
            if (stringifiedPayload && stringifiedPayload !== "") {
                let payload = parseJSONSafely(stringifiedPayload);

                let values = payload.values;
                let modifiedValues = {
                    ...values,
                    report_added_date: values.report_added_date && values.report_added_date !== "" ? new Date(values.report_added_date) : new Date(),
                    report_closed_date: values.report_closed_date && values.report_closed_date !== "" ? new Date(values.report_closed_date) : new Date(),
                }

                let linkedDocList = payload.linkedDocList;
                let backGroundActivityList = payload.backGroundActivityList;

                let selectedLinkedActivityList = payload.selectedLinkedActivityList;
                let selectedInteractingSeceList = payload.selectedInteractingSeceList;

                setFormInitValues(prevState => ({...prevState, ...modifiedValues}));

                setLinkedDocList(linkedDocList);
                setBackGroundActivityList(backGroundActivityList);
                setSelectedLinkedActivityList(selectedLinkedActivityList);
                setSelectedInteractingSeceList(selectedInteractingSeceList);
                
                localStorage.removeItem(key);
            }
        }
    }

    //Here we are removing the raised finding
    const handleRemoveFindingRaised = (findingRef) => {
        console.log(findingRef);
        const stringifiedFindings = localStorage.getItem(actId);
        const tempValue = stringifiedFindings && stringifiedFindings !== "" ? stringifiedFindings : "[]";
        let findingList = parseJSONSafely(tempValue);

        let filtered = findingList.filter(f => f.finding_ref !== findingRef);
        setTempFindingList(filtered);

        const payload = JSON.stringify(filtered);
        localStorage.setItem(actId, payload);
    }

    const handleSave = (values, { setSubmitting, report_action }) => {
        submitReportLogic(values, setSubmitting, report_action)           
    };
  
    const submitReportLogic = (values, setSubmitting, report_action) => {
        // if (reportAction === REPORT_ACTION_REVIEW && values.report_current_status !== ACTIVITY_STATUS_SYS_VAR_COMPLETED_ID) {
        //     return true;
        // }

        const { _id: userId = "" } = authContext.user;
        let va_ids = createIdList(selectedLinkedActivityList);
        let sece_ids = createIdList(selectedInteractingSeceList);

        let confidenceLevel = getConfidenceLevel(confidenceLevelVariableData, seceObject.sece_criticality).toString();
        
        let reportAbsIvb = values.report_abs_ivb === "true" ? true : false;

        if (formMode === 'add') {
            let newReport = {
                ...(values.report_dh && values.report_dh !== "" && {dh_id: values.report_dh}),
                ...(values.report_ag && values.report_ag !== "" && {ag_id: values.report_ag}),
                ...(values.report_installation && values.report_installation !== "" && {installation_id: values.report_installation}),
                ...(values.report_barrier && values.report_barrier !== "" && {barrier_id: values.report_barrier}),
                ...(values.report_sece && values.report_sece !== "" && {sece_id: values.report_sece}),
                //report_nature: values.report_nature,
                //report_freq: values.report_freq,
                report_confidence: confidenceLevel,
                report_comp_sample: values.report_comp_sample,
                report_verifier_id: currentUserId,
                report_current_status: values.report_current_status,
                report_previous_status: values.report_current_status,

                ...(values.report_phase && values.report_phase !== "" && { report_phase: values.report_phase }),

                report_prior_norm: values.report_prior_norm,
                report_time_taken: values.report_time_taken,
                report_discrepancy: getDiscrepancy(values.report_prior_norm, values.report_time_taken),
                report_justification: values.report_justification,

                report_linked_doc: JSON.stringify(linkedDocList),
                report_verification_details: values.report_verification_details,
                ...(values.report_conclusive_statement && values.report_conclusive_statement !== "" && {report_conclusive_statement: values.report_conclusive_statement}),
                report_attachments: values.report_attachments,
                linked_va_ids: va_ids,
                interacting_sece_ids: sece_ids,
                report_synopsis: values.report_synopsis,
                report_bg_activities: JSON.stringify(backGroundActivityList),

                report_abs_ivb: reportAbsIvb,

                report_action: report_action ? report_action : reportAction,
                report_added_date: new Date(),
                report_added_id: userId,
                report_modified_by: userId,
                report_modified_date: new Date(),
                report_archived: false,
                report_status: values.report_status
            }
            createReport({ variables: { rpt: newReport } });
        }

        if (formMode === 'edit' && rptId !== "" && userId !== "") {
            let newReport = {
                ...(values.report_dh && values.report_dh !== "" && {dh_id: values.report_dh}),
                ...(values.report_ag && values.report_ag !== "" && {ag_id: values.report_ag}),
                ...(values.report_installation && values.report_installation !== "" && {installation_id: values.report_installation}),
                ...(values.report_barrier && values.report_barrier !== "" && {barrier_id: values.report_barrier}),
                ...(values.report_sece && values.report_sece !== "" && {sece_id: values.report_sece}),

                report_ref: reportObject.report_ref,
                //report_nature: values.report_nature,
                //report_freq: values.report_freq,
                report_confidence: confidenceLevel,
                report_comp_sample: values.report_comp_sample,

                ...(!reportHasVerifier && {
                    report_verifier_id: currentUserId,
                    report_added_id: currentUserId,
                    report_added_date: new Date()
                }),

                report_current_status: values.report_current_status,
                ...(previousReportStatusId && previousReportStatusId !== "" && { report_previous_status: previousReportStatusId }),
                ...(values.report_phase && values.report_phase !== "" && { report_phase: values.report_phase }),

                report_prior_norm: values.report_prior_norm,
                report_time_taken: values.report_time_taken,
                report_discrepancy: getDiscrepancy(values.report_prior_norm, values.report_time_taken),
                report_justification: values.report_justification,

                report_linked_doc: JSON.stringify(linkedDocList),
                report_verification_details: values.report_verification_details,
                ...(values.report_conclusive_statement && values.report_conclusive_statement !== "" && {report_conclusive_statement: values.report_conclusive_statement}),
                report_attachments: values.report_attachments,
                linked_va_ids: va_ids,
                interacting_sece_ids: sece_ids,
                report_synopsis: values.report_synopsis,
                report_bg_activities: JSON.stringify(backGroundActivityList),

                report_abs_ivb: reportAbsIvb,

                report_action: report_action ? report_action : reportAction,
                report_status: values.report_status,

                report_modified_by: userId,
                report_modified_date: new Date(),
            }
            updateReport({ variables: { rptId: rptId, userId: userId, rpt: newReport } });
        }

        if (formMode === 'verify') {
            let newReport = {
                ...(dutyHolderObject._id && dutyHolderObject._id !== "" && {dh_id: dutyHolderObject._id}),
                ...(assetGroupObject._id && assetGroupObject._id !== "" && {ag_id: assetGroupObject._id}),
                ...(installationObject._id && installationObject._id !== "" && {installation_id: installationObject._id}),
                ...(barrierObject._id && barrierObject._id !== "" && {barrier_id: barrierObject._id}),
                ...(seceObject._id && seceObject._id !== "" && {sece_id: seceObject._id}),
                ...(verScheduleObject._id && verScheduleObject._id !== "" && {vs_id: verScheduleObject._id}),
                ...(verActivityObject._id && verActivityObject._id !== "" && {va_id: verActivityObject._id}),

                //report_nature: values.report_nature,
                //report_freq: values.report_freq,
                report_confidence: confidenceLevel,
                report_comp_sample: values.report_comp_sample,
                report_verifier_id: currentUserId,
                report_current_status: values.report_current_status,
                report_previous_status: values.report_current_status,
                ...(values.report_phase && values.report_phase !== "" && { report_phase: values.report_phase }),
                report_prior_norm: values.report_prior_norm,
                report_time_taken: values.report_time_taken,
                report_discrepancy: getDiscrepancy(values.report_prior_norm, values.report_time_taken),
                report_justification: values.report_justification,

                report_linked_doc: JSON.stringify(linkedDocList),
                report_verification_details: values.report_verification_details,
                ...(values.report_conclusive_statement && values.report_conclusive_statement !== "" && {report_conclusive_statement: values.report_conclusive_statement}),
                report_attachments: values.report_attachments,
                linked_va_ids: va_ids,
                interacting_sece_ids: sece_ids,
                report_synopsis: values.report_synopsis,
                report_bg_activities: JSON.stringify(backGroundActivityList),

                report_abs_ivb: reportAbsIvb,

                report_action: report_action ? report_action : reportAction,
                report_added_date: new Date(),
                report_added_id: userId,

                report_modified_by: userId,
                report_modified_date: new Date(),

                report_archived: false,
                report_status: values.report_status
            }
            createReport({ variables: { rpt: newReport } });
        }

        if (formMode === 'verify-edit') {
            let editReport = {
                ...(dutyHolderObject._id && dutyHolderObject._id !== "" && {dh_id: dutyHolderObject._id}),
                ...(assetGroupObject._id && assetGroupObject._id !== "" && {ag_id: assetGroupObject._id}),
                ...(installationObject._id && installationObject._id !== "" && {installation_id: installationObject._id}),
                ...(barrierObject._id && barrierObject._id !== "" && {barrier_id: barrierObject._id}),
                ...(seceObject._id && seceObject._id !== "" && {sece_id: seceObject._id}),
                ...(verScheduleObject._id && verScheduleObject._id !== "" && {vs_id: verScheduleObject._id}),
                ...(verActivityObject._id && verActivityObject._id !== "" && {va_id: verActivityObject._id}),

                report_ref: reportObject.report_ref,
                //report_nature: values.report_nature,
                //report_freq: values.report_freq,
                report_confidence: confidenceLevel,
                report_comp_sample: values.report_comp_sample,

                ...(!reportHasVerifier && {
                    report_verifier_id: currentUserId,
                    report_added_id: currentUserId,
                    report_added_date: new Date()
                }),

                report_current_status: values.report_current_status,
                ...(previousReportStatusId && previousReportStatusId !== "" && { report_previous_status: previousReportStatusId }),
                ...(values.report_phase && values.report_phase !== "" && { report_phase: values.report_phase }),
                report_prior_norm: values.report_prior_norm,
                report_time_taken: values.report_time_taken,
                report_discrepancy: getDiscrepancy(values.report_prior_norm, values.report_time_taken),
                report_justification: values.report_justification,

                report_linked_doc: JSON.stringify(linkedDocList),
                report_verification_details: values.report_verification_details,
                ...(values.report_conclusive_statement && values.report_conclusive_statement !== "" && {report_conclusive_statement: values.report_conclusive_statement}),
                report_attachments: values.report_attachments,
                linked_va_ids: va_ids,
                interacting_sece_ids: sece_ids,
                report_synopsis: values.report_synopsis,
                report_bg_activities: JSON.stringify(backGroundActivityList),

                report_abs_ivb: reportAbsIvb,

                report_action: report_action ? report_action : reportAction,
                report_status: values.report_status,

                report_modified_by: userId,
                report_modified_date: new Date(),
            }

            updateReport({ variables: { rptId: rptId, userId: userId, rpt: editReport } });
        }
        setSubmitting(false);
    }

    const columns = React.useMemo(
        () => [
          {
            Header: 'Finding Ref',
            accessor: 'finding_ref', // accessor is the "key" in the data
          },
          {
            Header: 'Synopsis',
            accessor: 'finding_synopsis',
          },
          {
            Header: '',
            id: 'buttons',
            accessor: originalRow => originalRow,
            Cell: ({value})=> (
                <div className="text-right">
                        {value._id &&
                            value._id !== "" &&
                            value.workflow_status &&
                            value.workflow_status !== NOTIFICATION_STATUS_PENDING &&
                            value.workflow_status !== NOTIFICATION_STATUS_APPROVED
                            ?
                            <Link
                                to={createLocationFrom({
                                    finding: value,
                                    search: "?origin=activity-report"
                                })}
                                className="button-red mb-1 mr-1 text-xs"
                                target={"_blank"}
                            >Edit</Link>
                            :
                            <></>
                        }

                    {value._id && value._id !== "" ?
                        <Link
                            to={{
                                pathname: `/reporting/finding/view/${value._id}`,
                                search: "?origin=activity-report" 
                            }}
                            className="button-red mb-1 mr-1 text-xs"
                            target={"_blank"}
                        >
                            View
                        </Link> :
                        <Link
                            to={{
                                pathname: `/reporting/verify`,
                                state: { formMode: 'verify', verId: verId, actId: actId }
                            }}
                            className="button-red mb-1 mr-1 text-xs"
                            onClick={() => { handleRemoveFindingRaised(value.finding_ref) }}
                        >
                            Remove
                        </Link>
                    }
                </div>
            )
          },
          ],
        []
      )

    if (((formMode === "verify") || (formMode === "verify-edit")) && shouldSpin) {
        return (
            <PageSpinner />
        )
    }

    if (vsError || vaError || seceError) {
        return (
          <span>Something went wrong retrieving the table data</span>
        )
    }

    if (createReportLoading || updateReportLoading) {
        return (
            <PageSpinner displayText={reportAction === REPORT_ACTION_SAVE ? "saving form" : "submitting form"} />
        )
    }
    
    if (createReportError || updateReportError ) {
        return (
          <span>Something went wrong while submitting the form</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{generatePageTitleFrom(formMode, status)}</h1>
            </div>

            <Formik
                validateOnChange={enableValidation}
                validateOnBlur={enableValidation}
                enableReinitialize={true}
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={validation(formMode, tempFindingList.length, reportAction)}
                onSubmit={(values, {setSubmitting}) => {
                    submitReportLogic(values, setSubmitting);                 
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    setFieldTouched,
                    submitForm,
                    setSubmitting,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="my-4 flex">
                            <Link to={handleBackLink(formMode, verId)} className="button-red mr-2"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>
                        </div>
                        <div className="block overflow-x-auto">
                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    {/* 
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Date Raised</th>
                                        <td className="bg-gray-100">
                                            {values.report_added_date && values.report_added_date !== "" ? formatDateToLocaleDateString(values.report_added_date): formatDateToLocaleDateString(new Date()) }
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Date Closed</th>
                                        <td className="bg-gray-100">
                                            {values.report_closed_date !== "" ? formatDateToLocaleDateString(values.report_closed_date): "N/A"}
                                        </td>
                                    </tr> 
                                    */}
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Duty Holder</th>
                                        <td>
                                            {formMode === 'add' ?
                                                <div className="mt-5">
                                                    <select
                                                        className="form-control block w-full"
                                                        name="report_dh"
                                                        id="report_dh"
                                                        onChange={(event) => { handleDutyHolderChange(event); handleChange(event) }}
                                                        onBlur={handleBlur}
                                                        value={values.report_dh}
                                                    >
                                                        <option value={""}>Select Duty Holder</option>
                                                        {dutyHolderList.map(dh =>
                                                            <option key={dh._id} value={dh._id}>{dh.dh_name}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched.report_dh} message={errors.report_dh} />
                                                </div>
                                                :
                                                <div>
                                                    <input
                                                        name="report_dh"
                                                        id="report_dh"
                                                        value={values.report_dh}
                                                        onChange={()=>{}}
                                                        hidden />
                                                    <span>
                                                        {dutyHolderObject.dh_name && dutyHolderObject.dh_name}
                                                    </span>
                                                    <FormError touched={touched.report_dh} message={errors.report_dh} />
                                                </div>
                                            }
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Asset Group</th>
                                        <td>
                                            {formMode === 'add' ?
                                                <div className="mt-5">
                                                    <select
                                                        className="form-control block w-full"
                                                        name="report_ag"
                                                        id="report_ag"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.report_ag}
                                                        disabled={selectedDutyHolderId === "Select Duty Holder"}
                                                    >
                                                        <option value={""}>Select Asset Group</option>
                                                        {assetGroupList.map(ag =>
                                                            <option key={ag._id} value={ag._id}>{ag.ag_title}</option>
                                                        )}
                                                    </select>
                                                    {/* <FormError touched={touched.report_ag} message={errors.report_ag} /> */}
                                                </div>
                                                :
                                                <div>
                                                    {/* <input
                                                        name="report_ag"
                                                        id="report_ag"
                                                        value={values.report_ag}
                                                        onChange={()=>{}}
                                                        hidden /> */}
                                                    <span>
                                                        {assetGroupObject.ag_title ? assetGroupObject.ag_title : "N/A"}
                                                    </span>
                                                    {/* <FormError touched={touched.report_ag} message={errors.report_ag} /> */}
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Installation</th>
                                        <td className="bg-gray-100">
                                            {formMode === 'add' ?
                                                <div className="mt-5">
                                                    <select
                                                        className="form-control block w-full"
                                                        name="report_installation"
                                                        id="report_installation"
                                                        onChange={(event) => {
                                                            handleChange(event); 
                                                            handleInstallationChange(event);
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.report_installation}
                                                        disabled={selectedDutyHolderId === "Select Duty Holder"}
                                                    >
                                                        <option value={""}>Select Installation</option>
                                                        {installationList.map(inst =>
                                                            <option key={inst._id} value={inst._id}>{inst.installation_title}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched.report_installation} message={errors.report_installation} />
                                                </div>
                                                : 
                                                <div>
                                                    <input
                                                        name="report_installation"
                                                        id="report_installation"
                                                        onChange={()=>{}}
                                                        value={values.report_installation}
                                                        hidden />
                                                    <span>
                                                        {installationObject.installation_title && installationObject.installation_title}
                                                    </span>
                                                    <FormError touched={touched.report_installation} message={errors.report_installation} />
                                                </div>                                               
                                            }
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Barrier</th>
                                        <td className="bg-gray-100">
                                            {formMode === 'add' ?
                                                <div className="mt-5">
                                                    <select
                                                        className="form-control block w-full"
                                                        name="report_barrier"
                                                        id="report_barrier"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.report_barrier}
                                                        disabled={selectedDutyHolderId === "Select Duty Holder"}
                                                    >
                                                        <option value={""}>Select Barrier</option>
                                                        {barrierList.map(bar =>
                                                            <option key={bar._id} value={bar._id}>{bar.bar_title}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched.report_barrier} message={errors.report_barrier} />
                                                </div>
                                                :
                                                <div>
                                                    <input
                                                        name="report_barrier"
                                                        id="report_barrier"
                                                        onChange={()=>{}}
                                                        value={values.report_barrier}
                                                        hidden />
                                                    <span>
                                                        {barrierObject.bar_title && barrierObject.bar_title}
                                                    </span>
                                                    <FormError touched={touched.report_barrier} message={errors.report_barrier} />
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">SECE Ref.</th>
                                        <td>{seceObject.sece_client_ref && seceObject.sece_client_ref}</td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">SECE Name</th>
                                        <td>
                                            {formMode === 'add' ?
                                                <div className="mt-5">
                                                    <select
                                                        className="form-control block w-full"
                                                        name="report_sece"
                                                        id="report_sece"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.report_sece}
                                                        disabled={selectedDutyHolderId === "Select Duty Holder"}
                                                    >
                                                        <option value={""}>Select Sece</option>
                                                        {seceList.map(sece =>
                                                            <option key={sece._id} value={sece._id}>{sece.sece_title}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched.report_sece} message={errors.report_sece} />
                                                </div>
                                                : (
                                                    seceObject && seceObject._id ?
                                                        <Link to={{ pathname: `/seces/view/${seceObject._id}` }}
                                                            className="hover:underline"
                                                            onClick={() => { saveFormValuesToLocalStoarge(actId, values) }}>{seceObject && seceObject.sece_title ? seceObject.sece_title : "N/A"}</Link>
                                                        :
                                                        <span>{seceObject && seceObject.sece_title ? seceObject.sece_title : "N/A"}</span>)
                                            }
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">SECE Criticality</th>
                                        <td className="bg-gray-100">{seceObject.sece_criticality && seceObject.sece_criticality}</td>

                                        <th className="bg-blue-800 font-sans font-semibold text-white">Confidence</th>
                                        <td className="bg-gray-100">{getConfidenceLevel(confidenceLevelVariableData, seceObject.sece_criticality)}</td>
                                    </tr>
                                    
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Verification Activity Ref.</th>
                                        <td>
                                            {verActivityObject && verActivityObject._id && verActivityObject.va_client_ref ? (
                                                <Link
                                                    to={{ pathname: `/verification-activities/view/${verActivityObject._id}` }}                                   
                                                    className="hover:underline"
                                                    target="blank"
                                                >
                                                    {verActivityObject.va_client_ref}
                                                </Link>
                                            ) : (
                                                <span>{"N/A"}</span>
                                            )}
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Verification Activity Short Description</th>
                                        <td>{verActivityObject.va_short_desc && verActivityObject.va_short_desc}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Nature</th>
                                        <td className="bg-gray-100">
                                            {formMode === 'add' ? <div className="mt-5">
                                                <select
                                                    className="form-control block w-full"
                                                    name="report_nature"
                                                    id="report_nature"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.report_nature}
                                                >
                                                    <option value = {""}>Select Nature</option>
                                                    {natureList.map(nature =>
                                                        <option key={nature._id} value={nature._id}>{nature.sysvar_title}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.report_nature} message={errors.report_nature} />
                                            </div> : verActivityObject.va_type && verActivityObject.va_type
                                            }
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Frequency</th>
                                        <td className="bg-gray-100">
                                            {
                                                formMode === 'add' ? <div className="mt-5">
                                                    <select
                                                        className="form-control block w-full"
                                                        name="report_freq"
                                                        id="report_freq"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.report_freq}
                                                    >
                                                        <option value = {""}>Select Frequency</option>
                                                        {frequencyList.map(freq =>
                                                            <option key={freq._id} value={freq._id}>{freq.sysvar_title}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched.report_freq} message={errors.report_freq} />
                                                </div> : verActivityObject.va_freq ? verActivityObject.va_freq : "N/A"
                                            }
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Required Sample</th>
                                        <td>{verActivityObject.va_baseline_sample && verActivityObject.va_baseline_sample}</td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="report_comp_sample">Completed Sample</label></th>
                                        <td>
                                            <div className="mt-5">
                                                <input
                                                    type="text"
                                                    className="form-control block w-full"
                                                    name="report_comp_sample"
                                                    id="report_comp_sample"
                                                    placeholder="Completed Sample"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.report_comp_sample}
                                                />
                                                <FormError touched={touched.report_comp_sample} message={errors.report_comp_sample} />
                                            </div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Previously Completed</th>
                                        <td className="bg-gray-100">{values.report_previously_completed}</td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Previous Verifier</th>
                                        <td className="bg-gray-100">{values.report_previous_verifier}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Previous Status</th>
                                        <td>{previousReportStatusTitle}</td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Previous Findings</th>
                                        <td>
                                            {values.report_previous_findings.length ? values.report_previous_findings.map((finding, index) =>
                                                <>
                                                    <Link
                                                        to={{ pathname: `/reporting/finding/view/${finding._id}` }}
                                                        className="hover:underline"
                                                        target="blank"
                                                    >
                                                        {finding.finding_ref}
                                                    </Link>
                                                    <span>
                                                        {index + 1 < values.report_previous_findings.length ? <span>{", "}</span> : <span></span>}
                                                    </span>
                                                </>
                                            ) : <span>{"N/A"}</span>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Completion Date</th>
                                        <td className="bg-gray-100">
                                            {/* {values.report_closed_date !== "" ? formatDateToLocaleDateString(values.report_closed_date): "N/A"} */}
                                            <div className="mt-5">
                                                <DatePicker
                                                    id="report_closed_date"
                                                    selected={values.report_closed_date}
                                                    dateFormat="dd MMMM Y"
                                                    name="report_closed_date"
                                                    onChange={date => setFieldValue('report_closed_date', date)}
                                                    onBlur={handleBlur}
                                                    value={values.report_closed_date}
                                                    className="form-control block w-full"
                                                    showMonthDropdown
                                                    showYearDropdown                                  
                                                    autoComplete="off"
                                                />
                                                <FormError touched={touched.report_closed_date} message={errors.report_closed_date} />
                                            </div>
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Verifier</th>
                                        <td className="bg-gray-100">{((formMode === "verify") || (formMode === "add")) ? currentUserName : values.report_verifier}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="report_current_status">Current Status</label></th>
                                        <td>
                                            <div className="mt-5">
                                                <select
                                                    className="form-control block w-full"
                                                    name="report_current_status"
                                                    id="report_current_status"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.report_current_status}
                                                >
                                                    <option value = {""}>Select Status</option>
                                                    {activityStatusList.map(status =>
                                                        <option key={status._id} value={status._id}>{status.sysvar_title}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.report_current_status} message={errors.report_current_status} />
                                            </div>
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="report_phase">Phase</label></th>
                                        <td>
                                            <div className="mt-5">
                                                <select
                                                    className="form-control block w-full"
                                                    name="report_phase"
                                                    id="report_phase"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.report_phase}
                                                >
                                                    <option value = {""}>Select Phase</option>
                                                    {phaseList.map(phase =>
                                                        <option key={phase._id} value={phase._id}>{phase.sysvar_title}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.report_phase} message={errors.report_phase} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Time (Minutes)</th>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white"><label htmlFor="report_prior_norm">Prior Norm</label></th>
                                        <td>

                                            <div className="mt-5">
                                                <input
                                                    type="number"
                                                    className="form-control block w-full"
                                                    name="report_prior_norm"
                                                    id="report_prior_norm"
                                                    placeholder="Prior Norm"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.report_prior_norm}
                                                />
                                                <FormError touched={touched.report_prior_norm} message={errors.report_prior_norm} />
                                            </div>

                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white"><label htmlFor="report_time_taken">Time Taken</label></th>
                                        <td>

                                            <div className="mt-5">
                                                <input
                                                    type="number"
                                                    className="form-control block w-full"
                                                    name="report_time_taken"
                                                    id="report_time_taken"
                                                    placeholder="Time Taken"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.report_time_taken}
                                                />
                                                <FormError touched={touched.report_time_taken} message={errors.report_time_taken} />
                                            </div>

                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Discrepancy</th>
                                        <td>{getDiscrepancy(values.report_prior_norm, values.report_time_taken)}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white w-1/2" colSpan="3"><label htmlFor="report_justification">Justification</label></th>
                                        <td colSpan="3" className="bg-gray-100">

                                            <div className="mt-5">
                                                <input
                                                    type="text"
                                                    className="form-control block w-full"
                                                    name="report_justification"
                                                    id="report_justification"
                                                    placeholder="Justification"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.report_justification}
                                                />
                                                <FormError touched={touched.report_justification} message={errors.report_justification} />
                                            </div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white w-1/2" colSpan="3">
                                            <label htmlFor="report_abs_ivb">ABS IVB</label>
                                        </th>
                                        <td colSpan="3" className="">

                                            <div className="mt-5">
                                                <select
                                                    className="form-control block w-full"
                                                    name="report_abs_ivb"
                                                    id="report_abs_ivb"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.report_abs_ivb}
                                                >
                                                    <option value={""}>Select</option>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </select>
                                                <FormError touched={touched.report_abs_ivb} message={errors.report_abs_ivb} />
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Linked Documentation
                                            <span className="float-right">
                                                <FontAwesomeIcon icon={['fas', 'plus']} className="text-white cursor-pointer" onClick={() => toggleLinkedDocModal()} />
                                            </span>
                                        </th>
                                    </tr>
                                    {linkedDocList.map((item, index) =>
                                        <table className="table-report w-full border mb-2" key={index}>
                                            <tbody>
                                                <tr>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white w-1/2" colSpan="3"></th>
                                                    <td colSpan="3" className="bg-blue-800 font-sans font-semibold text-white">
                                                        <span className="float-right">
                                                            <FontAwesomeIcon icon={faTrashAlt} className="text-white cursor-pointer" onClick={() => handleRemoveLinkedDoc(index)} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white w-1/2" colSpan="3">Document Name</th>
                                                    <td colSpan="3" className="bg-gray-100">{item.ld_name}</td>
                                                </tr>
                                                <tr>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white w-1/2" colSpan="3">Detailed Description</th>
                                                    <td colSpan="3">{item.ld_description}</td>
                                                </tr>
                                                <tr>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white w-1/2" colSpan="3">Document Links</th>
                                                    <td colSpan="3">{createFileLinks(item.ld_attachement, "")}</td>
                                                </tr>
                                                {
                                                    /*<tr>
                                                         <th className="bg-blue-900 font-sans font-semibold text-white w-1/2" colSpan="3">Performace Standard Requirement</th>
                                                         <td colSpan="3" className="bg-gray-100">{item.ld_requirement}</td>
                                                     </tr>*/
                                                }
                                            </tbody>
                                        </table>
                                    )}
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">
                                            {vaPerfStand && vaPerfStand._id ?
                                                (<Link to={{ pathname: `/performance-standards/view/${vaPerfStand._id}` }}
                                                    className="hover:underline">
                                                    Performance Standard Criteria
                                                </Link>) :
                                                (<span>Performance Standard Criteria</span>)
                                            }
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                {vaPerfStand.ps_criteria && vaPerfStand.ps_criteria !== "" ? <Sanitiser html={vaPerfStand.ps_criteria} /> : "N/A"}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">
                                            {verActivityObject && verActivityObject._id ? (
                                                <Link
                                                    to={{ pathname: `/verification-activities/view/${verActivityObject._id}` }}
                                                    className="hover:underline"
                                                >
                                                     Detailed Verification Activity Description
                                                </Link>
                                            ) : (
                                                <span> Detailed Verification Activity Description</span>
                                            )}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                {verActivityObject.va_long_desc && verActivityObject.va_long_desc !== "" ? verActivityObject.va_long_desc : "N/A"}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6"><label htmlFor="report_verification_details">Details of Verification Completed</label> <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>

                                            <div>
                                                <TextEditor
                                                    name="report_verification_details"
                                                    id="report_verification_details"
                                                    //onChange={handleChange}
                                                    onChange={details => setFieldValue('report_verification_details', details)}
                                                    //onBlur={handleBlur}
                                                    onBlur={touched => setFieldTouched('report_verification_details', touched)}
                                                    value={values.report_verification_details}
                                                    wrapper="w-full"
                                                />

                                                <FormError touched={touched.report_verification_details} message={errors.report_verification_details} />
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="report_conclusive_statement">Conclusive Statement</label></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="mt-5">
                                                <select
                                                    className="form-control block w-full break-words"
                                                    name="report_conclusive_statement"
                                                    id="report_conclusive_statement"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.report_conclusive_statement}
                                                >
                                                    <option value = {""}>Select Statement</option>
                                                    {statementList.map(st =>
                                                        <option key={st._id} value={st._id}>{st.sysvar_title}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.report_conclusive_statement} message={errors.report_conclusive_statement} />
                                            </div>
                                          
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Findings Raised</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {((values.report_conclusive_statement !== CONCLUSIVE_STATEMENT_FOR_SAVE_ID &&
                                                values.report_conclusive_statement !== "" &&
                                                values.report_conclusive_statement !== "Select Statement") || 
                                                (findingList.length > 0)) &&

                                                <div className="w-full px-8 pb-8">
                                                    <div className="mb-4 pb-6 border-b-2 border-gray-200">
                                                    </div>
                                                    <Link to={{
                                                        pathname: `/reporting/vs/${verId}/finding/${formMode}`,
                                                        state: {
                                                            formMode: formMode,
                                                            vsId: verId,
                                                            actId: actId,
                                                            ...(formMode === "verify-edit" && rptId !== "" && { editRptId: rptId }),
                                                            status: status,
                                                            inst_id: installationObject._id,
                                                            sece_id: seceObject._id,
                                                            origin: "FindingForm",
                                                            type: SINGLE_VA_FINDING_TYPE 
                                                        }
                                                    }}
                                                        className="mr-2 button-red"
                                                        onClick={() => { saveFormValuesToLocalStoarge(actId, values) }}
                                                    >Add Finding</Link>

                                                    <Table columns={columns} data={shortenFndSynopsis([...tempFindingList, ...findingList])} pagination={false} filter={false} />
                                                    <FormError touched={touched.report_findings} message={errors.report_findings} />
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Attachments <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {authContext.user && authContext.user.user_home_dir &&
                                                <FileUploadAlt
                                                    //onAttach={(files) => handleAddAttachment(files)}
                                                    onAttach={(files) => setFieldValue('report_attachments', JSON.stringify(files))}
                                                    id='fileManager'
                                                    buttonText='Attach Files'
                                                    apiURL={REACT_APP_FILE_MANAGER_API}
                                                    toolbarText='Attach'
                                                    homeDir={`/${authContext.user.user_home_dir}/`}
                                                    showDescription = {true}
                                                    {...(formMode === 'verify-edit' && { attachmentList: parseJSONSafely(values.report_attachments) ? parseJSONSafely(values.report_attachments) : [] })}
                                                />
                                            }
                                             {/*<Table columns={columns} data={attachmentList} pagination={false} filter={false} />*/}                                           
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="3">Background Activities <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>

                                    {bckGrdQuestionList.map((question, index) =>
                                        <tr key={question._id}>
                                            <th className={((index + 1) % 2) !== 0 ? "bg-blue-800 font-sans font-semibold text-white w-1/2" : "bg-blue-900 font-sans font-semibold text-white w-1/2"}><label htmlFor={`report_bck_acts_${index}`}>{question.sysvar_title}</label></th>
                                            <td className={((index + 1) % 2) !== 0 ? "" : "bg-gray-100"}>

                                                <div className="mt-5">
                                                    <select
                                                        className="form-control block w-full break-words"
                                                        name={`report_bck_acts_${index}`}
                                                        id={`report_bck_acts_${index}`}
                                                        onChange={(event) => { handleBgActAnswerChange(question, event); handleChange(event) }}
                                                        onBlur={handleBlur}
                                                        value={values[`report_bck_acts_${index}`]}
                                                    >
                                                        <option value = {""}>Select</option>
                                                        {answerOptionList.map(option =>
                                                            <option key={option._id} value={option._id}>{option.sysvar_title}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched[`report_bck_acts_${index}`]} message={errors[`report_bck_acts_${index}`]} />
                                                </div>

                                            </td>
                                            <td className={((index + 1) % 2) !== 0 ? "bg-gray-100" : ""}>
                                                {authContext.user && authContext.user.user_home_dir &&
                                                    <FileUploadAlt
                                                        onAttach={(files) => handleAddBgActEvidence(files, question._id)}
                                                        id='fileManager'
                                                        buttonText='Add Evidence'
                                                        apiURL={REACT_APP_FILE_MANAGER_API}
                                                        toolbarText='Attach'
                                                        homeDir={`/${authContext.user.user_home_dir}/`}
                                                        showDescription={true}
                                                        //handleFileDescription={handleBackActAttachFilesDescription}
                                                        //tempFileList={tempBackActAttachFileList}
                                                        {...(formMode === 'verify-edit' && { attachmentList: getBckGrndEvidence(backGroundActivityList, question._id) ? getBckGrndEvidence(backGroundActivityList, question._id) : [] })}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="7">Directly Linked Activities
                                            <span className="float-right">
                                                <FontAwesomeIcon icon={['fas', 'plus']} className="text-white cursor-pointer" onClick={() => toggleActivitiesModal()} />
                                            </span>
                                        </th>
                                    </tr>

                                    {selectedLinkedActivityList.map((act, index) =>
                                        <React.Fragment key={index}>
                                            {((index + 1) % 2) !== 0 ?
                                                <tr>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">Verification Activity Ref</th>
                                                    <td><Link to={{
                                                        pathname: act.va_client_ref !=="Not Available"? `/reporting/view-linked-activity/${act.id}` : "#",
                                                        state: { formMode: formMode, actId :actId , verId :verId }
                                                       }}
                                                       onClick = {() => {saveFormValuesToLocalStoarge(actId, values)}}
                                                        className="hover:underline">{act.va_client_ref}</Link></td>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">Activity Short Description</th>
                                                    <td>{act.va_short_desc}</td>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">Current Status</th>
                                                    <td className="capitalize">{act.current_status}</td>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">
                                                        <span className="float-right">
                                                            <FontAwesomeIcon icon={faTrashAlt} className="text-white cursor-pointer" onClick={() => handleRemoveLinkedActivity(act)} />
                                                        </span>
                                                    </th>
                                                </tr>
                                                :
                                                <tr>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">Verification Activity Ref</th>
                                                    <td className="bg-gray-100"><Link to="#" className="hover:underline">{act.va_client_ref}</Link></td>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">Activity Short Description</th>
                                                    <td className="bg-gray-100">{act.va_short_desc}</td>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">Current Status</th>
                                                    <td className="bg-gray-100">{act.current_status}</td>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">
                                                        <span className="float-right">
                                                            <FontAwesomeIcon icon={faTrashAlt} className="text-white cursor-pointer" onClick={() => handleRemoveLinkedActivity(act)} />
                                                        </span>
                                                    </th>
                                                </tr>
                                            }
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>

                            <table className="table-report w-full border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="7">Interacting SECEs
                                            <span className="float-right">
                                                <FontAwesomeIcon icon={['fas', 'plus']} className="text-white cursor-pointer" onClick={() => toggleSecesModal()} />
                                            </span>
                                        </th>
                                    </tr>
                                    {selectedInteractingSeceList.map((sece, index) =>
                                        <React.Fragment key={index}>
                                            {((index + 1) % 2) !== 0 ?
                                                <tr>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">SECE Ref.</th>
                                                    <td>{sece.seceClientRef}</td>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">SECE Name</th>
                                                    <td>{sece.seceTitle}</td>
                                                    {/*<th className="bg-blue-800 font-sans font-semibold text-white">Current Status</th>
                                                    <td>{sece.currentStatus}</td>*/}
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">
                                                        <span className="float-right">
                                                            <FontAwesomeIcon icon={faTrashAlt} className="text-white cursor-pointer" onClick={() => handleRemoveInteractingSece(sece)} />
                                                        </span>
                                                    </th>
                                                </tr>
                                                :
                                                <tr>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">SECE Ref.</th>
                                                    <td className="bg-gray-100">{sece.seceClientRef}</td>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">SECE Name</th>
                                                    <td className="bg-gray-100">{sece.seceTitle}</td>
                                                    {/*<th className="bg-blue-900 font-sans font-semibold text-white">Current Status</th>
                                                    <td className="bg-gray-100">{sece.currentStatus}</td>*/}
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">
                                                        <span className="float-right">
                                                            <FontAwesomeIcon icon={faTrashAlt} className="text-white cursor-pointer" onClick={() => handleRemoveInteractingSece(sece)} />
                                                        </span>
                                                    </th>
                                                </tr>
                                            }
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>

                        </div>

                        <>
                            <button type="button"
                                disabled={isSubmitting}
                                className={`${"mt-3 mb-2 mr-1 button-red capitalize" + (isSubmitting ? " cursor-not-allowed" : "")}`} name="save" value="save"
                                onClick={() => {
                                    setEnableValidation(false);                           
                                    setReportAction(REPORT_ACTION_SAVE);
                                    handleSave(values, {setSubmitting, report_action: REPORT_ACTION_SAVE});
                                }}
                            >Save</button>
                            <button type="button" disabled={isSubmitting}
                                className={`${"mt-3 mb-2 mr-1 button-red capitalize" + (isSubmitting ? " cursor-not-allowed" : "")}`} name="save" value="save"
                                onClick={() => {
                                    setEnableValidation(true);                       
                                    setReportAction(REPORT_ACTION_REVIEW);
                                    setTimeout(() => { submitForm() }, 200);
                                }}>Submit</button>

                            <button type="submit" disabled={isSubmitting} style={{ display: "none" }}>
                                Hidden Submit
                            </button>
                        </>
                    </form>
                    )}
            </Formik>

            {/* HELP TIP START*/}
            <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
                    <h3 className="mb-2 text-xl text-blue-900 font-bold">Help Tip</h3>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultricies nunc id vehicula pulvinar. Sed vitae luctus purus. Integer feugiat nisi porta lacus cursus, sed sollicitudin orci mattis. Praesent euismod ornare tortor eget aliquam. Vivamus fermentum convallis erat, at ultrices odio. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
            </div>
            {/* HELP TIP END */}   

            {/* Directly Linked Activities */}
            <div className="actmodal modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleActivitiesModal()}></div>
                <div className="absolute w-full md:w-11/12 lg:w-10/12 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
                    <div className="w-full px-8 pb-8">
                        <div className="pb-3 border-b-2 border-gray-200">
                            <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Directly Linked Activities</h1>
                        </div>
                        <Formik
                            onSubmit={(values, actions) => {
                                toggleActivitiesModal();
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                                isSubmitting
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <table className="w-full mb-5 mt-5 table-auto">
                                        <tbody>
                                            <tr>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">SECE Ref.</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">SECE Title</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">PS Client Ref.</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">PS Name</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">VA Ref.</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">VA Short Description</th>
                                                <th className="p-3 text-right text-blue-900 font-semibold border">Add (N/Y)</th>
                                            </tr>
                                            {linkedActivityList.length ? linkedActivityList.map(va =>
                                                <tr key={va.id}>
                                                    <td className="p-3 border">{va.sece_client_ref}</td>
                                                    <td className="p-3 border">{va.sece_title }</td>
                                                    <td className="p-3 border">{va.ps_ref}</td>
                                                    <td className="p-3 border">{va.ps_title}</td>
                                                    <td className="p-3 border">{va.va_client_ref }</td>
                                                    <td className="p-3 border">{va.va_short_desc}</td>
                                                    <td className="p-3 text-right border">
                                                        <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                            <input type="checkbox"
                                                                name={"va-" + va.id} id={"va-" + va.id}
                                                                className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                                                                checked={selectedLinkedActivityList.some(sva => sva.id === va.id)}
                                                                onChange={() => handleAddLinkedActivity(va.id)}
                                                            />
                                                            <label htmlFor={"va-" + va.id} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) :
                                                <tr>
                                                    <td className="p-3 border">{"Data is not Available."}</td>
                                                    <td className="p-3 text-right border"></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <button
                                        type="button"
                                        className="mt-3 button-red capitalize"
                                        onClick={() => toggleActivitiesModal()}>Apply Selection</button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            {/* Directly Linked Activities */}

            {/* Interacting Seces -> Start */}
            <div className="secemodal modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleSecesModal()}></div>
                <div className="absolute w-full md:w-11/12 lg:w-10/12 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
                    <div className="w-full px-8 pb-8">
                        <div className="pb-3 border-b-2 border-gray-200">
                            <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Interacting Seces</h1>
                        </div>
                        <Formik
                            onSubmit={(values, actions) => {
                                toggleSecesModal();
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                                isSubmitting
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <table className="w-full mb-5 mt-5 table-auto">
                                        <tbody>
                                            <tr>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">SECE Ref.</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">SECE Title</th>
                                                <th className="p-3 text-right text-blue-900 font-semibold border">Add (N/Y)</th>
                                            </tr>
                                            {interactingSeceList.length ? interactingSeceList.map(va =>
                                                <tr key={va.id}>
                                                    <td className="p-3 border">{va.seceClientRef}</td>
                                                    <td className="p-3 border">{va.seceTitle}</td>
                                                    <td className="p-3 text-right border">
                                                        <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                            <input type="checkbox"
                                                                name={"va-" + va.id} id={"va-" + va.id}
                                                                className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                                                                checked={selectedInteractingSeceList.some(sva => sva.id === va.id)}
                                                                onChange={() => handleAddInteractingSece(va.id)}
                                                            />
                                                            <label htmlFor={"va-" + va.id} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) :
                                                <tr>
                                                    <td className="p-3 border">{"Data is not Available."}</td>
                                                    <td className="p-3 text-right border"></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <button
                                        type="button"
                                        className="mt-3 button-red capitalize"
                                        onClick={() => toggleSecesModal()}>Apply Selection</button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            {/* Interacting Seces -> End */}

            {/* Linked Documentation Modal */}
            <div className="linkdocmodal modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleLinkedDocModal()}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
                    <table className="table-report w-full mb-4 border">
                        <tbody>
                            <tr>
                                <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="1">Linked Documentation</th>
                            </tr>
                            <tr>
                                <td>
                                    <div className="mt-5">
                                        <Formik
                                            enableReinitialize
                                            initialValues={linkedDocformInitValues}
                                            onSubmit={(values, {resetForm}) => {                                         
                                                resetForm({});
                                                let ldAttachment =  updateAttachmentDataNotStringified(values.ld_attachement, tempLinkDocAttachFileList);
                                                let tempValues =  {...values, ld_attachement:ldAttachment}
                                                handleLinkedDocFormData(tempValues);                                            
                                                toggleLinkedDocModal();                                                                                             
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                setFieldValue,
                                                handleSubmit,
                                                isSubmitting
                                            }) => (
                                                <form onSubmit={handleSubmit}>

                                                    {authContext.user && authContext.user.user_home_dir &&

                                                        <div className="mb-3">
                                                            <FileUpload
                                                                onAttach={(files) => { setFieldValue('ld_attachement', files) }}
                                                                id='fileManager'
                                                                buttonText='Attach Document'
                                                                apiURL={REACT_APP_FILE_MANAGER_API}
                                                                toolbarText='Attach'
                                                                homeDir={`/${authContext.user&&authContext.user.user_home_dir}/`}
                                                                showDescription={true}
                                                                handleFileDescription={handleLinkDocAttachFilesDescription}
                                                                tempFileList={tempLinkDocAttachFileList}
                                                                {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.ld_attachement) ? parseJSONSafely(values.ld_attachement) : [] })}
                                                            />
                                                        </div>

                                                    }

                                                    <div className="mb-3">
                                                        <label htmlFor="ld_name" className="block mb-1 text-blue-900 font-semibold">Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control block w-full md:w-full"
                                                            name="ld_name"
                                                            id="ld_name"
                                                            placeholder="Name"
                                                            onChange={handleChange}
                                                            onBlur={handleChange}
                                                            value={values.ld_name}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="ld_description" className="block mb-1 text-blue-900 font-semibold">Description</label>
                                                        <textarea
                                                            name="ld_description"
                                                            id="ld_description"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.ld_description}
                                                            className="form-control w-full"
                                                            maxLength="150"
                                                        />
                                                    </div>

                                                    {/*<div className="mb-3">
                                                        <label htmlFor="ld_requirement" className="block mb-1 text-blue-900 font-semibold">Performace Standard Requirement</label>
                                                        <textarea
                                                            name="ld_requirement"
                                                            id="ld_requirement"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.ld_requirement}
                                                            className="form-control w-full"
                                                            maxLength="150"
                                                        />
                                                    </div>*/}

                                                    <button type="submit" className="mt-3 button-red capitalize">Add</button>

                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Linked Documentation Modal */}

        </div>
    );
}

function formatSysVarDataAndUpdateStates(list, setNatureList, setFrequencyList, setPhaseList, setStatementList, setActivityStatusList, setBckGrdQuestionList, setAnswerOptionList, natureTypeId, frequencyTypeId, phaseTypeId, statementTypeId, activityStatusTypeId, questionTypeId, answerTypeId) {
    let natureList = [];
    let frequencyList = [];
    let phaseList = [];
    let statementList = [];
    let activityStatusList = [];
    let questionList = [];
    let answerList = [];

    natureList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === natureTypeId);
    frequencyList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === frequencyTypeId);
    phaseList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === phaseTypeId);
    statementList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === statementTypeId);
    activityStatusList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === activityStatusTypeId);
    questionList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === questionTypeId);
    answerList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === answerTypeId);

    setNatureList(natureList);
    setFrequencyList(frequencyList);
    setPhaseList(phaseList);
    setStatementList(statementList);
    setActivityStatusList(activityStatusList);
    setBckGrdQuestionList(questionList);
    setAnswerOptionList(answerList);
}

function formatVsAndUpdateState(verificationSchedule, reportList, statusList, setVerScheduleObject, setLinkedActivityList, setInteractingSeceList) {
    let linkedActList = [];
    let interactingSeceList = [];
    
    let vaList = verificationSchedule.va_ids ? verificationSchedule.va_ids : [];
    let seceList = verificationSchedule.sece_ids ? verificationSchedule.sece_ids : [];

    vaList.forEach(va => {
        const { sece_client_ref: seceClientRef = "N/A", sece_title: seceTitle = "N/A"  } = va.sece_id ? va.sece_id : {};
        const { ps_ref: psRef = "N/A",  ps_title: psTitle = "N/A",} = va.ps_id ? va.ps_id : {};

        let report = reportList.find(rpt => (rpt.va_id && rpt.va_id._id ? rpt.va_id._id : "") === va._id);
        let status = statusList.find(s => s._id === (report ? report.report_current_status : ""));
        let statusTitle = status ? status.sysvar_title : "Not Commenced";

        let verAct = {
            id: va._id,
            activityNo: va.va_client_ref ? va.va_client_ref : "N/A",
            activityShortDescription: va.va_short_desc,
            seceClientRef: seceClientRef ? seceClientRef : "N/A",
            seceTitle: seceTitle ? seceTitle : "N/A",
            psRef: psRef ? psRef : "N/A",
            psTitle: psTitle ? psTitle : "N/A",
            currentStatus: statusTitle
        }
        linkedActList.push(verAct);
    });

    seceList.forEach(se => {
        let sece = {
            id: se._id,
            seceNo: se.sece_ref ? se.sece_ref : "N/A",
            seceClientRef: se.sece_client_ref ? se.sece_client_ref : "N/A",
            seceTitle: se.sece_title,
            currentStatus: se.sece_status ? se.sece_status : "N/A"
        }
        interactingSeceList.push(sece);
    });

    setVerScheduleObject(verificationSchedule);
    //setLinkedActivityList(linkedActList);
    //setInteractingSeceList(interactingSeceList);
}

function formatInteractingSeces(intSeceList) {
    let newIntSeceList = [];

    if (intSeceList && intSeceList.length) {
        intSeceList.forEach(se => {
            let sece = {
                id: se._id,
                seceNo: se.sece_ref ? se.sece_ref : "N/A",
                seceClientRef: se.sece_client_ref ? se.sece_client_ref : "N/A",
                seceTitle: se.sece_title,
                currentStatus: se.sece_status ? se.sece_status : "N/A"
            }
            newIntSeceList.push(sece);
        });
    }

    return newIntSeceList;
}

function createIdList(list) {
    let newIdList = []
    if (list && list.length) {
       list.forEach(item => {
        newIdList.push(item.id)
       })
    }
    return newIdList
}

//function shouldDisplaySave(statementId, selectedStatementId) {
//    let displayButton;
//    let itemList = ["", "Select Statement", statementId];
//    displayButton = itemList.some(item => item === selectedStatementId);
//    return displayButton;
//}

function createBackgndActForEdit(backgndAct) {
    let bckgndActObject = {};
    if (backgndAct && backgndAct.length) {
        backgndAct.forEach((bckAct, index) => {
            bckgndActObject[`report_bck_acts_${index}`] = bckAct.answer_id
        });
    }
    return bckgndActObject;
}

function getDiscrepancy(priorNorm, timeTaken) {   
    let discrepancy = 0;
    //if (timeTaken > priorNorm) {
    //    discrepancy = (priorNorm + timeTaken);
    //}
    //if (priorNorm > timeTaken) {
    //    discrepancy = (priorNorm - timeTaken);
    //}
    discrepancy = (priorNorm - timeTaken);
    var discripancyString = discrepancy.toString();
    return discripancyString;
}

function createSelectedLinkedVas(vaIdList, linkedVaList) {
    let selectedVaList = [];
    if (vaIdList && vaIdList.length && linkedVaList && linkedVaList.length) {
        vaIdList.forEach(vaOb => {
            let linkedVa = linkedVaList.find(va => va.id === vaOb._id);
            if (linkedVa) {
                selectedVaList.push(linkedVa);
            }
        })
    }
    return selectedVaList;
}

function createSelectedInteractingSeces(seceIdList, interSeceList) {
    let selectedInterSeceList = [];
    if (seceIdList && seceIdList.length && interSeceList && interSeceList.length) {
        seceIdList.forEach(seceOb => {
            let interSece = interSeceList.find(sece=> sece.id === seceOb._id);
            if (interSece) {
                selectedInterSeceList.push(interSece);
            }
        })
    }
    return selectedInterSeceList;
}

function getBckGrndEvidence(list, id) {
    let evidence = [];
    if (list && list.length && id) {
        let tempEvidence = list.find(li => li.question_id === id);
        evidence = tempEvidence ? tempEvidence.evidence : [];
    }
    return evidence;
}

function createFileLinks(attachments, fileDestPath) {
    let linkList = "";
    let hasDestPath = fileDestPath && fileDestPath !== "";
    if (attachments && attachments.length) {
        let fullPath = `${REACT_APP_FILE_MANAGER_API}/download-file?path=${hasDestPath ? ("/" + fileDestPath + "/"):""}`;
        let numberOfAttachments = attachments.length;

        let newAttachments = <React.Fragment>{attachments.map((att, index) =>
            <a href={hasDestPath ? (fullPath + att.name) : (fullPath + att.path + att.name)} className="hover:underline" key={index} target={"_blank"} rel="noopener noreferrer" download={true}>
                {att.name}{((index + 1) < numberOfAttachments) ? <br /> : ""}
            </a>
        )}
        </React.Fragment>
        linkList = newAttachments;
    } else {
        linkList = <Link to="#" className="hover:underline">No File Attachment</Link>
    }

    return linkList;
}

function shortenFndSynopsis(fndList) {
    let newFndList = [];
    if (fndList && fndList.length) {

        fndList.forEach(element => {

            const {_id = "", finding_synopsis = "", finding_status = "", report_id = {} } = element ? element : {};
            let fndSynopsis = truncateString(finding_synopsis, 100);
            let fnd = {
                ...element,
                finding_id:_id, 
                finding_synopsis: fndSynopsis,
                workflow_status: finding_status,
                report_id: report_id._id ? report_id._id : ""
            }

            newFndList.push(fnd);
        });
    }
    return newFndList;
}

function generatePageTitleFrom(formMode, status) {
    let pageTitle = "";
    switch (formMode + "," + status) {
        case "verify-edit,null":
        case "verify":
        case "add":
            pageTitle = "Verify Activity";
            break;
        case "verify-edit,open":
        case "verify-edit,rejected":
        case "verify-edit,revision":
        case "edit":
            pageTitle = "Edit Activity";
            break;
        default:
            break;
    }
    return pageTitle;
}

export default AddEditReport;