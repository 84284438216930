/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { useParams, useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {omit} from 'lodash';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";

const Validation = Yup.object().shape({
    sv_name: Yup.string().label("sv_name").required("Name is required"),
});

const CREATE_SYSTEM_VARIABLE = gql`
    mutation CreateSystemVariable($sysVar: CreateSystemVariable!) {
        createSystemVariable(sysVar: $sysVar) {
            _id
            sysvar_title
            sysvar_notes
            success
            status_code
            message
        }
    }
`;

function AddEditSystemVariables(props) {

    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const { formMode, sysVarTypeId = "" } = useParams();
    const [formInitValues] = useState({sv_name:"",sv_notes:""});

    const [createSystemVariable, {data: createSystemVariableData}] = useMutation(CREATE_SYSTEM_VARIABLE);

    useEffect(() => {
        if (authContext && authContext.user){
        let result = createUserTypeAndRoleObject(authContext);
        setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);


    useEffect(() => {
        if (createSystemVariableData && createSystemVariableData.createSystemVariable) {
            const {success, message} = createSystemVariableData.createSystemVariable;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push(`/system-settings/variables/${sysVarTypeId}`);
            }    
        }
    }, [createSystemVariableData]);

    return(
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Value</h1>           
            </div>

            <Formik 
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={Validation}
                onSubmit={(values, actions) => {
                    if (formMode === 'add') {
                        const tempValues = {
                            sysvar_title: values.sv_name,
                            sysvar_notes: values.sv_notes,
                            sysvartype_id:sysVarTypeId,
                            sysvar_added_id: authContext.user._id,
                            sysvar_archived_date: new Date()
                        }  
                        createSystemVariable({variables: {sysVar: tempValues}})
                    }
                }}
            >
            {({
                values, 
                errors, 
                touched, 
                handleChange, 
                handleBlur, 
                setFieldValue, 
                handleSubmit, 
                isSubmitting
            }) => (
                <form onSubmit={handleSubmit}>

                    {/*
                    <div className="my-4">
                        <Link to={`/system-settings/variables/${sysVarTypeId}`} className="button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>
                    </div> */}

                    <div className="mt-6">
                        <label htmlFor="sv_name" className="block mb-1 text-blue-900 font-semibold">Name*</label>
                        <input 
                            type="text" 
                            className="form-control block w-full md:w-1/2" 
                            name="sv_name" 
                            id="sv_name" 
                            placeholder="Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sv_name}
                        />
                        <FormError touched={touched.sv_name} message={errors.sv_name} />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="sv_notes" className="block mb-1 text-blue-900 font-semibold">Notes</label>
                        <textarea
                            type="text" 
                            className="form-control block w-full md:w-1/2" 
                            name="sv_notes" 
                            id="sv_notes" 
                            placeholder="Notes"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sv_notes}
                        />
                    </div>

                    <button type="submit" className="mt-3 button-red capitalize">{formMode} Value</button>

                </form>
                )}
            </Formik>

        </div>
    )
}

export default AddEditSystemVariables;

