/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useContext} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import Table from '../global/Table';
import TableDropDown from '../global/TableDropDown';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import {AuthContext} from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import {capitaliseFirstCase, createUserTypeAndRoleObject} from "../../utils/index";
import BreadCrumb from "../global/Breadcrumb";
import {NOTIFICATION_STATUS_APPROVED, NOTIFICATION_STATUS_REJECT, NOTIFICATION_STATUS_PENDING, NOTIFICATION_STATUS_REVISION} from "../../constants";
import Comment from '../global/Comment';
import {sysObjects} from "../../constants/sysObjectList";

const dropdownListItems = ({dutyHolderId, assetGroupId, installationId, barrierId, seceId}) => (
  assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined ?
  [
    {
      id: 1,
      name: 'Performance Standards',
      link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standards`
    },
    {
      id: 2,
      name: 'Reference Activities',
      link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/reference-activities`
    }
  ]
  : assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined ?
  [
    {
      id: 1,
      name: 'Performance Standards',
      link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standards`
    },
    {
      id: 2,
      name: 'Reference Activities',
      link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/reference-activities`
    }
  ]
  : null
);

const SECES_BY_INST_ID_QUERY = gql`
    query SecesByInstallationId ($installationId: ID!){
        secesByInstallationId (installationId: $installationId){
            _id
            sece_title
            sece_ref
            sece_client_ref
            sece_criticality
            sece_group
            sece_archived
            sece_archived_id
            sece_archived_date
            bar_id{
                bar_title
            }
            sece_status
        }
    }
`;
const SECES_BY_BAR_ID_QUERY = gql`
    query SecesByBarrierId ($barrierId: ID!){
        secesByBarrierId (barrierId: $barrierId){
            _id
            sece_title
            sece_ref
            sece_client_ref
            sece_criticality
            sece_group
            sece_archived
            sece_archived_id
            sece_archived_date
            bar_id{
                bar_title
            }
            sece_status
        }
    }
`;

const UPDATE_SECE = gql`
    mutation UpdateSece($seceId: String!, $sece: UpdateSeceInput!, $userId: String!){
        updateSece(_id: $seceId, sece: $sece, userId: $userId){
            _id
        }
    }`;

function Seces(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [seceList, setSeceList] = useState([]);
  const [showArchivedSeces, setShowArchivedSeces] = useState(false);
  const [drDnInstallation, setDrDnInstallation] = useState({});

  const {id, assetGroupId, installationId, barrierId} = useParams();
  const {location: {state = ""}, match: {url = ""}} = props ? props : {};

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const history = useHistory();

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
     const { installation } = navContext;
        const { id: prevId } = drDnInstallation;
    
        if (prevId && installation.id && prevId !== installation.id) {
          history.push('/');
        }
        setDrDnInstallation(installation);
  }, [navContext]);

  const [secesByInstallationId, {
    loading: secesByInstIdLoading,
    error: secesByInstIdError,
    data: secesByInstIdData,
    refetch: refetchSecesByInstId
  }] = useLazyQuery(SECES_BY_INST_ID_QUERY);
  const [secesByBarrierId, {
    loading: secesByBarIdLoading,
    error: secesByBarIdError,
    data: secesByBarIdData,
    refetch: refetchSecesByBarId
  }] = useLazyQuery(SECES_BY_BAR_ID_QUERY);

  const [updateSece, {data: updateSeceData}] = useMutation(UPDATE_SECE);

  useEffect(() => {
    if (state === "archived") {
      setShowArchivedSeces(true);
    };
  }, []);

  useEffect(() => {

    if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined) {
      secesByInstallationId({ variables: { installationId: installationId }, errorPolicy: 'all' })
    };
    if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined) {
      secesByBarrierId({ variables: { barrierId: barrierId }, errorPolicy: 'all' });
    };

  }, [showArchivedSeces]);

  useEffect(() => {
    if (secesByInstIdData && secesByInstIdData.secesByInstallationId) {
      const {_id: userId = ""} = authContext.user ? authContext.user : {};
      let tempSeceList = formatSecesData(secesByInstIdData.secesByInstallationId, showArchivedSeces, userId, state, userTypeAndRoleObject);
      return setSeceList(tempSeceList);
    }
  }, [secesByInstIdData]);

  useEffect(() => {
    if (secesByBarIdData && secesByBarIdData.secesByBarrierId) {
      const {_id: userId = ""} = authContext.user ? authContext.user : {};
      let tempSeceList = formatSecesData(secesByBarIdData.secesByBarrierId, showArchivedSeces, userId, state, userTypeAndRoleObject); 
      return setSeceList(tempSeceList);
    }
  }, [secesByBarIdData]);

  useEffect(() => {
    if (updateSeceData && updateSeceData.updateSece) {

      if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined) {
        refetchSecesByInstId();
      };
      if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined) {
        refetchSecesByBarId();
      };

    }
  }, [updateSeceData]);

  const archiveManager = (seceId, isArchived, archivedId, archivedDate, userId) => {
    if (userId !== "") {
      const payload = {
        sece_archived: isArchived ? false : true,
        sece_archived_id: isArchived ? archivedId : userId,
        sece_archived_date: isArchived ? archivedDate : new Date()
      }
      updateSece({variables: {seceId: seceId, sece: payload, userId: userId}});
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'SECE Ref.',
        accessor: 'sece_client_ref',
      },
      {
        Header: 'SECE',
        accessor: 'sece_title',
      },
      {
        Header: 'Criticality',
        accessor: 'sece_criticality',
      },
      {
        Header: 'SECE Group',
        accessor: 'sece_group',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({value: {_id, sece_archived, sece_archived_id, sece_archived_date, user_id, state, sece_status, userTypeAndRoleObject}}) => {
          return (
            <div className="text-right">

              <Comment sysObjName={sysObjects.sece}
                sysObjRecordId={_id}
                buttonName={"Comments"}
                buttonClassName={"button-red mb-1 mr-1 text-xs"} />

              {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                {sece_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                  (sece_status === NOTIFICATION_STATUS_APPROVED ||
                    sece_status === NOTIFICATION_STATUS_REJECT ||
                    sece_status === NOTIFICATION_STATUS_REVISION) &&
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/edit/${_id}`,
                      state: { formMode: 'edit', archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    Edit
                  </Link>}
                </div>
              }
              {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {sece_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (sece_status === NOTIFICATION_STATUS_APPROVED ||
                      sece_status === NOTIFICATION_STATUS_REJECT ||
                      sece_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/edit/${_id}`,
                        state: { formMode: 'edit', archivedStatus: state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }

              {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" && sece_status !== NOTIFICATION_STATUS_PENDING &&
                (sece_status === NOTIFICATION_STATUS_APPROVED ||
                  sece_status === NOTIFICATION_STATUS_REJECT ||
                  sece_status === NOTIFICATION_STATUS_REVISION) &&
                <Link
                  to="#"
                  className="button-red mb-1 mr-1 text-xs"
                  onClick={() => {
                    archiveManager(_id, sece_archived, sece_archived_id, sece_archived_date, user_id)
                  }}
                >
                  {sece_archived ? 'Unarchive' : 'Archive'}
                </Link>
              }

              {sece_status !== NOTIFICATION_STATUS_REJECT &&
                <TableDropDown title="More" items={dropdownListItems({
                  dutyHolderId: id,
                  assetGroupId: assetGroupId,
                  installationId: installationId,
                  barrierId: barrierId,
                  seceId: _id
                })} />}

              {sece_status === NOTIFICATION_STATUS_REJECT && <Link
                to="#"
                className="button-red mr-1 text-xs"
                style={{ textDecorationLine: 'line-through' }}
              >
                More
              </Link>
              }

            </div>
          )
        }
      },
    ],
    []
  )

  if (secesByInstIdLoading || secesByBarIdLoading) {
    return (
      <PageSpinner/>
    )
  }

  if (secesByInstIdError || secesByBarIdError) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold">SECEs <FontAwesomeIcon icon={['fas', 'info-circle']}
                                                                                          className="ml-1 text-blue-900 cursor-pointer"
                                                                                          onClick={() => setShowHelp(!showHelp)}/>
        </h1>

        {showHelp &&
        <div>
          {showArchivedSeces === false ?
          <div>
            <p className="mt-3">A table of active safety and environmental critical elements (SECEs), each entry can be viewed, edited and archived.  Click 'More' for additional options.</p>
            <p>Add a new SECE by clicking 'Add SECE'.</p>
          </div>
          :
          <div>
            <p className="mt-3">A table of archived SECEs, each entry can be viewed and unarchived.  Click 'More' for additional options.</p>
          </div>
          }
        </div>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <BreadCrumb payload={JSON.stringify({
        dh_id: id,
        ag_id: assetGroupId,
        installation_id: installationId,
        bar_id: barrierId,
        last_crumb: 'SECEs'
      })}/>

      {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined &&
      <div className="inline">
        <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barriers`}
              className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
                                                                          className="text-white"/> Back</Link>

        {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" &&
        <Link
          to={{
            pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/add`,
            state: {formMode: 'add', archivedStatus: state}
          }}
          className="button-red mr-2 mb-2 py-3 px-5"
        >
          Add SECE
        </Link>
        }
      </div>
      }
      
      {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined &&
      <div className="inline">
        <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installations`}
              className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
                                                                          className="text-white"/> Back</Link>
        {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" &&
        <Link
          to={{
            pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/add`,
            state: {formMode: 'add', archivedStatus: state}
          }}
          className="button-red mr-2 mb-2 py-3 px-5"
        >
          Add SECE
        </Link>
        }
      </div>
      }

      <Link to={{
        pathname: showArchivedSeces ? url : (url + "/archived"),
        state: showArchivedSeces ? "" : "archived"
      }}
            className="button-red mr-2 mb-2 py-3 px-5"
            onClick={() => {
              setShowArchivedSeces(!showArchivedSeces)
            }}
            style={{backgroundColor: showArchivedSeces ? '#a70000' : ''}}
      >
        {showArchivedSeces ? "Active SECEs" : "Archived SECEs"}
      </Link>

      {seceList.length > 0 && assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined &&
        <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/seces/histories`} className="button-red mb-2 mr-2 py-3 px-5">History</Link>
      }
      {seceList.length > 0 && assetGroupId !== undefined && installationId !== undefined && barrierId === undefined &&
        <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/seces/histories`} className="button-red mb-2 mr-2 py-3 px-5">History</Link>
      }

      {seceList.length !== 0 ?
        <Table columns={columns} data={seceList}/>
        : <h4 className="mt-4">There are currently no SECEs</h4>}

    </div>
  )
}

function formatSecesData(seceList, showArchivedSeces, userId, state, userTypeAndRoleObject) {
  let newSeceList = [];

  if (seceList && seceList.length && userId !== "") {
    let tempSeceList = seceList.filter(sece => showArchivedSeces ? sece.sece_archived === true : sece.sece_archived !== true);
    tempSeceList.forEach(sece => {
      let formatedStatus = capitaliseFirstCase(sece.sece_status);
      let seceClientRef = sece.sece_client_ref && sece.sece_client_ref !== "" ? sece.sece_client_ref : "Not Available";
      let newSece = {
        ...sece,
        sece_client_ref: seceClientRef,
        status: formatedStatus,
        user_id: userId,
        state: state,
        userTypeAndRoleObject: userTypeAndRoleObject
      };
      newSeceList.push(newSece);
    })
  };

  return newSeceList;
}

export default Seces;