/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import {formatDateToLocaleDateString, capitaliseFirstCase} from "../../utils";

const ASSET_GROUP_HISTORIES_QUERY = gql`
query AssetGroupHistory($dhId: String){
    asset_group_history_by_duty_holder_id(dhId: $dhId) {
        _id
        dh_id {
            _id
        }
        ag_title
        ag_notes
        ag_child_id
        ag_added_date
        ag_added_id
        ag_status
        history_parent_record_id
        history_modifying_user_id {
            _id
            user_fname
            user_lname
            user_email
        }
        history_modified_date
        history_modified_date_timestamp
    }
}
`;

function AssetGroupHistories() {
  const [showHelp, setShowHelp] = useState(false);
  const [assetGroupHistories, setAssetGroupHistories] = useState([]);

  const { id: dhId } = useParams();

  const [asset_group_history_by_duty_holder_id, { loading, error, data }] = useLazyQuery(ASSET_GROUP_HISTORIES_QUERY);

  useEffect(() => {
    asset_group_history_by_duty_holder_id({ variables: { ...(dhId && dhId !== "" && { dhId: dhId }) }, errorPolicy: 'all' });
  }, [])

  useEffect(() => {
    if (data && data.asset_group_history_by_duty_holder_id) {
      let hisList = data.asset_group_history_by_duty_holder_id;
      let formatedHisList = formatAGHistoryData(hisList);
      let reversedHistoryList = formatedHisList.reverse();
      return setAssetGroupHistories(reversedHistoryList);
    }
  }, [data])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Access Group Name',
        accessor: 'ag_title',
      },
      {
        Header: 'Modified Date',
        accessor: "history_modified_date"
      },
      {
        Header: 'Modifying User',
        accessor: "modifying_user",
      },
      {
        Header: 'Status',
        accessor: "status",
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ag => (
          <div className="text-right">
            <Link
              to={{
                pathname: `/dutyholder/${ag.value.dhId}/asset-group/view-diff/${ag.value.id}/${ag.value.parentId}`,
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              View Change
            </Link>
          </div>
        )
      },
    ],
    []
  )

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200 mb-6">

        <h1 className="mb-3text-blue-900 font-sans text-2xl font-bold uppercase">Asset Group History<FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3 mb-3">A table detailing changes to Asset Groups.</p>
        }

      </div>

      <Link to={`/dutyholder/${dhId}/asset-groups`} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>

      <Table columns={columns} data={assetGroupHistories} />

    </div>
  )
}

function formatAGHistoryData(histories) {
  let historyList = []

  if (histories && histories.length) {

    histories.forEach(his => {
      const { _id, dh_id, ag_title, ag_status, history_modified_date, history_modifying_user_id, history_parent_record_id } = his ? his : {};
      const { _id: dhId = "" } = dh_id ? dh_id : {};
      const { user_fname = "", user_lname = "" } = history_modifying_user_id ? history_modifying_user_id : {}
      let userFullName = (user_fname !== "" && user_lname !== "") ? `${user_fname} ${user_lname}` : "N/A";

      let newHistory = {
        id: _id,
        parentId: history_parent_record_id,
        dhId: dhId,
        ag_title: ag_title && ag_title !== "" ? ag_title : "N/A",
        history_modified_date: history_modified_date && history_modified_date !== "" ? formatDateToLocaleDateString(history_modified_date) : "N/A",
        modifying_user: userFullName,
        status: ag_status && ag_status !== "" ? capitaliseFirstCase(ag_status) : "N/A"
      }

      historyList.push(newHistory);
    });
  }

  return historyList;
}

export default AssetGroupHistories;