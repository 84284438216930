import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FileUpload from "../global/FileUpload";

import {AuthContext} from "../../context/authContext"; 

const {REACT_APP_FILE_MANAGER_API} = process.env;

function FileManagement() {

  const authContext = useContext(AuthContext);

  const [showHelp, setShowHelp] = useState(false);

  //TITLE / INFO & MAIN BUTTON SECTION
  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">File Management <FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)}/></h1>

        {showHelp &&
        <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam condimentum a lectus non
          condimentum. Aliquam rhoncus, enim vitae condimentum pharetra, urna magna elementum sapien, non vehicula
          turpis tellus nec velit. Vivamus tristique venenatis neque, eget tristique mauris suscipit aliquet.
          Pellentesque et massa nunc.</p>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <h6 className="mb-6 font-bold text-sm text-red-900">Documentation - File Management</h6>

      <Link 
        to={{
          pathname: '/documentation',
        }}      
        className="button-red mr-2 mb-2 py-3 px-5"
      >
        Documentation
      </Link>

      {authContext.user && authContext.user.user_home_dir &&
      <div className="mt-6">
        <FileUpload
          onAttach={(files) => console.log(files)}
          id='fileManager'
          buttonText='View Files'
          apiURL={REACT_APP_FILE_MANAGER_API}
          toolbarText='Attach'
          homeDir={`/${authContext.user.user_home_dir}/`}
          isFileManager={true}
        />
      </div>
      }

    </div>
  )
  //TITLE / INFO & MAIN BUTTON SECTION - END
}

export default FileManagement;
