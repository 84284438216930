/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useContext} from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import DatePicker from 'react-datepicker';
import TableDropDown from '../global/TableDropDown';
import "react-datepicker/dist/react-datepicker.css";
import Table from '../global/Table';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from '../global/pageSpinner';
import { AuthContext } from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";
import {REPORT_ACTION_SAVE} from '../../constants/';

const dropdownListItems = ({id, is_archived, archived_id, archived_date, user_id, archiveManager}) => {
    let listItems = [
        {
            id: 3,
            name: 'View Report',
            link: `/reporting/view/${id}`
        },
        {
            id: 4,
            name: 'Edit',
            link: `/reporting/edit/${id}`,
            state: { formMode: 'edit' }
        },
        {
            id: 5,
            name: is_archived? 'Unarchive':'Archive',
            link: "",
            callback: () => {archiveManager(id, is_archived, archived_id, archived_date, user_id)}
        }
    ];

    return listItems;
}

const REPORTS_BY_FILTER_PARAMS_QUERY = gql`
query ReportSearch($param: ReportSearchInput!){
    reportSearch(filterParam: $param) {
      _id
      dh_id{
        _id
        dh_name
      }
      installation_id{
        _id
        installation_title
      }  
      report_added_date
      report_added_id{
        user_fname
        user_lname
      }
      report_archived
      report_archived_id
      report_archived_date
      report_status
  }
}`;

const UPDATE_REPORT = gql`
    mutation UpdateReport($rptId: String!, $userId: String, $rpt: UpdateReportInput!) {
        updateReport(_id: $rptId, userId: $userId, rpt: $rpt) {
            _id
        }
    }
`;

const DUTY_HOLDERS_QUERY = gql`
    query {
        dutyHolders {
            _id
            dh_name
            dh_status
            dh_added_date
            dh_archived
        }
}`;

const ASSETGROUPS_BY_DH_ID_QUERY = gql`
query AssetGroupsByDutyHolderId($dhId: ID){
    assetGroupsByDutyHolderId(dhId: $dhId) {
      _id
      ag_title
      ag_archived
      ag_status
  }
}`;

const BARRIERS_BY_DH_ID_QUERY = gql`
query BarrierByDutyHolderId($dhId: ID){
    barrierByDutyHolderId(dhId: $dhId) {
       _id
       bar_title
       bar_archived
       bar_status
    }
}`;

const INSTALLATIONS_BY_DH_ID_QUERY = gql`
query installationsByDutyHolderId($dhId: ID){
    installationsByDutyHolderId(dhId: $dhId) {
      _id
      installation_title
      installation_archived
      installation_status
  }
}`;

const SECES_BY_DH_ID_QUERY = gql`
query SecesByDutyHolderId($dhId: ID){
  secesByDutyHolderId(dhId: $dhId) {
       _id
       sece_title
       sece_archived
       sece_status
    }
}`;

const VA_BY_DH_ID_QUERY = gql`
query VerificationActivitiesByDutyHolderId($dhId: ID!){
    verificationActivitiesByDutyHolderId(dhId: $dhId){
        _id    
        va_short_desc
        va_archived
        va_status
    }
}`;

const RA_BY_DH_ID_QUERY = gql`
query referenceActivitiesByDutyHolderId($dhId: ID!){
    referenceActivitiesByDutyHolderId(dhId: $dhId){
        _id    
        ra_title
        ra_archived
        ra_status
    }
}`;

const PS_BY_DH_ID_QUERY = gql`
query PerformanceStandardsByDutyHolderId($dhId: ID!){
    performanceStandardsByDutyHolderId(dhId: $dhId){
        _id    
        ps_title
        ps_archived
        ps_status
    }
}`;

function Reporting(props) {
    const [showHelp, setShowHelp] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [showArchivedReports, setShowArchivedReports] = useState(false);

    const [selectedDutyHolderId, setSelectedDutyHolderId] = useState('Select Duty Holder');

    const [dutyHolderList, setDutyHolderList] = useState([]);
    const [assetGroupList, setAssetGroupList] = useState([]);
    const [barrierList, setBarrierList] = useState([]);
    const [installationList, setInstallationList] = useState([]);
    const [seceList, setSeceList] = useState([]);

    const [vaList, setVaList] = useState([]);
    const [raList, setRaList] = useState([]);
    const [psList, setPsList] = useState([]);

    const [authorList, setAuthorList] = useState([]);

    const authContext = useContext(AuthContext);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const { location: { state = "" }, match: { url = "" } } = props ? props : {};

    const [dutyHolders, {data: dutyHoldersData}] = useLazyQuery(DUTY_HOLDERS_QUERY);
    const [assetGroupsByDutyHolderId, {data: assetGroupsData}] = useLazyQuery(ASSETGROUPS_BY_DH_ID_QUERY);
    const [barrierByDutyHolderId, {data: barriersData}] = useLazyQuery(BARRIERS_BY_DH_ID_QUERY);
    const [installationsByDutyHolderId, {data: installsData}] = useLazyQuery(INSTALLATIONS_BY_DH_ID_QUERY);
    const [secesByDutyHolderId, {data: secesData}] = useLazyQuery(SECES_BY_DH_ID_QUERY);

    const [verificationActivitiesByDutyHolderId, {data: vaData}] = useLazyQuery(VA_BY_DH_ID_QUERY);
    const [referenceActivitiesByDutyHolderId, {data: raData}] = useLazyQuery(RA_BY_DH_ID_QUERY);
    const [performanceStandardsByDutyHolderId, {data: psData}] = useLazyQuery(PS_BY_DH_ID_QUERY);

    const [reportSearch, { loading, error, data: reportSearchData, refetch }] = useLazyQuery(REPORTS_BY_FILTER_PARAMS_QUERY);
    const [updateReport, { data: updateReportData }] = useMutation(UPDATE_REPORT);

    useEffect(() => {
        if (authContext && authContext.user){
           let result = createUserTypeAndRoleObject(authContext);
           setUserTypeAndRoleObject(result);
        }
    }, [authContext]);
    
    useEffect(() => {
        if (state === "archived") {
            setShowArchivedReports(true);
        };

        dutyHolders({ variables: {}, errorPolicy: 'all' });
        setAuthorList([]) //Temp Measure
    }, []);

    useEffect(() => {
        if (selectedDutyHolderId !== 'Select Duty Holder') {
            fetchMultiSelectData(selectedDutyHolderId)
        }
    }, [selectedDutyHolderId]);

    useEffect(() => {
        const { myRptFilterKey = "", instId = "" } = state ? state : {}
        if (myRptFilterKey !== "" && myRptFilterKey !== "na") {
            const param = {
                report_status: myRptFilterKey,
                ...((instId && instId !== "") && { installation_id: instId })
            };
            reportSearch({ variables: { param: param }, errorPolicy: 'all' });
        } else {
            reportSearch({ variables: { param: {} }, errorPolicy: 'all' });
        }
    }, [showArchivedReports, url]);

    useEffect(() => {
        if (dutyHoldersData && dutyHoldersData.dutyHolders) {
            let list = dutyHoldersData.dutyHolders;
            let newDutyHolderList = list.filter(dh => dh.dh_status === "approved" && dh.dh_archived !== true);
            setDutyHolderList(newDutyHolderList)
        }
    }, [dutyHoldersData]);

    useEffect(() => {
        if (assetGroupsData && assetGroupsData.assetGroupsByDutyHolderId) {
            let list = assetGroupsData.assetGroupsByDutyHolderId;
            let newAssetGroupList = list.filter(ag => ag.ag_status === "approved" && ag.ag_archived !== true);
            setAssetGroupList(newAssetGroupList);
        }
    }, [assetGroupsData]);

    useEffect(() => {
        if (barriersData && barriersData.barrierByDutyHolderId) {
            let list = barriersData.barrierByDutyHolderId;
            let newBarrierList = list.filter(bar => bar.bar_archived !== true);
            setBarrierList(newBarrierList);   
        }
    }, [barriersData]);

    useEffect(() => {
        if (installsData && installsData.installationsByDutyHolderId) {
            let list = installsData.installationsByDutyHolderId;
            let newInstallList = list.filter(install => install.installation_archived !== true);
            setInstallationList(newInstallList);
        }
    }, [installsData]);

    useEffect(() => {
        if (secesData && secesData.secesByDutyHolderId) {
            let list = secesData.secesByDutyHolderId;
            let newSeceList = list.filter(sece => sece.sece_archived !== true);
            setSeceList(newSeceList);       
        }
    }, [secesData]);


    useEffect(() => {
        if (vaData && vaData.verificationActivitiesByDutyHolderId) {
            let list = vaData.verificationActivitiesByDutyHolderId;
            let verActList = list.filter(va => va.va_archived !== true);
            setVaList(verActList);   
        }
    }, [vaData]);

    useEffect(() => {
        if (raData && raData.referenceActivitiesByDutyHolderId) {
            let list = raData.referenceActivitiesByDutyHolderId;
            let refActList = list.filter(ra => ra.ra_archived !== true);
            setRaList(refActList);
        }
    }, [raData]);

    useEffect(() => {
        if (psData && psData.performanceStandardsByDutyHolderId) {
            let list = psData.performanceStandardsByDutyHolderId;
            let perfStanList = list.filter(ps => ps.ps_archived !== true);
            setPsList(perfStanList);       
        }
    }, [psData]);

    useEffect(() => {
        if (reportSearchData && reportSearchData.reportSearch) {
            const { _id: userId = "" } = authContext.user ? authContext.user : {};
            let formatedReportList = formatReportsData(reportSearchData.reportSearch, showArchivedReports, userId, state, userTypeAndRoleObject);
            setReportList(formatedReportList)
        }
    }, [reportSearchData]);

    useEffect(() => {
        if (updateReportData && updateReportData.updateReport) {
            refetch();
        }
    }, [updateReportData]);

    const fetchMultiSelectData = (dutyholderId) => {
        assetGroupsByDutyHolderId({ variables: { dhId: dutyholderId }, errorPolicy: 'all' });
        barrierByDutyHolderId({ variables: { dhId: dutyholderId }, errorPolicy: 'all' });
        installationsByDutyHolderId({ variables: { dhId: dutyholderId }, errorPolicy: 'all' });
        secesByDutyHolderId({ variables: { dhId: dutyholderId }, errorPolicy: 'all' });

        verificationActivitiesByDutyHolderId({ variables: { dhId: dutyholderId }, errorPolicy: 'all' });
        referenceActivitiesByDutyHolderId({ variables: { dhId: dutyholderId }, errorPolicy: 'all' });
        performanceStandardsByDutyHolderId({ variables: { dhId: dutyholderId }, errorPolicy: 'all' });
    }

    const handleDutyHolderChange = (event) => {
        let value = event.target.value;
        setSelectedDutyHolderId(value);
    };

    const archiveManager = (reportId, isArchived, archivedId, archivedDate, userId) => {
        if (reportId !== "") {
            const payload = {
                report_archived: isArchived ? false : true,
                report_archived_id: isArchived ? archivedId : userId,
                report_archived_date: isArchived ? archivedDate : new Date(),
                report_action : REPORT_ACTION_SAVE
            }
            updateReport({ variables: { rptId: reportId, userId: userId, rpt: payload } });
        }
    }

    function toggleModal() {
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Duty Holder',
                accessor: 'dh_name',
            },
            {
                Header: 'Installation',
                accessor: 'installation_title',
            },
            {
                Header: 'Report Ref.',
                accessor: 'report_ref',
            },
            {
                Header: 'Report Desc.',
                accessor: 'report_description',
            },
            {
                Header: 'Report Date',
                accessor: 'report_date',
            },
            {
                Header: 'Author',
                accessor: 'report_auth',
            },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: ({ value: { id, is_archived, archived_id, archived_date, user_id, userTypeAndRoleObject } }) => (
                    <div className="text-right" style={{ minWidth: '75px' }}>

                        {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" && 
                            <TableDropDown title="More" items={dropdownListItems({ id, is_archived, archived_id, archived_date, user_id, archiveManager })} />
                        }
                        {userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS" && 
                            <Link to={{
                                pathname: `/reporting/view/${id}`,
                            }}
                                className="button-red mb-1 mr-1 text-xs">
                                View Report
                            </Link>
                        }
                    </div>
                )
            },
        ],
        []
    )

    if (loading) {
        return (
          <PageSpinner/>
        )
      }
    
      if (error) {
        return (
          <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-6 border-b-2 border-gray-200">

                <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Reporting <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                    <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam condimentum a lectus non condimentum. Aliquam rhoncus, enim vitae condimentum pharetra, urna magna elementum sapien, non vehicula turpis tellus nec velit. Vivamus tristique venenatis neque, eget tristique mauris suscipit aliquet. Pellentesque et massa nunc.</p>
                }

            </div>

            <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

            <h6 className="mb-6 font-bold text-sm text-red-900">Dashboard - Reporting</h6>

            {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                <Link
                    to={{ pathname: "/reporting/vs-report/report-index" }}
                    className="button-red mr-2 mb-2 py-3 px-5"
                >
                    Add Report
                </Link>
            }

            <Link to={{
                pathname: showArchivedReports ? url : (url + "/archived"),
                state: showArchivedReports ? "" : "archived"
            }}

                className="button-red mr-2 mb-2 py-3 px-5"
                onClick={() => { setShowArchivedReports(!showArchivedReports) }}
            >
                {showArchivedReports ? "Active Reports" : "Archived Reports"}
            </Link>

            <Link to="#" className="button-red mr-2 mb-2 py-3 px-5 modal-button" onClick={() => toggleModal()} >Filter Reports</Link>

            <Table columns={columns} data={reportList} />


            <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
                    
                    <Formik 
                        initialValues={{}} 
                        onSubmit={(values, {resetForm}) => {
                            resetForm({});
                            let filter = {
                                dh_id: values.dh_id !== "Select Duty Holder" ? values.dh_id : "",
                                ag_id: values.ag_id !== "Select Asset Group" ? values.ag_id : "",
                                installation_id: values.installation_id !== "Select Installation" ? values.installation_id : "",
                                barrier_id: values.bar_id !== "Select Barrier" ? values.bar_id : "",
                                sece_id: values.sece_id !== "Select SECE" ? values.sece_id : "",
                                va_id: values.va_id !== "Select Verification Activity" ? values.va_id : "",

                                report_added_id: values.author_id !== "Select Author" ? values.author_id : "",
                                report_added_date: values.report_date,
                            }
                            
                            reportSearch({ variables: { param: filter} });
                            
                            toggleModal();
                        }}
                    
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <form onSubmit={handleSubmit}>

                                <div className="mb-4 pb-2 border-b-2 border-gray-200">
                                    <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">Filter Reports</h2>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="dh_id" className="block mb-1 text-blue-900 font-semibold">Duty Holder</label>
                                    <select
                                        className="form-control block w-full"
                                        name="dh_id"
                                        id="dh_id"
                                        onChange={(event) => { handleDutyHolderChange(event); handleChange(event) }}
                                        onBlur={handleBlur}
                                        value={values.dh_id}
                                    >
                                        <option>Select Duty Holder</option>
                                        {dutyHolderList.map(dh =>
                                            <option key={dh._id} value={dh._id}>{dh.dh_name}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="ag_id" className="block mb-1 text-blue-900 font-semibold">Asset Group</label>
                                    <select
                                        className="form-control block w-full"
                                        name="ag_id"
                                        id="ag_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ag_id}
                                    >
                                        <option>Select Asset Group</option>
                                        {assetGroupList.map(ag =>
                                            <option key={ag._id} value={ag._id}>{ag.ag_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="installation_id" className="block mb-1 text-blue-900 font-semibold">Installation</label>
                                    <select
                                        className="form-control block w-full"
                                        name="installation_id"
                                        id="installation_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.installation_id}
                                    >
                                        <option>Select Installation</option>
                                        {installationList.map(inst =>
                                            <option key={inst._id} value={inst._id}>{inst.installation_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="bar_id" className="block mb-1 text-blue-900 font-semibold">Barrier</label>
                                    <select
                                        className="form-control block w-full"
                                        name="bar_id"
                                        id="bar_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.bar_id}
                                    >
                                        <option>Select Barrier</option>
                                        {barrierList.map(bar =>
                                            <option key={bar._id} value={bar._id}>{bar.bar_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="sece_id" className="block mb-1 text-blue-900 font-semibold">SECE*</label>
                                    <select
                                        className="form-control block w-full"
                                        name="sece_id"
                                        id="sece_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.sece_id}
                                    >
                                        <option>Select SECE</option>
                                        {seceList.map(sece =>
                                            <option key={sece._id} value={sece._id}>{sece.sece_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="ps_id" className="block mb-1 text-blue-900 font-semibold">Performance Standard</label>
                                    <select
                                        className="form-control block w-full"
                                        name="ps_id"
                                        id="ps_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ps_id}
                                    >
                                        <option>Select Performance Standard</option>
                                        {psList.map(ps =>
                                            <option key={ps._id} value={ps._id}>{ps.ps_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="va_id" className="block mb-1 text-blue-900 font-semibold">Verification Activity</label>
                                    <select
                                        className="form-control block w-full"
                                        name="va_id"
                                        id="va_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.va_id}
                                    >
                                        <option>Select Verification Activity</option>
                                        {vaList.map(va =>
                                            <option key={va._id} value={va._id}>{va.va_short_desc}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="ra_id" className="block mb-1 text-blue-900 font-semibold">Reference Activity</label>
                                    <select
                                        className="form-control block w-full"
                                        name="ra_id"
                                        id="ra_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ra_id}
                                    >
                                        <option>Select Reference Activity</option>
                                        {raList.map(ra =>
                                            <option key={ra._id} value={ra._id}>{ra.ra_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="author_id" className="block mb-1 text-blue-900 font-semibold">Author</label>
                                    <select
                                        className="form-control block w-full"
                                        name="author_id"
                                        id="author_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.author_id}
                                    >
                                        <option>Select Author</option>
                                        {authorList.map(author =>
                                            <option key={author._id} value={author._id}>{author.user_fname+" "+author.user_lname}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="level" className="block mb-1 text-blue-900 font-semibold">Level</label>
                                    <select
                                        className="form-control block w-full"
                                        name="level"
                                        id="level"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.level}
                                    >
                                        <option>Select Level</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">A</option>
                                        <option value="5">B</option>
                                        <option value="6">C</option>
                                        <option value="7">High</option>
                                        <option value="8">Medium</option>
                                        <option value="9">Low</option>
                                    </select>
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="report_date" className="block mb-1 text-blue-900 font-semibold">Report Date</label>
                                    <DatePicker
                                        id="report_date"
                                        selected={values.report_date}
                                        dateFormat="d/MM/Y"
                                        name="report_date"
                                        onChange={date => setFieldValue('report_date', date)}
                                        onBlur={handleBlur}
                                        value={values.report_date}
                                        className="form-control block w-full"
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>
                                <button type="submit" className="mt-3 mb-4 button-red capitalize">Apply Filter</button>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

function formatReportsData(reportList, showArchivedReports, userId, state, userTypeAndRoleObject) {
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    let newReportList = [];
    if (reportList && reportList.length && userId !== "") {
        let tempReportList = reportList.filter(rpt => showArchivedReports ? rpt.report_archived === true : rpt.report_archived !== true);
        tempReportList.forEach((report, index) => {

            let dutyHolderName = report.dh_id ? report.dh_id.dh_name : "Not Available";
            let installTitle = report.installation_id ? report.installation_id.installation_title : "Not Available";

            const { user_fname: authFirstName= "", user_lname: authLastName = "" } = report.report_added_id ? report.report_added_id : {};
            
            let reportDateString = "N/A";

            if (report && report.report_added_date && report.report_added_date !== "") {
                let reportDate = new Date(report.report_added_date);
                reportDateString = reportDate.toLocaleDateString('en-GB', options);
            }
            
            
            let newReport = {
                id: report._id,
                dh_name: dutyHolderName,
                installation_title: installTitle,
                report_ref: formatRef(index),
                report_description: 'N/A',
                report_date: reportDateString,
                report_auth: (authFirstName + " " + authLastName),
                user_id: userId,
                state: state,
                is_archived: report.report_archived,
                archived_id: report.report_archived_id,
                archived_date: report.report_archived_date,
                userTypeAndRoleObject: userTypeAndRoleObject
            };
            newReportList.push(newReport);
        })
    };

    return newReportList;
}

function formatRef(index) {
    let verRef = "";

    let refNumFormat = "0000000"
    let num = index + 1;
    let refNum = refNumFormat.concat(num.toString());
    let formatedRefNum = refNum.substring(refNum.length - 7);
  
    verRef = `Report ${formatedRefNum}`;
  
    return verRef;
  }


export default Reporting;