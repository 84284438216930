import React, { createContext, useState } from 'react';

const DashboardContext = createContext();

function DashboardContextProvider({ children }) {
    const [activityStatus, setActivityStatus] = useState("");
    const [activitySearchParam, setActivitySearchParam] = useState({});
    const [findingSearchParam, setFindingSearchParam] = useState({});
    const [actionSearchParam, setActionSearchParam] = useState({});

    const updateActivityStatus = (status) => {
        if (status) {
            setActivityStatus(status);
        }
    }

    const updateActivitySearchParam = (Param) => {
        if (Param) {
            setActivitySearchParam(Param);
        }
    }
    const updateFindingSearchParam = (Param) => {
        if (Param) {
            setFindingSearchParam(Param);
        }
    }
    const updateActionSearchParam = (Param) => {
        if (Param) {
            setActionSearchParam(Param);
        }
    }

    const clearContext = () => {
        setActivityStatus("");
        setActivitySearchParam({});
        setFindingSearchParam({});
        setActionSearchParam({});
    }

    return (
        <DashboardContext.Provider value={{
            activityStatus: activityStatus,
            activitySearchParam: activitySearchParam,
            findingSearchParam: findingSearchParam,
            actionSearchParam: actionSearchParam,
            updateActivityStatus: updateActivityStatus,
            updateActivitySearchParam: updateActivitySearchParam,
            updateFindingSearchParam: updateFindingSearchParam,
            updateActionSearchParam: updateActionSearchParam,
            clearContext: clearContext
        }}>
            {children}
        </DashboardContext.Provider>
    );
}

export { DashboardContext, DashboardContextProvider };