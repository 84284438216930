import React, { createContext, useState } from 'react';

const VsReportContext = createContext();

function VsReportContextProvider({ children }) {
    const [formInitParam, setFormInitParam] = useState({});

    const updateFormInitParam = (Param) => {
        if (Param) {
            setFormInitParam(Param);
        }
    }

    const clearContext = () => {
        setFormInitParam({});
    }

    return (
        <VsReportContext.Provider value={{
            formInitParam: formInitParam,
            updateFormInitParam: updateFormInitParam,
            clearContext: clearContext
        }}>
            {children}
        </VsReportContext.Provider>
    );
}

export { VsReportContext, VsReportContextProvider };