/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useState, useEffect} from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Table from '../global/Table';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";

const SYSTEM_VARIABLE_TYPE_QUERY = gql`
query SystemVariableType($sysVarTypeId: ID!){
    systemVariableType(_id: $sysVarTypeId){
      _id
      sysvartype_title
      sysvartype_section
    }
}`;

const SYSTEM_VARIABLES_QUERY = gql`
query SystemVariablesBySysVarTypeId($isArchived:Boolean!, $sysVarTypeId: String!){
  systemVariablesBySysVarTypeId(isArchived:$isArchived, sysVarTypeId: $sysVarTypeId){
      _id
      sysvar_title
      sysvar_notes
      sysvar_archived
    }
}`;

const ARCHIVE_SYSTEM_VARIABLE = gql`
mutation ArchiveSystemVariable($sysVarId:String!) {
    archiveSystemVariable(_id: $sysVarId) {
         _id
    }
}`;

const UN_ARCHIVE_SYSTEM_VARIABLE = gql`
mutation UnArchiveSystemVariable($sysVarId:String!) {
  unArchiveSystemVariable(_id: $sysVarId) {
         _id
    }
}`;

function SystemVariables() {
  const [showHelp, setShowHelp] = useState(false);
  const [sysVarType, setSysVarType] = useState({});
  const [sysVars, setSysVars] = useState([]);
  const [showArchivedValues, setShowArchivedValues] = useState(false);

  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const {sysVarTypeId = ""} = useParams();

  const [systemVariableType, {data:sysVarTypeData}] = useLazyQuery(SYSTEM_VARIABLE_TYPE_QUERY);
  const [systemVariablesBySysVarTypeId, {data:sysVarsData}] = useLazyQuery(SYSTEM_VARIABLES_QUERY);
  const [archiveSystemVariable, {data:archiveSystemVariableData}] = useMutation(ARCHIVE_SYSTEM_VARIABLE);
  const [unArchiveSystemVariable, {data:unArchiveSystemVariableData}] = useMutation(UN_ARCHIVE_SYSTEM_VARIABLE);

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
  }, [userTypeAndRoleObject]);


  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);

       systemVariableType({variables: {sysVarTypeId}, errorPolicy: 'all'});
       systemVariablesBySysVarTypeId({variables: {isArchived:showArchivedValues, sysVarTypeId:sysVarTypeId}, errorPolicy: 'all'});
    }
  }, [authContext]);

  useEffect(() => {
    if (sysVarTypeId !== "") {
      systemVariableType({variables: {sysVarTypeId}, errorPolicy: 'all'});
      systemVariablesBySysVarTypeId({variables: {isArchived:showArchivedValues, sysVarTypeId:sysVarTypeId}, errorPolicy: 'all'});
    }
  }, [showArchivedValues]);

  useEffect(() => {
    if (sysVarTypeData && sysVarTypeData.systemVariableType) {
      setSysVarType(sysVarTypeData.systemVariableType)
    }
  }, [sysVarTypeData]);

  useEffect(() => {
    if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeId) {
      let tempSysVarsList = formatSysVarsData(sysVarsData.systemVariablesBySysVarTypeId, userTypeAndRoleObject);
      setSysVars(tempSysVarsList);
    }
  }, [sysVarsData]);

  useEffect(() => {
    if (archiveSystemVariableData) {
      systemVariablesBySysVarTypeId({variables: {isArchived:showArchivedValues, sysVarTypeId:sysVarTypeId}, errorPolicy: 'all'});
    }
  }, [archiveSystemVariableData]);

  useEffect(() => {
    if (unArchiveSystemVariableData) {
      systemVariablesBySysVarTypeId({variables: {isArchived:showArchivedValues, sysVarTypeId:sysVarTypeId}, errorPolicy: 'all'});
    }
  }, [unArchiveSystemVariableData]);

  const archiveManager = (sysVarId, isArchived) => {
    if (isArchived) {
      unArchiveSystemVariable({variables: {sysVarId}});
    }else{
      archiveSystemVariable({variables: {sysVarId}});
    } 
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'sysvar_title', 
      },
      {
        Header: 'Notes',
        accessor: 'sysvar_notes',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({value:{_id:sysVarId, sysvar_archived:isArchived, userTypeAndRoleObject}}) => (
          <div className="text-right">     

            {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" && userTypeAndRoleObject.userRole !=="Verifier" &&
            <Link
              to="#"
              className="button-red mb-1 mr-1 text-xs"
              onClick={() => {archiveManager(sysVarId, isArchived)}}
            >
              {isArchived? 'Unarchive':'Archive'}
            </Link>
            }

          </div>
        )
      },
      ],
    []
  )
  
  return(
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-6 border-b-2 border-gray-200">

      <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase" style= {{height:'1.5em'}}>{sysVarType.sysvartype_title}
        {sysVarType.sysvartype_title&&<FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)}/>}
      </h1>

        {showHelp &&
        <div>
            {sysVarType.sysvartype_title === "Schedule Type" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Nature / Activity Type" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Frequency" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active verification activity {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived verification activity {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Competency Level" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title}s.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title}s.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Criticality / Finding Level" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active SECE and Performance Standard {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived SECE and Performance Standard {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Verification Phase" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Conclusion Statements" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Verification Periodicity" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active verification schedule periodicity variables.</p>
              : <p className="mt-3">A table of archived verification schedule periodicity variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Current Status" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title} - [Findings / Activities / Schedule] variables.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title} - [Findings / Activities / Schedule] variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Background Activity Answers" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Background Activity Questions" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Verification Activity Frequency" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Notice Type" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Notice Periodicity" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Finding Status" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Activity Status" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
            {sysVarType.sysvartype_title === "Schedule Status" &&
            <div>
              {showArchivedValues === false ?
                <p className="mt-3">A table of active {sysVarType.sysvartype_title} variables.</p>
              : <p className="mt-3">A table of archived {sysVarType.sysvartype_title} variables.</p> }
                <p>Click 'Add Value' to create a new variable and 'Archive' to archive an existing variable.</p>
            </div>
            }
        </div>
        }
        
      </div>

      <Link to="/system-settings" className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>

      {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" && userTypeAndRoleObject.userRole !=="Verifier" &&
        <Link 
          to={{
            pathname: `/system-settings/variables/${sysVarTypeId}/add`,
            state: { formMode:'add'}
          }}      
          className="button-red mr-2 mb-2 py-3 px-5"
        >
          Add Value
        </Link>
      }

      <Link to="#" 
            className="button-red mr-2 mb-2 py-3 px-5"
            onClick={()=>{setShowArchivedValues(!showArchivedValues)}}
            >{showArchivedValues?"Show Active ":"Show Archived"}</Link>

      <Table columns={columns} data={sysVars} />

    </div>
  )

}

function formatSysVarsData(sysVars, userTypeAndRoleObject) {
  let newSysVarsList = [];

  if (sysVars && sysVars.length) {
    let tempSysVarsList = sysVars;
    tempSysVarsList.forEach(sysVars => {
      let newSysVars = { ...sysVars, userTypeAndRoleObject: userTypeAndRoleObject };
      newSysVarsList.push(newSysVars);
    })
  };

  return newSysVarsList;
}

export default SystemVariables;