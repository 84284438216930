/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { sortBy } from 'lodash';
import {gql, useLazyQuery} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {AuthContext} from "../../context/authContext";
import { NavContext } from "../../context/navContext";
import PageSpinner from '../global/pageSpinner';
import {createUserTypeAndRoleObject} from "../../utils/index";

const BOWTIE_DASHBOARD_SEARCH_QUERY = gql` 
query BowtieDashboardSearch($param: BowtieDashbardSearchInput!){ 
    bowtieDashboardSearch(param: $param){ 
        _id
        dh_id
        installation_id
        major_accident_hazard_bowtie_dashboard{
            mah_id
            mah_client_ref
            mah_title
            mah_subtitle_1
            mah_subtitle_2
            mah_bowtie_status{
                color
            } 
        }
        bowtie_dashboard_type
        created_date   
  } 
}`;

function BowtieDashAlt(props) {
    const [showHelp, setShowHelp] = useState(false);
    const [mahData, setMahData] = useState([]);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
    const [shouldSpin, setShouldSpin] = useState(false);

    const [drDnDutyholder, setDrDnDutyholder] = useState({});
    const [drDnInstallation, setDrDnInstallation] = useState({});
    const [drDnSece, setDrDnSece] = useState({});

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);

    const [bowtieDashboardSearch, { loading: bowtieDashLoading, error: bowtieDashError, data: bowtieDashData }] = useLazyQuery(BOWTIE_DASHBOARD_SEARCH_QUERY);

    useEffect(() => {
        const { dutyHolder, installation, sece } = navContext;
        setDrDnDutyholder(dutyHolder);
        setDrDnInstallation(installation);
        setDrDnSece(sece);
    }, [navContext]);  

    useEffect(() => {
        if (authContext && authContext.user) {
          let result = createUserTypeAndRoleObject(authContext);
          setUserTypeAndRoleObject(result);
        }
      }, [authContext]);


    useEffect(() => {
        if (drDnInstallation.id && drDnInstallation.id !== "") {
            bowtieDashboardSearch({ variables: { param: { installation_id: drDnInstallation.id } }, errorPolicy: 'all' });
            setShouldSpin(true);
        } else {
            setMahData([]);
        }
    }, [drDnInstallation]);

    useEffect(() => {
        if (bowtieDashData && bowtieDashData.bowtieDashboardSearch) {
            const bowDashList = bowtieDashData.bowtieDashboardSearch;
            const { major_accident_hazard_bowtie_dashboard: tempMahList = [] } = bowDashList[0] ? bowDashList[0] : [];
            const orderedMahList = sortBy([...tempMahList], (mah) => { return mah.mah_client_ref });
            setMahData(orderedMahList);
            setShouldSpin(false);
        }
    }, [bowtieDashData]);

    if (shouldSpin || bowtieDashLoading) {
        return (
            <PageSpinner displayText = {"generating dashboard"} /> 
        )
    }

    if (bowtieDashError) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    //TITLE / INFO & MAIN BUTTON SECTION
    return (
        <div className="w-full px-8 pb-8">
            <div className="pb-6 border-b-2 border-gray-200">
                <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Bowtie Dashboard <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>
                {showHelp &&
                    <p className="mt-3">Bowties relating to each Major Accident Hazard corresponding to the selected Duty Holder and Installation provide a visual representation of the current risk level, highlighting SECE vulnerabilities in relation to the prevention of major accident hazards.</p>
                }
            </div>

            {
            /*<h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>
            {(drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900">Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
            {(drDnSece.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"> Installation: {drDnInstallation.name} - SECE :  {drDnSece.name}</h6>}*/
            }

            <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>
            {(userTypeAndRoleObject &&
              userTypeAndRoleObject.userType === "ABS" && 
              drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Bowtie - Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
            {(userTypeAndRoleObject &&
              userTypeAndRoleObject.userType === "Duty Holder" && 
              drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Bowtie - Installation: {drDnInstallation.name}</h6>}
            {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Bowtie - Duty Holder / Installation : All</h6>}

            <div className="mt-3">
                <p>Key: </p>
                <p><FontAwesomeIcon icon={['fas', 'square-full']} className="text-green-900" /> Low</p>
                <p><FontAwesomeIcon icon={['fas', 'square-full']} className="text-yellow-900" /> Medium</p>
                <p><FontAwesomeIcon icon={['fas', 'square-full']} className="text-red-900" /> High</p>
                <p><FontAwesomeIcon icon={['fas', 'square-full']} className="text-black" /> Critical</p>
            </div>

            <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {
                    mahData.length ? mahData.map((mah, index) =>
                        <div key={index}>
                            <div className="h-48 w-48 mx-auto p-4 text-white text-center flex items-center justify-center rounded-full border border-gray-800" 
                                 style={{ backgroundColor: `${mah.mah_bowtie_status ? mah.mah_bowtie_status.color : "black"}` }}>
                                <Link
                                    to={{
                                        pathname: `/bowtie/view/${mah.mah_id}`,
                                        state: { mahId: mah.mah_id }
                                    }}

                                    className="hover:underline">{mah.mah_title}</Link>
                            </div>
                            <p className="font-sans text-center pt-3">{mah.mah_client_ref && mah.mah_client_ref !== "" ? mah.mah_client_ref : "N/A"}</p>
                        </div>
                    ) : (drDnInstallation.id != null) ? 'No data currently available' : 'Please select an installation from the dropdown in the side menu'
                }
                {/*
                        <div>
                            <div className="bg-orange-900 h-48 w-48 mx-auto p-4 text-white text-center flex items-center justify-center rounded-full border border-gray-800">
                                <Link to={`/bowtie`} className="hover:underline">Hyrdocarbon Release at Seabed</Link>
                            </div>
                            <p className="font-sans text-center">Bowtie Ref. 02</p>
                        </div>

                        <div>
                            <div className="bg-red-900 h-48 w-48 mx-auto p-4 text-white text-center flex items-center justify-center rounded-full border border-gray-800">
                                <Link to={`/bowtie`} className="hover:underline">Explosive Mixture Gas Release</Link>
                            </div>
                            <p className="font-sans text-center">Bowtie Ref. 03</p>
                        </div>

                        <div>
                            <div className="bg-black h-48 w-48 mx-auto p-4 text-white text-center flex items-center justify-center rounded-full border border-gray-800">
                                <Link to={`/bowtie`} className="hover:underline">Blowout at Drill Floor</Link>
                            </div>
                            <p className="font-sans text-center">Bowtie Ref. 04</p>
                        </div>

                        <div>
                            <div className="bg-red-900 h-48 w-48 mx-auto p-4 text-white text-center flex items-center justify-center rounded-full border border-gray-800">
                                <Link to={`/bowtie`} className="hover:underline">Test Bowtie Circle Goes Here</Link>
                            </div>
                            <p className="font-sans text-center">Bowtie Ref. 05</p>
                        </div>

                        <div>
                            <div className="bg-black h-48 w-48 mx-auto p-4 text-white text-center flex items-center justify-center rounded-full border border-gray-800">
                                <Link to={`/bowtie`} className="hover:underline">Another Test Bowtie Circle Goes Here</Link>
                            </div>
                            <p className="font-sans text-center">Bowtie Ref. 06</p>
                        </div>

                        <div>
                            <div className="bg-green-900 h-48 w-48 mx-auto p-4 text-white text-center flex items-center justify-center rounded-full border border-gray-800">
                                <Link to={`/bowtie`} className="hover:underline">Test Bowtie Circle Goes Here</Link>
                            </div>
                            <p className="font-sans text-center">Bowtie Ref. 07</p>
                        </div>

                        <div>
                            <div className="bg-orange-900 h-48 w-48 mx-auto p-4 text-white text-center flex items-center justify-center rounded-full border border-gray-800">
                                <Link to={`/bowtie`} className="hover:underline">Another Test Bowtie Circle Goes Here</Link>
                            </div>
                            <p className="font-sans text-center">Bowtie Ref. 08</p>
                        </div>
                */}
            </div>
        </div>
    )
    //TITLE / INFO & MAIN BUTTON SECTION - END
}

export default BowtieDashAlt;