import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class InfoModal extends Component {

    state = {
        on: false
    }

    toggle = () => {
        this.setState({
            on: !this.state.on
        })
    }

    render(){
        return(
        <div className="inline">
            {this.props.theme === "dark" &&
                <FontAwesomeIcon icon={['fas', 'info-circle']} className="text-blue-900 cursor-pointer" onClick={this.toggle} />
            }

            {this.props.theme === "light" &&
                <FontAwesomeIcon icon={['fas', 'info-circle']} className="text-red-900 cursor-pointer" onClick={this.toggle} />
            }

            {this.state.on &&
            <div className="modal fixed w-full h-full top-0 left-0 flex justify-center z-30">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={this.toggle}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{maxHeight: 'calc(100% - 3.5rem)'}}>
                    {this.props.children}
                </div>
            </div>
            }

        </div>
        )
    }

}

export default InfoModal;