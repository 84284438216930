import React from 'react';

const RouteNotFound = (props) => {

  return (
    <div className="w-full px-8 pb-8 wide-table">
      <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">404: Error Page not found </h1>
    </div>
  )
}

export default RouteNotFound;