/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, {useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from "../global/pageSpinner";

const VA_BY_ID_QUERY = gql`
query VerificationActivity($vaId: ID!){
    verificationActivity(_id: $vaId){
        _id
        dh_id{
            _id
            dh_name
        }
        sece_id{
            _id        
        } 
        ps_id{
            _id
        }
    }
}`;

const SECE_BY_ID_QUERY = gql`
query Sece($seceId: ID!){
    sece(_id: $seceId){
        _id
        dh_id{
            _id
        }
        ag_id{
            _id
            ag_title
        }
        bar_id{
            _id
            bar_title
        }
        installation_id{
            _id
            installation_title
        }
    }
}`;

function ViewLinkedActivity(props) {
    const [vaObject, setVaObject] = useState(null);
    const [genericError, setGenericError] = useState(false);
    const [shouldSpin] = useState(true);
    const { location: { state } = {} } = props ? props : {};
    const { vaId = ""} = useParams();

    const history = useHistory();

    const [verificationActivity, {error:vaError, data: verActData}] = useLazyQuery(VA_BY_ID_QUERY);
    const [sece, {error:seceError, data: seceData}] = useLazyQuery(SECE_BY_ID_QUERY);

    useEffect(() => {
        let toView = localStorage.getItem('toView');
        if (toView && toView === "No") {
            history.push("/reporting/verify", state);
            localStorage.removeItem('toView');
        }
        if (vaId !== "" && !toView) {
            localStorage.setItem('toView', "Yes");
            verificationActivity({ variables: { vaId: vaId }, errorPolicy: 'all' })
        }
    }, []);

    useEffect(() => {
        if (verActData && verActData.verificationActivity) {
           let va = verActData.verificationActivity;
           let seceId = va.sece_id._id
            setVaObject(verActData.verificationActivity);
            sece({ variables: {seceId: seceId}, errorPolicy: 'all' })
        }
    }, [verActData]);

    useEffect(() => {
        if (seceData && seceData.sece) {
          let seceObj = seceData.sece;
          let dhId = vaObject.dh_id._id;
          let agId = seceObj.ag_id._id;
          let installId = seceObj.installation_id._id;
          let barId = seceObj.bar_id._id;
          let seceId = seceObj._id;
          let psId = vaObject.ps_id._id;
          let vaId = vaObject._id;
          if (dhId && agId && installId && barId && seceId && psId && vaId) {
             history.push(`/dutyholder/${dhId}/asset-group/${agId}/installation/${installId}/barrier/${barId}/sece/${seceId}/performance-standard/${psId}/verification-activity/view/${vaId}`, {fromReport : true});
          } else {
            setGenericError(true);
          }   
        }
    }, [seceData]);

    if (shouldSpin) {
        return (
          <PageSpinner/>
        )
    }
    
    if (vaError||seceError || genericError) {
        return (
          <span>There was a problem navigating to the Activity View Page</span>
        )
    }

    return (
        <div>
            This is the temporary page for navigating to Activity List
        </div>
    );
}

export default ViewLinkedActivity;