import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import 'rc-dropdown/assets/index.css';

class TableDropDown extends Component {
  
  render() {

    const menu = (
      <Menu className="table-dd-menu">
        {this.props.items.map((item, index) => {
          return this.props.id !== undefined ? (
            <MenuItem key={item.id ? item.id : index}>
              <Link
                to={{
                  pathname: item.link,
                }}
                className="flex font-sans text-blue-900 text-left text-xs hover:opacity-50"
              >{item.name}</Link>
            </MenuItem>
          ) : (
            <MenuItem key={item.id ? item.id : index}>
              <Link
                to={{
                  pathname: `${item.link}`,
                }}
                className="flex font-sans text-blue-900 text-left text-xs hover:opacity-50"
              >{item.name}</Link>
            </MenuItem>
          )
        }
        )}
      </Menu>
    );
    
    return (
      <div className="relative inline-block">
        <Dropdown
          trigger={['click']}
          overlay={menu}
          animation="slide-up"
        >
        <button 
          className="button-red text-xs" 
        >
          {this.props.title} <FontAwesomeIcon icon={['fas', 'ellipsis-v']} className="text-white" />
        </button>
        </Dropdown>

      </div>
    );
    
  }
};

export default TableDropDown;