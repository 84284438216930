/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Table from '../global/Table';
import TableDropDown from '../global/TableDropDown2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import { NOTIFICATION_STATUS_UNAPPROVED, NOTIFICATION_STATUS_APPROVED, NOTIFICATION_STATUS_REJECT, NOTIFICATION_STATUS_REVISION } from '../../constants/';
import { AuthContext } from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import { capitaliseFirstCase, createUserTypeAndRoleObject } from "../../utils/index";
import Comment from '../global/Comment';
import { sysObjects } from "../../constants/sysObjectList";

const dropdownListItems = ({ dutyHolderId, dh_status }) => ([
  dh_status !== NOTIFICATION_STATUS_REJECT &&
  {
    id: 1,
    name: 'Users',
    link: `dutyHolder/${dutyHolderId}/users`
  },
  {
    id: 2,
    name: 'Locations',
    link: `/dutyholder/${dutyHolderId}/locations`
  },
  dh_status !== NOTIFICATION_STATUS_REJECT &&
  {
    id: 3,
    name: 'Asset Groups',
    link: `/dutyholder/${dutyHolderId}/asset-groups`
  },
])

const ARCHIVE_DUTY_HOLDER = gql`
    mutation ArchiveDutyHolder($dutyHolderId: String!, $userId: String!) {
        archiveDutyHolder(_id: $dutyHolderId, userId: $userId) {
            _id
            dh_code
            dh_name
        }
    }`;

const UN_ARCHIVE_DUTY_HOLDER = gql`
    mutation UnArchiveDutyHolder($dutyHolderId: String!, $userId: String!) {
        unArchiveDutyHolder(_id: $dutyHolderId, userId: $userId) {
            _id
            dh_code
            dh_name
        }
    }`;

const DUTY_HOLDER_SEARCH_QUERY = gql`
    query DutyHolderSearch($searchParam: DutyHolderSearchInput!) {
      dutyHolderSearch(searchParam: $searchParam){
            _id
            dh_code
            dh_name
            dh_location
            dh_head_office {
                location_name
            }
            dh_status
            dh_added_date
            dh_archived
        }
    }`;

function DutyHolders(props) {
  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [showHelp, setShowHelp] = useState(false);
  const [dutyHolderList, setDutyHolderList] = useState([]);
  const [showArchivedDutyHolders, setShowArchivedDutyHolders] = useState(false);

  const [drDnDutyholder, setDrDnDutyholder] = useState({});

  const { location: { state = "" }, match: { url = "" } } = props ? props : {};

  const [dutyHolderSearch, { loading: dHLoading, error: dHError, data: dutyHoldersData, refetch }] = useLazyQuery(DUTY_HOLDER_SEARCH_QUERY);
  const [archiveDutyHolder, { data: archiveDutyHolderData }] = useMutation(ARCHIVE_DUTY_HOLDER);
  const [unArchiveDutyHolder, { data: unArchDutyHolderData }] = useMutation(UN_ARCHIVE_DUTY_HOLDER);

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    const { dutyHolder} = navContext;
    setDrDnDutyholder(dutyHolder);
  }, [navContext]);

  useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
    }
  }, [userTypeAndRoleObject]);

  useEffect(() => {
    if (state === "archived") {
      setShowArchivedDutyHolders(true);
    }
  }, []);

  useEffect(() => {
    setDutyHolderList([]);
    const payload = {
      dh_id: drDnDutyholder.id ? drDnDutyholder.id : "",
      archived: showArchivedDutyHolders,
    };
    dutyHolderSearch({ variables: { searchParam: payload }, errorPolicy: 'all' });
  }, [drDnDutyholder, showArchivedDutyHolders]);

  useEffect(() => {
    if (dutyHoldersData && dutyHoldersData.dutyHolderSearch) {
      let formatedDutyHolders = formatDutyHoldersData(dutyHoldersData.dutyHolderSearch, state, null);
      setDutyHolderList(formatedDutyHolders);
    }
  }, [dutyHoldersData]);

  useEffect(() => {
    if (archiveDutyHolderData) {
      refetch();
    }
  }, [archiveDutyHolderData]);

  useEffect(() => {
    if (unArchDutyHolderData) {
      refetch();
    }
  }, [unArchDutyHolderData]);

  const archiveManager = (dutyHolderId, isArchived) => {
    if (isArchived) {
      unArchiveDutyHolder({ variables: { dutyHolderId, userId: authContext.user._id } });
    } else {
      archiveDutyHolder({ variables: { dutyHolderId, userId: authContext.user._id } });
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Duty Holder Code',
        accessor: 'dh_code',
      },
      {
        Header: 'Duty Holder Name',
        accessor: 'dh_name',
      },
      {
        Header: 'Head Office',
        accessor: 'dh_head_office.location_name',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: dutyHolder => {

          if (dutyHolder.value.dh_status !== NOTIFICATION_STATUS_UNAPPROVED) {
            return (
              <div className="text-right">

                <Comment sysObjName={sysObjects.dutyHolder}
                  sysObjRecordId={dutyHolder.value._id}
                  buttonName={"Comments"}
                  buttonClassName={"button-red mb-1 mr-1 text-xs"} />

                <Link
                  to={{ pathname: `/dutyholder/${dutyHolder.value._id}/histories` }}
                  className="button-red mb-1 mr-1 text-xs"
                >
                  History
                </Link>
                <Link
                  to={{
                    pathname: `/dutyholders/view/${dutyHolder.value._id}`,
                    state: { archivedStatus: dutyHolder.value.state }
                  }}
                  className="button-red mb-1 mr-1 text-xs"
                >
                  View
                </Link>

                {(dutyHolder.value.dh_status === NOTIFICATION_STATUS_APPROVED ||
                  dutyHolder.value.dh_status === NOTIFICATION_STATUS_REJECT ||
                  dutyHolder.value.dh_status === NOTIFICATION_STATUS_REVISION) &&
                  <>
                    {dutyHolder.value.showArchived ? "" : <Link
                      to={{
                        pathname: `/dutyholder/edit/${dutyHolder.value._id}`,
                        state: { formMode: 'edit', archivedStatus: dutyHolder.value.state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                    <button
                      className="button-red mb-1 mr-1 text-xs"
                      type="button"
                      onClick={() => { archiveManager(dutyHolder.value._id, dutyHolder.value.dh_archived) }}
                    >
                      {dutyHolder.value.dh_archived ? 'Unarchive' : 'Archive'}
                    </button>
                  </>
                }

                <TableDropDown title="More" items={dropdownListItems({ dutyHolderId: dutyHolder.value._id, dh_status: dutyHolder.value.dh_status })} />

              </div>
            )
          }

          if (dutyHolder.value.dh_status === NOTIFICATION_STATUS_UNAPPROVED) {
            return (
              <div className="text-right">
                <Link
                  to={{
                    pathname: `/dutyholder/${dutyHolder.value._id}/locations`,
                  }}
                  className="button-red mb-1 mr-1 text-xs"
                >
                  Locations
                </Link>
              </div>
            )
          }

          return null;
        }
      },
    ],
    []
  )

  if (dHLoading) {
    return (
      <PageSpinner />
    )
  }

  if (dHError) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Duty Holder Management <FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <div>
            {showArchivedDutyHolders === false ?
              <div>
                <p className="mt-3">A table of active Duty Holders, each entry can be viewed, edited and archived.  Click 'More' for additional options.</p>
                <p>Add a new Duty Holder by clicking 'Add Duty Holder'.</p>
              </div>
              :
              <div>
                <p className="mt-3">A table of archived Duty Holders, each entry can be viewed and unarchived.</p>
                <p>Click 'More' for additional options.</p>
              </div>
            }
          </div>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <h6 className="mb-6 font-bold text-sm text-red-900">Dashboard - Duty Holder Management</h6>

      {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" && userTypeAndRoleObject.userRole !== "Verifier" &&
        <Link
          to={{
            pathname: '/dutyholder/add',
            state: { formMode: 'add' }
          }}
          className="button-red mr-2 mb-2 py-3 px-5"
        >
          Add Duty Holder
        </Link>
      }

      <Link to={{
        pathname: showArchivedDutyHolders ? url : (url + "/archived"),
        state: showArchivedDutyHolders ? "" : "archived"
      }}
        className="button-red mr-2 mb-2 py-3 px-5"
        onClick={() => { setShowArchivedDutyHolders(!showArchivedDutyHolders) }}
        style={{ backgroundColor: showArchivedDutyHolders ? '#a70000' : '' }}
      >
        {showArchivedDutyHolders ? "Active Duty Holders" : "Archived Duty Holders"}
      </Link>

      {dutyHolderList.length > 0 &&
        <Link to="/dutyholders/histories" className="button-red mb-2 mr-2 py-3 px-5">History</Link>
      }

      <Table columns={columns} data={dutyHolderList} />
    </div>
  )

}

function formatDutyHoldersData(dutyHolderList, state, showArchived) {
  let newDutyHolderList = [];
  if (dutyHolderList && dutyHolderList.length) {
    dutyHolderList.forEach(dutyHolder => {
      let formatedStatus = capitaliseFirstCase(dutyHolder.dh_status);
      let newDutyHolder = {
        ...dutyHolder,
        status: formatedStatus,
        state: state,
        showArchived: showArchived
      };
      newDutyHolderList.push(newDutyHolder);
    })
  };

  return newDutyHolderList;
}

export default DutyHolders;