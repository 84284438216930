/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useState, useContext} from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {lowerCase} from "lodash";
import FormError from "../global/FormError";
import { gql, useLazyQuery} from '@apollo/client';
import {useHistory, useParams} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from "../../context/authContext";
import {NavContext} from '../../context/navContext';
import {VsReportContext} from "../../context/vsReportContext";
import {createUserTypeAndRoleObject, parseJSONSafely} from "../../utils/index";
import {NOTIFICATION_STATUS_APPROVED } from '../../constants/';

import makeAnimated from 'react-select/animated';

const Validation = Yup.object().shape({
    vs_rpt_type: Yup.string().label("vs_rpt_type").required("Report Type is required"),
    //vs_statuses: Yup.string().label("vs_statuses").required("Schedule Status is required"),
    dh_id: Yup.string().label("dh_id").required("Duty Holder is required"),
    inst_ids: Yup.string().label("inst_ids").required("Installation is required"),
    vs_ids: Yup.string().label("vs_ids").required("Verification Schedule is required"),
})

const VS_REPORT_BY_ID_QUERY = gql`
query verificationScheduleReport($vsRptId: ID!){
    verificationScheduleReport(_id: $vsRptId) {
        _id
        dh_id{
            _id
            dh_name
        }
        installation_ids{
            _id
            installation_title
        }
        vs_rpt_ref
        vs_rpt_is_periodic
        vs_rpt_title
        vs_rpt_description
        vs_rpt_summary
        vs_rpt_scope
        vs_rpt_statement
        vs_rpt_improvements
        vs_rpt_concerns
        vs_rpt_positives
        vs_rpt_payment_details
        vs_ids{
           _id 
           vs_title
           vs_periodicity {
             _id
           }
           vs_status
        }
        vs_status_list
        vs_rpt_start_date
        vs_rpt_end_date
        vs_rpt_added_date
        vs_rpt_added_id{
            _id
        }
        vs_rpt_archived
        vs_rpt_status
  }
}`;

const reportTypes = [
    { id: 1, name: "Periodic" },
    { id: 2, name: "Asset Verification" }
]

const vsStatus = [
    { value: 1, label: "Ongoing" },
    { value: 2, label: "Pending" },
    { value: 3, label: "Completed" }
]

const VERIFICATION_SCHEDULES_SEARCH = gql`
query VerificationScheduleSearch($param: VerificationScheduleSearch!){
  verificationScheduleSearch(param: $param){
        _id
        vs_title
        vs_periodicity{
          _id
        }
        schedule_type_id{
          _id
          sysvar_title
        }
        dh_id{
          _id
          dh_name
          dh_code
        }
        installation_ids{
          _id
          installation_code
          installation_title
        }
        sece_ids{
          _id
          sece_title
        }
        va_ids{
          _id
          va_short_desc
          va_type
        }
        ra_ids{
          _id
          ra_title
        }
        verifier_ids{
          _id
          user_fname
          user_lname
        }
        vs_start_date_time
        vs_end_date_time
        vs_added_date
        vs_added_id{_id}
        vs_archived
        vs_status
    }
}`;

const DUTY_HOLDERS_QUERY = gql`
    query {
        dutyHolders {
            _id
            dh_name
            dh_status
            dh_added_date
            dh_archived
        }
}`;

const INSTALLATIONS_BY_DH_ID_QUERY = gql`
query installationsByDutyHolderId($dhId: ID){
    installationsByDutyHolderId(dhId: $dhId) {
      _id
      installation_title
      installation_archived
      installation_status
  }
}`;

function VerificationScheduleReportIndex(props) {
    const [showHelp, setShowHelp] = useState(false);
    const [reportTypeList, setReportTypeList] = useState([]);
    const [vsList, setVsList] = useState([]);
    const [vsStatusList, setVsStatusList] = useState([]);
    const [selectedVsStatusList, setSelectedVsStatusList] = useState([]);

    const [drDnDutyholder, setDrDnDutyholder] = useState({});
    const [drDnInstallation, setDrDnInstallation] = useState({});

    const [dutyHolderList, setDutyHolderList] = useState([]);
    const [selectedDutyHolderId, setSelectedDutyHolderId] = useState("");

    const [installationList, setInstallationList] = useState([]);
    const [selectedInstIdList, setSelectedInstIdList] = useState([]);

    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);
    const vsReportContext = useContext(VsReportContext);

    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const history = useHistory();

    const { formMode = "", vsRptId = "", isFromStep2 = false} = useParams();

    const [formInitValues, setFormInitValues] = useState({
        vs_rpt_type: "", 
        dh_id: "",
        inst_ids: [],
        vr_start_date: null,
        vr_end_date: null,
        vs_statuses: [],
        vs_ids: []
    });

    const [verificationScheduleReport, {data: vsReportData}] = useLazyQuery(VS_REPORT_BY_ID_QUERY);
    const [verificationScheduleSearch, {data: vsData}] = useLazyQuery(VERIFICATION_SCHEDULES_SEARCH);
    const [dutyHolders, {data: dutyHoldersData}] = useLazyQuery(DUTY_HOLDERS_QUERY);
    const [installationsByDutyHolderId, {data: installsData}] = useLazyQuery(INSTALLATIONS_BY_DH_ID_QUERY);

    useEffect(() => {
        dutyHolders({ variables: {}, errorPolicy: 'all' });

        if (formMode === "edit" && vsRptId !== "" && !isFromStep2) {
            verificationScheduleReport({ variables: { vsRptId: vsRptId }, errorPolicy: 'all' });
        }

        setReportTypeList(reportTypes);
        setVsStatusList(vsStatus);
    }, []);

    useEffect(() => {
        if (authContext && authContext.user) {
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        const { dutyHolder, installation } = navContext;
        setDrDnDutyholder(dutyHolder);
        setDrDnInstallation(installation);
    }, [navContext]);

    useEffect(() => {
        if (vsReportData && vsReportData.verificationScheduleReport) {
            let vsReport = vsReportData.verificationScheduleReport;

            let periodType = vsReport.vs_rpt_is_periodic === true ? "1": "2";
            let vsStatus = vsReport.vs_status_list && vsReport.vs_status_list !== "" ? vsReport.vs_status_list : "[]";
            let vsStatusList = parseJSONSafely(vsStatus);
            let vsStatusIdList = vsStatusList && vsStatusList.length > 0 ? vsStatusList.map(id => lowerCase(id.label)) : [];

            let dhId = vsReport.dh_id ? vsReport.dh_id._id : "";
            let instIds = vsReport.installation_ids ? vsReport.installation_ids.map(inst => inst._id) : [];

            let instIdForDrpDwn = vsReport.installation_ids ? vsReport.installation_ids.map(inst => {return {value: inst._id, label: inst.installation_title}}) : [];
            let vsIdForDrpDwn = vsReport.vs_ids? vsReport.vs_ids.map(vs => { return {value: vs._id, label: vs.vs_title}}) : [];

            let vrStartDate = vsReport.vs_rpt_start_date? new Date(parseInt(vsReport.vs_rpt_start_date)): null;
            let vrEndDate = vsReport.vs_rpt_end_date? new Date(parseInt(vsReport.vs_rpt_end_date)) : null;

            let initIndexForm = {
                vs_rpt_type: periodType,
                dh_id: dhId,
                inst_ids: instIdForDrpDwn,
                vs_ids: vsIdForDrpDwn,
                vs_statuses: vsStatusList,
                vr_start_date: vrStartDate,
                vr_end_date: vrEndDate
            }

            setSelectedDutyHolderId(dhId);
            setSelectedInstIdList(instIds);
            setSelectedStartDate(vrStartDate);
            setSelectedEndDate(vrEndDate);
            setSelectedVsStatusList(vsStatusIdList);

            setFormInitValues({ ...formInitValues, ...initIndexForm })
        }
    }, [vsReportData]);

    useEffect(() => {
        if (drDnDutyholder && drDnDutyholder.id) {
            const {id: dhId, name: dhName} = drDnDutyholder;
            let drpDwnDhList = [{_id: dhId, dh_name: dhName}];

            setDutyHolderList(drpDwnDhList);
        }
        else
        {
            dutyHolders({ variables: {}, errorPolicy: 'all' });
        }
    }, [drDnDutyholder, drDnInstallation]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);

    useEffect(() => {
        let formInitParam = vsReportContext.formInitParam;
        if (formInitParam && formInitParam.hasOwnProperty('vs_rpt_type')) {    

            const {
                vs_rpt_type = "", 
                vs_statuses = [], 
                dh_id = "", 
                inst_ids = [], 
                vs_ids = [],
                vr_start_date = null,
                vr_end_date = null,
            } = formInitParam;

            let instIdList = inst_ids && inst_ids.length > 0 ? inst_ids.map(id => id.value) : [];
            let vsStatusIdList = vs_statuses && vs_statuses.length > 0 ? vs_statuses.map(id => lowerCase(id.label)) : [];

            setSelectedDutyHolderId(dh_id);
            setSelectedInstIdList(instIdList);
            setSelectedStartDate(vr_start_date);
            setSelectedEndDate(vr_end_date);
            setSelectedVsStatusList(vsStatusIdList);

            let tempInitValues = {
                vs_rpt_type: vs_rpt_type,
                vs_statuses: vs_statuses,
                dh_id: dh_id,
                inst_ids: inst_ids,
                vs_ids: vs_ids,
                vr_start_date: new Date(vr_start_date),
                vr_end_date: new Date(vr_end_date),
            };

            setFormInitValues({ ...formInitValues, ...tempInitValues })
        }
    }, [vsReportContext]);

    useEffect(() => {
        let payLoad = {
            ...(selectedStartDate && {vs_start_date_time: selectedStartDate}),
            ...(selectedEndDate && {vs_end_date_time: selectedEndDate}),
            ...(selectedVsStatusList.length > 0 &&  {vs_status_list: selectedVsStatusList}),
            ...(selectedDutyHolderId !== "" && { dutyholderIds: [selectedDutyHolderId] }),
            ...(selectedInstIdList.length > 0 && { installationIds: selectedInstIdList })
        }

        verificationScheduleSearch({ variables: { param: { ...payLoad } }, errorPolicy: 'all' });
    }, [selectedVsStatusList, selectedDutyHolderId, selectedInstIdList, selectedStartDate, selectedEndDate]);

    useEffect(() => {
        if (vsData && vsData.verificationScheduleSearch) {
            setVsList(vsData.verificationScheduleSearch);
        }
    }, [vsData]);
 
    useEffect(() => {
        if (selectedDutyHolderId !== '') {
            installationsByDutyHolderId({ variables: { dhId: selectedDutyHolderId }, errorPolicy: 'all' });
        }
    }, [selectedDutyHolderId]);

    useEffect(() => {
        if (dutyHoldersData && dutyHoldersData.dutyHolders) {
            let list = dutyHoldersData.dutyHolders;
            let newDutyHolderList = list.filter(dh => dh.dh_status === "approved" && dh.dh_archived !== true);
            setDutyHolderList(newDutyHolderList);
        }
    }, [dutyHoldersData]);

    useEffect(() => {
        if (installsData && installsData.installationsByDutyHolderId) {
            let tempList = formatInstallationData(installsData.installationsByDutyHolderId);
            setInstallationList(tempList);
        }
    }, [installsData]);

    const handleDutyHolderChange = (event) => {
        let value = event.target.value;
        setSelectedDutyHolderId(value);
    };

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setSelectedEndDate(date);
    };

    const navigateToReport = () => {
        if (formMode === "edit" && vsRptId !== "") {
            history.push(`/reporting/vs-report/edit/${vsRptId}`, {formMode: "edit"});
        } else {
            history.push('/reporting/vs-report/add');
        }
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-6 border-b-2 border-gray-200">
                <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Reporting Index <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>
                {showHelp && <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam condimentum a lectus non condimentum. Aliquam rhoncus, enim vitae condimentum pharetra, urna magna elementum sapien, non vehicula turpis tellus nec velit. Vivamus tristique venenatis neque, eget tristique mauris suscipit aliquet. Pellentesque et massa nunc.</p>
                }
            </div>

            <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

            <h6 className="mb-6 font-bold text-sm text-red-900">Dashboard - Reporting - Type</h6>

            <Formik
                enableReinitialize
                initialValues={formInitValues}
                validationSchema={Validation}
                onSubmit={(values, actions) => {
                    vsReportContext.updateFormInitParam({...values});
                    navigateToReport();
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}> 
                        {/*
                        <div className="my-4">
                            <Link to={{pathname: `/reporting/report-search`, state: {} }}
                                className="button-red mr-2"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back
                            x</Link>
                        </div> */}

                        <div className="mt-6">
                            <label htmlFor="vs_rpt_type" className="block mb-1 text-blue-900 font-semibold">Report Type*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="vs_rpt_type"
                                id="vs_rpt_type"
                                onChange={(event)=> {
                                    handleChange(event)
                                    setFieldValue('dh_id', "");
                                    setFieldValue('inst_ids', []);
                                    setFieldValue('vs_statuses', []);
                                    setFieldValue('vr_start_date', "");
                                    setFieldValue('vr_end_date', "");
                                    setFieldValue('vs_ids', []);
                                }}
                                onBlur={handleBlur}
                                value={values.vs_rpt_type}
                            >
                                <option value={""}>Select Report Type</option>
                                {reportTypeList.map((type, index) => (
                                    <option key={index} value={type.id}>{type.name}</option>
                                ))}
                            </select>
                            <FormError touched={touched.vs_rpt_type} message={errors.vs_rpt_type} />
                        </div>

                        <div>
                            <label htmlFor="dh_id" className="block mb-1 text-blue-900 font-semibold">Duty Holder*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="dh_id"
                                id="dh_id"
                                onChange={(event) => {
                                    handleChange(event);
                                    handleDutyHolderChange(event);
                                    setFieldValue('inst_ids', []);
                                    setFieldValue('vs_ids', []);
                                }}
                                onBlur={handleBlur}
                                value={values.dh_id}
                            >
                                <option value={""}>Select Duty Holder</option>
                                {dutyHolderList.map((dh, index) => (
                                    <option key={index} value={dh._id}>{dh.dh_name}</option>
                                ))}
                            </select>
                            <FormError touched={touched.dh_id} message={errors.dh_id} />
                        </div>

                        <div>
                            <label htmlFor="inst_ids" className="block mb-1 text-blue-900 font-semibold">Installations*</label>
                            <Select
                                id="inst_ids"
                                name="inst_ids"
                                aria-label="Installations"
                                className="w-full md:w-1/2"
                                classNamePrefix="react-select"
                                options={formatInstListToSelectList(installationList)}
                                isMulti
                                isSearchable
                                components={makeAnimated()}
                                closeMenuOnSelect={false}
                                placeholder="Select Installations"
                                onChange={(inst) => {
                                    let idList = inst && inst.length > 0 ? inst.map(id => id.value) : [];
                                    setSelectedInstIdList(idList);
                                    setFieldValue('inst_ids', (inst ? inst : []));
                                    setFieldValue('vs_ids', []);
                                }}
                                onBlur={inst => {setFieldTouched('inst_ids', (inst ? inst : []))}}
                                value={values.inst_ids}    
                            />
                            <FormError touched={touched.inst_ids} message={errors.inst_ids} />
                        </div>

                        {values.vs_rpt_type === "1" &&
                            <>
                                <div>
                                    <label htmlFor="vr_start_date" className="block mb-1 text-blue-900 font-semibold">Start Date*</label>
                                    <DatePicker
                                        id="vr_start_date"
                                        selected={values.vr_start_date}
                                        dateFormat="dd MMMM yyyy - h:mm aa"
                                        name="vr_start_date"
                                        onChange={(date) => {
                                            setFieldValue('vr_start_date', date);                             
                                            setFieldValue('vs_ids', []);
                                            handleStartDateChange(date);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.vr_start_date}
                                        className="form-control block w-full md:w-1/2"
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                    />
                                    <FormError touched={touched.vr_start_date} message={errors.vr_start_date} />
                                </div>

                                <div>
                                    <label htmlFor="vr_end_date" className="block mb-1 text-blue-900 font-semibold">End Date*</label>
                                    <DatePicker
                                        id="vr_end_date"
                                        selected={values.vr_end_date}
                                        dateFormat="dd MMMM yyyy - h:mm aa"
                                        name="vr_end_date"
                                        onChange={(date) => {
                                            setFieldValue('vr_end_date', date);                                          
                                            setFieldValue('vs_ids', []);
                                            handleEndDateChange(date)
                                        }}
                                        onBlur={handleBlur}
                                        value={values.vr_end_date}
                                        className="form-control block w-full md:w-1/2"
                                        showMonthDropdown
                                        showYearDropdown
                                        minDate={new Date(values.vr_start_date)}
                                        autoComplete="off"
                                    />
                                    <FormError touched={touched.vr_end_date} message={errors.vr_end_date} />
                                </div>
                            </>
                        }                  

                        <div>
                            <label htmlFor="vs_statuses" className="block mb-1 text-blue-900 font-semibold">Verification Schedule Status</label>
                            <Select
                                id="vs_statuses"
                                name="vs_statuses"
                                aria-label="Verification Schedule Status"
                                className="w-full md:w-1/2"
                                classNamePrefix="react-select"
                                options={vsStatusList}
                                isMulti
                                isSearchable
                                components={makeAnimated()}
                                closeMenuOnSelect={false}
                                placeholder="Select Schedule Status"
                                onChange={(schedule) => {
                                    let idList = schedule && schedule.length > 0 ? schedule.map(id => lowerCase(id.label)) : [];
                                    setSelectedVsStatusList(idList);
                                    setFieldValue('vs_statuses', (schedule ? schedule : []));
                                    setFieldValue('vs_ids', []);
                                }}
                                onBlur={(schedule) => {setFieldTouched('vs_statuses', (schedule ? schedule : []))}}
                                value={values.vs_statuses}    
                            />
                            <FormError touched={touched.vs_statuses} message={errors.vs_statuses} />
                        </div>

                        <div>
                            <label htmlFor="vs_ids" className="block mb-1 text-blue-900 font-semibold">Verification Schedules*</label>
                            <Select
                                id="vs_ids"
                                name="vs_ids"
                                aria-label="Verification Schedules"
                                className="w-full md:w-1/2"
                                classNamePrefix="react-select"
                                options={formatVsListToSelectList(vsList)}
                                isMulti
                                isSearchable
                                components={makeAnimated()}
                                closeMenuOnSelect={false}
                                placeholder="Select Schedules"
                                onChange={schedule => {setFieldValue('vs_ids', (schedule ? schedule : []))}}
                                onBlur={schedule => {setFieldTouched('vs_ids', (schedule ? schedule : []))}}
                                value={values.vs_ids}    
                            />
                            <FormError touched={touched.vs_ids} message={errors.vs_ids} />
                        </div>
                        
                        <button type="submit" className="mt-3 button-red capitalize">Next</button>
                    </form>
                )}
            </Formik>
        </div>
    );
}

function formatInstListToSelectList(installations) {
    let formatedList = [];
    if (installations && installations.length) {
        let newInstallationList = installations.filter(installation => installation.installation_archived !== true && installation.installation_status === NOTIFICATION_STATUS_APPROVED);
        newInstallationList.forEach(installation => {
            let newInstallation = {
                value : installation._id,
                label: installation.installation_title
            }
            formatedList.push(newInstallation);
        });
    }
    return formatedList;
}

function formatVsListToSelectList(vsList) {
    let formatedList = [];
    if (vsList && vsList.length) {
        let newVsList = vsList.filter(vs => vs.vs_archived !== true);
        newVsList.forEach(vsItem => {
            let newVs = {
                value : vsItem._id,
                label: vsItem.vs_title
            }
            formatedList.push(newVs);
        });
    }
    return formatedList;
}

//function formatBackToIdList(items) {
//    let formatedList = [];
//    if (items && items.length) {
//        items.forEach(item => {
//            let id = item.value;
//            formatedList.push(id);
//        });
//    }
//    return formatedList;
//}

function formatInstallationData(installations) {
    let formatedList = [];
    if (installations && installations.length) {
        let newInstallationList = installations.filter(installation => installation.installation_archived !== true && installation.installation_status === NOTIFICATION_STATUS_APPROVED);
        formatedList = newInstallationList;
    }
    return formatedList;
}

export default VerificationScheduleReportIndex;