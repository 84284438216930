/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';
import { AuthContext } from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import { createUserTypeAndRoleObject } from "../../utils";

const ViewBarrier = (props) => {

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const [drDnInstallation, setDrDnInstallation] = useState({});

  const { id, assetGroupId, installationId, barrierId = "" } = useParams();
  const { location: { state = "" } } = props ? props : {};

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const history = useHistory();

  const BARRIER_QUERY = gql`
    query Barrier($barrierId: ID!){
        barrier(_id: $barrierId) {
            dh_id{
                _id
                dh_name
            }
            ag_id{
                _id
            }
            installation_id{
              _id
              installation_title
            }
            bar_title 
            bar_notes
            bar_order_no
            bar_status
        }
    }
    `;

  const { loading, error, data } = useQuery(BARRIER_QUERY, {
    variables: { barrierId },
    errorPolicy: 'all'
  });

  const [barrier, setBarrier] = useState([]);

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    const {installation } = navContext;
    const { id: prevId } = drDnInstallation;
     if (prevId && installation.id && prevId !== installation.id) {
          history.push('/');
     }
     setDrDnInstallation(installation);
  }, [navContext]);

  useEffect(() => {
    if (data && data.barrier) {
      setBarrier(data.barrier)
    }
  }, [data]);

  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 100, 40, 24],
    header: function (currentPage, pageCount) {

      var headerObj;
      if (currentPage % 2) {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['75%', '25%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: barrier.dh_id && barrier.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: "Barrier Details", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      } else {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['25%', '75%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: barrier.dh_id && barrier.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: "Barrier Details", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      }

      return headerObj;
    },
    footer: function (currentPage, pageCount) {

      var footerObj;

      if (currentPage % 2) {
        footerObj = {
          table: {
            widths: [30, '*', 100, 28],
            body: [
              [
                { text: ' ', border: [false, false, false, false] },
                { text: 'BARRIER DETAILS', style: 'footerText', border: [false, false, false, false] },
                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                { text: ' ', border: [true, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      } else {
        footerObj = {
          table: {
            widths: [30, 100, '*', 28],
            body: [
              [
                { text: ' ', border: [false, false, true, false] },
                { text: currentPage.toString(), border: [true, false, false, false] },
                { text: `Copyright © by ABS. All Rights Reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                { text: ' ', border: [false, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      }

      return footerObj;

    },
    info: {
      title: barrier.dh_id && barrier.dh_id.dh_name + ' - ' + barrier.bar_title,
    },
    content: [

      { text: barrier.bar_title, style: 'title' },

      {
        table: {
          widths: ['30%', '70%'],
          headerRows: 0,
          body: [
            [{ text: 'Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, barrier.bar_status],
            [{ text: 'Duty Holder', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, barrier.dh_id && barrier.dh_id.dh_name],
            [{ text: 'Installation', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, barrier.installation_id && barrier.installation_id.installation_title],
            [{ text: 'Order No.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, barrier.bar_order_no],
          ]
        },
        style: 'standardTable',
      },

    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      title: {
        marginTop: 10,
        font: 'Montserrat',
        fontSize: 14,
        bold: true,
        color: '#002a4e'
      },
      redTitle: {
        marginBottom: 10,
        font: 'Montserrat',
        color: '#b40c1e'
      },
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1.2
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }

  const pdfTab = () => {
    pdfMake.createPdf(docDefinition).open();
  }

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the barrier data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Barrier Details</h1>
      </div>

      {displayBackLink(state, id, assetGroupId, installationId)}

      <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      <div className="w-full lg:w-1/2">

        <div className="my-6 pb-6 border-b-2 border-gray-200">
          <h2 className="font-bold text-2xl text-blue-900">{barrier.bar_title}</h2>

          <table className="w-full my-5 table-auto">
            <tbody>
              <tr>
                <th className="p-3 text-left bg-white border">Status</th>
                <td className="p-3 border">{barrier.bar_status}</td>
              </tr>
              {barrier.dh_id &&
                <tr>
                  <th className="p-3 text-left bg-white border">Duty Holder</th>
                  <td className="p-3 border">{barrier.dh_id.dh_name}</td>
                </tr>
              }
              {barrier.installation_id &&
                <tr>
                  <th className="p-3 text-left bg-white border">Installation</th>
                  <td className="p-3 border">{barrier.installation_id.installation_title}</td>
                </tr>
              }
              <tr>
                <th className="p-3 text-left bg-white border">Order No.</th>
                <td className="p-3 border">{barrier.bar_order_no}</td>
              </tr>
              {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                <>
                  <tr>
                    <th className="p-3 text-left bg-white border" colSpan="2">Notes</th>
                  </tr>
                  <tr>
                    <td className="p-3 border" colSpan="2">{barrier.bar_notes}</td>
                  </tr>
                </>
              }
            </tbody>
          </table>

        </div>

        {displayBackLink(state, id, assetGroupId, installationId)}

        <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      </div>

    </div>
  )

}

function displayBackLink(state, id, assetGroupId, installationId) {
  if (assetGroupId !== undefined && installationId !== undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barriers` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
}

export default ViewBarrier;
