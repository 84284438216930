import pdfMake from "pdfmake/build/pdfmake";

const {REACT_APP_URL} = process.env;

  pdfMake.fonts = {
    Montserrat: {
      normal: `${REACT_APP_URL}/fonts/Montserrat-Regular.ttf`,
      bold: `${REACT_APP_URL}/fonts/Montserrat-Bold.ttf`,
      italics: `${REACT_APP_URL}/fonts/Montserrat-Italic.ttf`,
      bolditalics: `{REACT_APP_URL}/fonts/Montserrat-BoldItalic.ttf`
    },
    Tinos: {
      normal: `${REACT_APP_URL}/fonts/Tinos-Regular.ttf`,
      bold: `${REACT_APP_URL}/fonts/Tinos-Bold.ttf`,
      italics: `${REACT_APP_URL}/fonts/Tinos-Italic.ttf`,
      bolditalics: `${REACT_APP_URL}/fonts/Tinos-BoldItalic.ttf`
    },
    
    Roboto: {
      normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
      bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    },
  }