/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext} from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils";

const COMPETENCY_QUERY = gql`
query Competency($compId: ID!){
  competency(_id: $compId) {
        _id
        comp_code
        comp_title
        comp_level
        comp_notes
        comp_cat_id{
        comp_cat_code
        comp_cat_title
        }
    }
}`;

const ViewCompetency = (props) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const [comp, setCompetency] = useState({});
  const { compId = "" } = useParams();
  const { location: { state = "" } } = props ? props : {};

  const [competency, { data: competencyData }] = useLazyQuery(COMPETENCY_QUERY);

  useEffect(() => {
    if (authContext && authContext.user){
        let result = createUserTypeAndRoleObject(authContext);
        setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
      if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
          history.push('/access-permissions');
      }
  }, [userTypeAndRoleObject]);

  useEffect(() => {
    competency({ variables: { compId }, errorPolicy: 'all' });
  }, [])

  useEffect(() => {
    if (competencyData && competencyData.competency) {
      setCompetency(competencyData.competency)
    }
  }, [competencyData])

  const displayBackLink = (state) => {
    return (
      <Link to={{
        pathname: "/user-competency/competencies" + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Competency Detail</h1>
      </div>

      {displayBackLink(state)}

      <div className="w-full lg:w-1/2">
        <div className="my-6">
          <h2 className="font-bold text-2xl text-blue-900">{comp.comp_title}</h2>
          <h2 className="mb-2 text-lg text-blue-900">{comp.comp_cat_id && comp.comp_cat_id.comp_cat_title}</h2>
          <h3 className="font-bold text-red-900">{comp.comp_code}</h3>

          <h3 className="font-bold text-xl text-blue-900">{comp.comp_level}</h3>

          <p className="mt-3">{comp.comp_notes}</p>
        </div>
      </div>

      {displayBackLink(state)}

    </div>
  )
}

export default ViewCompetency;
