/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useContext} from 'react';
import {Link, useParams} from 'react-router-dom';
import Table from '../global/Table';
import TableDropDown from '../global/TableDropDown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import PageSpinner from "../global/pageSpinner";
import {AuthContext} from "../../context/authContext";
import BreadCrumb from "../global/Breadcrumb";
import {capitaliseFirstCase, createUserTypeAndRoleObject} from "../../utils/index";
import {NOTIFICATION_STATUS_APPROVED, NOTIFICATION_STATUS_REJECT, NOTIFICATION_STATUS_REVISION} from "../../constants";
import Comment from '../global/Comment';
import {sysObjects} from "../../constants/sysObjectList";

const dropdownListItems = ({dutyHolderId, assetGroupId, installationId, barrierId, seceId, psId, vaId}) => (
  assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && vaId !== undefined ?
    [
      {
        id: 1,
        name: 'Reference Activities',
        link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/Reference-activities`
      }
    ]
    : assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && vaId !== undefined ?
      [
        {
          id: 1,
          name: 'Reference Activities',
          link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/Reference-activities`
        },
      ]
    : null
  );

const VA_BY_PS_ID_QUERY = gql`
    query VerificationActivitiesByPSId ($psId: ID!){
        verificationActivitiesByPSId (psId: $psId){
            _id
            sece_id{
                sece_title
            }
            ps_id{
                ps_title
            }
            va_ref
            va_client_ref
            va_short_desc
            va_long_desc
            va_type
            va_onshore_offshore
            va_freq
            va_baseline_sample
            va_class
            va_legislation
            va_archived
            va_archived_id
            va_archived_date
            va_status
        }
    }`;

const UPDATE_VERIFICATION_ACTIVITY = gql`
    mutation UpdateVerificationActivity($vaId: String!, $userId: String, $va: UpdateVerificationActivityInput!){
        updateVerificationActivity(_id: $vaId, userId: $userId, va: $va){
            _id
        }
    }`;

function VerfificationActivities(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [vaList, setVaList] = useState([]);
  const [showArchivedVas, setShowArchivedVas] = useState(false);

  const {id, assetGroupId, installationId, barrierId, seceId, psId} = useParams();

  const authContext = useContext(AuthContext);

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const {location: {state = ""}, match: {url = ""}} = props ? props : {};

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  const [verificationActivitiesByPSId, {loading, error, data: vaData, refetch}] = useLazyQuery(VA_BY_PS_ID_QUERY);
  const [updateVerificationActivity, {data: updateVaData}] = useMutation(UPDATE_VERIFICATION_ACTIVITY);

  useEffect(() => {
    if (state === "archived") {
      setShowArchivedVas(true);
    }
  }, []);

  useEffect(() => {
    if (psId) {
      verificationActivitiesByPSId({variables: {psId: psId}, errorPolicy: 'all'});
    }
  }, [showArchivedVas]);

  useEffect(() => {
    if (vaData && vaData.verificationActivitiesByPSId) {
      const {_id: userId = ""} = authContext.user ? authContext.user : {};
      let tempVaList = formatVaData(vaData.verificationActivitiesByPSId, showArchivedVas, userId, userTypeAndRoleObject);
      setVaList(tempVaList);
    }
  }, [vaData]);

  useEffect(() => {
    if (updateVaData && updateVaData.updateVerificationActivity) {
      refetch();
    }
  }, [updateVaData]);

  const archiveManager = (vaId, isArchived, archivedId, archivedDate, userId) => {
    if (vaId !== "") {
      const payload = {
        va_archived: isArchived ? false : true,
        va_archived_id: isArchived ? archivedId : userId,
        va_archived_date: isArchived ? archivedDate : new Date()
      }
      updateVerificationActivity({variables: {vaId: vaId, userId: userId, va: payload}});
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Ref',
        accessor: 'va_client_ref',
      },
      {
        Header: 'Verification Activity',
        accessor: 'va_short_desc',
      },
      {
        Header: 'SECE',
        accessor: 'sece_id.sece_title',
      },
      {
        Header: 'Performance Standard',
        accessor: 'ps_id.ps_title',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({value: {_id, va_archived, va_archived_id, va_archived_date, user_id, va_status, userTypeAndRoleObject}}) => {
          return (
            <div className="text-right">

              <Comment sysObjName={sysObjects.verificationActivity}
                sysObjRecordId={_id}
                buttonName={"Comments"}
                buttonClassName={"button-red mb-1 mr-1 text-xs"} />

              {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && psId !== undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activity/view/${_id}`,
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {va_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (va_status === NOTIFICATION_STATUS_APPROVED ||
                      va_status === NOTIFICATION_STATUS_REJECT ||
                      va_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activity/edit/${_id}`,
                        state: { formMode: 'edit' }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }

              {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && psId !== undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activity/view/${_id}`,
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {va_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (va_status === NOTIFICATION_STATUS_APPROVED ||
                      va_status === NOTIFICATION_STATUS_REJECT ||
                      va_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activity/edit/${_id}`,
                        state: { formMode: 'edit' }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }

              {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                (va_status === NOTIFICATION_STATUS_APPROVED ||
                  va_status === NOTIFICATION_STATUS_REJECT ||
                  va_status === NOTIFICATION_STATUS_REVISION) &&
                <Link
                  to="#"
                  className="button-red mb-1 mr-1 text-xs"
                  onClick={() => {
                    archiveManager(_id, va_archived, va_archived_id, va_archived_date, user_id)
                  }}
                >
                  {va_archived ? 'Unarchive' : 'Archive'}
                </Link>
              }

              <TableDropDown title="More" items={dropdownListItems({
                  dutyHolderId: id,
                  assetGroupId: assetGroupId,
                  installationId: installationId,
                  barrierId: barrierId,
                  seceId: seceId,
                  psId: psId,
                  vaId: _id
                })}/>

            </div>
          )
        }
      },
    ],
    []
  )

  if (loading) {
    return (
      <PageSpinner/>
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Verification Activities <FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)}/></h1>

        {showHelp &&
        <div>
          {showArchivedVas === false ?
          <div>
            <p className="mt-3">A table of active Verification Activities, each activity can be viewed, edited and archived.  Click 'More' for Reference Activities.</p>
            <p>Add a new Verification Activity by clicking 'Add Performance Standards'.</p>
          </div>
          :
          <div>
            <p className="mt-3">A table of archived Performance Standards, each entry can be viewed and unarchived.  Click 'More' for additional options.</p>
          </div>
          }
        </div>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <BreadCrumb payload={JSON.stringify({
        dh_id: id,
        ag_id: assetGroupId,
        installation_id: installationId,
        bar_id: barrierId,
        sece_id: seceId,
        ps_id: psId,
        last_crumb: 'Verification Activities'
      })}/>

      {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && psId !== undefined &&
      <div className="inline">
        <Link
          to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standards`}
          className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
                                                                      className="text-white"/> Back</Link>

        {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" &&
        <Link
          to={{
            pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activity/add`,
            state: {formMode: 'add'}
          }}
          className="button-red mr-2 mb-2 py-3 px-5"
        >
          Add Verification Activity
        </Link>
        }
      </div>
      }

      {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && psId !== undefined &&
      <div className="inline">
        <Link
          to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standards`}
          className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
                                                                      className="text-white"/> Back</Link>

        {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" &&
        <Link
          to={{
            pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activity/add`,
            state: {formMode: 'add'}
          }}
          className="button-red mr-2 mb-2 py-3 px-5"
        >
          Add Verification Activity
        </Link>
        }
      </div>
      }

      <Link to={{
        pathname: showArchivedVas ? url : (url + "/archived"),
        state: showArchivedVas ? "" : "archived"
      }}
            className="button-red mr-2 mb-2 py-3 px-5"
            onClick={() => {
              setShowArchivedVas(!showArchivedVas)
            }}
      >
        {showArchivedVas ? "Active Verification Activities" : "Archived Verification Activities"}

      </Link>

      {vaList.length > 0 &&
        <Link to={`${url}/histories`} className="button-red mb-2 mr-2 py-3 px-5">History</Link>
      }

      {vaList.length !== 0 ?
        <Table columns={columns} data={vaList}/>
        : <h4 className="mt-4">There are currently no {showArchivedVas ? "archived" : ""} Verification Activities</h4>}

    </div>
  )
}

function formatVaData(vaList, showArchivedVas, userId, userTypeAndRoleObject) {
  let newVaList = [];

  if (vaList && vaList.length && userId !== "") {
    let tempVaList = vaList.filter(va => showArchivedVas ? va.va_archived === true : va.va_archived !== true);
    tempVaList.forEach(verAct => {
      let formatedStatus = capitaliseFirstCase(verAct.va_status);
      let newVa = { ...verAct, status: formatedStatus, user_id: userId, userTypeAndRoleObject: userTypeAndRoleObject };
      newVaList.push(newVa);
    })
  };

  return newVaList;
}

export default VerfificationActivities;