/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { useParams, useHistory } from 'react-router-dom';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { omit, capitalize } from 'lodash';
import { AuthContext } from "../../context/authContext";
import FileUpload from '../global/FileUpload';
import PageSpinner from '../global/pageSpinner';
import BreadCrumb from "../global/Breadcrumb";
import { parseJSONSafely, updateAttachmentData, removeLastNthParamFrmUrl, createUserTypeAndRoleObject } from "../../utils";
import { NATURE_SYSTEM_VARIABLE_TYPE_ID, VA_FREQUENCY_SYSTEM_VARIABLE_TYPE_ID } from '../../constants/';

const { REACT_APP_FILE_MANAGER_API } = process.env;

const validation = (vaByClientRefCount) => {
    let validation = {};
    validation = Yup.object().shape({
        sece_id: Yup.string().label("sece_id").required("SECE is required"),
        ps_id: Yup.string().label("ps_id").required("Performance Standard is required"),
        va_client_ref_exist: Yup.boolean().default(vaByClientRefCount > 0),
        va_client_ref: Yup.string()
            .label("va_client_ref")
            .required("Client Ref is required")
            .when('va_client_ref_exist', {
                is: true,
                then: Yup.string().test("va_client_ref", "Client ref already exists", function (value) {
                    const { path, createError } = this;
                    return createError({ path, message: "Client ref already exists" });
                }),
            }),
        va_short_desc: Yup.string().label("va_short_desc").required("Short Description is required"),
        va_long_desc: Yup.string().label("va_long_desc").required("Long Description is required"),
        va_type: Yup.string().label("va_type").required("Type is required"),
        va_onshore_offshore: Yup.string().label("va_onshore_offshore").required("Offshore/Onshore is required"),
        va_freq: Yup.string().label("va_freq").required("Frequency is required"),
        va_baseline_sample: Yup.string().label("va_baseline_sample").required("Baseline Sample is required"),
        va_class: Yup.string().label("va_class").required("Class is required"),
    });
    return validation;
}

const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
            _id
            sysvar_title
            sysvar_archived
            sysvartype_id{
                _id
                sysvartype_title
            }
        }
}`;

const CREATE_VERIFICATION_ACTIVITY = gql`
    mutation AddVerificationActivity($va: CreateVerificationActivity!, $psId: String) {
        createVerificationActivity(va: $va, psId: $psId) {
            _id
            success
            status_code
            message
        }
    }
`;

const UPDATE_VERIFICATION_ACTIVITY = gql`
    mutation UpdateVerificationActivity($id: String!, $userId: String, $va: UpdateVerificationActivityInput!, $psId: String) {
        updateVerificationActivity(_id: $id, userId: $userId va: $va, psId: $psId) {
            dh_id{
                dh_name
            }
            sece_id{
                sece_title
            }
            ps_id{
                ps_title
            }
            va_short_desc
            va_long_desc
            va_type
            va_onshore_offshore
            va_freq
            va_baseline_sample
            va_class
            va_legislation
            success
            status_code
            message               
        }
    }
`;

const VA_QUERY = gql`
query VerificationActivity($vaId: ID!){
    verificationActivity(_id: $vaId) {
        dh_id{
            _id
            dh_name
        }
        ag_id{
            _id
        }
        installation_id{
            _id
        }
        bar_id{
            _id
        }
        sece_id{
            _id
            sece_title
        }
        ps_id{
            _id
            ps_title
        }
        va_client_ref
        va_short_desc
        va_long_desc
        va_type
        va_onshore_offshore
        va_freq
        va_baseline_sample
        va_class
        va_class_attachments
        va_legislation
        va_abs_ivb
    }
}`;

const VA_BY_CLIENT_REF_QUERY = gql`
query VaByClientRef($clientRef: String!){
  vaByClientRef(clientRef: $clientRef)
}`;

const AddEditVerificationActivity = (props) => {
    const authContext = useContext(AuthContext);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
    const history = useHistory();

    const [showHelp, setShowHelp] = useState(false);

    const [natureList, setNatureList] = useState([]);
    const [vaFreqList, setVaFreqList] = useState([]);

    const [vaByClientRefCount, setVaByClientRefCount] = useState(undefined);
    const [enteredClientRef, setEnteredClientRef] = useState(undefined);

    const { formMode, id, assetGroupId, installationId, barrierId, seceId, psId, vaId = "" } = useParams();
    const { match: { url = "" } } = props ? props : {};

    const [formInitValues, setFormInitValues] = useState({
        dh_id: id,
        ag_id: assetGroupId,
        installation_id: installationId,
        bar_id: barrierId,
        sece_id: seceId,
        ps_id: psId,
        va_client_ref: "",
        va_short_desc: "",
        va_long_desc: "",
        va_type: "",
        va_onshore_offshore: "",
        va_freq: "",
        va_baseline_sample: "",
        va_class: "",
        va_class_attachments: "[]",
        va_legislation: "[]",
        va_abs_ivb: false
    });

    const [verificationActivity, { data: vaData }] = useLazyQuery(VA_QUERY);
    const [vaByClientRef, { data: vaByClientRefData }] = useLazyQuery(VA_BY_CLIENT_REF_QUERY);
    const [systemVariablesBySysVarTypeIds, { data: sysVarsData }] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);
    const [createVerificationActivity, {loading: loadingCreateVa, error: errorCreateVa, data: createVerificationActivityData }] = useMutation(CREATE_VERIFICATION_ACTIVITY);
    const [updateVerificationActivity, {loading: loadingUpdateVa, error: errorUpdateVa, data: updateVerificationActivityData }] = useMutation(UPDATE_VERIFICATION_ACTIVITY);

    const sysVarTypeIds = [NATURE_SYSTEM_VARIABLE_TYPE_ID, VA_FREQUENCY_SYSTEM_VARIABLE_TYPE_ID];

    useEffect(() => {
        if (authContext && authContext.user) {
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);

    useEffect(() => {
        systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });
        if (formMode === 'edit' && vaId !== "") {
            verificationActivity({ variables: { vaId: vaId }, errorPolicy: 'all' });
        }
    }, []);

    useEffect(() => {
        if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
            formatSysVarDataAndUpdateStates(sysVarsData.systemVariablesBySysVarTypeIds,
                setNatureList,
                setVaFreqList,
                NATURE_SYSTEM_VARIABLE_TYPE_ID,
                VA_FREQUENCY_SYSTEM_VARIABLE_TYPE_ID);
        }
    }, [sysVarsData]);

    useEffect(() => {
        if (vaData && vaData.verificationActivity) {

            let tempVaData = vaData.verificationActivity;

            const { _id: dutyHolderId = "" } = tempVaData.dh_id ? tempVaData.dh_id : {};
            const { _id: agId = "" } = tempVaData.ag_id ? tempVaData.ag_id : {};
            const { _id: instId = "" } = tempVaData.installation_id ? tempVaData.installation_id : {};
            const { _id: barId = "" } = tempVaData.bar_id ? tempVaData.bar_id : {};
            const { _id: seceId = "" } = tempVaData.sece_id ? tempVaData.sece_id : {};
            const { _id: psId = "" } = tempVaData.ps_id ? tempVaData.ps_id : {};

            let vaClassAttachments = tempVaData.va_class_attachments && tempVaData.va_class_attachments !== "" ? tempVaData.va_class_attachments : "[]";
            let vaLegislation = tempVaData.va_legislation && tempVaData.va_legislation !== "" ? tempVaData.va_legislation : "[]";

            setFormInitValues({
                dh_id: dutyHolderId,
                ag_id: agId,
                installation_id: instId,
                bar_id: barId,
                sece_id: seceId,
                ps_id: psId,
                va_client_ref: tempVaData.va_client_ref,
                va_short_desc: tempVaData.va_short_desc,
                va_long_desc: tempVaData.va_long_desc,
                va_type: tempVaData.va_type,
                va_onshore_offshore: tempVaData.va_onshore_offshore,
                va_freq: tempVaData.va_freq,
                va_baseline_sample: tempVaData.va_baseline_sample,
                va_class: tempVaData.va_class,
                va_class_attachments: vaClassAttachments,
                va_legislation: vaLegislation,
                va_abs_ivb : tempVaData.va_abs_ivb ? tempVaData.va_abs_ivb : false
            })
        }
    }, [vaData]);

    useEffect(() => {
        if (createVerificationActivityData) {
            const { success, message } = createVerificationActivityData.createVerificationActivity;
            if (!success) {
                alert(message);
            }
            if (success) {
                if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && psId !== undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activities`);
                }
                if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && psId !== undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activities`);
                }
            }
        }
    }, [createVerificationActivityData]);

    useEffect(() => {
        if (updateVerificationActivityData) {
            const { success, message } = updateVerificationActivityData.updateVerificationActivity;
            if (!success) {
                alert(message);
            }
            if (success) {
                if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && psId !== undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activities`);
                }
                if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && psId !== undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activities`);
                }
            }
        }
    }, [updateVerificationActivityData]);

    useEffect(() => {
        if (vaByClientRefData) {
            if (formMode === "edit" && enteredClientRef === formInitValues.va_client_ref) {
                setVaByClientRefCount(0);
            } else {
                setVaByClientRefCount(vaByClientRefData.vaByClientRef);
            }
        }
    }, [vaByClientRefData]);

    const handleClientRefChange = (event) => {
        let clientRef = event.target.value;
        setEnteredClientRef(clientRef);
        vaByClientRef({ variables: { clientRef: clientRef }, errorPolicy: 'all' });
    }

    let tempClassAttachFileList = [];
    const handleClassAttachFilesDescription = (item) => {
        let index = tempClassAttachFileList.findIndex(f => f.name === item.name);
        if (index >= 0) {
            tempClassAttachFileList[index] = item;
        } else {
            tempClassAttachFileList.push(item);
        }
    };

    let tempLinkedDocFileList = [];
    const handleLinkedDocFilesDescription = (item) => {
        let index = tempLinkedDocFileList.findIndex(f => f.name === item.name);
        if (index >= 0) {
            tempLinkedDocFileList[index] = item;
        } else {
            tempLinkedDocFileList.push(item);
        }
    };

    if (loadingCreateVa || loadingUpdateVa) {
        return (
            <PageSpinner displayText={"submitting form"}/>
        )
    }

    if (errorCreateVa || errorUpdateVa ) {
        return (
            <span>Something went wrong saving verification activity</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Verification Activity <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>
                {showHelp &&
                    <div>
                        <p>Enter the details into the required fields, including adding attachments by clicking on 'Attach Files'.</p>
                        <p>Once complete, click '<span className="capitalize">{formMode}</span> Verification Activity' which will initiate a notification for review.</p>
                    </div>
                }

            </div>

            <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>
            <BreadCrumb payload={JSON.stringify({
                dh_id: id,
                ag_id: assetGroupId,
                installation_id: installationId,
                bar_id: barrierId,
                sece_id: seceId,
                ps_id: psId,
                last_crumb: `${capitalize(formMode)} Verification Activity`
            })} />

            <Formik
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={validation(vaByClientRefCount)}
                onSubmit={(values, actions) => {
                    let tempValues = { ...values };
                    const { _id: userId = "" } = authContext.user ? authContext.user : {};

                    tempValues.va_class_attachments = updateAttachmentData(tempValues.va_class_attachments, tempClassAttachFileList);
                    tempValues.va_legislation = updateAttachmentData(tempValues.va_legislation, tempLinkedDocFileList);

                    tempValues.va_abs_ivb = tempValues.va_abs_ivb === "true" ? true : false;
                    
                    if (formMode === 'add') {
                        tempValues.va_added_id = userId;
                        tempValues.va_base_path = removeLastNthParamFrmUrl(url, 1);
                        createVerificationActivity({ variables: { va: tempValues, psId: psId } })
                    }

                    if (formMode === 'edit') {
                        updateVerificationActivity({ variables: { id: vaId, userId: userId, va: tempValues } })
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="my-4">
                            <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>
                        </div>

                        <div>
                            <label htmlFor="va_client_ref" className="block mb-1 text-blue-900 font-semibold">Client Ref*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="va_client_ref"
                                id="va_client_ref"
                                placeholder="Client Ref"
                                onChange={(event) => { handleClientRefChange(event); handleChange(event) }}
                                onBlur={handleBlur}
                                value={values.va_client_ref}
                            />
                            <FormError touched={touched.va_client_ref} message={errors.va_client_ref} />
                        </div>

                        <div>
                            <label htmlFor="va_short_desc" className="block mb-1 text-blue-900 font-semibold">Short Description*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="va_short_desc"
                                id="va_short_desc"
                                placeholder="Short Description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.va_short_desc}
                                rows="7"
                            />
                            <FormError touched={touched.va_short_desc} message={errors.va_short_desc} />
                        </div>

                        <div>
                            <label htmlFor="va_long_desc" className="block mb-1 text-blue-900 font-semibold">Long Description*</label>
                            <textarea
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="va_long_desc"
                                id="va_long_desc"
                                placeholder="Long Description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.va_long_desc}
                                rows="7"
                            />
                            <FormError touched={touched.va_long_desc} message={errors.va_long_desc} />
                        </div>

                        <div>
                            <label htmlFor="va_type" className="block mb-1 text-blue-900 font-semibold">Nature*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="va_type"
                                id="va_type"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.va_type}
                            >
                                <option value={""}>Select VA Nature</option>
                                {natureList.map(typ =>
                                    <option value={typ.title} key={typ.id}>{typ.title}</option>
                                )}
                            </select>
                            <FormError touched={touched.va_type} message={errors.va_type} />
                        </div>

                        <div>
                            <label htmlFor="va_offshore_onshore" className="block mb-1 text-blue-900 font-semibold">Onshore/Offshore*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="va_onshore_offshore"
                                id="va_onshore_offshore"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.va_onshore_offshore}
                            >
                                <option value={""}>Select</option>
                                <option value="Onshore">Onshore</option>
                                <option value="Offshore">Offshore</option>
                            </select>
                            <FormError touched={touched.va_onshore_offshore} message={errors.va_onshore_offshore} />
                        </div>

                        <div>
                            <label htmlFor="va_freq" className="block mb-1 text-blue-900 font-semibold">Frequency*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="va_freq"
                                id="va_freq"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.va_freq}
                            >
                                <option value={""}>Select</option>
                                {vaFreqList.map(freq =>
                                    <option value={freq.title} key={freq.id}>{freq.title}</option>
                                )}
                            </select>
                            <FormError touched={touched.va_freq} message={errors.va_freq} />
                        </div>

                        <div>
                            <label htmlFor="va_baseline_sample" className="block mb-1 text-blue-900 font-semibold">Baseline Sample*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="va_baseline_sample"
                                id="va_baseline_sample"
                                placeholder="Sample Size"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.va_baseline_sample}
                            />
                            <FormError touched={touched.va_baseline_sample} message={errors.va_baseline_sample} />
                        </div>

                        <div>
                            <label htmlFor="va_abs_ivb" className="block mb-1 text-blue-900 font-semibold">ABS IVB</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="va_abs_ivb"
                                id="va_abs_ivb"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.va_abs_ivb}
                            >
                                <option value={""}>Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                            <FormError touched={touched.va_abs_ivb} message={errors.va_abs_ivb} />
                        </div>

                        <div>
                            <label htmlFor="va_class" className="block mb-1 text-blue-900 font-semibold">Class*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="va_class"
                                id="va_class"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.va_class}
                            >
                                <option value={""}>Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Partial">Partial</option>
                            </select>
                            <FormError touched={touched.va_class} message={errors.va_class} />
                        </div>
                        {values.va_class === "Yes" &&
                            <div>
                                <label htmlFor="va_class_attachments" className="block mb-1 text-blue-900 font-semibold">Class Attachments</label>

                                {authContext.user && authContext.user.user_home_dir &&
                                    <FileUpload
                                        onAttach={(files) => setFieldValue('va_class_attachments', JSON.stringify(files))}
                                        id='fileManager'
                                        buttonText='Attach Files'
                                        apiURL={REACT_APP_FILE_MANAGER_API}
                                        toolbarText='Attach'
                                        homeDir={`/${authContext.user.user_home_dir}/`}
                                        showDescription={true}
                                        handleFileDescription={handleClassAttachFilesDescription}
                                        tempFileList={tempClassAttachFileList}
                                        {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.va_class_attachments) ? parseJSONSafely(values.va_class_attachments) : [] })}
                                    />
                                }
                                <FormError touched={touched.va_class_attachments} message={errors.va_class_attachments} />
                            </div>
                        }

                        <div>
                            <label htmlFor="va_legislation" className="block mb-1 text-blue-900 font-semibold">Linked Documentation*</label>

                            {authContext.user && authContext.user.user_home_dir &&
                                <FileUpload
                                    onAttach={(files) => setFieldValue('va_legislation', JSON.stringify(files))}
                                    id='fileManager'
                                    buttonText='Attach Files'
                                    apiURL={REACT_APP_FILE_MANAGER_API}
                                    toolbarText='Attach'
                                    homeDir={`/${authContext.user.user_home_dir}/`}
                                    showDescription={true}
                                    handleFileDescription={handleLinkedDocFilesDescription}
                                    tempFileList={tempLinkedDocFileList}
                                    {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.va_legislation) ? parseJSONSafely(values.va_legislation) : [] })}
                                />
                            }
                            <FormError touched={touched.va_legislation} message={errors.va_legislation} />
                        </div>

                        <button type="submit" className="mt-3 button-red capitalize">{formMode} Verification Activity</button>
                    </form>
                )}
            </Formik>

        </div>
    )

}

function formatSysVarDataAndUpdateStates(list, setNatureList, setVaFreqList, natureTypeId, vaFreqTypeId) {
    let natureList = [];
    let vaFreqList = [];

    if (list && list.length) {
        let tempList = list.filter(sysVar => sysVar.sysvar_archived !== true);

        let tempNatureList = tempList.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === natureTypeId);
        let tempVaFreqList = tempList.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === vaFreqTypeId);

        tempNatureList.forEach(nature => {
            let newNature = {
                id: nature._id,
                title: nature.sysvar_title
            }
            natureList.push(newNature);
        })

        tempVaFreqList.forEach(freq => {
            let newVaFreq = {
                id: freq._id,
                title: freq.sysvar_title
            }
            vaFreqList.push(newVaFreq);
        })
    }
    setNatureList(natureList);
    setVaFreqList(vaFreqList);
}

export default AddEditVerificationActivity;
