/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import { useQueryString, removeLastNthParamFrmUrl, capitaliseFirstCase } from '../../utils';

const MAH_HISTORIES_QUERY = gql`
query MahHistoriesByDhId($dhId: String!, $filter: String){
    mahHistoriesByDutyHolderId(dhId: $dhId, filter: $filter) {
        _id
        dh_id {
            _id
        }
        mah_title
        mah_child_id
        mah_added_date
        mah_added_id
        mah_archived
        mah_archived_id
        mah_archived_date
        mah_status
        history_parent_record_id
        history_modifying_user_id{
           _id
           user_fname
           user_lname
           user_email
        }
        history_modified_date
        history_modified_date_timestamp
    }
}`;

function MajorAccidentHazardHistories(props) {
    const [showHelp, setShowHelp] = useState(false);
    const [mahHistories, setMahHistories] = useState([]);

    const { id: dhId = "", installationId = ""} = useParams();
    const queryString = useQueryString();
    const filters = queryString.get('filters');

    const { match: {url = ""}} = props ? props : {};

    const [mahHistoriesByDutyHolderId, { loading, error, data: mahHistoriesData }] = useLazyQuery(MAH_HISTORIES_QUERY);

    useEffect(() => {
        let localFilter = filters ? filters : JSON.stringify({ 
            ...(installationId && installationId !== "" && { installation_id: installationId })
        });
        mahHistoriesByDutyHolderId({ variables: { dhId, ...({ filter: localFilter }) }, errorPolicy: 'all' });
    }, [])

    useEffect(() => {
        if (mahHistoriesData && mahHistoriesData.mahHistoriesByDutyHolderId) {
            let hisList = mahHistoriesData.mahHistoriesByDutyHolderId;
            let formatedHisList = formatMahHistories(hisList);
            let reversedHistoryList = formatedHisList.reverse();
            return setMahHistories(reversedHistoryList);
        }
    }, [mahHistoriesData])


    const columns = React.useMemo(
        () => [
            {
                Header: 'MAH Title',
                accessor: 'name',
            },
            {
                Header: 'Modified Date',
                accessor: 'modified_date'
            },
            {
                Header: 'Modifying User',
                accessor: "modified_by",
            },
            {
                Header: 'Status',
                accessor: "status",
            },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: ({value:{id, dhId, parentId}}) => (
                    <div className="text-right">
                        <Link
                            to={{
                                pathname: `${removeLastNthParamFrmUrl(url, 1)}/view-diff/${id}/${parentId}`,
                            }}
                            className="button-red mb-1 mr-1 text-xs"
                        >
                            View Change
                        </Link>
                    </div>
                )
            },
        ],
        []
    )

    if (loading) {
        return (
            <PageSpinner />
        )
    }

    if (error) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-6 mb-6 border-b-2 border-gray-200">

                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">MAH History<FontAwesomeIcon
                    icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
                    onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                    <p className="mt-3">A table detailing changes to MAHs.</p>
                }

            </div>

            <Link to={{pathname: `${removeLastNthParamFrmUrl(url, 1)}`}} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>



            <Table columns={columns} data={mahHistories} />

        </div>
    )
}

function formatMahHistories(mahHistories) {

    let newMahHistories = [];
    if (mahHistories && mahHistories.length) {
        mahHistories.forEach(mahHis => {

            const { user_fname: userFname = "", user_lname: userLname = "" } = mahHis.history_modifying_user_id ? mahHis.history_modifying_user_id : {};
            const { _id: dutyHolderId = "" } = mahHis.dh_id ? mahHis.dh_id : {};
            let modifiedDate = new Date(mahHis.history_modified_date);
            let options = { year: 'numeric', month: 'long', day: 'numeric' };
            let modifiedDateString = modifiedDate.toLocaleDateString('en-GB', options);

            let userFullName = (userFname !== "" && userLname !== "") ? `${userFname} ${userLname}` : "N/A";

            let mahHistory = {
                id: mahHis._id,
                dhId: dutyHolderId,
                name: mahHis.mah_title,
                parentId: mahHis.history_parent_record_id,
                modified_date: modifiedDateString,
                modified_by: userFullName,
                status: mahHis.mah_status && mahHis.mah_status !== "" ? capitaliseFirstCase(mahHis.mah_status) : "N/A"
            };

            newMahHistories.push(mahHistory);
        })
    }
    return newMahHistories;
}

export default MajorAccidentHazardHistories;