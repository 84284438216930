import React, { useState, useEffect } from 'react';
import { capitalize } from 'lodash';
import { gql, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CREATE_DASHBOARD_TABLE = gql`
    mutation CreateDashboardTable($param: DashboardUpdateInput) {
        createDashboardTable(param: $param) {
            success
            status_code
            message
            inner_error
        }
    }
`;

const CREATE_STATISTICS_TABLE = gql`
    mutation CreateStatisticTable($param: StatisticUpdateInput) {
        createStatisticTable(param: $param) {
            success
            status_code
            message
            inner_error
        }
    }
`;

const CREATE_BOWTIE_DASHBOARD_TABLE = gql`
    mutation CreateBowtieDashboardTable($param: BowtieUpdateInput) {
        createBowtieDashboardTable(param: $param) {
            success
            status_code
            message
            inner_error
        }
    }
`;

const CREATE_BOWTIE_TABLE = gql`
    mutation CreateBowtieTable($param: BowtieUpdateInput) {
        createBowtieTable(param: $param) {
            success
            status_code
            message
            inner_error
        }
    }
`;

const UPDATE_ACTIVITY_TABLE = gql`
    mutation updateActivityTable($param: ActivityUpdateInput) {
        updateActivityTable(param: $param) {
            success
            status_code
            message
            inner_error
        }
    }
`;

const DASHBOARD = "dashboard";
const STATISTIC = "statistics";
const BOWTIE_DASHBOARD = "bowtie-dashboard";
const BOWTIE = "bowtie";
const ACTIVITY = "activity";

function BatchOperations(props) {
    const [showHelp, setShowHelp] = useState(false);
    const [batchOperationName, setBatchOperationName] = useState("");
    const [batchOperationMode, setBatchOperationMode] = useState(false);

    const [progress, setProgress] = useState(10);
    const [elapsedTime, setElapsedTime] = useState(0);

    const [isInProgress, setIsInProgress] = useState(false);
    //const [isCompleted, setIsCompleted] = useState(false);

    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    //const [statusCode, setStatusCode] = useState("");
    //const [message, setMessage] = useState("");
    const [innerErrorMessage, setInnerErrorMessage] = useState("");

    const [createDashboardTable, { data: dashData }] = useMutation(CREATE_DASHBOARD_TABLE);
    const [createStatisticTable, { data: statData }] = useMutation(CREATE_STATISTICS_TABLE);
    const [createBowtieDashboardTable, { data: bowDashData }] = useMutation(CREATE_BOWTIE_DASHBOARD_TABLE);
    const [createBowtieTable, { data: bowData }] = useMutation(CREATE_BOWTIE_TABLE);
    const [updateActivityTable, { data: actData }] = useMutation(UPDATE_ACTIVITY_TABLE);

    useEffect(() => {
        if (dashData && dashData.createDashboardTable) {
            let createDashboardResult = dashData.createDashboardTable;
            //console.log("createDashboardResult>>>>", createDashboardResult);

            const {
                success,
                inner_error
            } = createDashboardResult;

            setIsSuccess((success === true));
            setIsError((success === false));

            //setStatusCode(status_code);
            //setMessage(message);
            setInnerErrorMessage(inner_error);

            setIsInProgress(false);
            //setIsCompleted(true);
            setProgress(100);
        }
    }, [dashData]);

    useEffect(() => {
        if (statData && statData.createStatisticTable) {
            let createStatisticResult = statData.createStatisticTable;

            //console.log("createStatisticResult>>>>", createStatisticResult);

            const {
                success,
                inner_error
            } = createStatisticResult;

            setIsSuccess((success === true));
            setIsError((success === false));

            //setStatusCode(status_code);
            //setMessage(message);
            setInnerErrorMessage(inner_error);

            setIsInProgress(false);
            //setIsCompleted(true);
            setProgress(100);
        }
    }, [statData]);

    useEffect(() => {
        if (bowDashData && bowDashData.createBowtieDashboardTable) {
            let createBowtieDashboardResult = bowDashData.createBowtieDashboardTable;
            //console.log("createBowtieDashboardResult>>>>", createBowtieDashboardResult);

            const {
                success,
                inner_error
            } = createBowtieDashboardResult;

            setIsSuccess((success === true));
            setIsError((success === false));

            //setStatusCode(status_code);
            //setMessage(message);
            setInnerErrorMessage(inner_error);

            setIsInProgress(false);
            //setIsCompleted(true);
            setProgress(100);
        }
    }, [bowDashData]);

    useEffect(() => {
        if (bowData && bowData.createBowtieTable) {
            let createBowtieDashboardResult = bowData.createBowtieTable;
            //console.log("createBowtieDashboardResult>>>>", createBowtieDashboardResult);

            const {
                success,
                inner_error
            } = createBowtieDashboardResult;

            setIsSuccess((success === true));
            setIsError((success === false));

            //setStatusCode(status_code);
            //setMessage(message);
            setInnerErrorMessage(inner_error);

            setIsInProgress(false);
            //setIsCompleted(true);
            setProgress(100);
        }
    }, [bowData]);

    useEffect(() => {
        if (actData && actData.updateActivityTable) {
            let updateActivityResult = actData.updateActivityTable;
            //console.log("createBowtieDashboardResult>>>>", createBowtieDashboardResult);

            const {
                success,
                inner_error
            } = updateActivityResult;

            setIsSuccess((success === true));
            setIsError((success === false));

            //setStatusCode(status_code);
            //setMessage(message);
            setInnerErrorMessage(inner_error);

            setIsInProgress(false);
            //setIsCompleted(true);
            setProgress(100);
        }
    }, [actData]);

    useEffect(() => {
        let startTime;
        // track elapsed time
        startTime = Date.now();
        const timeIntervalId = setInterval(() => {
            setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
        }, 1000);

        return () => {
            clearInterval(timeIntervalId);
        };
    }, [progress]);

    const batchOperationManager = (operationType) => {

        setIsInProgress(true);
        //setIsCompleted(false);
        setBatchOperationName(operationType)

        setIsSuccess(false);
        setIsError(false);
        setProgress(10);
        setElapsedTime(0);

        switch (operationType) {
            case DASHBOARD:
                createDashboardTable({ variables: { param: {} }, errorPolicy: 'all' });
                break;
            case STATISTIC:
                createStatisticTable({ variables: { param: {} }, errorPolicy: 'all' });
                break;
            case BOWTIE_DASHBOARD:
                createBowtieDashboardTable({ variables: { param: {} }, errorPolicy: 'all' });
                break;
            case BOWTIE:
                createBowtieTable({ variables: { param: {} }, errorPolicy: 'all' });
                break;
            case ACTIVITY:
                updateActivityTable({ variables: { param: {} }, errorPolicy: 'all' });
                break;
            default:
                break;
        }
    }

    return (
        <div className="w-full px-8 pb-8">
            <div className="pb-6 border-b-2 border-gray-200">
                <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Batch Operations <FontAwesomeIcon
                    icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
                    onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                    <div>
                        <p className="mt-3">This page allows you to perform batch operations.</p>
                    </div>
                }
            </div>

            <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>
            <h6 className="mb-6 font-bold text-sm text-red-900">Dashboard - Admin - Batch Operations</h6>


            <div className="mt-4 text-blue-900 font-sans text-sm font-bold">Batch Operation Mode</div>
            <div className={"relative block w-10 mr-2 align-left select-none transition duration-200 ease-in"}>

                <input type="checkbox"
                    name={"header_id"} id={"header_id"}
                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                    checked={batchOperationMode}
                    onChange={(event) => { setBatchOperationMode(!batchOperationMode) }}
                />
                <label htmlFor={"header_id"} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
            </div>

            <button
                disabled={isInProgress || !batchOperationMode}
                type="button"
                className={`${"mt-3 button-red mr-2 mb-2 py-3 px-5" + (isInProgress || !batchOperationMode ? " cursor-not-allowed" : "") + (batchOperationName === DASHBOARD ? " underline" : "")}`}
                onClick={() => batchOperationManager(DASHBOARD)}
            >Create Dashboard Table
            </button>

            <button
                disabled={isInProgress || !batchOperationMode}
                type="button"
                className={`${"mt-3 button-red mr-2 mb-2 py-3 px-5" + (isInProgress || !batchOperationMode ? " cursor-not-allowed" : "") + (batchOperationName === STATISTIC ? " underline" : "")}`}
                onClick={() => batchOperationManager(STATISTIC)}
            > Create Statistics Table
            </button>

            <button
                disabled={isInProgress || !batchOperationMode}
                type="button"
                className={`${"mt-3 button-red mr-2 mb-2 py-3 px-5" + (isInProgress || !batchOperationMode ? " cursor-not-allowed" : "") + (batchOperationName === BOWTIE_DASHBOARD ? " underline" : "")}`}
                onClick={() => batchOperationManager(BOWTIE_DASHBOARD)}
            > Create Bowtie Dashboard Table
            </button>

            <button
                disabled={isInProgress || !batchOperationMode}
                type="button"
                className={`${"mt-3 button-red mr-2 mb-2 py-3 px-5" + (isInProgress || !batchOperationMode ? " cursor-not-allowed" : "") + (batchOperationName === BOWTIE_DASHBOARD ? " underline" : "")}`}
                onClick={() => batchOperationManager(BOWTIE)}
            > Create Bowtie Table
            </button>

            <button
                disabled={isInProgress || !batchOperationMode}
                type="button"
                className={`${"mt-3 button-red mr-2 mb-2 py-3 px-5" + (isInProgress || !batchOperationMode ? " cursor-not-allowed" : "") + (batchOperationName === ACTIVITY ? " underline" : "")}`}
                onClick={() => batchOperationManager(ACTIVITY)}
            > Create Activity Table
            </button>

            <div className="min-h-screen bg-gray-10 flex flex-col justify-center items-center">
                <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
                    <h1 className="text-center text-blue-900 text-2xl font-bold mb-4">{capitalize(batchOperationName)} Batch Operation Progress</h1>
                    {!isSuccess && !isError && isInProgress && (
                        <>
                            <div className="relative pt-1 mb-4">
                                <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200">
                                    <div
                                        style={{ width: `${progress}%` }}
                                        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-500 ease-in-out"
                                    />
                                </div>
                                <p className="text-sm font-semibold text-gray-600">{`${progress}%`}</p>
                            </div>
                            <p className="text-sm font-medium text-gray-600">
                                Please wait while the batch operation completes.
                                {elapsedTime > 0 && ` Elapsed time: ${elapsedTime}s`}
                            </p>
                        </>
                    )}
                    {isSuccess && (
                        <>
                            <p className="text-sm font-medium text-green-600 mb-4">Batch operation completed successfully!</p>
                            <button
                                className="button-red hover:bg-red-800 text-white font-semibold py-2 px-4"
                                onClick={() => { batchOperationManager(batchOperationName) }}
                            >
                                Start Over
                            </button>
                        </>
                    )}
                    {isError && (
                        <>
                            <p className="text-sm font-medium text-red-600 mb-4">There was an error during the batch operation.</p>
                            <button
                                className="button-red hover:bg-red-600 text-white font-semibold py-2 px-4 mr-6"
                                onClick={() => { batchOperationManager(batchOperationName) }}
                            >
                                Try Again
                            </button>
                            <button
                                className="button-red hover:bg-red-600 text-white font-semibold py-2 px-4"
                                onClick={() => { alert(innerErrorMessage) }}
                            >
                                View Error Message
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default BatchOperations;