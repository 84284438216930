/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Table from '../global/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery } from '@apollo/client';
import PageSpinner from "../global/pageSpinner";
import { AuthContext } from "../../context/authContext";
import { createUserTypeAndRoleObject } from "../../utils/index";

const ACCESS_LOGS_QUERY = gql`
    query   {
      accessLogs {
            _id
            al_user_type
            al_user_ip_address
            al_user_email
            al_login_status
            al_added_date
        }
}`;

function AccessLog() {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [showHelp, setShowHelp] = useState(false);
  const [accessLogList, setAccessLogList] = useState([]);

  const [accessLogs, { loading, error, data }] = useLazyQuery(ACCESS_LOGS_QUERY);

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
    }
  }, [userTypeAndRoleObject]);


  useEffect(() => {
    accessLogs({ variables: {}, errorPolicy: 'all' })
  }, [])

  useEffect(() => {
    if (data && data.accessLogs) {
      let tempList = formatAccessLogData(data.accessLogs);
      setAccessLogList(tempList);
    }
  }, [data])

  const columns = React.useMemo(
    () => [
      {
        Header: 'IP',
        accessor: 'user_ip',
      },
      {
        Header: 'User Type',
        accessor: 'user_type',
      },
      {
        Header: 'Email',
        accessor: 'user_email',
      },
      {
        Header: 'Date/Time',
        accessor: 'user_time',
      },
      {
        Header: 'Status',
        accessor: 'login_status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: '',
      }
    ],
    []
  )

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-4 pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Access Log <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3">Details of logins to ABS Verification Manager.</p>
        }

      </div>

      {/*
      <Link to="/" className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>
      */}

      <Table columns={columns} data={accessLogList} />

    </div>
  )
}

function formatAccessLogData(accessLogs) {
  let newList = [];
  let dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  if (accessLogs && accessLogs.length) {
    accessLogs.forEach((access) => {

      let dateAdded = new Date(access.al_added_date);
      let dateAddedString = dateAdded.toLocaleDateString('en-GB', dateOptions);
      let timeAddedString = dateAdded.toLocaleTimeString();

      let newAccessLog = {
        user_ip: access.al_user_ip_address,
        user_type: access.al_user_type,
        user_email: access.al_user_email,
        user_time: dateAddedString + " - " + timeAddedString,
        login_status: access.al_login_status
      }

      newList.push(newAccessLog);
    })
  }

  return newList;
}

export default AccessLog;