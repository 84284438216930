export const sysObjects = {
    user : "User",
    dutyHolder  : "DutyHolder",
    assetGroup  : "AssetGroup",
    installation : "Installation",
    barrier  : "Barrier",
    sece  : "Sece",
    majorAccidentHazard  : "MajorAccidentHazard",
    performanceStandard  : "PerformanceStandard",
    referenceActivity  : "ReferenceActivity",
    verificationActivity  : "VerificationActivity",
    location  : "Location",
    infoMatrix  : "InfoMatrix",
    report  : "Report",
    finding  : "Finding",
    userCompetency  : "UserCompetency",
    verificationScheduleReport  : "VerificationScheduleReport",
}