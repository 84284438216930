/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import DatePicker from 'react-datepicker';
//import { Link } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Line } from 'react-chartjs-2';
import PageSpinner from "../../components/global/pageSpinner";
import ErrorBoundary from '../global/ErrorBoundary';
import { AuthContext } from '../../context/authContext';
import { NavContext } from '../../context/navContext';
import { createUserTypeAndRoleObject, getBreadCrumbHeader } from "../../utils/index";
import moment from 'moment';

const Validation = Yup.object().shape({
    from_date: Yup.string().label("from_date").required("From Date is required"),
    to_date: Yup.string().label("to_date").required("To Date is required").test("to_date", "To date cannot be before From date", function (value) {
        const { path, createError, parent } = this;
        let responseDate = parent.from_date;
        return moment(value).isBefore(responseDate) ? createError({ path, message: "To date cannot be before From date" }) : true;
    }),
})

const DASHBOARD_SEARCH_QUERY = gql` 
query DashboardSearch($param: DashboardSearchInput!){ 
  dashboardSearch(param: $param){ 
    _id 
    verification_schedule_extended{ 
      labels 
      datasets { 
        label 
        borderColor 
        backgroundColor 
        fill 
        data 
      } 
    }  
    created_date 
  } 
}`;

const VS_STATS_BY_INSTALL_ID_AND_DATES_QUERY = gql`
query VerficationScheduleStatsByDatesAlt($dhId: ID, $instId: ID, $startDateInput: String!, $endDateOutput: String!){
    verficationScheduleStatsByDatesAlt(dhId: $dhId, instId: $instId, startDateInput: $startDateInput, endDateOutput: $endDateOutput) {
    labels
    datasets {
      label
      borderColor
      backgroundColor
      fill
      data
    }
  }
}`;

function VerificationScheduleStatsDashExpAlt(props) {
    const [showHelp, setShowHelp] = useState(false);
    const [VerificationData, setVerificationData] = useState({});
    const [formInitValues, setFormInitValues] = useState({ from_date: "", to_date: "" });
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [drDnDutyholder, setDrDnDutyholder] = useState({});
    const [drDnInstallation, setDrDnInstallation] = useState({});
    const [drDnSece, setDrDnSece] = useState({});

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);

    const [dashboardSearch, { loading: loadingDashSearch, error: errorDashSearch, data: dashSearchData }] = useLazyQuery(DASHBOARD_SEARCH_QUERY);
    const [verficationScheduleStatsByDatesAlt, { loading: loadingVsStatsByDates, error: errorVsStatsByDates, data: vsStatsByDatesData }] = useLazyQuery(VS_STATS_BY_INSTALL_ID_AND_DATES_QUERY);

    useEffect(() => {
        const { dutyHolder, installation, sece } = navContext;
        setDrDnDutyholder(dutyHolder);
        setDrDnInstallation(installation);
        setDrDnSece(sece);
    }, [navContext]);

    useEffect(() => {
        if (authContext && authContext.user) {
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
            const { _id: userId = "" } = authContext.user;
            const { from_date = "", to_date = "" } = formInitValues;

            let dh_id = "";
            let dashboard_type = "";
            const { userDhId = "", userType = "" } = result ?? {};
            if (userType === "ABS") {
                dh_id = drDnDutyholder.id || "";              
                dashboard_type = drDnInstallation.id ? "filter" : "default";
            } else if (userType === "Duty Holder") {
                dh_id = userDhId;
                dashboard_type = "filter";
            }

            let dashboardPayload = {
                user_id: userId,
                dashboard_type: dashboard_type,
                ...(dh_id && { dh_id: dh_id }),
                ...(drDnInstallation.id && { installation_id: drDnInstallation.id })
            };

            let vsStatsPayload = {
                user_id: userId,
                dashboard_type: dashboard_type,
                ...(dh_id && { dhId: dh_id }),
                ...(drDnInstallation.id && { instId: drDnInstallation.id })
            };

            //Clear Data
            setVerificationData({});
            if (userId !== "" && from_date === "" && to_date === "") {
                dashboardSearch({ variables: { param: { ...dashboardPayload } }, errorPolicy: 'all' });
            }

            if (userId !== "" && from_date !== "" && to_date !== "") {
                verficationScheduleStatsByDatesAlt({
                    variables: {
                        ...vsStatsPayload,
                        startDateInput: from_date,
                        endDateOutput: to_date
                    }, errorPolicy: 'all'
                });
            }
        }
    }, [authContext, drDnDutyholder, drDnInstallation, drDnSece, formInitValues]);

    useEffect(() => {
        if (dashSearchData && dashSearchData.dashboardSearch) {
            let dashboardList = dashSearchData.dashboardSearch;
            const {
                verification_schedule_extended = {}
            } = dashboardList[0] ? dashboardList[0] : {};
            setVerificationData(verification_schedule_extended);
        }
    }, [dashSearchData]);

    useEffect(() => {
        if (vsStatsByDatesData && vsStatsByDatesData.verficationScheduleStatsByDatesAlt) {
            setVerificationData(vsStatsByDatesData.verficationScheduleStatsByDatesAlt)
        }
    }, [vsStatsByDatesData]);

    const handleClearDate = () => {
        setFormInitValues({ from_date: "", to_date: "" });
    }

    if (loadingDashSearch || loadingVsStatsByDates) {
        return (
            <PageSpinner />
        )
    }

    if (errorDashSearch || errorVsStatsByDates) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="mb-3 pb-6 border-b-2 border-gray-200">
                <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Verification Schedule Dashboard Statistics <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>
                {showHelp &&
                    <div>
                        <p className="mt-3">The graph shows the number of scheduled and completed verification activities over time for the Duty Holder and / or Installation selected on the drop-down menu.</p>
                        <p>To filter by specific dates, select from and to dates and click ‘Apply’.</p>
                    </div>
                }
            </div>

            {getBreadCrumbHeader({
                userTypeAndRoleObject,
                drDnDutyholder,
                drDnInstallation,
                drDnSece,
                section: "",
                title: ""
            })
            }
            
            {/*
            <Link
                to={{
                    pathname: '/',
                }}
                className="button-red mr-2 mb-2 py-3 px-5"
            >
                <FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back to Dashboard
            </Link> 
            */}

            <Formik
                initialValues={formInitValues}
                validationSchema={Validation}
                onSubmit={(values, actions) => {
                    setFormInitValues({ from_date: values.from_date, to_date: values.to_date })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit} className="flex flex-col md:flex-row my-3">

                        <div className="mr-0 md:mr-3">
                            <label htmlFor="from_date" className="block text-blue-900 font-semibold">From</label>
                            <div className="mt-3">
                                <DatePicker
                                    id="from_date"
                                    selected={values.from_date}
                                    dateFormat="dd MMMM Y"
                                    name="from_date"
                                    onChange={date => setFieldValue('from_date', date)}
                                    onBlur={handleBlur}
                                    value={values.from_date}
                                    className="form-control block w-full"
                                    showMonthDropdown
                                    showYearDropdown
                                    autoComplete="off"
                                />
                                <FormError touched={touched.from_date} message={errors.from_date} />
                            </div>
                        </div>

                        <div className="mr-0 md:mr-3">
                            <label htmlFor="to_date" className="block text-blue-900 font-semibold">To</label>
                            <div className="mt-3">
                                <DatePicker
                                    id="to_date"
                                    selected={values.to_date}
                                    dateFormat="dd MMMM Y"
                                    name="to_date"
                                    onChange={date => setFieldValue('to_date', date)}
                                    onBlur={handleBlur}
                                    value={values.to_date}
                                    className="form-control block w-full"
                                    showMonthDropdown
                                    showYearDropdown
                                    autoComplete="off"
                                />
                                <FormError touched={touched.to_date} message={errors.to_date} />
                            </div>
                        </div>

                        <div>
                            <p className="hidden md:block">&nbsp;</p>
                            <button type="submit" className="mt-3 button-red capitalize">Apply</button>
                            <button type="button" className="mt-3 ml-2 button-red capitalize" onClick={() => handleClearDate()}>Clear</button>
                        </div>

                    </form>
                )}
            </Formik>

            <div className="p-4 bg-gray-100">
                <h3 className="mb-3 font-semibold text-center text-blue-900 uppercase">Verification Schedule 60 Months</h3>
                <ErrorBoundary>
                    <Line
                        data={VerificationData}
                        options={{
                            scales: {
                                xAxes: [{
                                    stacked: false,
                                    ticks: {
                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                    },
                                }],
                                yAxes: [{
                                    stacked: false,
                                    ticks: {
                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                    },
                                }]
                            },
                            legend: {
                                labels: {
                                    fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                }
                            },
                        }}
                    />
                </ErrorBoundary>
            </div>

        </div>
    )

}

export default VerificationScheduleStatsDashExpAlt;