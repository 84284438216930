/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Table from '../global/Table';
import {gql, useMutation, useLazyQuery} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from '../global/pageSpinner';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject, capitaliseFirstCase} from "../../utils/index";
import Comment from '../global/Comment';
import {NOTIFICATION_STATUS_UNAPPROVED, NOTIFICATION_STATUS_PENDING} from "../../constants";
import {sysObjects} from "../../constants/sysObjectList";

const USER_COMPETENCIES_QUERY = gql`
query UserCompetencies($isArchived:Boolean!){
    userCompetencies(isArchived: $isArchived){
      _id
      user_id{
        _id
        user_fname
        user_lname
      }
      user_comp_evidence
      user_comp_compId{ 
        _id
        comp_code
        comp_title
      }
      user_comp_catId{ 
        _id
        comp_cat_title
      }
      user_comp_expiry
      user_comp_approverId{ 
        _id
        user_fname
        user_lname
      }
      user_comp_level{
        _id
        sysvar_title 
      }
      user_comp_status
      user_comp_date_gained
      user_comp_archived
      user_comp_archived_id
      user_comp_archived_date
    }
}`;

const UPDATE_USER_COMPETENCY = gql`
mutation UpdateUserCompetency($userCompId: String!, $userId: String!, $userComp: UpdateUserCompetencyInput!) {
  updateUserCompetency(_id: $userCompId, userId: $userId, userComp: $userComp) {
       _id
    }
}`;

function UserCompetency(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [userComps, setUserCompetencies] = useState([]);
  const [showArchivedUserCompetencies, setShowArchivedUserCompetencies] = useState(false);

  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const { location: { state = "" }, match: { url = "" } } = props ? props : {};

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
  }, [userTypeAndRoleObject]);

  const [userCompetencies, {loading, error, data:userCompetenciesData, refetch}] = useLazyQuery(USER_COMPETENCIES_QUERY);
  const [updateUserCompetency, {data: updateUserCompData}] = useMutation(UPDATE_USER_COMPETENCY);

  useEffect(() => {
    if (state === "archived"){
       setShowArchivedUserCompetencies(true);
    }
  }, []);

  useEffect(() => {
       userCompetencies({ variables: {isArchived:showArchivedUserCompetencies}, errorPolicy: 'all'})
  }, [showArchivedUserCompetencies]);

  useEffect(() => {
    if (userCompetenciesData && userCompetenciesData.userCompetencies) {
      const { _id: userId = "" } = authContext.user ? authContext.user : {};
      let formatedUserCompetencies = formatUserCompetenciesData(userCompetenciesData.userCompetencies, userId);
      setUserCompetencies(formatedUserCompetencies);
    }
  }, [userCompetenciesData]);

  useEffect(() => {
    if (updateUserCompData && updateUserCompData.updateUserCompetency) {
      refetch()
    }
  }, [updateUserCompData]);

  const archiveManager = (userCompId, isArchived, archivedId, archivedDate, userId) => {
    if (userCompId !== "") {
      const payload = {
        user_comp_archived: isArchived ? false : true,
        user_comp_archived_id: isArchived ? archivedId : userId,
        user_comp_archived_date: isArchived ? archivedDate : new Date()
      }
      updateUserCompetency({ variables: { userCompId: userCompId, userId: userId, userComp: payload } });
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'User/Verifier',
        accessor: 'user_fullname',
      },
      {
        Header: 'Competency',
        accessor: 'competency_title',
      },
      {
        Header: 'Category',
        accessor: 'category_title',
      },
      {
        Header: 'Level',
        accessor: 'competency_level',
      },
      {
        Header: 'Expiry',
        accessor: 'expiry_date', 
      },
      {
        Header: 'Status',
        accessor: 'expiry_status',
      },
      {
        Header: 'Approved By',
        accessor: 'approver_fullname',
      },
      {
        Header: 'Approval Status',
        accessor: 'approval_status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { id, user_comp_status, is_archived, archived_id, archived_date, user_id } }) => (
          <div className="text-right">
            <Link
              to={{
                pathname: `/user-competency/view/${id}`,
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              View
            </Link>
            {
              (user_comp_status === NOTIFICATION_STATUS_PENDING || user_comp_status === NOTIFICATION_STATUS_UNAPPROVED) ? "" :
                <>
                  {is_archived ? "" : <Link
                    to={{
                      pathname: `/user-competency/edit/${id}`,
                      state: { formMode: 'edit' }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    Edit
                  </Link>}
                  <Link
                    to="#"
                    className="button-red mr-1 text-xs"
                    type="button"
                    onClick={() => { archiveManager(id, is_archived, archived_id, archived_date, user_id) }}
                  >
                    {is_archived ? 'Unarchive' : 'Archive'}
                  </Link>
                </>
            }

            <Comment sysObjName={sysObjects.userCompetency}
              sysObjRecordId={id}
              buttonName={"Comments"}
              buttonClassName={"button-red mb-1 mr-1 text-xs"} />
          </div>
        )
      },
      ],
    []
  )

  if (loading) {
    return (
      <PageSpinner/>
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return(
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">User Competencies <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
        <div>
          {showArchivedUserCompetencies === false ?
            <p className="mt-3">A table of the active competencies awarded to ABS users.  Each entry can be viewed, edited and archived.</p>
          :
            <p className="mt-3">A table of the active competencies awarded to ABS users.  Each entry can be viewed, edited and unarchived.</p>
          }
            <p>Click ‘Competencies’ for more information on each Competency.</p>
        </div>
        }
        
      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <h6 className="mb-6 font-bold text-sm text-red-900">Dashboard - User Competencies</h6>

      {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" && userTypeAndRoleObject.userRole !=="Verifier" &&
      <Link 
        to={{
          pathname: '/user-competency/add',
          state: { formMode:'add'}
        }}      
        className="button-red mr-2 mb-2 py-3 px-5"
      >
        Add
      </Link>
      }

      <Link to={{
        pathname: showArchivedUserCompetencies ? url : (url + "/archived"),
        state: showArchivedUserCompetencies ? "" : "archived"
      }}
        className="button-red mr-2 mb-2 py-3 px-5"
        onClick={() => { setShowArchivedUserCompetencies(!showArchivedUserCompetencies) }}
      >
        {showArchivedUserCompetencies ? "Active User Competencies" : "Archived User Competencies"}
      </Link>

      {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" && userTypeAndRoleObject.userRole !=="Verifier" &&
        <Link to="/user-competency/competencies" className="button-red mr-2 mb-2 py-3 px-5">Competencies</Link>
      }
      
      <Link to="/user-competency/histories" className="button-red mr-2 mb-2 py-3 px-5">History</Link>

      <Table 
        columns={columns} 
        data={userComps} 
        getRowProps={row => ({
          style: {
            background: row.original.expiry_status ==='Expired' ? '#ffe7ea' : row.original.expiry_status ==='Expiring Soon' ? '#fff3db' : row.original.expiry_status ==='Valid' ? '#e2ffd9' : null,
          },
        })}
      />

    </div>
  )

}

function formatUserCompetenciesData(userCompetencies, userId) {

  let newUserCompetencies = [];
  if (userCompetencies && userCompetencies.length && userId !== "") {
    userCompetencies.forEach(userComp => {
      const { user_fname: userFname = "", user_lname: userLname = "" } = userComp.user_id ? userComp.user_id : {};
      const { comp_title = "N/A" } = userComp.user_comp_compId ? userComp.user_comp_compId : {};
      const { comp_cat_title = "N/A" } = userComp.user_comp_catId ? userComp.user_comp_catId : {};
      const { sysvar_title : comp_level = "N/A" } = userComp.user_comp_level ? userComp.user_comp_level : {};
      const { user_fname: approverFname = "", user_lname: approverLname = "" } = userComp.user_comp_approverId ? userComp.user_comp_approverId : {};
      let expiryDate = new Date(userComp.user_comp_expiry);

      let formatedStatus = capitaliseFirstCase(userComp.user_comp_status);
      let options = { year: 'numeric', month: 'long', day: 'numeric' };

      let expiryDateString = expiryDate.toLocaleDateString('en-GB', options);
      let expiryStatus = getExpirationStatus(userComp.user_comp_expiry);

      let newUserComp = {
        id: userComp._id,
        user_fullname: userFname !== "" && userLname !== "" ? (userFname + " " + userLname) : "N/A",
        competency_title: comp_title,
        competency_level: comp_level,
        category_title: comp_cat_title,
        expiry_date: expiryDateString,
        expiry_status: expiryStatus,
        approver_fullname: approverFname !== "" && approverLname !== "" ? (approverFname + " " + approverLname) : "N/A",
        approval_status: formatedStatus,
        user_comp_status: userComp.user_comp_status,
        is_archived: userComp.user_comp_archived,
        archived_id: userComp.user_comp_archived_id,
        archived_date: userComp.user_comp_archived_date,
        user_id: userId
      };

      newUserCompetencies.push(newUserComp);
    })
  }
  return newUserCompetencies;
}

function getExpirationStatus(compExpiryDate) {
  let ExpiryStatus = "Not Available";

  if (compExpiryDate && compExpiryDate !== "") {
    let currentDate = new Date();
    let expiryDate = new Date(compExpiryDate);
    let dateDifference = expiryDate - currentDate;
    let remainingDays = dateDifference / (1000 * 3600 * 24);

    if (remainingDays > 90) {
      ExpiryStatus = 'Valid';
    }

    if (remainingDays > 0 && remainingDays < 90) {
      ExpiryStatus = 'Expiring Soon';
    }

    if (remainingDays < 0) {
      ExpiryStatus = 'Expired';
    }
  }

  return ExpiryStatus;
}

export default UserCompetency;