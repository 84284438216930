/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import {AuthContext} from "../../context/authContext";
import {capitaliseFirstCase, createUserTypeAndRoleObject} from "../../utils/index";
import Comment from '../global/Comment';
import {sysObjects} from "../../constants/sysObjectList";
import {
    NOTIFICATION_STATUS_PENDING, 
    NOTIFICATION_STATUS_UNAPPROVED,
    ABS_LOCATION_TYPE_ID,
    DUTYHOLDER_LOCATION_TYPE_ID,
    TEMP_DUTYHOLDER_ID
} from '../../constants/';

const LOCATION_SEARCH_QUERY = gql`
query locationSearch($searchParam: LocationSearchInput!){
    locationSearch(searchParam: $searchParam){
        _id
        location_name
        location_address_city
        location_archived
        location_status
    }
}`;

const ARCHIVE_LOCATION = gql`
mutation ArchiveLocation($locationId:String!) {
    archiveLocation(_id: $locationId) {
       _id
    }
}`;

const UN_ARCHIVE_LOCATION = gql`
mutation UnArchiveLocation($locationId:String!) {
    unArchiveLocation(_id: $locationId) {
       _id
    }
}`;

function Locations() {
    const authContext = useContext(AuthContext);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
    const history = useHistory();

    const [showHelp, setShowHelp] = useState(false);
    const [locationList, setLocationList] = useState([]);
    const [showArchivedLocations, setShowArchivedLocations] = useState(false);

    const [shouldSpin, setShouldSpin] = useState(false);

    let { featureName = "", featureId = "" } = useParams();

    const [locationSearch, {error:locationsError, data :locationsData, refetch}] = useLazyQuery(LOCATION_SEARCH_QUERY);

    const [archiveLocation, {data: archiveLocationData}] = useMutation(ARCHIVE_LOCATION);
    const [unArchiveLocation, {data: unArchiveLocationData}] = useMutation(UN_ARCHIVE_LOCATION);

    useEffect(() => {
        setShouldSpin(true)
    }, []);

    useEffect(() => {
    if (authContext && authContext.user) {
        let result = createUserTypeAndRoleObject(authContext);
        setUserTypeAndRoleObject(result);
    }
    }, [authContext]);

    useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
    }, [userTypeAndRoleObject]);
    
    useEffect(() => {
        let location_type_id = "";
        if (featureName === "abs" && featureId === TEMP_DUTYHOLDER_ID) {
            location_type_id = ABS_LOCATION_TYPE_ID
        }
        if (featureName === "dutyholder") {
            location_type_id = DUTYHOLDER_LOCATION_TYPE_ID
        }
        let payload = {
            dh_id: featureId,
            location_type_id: location_type_id,
            location_archived: showArchivedLocations,
        }
        locationSearch({ variables: { searchParam: payload }, errorPolicy: 'all' })
    }, [showArchivedLocations]);

    useEffect(() => {
        if (locationsData && locationsData.locationSearch) {
            let locations=  formatLocations(locationsData.locationSearch);
            setLocationList(locations);
            setShouldSpin(false)
        }       
    }, [locationsData]);

    useEffect(() => {
        if (archiveLocationData) {
            refetch()
        }
      }, [archiveLocationData]);
    
    useEffect(() => {
        if (unArchiveLocationData) {
            refetch()
        }
    }, [unArchiveLocationData]);

    const archiveManager = (locationId, isArchived) => {
        if (isArchived) {
            unArchiveLocation({variables: {locationId}});
        }else{
            archiveLocation({variables: {locationId}});
        }
      }

    /*
    const getBackLink = () => {
        if (featureName ==="dutyholder") {
            let backLink = "/dutyholders"
            return backLink;
        };

        if (featureName ==="abs") {
            let backLink = "/users"
            return backLink;
        }
    } */
    
    const columns = React.useMemo(
        () => [
            {
                Header: 'Location Name',
                accessor: 'location_name',
            },
            {
                Header: 'City',
                accessor: 'location_address_city',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: ({ value: { _id, location_status, location_archived } }) => (
                    <div className="text-right">
                        <Comment sysObjName={sysObjects.location}
                            sysObjRecordId={_id}
                            buttonName={"Comments"}
                            buttonClassName={"button-red mb-1 mr-1 text-xs"} />

                        <Link
                            to={{
                                pathname: `/${featureName}/${featureId}/location/${_id}/histories`,
                            }}
                            className="button-red mb-1 mr-1 text-xs">
                            History
                        </Link>
                        <Link
                            to={{
                                pathname: `/${featureName}/${featureId}/locations/view/${_id}`,
                            }}
                            className="button-red mb-1 mr-1 text-xs">
                            View
                        </Link>

                        {location_status === NOTIFICATION_STATUS_PENDING || 
                         location_status === NOTIFICATION_STATUS_UNAPPROVED ?
                            <></> :
                            <>
                                <Link
                                    to={{
                                        pathname: `/${featureName}/${featureId}/locations/edit/${_id}`,
                                        state: { formMode: 'edit' }
                                    }}
                                    className="button-red mb-1 mr-1 text-xs"
                                >
                                    Edit
                                </Link>
                                <Link
                                    to="#"
                                    className="button-red text-xs"
                                    onClick={() => { archiveManager(_id, location_archived) }}
                                >
                                    {location_archived ? 'Unarchive' : 'Archive'}
                                </Link>
                            </>
                        }
                    </div>
                )
            },
        ],
        []
    )

    if (shouldSpin) {
        return (
            <PageSpinner />
        )
    }

    if (locationsError) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-6 border-b-2 border-gray-200">

                <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">{featureName === "dutyholder" ? "Duty Holder" : "ABS"} Locations <FontAwesomeIcon
                    icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
                    onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp && featureName !== "dutyholder" &&
                <div>
                    {showArchivedLocations === false ?
                    <div>
                        <p className="mt-3">A table of active ABS office locations.</p>
                        <p>Use the 'Add Location' button to input details of a new location.</p>
                    </div>
                    : 
                    <p className="mt-3">A table of archived ABS office locations.</p>
                    }
                </div>
                }

                {showHelp && featureName === "dutyholder" &&
                <div>
                    {showArchivedLocations === false ?
                    <div>
                        <p className="mt-3">A table of active Duty Holder locations.</p>
                        <p>Use the 'Add Location' button to input details of a new location.</p>
                    </div>
                    : 
                    <p className="mt-3">A table of archived Duty Holder locations.</p>
                    }
                </div>
                }

            </div>

            <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

            <h6 className="mb-6 font-bold text-sm text-red-900">{featureName === "dutyholder" ? "Duty Holder" : "ABS"} - Locations</h6>

            {/*
            <Link
                to={{ pathname: `${getBackLink()}` }}
                className="button-red mr-2 mb-2 py-3 px-5">
                Back
            </Link>
            */}
            <Link
                to={{
                    pathname: `/${featureName}/${featureId}/locations/add`,
                    state: { formMode: 'add' }
                }}
                className="button-red mr-2 mb-2 py-3 px-5"
            >
                Add Location
            </Link>

            <Link to="#"
                className="button-red mb-2 py-3 px-5"
                onClick={() => { setShowArchivedLocations(!showArchivedLocations) }}
                style={{ backgroundColor: showArchivedLocations ? '#a70000' : '' }}
            >
                {showArchivedLocations ? "Active Locations" : "Archived Locations"}
            </Link>

            {locationList.length > 0 &&
                <Link to={`/${featureName}/${featureId}/locations/histories`}
                    className="button-red ml-2 mb-2 py-3 px-5">History</Link>
            }

            {!shouldSpin && locationList.length > 0 ?
                <Table columns={columns} data={locationList} />
                : <h4 className="mt-4">There are currently no Locations</h4>
            }

        </div>
    )
}

function formatLocations(locations) {
    let newLocations = [];
    if (locations) {
        locations.forEach(loc => {
            let formatedStatus = capitaliseFirstCase(loc.location_status);
            let location = {
                ...loc,
                status: formatedStatus,
            };           
            newLocations.push(location);
        })
    }
    return newLocations
}

export default Locations;
