import React,  {createContext, useState} from 'react';

const PageContext = createContext();

function PageContextProvider({children}) {
    const [activityRegisterPage, setActivityRegisterPage] = useState({});
    const [actionListPage, setActionListPage] = useState({});

    const updateActivityRegisterPage = (page) => {
        if (page) {
            setActivityRegisterPage(page);
        }
    }

    const updateActionListPage = (page) => {
        if (page) {
            setActionListPage(page);
        }
    }

    const clearContext = () => {
        setActivityRegisterPage({});
        setActionListPage({});
    }
  
    return (
        <PageContext.Provider value = {{
            activityRegisterPage:activityRegisterPage, 
            actionListPage:actionListPage, 
            updateActivityRegisterPage:updateActivityRegisterPage, 
            updateActionListPage:updateActionListPage, 
            clearContext:clearContext
        }}>
              {children}
        </PageContext.Provider>
    );
}

export {PageContext, PageContextProvider};