/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Pie, Bar, HorizontalBar } from 'react-chartjs-2';
import { Link, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { } from 'lodash';
import * as d3 from "d3";
import * as venn from "venn.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import ErrorBoundary from '../global/ErrorBoundary';
import { AuthContext } from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import { createUserTypeAndRoleObject, formatDate } from "../../utils/index";
import { DH_FINDING_TYPE, INST_FINDING_TYPE } from "../../constants";

export const FindingIssues = (props) => {
    const [fndByIssueList, setFndByIssueList] = useState([]);

    const [setList, setSetList] = useState([]);
    const { data } = props;

    let history = useHistory();

    useEffect(() => {
        setFndByIssueList(data);
    }, [data]);

    useEffect(() => {
        if (fndByIssueList && fndByIssueList.length) {
            let vennList = createVennSets(fndByIssueList);
            setSetList(vennList);
        }
    }, [fndByIssueList]);

    useEffect(() => {
        if (setList && setList.length > 0) {

            let chart = venn.VennDiagram();

            let vennDiv = d3.select("#venn");

            var children = vennDiv.selectChildren()

            children.nodes().forEach(n => {
                d3.select(n).remove()
            })

            var size = children.size();

            if (size === 0) {
                var div = vennDiv.append("div");

                vennDiv.append("div")
                    .classed("svg-container", true)
                    .datum(setList)
                    .call(chart);

                d3.select("#venn svg")
                    .attr("preserveAspectRatio", "xMinYMin meet")
                    .attr("viewBox", "0 0 600 350")
                    .classed("svg-content-responsive", true);

                var colours = ['#002A4E', '#626366', '#5E8AB4', '#FFAD0A', '#DA1F33'];
                var sets = ['A', 'B', 'C', 'D', 'E'];

                d3.selectAll("#venn text")
                    .style("fill", "#fff")
                    .style("font-size", "12px");

                d3.selectAll("#venn .venn-circle path")
                    .style("fill", function (d, i) { return colours[sets.indexOf(d.sets[0])] })
                    .style("fill-opacity", .8);

                var tooltip = div.attr("class", "venntooltip").style("z-index", 1000);

                d3.selectAll("g")
                    .on("mouseover", function (d, i) {
                        // Display a tooltip with the current size
                        if (i.label) {
                            tooltip.transition().duration(400).style("opacity", .9);
                            tooltip.text(i.label);
                        }
                    })
                    .on("mousemove", function (x, y) {
                        tooltip.style("left", (x.pageX) + "px")
                            .style("top", (x.pageY - 28) + "px");
                    })
                    .on("mouseout", function (d, i) {
                        tooltip.transition().duration(400).style("opacity", 0);
                    });

                d3.selectAll("#venn .venn-circle path")
                    .on("click", function (d, i) {
                        let searchObject = {
                            issueCode: "",
                            issueName: i.label,
                            displayName: `Findings By Issue: ${i.label}`
                        }
                        let search = new URLSearchParams(searchObject).toString()
                        let location = {
                            pathname: i.link,
                            search: search
                        }
                        history.push(location);
                    });
            }
        }
    }, [setList, history]);

    return (
        <div id="venn"></div>
    );
};

const STATISTICS_SEARCH_QUERY = gql` 
query StatisticSearch($param: StatisticSearchInput!){ 
   statisticSearch(param: $param){ 
    _id

    dh_id
    installation_id
    user_id
    user_type

    finding_stats{
        date {
            labels,
            datasets {
                label
                data
                backgroundColor
                linkHref
            }
        }
        level {
            labels,
            datasets {
                label
                data
                backgroundColor
                linkHref
            }
        }
    }
    finding_stats_group_by_barrier{
        date {
            labels
            values
            datasets {
                label
                data
                backgroundColor
                linkHref
            }
        }
        level {
            labels
            values
            datasets {
                label
                data
                backgroundColor
                linkHref
            }
        }
    }
    finding_stats_group_by_dh_inst{
        date {
            labels
            values
            datasets {
                label
                data
                backgroundColor
                linkHref
            }
        }
        level {
            labels
            values
            datasets {
                label
                data
                backgroundColor
                linkHref
            }
        }
    }
    finding_stats_group_by_action_party{
        date {
            labels
            values
            datasets {
                label
                data
                backgroundColor
                linkHref
            }
        }
        level {
            labels
            values
            datasets {
                label
                data
                backgroundColor
                linkHref
            }
        }
    }
    finding_stats_group_by_exam_date{
        date {
            labels
            datasets {
                label
                data
                backgroundColor
                linkHref
            }
        }
        level {
            labels
            datasets {
                label
                data
                backgroundColor
                linkHref
            }
        }
    }
    finding_stats_group_by_nature{
        date {
            labels
            datasets {
                label
                data
                backgroundColor
                linkHref
            }
        }
        level {
            labels
            datasets {
                label           
                data
                backgroundColor
                linkHref
            }
        }
    }
    finding_stats_group_by_sece{
        date {
            labels
            values
            datasets {
                label
                data
                backgroundColor
                linkHref
            }
        }
        level {
            labels
            values
            datasets {
                label
                data
                backgroundColor
                linkHref
            }
        }
    }
    finding_stats_group_by_issues{
        label
        count
        backgroundColor
        linkHref
    }

    statistic_type
    created_date   
  } 
}`;

const StatisticsAlt = (props) => {

    const [showHelp, setShowHelp] = useState(false);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [drDnDutyholder, setDrDnDutyholder] = useState({});
    const [drDnInstallation, setDrDnInstallation] = useState({});
    const [drDnSece, setDrDnSece] = useState({});

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);

    let history = useHistory();

    const defaultState = {
        date: { datasets: [] },
        level: { datasets: [] }
    }

    const [openFindingsStats, setOpenFindingsStats] = useState(defaultState);
    const [barrierFindingsStats, setBarrierFindingsStats] = useState(defaultState);
    const [dhInstFindingsStats, setDhInstFindingsStats] = useState(defaultState);
    const [actionPartyFindingsStats, setActionPartyFindingsStats] = useState(defaultState);
    const [examDateFindingsStats, setExamDateFindingsStats] = useState(defaultState);
    const [natureFindingsStats, setNatureFindingsStats] = useState(defaultState);
    const [seceFindingsStats, setSeceFindingsStats] = useState(defaultState);
    const [issuesFindingsStats, setIssuesFindingsStats] = useState([]);
    const [issuesFindingsStatsBarChart, setIssuesFindingsStatsBarChart] = useState({});

    const [toggleOpenFindingsStats, setToggleOpenFindingsStats] = useState(false);
    const [toggleBarrierFindingsStats, setToggleBarrierFindingsStats] = useState(false);
    const [toggleActionPartyFindingsStats, setToggleActionPartyFindingsStats] = useState(false);
    const [toggleExamDateFindingsStats, setToggleExamDateFindingsStats] = useState(false);
    const [toggleNatureFindingsStats, setToggleNatureFindingsStats] = useState(false);
    const [toggleIssueFindingsStats, setToggleIssueFindingsStats] = useState(false);
    const [toggleSeceFindingsStats, setToggleSeceFindingsStats] = useState(false);
    const [toggleDhInstFindings, setToggleDhInstFindings] = useState(false);

    const [statisticSearch, { loading: statisticLoading, error: statisticError, data: statisticData }] = useLazyQuery(STATISTICS_SEARCH_QUERY);

    useEffect(() => {
        const { dutyHolder, installation, sece } = navContext;
        setDrDnDutyholder(dutyHolder);
        setDrDnInstallation(installation);
        setDrDnSece(sece);
    }, [navContext]);

    useEffect(() => {
        if (authContext && authContext.user) {
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);

            const { _id: userId = "" } = authContext.user;
            if (userId !== "") {

                let payload = {
                    user_id: userId,
                    statistic_type: drDnInstallation.id && drDnInstallation.id !== "" ? "filter" : "default",
                    ...(drDnDutyholder.id && { dh_id: drDnDutyholder.id }),
                    ...(drDnInstallation.id && { installation_id: drDnInstallation.id })
                };
                statisticSearch({ variables: { param: { ...payload } }, errorPolicy: 'all' });
            }
        }

    }, [authContext, drDnDutyholder, drDnInstallation, drDnSece]);

    useEffect(() => {
        if (statisticData && statisticData.statisticSearch) {
            let statisticList = statisticData.statisticSearch;

            const {
                finding_stats = defaultState,
                finding_stats_group_by_barrier = defaultState,
                finding_stats_group_by_dh_inst = defaultState,
                finding_stats_group_by_action_party = defaultState,
                finding_stats_group_by_exam_date = defaultState,
                finding_stats_group_by_nature = defaultState,

                finding_stats_group_by_sece = defaultState,
                finding_stats_group_by_issues: findingsByIssueData = [],

            } = statisticList && statisticList[0] ? statisticList[0] : {};

            setOpenFindingsStats({
                date: finding_stats.date,
                level: finding_stats.level,
            });

            setBarrierFindingsStats({
                date: finding_stats_group_by_barrier.date,
                level: finding_stats_group_by_barrier.level,
            });

            setDhInstFindingsStats({
                date: finding_stats_group_by_dh_inst.date,
                level: finding_stats_group_by_dh_inst.level,
            });
            setActionPartyFindingsStats({
                date: finding_stats_group_by_action_party.date,
                level: finding_stats_group_by_action_party.level,
            });

            setExamDateFindingsStats({
                date: finding_stats_group_by_exam_date.date,
                level: finding_stats_group_by_exam_date.level,
            });

            setNatureFindingsStats({
                date: finding_stats_group_by_nature.date,
                level: finding_stats_group_by_nature.level,
            });

            setSeceFindingsStats({
                date: finding_stats_group_by_sece.date,
                level: finding_stats_group_by_sece.level,
            });

            let filteredList = filterFindingsByIssue(findingsByIssueData);
            setIssuesFindingsStats(filteredList)
            let findingsByIssueBarChart = createDataForFindingsByIssueBarChart(findingsByIssueData);
            setIssuesFindingsStatsBarChart(findingsByIssueBarChart);
        }
    }, [statisticData]);

    return (
        <div className="w-full px-8 pb-8 wide-table">
            <div className="pb-6 border-b-2 border-gray-200">
                <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Statistics <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>
                {showHelp &&
                    <div>
                        <p className="mt-3 mb-3">Charts allowing assessment, against a range of parameters, of open Findings relating to the Duty Holder and / or Installation selected in the drop-down menu.  Use the toggle switch to between Finding Status and Level.</p>

                        <p>The Findings by issue chart can be presented as a Venn diagram (showing quantities of legislative, integrity and paperwork / process issues) or a bar chart, breaking down types of cumulative issues.</p>
                    </div>
                }
            </div>

            <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

            {(userTypeAndRoleObject &&
                userTypeAndRoleObject.userType === "ABS" &&
                drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Statistics- Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
            {(userTypeAndRoleObject &&
                userTypeAndRoleObject.userType === "Duty Holder" &&
                drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Statistics- Installation: {drDnInstallation.name}</h6>}
            {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Statistics- Duty Holder / Installation : All</h6>}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                <div className="bg-gray-100">

                    <div className="flex flex-col h-full">

                        <div className="p-4">

                            <div className="flex flex-row justify-between">
                                <h3 className="mb-3 font-semibold text-blue-900 uppercase">All Open Findings</h3>

                                <div>
                                    <div className="text-xs text-gray-900 inline">Status</div>
                                    <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox" className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer" id="AllOpenFindings" name="AllOpenFindings" onChange={() => setToggleOpenFindingsStats(!toggleOpenFindingsStats)} checked={toggleOpenFindingsStats} />
                                        <label htmlFor="AllOpenFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                                    </div>
                                    <div className="text-xs text-gray-900 inline">Level</div>
                                </div>
                            </div>

                            {statisticLoading && (
                                <PageSpinner />
                            )}

                            {statisticError && (
                                <span>Something went wrong retrieving the data</span>
                            )}

                            {!statisticLoading && !statisticError && toggleOpenFindingsStats === false && openFindingsStats.date.datasets &&
                                <ErrorBoundary>
                                    {openFindingsStats.date.datasets.length ?
                                        <Pie
                                            data={openFindingsStats.date}
                                            options={{
                                                title: { display: false, text: 'All Open Findings', fontColor: '#002a4e' },
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let statusKey = element[0]._chart.data.labels[element[0]._index];
                                                        let location = {
                                                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                            search: `completionStatus=${statusKey}&displayName=Open Findings By Status: ${statusKey}&findingStatus=open`
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        /> :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            }
                            {!statisticLoading && !statisticError && toggleOpenFindingsStats === true && openFindingsStats.level.datasets &&
                                <ErrorBoundary>
                                    {openFindingsStats.level.datasets.length ? <Pie
                                        data={openFindingsStats.level}
                                        options={{
                                            title: { display: false, text: 'All Open Findings', fontColor: '#002a4e' },
                                            legend: {
                                                align: 'start',
                                                labels: {
                                                    fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                }
                                            },
                                            onClick: function (evt, element) {
                                                if (element.length > 0) {
                                                    let fndKey = element[0]._chart.data.labels[element[0]._index];
                                                    let location = {
                                                        pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                        search: `level=${fndKey}&displayName=Open Findings By Level: ${fndKey}&findingStatus=open`
                                                    }
                                                    history.push(location);
                                                }
                                            }
                                        }}
                                    /> :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            }
                        </div>

                    </div>

                </div>

                <div className="bg-gray-100">

                    <div className="flex flex-col h-full">

                        <div className="p-4">

                            <div className="flex flex-row justify-between">
                                <h3 className="font-semibold text-blue-900 uppercase">Open Findings by Barrier</h3>

                                <div>
                                    <div className="text-xs text-gray-900 inline">Status</div>
                                    <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox" className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer" id="BarrierFindings" name="BarrierFindings" onChange={() => setToggleBarrierFindingsStats(!toggleBarrierFindingsStats)} checked={toggleBarrierFindingsStats} />
                                        <label htmlFor="BarrierFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                                    </div>
                                    <div className="text-xs text-gray-900 inline">Level</div>
                                </div>
                            </div>

                            {statisticLoading && (
                                <PageSpinner />
                            )}
                            {statisticError && (
                                <span>Something went wrong retrieving the data</span>
                            )}

                            {!statisticLoading && !statisticError && toggleBarrierFindingsStats === false && barrierFindingsStats.date.datasets &&
                                <ErrorBoundary>
                                    {barrierFindingsStats.date.datasets.length ?
                                        <Bar
                                            data={barrierFindingsStats.date}
                                            options={{
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }]
                                                },
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let bar = this.getElementAtEvent(evt)[0];
                                                        let index = bar._index;
                                                        let datasetIndex = bar._datasetIndex;

                                                        const { date: { values = [] } = {} } = barrierFindingsStats ? barrierFindingsStats : {};
                                                        let selectedBarId = values[index];

                                                        let statusKey = element[datasetIndex]._model.datasetLabel;
                                                        let barrierName = element[datasetIndex]._model.label;

                                                        let searchObject = {
                                                            //responseStatus: statusKey,
                                                            completionStatus: statusKey,
                                                            barrierTitle: barrierName,
                                                            barrierId: selectedBarId ? selectedBarId : "",
                                                            findingStatus: "open",
                                                            displayName: `Findings By Barrier & Status: ${barrierName} & ${statusKey}`
                                                        }
                                                        let search = new URLSearchParams(searchObject).toString()
                                                        let location = {
                                                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                            search: search
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        /> :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            }

                            {!statisticLoading && !statisticError && toggleBarrierFindingsStats === true && barrierFindingsStats.level.datasets &&
                                <ErrorBoundary>
                                    {barrierFindingsStats.level.datasets.length ?
                                        <Bar
                                            data={barrierFindingsStats.level}
                                            options={{
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }]
                                                },
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let bar = this.getElementAtEvent(evt)[0];
                                                        let index = bar._index;
                                                        let datasetIndex = bar._datasetIndex;

                                                        const { level: { values = [] } = {} } = barrierFindingsStats ? barrierFindingsStats : {};
                                                        let selectedBarId = values[index];

                                                        let levelKey = element[datasetIndex]._model.datasetLabel;
                                                        let barrierName = element[datasetIndex]._model.label;

                                                        let searchObject = {
                                                            level: levelKey,
                                                            barrierTitle: barrierName,
                                                            barrierId: selectedBarId ? selectedBarId : "",
                                                            findingStatus: "open",
                                                            displayName: `Findings By Barrier & Level: ${barrierName} & ${levelKey}`
                                                        };
                                                        let search = new URLSearchParams(searchObject).toString()
                                                        let location = {
                                                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                            search: search
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        /> :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            }
                        </div>

                    </div>

                </div>

                <div className="bg-gray-100">

                    <div className="flex flex-col h-full">

                        <div className="p-4">

                            <div className="flex flex-row justify-between">
                                <h3 className="font-semibold text-blue-900 uppercase">Findings By Action Party</h3>

                                <div>
                                    <div className="text-xs text-gray-900 inline">Status</div>
                                    <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox" className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer" id="ActionPartyFindings" name="ActionPartyFindings" onChange={() => setToggleActionPartyFindingsStats(!toggleActionPartyFindingsStats)} checked={toggleActionPartyFindingsStats} />
                                        <label htmlFor="ActionPartyFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                                    </div>
                                    <div className="text-xs text-gray-900 inline">Level</div>
                                </div>
                            </div>

                            {statisticLoading && (
                                <PageSpinner />
                            )}
                            {statisticError && (
                                <span>Something went wrong retrieving the data</span>
                            )}

                            {!statisticLoading && !statisticError && toggleActionPartyFindingsStats === false && actionPartyFindingsStats.date.datasets &&
                                <ErrorBoundary>
                                    {actionPartyFindingsStats.date.datasets.length ?
                                        <Bar
                                            data={actionPartyFindingsStats.date}
                                            options={{
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }]
                                                },
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let bar = this.getElementAtEvent(evt)[0];
                                                        let index = bar._index;
                                                        let datasetIndex = bar._datasetIndex;

                                                        const { date: { values = [] } = {} } = actionPartyFindingsStats ? actionPartyFindingsStats : {};
                                                        let selectedApId = values[index];

                                                        let statusKey = element[datasetIndex]._model.datasetLabel;
                                                        let actionPartyName = element[datasetIndex]._model.label;

                                                        let searchObject = {
                                                            //responseStatus: statusKey,
                                                            completionStatus: statusKey,
                                                            actionPartyId: selectedApId,
                                                            findingStatus: "open",
                                                            displayName: `Findings By Status & Action Party: ${statusKey} & ${actionPartyName}`
                                                        }
                                                        let search = new URLSearchParams(searchObject).toString()
                                                        let location = {
                                                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                            search: search
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        /> :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            }

                            {!statisticLoading && !statisticError && toggleActionPartyFindingsStats === true && actionPartyFindingsStats.level.datasets &&
                                <ErrorBoundary>
                                    {actionPartyFindingsStats.level.datasets.length ?
                                        <Bar
                                            data={actionPartyFindingsStats.level}
                                            options={{
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }]
                                                },
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let bar = this.getElementAtEvent(evt)[0];
                                                        let index = bar._index;
                                                        let datasetIndex = bar._datasetIndex;

                                                        const { level: { values = [] } = {} } = actionPartyFindingsStats ? actionPartyFindingsStats : {};
                                                        let selectedApId = values[index];

                                                        let levelKey = element[datasetIndex]._model.datasetLabel;
                                                        let actionPartyName = element[datasetIndex]._model.label;

                                                        let searchObject = {
                                                            level: levelKey,
                                                            actionPartyId: selectedApId ? selectedApId : "",
                                                            findingStatus: "open",
                                                            displayName: `Findings By Level & Action Party: ${levelKey} & ${actionPartyName}`
                                                        }
                                                        let search = new URLSearchParams(searchObject).toString()
                                                        let location = {
                                                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                            search: search
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        /> :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            }
                        </div>
                    </div>
                </div>

                <div className="bg-gray-100">

                    <div className="flex flex-col h-full">

                        <div className="p-4">

                            <div className="flex flex-row justify-between">
                                <h3 className="font-semibold text-blue-900 uppercase">Findings By Exam Date</h3>

                                <div>
                                    <div className="text-xs text-gray-900 inline">Status</div>
                                    <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox"
                                            className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer"
                                            id="ExamDateFindings"
                                            name="ExamDateFindings"
                                            onChange={() => setToggleExamDateFindingsStats(!toggleExamDateFindingsStats)}
                                            checked={toggleExamDateFindingsStats} />
                                        <label htmlFor="ExamDateFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                                    </div>
                                    <div className="text-xs text-gray-900 inline">Level</div>
                                </div>
                            </div>

                            {statisticLoading && (
                                <PageSpinner />
                            )}

                            {statisticError && (
                                <span>Something went wrong retrieving the data</span>
                            )}

                            {!statisticLoading && !statisticError && toggleExamDateFindingsStats === false && examDateFindingsStats.date.datasets &&
                                <ErrorBoundary>
                                    {examDateFindingsStats.date.datasets.length ?
                                        <Bar
                                            data={examDateFindingsStats.date}
                                            options={{
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }]
                                                },
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let bar = this.getElementAtEvent(evt)[0];
                                                        //let index = bar._index;
                                                        let datasetIndex = bar._datasetIndex;

                                                        let statusKey = element[datasetIndex]._model.datasetLabel;
                                                        let dateKey = element[datasetIndex]._model.label;

                                                        let searchObject = {
                                                            //responseStatus: statusKey,
                                                            completionStatus: statusKey,
                                                            examDate: dateKey,
                                                            findingStatus: "open",
                                                            displayName: `Findings By Status & Exam Date: ${statusKey} & ${formatDate(dateKey)}`
                                                        }
                                                        let search = new URLSearchParams(searchObject).toString()
                                                        let location = {
                                                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                            search: search
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        /> :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            }

                            {!statisticLoading && !statisticError && toggleExamDateFindingsStats === true && examDateFindingsStats.level.datasets &&
                                <ErrorBoundary>
                                    {examDateFindingsStats.level.datasets.length ?
                                        <Bar
                                            data={examDateFindingsStats.level}
                                            options={{
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }]
                                                },
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let bar = this.getElementAtEvent(evt)[0];
                                                        //let index = bar._index;
                                                        let datasetIndex = bar._datasetIndex;

                                                        let levelKey = element[datasetIndex]._model.datasetLabel;
                                                        let dateKey = element[datasetIndex]._model.label;

                                                        let searchObject = {
                                                            level: levelKey,
                                                            examDate: dateKey,
                                                            findingStatus: "open",
                                                            displayName: `Findings By Level & Exam Date: ${levelKey} & ${dateKey}`
                                                        }
                                                        let search = new URLSearchParams(searchObject).toString()
                                                        let location = {
                                                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                            search: search
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        />
                                        :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            }
                        </div>

                    </div>

                </div>

                <div className="bg-gray-100">

                    <div className="flex flex-col h-full">

                        <div className="p-4">

                            <div className="flex flex-row justify-between">
                                <h3 className="font-semibold text-blue-900 uppercase">Findings By Nature</h3>

                                <div>
                                    <div className="text-xs text-gray-900 inline">Status</div>
                                    <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox" className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer" id="NatureFindings" name="NatureFindings"
                                            onChange={() => setToggleNatureFindingsStats(!toggleNatureFindingsStats)}
                                            checked={toggleNatureFindingsStats} />
                                        <label htmlFor="NatureFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                                    </div>
                                    <div className="text-xs text-gray-900 inline">Level</div>
                                </div>

                            </div>

                            {statisticLoading && (
                                <PageSpinner />
                            )}

                            {statisticError && (
                                <span>Something went wrong retrieving the data</span>
                            )}

                            {!statisticLoading && !statisticError && toggleNatureFindingsStats === false && natureFindingsStats.date.datasets &&
                                <ErrorBoundary>
                                    {natureFindingsStats.date.datasets.length ?
                                        <Bar
                                            data={natureFindingsStats.date}
                                            options={{
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }]
                                                },
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let bar = this.getElementAtEvent(evt)[0];
                                                        //let index = bar._index;
                                                        let datasetIndex = bar._datasetIndex;

                                                        let statusKey = element[datasetIndex]._model.datasetLabel;
                                                        let natureKey = element[datasetIndex]._model.label;

                                                        let searchObject = {
                                                            //responseStatus: statusKey,
                                                            completionStatus: statusKey,
                                                            nature: natureKey,
                                                            findingStatus: "open",
                                                            origin: "Statistic",
                                                            section: "Findings By Nature",
                                                            displayName: `Findings By Status & Nature: ${statusKey} & ${natureKey}`,
                                                        }
                                                        let search = new URLSearchParams(searchObject).toString()
                                                        let location = {
                                                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                            search: search
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        /> :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            }

                            {!statisticLoading && !statisticError && toggleNatureFindingsStats === true && natureFindingsStats.level.datasets &&
                                <ErrorBoundary>
                                    {natureFindingsStats.level.datasets.length ?
                                        <Bar
                                            data={natureFindingsStats.level}
                                            options={{
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }]
                                                },
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let bar = this.getElementAtEvent(evt)[0];
                                                        //let index = bar._index;
                                                        let datasetIndex = bar._datasetIndex;

                                                        let levelKey = element[datasetIndex]._model.datasetLabel;
                                                        let natureKey = element[datasetIndex]._model.label;
                                                        let searchObject = {
                                                            level: levelKey,
                                                            nature: natureKey,
                                                            findingStatus: "open",
                                                            origin: "Statistic",
                                                            section: "Findings By Nature",
                                                            displayName: `Findings By Level & Nature: ${levelKey} & ${natureKey}`,
                                                        }
                                                        let search = new URLSearchParams(searchObject).toString()
                                                        let location = {
                                                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                            search: search
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        /> :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            }
                        </div>

                    </div>

                </div>

                <div className="bg-gray-100">

                    <div className="flex flex-col h-full">

                        <div className="p-4">

                            <div className="flex flex-row justify-between">
                                <h3 className="font-semibold text-blue-900 uppercase pb-4">Findings By Issue</h3>

                                <div>
                                    <div className="text-xs text-gray-900 inline">Venn Diagram</div>
                                    <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox"
                                            className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer"
                                            id="IssueFindings"
                                            key={"IssueFindings"}
                                            name="IssueFindings"
                                            onChange={() => setToggleIssueFindingsStats(!toggleIssueFindingsStats)}
                                            checked={toggleIssueFindingsStats} />
                                        <label htmlFor="IssueFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                                    </div>
                                    <div className="text-xs text-gray-900 inline">Bar Chart</div>
                                </div>
                            </div>

                            {statisticLoading && (
                                <PageSpinner />
                            )}

                            {statisticError && (
                                <span>Something went wrong retrieving the data</span>
                            )}

                            {!statisticLoading && !statisticError && toggleIssueFindingsStats === false &&
                                (
                                    <ErrorBoundary>
                                        {issuesFindingsStats.length ?
                                            <>
                                                <div className="flex">
                                                    <div className="mt-2 w-full flex flex-row justify-between">
                                                        {issuesFindingsStats.map((issue, index) => (
                                                            <Link
                                                                to={{
                                                                    pathname: `/reporting/findings`,
                                                                    search: ` issueCode=${index}&issueName=${issue.label}&displayName=Findings By Issue: ${issue.label}`,
                                                                }}
                                                                key={index}
                                                            >
                                                                <div key={issue.label} className={`p-2 text-center ${issue.label === "Paperwork / Process Issue" || issue.label === "Document Update Required" ? "text-black" : "text-white"}`} style={{ backgroundColor: `${issue.backgroundColor}` }}>
                                                                    <span className="block text-sm">{issue.count}</span>
                                                                    <span style={{ fontSize: '0.6rem' }}>{issue.label}</span>
                                                                </div>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="w-full xl:w-4/5 mx-auto">
                                                    <FindingIssues data={issuesFindingsStats} key={10} />
                                                </div>
                                            </>
                                            : <>"No Data to Display"</>
                                        }
                                    </ErrorBoundary>
                                )
                            }

                            {!statisticLoading && !statisticError && toggleIssueFindingsStats === true && (
                                <ErrorBoundary>
                                    {issuesFindingsStatsBarChart.datasets.length ?
                                        <Bar
                                            data={issuesFindingsStatsBarChart}
                                            options={{
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }]
                                                },
                                                legend: {
                                                    display: false,
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let issueCode = element[0]._index;
                                                        let issueName = element[0]._model.label;
                                                        let searchObject = {
                                                            issueCode: issueCode,
                                                            issueName: issueName,
                                                            displayName: `Findings By Issue: ${issueName}`
                                                        };
                                                        let search = new URLSearchParams(searchObject).toString()
                                                        let location = {
                                                            pathname: "/reporting/findings",
                                                            search: search
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        /> :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            )}
                        </div>

                    </div>

                </div>

                <div className="my-4 bg-gray-100">

                    <div className="flex flex-col h-full">

                        <div className="p-4">

                            <div className="flex flex-row justify-between">
                                <h3 className="font-semibold text-blue-900 uppercase">Findings By SECE</h3>

                                <div>
                                    <div className="text-xs text-gray-900 inline">Status</div>
                                    <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox" className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer" id="SeceFindings" name="SeceFindings" onChange={() => setToggleSeceFindingsStats(!toggleSeceFindingsStats)} checked={toggleSeceFindingsStats} />
                                        <label htmlFor="SeceFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                                    </div>
                                    <div className="text-xs text-gray-900 inline">Level</div>
                                </div>
                            </div>

                            {statisticLoading && (
                                <PageSpinner />
                            )}

                            {statisticError && (
                                <span>Something went wrong retrieving the data</span>
                            )}

                            {!statisticLoading && !statisticError && toggleSeceFindingsStats === false && seceFindingsStats.date.datasets &&
                                <ErrorBoundary>
                                    {seceFindingsStats.date.datasets.length ?
                                        <HorizontalBar
                                            data={seceFindingsStats.date}
                                            options={{
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }]
                                                },
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let bar = this.getElementAtEvent(evt)[0];
                                                        let index = bar._index;
                                                        let datasetIndex = bar._datasetIndex;

                                                        const { date: { labels = [] } = {} } = seceFindingsStats ? seceFindingsStats : {};
                                                        let selectedSeceTitle = labels[index];

                                                        let statusKey = element[datasetIndex]._model.datasetLabel;
                                                        let seceName = element[datasetIndex]._model.label;

                                                        let searchObject = {
                                                            //responseStatus: statusKey,
                                                            completionStatus: statusKey,
                                                            seceTitle: selectedSeceTitle,
                                                            findingStatus: "open",
                                                            displayName: `Findings By Status & SECE: ${statusKey} & ${seceName}`
                                                        }
                                                        let search = new URLSearchParams(searchObject).toString()
                                                        let location = {
                                                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                            search: search
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        /> :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            }

                            {!statisticLoading && !statisticError && toggleSeceFindingsStats === true && seceFindingsStats.level.datasets &&
                                <ErrorBoundary>
                                    {seceFindingsStats.level.datasets.length ?
                                        <HorizontalBar
                                            data={seceFindingsStats.level}
                                            options={{
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }]
                                                },
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let bar = this.getElementAtEvent(evt)[0];
                                                        let index = bar._index;
                                                        let datasetIndex = bar._datasetIndex;

                                                        const { level: { labels = [] } = {} } = seceFindingsStats ? seceFindingsStats : {};
                                                        let selectedSeceTitle = labels[index];

                                                        let levelKey = element[datasetIndex]._model.datasetLabel;
                                                        let seceName = element[datasetIndex]._model.label;

                                                        let searchObject = {
                                                            level: levelKey,
                                                            seceTitle: selectedSeceTitle,
                                                            findingStatus: "open",
                                                            displayName: `Findings By Level & SECE: ${levelKey} & ${seceName}`
                                                        }

                                                        let search = new URLSearchParams(searchObject).toString()
                                                        let location = {
                                                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                            search: search
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        /> :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            }
                        </div>
                    </div>

                </div>

                <div className="my-4 bg-gray-100">

                    <div className="flex flex-col h-full">

                        <div className="p-4">

                            <div className="flex flex-row justify-between">
                                <h3 className="font-semibold text-blue-900 uppercase">Dutyholder &amp; Installation Findings</h3>

                                <div>
                                    <div className="text-xs text-gray-900 inline">Status</div>
                                    <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox" className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer" id="DhInstFindings" name="DhInstFindings" onChange={() => setToggleDhInstFindings(!toggleDhInstFindings)} checked={toggleDhInstFindings} />
                                        <label htmlFor="DhInstFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                                    </div>
                                    <div className="text-xs text-gray-900 inline">Level</div>
                                </div>
                            </div>

                            {statisticLoading && (
                                <PageSpinner />
                            )}

                            {statisticError && (
                                <span>Something went wrong retrieving the data</span>
                            )}

                            {!statisticLoading && !statisticError && toggleDhInstFindings === false && dhInstFindingsStats.date.datasets &&
                                <ErrorBoundary>
                                    {dhInstFindingsStats.date.datasets.length ?
                                        <Bar
                                            data={dhInstFindingsStats.date}
                                            options={{
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }]
                                                },
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let finding = this.getElementAtEvent(evt)[0];
                                                        //let index = finding._index;
                                                        let datasetIndex = finding._datasetIndex;

                                                        let statusKey = element[datasetIndex]._model.datasetLabel;
                                                        let findingTypeKey = element[datasetIndex]._model.label;

                                                        let searchObject = {
                                                            //responseStatus: statusKey,
                                                            completionStatus: statusKey,
                                                            findingType: mapToFindingsTypeKey(findingTypeKey),
                                                            findingStatus: "open",
                                                            displayName: `${findingTypeKey} By Status: ${statusKey}`,
                                                        }
                                                        let search = new URLSearchParams(searchObject).toString()
                                                        let location = {
                                                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                            search: search
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        /> :
                                        <>"No Data to Display"</>
                                    }
                                </ErrorBoundary>
                            }

                            {!statisticLoading && !statisticError && toggleDhInstFindings === true && dhInstFindingsStats.level.datasets &&
                                <ErrorBoundary>
                                    {dhInstFindingsStats.level.datasets.length ?
                                        <Bar
                                            data={dhInstFindingsStats.level}
                                            options={{
                                                scales: {
                                                    xAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }],
                                                    yAxes: [{
                                                        stacked: true,
                                                        ticks: {
                                                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                        },
                                                    }]
                                                },
                                                legend: {
                                                    align: 'start',
                                                    labels: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    }
                                                },
                                                onClick: function (evt, element) {
                                                    if (element.length > 0) {
                                                        let finding = this.getElementAtEvent(evt)[0];
                                                        //let index = bar._index;
                                                        let datasetIndex = finding._datasetIndex;

                                                        let statusKey = element[datasetIndex]._model.datasetLabel;
                                                        let findingTypeKey = element[datasetIndex]._model.label;

                                                        let searchObject = {
                                                            level: statusKey,
                                                            findingType: mapToFindingsTypeKey(findingTypeKey),
                                                            findingStatus: "open",
                                                            displayName: `${findingTypeKey} By Level: ${statusKey}`,
                                                        }

                                                        let search = new URLSearchParams(searchObject).toString()
                                                        let location = {
                                                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                                                            search: search
                                                        }
                                                        history.push(location);
                                                    }
                                                }
                                            }}
                                        /> :
                                        <>"No Data to Display"</>}
                                </ErrorBoundary>
                            }

                        </div>

                    </div>

                </div>

            </div>

        </div>
    )

}

export const filterFindingsByIssue = (findingsByIssueData) => {
    let newFindingsByIssues = [];

    if (findingsByIssueData && findingsByIssueData.length) {

        let exclusionList = [
            'Integrity Issue & Legislative Issue',
            'Integrity Issue & Paperwork / Process Issue',
            'Legislative Issue & Paperwork / Process Issue',
            'Integrity Issue & Legislative Issue & Paperwork / Process Issue'
        ]

        for (let index = 0; index < findingsByIssueData.length; index++) {
            const element = findingsByIssueData[index];

            let exist = exclusionList.includes(element.label);
            if (!exist) {
                newFindingsByIssues.push(element);
            }
        }
    }

    return newFindingsByIssues;
}

export const createDataForFindingsByIssueBarChart = (findingsByIssueData) => {
    let defaultData = {
        labels: [
            'Integrity Issue',
            'Legislative Issue',
            'Paperwork / Process Issue',

            'Document Update Required',
            'ROGI Findings',

            'Integrity Issue & Legislative Issue',
            'Integrity Issue & Paperwork / Process Issue',
            'Legislative Issue & Paperwork / Process Issue',
            'Integrity Issue & Legislative Issue & Paperwork / Process Issue',
        ],
        datasets: [{
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: [
                '#002A4E',
                '#626366',
                '#5E8AB4',

                '#FFAD0A',
                '#DA1F33',

                '#DBDCDD',
                '#B1B3B5',
                '#898B8D',
                '#333333'
            ]
        }]
    }

    if (findingsByIssueData && findingsByIssueData.length) {
        let integrityIssue = findingsByIssueData.find(fnd => fnd.label === 'Integrity Issue');
        let legislativeIssue = findingsByIssueData.find(fnd => fnd.label === 'Legislative Issue');
        let paperworkProcessIssue = findingsByIssueData.find(fnd => fnd.label === 'Paperwork / Process Issue');
        let documentUpdateRequired = findingsByIssueData.find(fnd => fnd.label === 'Document Update Required');
        let rOGIFindings = findingsByIssueData.find(fnd => fnd.label === 'ROGI Findings');

        let intIssueAndLegIssue = findingsByIssueData.find(fnd => fnd.label === 'Integrity Issue & Legislative Issue');
        let intIssueAndPwIssue = findingsByIssueData.find(fnd => fnd.label === 'Integrity Issue & Paperwork / Process Issue');
        let legIssueAndPwIssue = findingsByIssueData.find(fnd => fnd.label === 'Legislative Issue & Paperwork / Process Issue');
        let intIssueAndlegIssueAndPwIssue = findingsByIssueData.find(fnd => fnd.label === 'Integrity Issue & Legislative Issue & Paperwork / Process Issue');

        let integrityIssueCount = integrityIssue ? integrityIssue.count : 0;
        let legislativeIssueCount = legislativeIssue ? legislativeIssue.count : 0;
        let paperworkProcessIssueCount = paperworkProcessIssue ? paperworkProcessIssue.count : 0;
        let documentUpdateRequiredCount = documentUpdateRequired ? documentUpdateRequired.count : 0;
        let rOGIFindingsCount = rOGIFindings ? rOGIFindings.count : 0;

        let intIssueAndLegIssueCount = intIssueAndLegIssue ? intIssueAndLegIssue.count : 0;
        let intIssueAndPwIssueCount = intIssueAndPwIssue ? intIssueAndPwIssue.count : 0;
        let legIssueAndPwIssueCount = legIssueAndPwIssue ? legIssueAndPwIssue.count : 0;
        let intIssueAndlegIssueAndPwIssueCount = intIssueAndlegIssueAndPwIssue ? intIssueAndlegIssueAndPwIssue.count : 0;

        let dataSets = [{
            data: [
                integrityIssueCount,
                legislativeIssueCount,
                paperworkProcessIssueCount,

                documentUpdateRequiredCount,
                rOGIFindingsCount,

                intIssueAndLegIssueCount,
                intIssueAndPwIssueCount,
                legIssueAndPwIssueCount,
                intIssueAndlegIssueAndPwIssueCount
            ],
            backgroundColor: [
                '#002A4E',
                '#626366',
                '#5E8AB4',

                '#FFAD0A',
                '#DA1F33',

                '#DBDCDD',
                '#B1B3B5',
                '#898B8D',
                '#333333'
            ]
        }]
        defaultData = { ...defaultData, datasets: dataSets };
    }

    return defaultData;
}

function createVennSets(fndByIssueList) {
    let vennSet = [];

    if (fndByIssueList && fndByIssueList.length > 0) {
        let keyList = ["A", "B", "C", "D", "E"];

        let issueList = fndByIssueList;
        let countTotal = 0;
        issueList.map(fnd => countTotal += fnd.count);
        let tempSetList = [];

        issueList.forEach((fnd, index) => {
            let count = fnd.count ? fnd.count : 0;
            let scaledCount = 0;

            try {
                let result = (count / countTotal) * 10;
                let formatedScaledCount = parseFloat(parseFloat(result).toFixed(2));
                scaledCount = formatedScaledCount;
            } catch (error) {
                scaledCount = 0;
            }

            let keyItem = keyList[index];
            let label = fnd.label && fnd.label !== "" ? fnd.label : "";
            let link = fnd.linkHref && fnd.linkHref !== "" ? `/${fnd.linkHref}` : "/";

            let setItem = {
                sets: [keyItem],
                size: scaledCount,
                label: label,
                link: link
            }
            tempSetList.push(setItem);
        })

        let intesections = [
            { sets: ['A', 'B'], size: .4 },
            { sets: ['A', 'C'], size: .4 },
            { sets: ['B', 'C'], size: .4 }
        ];

        vennSet = [...tempSetList, ...intesections];
    }
    return vennSet;
}

function mapToFindingsTypeKey(label) {
    let key = ""
    if (label && label !== "") {
        switch (label) {
            case "Dutyholder Findings":
                key = DH_FINDING_TYPE;
                break;
            case "Installation Findings":
                key = INST_FINDING_TYPE;
                break;
            default:
                break;
        }
    }

    return key;
}

export default StatisticsAlt;