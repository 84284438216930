import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

const PageSpinner = (prop) => {
    const { 
        type="Oval",
        color = "#002a4e",
        height = 50, 
        width = 50, 
        timeout = 6000, 
        displayText = "" 
    } = prop ? prop : {}
    return (
        <div className="flex flex-col w-full items-center justify-center">
            <Loader
                type={type}
                color={color}
                height={height}
                width={width}
                timeout={timeout}
            />
            <p>{displayText}</p>
        </div>
    )
}

export default PageSpinner;