/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react';
import { Formik } from 'formik';
import {gql, useMutation, useLazyQuery} from '@apollo/client';
import {AuthContext} from "../../context/authContext";
import {uniqueId} from 'lodash';
import PropTypes from 'prop-types';

Comment.propTypes = {
    sysObjName : PropTypes.string,
    sysObjRecordId : PropTypes.string,
    buttonName : PropTypes.string,
    buttonClassName : PropTypes.string
};

const SYSTEM_OBJECT_QUERY = gql`
query {
  systemObjects {
        _id
        so_name
    }
}
`;

const CREATE_COMMENT = gql`
mutation CreateComment($com: CreateComment!) {
  createComment(com: $com) {
        _id
        success
        status_code
        message
    }
}
`;

const COMMENTS_BY_SOID_AND_SORID_QUERY = gql`
query CommentsBySoIdAndSorId ($soId : ID!, $sorId : ID!) {
commentsBySoIdAndSorId (soId: $soId, sorId: $sorId) {
     _id
     so_id {
        _id
     }
     so_record_id
     com_body
     com_added_date
     com_added_id {
        _id
        user_fname
        user_lname 
        user_profile_image
      }
   }
 }`;

function Comment(props) {
    const [soRecordId, setSoRecordId] = useState("");
    const [soId, setSoId] = useState("");
    const [soName, setSoName] = useState("");
    const [btnName, setButtonName] = useState("");
    const [btnClassName, setButtonClassName] = useState("");
    const [commentsList, setCommentsList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const authContext = useContext(AuthContext);
    const { sysObjName = "", sysObjRecordId = "", buttonName = "", buttonClassName = "" } = props ? props : {};

    const [systemObjects, { data: soData }] = useLazyQuery(SYSTEM_OBJECT_QUERY);
    const [commentsBySoIdAndSorId, { data: comsData }] = useLazyQuery(COMMENTS_BY_SOID_AND_SORID_QUERY);
    const [createComment, { data: createCommentData }] = useMutation(CREATE_COMMENT);

    useEffect(() => {
        setSoName(sysObjName);
        setSoRecordId(sysObjRecordId);
        setButtonName(buttonName);
        setButtonClassName(buttonClassName);
    }, [sysObjName, sysObjRecordId, buttonName, buttonClassName]);

    useEffect(() => {
        systemObjects({variables: {}, errorPolicy: 'all'});
    }, [soName]);
    
    useEffect(() => {
        if (soData && soData.systemObjects) {
          let systemObjects = soData.systemObjects;
          const sysObject = systemObjects.find(so => so.so_name === soName);
          const { _id: soId = "" } = sysObject ? sysObject : {};
          setSoId(soId);
        }
    }, [soData]);

    useEffect(() => {
        if (comsData && comsData.commentsBySoIdAndSorId){
           setCommentsList(comsData.commentsBySoIdAndSorId);
           toggleModal();
        }
      }, [comsData]);
    
    useEffect(() => {
        if (createCommentData) {
            const { success, message } = createCommentData.createComment;
            if (!success) {
                alert(message);
            }
            if (success) {
                console.log("createCommentData>>>>", createCommentData);
                toggleModal();
            }
        }
    }, [createCommentData]);

    const handleCommentBtnClick = (recordId, soId) => {
        if (recordId && recordId !== "" && soId && soId !== "") {
            commentsBySoIdAndSorId({ variables: { soId: soId, sorId: recordId }, errorPolicy: 'all' });          
        } 
    }

    const toggleModal = () => {
        setShowModal(!showModal);
    }

    return (
        <>
            <button
                className={btnClassName}
                type="button"
                onClick={() => { handleCommentBtnClick(soRecordId, soId) }}
            >
                {btnName}
            </button>
            
            {showModal && soRecordId !== "" && <div className="modal fixed w-full h-full top-0 left-0 flex justify-center z-30">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
                <div className="fixed h-full w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
                    <Formik
                        initialValues={{ comment: "" }}
                        onSubmit={(values, { resetForm }) => {

                            if (soId && soId !== "" && soRecordId && soRecordId !== "" && values.comment && values.comment !== "") {
                                const { _id: userId = "" } = authContext.user ? authContext.user : {};
                                const commentPayload = {
                                    so_id: soId,
                                    so_record_id: soRecordId,
                                    com_body: values.comment,
                                    com_added_date: new Date(),
                                    com_added_id: userId,
                                }
                                createComment({ variables: { com: commentPayload } });
                            }
                            resetForm({});
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <form className="h-full overflow-hidden" onSubmit={handleSubmit}>
                                <div className="mb-4 pb-2 border-b-2 border-gray-200">
                                    <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase text-left">Comments</h2>
                                </div>

                                <div className="overflow-y-auto" style={{ height: 'calc(100% - 3.5rem)' }}>

                                    {commentsList.map((comment, index) =>
                                        <div className={`w-11/12 mb-3 mr-3 ${isLoggedInUserComment(comment, authContext)? "float-right" : ""}`} key={index}>
                                        <div className="text-left">
                                            <p className="text-sm">{comment.com_added_id.user_fname} {comment.com_added_id.user_lname} - {formatDate(comment.com_added_date)}</p>
                                        </div>
                                        <div key={`comment-${index}`} className={`p-2 border ${isLoggedInUserComment(comment, authContext)? "bg-red-900" : "bg-blue-700"} text-sm text-white text-left rounded`}>{comment.com_body}</div>
                                    </div>
                                    )}
                                    <div className="mb-2 mr-3">
                                        <textarea
                                            type="text"
                                            className="form-control block w-full md:w-full"
                                            name="comment"
                                            id={uniqueId()}
                                            placeholder="Write your comment here"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.comment}
                                        />
                                    </div>
                                    <button type="submit" className="mr-3 mb-4 button-red capitalize">Submit</button>
                                </div>   
                            </form>
                        )}
                    </Formik>

            </div>
            </div>}
         
        </>
    );
}

function formatDate(date) {
    let tempDate = "";
    if (date && date !== "") {
      let dateOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      let dateAdded = new Date(date);
      tempDate = dateAdded.toLocaleDateString('en-GB', dateOptions);
    }
    return tempDate;
}

function isLoggedInUserComment(comment, authContext) {
    let retval = false
    if (authContext && comment) {
        const { _id: userId = "userId" } = authContext.user ? authContext.user : {};
        const { _id: addedId = "addedId" } = comment.com_added_id ? comment.com_added_id : {};
        retval = (addedId === userId);
    }
    return retval;
}

export default Comment;