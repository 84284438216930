/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import Table from '../global/Table';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";

import Sanitiser from "../global/sanitiser";

import { AuthContext } from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import { DashboardContext } from '../../context/dashboardContext';

import { PageContext } from '../../context/pageContext';
import { sysObjects } from "../../constants/sysObjectList";

import {
  USER_TYPE_ABS_ID,
  USER_TYPE_DUTY_HOLDER_ID,
  ACTION_STATUS_UNAPPROVED,
  NOTIFICATION_STATUS_PENDING,
  NOTIFICATION_STATUS_APPROVED,
  ACTION_SEARCH_ORIGIN_FILTER,
  ACTION_SEARCH_ORIGIN_NAV
} from '../../constants/';

import {
  parseJSONSafely,
  formatDateToLocaleDateString,
  createUserTypeAndRoleObject,
  getBreadCrumbHeader,
  valueFromInfoMatrixLevel
} from "../../utils/index";

const PAGE_NAME = "ActionList";

const ACTIONS_SEARCH_QUERY = gql`
    query ActionSearch($param: ActionSearchInput!){
      actionSearch(param: $param) {
        _id
        type
        operation
        raised_by{
          _id
        }
        title
        task
        detail
        build_no
        priority
        date_added
        meta
        metadata
        view_path
        status 
        }
    }
`;

const DUTY_HOLDER_QUERY = gql`
    query DutyHolders($status: String) {
      dutyHolders(status: $status){
            _id
            dh_code
            dh_name
            dh_status
            dh_added_date
            dh_archived
        }
    }`;

const USERS_SEARCH_QUERY = gql`
query UserSearch($param: UserSearchInput!){
    user_search (param: $param){
        _id
        user_fname
        user_lname
        user_archived
        user_type {
            _id
            user_type_display_name
        }
        user_role{
            _id
            user_role_code
        }
    }
}`;

const UPDATE_ACTION = gql`
    mutation UpdateAction($id: String!, $action: ActionInput!) {
      updateAction(_id: $id, action: $action) {
             _id
             type
             operation
        }
    }`;

const formDefaultValue = {
  user_type_id: "",
  dh_id: "",
  user_id: ""
}

const defaultUserTypes = [
  { name: "ABS", id: USER_TYPE_ABS_ID },
  { name: "Duty Holder", id: USER_TYPE_DUTY_HOLDER_ID },
]

function ActionList(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [actionList, setActionList] = useState([]);
  const [actionStatus] = useState(ACTION_STATUS_UNAPPROVED) //setActionStatus
  const [startLoading, setStartLoading] = useState(true);
  const [modalAction] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageName, setPageName] = useState("");
  const [drDnDutyholder, setDrDnDutyholder] = useState({});
  const [drDnInstallation, setDrDnInstallation] = useState({});
  const [drDnSece, setDrDnSece] = useState({});
  const [actionPriority, setActionPriority] = useState("");
  const [actionUserId, setActionUserId] = useState("");
  const [actionSearchParam, setActionSearchParam] = useState({});

  const [hasFilterParam, setHasFilterParam] = useState(false);
  const [formInitValues, setFormInitValues] = useState({});

  const [selectedUserTypeId, setSelectedUserTypeId] = useState("");
  const [selectedDutyHolderId, setSelectedDutyHolderId] = useState("");

  const [userTypes, setUserTypes] = useState([]);
  const [dutyholders, setDutyholders] = useState([]);
  const [users, setUsers] = useState([]);

  const { location: { pathname = "", search = "" } } = props ? props : {};

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const dashboardContext = useContext(DashboardContext);

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const pageContext = useContext(PageContext);

  const [user_search, { loading: userLoading, error: userError, data: usersData }] = useLazyQuery(USERS_SEARCH_QUERY);
  const [dutyHolders, { loading: dHLoading, error: dHError, data: dutyHoldersData }] = useLazyQuery(DUTY_HOLDER_QUERY);

  const [actionSearch, { error: actionError, data: actionData, refetch }] = useLazyQuery(ACTIONS_SEARCH_QUERY);
  const [updateAction, { data: uaData }] = useMutation(UPDATE_ACTION);

  useEffect(() => {
    setPageName(PAGE_NAME);
    setUserTypes(defaultUserTypes);
    setFormInitValues(formDefaultValue);
  }, []);

  useEffect(() => {
    if (authContext && authContext.user) {

      const { user_duty_holder, user_type } = authContext.user ?? {};

      const { _id = "" } = user_duty_holder ?? {};
      const { _id: user_type_id = "" } = user_type ?? {};

      setSelectedUserTypeId(user_type_id);

      if (user_type_id === USER_TYPE_DUTY_HOLDER_ID) {
        setFormInitValues(prevState => ({ ...prevState, user_type_id: user_type_id, dh_id: _id }));
        setSelectedDutyHolderId(_id);
      }

      if (user_type_id === USER_TYPE_ABS_ID) {
        setFormInitValues(prevState => ({ ...prevState, user_type_id: user_type_id }));
      }

      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (dashboardContext && dashboardContext.actionSearchParam) {
      setActionSearchParam(dashboardContext.actionSearchParam);
    }
  }, [dashboardContext]);

  useEffect(() => {
    if (pathname && pathname === "/reporting/action-list") {
      setActionPriority("");
      setActionUserId("");
    }
  }, [pathname]);

  useEffect(() => {
    const { actionListPage: { page = 0 } } = pageContext;
    setCurrentPage(page);
  }, [pageContext]);

  useEffect(() => {
    const { dutyHolder, installation, sece } = navContext;
    setDrDnDutyholder(dutyHolder);
    setDrDnInstallation(installation);
    setDrDnSece(sece);
  }, [navContext]);

  useEffect(() => {
    let searchQueryParams = new URLSearchParams(search);

    let priority = searchQueryParams.get("priority");
    let userId = searchQueryParams.get("userId") ?? "";
    let searchOrigin = searchQueryParams.get("search_origin") ?? "";

    setActionPriority(priority);
    setActionUserId(userId);
    setFormInitValues(prevState => ({ ...prevState, user_id: userId }));

    let dpDnSearchParams = {
      ...(drDnDutyholder.id ? { dh_id: drDnDutyholder.id } : {}),
      ...(drDnInstallation.id ? { installation_id: drDnInstallation.id } : {}),
      ...(drDnSece.id ? { sece_id: drDnSece.id } : {})
    };

    let generalSearchParams = {
      priority: priority,
      user_id: userId,
      status: actionStatus,
      search_origin: searchOrigin
    }

    let searchParams = {
      ...dpDnSearchParams,
      ...generalSearchParams
    }

    actionSearch({ variables: { param: { ...searchParams } }, errorPolicy: 'all' });
    setStartLoading(true);

  }, [search, actionUserId, actionStatus, actionSearchParam, drDnDutyholder, drDnInstallation, drDnSece])

  useEffect(() => {
    if (actionData && actionData.actionSearch) {
      let list = formatActionData(actionData.actionSearch, userTypeAndRoleObject);
      setActionList(list);
      setStartLoading(false);
    }
  }, [actionData]);

  useEffect(() => {
    if (uaData && uaData.updateAction) {
      refetch();
    }
  }, [uaData]);

  useEffect(() => {
    fetchMultiSelectData(selectedUserTypeId, selectedDutyHolderId)
  }, [selectedUserTypeId, selectedDutyHolderId]);

  useEffect(() => {
    if (dutyHoldersData && dutyHoldersData.dutyHolders) {
      let list = dutyHoldersData.dutyHolders;
      setDutyholders(list);
    }
  }, [dutyHoldersData]);

  useEffect(() => {
    if (usersData && usersData.user_search) {
      let list = usersData.user_search;
      setUsers(list);
    }
  }, [usersData]);

  const clearFilter = () => {
    const { user_duty_holder, user_type } = authContext.user ?? {};

    const { _id = "" } = user_duty_holder ?? {};
    const { _id: user_type_id = "" } = user_type ?? {};

    if (user_type_id === USER_TYPE_DUTY_HOLDER_ID) {
      setFormInitValues({ user_type_id: user_type_id, dh_id: _id, user_id: "" });
      setSelectedUserTypeId(user_type_id);
      setSelectedDutyHolderId(_id);

    } else {
      setFormInitValues({ ...formDefaultValue, user_type_id: user_type_id });
      setSelectedUserTypeId(user_type_id);
      setSelectedDutyHolderId("");

      setDutyholders([]);
      setHasFilterParam(false);
    }

    const additionalFindingSearchParam = {
      ...(drDnDutyholder.id && drDnDutyholder.id !== "" && { dh_id: drDnDutyholder.id }),
      ...(drDnInstallation.id && drDnInstallation.id !== "" && { installation_id: drDnInstallation.id }),
      ...(drDnSece.id && drDnSece.id !== "" && { sece_id: drDnSece.id }),
    };

    let payload = {
      ...additionalFindingSearchParam,
      search_origin: ACTION_SEARCH_ORIGIN_NAV
    }

    actionSearch({ variables: { param: { ...payload } }, errorPolicy: 'all' });
    setStartLoading(true);
    toggleFilterActionModal();
  }

  const fetchMultiSelectData = (userTypeId, dutyHolderId) => {
    if (userTypeId && userTypeId !== "") {
      setDutyholders([]);
      setUsers([]);

      dutyHolders({
        variables: {
          status: NOTIFICATION_STATUS_APPROVED
        }, errorPolicy: 'all'
      });

      user_search({
        variables: {
          param: {
            user_type: userTypeId,
            ...(dutyHolderId && dutyHolderId !== "" ? { dh_id: dutyHolderId } : {}),
            user_archived: false,
            user_status: NOTIFICATION_STATUS_APPROVED
          }
        }, errorPolicy: 'all'
      });

    }

    if (dutyHolderId && dutyHolderId !== "") {
      setUsers([]);
      user_search({
        variables: {
          param: {
            dh_id: dutyHolderId,
            ...(userTypeId && userTypeId !== "" ? { user_type: userTypeId } : {}),
            user_archived: false,
            user_status: NOTIFICATION_STATUS_APPROVED
          }
        }, errorPolicy: 'all'
      });
    }
  }

  const handleUserTypeChange = (event) => {
    let value = event.target.value;
    setSelectedUserTypeId(value);
    setFormInitValues(prevState => ({ ...prevState, user_type_id: value }));
  };

  const handleDutyholderChange = (event) => {
    let value = event.target.value;
    setSelectedDutyHolderId(value);
    setFormInitValues(prevState => ({ ...prevState, dh_id: value }));
  };

  const handleUserChange = (event) => {
    let value = event.target.value;
    setFormInitValues(prevState => ({ ...prevState, user_id: value }));
  };

  const handleArchieveAction = (input) => {
    const { action_id, user_id } = input ?? {};
    let payload = {
      ac_archived: true,
      ac_archived_id: user_id,
      ac_archived_date: new Date()
    };
    updateAction({ variables: { id: action_id, action: payload } });
    setStartLoading(true);
  }

  function toggleModal() {
    const modal = document.querySelector('.modal')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
  }

  function toggleFilterActionModal() {
    const modal = document.querySelector('.filter-action-modal')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Task',
        accessor: 'action_task', // accessor is the "key" in the data 
      },
      {
        Header: 'Finding Ref',
        accessor: 'finding_ref',
      },
      {
        Header: 'Finding Level',
        accessor: 'finding_level',
      },
      {
        Header: 'Status',
        accessor: 'finding_current_status',
      },
      {
        Header: 'Last Response',
        accessor: 'finding_last_response',
      },
      {
        Header: 'Date of Last Response',
        accessor: 'finding_date_of_last_response',
      },
      {
        Header: 'Last Responded to By',
        accessor: 'finding_last_responded_to_by',
      },
      {
        Header: 'Synopsis',
        accessor: 'finding_synopsis',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: action }) => (
          <div className="text-right">
            {action.workflow_status &&
              action.workflow_status !== NOTIFICATION_STATUS_PENDING &&
              action.action_operation !== "Close" &&
              <Link
                to={{
                  pathname: action.report_id ? `/reporting/${action.report_id}/finding/edit/${action.findingId}` : `/reporting/finding/edit/${action.findingId}`,
                  state: { formMode: 'edit' }
                }}
                className="button-red mb-1 mr-1 text-xs"
              >
                {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" ? "Edit" : " Respond"}
              </Link>
            }

            <Link
              to={{
                pathname: `/reporting/finding/view/${action.findingId}`
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              View
            </Link>

            {action.action_operation === "Close" &&
              userTypeAndRoleObject &&
              userTypeAndRoleObject.userType === "ABS" && <button
                className="button-red mb-1 mr-1 text-xs"
                onClick={() => handleArchieveAction({ action_id: action.id, user_id: userTypeAndRoleObject.userId })}
              >
                Archive
              </button>}
          </div>
        ),
      },
    ],
    [userTypeAndRoleObject, handleArchieveAction]
  )

  if (startLoading) {
    return (
      <PageSpinner />
    )
  }

  if (actionError || userError || dHError) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  //TITLE / INFO & MAIN BUTTON SECTION
  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Action List <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam condimentum a lectus non condimentum. Aliquam rhoncus, enim vitae condimentum pharetra, urna magna elementum sapien, non vehicula turpis tellus nec velit. Vivamus tristique venenatis neque, eget tristique mauris suscipit aliquet. Pellentesque et massa nunc.</p>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      {getBreadCrumbHeader({
        userTypeAndRoleObject: userTypeAndRoleObject,
        drDnDutyholder: drDnDutyholder,
        drDnInstallation: drDnInstallation,
        drDnSece: drDnSece,
        section: "Dashboard",
        title: (actionPriority && actionPriority !== "" ? `My ${actionPriority !== "na" ? actionPriority + " Priority" : ""}  Action List` : "Action List")
      })
      }

      <button className="button-red mr-2 mb-2 py-3 px-5 modal-button"
        onClick={() => toggleFilterActionModal()}
        style={{ ...(hasFilterParam && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}>Filter Actions</button>

      {/* {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "ABS" && 
        drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - {actionPriority!== "" ? `My ${actionPriority !== "na"? actionPriority+ " Priority": ""}  Action List` : "Action List"} - Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "Duty Holder" && 
        drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - {actionPriority!== "" ? `My ${actionPriority !== "na"? actionPriority + " Priority": ""}  Action List` : "Action List"} - Installation: {drDnInstallation.name}</h6>}
      {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - {actionPriority!== "" ? `My ${actionPriority !== "na"? actionPriority + " Priority": ""}  Action List` : "Action List"} - Duty Holder / Installation : All</h6>} */}

      {actionList.length ?
        <Table
          columns={columns}
          data={actionList}
          currentPage={currentPage}
          pageName={pageName}
          getRowProps={row => ({
            style: {
              background: row.original.action_priority === 'High' ? '#ffe7ea' : row.original.action_priority === 'Medium' ? '#fff3db' : row.original.action_priority === 'Low' ? '#e2ffd9' : null,
            },
          })}
        />
        : <h4 className="mt-4">There are currently no Actions</h4>}

      {/* ACTION DETAILS */}
      <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
        <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
        <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

          <div className="mb-4 pb-2 border-b-2 border-gray-200">
            <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">Action Details</h2>
          </div>

          {modalAction.action_task &&
            <h2 className="text-blue-900 font-sans text-1xl font-bold"> {modalAction.action_task}</h2>
          }
          {modalAction.action_build &&
            <h5 className="mb-2 font-bold text-sm text-red-900"> {modalAction.action_build}</h5>
          }

          {modalAction.action_priority &&
            <h5 className="mb-4 text-sm">Priority: <span className="font-bold text-red-900"> {modalAction.action_priority}</span></h5>
          }

          <p className="mb-4">{modalAction.action_task && modalAction.action_task}</p>

          <button className="mt-3 mb-4 button-red capitalize" onClick={() => toggleModal()}>Close</button>
        </div>
      </div>
      {/* ACTION DETAILS END */}

      {/* FILTER ACTION MODAL*/}
      <div className="filter-action-modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
        <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleFilterActionModal()}></div>
        <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

          <Formik
            initialValues={formInitValues}
            enableReinitialize={true}
            onSubmit={(values, action) => {

              let payload = {
                user_type_id: values.user_type_id,
                dh_id: values.dh_id,
                user_id: values.user_id,
                search_origin: ACTION_SEARCH_ORIGIN_FILTER
              }

              actionSearch({ variables: { param: { ...payload } }, errorPolicy: 'all' });
              setFormInitValues({ ...values });
              setHasFilterParam(true);
              setStartLoading(true);
              toggleFilterActionModal();

            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>

                <div className="mb-4 pb-2 border-b-2 border-gray-200">
                  <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">Filter Actions</h2>
                </div>

                <div className="mb-4">
                  <label htmlFor="user_type_id" className="block mb-1 text-blue-900 font-semibold">User Type</label>
                  <select
                    disabled={
                      (userTypeAndRoleObject &&
                        userTypeAndRoleObject.userType &&
                        userTypeAndRoleObject.userType === "Duty Holder") || actionUserId !== ""}
                    className="form-control block w-full"
                    name="user_type_id"
                    id="user_type_id"
                    onChange={(event) => {
                      handleChange(event);
                      handleUserTypeChange(event);
                      setFieldValue("dh_id", "");
                      setFieldValue("user_id", "");
                    }}
                    onBlur={handleBlur}
                    value={values.user_type_id}
                  >
                    <option value={""}>Select User Type</option>
                    {userTypes.map(u =>
                      <option key={u.id} value={u.id}>{u.name}</option>
                    )}
                  </select>
                </div>

                {values.user_type_id === USER_TYPE_DUTY_HOLDER_ID &&
                  <div className="mb-4">
                    <label htmlFor="dh_id" className="block mb-1 text-blue-900 font-semibold">Duty Holder</label>
                    <select
                      disabled={userTypeAndRoleObject && userTypeAndRoleObject.userType && userTypeAndRoleObject.userType === "Duty Holder"}
                      className="form-control block w-full"
                      name="dh_id"
                      id="dh_id"
                      onChange={(event) => {
                        handleChange(event);
                        handleDutyholderChange(event);
                        setFieldValue("user_id", "");
                      }}
                      onBlur={handleBlur}
                      value={values.dh_id}
                    >
                      <option value={""}>{dHLoading ? "loading..." : "Select Duty Holder"}</option>
                      {dutyholders.map(dh =>
                        <option key={dh._id} value={dh._id}>{dh.dh_name}</option>
                      )}
                    </select>
                  </div>
                }

                <div className="mb-4">
                  <label htmlFor="user_id" className="block mb-1 text-blue-900 font-semibold">User</label>
                  <select
                    disabled={actionUserId !== ""}
                    className="form-control block w-full"
                    name="user_id"
                    id="user_id"
                    onChange={(event) => {
                      handleChange(event);
                      handleUserChange(event);
                    }}
                    onBlur={handleBlur}
                    value={values.user_id}
                  >
                    <option value={""}>{userLoading ? "loading..." : "Select User"}</option>
                    {users.map(user =>
                      <option key={user._id} value={user._id}>{`${user.user_fname} ${user.user_lname}`}</option>
                    )}
                  </select>
                </div>

                <button
                  disabled={actionUserId !== ""}
                  type="submit"
                  className={`${"mt-3 mb-4 button-red capitalize mr-4" + (actionUserId !== "" ? " cursor-not-allowed" : "")}`}>Apply Filter</button>
                <button
                  disabled={actionUserId !== ""}
                  type="button" onClick={() => { clearFilter() }}
                  className={`${"mt-3 mb-4 button-red capitalize" + (actionUserId !== "" ? " cursor-not-allowed" : "")}`}>Clear Filter</button>

              </form>
            )}
          </Formik>

        </div>
      </div>
      {/* FILTER ACTION MODAL*/}

    </div>
  )
  //TITLE / INFO & MAIN BUTTON SECTION - END
}

function formatActionData(list, userTypeAndRoleObject) {
  let newList = [];
  if (list && list.length) {

    list.forEach(action => {
      const metadata = parseJSONSafely(action.metadata) ? parseJSONSafely(action.metadata) : {};
      const {
        _id: findingId = "",
        dh_id,
        installation_id,
        finding_ref,
        finding_infomatrix_level,
        finding_current_status,
        finding_responses,
        finding_synopsis,
        report_id,
        finding_status
      } = metadata;

      const { dh_name: dhName = "N/A" } = dh_id ?? {};

      const { installation_title: instName = "N/A" } = installation_id ?? {};

      const { _id: fnd_status_id = "", sysvar_title: fnd_status = "N/A" } = finding_current_status ?? {};

      const responses = parseJSONSafely(finding_responses) ? parseJSONSafely(finding_responses) : [];
      const lastResponse = responses[responses.length - 1];

      const { response_note = "N/A", response_update_by = "N/A", response_update_date = "" } = lastResponse ?? {};

      const { userType = "" } = userTypeAndRoleObject ?? {};

      let task = action.task;

      if (action.operation === "Create" && action.task === "Finding approved" && userType === "Duty Holder") {
        task = "Finding Raised";
      }

      if (action.operation === "Update" && action.task === "Finding approved" && userType === "Duty Holder") {
        task = "Finding Updated";
      }

      let newAction = {
        id: action._id,
        findingId: findingId,
        recordId: findingId,
        soName: sysObjects.finding,
        action_task: task,
        action_operation: action.operation,
        dutyholder: dhName,
        installation: instName,
        finding_ref: finding_ref,
        finding_level: valueFromInfoMatrixLevel(finding_infomatrix_level),
        finding_current_status: fnd_status,
        finding_current_status_id: fnd_status_id,
        finding_last_response: response_note ? <Sanitiser html={response_note} /> : "N/A",
        finding_date_of_last_response: formatDateToLocaleDateString(response_update_date) ?? "N/A",
        finding_last_responded_to_by: response_update_by,
        finding_synopsis: finding_synopsis,
        report_id: report_id,
        finding_status: action.status,
        workflow_status: finding_status,
        meta: action.meta,
        type: action.type,
        date_raised: formatDateToLocaleDateString(action.date_added),
        userTypeAndRoleObject: userTypeAndRoleObject
      }

      newList.push(newAction);
    })
  }
  return newList
}

export default ActionList;