export const USER_TYPE_DUTY_HOLDER_ID = '5f61f1f69481950286cd6e7d';
export const USER_TYPE_ABS_ID = '5f61f1f69481950286cd6e7c';
export const OIM_USER_ROLE_ID = '5f904c54eb2fd619c499b305';
export const FOCAL_POINT_USER_ROLE_ID = '5f904c13eb2fd619c499b302';

export const TEMP_DUTYHOLDER_ID = '5f91a785f7a4ed7a143f473d';

export const NOTIFICATION_TYPE_DUTYHOLDER = 'Duty Holder';
export const NOTIFICATION_TYPE_ACCESSGROUP = 'Access Group';
export const NOTIFICATION_TYPE_INSTALLATION = 'Installation';
export const NOTIFICATION_TYPE_BARRIER = 'Barrier';
export const NOTIFICATION_TYPE_SECE = 'SECE';

export const NOTIFICATION_TYPE_DUTYHOLDER_UPDATE = 'Duty Holder update';
export const NOTIFICATION_TYPE_ASSETGROUP = 'Asset Group';
export const NOTIFICATION_TYPE_ASSETGROUP_UPDATE = 'Asset Group update';
export const NOTIFICATION_TYPE_INSTALLATION_UPDATE = 'Installation update';
export const NOTIFICATION_TYPE_BARRIER_UPDATE = 'Barrier update';
export const NOTIFICATION_TYPE_SECE_UPDATE = 'SECE update';
export const NOTIFICATION_TYPE_MAJOR_ACCIDENT_HAZARD = 'Major Accident Hazard';
export const NOTIFICATION_TYPE_MAJOR_ACCIDENT_HAZARD_UPDATE = 'Major Accident Hazard update';
export const NOTIFICATION_TYPE_PERFORMANCE_STANDARD = 'Performance Standard';
export const NOTIFICATION_TYPE_PERFORMANCE_STANDARD_UPDATE = 'Performance Standard update';
export const NOTIFICATION_TYPE_VERIFICATION_ACTIVITY = 'Verification Activity';
export const NOTIFICATION_TYPE_VERIFICATION_ACTIVITY_UPDATE = 'Verification Activity update';
export const NOTIFICATION_TYPE_REFERENCE_ACTIVITY = 'Reference Activity';
export const NOTIFICATION_TYPE_REFERENCE_ACTIVITY_UPDATE = 'Reference Activity update';
export const NOTIFICATION_TYPE_USER = 'User';
export const NOTIFICATION_TYPE_USER_UPDATE = 'User update';
export const NOTIFICATION_TYPE_VERIFICATION_SCHEDULE = 'Verification Schedule';
export const NOTIFICATION_TYPE_REPORT = 'Verification Report Update';
export const NOTIFICATION_TYPE_FINDING = 'Finding';
export const NOTIFICATION_TYPE_FINDING_UPDATE = 'Finding update';
export const NOTIFICATION_TYPE_LOCATION = 'Location';
export const NOTIFICATION_TYPE_LOCATION_UPDATE = 'Location update';
export const NOTIFICATION_TYPE_INFOMATRIX = 'Info Matrix';
export const NOTIFICATION_TYPE_INFOMATRIX_UPDATE = 'Info Matrix update';

export const NOTIFICATION_TYPE_USER_COMPETENCY = 'User Competency';
export const NOTIFICATION_TYPE_USER_COMPETENCY_UPDATE = 'User Competency update';

export const NOTIFICATION_TYPE_VERIFICATION_SCHEDULE_REPORT = 'Verification Schedule Report';
export const NOTIFICATION_TYPE_VERIFICATION_SCHEDULE_REPORT_UPDATE = 'Verification Schedule Report Update';

export const NOTIFICATION_TYPE_PERIODIC_VERIFICATION_REPORT = 'Periodic Verification Report';
export const NOTIFICATION_TYPE_PERIODIC_VERIFICATION_REPORT_UPDATE = 'Periodic Verification Report Update';

export const NOTIFICATION_TYPE_ASSET_VERIFICATION_REPORT = 'Asset Verification Report';
export const NOTIFICATION_TYPE_ASSET_VERIFICATION_REPORT_UPDATE = 'Asset Verification Report Update';

export const NOTIFICATION_STATUS_UNAPPROVED = 'unapproved';
export const NOTIFICATION_STATUS_APPROVED = 'approved';
export const NOTIFICATION_STATUS_PENDING = 'pending';
export const NOTIFICATION_STATUS_REJECT = 'rejected';
export const NOTIFICATION_STATUS_REVISION = 'revision';

export const NOTIFICATION_STATUS_OPEN = 'open';
export const NOTIFICATION_STATUS_ONGOING = 'ongoing';
export const NOTIFICATION_STATUS_COMPLETE = 'completed';
export const NOTIFICATION_STATUS_REVIEW = 'review';
export const NOTIFICATION_STATUS_RESCHEDULED = 'rescheduled';
export const NOTIFICATION_STATUS_CLOSED = 'closed';

export const NOTIFICATION_ACTION_CREATE = 'create';
export const NOTIFICATION_ACTION_UPDATE = 'update';

export const GDPR_NOTICE_TYPE_ID = '5fa2f5c16aff93362c6a8b2b';

export const NOTICE_STATUS_ACCEPTED = 'accepted';
export const NOTICE_STATUS_DECLINED = 'declined';

export const NOTIFICATION_PRIORITY_LOW = 'Low';
export const NOTIFICATION_PRIORITY_MEDIUM = 'Medium';
export const NOTIFICATION_PRIORITY_HIGH = 'High';

export const ACTION_STATUS_UNAPPROVED = 'unapproved';
export const ACTION_STATUS_APPROVED = 'approved';
export const ACTION_STATUS_PENDING = 'pending';
export const ACTION_STATUS_REJECT = 'rejected';
export const ACTION_STATUS_DECLINED = 'declined';

export const ASSETGROUP_NULL = '5f68ad8b9481950286cd6e7e' // assetgroup not needed
export const BARRIER_NULL = '5f68ad8b9481950286cd6e7f' // barrier not needed

export const USER_NULL = '60085730b01478299c1c3086' // user not needed

export const SCHEDULE_SYSTEM_VARIABLE_TYPE_ID = '5f85f48ba97ba7047006b03f';
export const PERIODICITY_SYSTEM_VARIABLE_TYPE_ID = '5fb6629614c242276801b1ff';
export const NATURE_SYSTEM_VARIABLE_TYPE_ID = '5f85f4a8a97ba7047006b040';
export const FREQUENCY_SYSTEM_VARIABLE_TYPE_ID = '5f85f4b7a97ba7047006b041';
export const PHASE_SYSTEM_VARIABLE_TYPE_ID = '5f86f735a4934414e4d53f5b';
export const STATEMENT_SYSTEM_VARIABLE_TYPE_ID = '5fa01ce6fcec1253a4d989f2';
export const STATUS_SYSTEM_VARIABLE_TYPE_ID = '5fd0d4ec25e2eb5b78d91598';

export const CURRENT_STATUS_FINDINGS_SYSTEM_VARIABLE_TYPE_ID = '60e86970945b1456ec83b60a';

export const ACTIVITY_STATUS_SYSTEM_VARIABLE_TYPE_ID = '60e86988945b1456ec83b60b';
export const FINDING_STATUS_SYSTEM_VARIABLE_TYPE_ID = '60e86988945b1456ec83b60b';
export const ANSWERS_SYSTEM_VARIABLE_TYPE_ID = '5fd0d5a025e2eb5b78d9159a';
export const QUESTIONS_SYSTEM_VARIABLE_TYPE_ID = '5fd204647dbf40326c2e36f6';
export const FINDING_LEVEL_SYSTEM_VARIABLE_TYPE_ID = '5f86f71ca4934414e4d53f5a';
export const VA_FREQUENCY_SYSTEM_VARIABLE_TYPE_ID = '600ab3bddc8e09520872b733';

export const MEETING_SCHEDULE_TYPE_ID = '5f8707d86711613c600632d0';

export const CONCLUSIVE_STATEMENT_FOR_SAVE_ID = '5fa0371cd51af429ae6c2499';

export const NOTICE_TYPE_SYS_VAR_TYPE_ID = '5fd8a8715643385dd04a8562';
export const NOTICE_PERIOD_SYS_VAR_TYPE_ID = '5fd8a8935643385dd04a8563';

export const OFFSHORE_SCHEDULE_TYPE_ID = '5f8707e96711613c600632d2';
export const ONSHORE_SCHEDULE_TYPE_ID = '5f8707e16711613c600632d1';
export const ADHOC_SCHEDULE_TYPE_ID = '5f8707cd6711613c600632cf';

export const REPORT_ACTION_SAVE = 'save';
export const REPORT_ACTION_REVIEW = 'review';

export const FINDING_ACTION_SAVE = 'save';
export const FINDING_ACTION_REVIEW = 'review';

export const SCHEDULE_FORM_ORIGIN_VS_PLANNER = 'vs_planner';
export const SCHEDULE_FORM_ORIGIN_VS_REGISTER = 'vs_register';
export const SCHEDULE_FORM_ORIGIN_VS_AR_REGISTER = 'ar_register';

export const CURRENT_STATUS_SYS_VAR_CLOSED_ID = '5fd0d5d825e2eb5b78d9159c';
export const CURRENT_STATUS_SCHEDULE_SYS_VAR_TYPE_ID = '60e869a0945b1456ec83b60c';
export const CURRENT_STATUS_SCHEDULE_SYS_VAR_CLOSED_ID = '62ea578384e1ae4976ce18d3';
export const CURRENT_STATUS_SCHEDULE_SYS_VAR_COMPLETED_ID = '6124fe012620ad8d48224ceb';

export const SYS_VAR_ANSWER_YES_ID = "5fd0d5e925e2eb5b78d9159d";
export const ACTIVITY_STATUS_SYS_VAR_COMPLETED_ID = '6124fd1f2620ad8d48224ce8';
export const ACTIVITY_STATUS_SYS_VAR_COMMENCED_ID = '6124fcf02620ad8d48224ce6';
export const ACTIVITY_STATUS_SYS_VAR_NOT_COMMENCED_ID = '6124fce62620ad8d48224ce5';
export const ACTIVITY_STATUS_SYS_VAR_SCHEDULED = '62bc559a9a3f61442b348e63';

export const VS_GRAPH_DISPLAY_MONTHS = 12;
export const VS_GRAPH_DISPLAY_MONTHS_EXP = 60;

export const FINDING_ORIGIN_ACTIVITY_REGISTER = 'ActivityRegister';
export const FINDING_ORIGIN_REPORT = 'Report';
export const FINDING_ORIGIN_STANDALONE = 'Standalone';

export const DEFAULT_CURRENT_ACTIVITY_STATUS = 'Scheduled';

export const POSITIVE_CONCLUSION_STATEMENT = "5fa0371cd51af429ae6c2499";

export const DH_FINDING_TYPE = "dutyholder_level_fnd";
export const INST_FINDING_TYPE = "installation_level_fnd";
export const MULTI_VA_FINDING_TYPE = "multiple_va_fnd";
export const SINGLE_VA_FINDING_TYPE = "single_va_fnd";

export const TEMP_NOT_SCHEDULED_ID = "f9267e8b47ab4df59cc2a21a956b5195";
export const TEMP_OVERDUE_ID = "d4a58172b60d4e9db1e5838c17c14f78";
export const TEMP_IN_DATE_ID = "1e83c17c14f78d4a58172b60d4e9db";

export const VA_CATEGORY_ORININAL = "original";
export const VA_CATEGORY_PLANNED = "planned";
export const VA_CATEGORY_OVERDUE_OR_MISSED = "overdue-or-missed";
export const VA_CATEGORY_MY_SCHEDULE = "my-schedule";
export const VA_CATEGORY_CURRENT = "current";

export const FINDING_SEARCH_ORIGIN_DASHBOARD = "Dashboard";
export const FINDING_SEARCH_ORIGIN_STATISTIC = "Statistic";

export const ABS_LOCATION_TYPE_ID = "64b6b58b562b753611ac8867";
export const DUTYHOLDER_LOCATION_TYPE_ID = "64b6b5cc562b753611ac8868";

export const FINDING_SO_TYPE_ID = "6123aff42565c73ed4018a06";
export const REPORT_SO_TYPE_ID = "6177eef7e2385d5b905500d8";

export const ACTION_SEARCH_ORIGIN_NAV = "nav";
export const ACTION_SEARCH_ORIGIN_DASHBOARD = "dashborad";
export const ACTION_SEARCH_ORIGIN_FILTER = "filter";


export const MY_FINDINGS = "My Findings";

export const USER_ROLE_APPROVER = "Approver";
export const USER_ROLE_VERIFIER = "Verifier";
export const USER_ROLE_ADMIN = "Admin";