/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import {AuthContext} from "../../context/authContext";
import {formatDateToLocaleDateString, capitaliseFirstCase, createUserTypeAndRoleObject} from "../../utils";

const DUTY_HOLDER_HISTORIES_QUERY = gql`
query InstallationHistory($dhId: String){
    duty_holder_histories(dhId: $dhId) {
        _id
        dh_code
        dh_name
        dh_description
        dh_status
        dh_child_id
        dh_added_date
        dh_added_id
        dh_archived
        history_parent_record_id
        history_modifying_user_id {
            _id
            user_fname
            user_lname
            user_email
        }
        history_modified_date
        history_modified_date_timestamp
    }
}
`;
function DutyHolderHistories() {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [showHelp, setShowHelp] = useState(false);
  const [dutyHolderHistories, setDutyHolderHistories] = useState([]);

  const { id: dhId = "" } = useParams();

  const [duty_holder_histories, { loading, error, data }] = useLazyQuery(DUTY_HOLDER_HISTORIES_QUERY);
  
  useEffect(() => {
  if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
  }
  }, [authContext]);

  useEffect(() => {
  if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
  }
  }, [userTypeAndRoleObject]);


  useEffect(() => {
    duty_holder_histories({ variables: { ...(dhId && dhId !== "" && { dhId: dhId }) }, errorPolicy: 'all' });
  }, [])

  useEffect(() => {
    if (data && data.duty_holder_histories) {
      let histories = data.duty_holder_histories;
      let formatedHistories = formatDutyholderHistoryData(histories);
      let reversedHistoryList = formatedHistories.reverse();
      setDutyHolderHistories(reversedHistoryList);
    }
  }, [data])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Duty Holder Code',
        accessor: 'dh_code',
      },
      {
        Header: 'Duty Holder Name',
        accessor: 'dh_name',
      },
      {
        Header: 'Modified Date',
        accessor: "history_modified_date"
      },
      {
        Header: 'Modifying User',
        accessor: "modifying_user",
      },
      {
        Header: 'Status',
        accessor: "status",
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { id, parentId } }) => (
          <div className="text-right">
            <Link
              to={{
                pathname: `/dutyholder/view-diff/${id}/${parentId}`,
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              View Change
            </Link>

          </div>
        )
      },
    ],
    []
  )

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="mb-6 text-blue-900 font-sans text-2xl font-bold uppercase">{(dhId && dhId !== "" ? "Duty Holder History" : "Duty Holders History")}<FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3 mb-6">A table detailing changes to Duty Holders</p>
        }

        <Link to="/dutyholders" className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>

      </div>

      <Table columns={columns} data={dutyHolderHistories} />

    </div>
  )
}

function formatDutyholderHistoryData(histories) {
  let historyList = []

  if (histories && histories.length) {

    histories.forEach(his => {

      const { _id, dh_code, dh_name, dh_status, history_modified_date, history_modifying_user_id, history_parent_record_id } = his ? his : {};
      const { user_fname = "", user_lname = "" } = history_modifying_user_id ? history_modifying_user_id : {}
      let userFullName = (user_fname !== "" && user_lname !== "") ? `${user_fname} ${user_lname}` : "N/A";

      let newHistory = {
        id: _id,
        parentId: history_parent_record_id,
        dh_code: dh_code && dh_code !== "" ? dh_code : "N/A",
        dh_name: dh_name && dh_name !== "" ? dh_name : "N/A",
        history_modified_date: history_modified_date && history_modified_date !== "" ? formatDateToLocaleDateString(history_modified_date) : "N/A",
        modifying_user: userFullName,
        status: dh_status && dh_status !== "" ? capitaliseFirstCase(dh_status) : "N/A"
      }

      historyList.push(newHistory);
    });
  }

  return historyList;
}

export default DutyHolderHistories;