/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useContext} from 'react';
import { v4 as uuidv4 } from 'uuid';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { Link , useHistory, useParams} from 'react-router-dom';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import TextEditor from '../../components/global/TextEditor';
import {omit} from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {AuthContext} from "../../context/authContext";
import {VsReportContext} from "../../context/vsReportContext";
import PageSpinner from '../global/pageSpinner';
import {parseJSONSafely, createUserTypeAndRoleObject, formatDateToLocaleDateString} from "../../utils/index";
import {REPORT_ACTION_SAVE, REPORT_ACTION_REVIEW} from '../../constants/';
import { faTrashAlt, faPenAlt } from '@fortawesome/free-solid-svg-icons';

const validation = (vsReportTypeId) => {
    let validation = {};
    let verifyValObject = {
        vs_rpt_title: Yup.string().label("vs_rpt_title").required("Report Title is required"),
        vs_revision_description: Yup.string().label("vs_revision_description").required("Revision Description is required"),
        vs_scheme_ref: Yup.string().label("vs_scheme_ref").required("Verification Scheme Ref is required"),

        vs_rpt_description: Yup.string().label("vs_rpt_description").required("Description is required"),
        vs_rpt_summary: Yup.string().label("vs_rpt_summary").required("Executive Summary is required"),
        vs_rpt_scope: Yup.string().label("vs_rpt_scope").required("Extent of Verification is required"),

        vs_rpt_statement: Yup.string().label("vs_rpt_statement").required("Statement is required"),
        vs_rpt_improvements: Yup.string().label("vs_rpt_improvements").required("Opportunities for Improvement is required"),

        vs_rpt_concerns: Yup.string().label("vs_rpt_concerns").required("Areas of Concern is required"),
        vs_rpt_positives: Yup.string().label("vs_rpt_positives").required("Positives From is required"),
        //vs_rpt_payment_details: Yup.string().label("vs_rpt_payment_details").required("Invoicing / Payment Details is required")
    }
    
    let fullValidationObject = {
        ...verifyValObject,
        //vs_rpt_start_date: Yup.string().label("vs_rpt_start_date").required("Start Date is required"),
        //vs_rpt_end_date: Yup.string().label("vs_rpt_end_date").required("End Date is required"),
    }

    if (vsReportTypeId === "1") {
        validation = Yup.object().shape(fullValidationObject);
    } else {
        validation = Yup.object().shape(verifyValObject);
    }

    return validation;
}

const reportIssuanceValidation = () => {
    let validation = Yup.object().shape({
        issue_number: Yup.string().label("issue_number").required("Issue Number is required"),
        document_holder: Yup.string().label("document_holder").required("Document holder is required"),
        location: Yup.string().label("location").required("Location is required"),
    });
    return validation;
}

const VS_REPORT_BY_ID_QUERY = gql`
query verificationScheduleReport($vsRptId: ID!){
    verificationScheduleReport(_id: $vsRptId) {
        _id
        dh_id{
            _id
            dh_name
        }
        installation_ids{
            _id
            installation_title
        }
        vs_rpt_ref
        vs_rpt_is_periodic
        vs_rpt_title
        vs_rpt_description
        vs_rpt_summary
        vs_rpt_scope
        vs_rpt_statement
        vs_rpt_improvements
        vs_rpt_concerns
        vs_rpt_positives
        vs_rpt_payment_details
        vs_rpt_rev_num
        vs_rpt_issuances
        vs_ids{
           _id 
           vs_title
           vs_periodicity{_id}
           vs_status
        }
        vs_status_list
        vs_rpt_start_date
        vs_rpt_end_date
        vs_rpt_revision_description
        vs_rpt_scheme_ref
        vs_rpt_added_date
        vs_rpt_added_id{
            _id
        }
        vs_rpt_approved_by{
            _id
            user_fname
            user_lname
        }
        vs_rpt_approved_date
        vs_rpt_archived
        vs_rpt_status
  }
}`;

const CREATE_VS_REPORT = gql`
    mutation CreateVerificationScheduleReport($vsRpt: CreateVerificationScheduleReport!) {
        createVerificationScheduleReport(vsRpt: $vsRpt) {
            _id
            success
            status_code
            message
        }
    }
`;

const UPDATE_VS_REPORT = gql`
    mutation UpdateVerificationScheduleReport($vsRptId: String!, $userId: String, $vsRpt: UpdateVerificationScheduleReportInput!) {
        updateVerificationScheduleReport(_id: $vsRptId, userId: $userId, vsRpt: $vsRpt) {
            _id
            success
            status_code
            message
        }
    }
`;

const defaultReportIssuanceFormInitValues = {
    id: "",
    issue_number: "",
    document_holder: "",
    location: ""
}

function AddEditVerificationScheduleReport(props) {
    const [enableValidation, setEnableValidation] = useState(false);
    const [isRptPeriodic, setIsRptPeriodic] = useState(false);
    const [vsReportTypeId, setVsReportTypeId] = useState("");
    const [vsId, setVsId] = useState("");
    const [indexFormValues, setIndexFormValues] = useState({});
    const [vsReportAction, setVsReportAction] = useState(REPORT_ACTION_SAVE);

    const [reportIssuanceList, setReportIssuanceList] = useState([]);
    const [reportIssuanceFormAction, setReportIssuanceFormAction] = useState("");

    const { formMode = "", vsRptId = ""} = useParams();

    const history = useHistory();

    const authContext = useContext(AuthContext);
    const vsReportContext = useContext(VsReportContext);

    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [reportIssuanceFormInitValues, setReportIssuanceFormInitValues] = useState(defaultReportIssuanceFormInitValues);

    const [formInitValues, setFormInitValues] = useState({
        vs_rpt_is_periodic: "",
        vs_rpt_title: "",
        vs_rpt_description: "",
        vs_rpt_rev_num: "",
        vs_rpt_summary: "",
        vs_rpt_scope: "",
        vs_rpt_statement: "",
        vs_rpt_improvements: "",
        vs_rpt_concerns: "",
        vs_rpt_positives: "",
        vs_rpt_payment_details: "",
        vs_rpt_start_date: "",
        vs_rpt_end_date: "",
        vs_rpt_status: "",
        vs_rpt_approved_by: "",
        vs_rpt_approved_date: "",
        vs_revision_description:"",
        vs_scheme_ref:""
    });

    const [verificationScheduleReport, {data: vsReportData}] = useLazyQuery(VS_REPORT_BY_ID_QUERY);
    const [createVerificationScheduleReport, { loading: createVsReportLoading, error: createVsReportError, data: createVsReportData }] = useMutation(CREATE_VS_REPORT);
    const [updateVerificationScheduleReport, {loading: updateVsReportLoading, error: updateVsReportError, data: updateVsReportData}] = useMutation(UPDATE_VS_REPORT);

    useEffect(() => {
        if (formMode === "edit" && vsRptId !== "") {
            verificationScheduleReport({ variables: { vsRptId: vsRptId }, errorPolicy: 'all' });
        }
    }, []);

    useEffect(() => {
    if (authContext && authContext.user){
        let result = createUserTypeAndRoleObject(authContext);
        setUserTypeAndRoleObject(result);
    }
    }, [authContext]);

    useEffect(() => {
        let formInitParam = vsReportContext.formInitParam;
        if (formInitParam && formInitParam.hasOwnProperty('vs_rpt_type')) {
            const { vs_rpt_type, vs_id } = formInitParam;
            setIndexFormValues(formInitParam);
            setVsReportTypeId(vs_rpt_type);
            setVsId(vs_id);
        }
    }, [vsReportContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);

    useEffect(() => {
        if (vsReportData && vsReportData.verificationScheduleReport) {
            let vsReport = vsReportData.verificationScheduleReport;

            const { user_fname = "", user_lname = "" } = vsReport.vs_rpt_approved_by ? vsReport.vs_rpt_approved_by : {};
            const approver = (user_fname !== "" && user_lname !== "") ? `${user_fname} ${user_lname}` : "N/A";


            const vsApprovedDate = new Date(parseInt(vsReport.vs_rpt_approved_date));
            const vsRptApprovedDate = formatDateToLocaleDateString(vsApprovedDate);

            let isVsRptPeriodic = vsReport.vs_rpt_is_periodic;

            let vsRptIssuances = vsReport.vs_rpt_issuances ? parseJSONSafely(vsReport.vs_rpt_issuances) : parseJSONSafely("[]");

            let initValue = {
                vs_rpt_is_periodic: vsReport.vs_rpt_is_periodic,
                vs_rpt_title: vsReport.vs_rpt_title,
                vs_rpt_rev_num: vsReport.vs_rpt_rev_num,
                vs_rpt_description: vsReport.vs_rpt_description,
                vs_rpt_summary: vsReport.vs_rpt_summary,
                vs_rpt_scope: vsReport.vs_rpt_scope,
                vs_rpt_statement: vsReport.vs_rpt_statement,
                vs_rpt_improvements: vsReport.vs_rpt_improvements,
                vs_rpt_concerns: vsReport.vs_rpt_concerns,
                vs_rpt_positives: vsReport.vs_rpt_positives,
                vs_rpt_payment_details: vsReport.vs_rpt_payment_details,
                vs_id: vsReport.vs_id ? vsReport.vs_id._id : "",
                vs_rpt_start_date: new Date(parseInt(vsReport.vs_rpt_start_date)),
                vs_rpt_end_date: new Date(parseInt(vsReport.vs_rpt_end_date)),
                vs_rpt_status: vsReport.vs_rpt_status,

                vs_revision_description: vsReport.vs_rpt_revision_description ? vsReport.vs_rpt_revision_description : "",
                vs_scheme_ref: vsReport.vs_rpt_scheme_ref ? vsReport.vs_rpt_scheme_ref  :"",
                vs_rpt_approved_by: approver,
                vs_rpt_approved_date: vsRptApprovedDate !== "" ? vsRptApprovedDate : "N/A"
            }

            setIsRptPeriodic(isVsRptPeriodic);
            setReportIssuanceList(vsRptIssuances);
            setFormInitValues(initValue);
        }
    }, [vsReportData]);

    useEffect(() => {
        if (createVsReportData && createVsReportData.createVerificationScheduleReport) {
            const { success, message } = createVsReportData.createVerificationScheduleReport;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push(`/reporting/report-search`);
            }
        }
    }, [createVsReportData]);

    useEffect(() => {
        if (updateVsReportData && updateVsReportData.updateVerificationScheduleReport) {
            const { success, message } = updateVsReportData.updateVerificationScheduleReport;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push(`/reporting/report-search`);
            }
        }
    }, [updateVsReportData]);

    const handleRemoveReportIssuance = (id) => {
        if (id && id !== "") {
            let issuance = reportIssuanceList.filter(issue => issue.id !== id);
            setReportIssuanceList([...issuance]);
        }
    }

    const handleEditReportIssuance = (id) => {
        if (id && id !== "") {
            let issuance = reportIssuanceList.find(issue => issue.id === id);
            setReportIssuanceFormInitValues(issuance);
            setReportIssuanceFormAction("edit");
            toggleReportIssuanceModal();
        }
    }

    const toggleReportIssuanceModal = () => {
        const modal = document.querySelector('.reportissuancemodal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    const toggleModal = () => {
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    const handleSave = (values, { setSubmitting, vs_report_action }) => {
        submitReportLogic(values, setSubmitting, vs_report_action)           
    };

    const submitReportLogic = (values, setSubmitting, vs_report_action) => {
        const { _id: userId = "" } = authContext.user;

        let isPeriodic = (vsReportTypeId === "1") ? true : false;
        let dhId = indexFormValues.dh_id? indexFormValues.dh_id: "";
        let installationIds = indexFormValues.inst_ids? indexFormValues.inst_ids.map(inst => inst.value): [];
        let vsIds =  indexFormValues.vs_ids? indexFormValues.vs_ids.map(vs => vs.value): [];
        let vsStatusList = indexFormValues.vs_statuses ? JSON.stringify(indexFormValues.vs_statuses) : "[]";

        let reportIssuances = JSON.stringify(reportIssuanceList);

        let vrStartDate = indexFormValues.vr_start_date ? indexFormValues.vr_start_date : "";
        let vrEndDate = indexFormValues.vr_end_date ? indexFormValues.vr_end_date : "";

        if (formMode === 'add' && vsId !== "") {
            let newVsReport = {
                ...(dhId && dhId !== "" && {dh_id: dhId}),
                installation_ids: installationIds,

                vs_rpt_is_periodic: isPeriodic,
                vs_rpt_title: values.vs_rpt_title,
                vs_rpt_description: values.vs_rpt_description,
                vs_rpt_summary: values.vs_rpt_summary,
                vs_rpt_scope: values.vs_rpt_scope,
                vs_rpt_statement: values.vs_rpt_statement,
                vs_rpt_improvements: values.vs_rpt_improvements,
                vs_rpt_concerns: values.vs_rpt_concerns,
                vs_rpt_positives: values.vs_rpt_positives,
                vs_rpt_payment_details: values.vs_rpt_payment_details,

                vs_ids: vsIds,
                vs_status_list: vsStatusList,
                vs_rpt_issuances: reportIssuances,

                vs_rpt_start_date: vrStartDate,
                vs_rpt_end_date: vrEndDate,

                vs_rpt_revision_description: values.vs_revision_description,
                vs_rpt_scheme_ref: values.vs_scheme_ref,

                vs_rpt_action: vs_report_action ? vs_report_action : vsReportAction,

                vs_rpt_added_date: new Date(),
                vs_rpt_added_id: userId,

                vs_rpt_modified_date: new Date(),
                vs_rpt_modified_by: userId,

                vs_rpt_archived: false
            }
            createVerificationScheduleReport({ variables: { vsRpt: newVsReport } });
        }

        if (formMode === 'edit' && vsRptId !== "" && userId !== "") {
            let vsReport = {
                ...(dhId && dhId !== "" && {dh_id: dhId}),
                installation_ids: installationIds,

                vs_rpt_is_periodic: isPeriodic,
                vs_rpt_title: values.vs_rpt_title,
                vs_rpt_rev_num: values.vs_rpt_rev_num,
                vs_rpt_description: values.vs_rpt_description,
                vs_rpt_summary: values.vs_rpt_summary,
                vs_rpt_scope: values.vs_rpt_scope,
                vs_rpt_statement: values.vs_rpt_statement,
                vs_rpt_improvements: values.vs_rpt_improvements,
                vs_rpt_concerns: values.vs_rpt_concerns,
                vs_rpt_positives: values.vs_rpt_positives,
                vs_rpt_payment_details: values.vs_rpt_payment_details,

                vs_ids: vsIds,
                vs_status_list: vsStatusList,

                vs_rpt_issuances: reportIssuances,

                vs_rpt_modified_date: new Date(),
                vs_rpt_modified_by: userId,

                vs_rpt_start_date: vrStartDate,
                vs_rpt_end_date: vrEndDate,

                vs_rpt_revision_description: values.vs_revision_description,
                vs_rpt_scheme_ref: values.vs_scheme_ref,

                vs_rpt_action: vs_report_action ? vs_report_action : vsReportAction
            }
            updateVerificationScheduleReport({ variables: { vsRptId: vsRptId, userId: userId, vsRpt: vsReport } });
        }
        vsReportContext.clearContext(); 
        setSubmitting(false);
    }

    if (createVsReportLoading || updateVsReportLoading) {
        return (
            <PageSpinner displayText={vsReportAction === REPORT_ACTION_SAVE ? "saving form" : "submitting form"} />
        )
    }

    if (createVsReportError || updateVsReportError ) {
        return (
          <span>Something went wrong while submitting the form</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase"> {formMode === "add" ? "Create" : "Edit"} {(vsReportTypeId === "1" || isRptPeriodic) ? "Periodic Verification" : "Asset Verification" } {"Report"}</h1>
            </div>

            <Formik
                validateOnChange={enableValidation}
                validateOnBlur={enableValidation}
                enableReinitialize={true}
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={validation(vsReportTypeId)}
                onSubmit={(values, { setSubmitting }) => {
                    submitReportLogic(values, setSubmitting, null);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    setFieldTouched,
                    setSubmitting,
                    submitForm,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="my-4 flex">
                            <Link to={{
                                pathname: `/reporting/vs-report/report-index/${formMode}` + (vsRptId !== "" ? `/${vsRptId}` : ""),
                                state: { formMode: formMode, isFromStep2: true }
                            }}
                                className="button-red mr-2">
                                <FontAwesomeIcon icon={['fas', 'angle-left']}
                                    className="text-white" /> Back
                            </Link>
                        </div>

                        <div className="block overflow-x-auto">
                            <table className="table-report w-full mb-4 border" style={{minWidth:'600px'}}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Report Title</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="mt-2">
                                                <textarea 
                                                    name="vs_rpt_title" 
                                                    id="vs_rpt_title" 
                                                    onChange={(event) => {handleChange(event)}}
                                                    onBlur={handleBlur}
                                                    value={values.vs_rpt_title}
                                                    className="form-control w-full"
                                                />
                                                <FormError touched={touched.vs_rpt_title} message={errors.vs_rpt_title} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{minWidth:'600px'}}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Verification Scheme Ref(s)</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="mt-2">
                                                <textarea 
                                                    name="vs_scheme_ref" 
                                                    id="vs_scheme_ref" 
                                                    onChange={(event) => {handleChange(event)}}
                                                    onBlur={handleBlur}
                                                    value={values.vs_scheme_ref}
                                                    className="form-control w-full"
                                                />
                                                <FormError touched={touched.vs_scheme_ref} message={errors.vs_scheme_ref} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{minWidth:'600px'}}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Revision Description</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="mt-2">
                                                <textarea 
                                                    name="vs_revision_description" 
                                                    id="vs_revision_description" 
                                                    onChange={(event) => {handleChange(event)}}
                                                    onBlur={handleBlur}
                                                    value={values.vs_revision_description}
                                                    className="form-control w-full"
                                                />
                                                <FormError touched={touched.vs_revision_description} message={errors.vs_revision_description} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="7">Report Issuances
                                            <span className="float-right">
                                                <FontAwesomeIcon icon={['fas', 'plus']} className="text-white cursor-pointer" onClick={() => { 
                                                    setReportIssuanceFormAction("add");
                                                    toggleReportIssuanceModal()
                                                    }} />
                                            </span>
                                        </th>
                                    </tr>
                                    {reportIssuanceList.map((issue, index) =>
                                        <React.Fragment key={index}>
                                            <tr>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Issue Number</th>
                                                <td>{issue.issue_number}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Document Holder</th>
                                                <td>{issue.document_holder}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Location</th>
                                                <td>{issue.location}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">
                                                    <span className="float-right flex">
                                                        <span className="mr-5">
                                                            <FontAwesomeIcon icon={faPenAlt} className="text-white cursor-pointer" onClick={() => handleEditReportIssuance(issue.id)} />
                                                        </span>
                                                        <span>
                                                            <FontAwesomeIcon icon={faTrashAlt} className="text-white cursor-pointer" onClick={() => handleRemoveReportIssuance(issue.id)} />
                                                        </span>
                                                    </span>
                                                </th>
                                            </tr>
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>
                            
                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    {(vsReportTypeId === "1") &&
                                        <tr>
                                            <th className="bg-blue-900 font-sans font-semibold text-white">Start Date</th>
                                            <td className="bg-gray-100 font-sans">
                                                <div>
                                                    {indexFormValues.vr_start_date? formatDateToLocaleDateString(indexFormValues.vr_start_date) : "N/A"}
                                                </div>
                                            </td>
                                            <th className="bg-blue-900 font-sans font-semibold text-white">End Date</th>
                                            <td className="bg-gray-100 font-sans">
                                                <div>
                                                    {indexFormValues.vr_end_date? formatDateToLocaleDateString(indexFormValues.vr_end_date) : "N/A"}
                                                </div>
                                            </td>
                                        </tr>
                                    }

                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Approved By</th>
                                        <td>
                                            <div className="mt-5">
                                                <input
                                                    disabled
                                                    readOnly
                                                    type="text"
                                                    className="form-control block w-full"
                                                    name="vs_rpt_approved_by"
                                                    id="vs_rpt_approved_by"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.vs_rpt_approved_by}
                                                />
                                                {/*<FormError touched={touched.vs_rpt_description} message={errors.vs_rpt_description} />*/}
                                            </div>
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Date</th>
                                        <td>
                                            <div className="mt-10">
                                                <DatePicker
                                                    readOnly
                                                    disabled
                                                    id="vs_rpt_date"
                                                    selected={values.vs_rpt_date}
                                                    dateFormat="dd MMMM Y"
                                                    name="vs_rpt_date"
                                                    onChange={date => setFieldValue('vs_rpt_date', date)}
                                                    onBlur={handleBlur}
                                                    value={values.vs_rpt_approved_date}
                                                    className="form-control block w-full"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    autoComplete="off"
                                                />
                                                <FormError touched={touched.vs_rpt_date} message={errors.vs_rpt_date} />
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Authors
                                            <span className="float-right">
                                                <FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} />
                                            </span>
                                        </th>
                                    </tr>
                                    {[].length !== 0 ? [].map((fnd, index) =>
                                        <React.Fragment key={index}>
                                            <tr>
                                                <th className="bg-blue-900 font-sans font-semibold text-white w-1/6">Finding Ref</th>
                                                <td className="w-1/4">{fnd.finding_ref}</td>
                                                <th className="bg-blue-900 font-sans font-semibold text-white w-1/4">Synopsis</th>
                                                <td className="w-1/4">
                                                    <p className="font-sans">{fnd.finding_synopsis}</p>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ) :
                                        <tr>
                                            <td className="bg-gray-100" colSpan="6">No Authors To Display</td>
                                        </tr>}
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Description <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>

                                            <div>
                                                <TextEditor
                                                    name="vs_rpt_description"
                                                    id="vs_rpt_description"
                                                    onChange={desc => setFieldValue('vs_rpt_description', desc)}
                                                    onBlur={touched => setFieldTouched('vs_rpt_description', touched)}
                                                    value={values.vs_rpt_description}
                                                    wrapper="w-full"
                                                />
                                                <FormError touched={touched.vs_rpt_description} message={errors.vs_rpt_description} />
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Executive Summary <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>

                                            <div>
                                                <TextEditor
                                                    name="vs_rpt_summary"
                                                    id="vs_rpt_summary"
                                                    onChange={sum => setFieldValue('vs_rpt_summary', sum)}
                                                    onBlur={touched => setFieldTouched('vs_rpt_summary', touched)}
                                                    value={values.vs_rpt_summary}
                                                    wrapper="w-full"
                                                />
                                                <FormError touched={touched.vs_rpt_summary} message={errors.vs_rpt_summary} />
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Extent of Verification <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>

                                            <div>
                                                <TextEditor
                                                    name="vs_rpt_scope"
                                                    id="vs_rpt_scope"
                                                    onChange={scope => setFieldValue('vs_rpt_scope', scope)}
                                                    onBlur={touched => setFieldTouched('vs_rpt_scope', touched)}
                                                    value={values.vs_rpt_scope}
                                                    wrapper="w-full"
                                                />
                                                <FormError touched={touched.vs_rpt_scope} message={errors.vs_rpt_scope} />
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Additional Information <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <TextEditor
                                                    name="vs_rpt_statement"
                                                    id="vs_rpt_statement"
                                                    onChange={statement => setFieldValue('vs_rpt_statement', statement)}
                                                    onBlur={touched => setFieldTouched('vs_rpt_statement', touched)}
                                                    value={values.vs_rpt_statement}
                                                    wrapper="w-full"
                                                />
                                                <FormError touched={touched.vs_rpt_statement} message={errors.vs_rpt_statement} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Opportunities for Improvement <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <TextEditor
                                                    name="vs_rpt_improvements"
                                                    id="vs_rpt_improvements"
                                                    onChange={improvements => setFieldValue('vs_rpt_improvements', improvements)}
                                                    onBlur={touched => setFieldTouched('vs_rpt_improvements', touched)}
                                                    value={values.vs_rpt_improvements}
                                                    wrapper="w-full"
                                                />
                                                <FormError touched={touched.vs_rpt_improvements} message={errors.vs_rpt_improvements} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Areas of Concern <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <TextEditor
                                                    name="vs_rpt_concerns"
                                                    id="vs_rpt_concerns"
                                                    onChange={improvements => setFieldValue('vs_rpt_concerns', improvements)}
                                                    onBlur={touched => setFieldTouched('vs_rpt_concerns', touched)}
                                                    value={values.vs_rpt_concerns}
                                                    wrapper="w-full"
                                                />
                                                <FormError touched={touched.vs_rpt_concerns} message={errors.vs_rpt_concerns} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Positives From <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>

                                            <div>
                                                <TextEditor
                                                    name="vs_rpt_positives"
                                                    id="vs_rpt_positives"
                                                    onChange={improvements => setFieldValue('vs_rpt_positives', improvements)}
                                                    onBlur={touched => setFieldTouched('vs_rpt_positives', touched)}
                                                    value={values.vs_rpt_positives}
                                                    wrapper="w-full"
                                                />
                                                <FormError touched={touched.vs_rpt_positives} message={errors.vs_rpt_positives} />
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Invoicing / Payment Details <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>

                                            <div>
                                                <TextEditor
                                                    name="vs_rpt_payment_details"
                                                    id="vs_rpt_payment_details"
                                                    onChange={payment => setFieldValue('vs_rpt_payment_details', payment)}
                                                    onBlur={touched => setFieldTouched('vs_rpt_payment_details', touched)}
                                                    value={values.vs_rpt_payment_details}
                                                    wrapper="w-full"
                                                />
                                                <FormError touched={touched.vs_rpt_payment_details} message={errors.vs_rpt_payment_details} />
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table> */}

                        </div>

                        <button
                            disabled={isSubmitting}
                            className={`${"mt-3 mb-2 mr-1 button-red capitalize" + (isSubmitting ? " cursor-not-allowed" : "")}`}
                            name="save"
                            value="save"
                            type="button"
                            onClick={() => {
                                setEnableValidation(false); 
                                setVsReportAction(REPORT_ACTION_SAVE);
                                handleSave(values, {setSubmitting, vs_report_action: REPORT_ACTION_SAVE});
                            }}
                        >Save
                        </button>

                        <button
                            disabled={isSubmitting}
                            className={`${"mt-3 mb-2 mr-1 button-red capitalize" + (isSubmitting ? " cursor-not-allowed" : "")}`} 
                            name="submit" 
                            value="submit"
                            type="button"
                            onClick={() => {
                                setEnableValidation(true); 
                                setVsReportAction(REPORT_ACTION_REVIEW);
                                setTimeout(() => { submitForm() }, 200);
                            }}>
                            Submit
                        </button>
                        <button type="submit" disabled={isSubmitting} style={{ display: "none" }}>
                            Hidden Submit
                        </button>
                    </form>
                )}
            </Formik>

            {/* HELP TIP */}
            <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

                    <h3 className="mb-2 text-xl text-blue-900 font-bold">Help Tip</h3>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultricies nunc id vehicula pulvinar. Sed vitae luctus purus. Integer feugiat nisi porta lacus cursus, sed sollicitudin orci mattis. Praesent euismod ornare tortor eget aliquam. Vivamus fermentum convallis erat, at ultrices odio. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>

                </div>
            </div>
            {/* HELP TIP END */}

              {/* Report Issuance Modal */}
              <div className="reportissuancemodal modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-10">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleReportIssuanceModal()}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
                    <table className="table-report w-full mb-4 border">
                        <tbody>
                            <tr>
                                <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="1">{reportIssuanceFormAction === "edit" ? "Edit" : "Add"} Report Issuance Detail</th>
                            </tr>
                            <tr>
                                <td>
                                    <Formik
                                        enableReinitialize
                                        initialValues={reportIssuanceFormInitValues}
                                        validationSchema={reportIssuanceValidation()}
                                        onSubmit={(values, { resetForm }) => {
                                            resetForm({});
                                            let respId = uuidv4();

                                            if (reportIssuanceFormAction === "add") {
                                                let tempValues = { 
                                                    ...values, 
                                                    id: respId,  
                                                };
                                                setReportIssuanceList([...reportIssuanceList, tempValues]);
                                                setReportIssuanceFormAction("");
                                            }

                                            if (reportIssuanceFormAction === "edit") {
                                                let tempValues = { 
                                                    ...values, 
                                                };

                                                const { id = "" } = values;
                                                let tempList = reportIssuanceList;
                                                let fndIndex = tempList.findIndex(fnd => fnd.id === id);
                                                tempList[fndIndex] = tempValues;
                                                setReportIssuanceList([...tempList]);
                                                setReportIssuanceFormAction("");
                                                setReportIssuanceFormInitValues(defaultReportIssuanceFormInitValues);
                                            }

                                            toggleReportIssuanceModal();
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            setFieldValue,
                                            handleSubmit,
                                            setFieldTouched,
                                            isSubmitting
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div>
                                                    <label htmlFor="issue_number" className="block mb-1 text-blue-900 font-semibold">Issue Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control block w-full"
                                                        name="issue_number"
                                                        id="issue_number"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.issue_number}
                                                    />
                                                    <FormError touched={touched.issue_number} message={errors.issue_number} />
                                                </div>

                                                <div>
                                                    <label htmlFor="document_holder" className="block mb-1 text-blue-900 font-semibold">Document Holder</label>
                                                    <input
                                                        type="text"
                                                        className="form-control block w-full"
                                                        name="document_holder"
                                                        id="document_holder"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.document_holder}
                                                    />
                                                    <FormError touched={touched.document_holder} message={errors.document_holder} />
                                                </div>

                                                <div>
                                                    <label htmlFor="location" className="block mb-1 text-blue-900 font-semibold">Location</label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control block w-full"
                                                        name="location"
                                                        id="location"
                                                        placeholder="Location"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.location}
                                                        rows="7"
                                                    />
                                                    <FormError touched={touched.location} message={errors.location} />
                                                </div>

                                                <button type="submit" className="mt-3 button-red capitalize">{reportIssuanceFormAction === "edit" ? "Edit" : "Add"} Report Issuance</button>
                                            </form>
                                        )}
                                    </Formik>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Linked Finding Responses Modal */}
        </div>
    );
}

export default AddEditVerificationScheduleReport;