import React,  {createContext} from 'react';

const VerificationContext = createContext();

const STEP_1_FORM_DATA = "step1FormData";
const STEP_2_FORM_DATA = "step2FormData";
const STEP_3_FORM_DATA = "step3FormData";
const ORIGINAL_STATE_DATA = "originStateData";

function VerificationContextProvider({ children }) {
    const [step1FormData, setStep1FormData] = useSessionStorage(STEP_1_FORM_DATA, {});
    const [step2FormData, setStep2FormData] = useSessionStorage(STEP_2_FORM_DATA, {});
    const [step3FormData, setStep3FormData] = useSessionStorage(STEP_3_FORM_DATA, {});
    const [originStateData, setOriginStateData] = useSessionStorage(ORIGINAL_STATE_DATA, {});

    const updateStep1FormData = (step1Data) => {
        if (step1Data) {
            setStep1FormData(step1Data);
        }
    }

    const updateStep2FormData = (step2Data) => {
        if (step2Data) {
            setStep2FormData(step2Data);
        }
    }

    const updateStep3FormData = (step3Data) => {
        if (step3Data) {
            setStep3FormData(step3Data);
        }
    }

    const updateOriginStateData = (originData) => {
        if (originData) {
            setOriginStateData(originData);
        }
    }

    const clearFormData = () => {
        sessionStorage.removeItem(STEP_1_FORM_DATA);
        setStep1FormData({});

        sessionStorage.removeItem(STEP_2_FORM_DATA);
        setStep2FormData({});

        sessionStorage.removeItem(STEP_3_FORM_DATA);
        setStep3FormData({});

        sessionStorage.removeItem(ORIGINAL_STATE_DATA);
        setOriginStateData({});
    }

    return (
        <VerificationContext.Provider value={{
            step1FormData: step1FormData,
            step2FormData: step2FormData,
            step3FormData: step3FormData,
            originStateData: originStateData,
            updateStep1FormData: updateStep1FormData,
            updateStep2FormData: updateStep2FormData,
            updateStep3FormData: updateStep3FormData,
            updateOriginStateData: updateOriginStateData,
            clearFormData: clearFormData
        }}>
            {children}
        </VerificationContext.Provider>
    );
}

// Custom hook to interact with session storage
export function useSessionStorage(key, initialValue) {
    const [state, setState] = React.useState(() => {
      // Retrieve data from session storage
      const storedValue = sessionStorage.getItem(key);
      if (storedValue !== null) {
        return JSON.parse(storedValue);
      } else {
        return initialValue;
      }
    });
  
    // Update state and session storage when state changes
    React.useEffect(() => {
      sessionStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
  
    return [state, setState];
  }

export {VerificationContext, VerificationContextProvider};


