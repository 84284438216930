/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useContext, useEffect} from 'react';
import ABSLogo from './../../images/abs_logo.png';
import ABSVmLogo from './../../images/abs-vm-header.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {gql, useQuery} from '@apollo/client';
import {AuthContext} from '../../context/authContext';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import 'rc-dropdown/assets/index.css';
import {
  NOTIFICATION_STATUS_UNAPPROVED,
  USER_ROLE_APPROVER,
  USER_ROLE_ADMIN
} from '../../constants/';

const NOTIFICATIONS_QUERY = gql`
    query NotificationSearch($searchParam: NotificationSearchInput!)  {
      notificationSearch(searchParam: $searchParam) {
            _id
            priority
            status
        }
    }
`;

const {REACT_APP_TOGGLE_FEATURE_USER = undefined} = process.env;

const Header = ({logoutHandler, accountInfo}) => {

  const [ navActive , setNavActive ] = useState(true);
  const [userId, setUserId] = useState('');
  const[userType, setUserType] = useState('');
  const[userRole, setUserRole] = useState('');

  const [notIconColorCode, setNotIconColorCode] = useState("");

  const authContext = useContext(AuthContext);

  const { data } = useQuery(NOTIFICATIONS_QUERY,
    {      
      variables: { searchParam: { status: NOTIFICATION_STATUS_UNAPPROVED } },      
      errorPolicy: 'all',
      pollInterval: 1000,
      skip: !(userRole === USER_ROLE_APPROVER || userRole === USER_ROLE_ADMIN)
    });
  
  useEffect(() => {
    if (data && data.notificationSearch) { 
      let iconClass = formatNotificationIcon(data.notificationSearch)
      setNotIconColorCode(iconClass);
    }
  }, [data])

  useEffect(() => {
    if (authContext && authContext.user) {
      setUserId(authContext.user._id);
      if (authContext.user.user_role) {
        const { user_role_display = '' } = authContext.user.user_role;
        setUserRole(user_role_display);
      }

      if (authContext.user.user_type) {
        const { user_type_display_name = '' } = authContext.user.user_type;
        setUserType(user_type_display_name);
      }
    }

  }, [authContext]);

  const menu = (
    <Menu id="settings-cog-menu" style={{backgroundColor:'#002a4e', borderColor: '#002a4e', borderRadius: 0}}>
      {userType === 'ABS' &&
      <MenuItem key="1"><Link to="/access-log" className="flex w-full font-sans text-sm text-white cursor-pointer hover:underline">Access Log</Link></MenuItem>}
      {userType === 'ABS' &&
      <MenuItem key="2"><Link to="/system-settings" className="flex w-full my-2 font-sans text-sm text-white cursor-pointer hover:underline">System Settings</Link></MenuItem>}
      <MenuItem key="3"><p onClick={() => logoutHandler()} className="flex font-sans text-sm text-white cursor-pointer hover:underline">Logout</p></MenuItem>
    </Menu>
  );

  return (
    <div>
      <div className="flex flex-col md:flex-row bg-blue-900 py-2 px-4">
        <div className="w-full md:w-1/2">
          <img src={ABSVmLogo} style={{maxWidth:280}} className="mt-1" alt="ABS Verification Manager" />
        </div>
        <div className="flex flex-row md:justify-end w-full md:w-1/2 mt-4 md:mt-0 text-left">

          <p className="font-sans text-white inline mr-auto md:mr-3">Hello, {accountInfo && accountInfo.name } </p>

          {userType === 'ABS' && (userRole === USER_ROLE_APPROVER ||userRole === USER_ROLE_ADMIN) ?
            <Link to="/notifications" title="Notifications">
              {notIconColorCode !== "" &&
                <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} size="lg" className={`mr-3 ${notIconColorCode}`} />
              }
            </Link> : <></>
          }
          
          <Link to={`/user/view/${userId}`} title="View My Profile">
            <FontAwesomeIcon icon={['far', 'user']} size="lg" className="mr-3 text-white" />
          </Link>

          <div className="relative inline-block">
            <Dropdown
              trigger={['click']}
              overlay={menu}
              animation="slide-up"
            >
              <FontAwesomeIcon icon={['fas', 'cog']} size="lg" className="mr-3 text-white cursor-pointer"/>
            </Dropdown>

          </div>

        </div>
      </div>
      <div className="sm:flex-row lg:flex py-4 px-4 bg-white">
        <div className="z-10">
          <Link to="/"><img src={ABSLogo} style={{ width: 175 }} alt="ABS Logo" /></Link>
        </div>

        {renderNav(userType, navActive, setNavActive)}
        
      </div>
    </div>
  )
}

function renderNav(userType, navActive, setNavActive) {
  if (userType === 'ABS') {
    return (
      <nav className="w-full lg:text-right">

        <div className="block lg:hidden absolute top-0 right-0 mt-20 mr-8">
          <FontAwesomeIcon icon={['fas', 'bars']} size="lg" className="text-blue-900 cursor-pointer" onClick={() => setNavActive(!navActive)} />
        </div>

        <ul className={navActive ? "hidden lg:inline-flex mt-4 py-4 lg:py-0 text-blue-900 font-semibold leading-loose lg:leading-normal font-sans list-none" : "lg:inline-flex mt-4 py-4 lg:py-0 text-blue-900 font-semibold leading-loose lg:leading-normal font-sans list-none"}>
          {REACT_APP_TOGGLE_FEATURE_USER !== 'off' && (<li className="mr-4"><Link to="/users" className="hover:text-red-900">User Management</Link></li>)}
          <li className="mr-4"><Link to="/user-competencies" className="hover:text-red-900">Competency Manager</Link></li>
          <li className="mr-4"><Link to="/dutyholders" className="hover:text-red-900">Duty Holder Management</Link></li>
          <li className="mr-3"><Link to="/notices" className="hover:text-red-900">Notices</Link></li>
        </ul>
      </nav>
    )
  }
}

function formatNotificationIcon(list) {
  let iconClass = "text-white";

  if (list && list.length) {

    let tempList = list.filter(notif => notif.status === 'unapproved');

    if (tempList.some(not => not.priority === "High")) {
        iconClass = "text-red-900";
    };

    if (tempList.some(not => ((not.priority === "Low") || (not.priority === "Medium")))) {
      iconClass = "text-yellow-900";
    };
  };

  return iconClass;
}

export default Header;
