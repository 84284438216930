/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import Table from '../global/Table';
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from '../global/pageSpinner';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";
import { VerificationContext } from "../../context/verificationContext";
import {
    NATURE_SYSTEM_VARIABLE_TYPE_ID,
    VA_FREQUENCY_SYSTEM_VARIABLE_TYPE_ID,
    ACTIVITY_STATUS_SYSTEM_VARIABLE_TYPE_ID,
    TEMP_NOT_SCHEDULED_ID,
    TEMP_OVERDUE_ID,
    VA_CATEGORY_ORININAL
} from '../../constants/';

const Validation = Yup.object().shape({});

const ACTIVITY_SEARCH_QUERY = gql`
query ActivitySearch($param: ActivitySearchParam!){
    activitySearch(param: $param){
    id
    va_id
    schedule_title
    schedule_start_date
    schedule_end_date
    sece_title
    sece_ref
    sece_group
    ps_ref
    activity_ref
    activity_short_desc
    activity_nature
    activity_freq
    activity_class
    activity_onshore_offshore
    activity_abs_ivb
    activity_current_status
    activity_report_current_status
    is_activity_overdue
    }
}`;

const RA_BY_INSTALL_IDS_QUERY = gql`
query ReferenceActivitiesByInstallationIds($installationIds: [ID!]){
    referenceActivitiesByInstallationIds(installationIds: $installationIds) {
        _id
        ra_title
        ra_archived
    }
}`;

const SECE_SEARCH_QUERY = gql`
query SeceSearch($param: SeceSearch!){
    seceSearch(param: $param){
        _id
        sece_title
        sece_order_no
        sece_group
        sece_archived
        sece_status
        sece_client_ref
    }
}`;

const PS_SEARCH_QUERY = gql`
query PerformanceStandardSearch($param: PerformanceStandardSearch!){
    performanceStandardSearch(param: $param){
        _id
        ps_title
        ps_archived
        ps_status
    }
}`;

const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
            _id
            sysvar_title
            sysvar_archived
            sysvartype_id{
                _id
                sysvartype_title
            }
        }
}`;

const vaFilterFormDefaultValues = {
    sece_id: "",
    sece_group: "",
    ps_id: "",
    nature_id: "",
    onshore_offshore: "",
    periodicity: "",
    class_item: "",
    status_id: "",
    va_completion_status: ""
}

const classes = [
    { id: "1", value: "Yes" },
    { id: "2", value: "No" },
    { id: "3", value: "Partial" }
]

const onOffshoreList = [
    {id: "1", value: "Onshore" },
    {id: "2", value: "Offshore" }
]

// const vaStatusList = [
//     {id: "1", value: "Ongoing" },
//     {id: "2", value: "Pending" },
//     {id: "3", value: "Completed" },
// ]

const AddEditVerificationScheduleStep2 = () => {
    const [verificationActivityList, setVerificationActivityList] = useState([]);
    const [referenceActivityList, setReferenceActivityList] = useState([]);

    const [seceList, setSeceList] = useState([]);
    const [seceGroupList, setSeceGroupList] = useState([]);
    const [psList, setPsList] = useState([]);

    const [natureList, setNatureList] = useState([]);
    const [frequencyList, setFrequencyList] = useState([]);
    const [activityStatusList, setActivityStatusList] = useState([]);

    const [onshoreOffshoreList, setOnshoreOffshoreList] = useState([]);
    const [classList, setClassList] = useState([]);
    // const [statusList, setStatusList] = useState([]);

    const [selectedSeceId, setSelectedSeceId] = useState("");

    const [formInitValues, setFormInitValues] = useState({va_ids: [], ra_ids: []});
    const [vaFilterFormInitValue, setVaFilterFormInitValue] = useState({});
    const [hasFilterParam, setHasFilterParam] = useState(false);

    //form add or edit state passed from link
    const { formMode, vsId = "" } = useParams();
    const history = useHistory();
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const authContext = useContext(AuthContext);
    const verificationContext = useContext(VerificationContext);

    const [activitySearch, {loading, error, data: vaSearchData }] = useLazyQuery(ACTIVITY_SEARCH_QUERY);
    const [referenceActivitiesByInstallationIds, { data: raByInstallIdsData }] = useLazyQuery(RA_BY_INSTALL_IDS_QUERY);

    const [seceSearch, { data: seceSearchData }] = useLazyQuery(SECE_SEARCH_QUERY);
    const [performanceStandardSearch, { data: psSearchData }] = useLazyQuery(PS_SEARCH_QUERY);

    const [systemVariablesBySysVarTypeIds, {data: sysVarsData}] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);

    const sysVarTypeIds = [
        NATURE_SYSTEM_VARIABLE_TYPE_ID, 
        VA_FREQUENCY_SYSTEM_VARIABLE_TYPE_ID,
        ACTIVITY_STATUS_SYSTEM_VARIABLE_TYPE_ID
    ];

    useEffect(() => {
        if (authContext && authContext.user){
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);
    
    useEffect(() => {
        const { vs_title = "", installation_ids = [], sece_ids = [] } = verificationContext.step1FormData;
        //Refreshing the browser causes the loss of context data. So at the moment it redirects to the Step 1 page
        
        if (vs_title === "") {
            moveToStep1(formMode, vsId);
        }

        if (installation_ids.length) {
            referenceActivitiesByInstallationIds({ variables: { installationIds: installation_ids }, errorPolicy: 'all' });
        };
        if (sece_ids.length) {
            setVerificationActivityList([]);
            activitySearch({ variables: { param: { sece_ids: sece_ids, activity_category: VA_CATEGORY_ORININAL, activity_archived : false } }, errorPolicy: 'all' });
            seceSearch({ variables: { param: { sece_ids: sece_ids } }, errorPolicy: 'all' });
        };
        systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });
        setInitFormValuesOnBackFromNav();
        setVaFilterFormInitValue(vaFilterFormDefaultValues);
        setClassList(classes);
        setOnshoreOffshoreList(onOffshoreList);
        //setStatusList(vaStatusList);
    }, []);

    useEffect(() => {
        fetchMultiSelectData(selectedSeceId)
    }, [selectedSeceId]);

    useEffect(() => {
        if (seceSearchData && seceSearchData.seceSearch) {
            let seceList = seceSearchData.seceSearch;
            const seceGroupSet = new Set(seceList.map(sece => sece.sece_group));
            const seceGroupList = Array.from(seceGroupSet);
            setSeceGroupList(seceGroupList);
            setSeceList(seceList);
        }
    }, [seceSearchData]);

    useEffect(() => {
        if (psSearchData && psSearchData.performanceStandardSearch) {
            let tempList = psSearchData.performanceStandardSearch;
            let filteredList = tempList.filter(ps => ps.ps_archived !== true);
            setPsList(filteredList);
        }
    }, [psSearchData]);

    useEffect(() => {
        if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
            formatSysVarDataAndUpdateStates(sysVarsData.systemVariablesBySysVarTypeIds,
                setNatureList,
                setFrequencyList,
                setActivityStatusList,
                NATURE_SYSTEM_VARIABLE_TYPE_ID,
                VA_FREQUENCY_SYSTEM_VARIABLE_TYPE_ID, 
                ACTIVITY_STATUS_SYSTEM_VARIABLE_TYPE_ID);
        }
    }, [sysVarsData]);

    useEffect(() => {
        if (vaSearchData && vaSearchData.activitySearch) {
            let valist = vaSearchData.activitySearch;
            setVerificationActivityList(valist);
        }
    }, [vaSearchData]);

    useEffect(() => {
        if (raByInstallIdsData && raByInstallIdsData.referenceActivitiesByInstallationIds) {
            //const { step2FormData } = verificationContext;
            let ralist = raByInstallIdsData.referenceActivitiesByInstallationIds;
            setReferenceActivityList(ralist);
            // if (step2FormData.ra_ids.length === 0 ) {
            //     let raIdList = []
            //     ralist.forEach(ra => {raIdList.push(ra._id); })
            //     setFormInitValues({ ...formInitValues, ra_ids: raIdList });
            // }
        }
    }, [raByInstallIdsData]);

    useEffect(() => {
        let step2FormData = {
            va_ids: formInitValues.va_ids,
            ra_ids: formInitValues.ra_ids
        };
        verificationContext.updateStep2FormData(step2FormData);
        //refetch();
    }, [formInitValues]);

    const fetchMultiSelectData = (seceId) => {  
        if (seceId && seceId !== "") {
            let seceIdList = [seceId];
            let param = { sece_ids: seceIdList }
            performanceStandardSearch({ variables: { param: param }, errorPolicy: 'all' });
        }
    }

    const handleSeceChange = (event) => {
        let value = event.target.value;
        setSelectedSeceId(value);
        setPsList([]);
    };

    const setInitFormValuesOnBackFromNav = () => {
        const { step2FormData } = verificationContext;
        if (step2FormData.va_ids) {
            let initValues = {
                va_ids: step2FormData.va_ids,
                ra_ids: step2FormData.ra_ids
            }
            setFormInitValues(initValues);
        }
    }

    const handleSelectAllVA = (vaList) => {
        let vaIdList = vaList.map(v => v.va_id);
        setFormInitValues({ ...formInitValues, va_ids: vaIdList });
    }

    const handleRemoveAllVA = () => {
        setFormInitValues({ ...formInitValues, va_ids: [] });
    }

    const handleVerificationActivityChange = (event, initValues, va_id) => {
        let checked = event.target.checked;
        let vaId = va_id;
        let vaIdList = initValues.va_ids;
        let tempIdList = [];
        if (checked) {
            vaIdList.push(vaId);
            tempIdList = vaIdList;
        } else {
            tempIdList = vaIdList.filter(id => id !== vaId)
        }
        setFormInitValues({ ...formInitValues, va_ids: tempIdList });
    }

    const handleReferenceActivityChange = (event, initValues, ra) => {
        let checked = event.target.checked;
        let raId = ra._id;
        let raIdList = initValues.ra_ids;
        let tempIdList = [];
        if (checked) {
            raIdList.push(raId);
            tempIdList = raIdList;
        } else {
            tempIdList = raIdList.filter(id => id !== raId)
        }
        setFormInitValues({ ...formInitValues, ra_ids: tempIdList });
    }

    const isVaChecked = (initFormValues, va_id) => {
        let vaIdList = initFormValues.va_ids;
        let result = vaIdList.some(id => id === va_id)
        return result;
    };

    const isRaChecked = (initFormValues, ra) => {
        let raIdList = initFormValues.ra_ids;
        let result = raIdList.some(id => id === ra._id)
        return result;
    };

    const moveToStep1 = (formMode, vsId) => {
        if (formMode === "add") {
            history.push(`/verification/${formMode}/step1`, { formMode: formMode });
        } else {
            const { vs_status = "", origin = "" } = verificationContext.originStateData;
            history.push(`/verification/${formMode}/step1/${vsId}`, { formMode: formMode, vs_status: vs_status, origin: origin });
        }
    };

    const moveToStep3 = (formMode, vsId) => {
        if (formMode === "add") {
            history.push(`/verification/${formMode}/step3`, { formMode: formMode });
        } else {
            history.push(`/verification/${formMode}/step3/${vsId}`, { formMode: formMode });
        }
    };

    function toggleModal() {
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    const clearFilter = () => {
        setVaFilterFormInitValue(vaFilterFormDefaultValues);
        setSelectedSeceId("");
        setPsList([]);
        setHasFilterParam(false);

        const { sece_ids = [] } = verificationContext.step1FormData;
        if (sece_ids.length) {
            setVerificationActivityList([]);
            activitySearch({ variables: { param: { sece_ids: sece_ids, activity_category:VA_CATEGORY_ORININAL, activity_archived : false } }, errorPolicy: 'all' });
        };

        toggleModal();
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'SECE Ref.',
                accessor: 'sece_ref', // accessor is the "key" in the data
            },
            {
                Header: 'SECE Name',
                accessor: 'sece_title', // accessor is the "key" in the data
            },
            {
                Header: 'PS Ref.',
                accessor: 'ps_ref', // accessor is the "key" in the data
            },
            {
                Header: 'VA Ref.',
                accessor: 'activity_ref', // accessor is the "key" in the data
            },
            {
                Header: 'Short Description',
                accessor: 'activity_short_desc', // accessor is the "key" in the data
            },
            {
                Header: 'Nature',
                accessor: 'activity_nature', // accessor is the "key" in the data
            },
            {
                Header: 'Onshore/Offshore',
                accessor: 'activity_onshore_offshore', // accessor is the "key" in the data
            },            
            {
                Header: 'Periodicity',
                accessor: 'activity_freq', // accessor is the "key" in the data
            },
            {
                Header: 'SECE Group',
                accessor: 'sece_group', // accessor is the "key" in the data
            },
            {
                Header: 'Class',
                accessor: 'activity_class', // accessor is the "key" in the data
            },
            {
                Header: 'Last Instance - Status',
                accessor: 'activity_report_current_status', // accessor is the "key" in the data
            },
            {
                Header: 'Overall Status',
                accessor: 'activity_current_status', // accessor is the "key" in the data
            },
            {
                Header: 'Assign (N/Y)',
                accessor: 'assign', // accessor is the "key" in the data
            },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: ({ value: {va_id} }) => {
                    return (
                        <div className="relative inline-block w-10 mr-2 align-left select-none transition duration-200 ease-in">
                            <input type="checkbox"
                                name={"va-" + va_id} id={"va-" + va_id}
                                className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                                checked={isVaChecked(formInitValues, va_id)}
                                onChange={(event) => { handleVerificationActivityChange(event, formInitValues, va_id) }}
                            />
                            <label htmlFor={"va-" + va_id} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                        </div>
                    )
                }
            },
        ],
        [formInitValues]
    )

    if (loading) {
        return (
            <PageSpinner displayText={"loading activities"}/>
        )
    }

    if (error) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return(
        <div className="w-full px-8 pb-8">
            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Verification Schedule - Assign Activities</h1>           
            </div>

            <Formik 
                enableReinitialize
                initialValues={formInitValues}                
                onSubmit={(values, actions) => {
                    const { step1FormData } = verificationContext;
                    if (step1FormData.vs_title) {
                        let step2FormData = {
                            va_ids: formInitValues.va_ids,
                            ra_ids: formInitValues.ra_ids
                        };
                        verificationContext.updateStep2FormData(step2FormData);
                        moveToStep3(formMode, vsId);
                    }else{
                        moveToStep1(formMode, vsId);
                    }
              
                }}
            >
            {({
                values, 
                errors, 
                touched, 
                handleChange, 
                handleBlur, 
                setFieldValue, 
                handleSubmit, 
                isSubmitting
            }) => (
                <form onSubmit={handleSubmit}>
                        <div className="my-4">
                            <Link to={{
                                pathname: `/verification/${formMode}/step1/${vsId}`,
                                state: { 
                                    formMode: formMode,
                                    ...(verificationContext && verificationContext.originStateData? verificationContext.originStateData :{}) 
                                }
                            }} className="button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>
                            <Link 
                            to="#" 
                            className="button-red ml-2 mb-2" 
                            onClick={() => toggleModal()} 
                            style={{ ...(hasFilterParam && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}
                            >Filter VA</Link>
                            <Link to="#" className="button-red ml-2 mb-2" onClick={() => handleSelectAllVA(verificationActivityList)} >Select All VA</Link>
                            <Link to="#" className="button-red ml-2 mb-2" onClick={() => handleRemoveAllVA()} >Remove All VA</Link>
                        </div>
                        
                        <div>
                            <div className="p-3 text-left text-blue-900 font-semibold border-b-2 border-gray-200">Verification Activities</div>
                        </div>

                        <Table columns={columns} data={verificationActivityList} pagination={true} filter={true} />

                        <div>
                            <div className="p-3 text-left text-blue-900 font-semibold border-b-2 border-gray-200"></div>
                        </div>

                        <table className="w-full md:w-1/2 mt-5 mb-5 table-auto">
                            <tbody>
                                <tr>
                                    <th className="p-3 text-left text-blue-900 font-semibold border">Reference Activities</th>
                                    <th className="p-3 text-right text-blue-900 font-semibold border">Assign (Y/N)</th>
                                </tr>
                                {referenceActivityList.length ? referenceActivityList.map(ra =>
                                    <tr key={ra._id}>
                                        <td className="p-3 border">{ra.ra_title}</td>
                                        <td className="p-3 text-right border">
                                            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                <input type="checkbox"
                                                    name={"ra" + ra._id}
                                                    id={"ra" + ra._id}
                                                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                                                    checked={isRaChecked(formInitValues, ra)}
                                                    onChange={(event) => { handleReferenceActivityChange(event, formInitValues, ra) }}
                                                />
                                                <label htmlFor={"ra" + ra._id} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                                            </div>
                                        </td>
                                    </tr>
                                ) : noDataNotice()}
                            </tbody>
                        </table>
                        <button type="submit" className="mt-3 button-red capitalize">Next</button>
                    </form>
                )}
            </Formik>

            {/* SEARCH FILTER POP UP */}
            <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

                    <Formik
                        initialValues={vaFilterFormInitValue}
                        validationSchema={Validation}
                        onSubmit={(values, action) => {

                            let seceIds = values.sece_id && values.sece_id !== "" ? [values.sece_id] : [];
                            let psIds = values.ps_id && values.ps_id !== "" ? [values.ps_id] : [];
                            let seceGroup = values.sece_group && values.sece_group !== "" ? {sece_group: parseInt(values.sece_group)} : {};
                            
                            //Must also pass in the sece ids selected in the previous page
                            const { sece_ids = [] } = verificationContext.step1FormData;

                            let filter = {
                                sece_ids: seceIds.length ? seceIds : sece_ids,
                                ...seceGroup,
                                ps_ids: psIds,
                                va_type: values.nature_id,
                                va_onshore_offshore: values.onshore_offshore,
                                va_freq: values.periodicity,
                                va_class: values.class_item,
                                va_completion_status: values.va_completion_status,
                                activity_category: VA_CATEGORY_ORININAL,
                                activity_archived : false
                            }

                            setHasFilterParam(true);
                            setVerificationActivityList([]);
                            activitySearch({ variables: { param: filter }, errorPolicy: 'all' });
                            setVaFilterFormInitValue({ ...values });
                            toggleModal();
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4 pb-2 border-b-2 border-gray-200">
                                    <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">Filter Verification Activities</h2>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="sece_id" className="block mb-1 text-blue-900 font-semibold">SECE</label>
                                    <select
                                        className="form-control block w-full"
                                        name="sece_id"
                                        id="sece_id"
                                        onChange={(event) => {
                                            handleChange(event);
                                            handleSeceChange(event);
                                            setFieldValue("ps_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.sece_id}
                                    >
                                        <option value={""}>Select SECE</option>
                                        {seceList.map(sece =>
                                            <option key={sece._id} value={sece._id}>{`${sece.sece_client_ref ? sece.sece_client_ref : "N/A"} : ${sece.sece_title}`}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="sece_group" className="block mb-1 text-blue-900 font-semibold">SECE Group</label>
                                    <select
                                        className="form-control block w-full"
                                        name="sece_group"
                                        id="sece_group"
                                        onChange={(event) => {
                                            handleChange(event);
                                            //handleSeceChange(event);
                                            //setFieldValue("sece_group", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.sece_group}
                                    >
                                        <option value={""}>Select SECE Group</option>
                                        {seceGroupList.map(group_number =>
                                            <option key={group_number} value={group_number}>{group_number}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="ps_id" className="block mb-1 text-blue-900 font-semibold">Performance Standard</label>
                                    <select
                                        className="form-control block w-full"
                                        disabled={values.sece_id===""}
                                        name="ps_id"
                                        id="ps_id"
                                        onChange={(event) => { handleChange(event); }}
                                        onBlur={handleBlur}
                                        value={values.ps_id}
                                    >
                                        <option value={""}>Select Performance Standard</option>
                                        {psList.map(ps =>
                                            <option key={ps._id} value={ps._id}>{ps.ps_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="nature_id" className="block mb-1 text-blue-900 font-semibold">Nature</label>
                                    <select
                                        className="form-control block w-full"
                                        name="nature_id"
                                        id="nature_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.nature_id}
                                    >
                                        <option value={""}>Select Nature</option>
                                        {natureList.map(nature =>
                                            <option key={nature.id} value={nature.title}>{nature.title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="onshore_offshore" className="block mb-1 text-blue-900 font-semibold">Onshore / Offshore</label>
                                    <select
                                        className="form-control block w-full"
                                        name="onshore_offshore"
                                        id="onshore_offshore"
                                        onChange={(event) => { handleChange(event); }}
                                        onBlur={handleBlur}
                                        value={values.onshore_offshore}
                                    >
                                        <option value={""}>Select Onshore / Offshore</option>
                                        {onshoreOffshoreList.map(onoff =>
                                            <option key={onoff.id} value={onoff.value}>{onoff.value}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="periodicity" className="block mb-1 text-blue-900 font-semibold">Frequency</label>
                                    <select
                                        className="form-control block w-full"
                                        name="periodicity"
                                        id="periodicity"
                                        onChange={(event) => { handleChange(event); }}
                                        onBlur={handleBlur}
                                        value={values.periodicity}
                                    >
                                        <option value={""}>Select Periodicity</option>
                                        {frequencyList.map(freq =>
                                            <option key={freq.id} value={freq.title}>{freq.title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="class_item" className="block mb-1 text-blue-900 font-semibold">Class</label>
                                    <select
                                        className="form-control block w-full"
                                        name="class_item"
                                        id="class_item"
                                        onChange={(event) => { handleChange(event); }}
                                        onBlur={handleBlur}
                                        value={values.class_item}
                                    >
                                        <option value={""}>Select Class</option>
                                        {classList.map(cl =>
                                            <option key={cl.id} value={cl.value}>{cl.value}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="va_completion_status" className="block mb-1 text-blue-900 font-semibold">VA Completion Status</label>
                                    <select
                                        className="form-control block w-full"
                                        name="va_completion_status"
                                        id="va_completion_status"
                                        onChange={(event) => { handleChange(event); }}
                                        onBlur={handleBlur}
                                        value={values.va_completion_status}
                                    >
                                        <option value={""}>Select VA Completion Status</option>
                                        {activityStatusList.map(cl =>
                                            <option key={cl.id} value={cl.id}>{cl.title}</option>
                                        )}
                                    </select>
                                </div>

                                <button type="submit" className="mt-3 mb-4 button-red capitalize mr-4">Apply Filter</button>
                                <button type="button" onClick={() => { clearFilter() }} className="mt-3 mb-4 button-red capitalize">Clear Filter</button>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
            {/* SEARCH FILTER POP UP END */}
        </div>
    )
}

function noDataNotice() {
    return (
        <tr>
            <td className="p-3 border" colSpan="6">{"Data is not Available."}</td>
        </tr>
    )
}

function formatSysVarDataAndUpdateStates(list, setNatureList, setFrequencyList, setActivityStatusList, natureTypeId, vaFreqTypeId, activityStatusTypeId) {
    let natureList = [];
    let vaFreqList = [];
    let activityStatusList = [];

    if (list && list.length) {
        let tempList = list.filter(sysVar => sysVar.sysvar_archived !== true);

        let tempNatureList = tempList.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === natureTypeId);
        let tempVaFreqList = tempList.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === vaFreqTypeId);
        let tempActivityStatusList = tempList.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === activityStatusTypeId);

        tempNatureList.forEach(nature => {
            let newNature = {
                id: nature._id,
                title: nature.sysvar_title
            }
            natureList.push(newNature);
        })

        tempVaFreqList.forEach(freq => {
            let newVaFreq = {
                id: freq._id,
                title: freq.sysvar_title
            }
            vaFreqList.push(newVaFreq);
        })

        tempActivityStatusList.forEach(status => {
            let newActivityStatus = {
                id: status._id,
                title: status.sysvar_title
            }
            activityStatusList.push(newActivityStatus);
        })

        let unScheduledActivity = {
            id: TEMP_NOT_SCHEDULED_ID,
            title: "Not on Schedule"
        }
        activityStatusList.push(unScheduledActivity);
        let overActivity = {
            id: TEMP_OVERDUE_ID,
            title: "Overdue"
        }
        activityStatusList.push(overActivity);
    }

    setNatureList(natureList);
    setFrequencyList(vaFreqList);
    setActivityStatusList(activityStatusList);
}

export default AddEditVerificationScheduleStep2;
