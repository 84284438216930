/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import { useQueryString, removeLastNthParamFrmUrl, formatDateToLocaleDateString,capitaliseFirstCase } from '../../utils';

const VA_HISTORIES_QUERY = gql`
query VaHistoriesByDutyHolderId($dhId: String!, $filter: String){
    vaHistoriesByDutyHolderId(dhId: $dhId, filter: $filter) {
        _id
        dh_id {
            _id
        }
        va_short_desc
        va_status
        history_parent_record_id
        history_modifying_user_id{
           _id
           user_fname
           user_lname
           user_email
        }
        history_modified_date
        history_modified_date_timestamp
    }
}`;

function VerificationActivityHistories(props) {
    const [showHelp, setShowHelp] = useState(false);
    const [vaHistories, setVaHistories] = useState([]);

    const { id: dhId = "", psId = "" } = useParams();
    const queryString = useQueryString();
    const filters = queryString.get('filters');

    const { match: { url = "" } } = props ? props : {};

    const [vaHistoriesByDutyHolderId, { loading, error, data: vaHistoriesData }] = useLazyQuery(VA_HISTORIES_QUERY);

    useEffect(() => {
        let localFilter = filters ? filters : JSON.stringify({ ...(psId && psId !== "" && { ps_id: psId }) });
        vaHistoriesByDutyHolderId({ variables: { dhId, ...({ filter: localFilter }) }, errorPolicy: 'all' });
    }, [])

    useEffect(() => {
        if (vaHistoriesData && vaHistoriesData.vaHistoriesByDutyHolderId) {
            let hisList = vaHistoriesData.vaHistoriesByDutyHolderId;
            let formatedHisList = formatVaHistoryData(hisList);
            let reversedHistoryList = formatedHisList.reverse();
            setVaHistories(reversedHistoryList);
        }
    }, [vaHistoriesData])

    const columns = React.useMemo(
        () => [
            {
                Header: 'VA Short Desc.',
                accessor: 'description',
            },
            {
                Header: 'Modified Date',
                accessor: "history_modified_date"
            },
            {
                Header: 'Modifying User',
                accessor: "modifying_user"
            },
            {
                Header: 'Status',
                accessor: "status",
              },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: ({ value: { id, parentId } }) => (
                    <div className="text-right">
                        <Link
                            to={{
                                pathname: `${removeLastNthParamFrmUrl(url, 1)}/view-diff/${id}/${parentId}`,
                            }}
                            className="button-red mb-1 mr-1 text-xs"
                        >
                            View Change
                        </Link>
                    </div>
                )
            },
        ],
        []
    )

    if (loading) {
        return (
            <PageSpinner />
        )
    }

    if (error) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">
            <div className="pb-6 mb-6 border-b-2 border-gray-200">

                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Verification Activity History<FontAwesomeIcon
                    icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
                    onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                    <p className="mt-3">A table detailing changes to Verification Activities.</p>
                }

            </div>

            <Link to={{ pathname: `${removeLastNthParamFrmUrl(url, 1)}` }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>


            <Table columns={columns} data={vaHistories} />
        </div>
    )
}

function formatVaHistoryData(histories) {
    let historyList = []

    if (histories && histories.length) {

        histories.forEach(his => {

            const { _id, va_short_desc, va_status, dh_id, history_modified_date, history_modifying_user_id, history_parent_record_id } = his ? his : {};
            const { _id: dhId = "" } = dh_id ? dh_id : {};
            const { user_fname = "", user_lname = "" } = history_modifying_user_id ? history_modifying_user_id : {};
            let userFullName = (user_fname !== "" && user_lname !== "") ? `${user_fname} ${user_lname}` : "N/A";

            let newHistory = {
                id: _id,
                parentId: history_parent_record_id,
                dhId: dhId,
                description: va_short_desc && va_short_desc !== "" ? va_short_desc : "N/A",
                history_modified_date: history_modified_date && history_modified_date !== "" ? formatDateToLocaleDateString(history_modified_date) : "N/A",
                modifying_user: userFullName,
                status: va_status && va_status !== "" ? capitaliseFirstCase(va_status) : "N/A"
            }

            historyList.push(newHistory);
        });
    }

    return historyList;
}
  
export default VerificationActivityHistories;
