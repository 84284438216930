/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { ProgressBarComponent } from '@syncfusion/ej2-react-progressbar';
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import Table from '../global/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sanitiser from "../global/sanitiser";
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';
import PageSpinner from '../global/pageSpinner';
import {NavContext} from '../../context/navContext';

import {
  formatDateToLocaleDateString,
  booleanToYesNo,
  processReportCompletedSample
} from "../../utils";
// import {
//   ACTIVITY_STATUS_SYS_VAR_COMMENCED_ID,
//   ACTIVITY_STATUS_SYS_VAR_NOT_COMMENCED_ID,
//   ACTIVITY_STATUS_SYS_VAR_COMPLETED_ID,
//   ACTIVITY_STATUS_SYS_VAR_SCHEDULED
// } from '../../constants/';

var htmlToPdfmake = require("html-to-pdfmake");

const VERIFICATION_ACTIVITY_DETAIL_BY_ID_QUERY = gql`
query VerificationActivityDetail($vaId: ID!){
    verificationActivityDetail(_id: $vaId){
        va{
            _id
            va_client_ref
            va_short_desc
            va_long_desc
            va_type
            va_onshore_offshore
            va_freq
            va_baseline_sample
            va_class
            va_legislation
            va_abs_ivb
        }
        sece{
            _id
            sece_title
            sece_ref
            sece_criticality    
        }
        ps {
            _id
            ps_ref
            ps_title
            ps_desc
            ps_criteria
            ps_source
            ps_standard_basis
            ps_scope
            ps_assurance
            ps_criticality
            ps_added_date
        }
        findings{
            label
            count
            class
            colour
        }
        reportHistories{
            report_id
            report_current_status_id
            vs_id
            va_id
            year
            dateScheduled
            scheduleStartDate
            scheduleEndDate
            dateCompleted
            statusCompletion
            statusFindings{
                label
                count 
                class
            }
            completedBy
            completedSample
            author
        }
    }
}`;

function ViewActivity(props) {
  const [activityObject, setActivityObject] = useState({});
  const [findingStatus, setFindingStatus] = useState({});
  const [completionStatus, setCompletionStatus] = useState("");
  const [overallStatus, setOverallStatus] = useState("");

  const [drDnInstallation, setDrDnInstallation] = useState({});

  const { vaId = "" } = useParams();
  const { location: {search = "" } } = props ? props : {};
  
  const navContext = useContext(NavContext);
  const history = useHistory();

  const [verificationActivityDetail, { loading: actLoading, error: actError, data: actData }] = useLazyQuery(VERIFICATION_ACTIVITY_DETAIL_BY_ID_QUERY);

  useEffect(() => {
    let searchQueryParams = new URLSearchParams(search);
    let completion_status = searchQueryParams.get("completion-status");
    let overall_status = searchQueryParams.get("overall-status");
    setCompletionStatus(completion_status);
    setOverallStatus(overall_status);
    if (vaId !== "") {
      verificationActivityDetail({ variables: { vaId: vaId }, errorPolicy: 'all' });
    }
  }, []);

  useEffect(() => {
    const { installation } = navContext;
    const { id: prevId } = drDnInstallation;

    if (prevId && installation.id && prevId !== installation.id) {
      history.push('/');
    }
    setDrDnInstallation(installation);
  }, [navContext]);

  useEffect(() => {
    if (actData && actData.verificationActivityDetail) {
      let formatedData = formatActivityData(actData.verificationActivityDetail, completionStatus, overallStatus);
      setActivityObject(formatedData);
    }
  }, [actData]);

  useEffect(() => {
    if (activityObject && activityObject.findings) {
      setFindingStatus(activityObject.findings);
    }
  }, [activityObject]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Year',
        accessor: 'activity_history_year', // accessor is the "key" in the data
      },
      {
        Header: 'Date Scheduled',
        accessor: 'activity_history_date', // accessor is the "key" in the data
        sortType: (a, b) => {
          const { values: { activity_history_date: aDate = "" } } = a ? a : {};
          const { values: { activity_history_date: bDate = "" } } = b ? b : {};
          let aDateTemp = aDate.split(" -")[0];
          let bDateTemp = bDate.split(" -")[0];
          var a1 = new Date(aDateTemp);
          var b1 = new Date(bDateTemp);
          if (a1 < b1)
            return 1;
          else if (a1 > b1)
            return -1;
          else
            return 0;
        }
      },
      {
        Header: 'Date Completed',
        accessor: 'activity_history_date_completed', // accessor is the "key" in the data
        sortType: (a, b) => {
          const { values: { activity_history_date_completed: aDate = "" } } = a ? a : {};
          const { values: { activity_history_date_completed: bDate = "" } } = b ? b : {};

          if (aDate === "N/A" && bDate === "N/A") {
            return 0; // Both are "N/A," consider them equal
          } else if (aDate === "N/A") {
            return 1; // a is "N/A," place it after b
          } else if (bDate === "N/A") {
            return -1; // b is "N/A," place it after a
          }

          var a1 = new Date(aDate);
          var b1 = new Date(bDate);
          if (a1 < b1)
            return 1;
          else if (a1 > b1)
            return -1;
          else
            return 0;
        }
      },
      {
        Header: 'Status - Completion',
        accessor: 'activity_history_status', // accessor is the "key" in the data
      },
      {
        Header: 'Status - Findings',
        accessor: 'activity_history_findings', // accessor is the "key" in the data
      },
      // {
      //     Header: 'Completed By',
      //     accessor: 'activity_history_completed_by', // accessor is the "key" in the data
      // },
      {
        Header: 'Completed Sample',
        accessor: 'activity_history_completed_sample', // accessor is the "key" in the data
      },
      {
        Header: 'Author',
        accessor: 'activity_history_auth', // accessor is the "key" in the data
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { report_id } }) => (
          <div className="text-right" style={{ minWidth: '75px' }}>
            {report_id && report_id !== "" ?
              <Link
                to={{
                  pathname: `/reporting/view/${report_id}`,
                  search: "?origin=view-activity"
                }}
                className="button-red mb-1 mr-1 text-xs" target={"_blank"}>
                View
              </Link> :
              <></>
            }
          </div>
        )
      },
    ],
    []
  )

  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 100, 40, 24],
    header: function (currentPage, pageCount) {

      var headerObj;
      if (currentPage % 2) {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['75%', '25%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: `${activityObject.ref && activityObject.ref} - ${activityObject.shortVerificationActivity}`, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: 'Activity Search', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      } else {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['25%', '75%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: `${activityObject.ref && activityObject.ref} - ${activityObject.shortVerificationActivity}`, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: 'Activity Search', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      }

      return headerObj;
    },
    footer: function (currentPage, pageCount) {

      var footerObj;

      if (currentPage % 2) {
        footerObj = {
          table: {
            widths: [30, '*', 100, 28],
            body: [
              [
                { text: ' ', border: [false, false, false, false] },
                { text: 'Activity Search', style: 'footerText', border: [false, false, false, false] },
                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                { text: ' ', border: [true, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      } else {
        footerObj = {
          table: {
            widths: [30, 100, '*', 28],
            body: [
              [
                { text: ' ', border: [false, false, true, false] },
                { text: currentPage.toString(), border: [true, false, false, false] },
                { text: `Copyright © by ABS. All Rights Reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                { text: ' ', border: [false, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      }

      return footerObj;

    },
    info: {
      title: activityObject.ref && activityObject.ref + ' - ' + activityObject.shortVerificationActivity + ' - Activity Search',
    },
    content: [
      { text: "Current Status - Findings", style: 'title' },
      {
        table: {
          widths: ['10%', '10%', '10%'],
          headerRows: 0,
          body: [
            [
              ...findingStatusTableRows(findingStatus)
            ],
          ]
        },
        layout: 'noBorders'
      },

      {
        table: {
          widths: ['50%', '50%'],
          headerRows: 0,
          body: [
            [{ text: 'Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, activityObject.ref && activityObject.ref],
            [{ text: 'Full Verification Activity', style: 'StandardTableHeader', colSpan: 2 }, {}],
            [{ text: activityObject.fullVerificationActivity && activityObject.fullVerificationActivity, colSpan: 2 }, {}],
            [{ text: 'SECE', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, activityObject.sece && activityObject.sece],
            [{ text: 'PS No.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, activityObject.psNo && activityObject.psNo],
            [{ text: 'Function', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, activityObject.functionPDF && htmlToPdfmake(activityObject.functionPDF)],
            [{ text: 'Performance Criteria', style: 'StandardTableHeader', colSpan: 2 }, {}],
            [{ stack: activityObject.performanceCriteriaPDF && htmlToPdfmake(activityObject.performanceCriteriaPDF), colSpan: 2 }, {}],
            [{ text: 'Source', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, activityObject.sourcePDF && htmlToPdfmake(activityObject.sourcePDF)],
            [{ text: 'Assurance', style: 'StandardTableHeader', colSpan: 2 }, {}],
            [{ stack: activityObject.assurancePDF && htmlToPdfmake(activityObject.assurancePDF), colSpan: 2 }, {}],
            [{ text: 'Nature', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, activityObject.nature && activityObject.nature],
            [{ text: 'Frequency', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, activityObject.frequency && activityObject.frequency],
            [{ text: 'Criticality', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, activityObject.criticality && activityObject.criticality],
            [{ text: 'Sample Size', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, activityObject.sampleSize && activityObject.sampleSize],
            [{ text: 'Onshore/Offshore', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, activityObject.onshoreOffshore && activityObject.onshoreOffshore],
            [{ text: 'ABS IVB', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'] }, activityObject.absIvb && activityObject.absIvb],
          ]
        },
        style: 'standardTable',
      },

      { text: "Verification Activity History", style: 'title' },

      {
        style: 'tableSM',
        table: {
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', '*'],
          headerRows: 1,
          body: [
            //header
            [
              { text: 'Year', style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#000'] },
              { text: 'Date Scheduled', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
              { text: 'Date Completed', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
              { text: 'Status - Completion', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
              { text: 'Status - Findings', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'], colSpan: 3 }, {}, {},
              { text: 'Completed Sample', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
              { text: 'Author', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'] },
            ],
            ...createActivityHistoriesTableRows(activityObject)
          ]
        }
      },


    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      title: {
        marginTop: 10,
        font: 'Montserrat',
        fontSize: 10,
        bold: true,
        color: '#002a4e'
      },
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1.2
      },
      tableSM: {
        fontSize: 8
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }

  //open in new tab
  const pdfTab = () => {
    pdfMake.createPdf(docDefinition).open();
  }

  if (actLoading) {
    return (
      <PageSpinner />
    )
  }

  if (actError) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{activityObject.shortVerificationActivity}</h1>
      </div>

      {/*<Link to="/reporting/activity-search" className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>*/}
      {/*
            <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button> */}
      <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      {
        activityObject.reportId && activityObject.reportId !== "" ?
          <Link to={{ pathname: `/reporting/view/${activityObject.reportId}` }} className="mr-2 button-red" target={"_blank"}>View Full Report <FontAwesomeIcon icon={['fas', 'angle-right']} /></Link> :
          <></>
      }

      <div className="w-full lg:w-1/2">
        <div className="my-6">
          <h2 className="font-bold text-2xl text-blue-900">{activityObject.shortVerificationActivity}</h2>
          <h2 className="mt-3 font-bold text-blue-900">Current Status - Completion</h2>
          <div className="w-64 -ml-2">
            {activityObject.completionStatus && <ProgressBarComponent
              height='40'
              trackThickness={30}
              progressThickness={30}
              progressColor={activityObject.completionStatus.colorCode}
              showProgressValue={true}
              labelStyle={{ color: '#FFFFFF' }}
              value={activityObject.completionStatus.value}
              animation={{
                enable: true,
                duration: 2000,
                delay: 0,
              }}
            />}
          </div>

          <h2 className="mt-3 mb-1 font-bold text-blue-900">Current Status - Findings</h2>
          <div className="flex flex-row">
            {activityObject.findings && activityObject.findings.length ? activityObject.findings.map((fnd, index) =>
              <div key={index} className={`p-2 ${fnd.class} text-center ${fnd.label === "Level 3" ? "text-white" : "text-black"}`}>{fnd.label}<br />
                <span className="bold text-2xl">{fnd.count}</span>
              </div>
            ) : ""}
          </div>
        </div>

        <table className="table-finding w-full mb-5 table-auto">
          <tbody>
            <tr>
              <th className="p-3 text-left bg-white border">Ref.</th>
              <td className="p-3 border">{activityObject.ref && activityObject.ref}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border" colSpan="2">Full Verification Activity</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">{activityObject.fullVerificationActivity && activityObject.fullVerificationActivity}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">SECE</th>
              <td className="p-3 border">{activityObject.sece && activityObject.sece}</td>
            </tr>
            {/*<tr>
                            <th colSpan="2" className="p-3 text-left bg-white border">Goal</th>
                        </tr>
                        <tr>
                            <td className="p-3 border" colSpan="2">{activityObject.goal && activityObject.goal}</td>
                        </tr>*/}
            <tr>
              <th className="p-3 text-left bg-white border">PS No.</th>
              <td className="p-3 border">{activityObject.psNo && activityObject.psNo}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Function</th>
              <td className="p-3 border">{activityObject.function && activityObject.function}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border" colSpan="2">Performance Criteria</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">{activityObject.performanceCriteria && activityObject.performanceCriteria}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Source</th>
              <td className="p-3 border">{activityObject.source}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border" colSpan="2">Assurance</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">{activityObject.assurance}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Nature</th>
              <td className="p-3 border">{activityObject.nature && activityObject.nature}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Frequency</th>
              <td className="p-3 border">{activityObject.frequency && activityObject.frequency}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Criticality</th>
              <td className="p-3 border">{activityObject.criticality && activityObject.criticality}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Sample Size</th>
              <td className="p-3 border">{activityObject.sampleSize && activityObject.sampleSize}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Onshore/Offshore</th>
              <td className="p-3 border">{activityObject.onshoreOffshore && activityObject.onshoreOffshore}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">ABS IVB</th>
              <td className="p-3 border">{activityObject.absIvb && activityObject.absIvb}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="w-full">

        <div className="mb-6 mt-6 pb-3 border-b-2 border-gray-200">
          <h2 className="mt-3 mb-1 font-bold text-blue-900">Verification Activity History</h2>
        </div>


        {activityObject.histories && activityObject.histories.length ?
          <Table columns={columns} data={activityObject.histories} /> :
          "No Data To Display"
        }

        <div className="mt-6">
          {/*<Link to="/reporting/activity-search" className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>*/}
          {/*
                    <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button> */}
          <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>
          {
            activityObject.reportId && activityObject.reportId !== "" ?
              <Link to={{ pathname: `/reporting/view/${activityObject.reportId}` }} className="mr-2 button-red" target={"_blank"}>View Full Report <FontAwesomeIcon icon={['fas', 'angle-right']} /></Link> :
              <></>
          }
        </div>
      </div>

    </div>
  )

}

function formatActivityData(activity, completionStatus, overallStatus) {
  let formatedActivity = {};

  if (activity) {
    const { va, sece, ps, findings, reportHistories, report } = activity ? activity : {};
    const { _id: report_id = "" } = report ? report : {};

    //const latestReportInstance = reportHistories ? reportHistories[0] : null;
    //const { report_current_status_id = "" } = latestReportInstance ? latestReportInstance : {};

    let completionStatusObj = formatCompletionStatusData(completionStatus, overallStatus);
    let histories = formatHistoryData(reportHistories);

    formatedActivity = {
      reportId: report_id,
      completionStatus: completionStatusObj,
      findings: findings,
      ref: va && va.va_client_ref ? va.va_client_ref : "N/A",
      shortVerificationActivity: va && va.va_short_desc ? va.va_short_desc : "N/A",
      fullVerificationActivity: va && va.va_long_desc ? va.va_long_desc : "N/A",
      sece: sece && sece.sece_title ? sece.sece_title : "N/A",
      goal: "N/A",
      psNo: ps && ps.ps_ref ? ps.ps_ref : "N/A",
      functionPDF: ps && ps.ps_desc ? ps.ps_desc : "N/A", //The Function comes from the decription field of the Performance Standard
      function: ps && ps.ps_desc ? <Sanitiser html={ps.ps_desc} /> : "N/A", //The Function comes from the decription field of the Performance Standard
      performanceCriteriaPDF: ps && ps.ps_criteria ? ps.ps_criteria : "N/A",
      performanceCriteria: ps && ps.ps_criteria ? <Sanitiser html={ps.ps_criteria} /> : "N/A",
      sourcePDF: ps && ps.ps_source ? ps.ps_source : "N/A",
      source: ps && ps.ps_source ? <Sanitiser html={ps.ps_source} /> : "N/A",
      assurancePDF: ps && ps.ps_assurance ? ps.ps_assurance : "N/A",
      assurance: ps && ps.ps_assurance ? <Sanitiser html={ps.ps_assurance} /> : "N/A",
      nature: va && va.va_type ? va.va_type : "N/A",
      frequency: va && va.va_freq ? va.va_freq : "N/A",
      criticality: sece && sece.sece_criticality ? sece.sece_criticality : "N/A",
      sampleSize: "N/A",
      onshoreOffshore: va && va.va_onshore_offshore ? va.va_onshore_offshore : "N/A",
      absIvb: booleanToYesNo(va.va_abs_ivb),
      histories: [...histories],
    }
  }

  return formatedActivity;
}

function formatCompletionStatusData(status, overallStatus) {
  let tempValue = { value: 0, colorCode: "#FF0000" };
  if (overallStatus !== "in-date") {
    return tempValue;
  }

  if (status) {
    switch (status) {
      //case ACTIVITY_STATUS_SYS_VAR_COMMENCED_ID:
      case "commenced":
        tempValue = { value: 50, colorCode: "#FFAD0A" };
        break;
      //case ACTIVITY_STATUS_SYS_VAR_COMPLETED_ID:
      case "completed":
        tempValue = { value: 100, colorCode: "#4CA342" };
        break;
      default:
        break;
    }
  }
  return tempValue;
}

function formatHistoryData(histories) {
  let formatedHistories = [];
  if (histories && histories.length) {
    histories.forEach(element => {
      const {
        report_id,
        vs_id,
        va_id,
        year,
        scheduleStartDate,
        scheduleEndDate,
        //dateScheduled, 
        dateCompleted,
        statusCompletion,
        statusFindings,
        completedBy,
        completedSample,
        author } = element ? element : {}

      //let historyStatus = (statusCompletion === "complete" || statusCompletion === "completed") ? <div className="text-black bg-green-900 p-1">Completed</div> : 'Scheduled';
      let historyStatus = statusCompletion;
      let scheduleStartString = scheduleStartDate && scheduleStartDate !== "" ? new Date(parseInt(scheduleStartDate)) : "";
      let scheduleEndString = scheduleEndDate && scheduleEndDate !== "" ? new Date(parseInt(scheduleEndDate)) : "";

      //let dateScheduledString = dateScheduled && dateScheduled !== "" ? new Date(parseInt(dateScheduled)) : "";
      let startDate = scheduleStartString !== "" ? formatDateToLocaleDateString(scheduleStartString) : null;
      let endDate = scheduleEndString !== "" ? formatDateToLocaleDateString(scheduleEndString) : null;

      //let historyDate = dateScheduledString !== "" ? formatDateToLocaleDateString(dateScheduledString) : "N/A";
      let historyDate = startDate && endDate ? `${startDate} - ${endDate}` : "N/A";

      let dateCompletedString = dateCompleted && dateCompleted !== "" ? new Date(parseInt(dateCompleted)) : "";
      let historyDateCompleted = dateCompletedString !== "" ? formatDateToLocaleDateString(dateCompletedString) : "N/A";

      let historyFindings = formatHistoryFindinsData(statusFindings);

      let history = {
        report_id: report_id,
        activity_history_vs_id: vs_id,
        activity_history_va_id: va_id,
        activity_history_year: year && year !== 0 ? year : "N/A",
        activity_history_date: historyDate,
        activity_history_date_completed: historyDateCompleted,
        activity_history_status: historyStatus,
        activity_history_findings: historyFindings,
        activity_history_findings_alt: statusFindings,
        activity_history_completed_by: completedBy && completedBy !== "" ? completedBy : "-",
        activity_history_completed_sample: processReportCompletedSample(completedSample),
        activity_history_auth: author && author !== "" ? author : "-",
      }

      formatedHistories.push(history);
    });
  }
  return formatedHistories;
}

function formatHistoryFindinsData(findings) {
  let findingsCount = <div className="flex flex-row">
    <div className="p-1 bg-blue-300 text-center text-black">Level 1<br /><span className="bold">-</span></div>
    <div className="p-1 bg-yellow-900 text-center text-black">Level 2<br /><span className="bold">-</span></div>
    <div className="p-1 bg-red-900 text-center text-white">Level 3<br /><span className="bold">-</span></div>
  </div>;
  if (findings && findings.length) {
    findingsCount = <div className="flex flex-row"> {findings.map((fnd, index) =>
      <div key={index} className={`p-1 ${fnd.class} text-center ${fnd.label === "Level 3" ? "text-white" : "text-black"}`}>{fnd.label}<br /><span className="bold">{fnd.count}</span></div>
    )}
    </div>
  }
  return findingsCount;
}

const createActivityHistoriesTableRows = (activityObject) => {
  let actHistories = [];
  const { histories = [] } = activityObject ?? {};

  if (!histories || histories.length === 0) {
    let emptyRow = ["No Data to Display", "", "", "", "", "", "", "", ""];
    actHistories.push(emptyRow);
    return actHistories;
  }

  histories.forEach((history, index) => {
    const {
      activity_history_year,
      activity_history_date,
      activity_history_date_completed,
      activity_history_status,
      activity_history_findings_alt,
      activity_history_completed_sample,
      activity_history_auth,
    } = history ?? {};

    const isOddNumber = ((index) % 2) === 0;

    let historyRow = [
      { text: `${activity_history_year}`, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
      { text: `${activity_history_date}`, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
      { text: `${activity_history_date_completed}`, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
      { text: `${activity_history_status}`, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
      ...historyFindingStatusTableRows(activity_history_findings_alt),
      { text: `${activity_history_completed_sample}`, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
      { text: `${activity_history_auth}`, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
    ];
    actHistories.push(historyRow);
  })
  return actHistories
}

export const historyFindingStatusTableRows = (findingStatusList) => {
  let rowList = [];
  if (findingStatusList && findingStatusList.length) {
    findingStatusList.forEach(f => {
      let row = {
        text: [
          `${f.label}\n`,
          { text: f.count, fontSize: 12 }
        ],
        alignment: 'center', fillColor: classToColorMapper(f.class), color: f.label === "Level 3" ? "#ffffff" : "#000000"
      };
      rowList.push(row);
    })
  }
  return rowList;
}

function classToColorMapper(className) {
  let color = "";
  switch (className) {
    case 'bg-blue-300':
      color = "#5E8AB4";
      break;
    case 'bg-yellow-900':
      color = "#FFAD0A"
      break;
    case 'bg-red-900':
      color = "#b40c1e"
      break;
    default:
      break;
  }
  return color;
}

export const findingStatusTableRows = (findingStatus) => {
  let rowList = [];
  if (findingStatus && findingStatus.length) {
    findingStatus.forEach(f => {
      let row = {
        text: [
          `${f.label}\n`,
          { text: f.count, fontSize: 12 }
        ],
        alignment: 'center', fillColor: f.colour, color: f.label === "Level 3" ? "#ffffff" : "#000000"
      };
      rowList.push(row);
    })
  }
  return rowList;
}

export default ViewActivity;
