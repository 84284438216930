import { library } from '@fortawesome/fontawesome-svg-core';

import { faUser, faQuestionCircle, faFolder, faFile, faImage, faFileWord, faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { faBars, faExclamationTriangle, faSquareFull, faInfoCircle, faCog, faCircle, faArrowRight, faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight, faAngleUp, faAngleDown, faCaretDown, faCaretUp, faSort, faBalanceScale, faEllipsisV, faDownload, faUpload, faShare, faLink, faFilePdf, faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function initFontAwesome(){
  library.add(
    faBars,
    faExclamationTriangle,
    faInfoCircle,
    faCog,
    faSquareFull,
    faCircle,
    faArrowRight,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleUp,
    faAngleDown,
    faCaretDown, 
    faCaretUp,
    faSort,
    faBalanceScale,
    faEllipsisV,
    faDownload,
    faUpload,
    faShare,
    faLink,
    faFilePdf,
    faPlus,
    faUser,
    faQuestionCircle,
    faFolder,
    faFile,
    faImage,
    faFileWord,
    faFileExcel,
    faTimesCircle
  );
}
export default initFontAwesome;