/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';
import { getSectionName } from "../../utils/index";
import { AuthContext } from "../../context/authContext";
import { parseJSONSafely, createUserTypeAndRoleObject } from "../../utils";
import {NavContext} from '../../context/navContext';

const { REACT_APP_FILE_MANAGER_API } = process.env;

const RA_QUERY = gql`
query ReferenceActivity($raId: ID!){
    referenceActivity(_id: $raId) {
        ra_title
        ra_desc
        ra_sample_size
        ra_sample_quantity
        ra_notes
        ra_attachment
        ra_attmnt_dest_path
        ra_status
    }
}`;

const DUTYHOLDER_QUERY = gql`
query DutyHolder($dutyHolderId: ID!){
    dutyHolder(_id: $dutyHolderId) {
        dh_name
    }
}
`;

const ASSETGROUP_QUERY = gql`
query AssetGroup($assetGroupId: ID!){
    assetGroup(_id: $assetGroupId) {
        ag_title
    }
}`;

const ViewReferenceActivity = (props) => {
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const [raAttachments, setRaAttachments] = useState([]);
  const [drDnInstallation, setDrDnInstallation] = useState({});

  const [dutyHolderName, setDutyHolderName] = useState("N/A");

  const [assetGroupName, setAssetGroupName] = useState("N/A");
  const [ra, setRa] = useState({});

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const history = useHistory();

  const { id, assetGroupId, installationId, barrierId, seceId, mahId, raId, psId, vaId } = useParams();
  const { location: { state = "" }, match: { url = "" } } = props ? props : {};

  const [referenceActivity, { loading, error, data }] = useLazyQuery(RA_QUERY);

  const [dutyHolder, { data: dhData }] = useLazyQuery(DUTYHOLDER_QUERY);
  const [assetGroup, { data: agData }] = useLazyQuery(ASSETGROUP_QUERY);

  useEffect(() => {
    if (id && id !== "") {
      dutyHolder({ variables: { dutyHolderId: id }, errorPolicy: 'all' })
    }

    if (assetGroupId && assetGroupId !== "") {
      assetGroup({ variables: { assetGroupId: assetGroupId }, errorPolicy: 'all' })
    }
    if (raId && raId !== "") {
      referenceActivity({ variables: { raId: raId }, errorPolicy: 'all' })
    }
  }, []);

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
      const { installation } = navContext;
      const { id: prevId } = drDnInstallation;

      if (prevId && installation.id && prevId !== installation.id) {
          history.push('/');
      }
      setDrDnInstallation(installation);
  }, [navContext]);


  useEffect(() => {
    if (dhData && dhData.dutyHolder) {
      let tempDhData = dhData.dutyHolder;
      let dhName = tempDhData.dh_name ? tempDhData.dh_name : "N/A";
      setDutyHolderName(dhName)
    }
  }, [dhData]);

  useEffect(() => {
    if (agData && agData.assetGroup) {
      let tempAgData = agData.assetGroup;
      let agTitle = tempAgData.ag_title ? tempAgData.ag_title : "N/A";
      setAssetGroupName(agTitle)
    }
  }, [agData]);

  useEffect(() => {
    if (data && data.referenceActivity) {
      let refActData = data.referenceActivity;
      let attachments = refActData.ra_attachment && refActData.ra_attachment !== "" ? refActData.ra_attachment : "[]";
      let attachmentList = parseJSONSafely(attachments) ? parseJSONSafely(attachments) : [];
      setRaAttachments(attachmentList);
      setRa(refActData)
    }
  }, [data]);

  function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 100, 40, 24],
    header: function (currentPage, pageCount) {

      var headerObj;
      if (currentPage % 2) {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['75%', '25%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: capitalizeFirstLetter(getSectionName(url, 5).replace("-", " ")) + " Reference Activity", font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: ra.ra_title, font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      } else {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['25%', '75%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: capitalizeFirstLetter(getSectionName(url, 5).replace("-", " ")) + " Reference Activity", fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: ra.ra_title, font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      }

      return headerObj;
    },
    footer: function (currentPage, pageCount) {

      var footerObj;

      if (currentPage % 2) {
        footerObj = {
          table: {
            widths: [30, '*', 100, 28],
            body: [
              [
                { text: ' ', border: [false, false, false, false] },
                { text: 'REFERENCE ACTIVITY DETAILS', style: 'footerText', border: [false, false, false, false] },
                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                { text: ' ', border: [true, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      } else {
        footerObj = {
          table: {
            widths: [30, 100, '*', 28],
            body: [
              [
                { text: ' ', border: [false, false, true, false] },
                { text: currentPage.toString(), border: [true, false, false, false] },
                { text: `Copyright © by ABS. All Rights Reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                { text: ' ', border: [false, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      }

      return footerObj;

    },
    info: {
      title: capitalizeFirstLetter(getSectionName(url, 5).replace("-", " ")) + ' Reference Activity - ' + ra.ra_title,
    },
    content: [

      { text: ra.ra_title, style: 'title' },
      {
        table: {
          widths: ['30%', '70%'],
          headerRows: 0,
          body: [

            [{ text: 'Duty Holder', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{ text: dutyHolderName, alignment: 'left', colSpan: 2 }, {}],

            [{ text: 'Asset Group Name', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{ text: assetGroupName, alignment: 'left', colSpan: 2 }, {}],
            [{ text: 'Description', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{ text: ra.ra_desc, alignment: 'left', colSpan: 2 }, {}],
            [{ text: 'Sample Size', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, { text: ra.ra_sample_size }],
            [{ text: 'Sample Quantity', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, { text: ra.ra_sample_quantity }],
            [{ text: 'Notes', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{ text: ra.ra_notes ? ra.ra_notes : 'N/A', alignment: 'left', colSpan: 2 }, {}],
            [{ text: 'Attachments', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{
              text: [
                {
                  text: raAttachments.length ? raAttachments.map(att => {
                    return (
                      "File Name: \n" +
                      att.name + '\n' +
                      "File Description: \n" +
                      att.description + '\n \n'
                    )
                  }) : "No Attachments to Display"
                },
              ], colSpan: 2
            }, {}],
            [{ text: 'Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'] }, { text: ra.ra_status }],
          ]
        },
        style: 'standardTable',
      },
    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      title: {
        marginTop: 10,
        font: 'Montserrat',
        fontSize: 14,
        bold: true,
        color: '#002a4e'
      },
      redTitle: {
        marginBottom: 10,
        font: 'Montserrat',
        color: '#b40c1e'
      },
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1.2
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }

  const pdfTab = () => {
    pdfMake.createPdf(docDefinition).open();
  }

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the Reference Activity data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{getSectionName(url, 5).replace("-", " ")} Reference Activity</h1>
      </div>

      {displayBackLink(state, id, assetGroupId, installationId, barrierId, seceId, mahId, psId, vaId)}

      <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      <div className="w-full lg:w-1/2">

        <div className="my-6">
          <h2 className="font-bold text-2xl text-blue-900">{ra.ra_title}</h2>
        </div>

        <table className="w-full mb-5 table-auto">
          <tbody>
            <tr>
              <th className="p-3 text-left border" colSpan="2">Duty Holder</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">{dutyHolderName}</td>
            </tr>
            <tr>
              <th className="p-3 text-left border" colSpan="2">Asset Group Name</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">{assetGroupName}</td>
            </tr>
            <tr>
              <th className="p-3 text-left border" colSpan="2">Asset Group Name</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">{assetGroupName}</td>
            </tr>
            <tr>
              <th className="p-3 text-left border" colSpan="2">Description</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">{ra.ra_desc}</td>
            </tr>
            <tr>
              <th className="p-3 text-left border">Sample Size</th>
              <td className="p-3 border">{ra.ra_sample_size}</td>
            </tr>
            <tr>
              <th className="p-3 text-left border">Sample Quantity</th>
              <td className="p-3 border">{ra.ra_sample_quantity}</td>
            </tr>
            {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
              <tr>
                <th className="p-3 text-left border" colSpan="2">Notes</th>
              </tr>
            }
            {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
              <tr>
                <td className="p-3 border" colSpan="2">{ra.ra_notes !== "" ? ra.ra_notes : "N/A"}</td>
              </tr>
            }
            <tr>
              <th className="p-3 text-left bg-white border" colSpan="2">Attachments</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">{createFileLinks(raAttachments, ra)}</td>
            </tr>
            <tr>
              <th className="p-3 text-left border">Status</th>
              <td className="p-3 border">{ra.ra_status}</td>
            </tr>
          </tbody>
        </table>

        {displayBackLink(state, id, assetGroupId, installationId, barrierId, seceId, mahId, psId, vaId)}

        <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      </div>

    </div>
  )
}

function displayBackLink(state, id, assetGroupId, installationId, barrierId, seceId, mahId, psId, vaId) {
  if (state && state.origin && state.origin === "notifications") {
    return (
      <Link to={{
        pathname: `/notifications`
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
  if (assetGroupId !== undefined && installationId === undefined && barrierId === undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
  if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
  if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
  if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId === undefined && mahId !== undefined && psId === undefined && vaId === undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mah/${mahId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
  if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId === undefined && mahId !== undefined && psId === undefined && vaId === undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/mah/${mahId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
  if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId === undefined && vaId === undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
  if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId === undefined && vaId === undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
  if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId === undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
  if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId === undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
  if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId !== undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
  if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId !== undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };

}

function createFileLinks(attachments, refAct) {
  let linkList = "";
  let fileDestPath = refAct.ra_attmnt_dest_path ? refAct.ra_attmnt_dest_path : "";
  if (attachments && attachments.length && fileDestPath !== "") {
    let fullPath = `${REACT_APP_FILE_MANAGER_API}/download-file?path=/${fileDestPath}/`;
    let numberOfAttachments = attachments.length;

    let newAttachments = <React.Fragment>{attachments.map((att, index) =>
      <React.Fragment key={index}>
        <div className="text-blue-900 font-sans text-sm font-bold">File Name:</div>
        <a href={fullPath + att.name} className="mb-1 hover:underline" key={index} target={"_blank"} rel="noopener noreferrer" download={true}>
          {att.name}{((index + 1) < numberOfAttachments) ? <br /> : ""}
        </a>
        <div className="text-blue-900 font-sans text-sm font-bold">File Description:</div>
        <div>
          {att.description}
        </div>
        {((index + 1) < numberOfAttachments) ? <br /> : ""}
      </React.Fragment>
    )}
    </React.Fragment>
    linkList = newAttachments;
  } else {
    linkList = <Link to="#" className="hover:underline">No File Attachment</Link>
  }

  return linkList;
}

export default ViewReferenceActivity;
