/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import Sanitiser from "../global/sanitiser";
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import Table from '../global/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from '../global/pageSpinner';
import {NavContext} from '../../context/navContext';
//import {AuthContext} from "../../context/authContext";
import {
    NATURE_SYSTEM_VARIABLE_TYPE_ID,
    PERIODICITY_SYSTEM_VARIABLE_TYPE_ID,
    PHASE_SYSTEM_VARIABLE_TYPE_ID,
    STATEMENT_SYSTEM_VARIABLE_TYPE_ID,
    ACTIVITY_STATUS_SYSTEM_VARIABLE_TYPE_ID,
    //STATUS_SYSTEM_VARIABLE_TYPE_ID, 
    FINDING_ORIGIN_REPORT,
    ANSWERS_SYSTEM_VARIABLE_TYPE_ID,
    QUESTIONS_SYSTEM_VARIABLE_TYPE_ID
} from '../../constants/';

import { 
    parseJSONSafely, 
    formatDateToLocaleDateString, 
    truncateString
 } from "../../utils";
 
const { REACT_APP_FILE_MANAGER_API } = process.env;

const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
            _id
            sysvar_title
            sysvar_archived
            sysvartype_id{
                _id
                sysvartype_title
            }
        }
}`;

//const VERIFICATION_SCHEDULE_ID_QUERY = gql`
//query VerificationSchedule($vsId: ID!){
//    verificationSchedule(_id: $vsId){
//        _id
//       vs_title
//        vs_periodicity
//        schedule_type_id{
//          _id
//          sysvar_title
//        }
//        dh_id{
//          _id
//          dh_name
//        }
//        installation_ids{
//          _id
//          installation_code
//        }
//        sece_ids{
//          _id
//          sece_title
//          sece_ref
//          sece_client_ref
//          sece_status
//        }
//        va_ids{
//         _id
//          va_short_desc
//          va_ref
//          va_client_ref
//          va_status
//        }
//        ra_ids{
//          _id
//          ra_title
//        }
//        verifier_ids{
//          _id
//          user_fname
//          user_lname
//        }
//        vs_start_date_time
//        vs_end_date_time
//        vs_added_date
//        vs_added_id{_id}
//        vs_archived
//    }
//}`;

const VA_BY_ID_QUERY = gql`
query VerificationActivity($vaId: ID!){
    verificationActivity(_id: $vaId){
        _id
        dh_id{
            _id
            dh_name
        }
        sece_id{
            _id        
        }
        ps_id {
            _id
            ps_ref
            ps_title
            ps_desc
            ps_criteria
            ps_base_path
        } 
        va_ref  
        va_client_ref 
        va_short_desc
        va_long_desc
        va_type
        va_onshore_offshore
        va_freq
        va_baseline_sample
        va_class
        va_legislation
        va_child_id
        va_added_date
        va_added_id
        va_archived
        va_archived_id
        va_archived_date
        va_status
    }
}`;

const SECE_BY_ID_QUERY = gql`
query Sece($seceId: ID!){
    sece(_id: $seceId){
        _id
        dh_id{
            _id
        }
        ag_id{
            _id
            ag_title
        }
        bar_id{
            _id
            bar_title
        }
        installation_id{
            _id
            installation_title
        }
        sece_title
        sece_ref
        sece_client_ref
        sece_criticality
        sece_owner
        sece_order_no
        sece_attachment
        sece_archived
        sece_status
    }
}`;

const REPORT_BY_ID_QUERY = gql`
query Report($rptId: ID!){
    report(_id: $rptId) {
      _id
      dh_id{
        _id
        dh_name
      }
      ag_id{
        _id
        ag_title
      }
      installation_id{
        _id
        installation_title
      }
      barrier_id{
        _id
        bar_title 
      }
      sece_id{
        _id
        sece_client_ref
        sece_title
        sece_criticality
      }
      vs_id{
        _id
        vs_title
        vs_periodicity{
            _id 
        }
      }
      va_id{
        _id
        va_ref
        va_client_ref
        va_short_desc 
        va_freq
        va_type
      }
      report_ref
      report_freq{
        _id 
        sysvar_title 
      }
      report_confidence
      report_comp_sample
      report_current_status{_id}
      report_previous_status{
        _id
        sysvar_title
      }
      report_phase
      report_prior_norm
      report_time_taken
      report_discrepancy
      report_justification
      report_linked_doc
      report_verification_details
      report_conclusive_statement{_id}
      report_attachments
      rpt_attmnt_dest_path
      rpt_ld_attmnt_dest_path
      rpt_ba_attmnt_dest_path
      linked_va_ids{
       _id
       va_ref
       va_client_ref
       va_short_desc 
       va_status
      }
      interacting_sece_ids{
        _id
        sece_client_ref
        sece_title
        sece_criticality
        sece_status
      }
      report_previous_verifier_id{
        _id
        user_fname
        user_lname
      }
      report_previously_completed
      report_previous_findings
      report_synopsis
      report_bg_activities
      report_archived
      report_status
      report_added_date
      report_added_id{
        user_fname
        user_lname
      }
      report_closed_date
      report_previous_activity{
        report_id
        previously_completed
        previous_verifier
        previous_status
        previous_findings{
          _id
          finding_ref
        }
      }
  }
}`;

// const FINDINGS_BY_REPORT_ID_QUERY = gql`
// query FindingsByReportId($rptId: ID!){
//     findingsByReportId(reportId: $rptId){
//         _id
//         finding_ref
//         finding_synopsis
//     }
// }`;

const FINDINGS_BY_REPORT_ID_QUERY = gql`
query FindingsByReportId($reportId: ID!, $fndOrigin: String){
    findingsByReportId(reportId: $reportId, fndOrigin: $fndOrigin){
     _id
     finding_synopsis
     finding_current_status{
        _id
     }
     finding_ref
     finding_status
     finding_origin
     report_id {
          _id
     }

     finding_level_summary
     finding_infomatrix_level

     finding_summary
     finding_action

     finding_date
     finding_status
     finding_added_date
  }
}`;

function ViewReport(props) {
    //const [synopsisCharsLeft, setSynopsisCharsLeft] = useState(150);
    //const [synopsisMaxChar, setSynopsisMaxChar] = useState(150);
    //const [attachmentList, setAttachmentList] = useState([]);

    const [shouldSpin, setShouldSpin] = useState(true);

    //const [verScheduleObject, setVerScheduleObject] = useState({});
    const [verActivityObject, setVerActivityObject] = useState({});
    const [vaPerfStand, setVaPerfStand] = useState({});

    const [dutyHolderObject, setDutyHolderObject] = useState({});
    const [assetGroupObject, setAssetGroupObject] = useState({});
    const [barrierObject, setBarrierObject] = useState({});
    const [installationObject, setInstallationObject] = useState({});
    const [seceObject, setSeceObject] = useState({});

    const [natureList, setNatureList] = useState([]);
    const [, setPeriodicityList] = useState([]);
    const [phaseList, setPhaseList] = useState([]);
    const [statementList, setStatementList] = useState([]);
    //const [statusList, setStatusList] = useState([]);
    const [activityStatusList, setActivityStatusList] = useState([])

    const [nature, setNature] = useState({});
    // const [frequency, setFrequency] = useState({});
    const [vaFrequency, setVaFrequency] = useState("");
    const [phase, setPhase] = useState({});
    const [reviewer, setReviewer] = useState("");
    const [statement, setStatement] = useState({});
    const [status, setStatus] = useState({});

    const [reportObject, setReportObject] = useState({});
    const [findingList, setFindingList] = useState([]);

    const [previouslyCompletedDate, setPreviouslyCompletedDate] = useState("");
    const [previousVerifier, setPreviousVerifier] = useState("");
    const [previousStatus, setPreviousStatus] = useState("");
    const [previousFindings, setPreviousFindings] = useState([]);

    const [backGroundActivityList, setBackGroundActivityList] = useState([]);

    //const [linkedActivityList, setLinkedActivityList] = useState([]);
    const [selectedLinkedActivityList, setSelectedLinkedActivityList] = useState([]);

    //const [interactingSeceList, setInteractingSeceList] = useState([]);
    const [selectedInteractingSeceList, setSelectedInteractingSeceList] = useState([]);

    const [bckGrdQuestionList, setBckGrdQuestionList] = useState([]);
    const [answerOptionList, setAnswerOptionList] = useState([]);

    const [linkedDocList, setLinkedDocList] = useState([]);
    const [reportAttachmentList, setReportAttachmentList] = useState([]);
    const [drDnInstallation, setDrDnInstallation] = useState({});

    //const [tempFindingList, setTempFindingList] = useState([]);

    //const [navOrigin, setNavOrigin] = useState("");

    const [linkedDocformInitValues, setLinkedDocFormInitValues] = useState({
        ld_name: "",
        ld_description: "",
        ld_requirement: "",
        ld_attachement: []
    });

    const { formMode = "", rptId = "" } = useParams(); //form add or edit state passed from link
    const { location: { state: { actId = "", verId = "" } = {} } = {} } = props ? props : {};

    //const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);
    const history = useHistory();

    const [systemVariablesBySysVarTypeIds, { data: sysVarsData }] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);
    //const [verificationSchedule, {error:vsError, data: verSchData}] = useLazyQuery(VERIFICATION_SCHEDULE_ID_QUERY);
    const [verificationActivity, { error: vaError, data: verActData }] = useLazyQuery(VA_BY_ID_QUERY);
    const [sece, { error: seceError, data: seceData }] = useLazyQuery(SECE_BY_ID_QUERY);

    const [findingsByReportId, { data: findingData }] = useLazyQuery(FINDINGS_BY_REPORT_ID_QUERY);
    const [report, { data: reportData }] = useLazyQuery(REPORT_BY_ID_QUERY);

    const sysVarTypeIds = [
        NATURE_SYSTEM_VARIABLE_TYPE_ID,
        PERIODICITY_SYSTEM_VARIABLE_TYPE_ID,
        PHASE_SYSTEM_VARIABLE_TYPE_ID,
        STATEMENT_SYSTEM_VARIABLE_TYPE_ID,
        ACTIVITY_STATUS_SYSTEM_VARIABLE_TYPE_ID,
        ANSWERS_SYSTEM_VARIABLE_TYPE_ID,
        QUESTIONS_SYSTEM_VARIABLE_TYPE_ID
    ];

    useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        } catch (error) { window.scrollTo(0, 0); };

        //let searchQueryParams = new URLSearchParams(search);
        //let origin = searchQueryParams.get("origin");
        //setNavOrigin(origin);

        systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });

        if (((formMode === "edit") || (formMode === "verify-edit")) && rptId !== "") {
            setLinkedDocFormInitValues({ ...linkedDocformInitValues });// temporary measure to surpress react warning 
        }

        report({ variables: { rptId: rptId }, errorPolicy: 'all' });
        findingsByReportId({ variables: { reportId: rptId, fndOrigin: FINDING_ORIGIN_REPORT }, errorPolicy: 'all' });
    }, []);

    useEffect(() => {
        const { installation } = navContext;
        const { id: prevId } = drDnInstallation;
    
        if (prevId && installation.id && prevId !== installation.id) {
          history.push('/');
        }
        setDrDnInstallation(installation);
      }, [navContext]);

    useEffect(() => {
        if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
            formatSysVarDataAndUpdateStates(sysVarsData.systemVariablesBySysVarTypeIds,
                setNatureList,
                setPeriodicityList,
                setPhaseList,
                setStatementList,
                setActivityStatusList,
                setBckGrdQuestionList,
                setAnswerOptionList,
                NATURE_SYSTEM_VARIABLE_TYPE_ID,
                PERIODICITY_SYSTEM_VARIABLE_TYPE_ID,
                PHASE_SYSTEM_VARIABLE_TYPE_ID,
                STATEMENT_SYSTEM_VARIABLE_TYPE_ID,
                ACTIVITY_STATUS_SYSTEM_VARIABLE_TYPE_ID,
                QUESTIONS_SYSTEM_VARIABLE_TYPE_ID,
                ANSWERS_SYSTEM_VARIABLE_TYPE_ID);
        }
    }, [sysVarsData]);

    //useEffect(() => {
    //    if (verSchData && verSchData.verificationSchedule) {
    //         formatVsAndUpdateState(verSchData.verificationSchedule, 
    //                                setVerScheduleObject, 
    //                               setLinkedActivityList, 
    //                               setInteractingSeceList);
    //    }
    //}, [verSchData]);

    useEffect(() => {
        if (verActData && verActData.verificationActivity) {
            let verAct = verActData.verificationActivity;

            let dutyHolder = verAct.dh_id ? verAct.dh_id : {};
            const { _id = "" } = verAct.sece_id ? verAct.sece_id : {};

            const { ps_id = {} } = verAct ? verAct : {};
            setVaPerfStand(ps_id);
            setDutyHolderObject(dutyHolder);
            setVerActivityObject(verAct);

            if (_id !== "") {
                sece({ variables: { seceId: _id }, errorPolicy: 'all' });
            }
        }
    }, [verActData]);

    useEffect(() => {
        if (seceData && seceData.sece) {
            let sece = seceData.sece;
            let assetGroup = sece.ag_id ? sece.ag_id : {};
            let barrier = sece.bar_id ? sece.bar_id : {};
            let installation = sece.installation_id ? sece.installation_id : {};

            setAssetGroupObject(assetGroup);
            setBarrierObject(barrier);
            setInstallationObject(installation);

            setSeceObject(sece);
        }
    }, [seceData]);

    useEffect(() => {
        if (reportData && reportData.report) {
            let report = reportData.report;

            let dh = report.dh_id;
            let ag = report.ag_id;
            let bar = report.barrier_id;
            let inst = report.installation_id;
            let sece = report.sece_id;
            let va = report.va_id;
            // let vs = report.vs_id;

            const { va_type: vaType = "N/A", va_freq: vaFreq = "N/A" } = va ? va : {};
            // const {_id: periodicityId} = vs.vs_periodicity ? vs.vs_periodicity : {};

            let reportNature = report.report_nature && report.report_nature._id !== "" ? natureList.find(n => n._id === report.report_nature._id) : { sysvar_title: vaType };

            // let reportFreq = periodicityId ? periodicityList.find(p => p._id === periodicityId) : { sysvar_title: "N/A" };

            let reportPhase = phaseList.find(p => p._id === report.report_phase);

            const { report_conclusive_statement, report_current_status } = report ? report : {};

            const { _id: conclusive_statement_id = "" } = report_conclusive_statement ? report_conclusive_statement : {};
            const { _id: current_status_id = "" } = report_current_status ? report_current_status : {};

            let reportConclusiveStatement = statementList.find(c => c._id === conclusive_statement_id);
            let reportCurrentStatus = activityStatusList.find(s => s._id === current_status_id);

            let linkedDocsString = report.report_linked_doc ? report.report_linked_doc : "[]";
            let linkedDocList = parseJSONSafely(linkedDocsString);

            let reportAttachmentString = report.report_attachments && report.report_attachments !== "" ? report.report_attachments : "[]";

            let reportAttachments = parseJSONSafely(reportAttachmentString);

            let bgdActString = report.report_bg_activities ? report.report_bg_activities : "[]";
            let bgdActList = parseJSONSafely(bgdActString);

            const { user_fname = "", user_lname = "" } = report.report_added_id ? report.report_added_id : {};
            const tempReviewer = (user_fname !== "" && user_lname !== "") ? `${user_fname} ${user_lname}` : "N/A";

            //let bgdActProps = createBackgndActForEdit(bgdActList);

            let linkedVaIds = report.linked_va_ids;
            //let selectedLinkedVas = createSelectedLinkedVas(linkedVaIds, linkedActivityList);

            let interactingSeceIds = report.interacting_sece_ids;
            //let selectedInteractingSeces = createSelectedInteractingSeces(interactingSeceIds, interactingSeceList);
           
            const {
                previous_findings,
                previous_status,
                previous_verifier,
                previously_completed} = report.report_previous_activity ?? {};

            var previouslyCompletedDateIso = "";
            if(previously_completed && previously_completed !== ""){
                previouslyCompletedDateIso = new Date(previously_completed);
            }
            let previouslyCompletedDate = formatDateToLocaleDateString(previouslyCompletedDateIso);
            
            let previouslyCompleted = previouslyCompletedDate && previouslyCompletedDate !== "" ? previouslyCompletedDate : "N/A";
            let previousFindings = previous_findings && previous_findings.length ? previous_findings : [];    
            let previousVerifier = previous_verifier ? previous_verifier: "N/A";
            let reportPreviousStatus = previous_status && previous_status !== "" ? previous_status : "N/A";

            setPreviouslyCompletedDate(previouslyCompleted);
            setPreviousFindings(previousFindings);
            setPreviousVerifier(previousVerifier);
            setPreviousStatus(reportPreviousStatus);

            setDutyHolderObject(dh);
            setAssetGroupObject(ag);
            setBarrierObject(bar);
            setInstallationObject(inst);
            setSeceObject(sece);
            setVerActivityObject(va);

            setReviewer(tempReviewer);

            setNature(reportNature ? reportNature : {});
            //setFrequency(reportFreq ? reportFreq : {});

            setVaFrequency(vaFreq);

            setPhase(reportPhase ? reportPhase : {});
            setStatement(reportConclusiveStatement ? reportConclusiveStatement : {});
            setStatus(reportCurrentStatus ? reportCurrentStatus : {});

            setReportObject(report);
            setLinkedDocList(linkedDocList);

            setSelectedLinkedActivityList(linkedVaIds);
            setSelectedInteractingSeceList(interactingSeceIds);
            setBackGroundActivityList(bgdActList);

            setReportAttachmentList(reportAttachments);

            const { _id: vaId = "" } = va;
            if (vaId && vaId !== "") {
                verificationActivity({ variables: { vaId: vaId }, errorPolicy: 'all' });
            }
            setShouldSpin(false);
        }
    }, [reportData]);

    useEffect(() => {
        if (findingData && findingData.findingsByReportId) {
            setFindingList(findingData.findingsByReportId);
        }
    }, [findingData]);

    const toggleModal = () => {
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }
    const columns = React.useMemo(
        () => [
            {
                Header: 'Finding Ref',
                accessor: 'finding_ref', // accessor is the "key" in the data
            },
            {
                Header: 'Synopsis',
                accessor: 'finding_synopsis',
            },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: ({ value: { _id } }) => (
                    <div className="text-right">
                        {_id && _id !== "" ?
                            <Link
                                to={{ 
                                    pathname: `/reporting/finding/view/${_id}`,
                                    search: "?origin=activity-report"  
                                }}
                                className="button-red mb-1 mr-1 text-xs"
                                target={"_blank"}
                            >
                                View
                            </Link> :
                            <></>
                        }
                    </div>
                )
            },
        ],
        []
    )

    if (shouldSpin) {
        return (
            <PageSpinner />
        )
    }

    if (vaError || seceError) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">
            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase"> View Report </h1>
            </div>

            <Formik
                enableReinitialize
                initialValues={{}}
                validationSchema={{}}
                onSubmit={(values, { actions }) => {}}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    setFieldTouched,
                    submitForm,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="my-4 flex">
                            {/*navOrigin !== "" && navOrigin !== "view-activity" ?
                                <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>
                                : <></>
                            */}
                            {/*<Link to={`/reporting/report-search`} className="button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>*/}
                        </div>

                        <div className="block overflow-x-auto">
                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    {/* 
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Date Raised</th>
                                        <td className="bg-gray-100">
                                            {reportObject.report_added_date && reportObject.report_added_date !== "" ? formatDateToLocaleDateString(reportObject.report_added_date) : "N/A"}
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Date Closed</th>
                                        <td className="bg-gray-100">
                                            {reportObject.report_closed_date && reportObject.report_closed_date !== "" ? formatDateToLocaleDateString(reportObject.report_closed_date) : "N/A"}
                                        </td>
                                    </tr> 
                                    */}
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Duty Holder</th>
                                        <td>
                                            {dutyHolderObject && dutyHolderObject.dh_name ? dutyHolderObject.dh_name : "N/A"}
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Asset Group</th>
                                        <td>
                                            {assetGroupObject && assetGroupObject.ag_title ? assetGroupObject.ag_title: "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Installation</th>
                                        <td className="bg-gray-100">
                                            {installationObject && installationObject.installation_title ? installationObject.installation_title : "N/A"}
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Barrier</th>
                                        <td className="bg-gray-100">
                                            {barrierObject && barrierObject.bar_title ? barrierObject.bar_title :  "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">SECE Ref.</th>
                                        <td>{seceObject && seceObject.sece_client_ref ? seceObject.sece_client_ref: "N/A"}</td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">SECE Name</th>
                                        <td>
                                            {seceObject && seceObject._id ?
                                                <Link to={{ pathname: `/seces/view/${seceObject._id}` }} className="hover:underline">{seceObject && seceObject.sece_title ? seceObject.sece_title : "N/A"}</Link>
                                                :
                                                <span>{seceObject && seceObject.sece_title ? seceObject.sece_title : "N/A"}</span>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Verification Activity Ref.</th>
                                        <td className="bg-gray-100">
                                            {verActivityObject && verActivityObject._id && verActivityObject.va_client_ref ? (
                                                <Link
                                                    to={{ pathname: `/verification-activities/view/${verActivityObject._id}` }}
                                                    className="hover:underline"
                                                    target="blank"
                                                >
                                                    {verActivityObject.va_client_ref}
                                                </Link>
                                            ) : (
                                                <span>{"N/A"}</span>
                                            )}
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Verification Activity Short Description</th>
                                        <td className="bg-gray-100">{verActivityObject.va_short_desc ? verActivityObject.va_short_desc : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Nature</th>
                                        <td>
                                            <div className="mt-5">
                                                {nature.sysvar_title ? nature.sysvar_title : "N/A"}
                                            </div>
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Frequency</th>
                                        {/* <td>
                                            <div className="mt-5">
                                                {frequency.sysvar_title ? frequency.sysvar_title : "N/A"}
                                            </div>
                                        </td> */}
                                        <td>
                                            <div className="mt-5">
                                                {vaFrequency}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Criticality</th>
                                        <td className="bg-gray-100">{seceObject.sece_criticality ? seceObject.sece_criticality : "N/A"}</td>

                                        <th className="bg-blue-800 font-sans font-semibold text-white">Confidence</th>
                                        <td className="bg-gray-100">{reportObject.report_confidence ? reportObject.report_confidence : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Required Sample</th>
                                        <td>{verActivityObject.va_baseline_sample ? verActivityObject.va_baseline_sample : "N/A"}</td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Completed Sample</th>
                                        <td>
                                            <div className="mt-5">
                                                {reportObject.report_comp_sample && reportObject.report_comp_sample !== ""? reportObject.report_comp_sample: "N/A"}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Previously Completed</th>
                                        <td className="bg-gray-100">{previouslyCompletedDate}</td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Previous Verifier</th>
                                        <td className="bg-gray-100">{previousVerifier}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Previous Status</th>
                                        <td>{previousStatus}</td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Previous Findings</th>
                                        <td>
                                            {previousFindings.length ? previousFindings.map((finding, index) =>
                                                <>
                                                    <Link
                                                        to={{ pathname: `/reporting/finding/view/${finding._id}` }}
                                                        className="hover:underline"
                                                        target="blank"
                                                    >
                                                        {finding.finding_ref}
                                                    </Link>
                                                    <span>
                                                        {index + 1 < previousFindings.length ? <span>{", "}</span> : <span></span>}
                                                    </span>
                                                </>
                                            ) : <span>{"N/A"}</span>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Completion Date</th>
                                        <td className="bg-gray-100">{reportObject.report_closed_date && reportObject.report_closed_date !== "" ? formatDateToLocaleDateString(reportObject.report_closed_date) : "N/A"}</td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Verifier</th>
                                        <td className="bg-gray-100">{reviewer}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Current Status</th>
                                        <td>
                                            <div className="mt-5">
                                                {status.sysvar_title ? status.sysvar_title : "N/A"}
                                            </div>
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Phase</th>
                                        <td>
                                            <div className="mt-5">
                                                {phase.sysvar_title ? phase.sysvar_title : "N/A"}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Time</th>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Prior Norm</th>
                                        <td>
                                            <div className="mt-5">
                                                {reportObject.report_prior_norm ? reportObject.report_prior_norm : "N/A"}
                                            </div>
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Time Taken</th>
                                        <td>
                                            <div className="mt-5">
                                                {reportObject.report_time_taken ? reportObject.report_time_taken : "N/A"}
                                            </div>
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Discrepancy</th>
                                        <td>{reportObject.report_discrepancy ? reportObject.report_discrepancy : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white w-1/2" colSpan="3">Justification</th>
                                        <td colSpan="3">
                                            <div className="mt-5">
                                                {reportObject.report_justification ? reportObject.report_justification : "N/A"}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Linked Documentation</th>
                                    </tr>
                                    {linkedDocList.map((item, index) =>
                                        <table className="table-report w-full border mb-2" key={index}>
                                            <tbody>
                                                <tr>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white w-1/2" colSpan="3"></th>
                                                    <td colSpan="3" className="bg-blue-800 font-sans font-semibold text-white"></td>
                                                </tr>
                                                <tr>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white w-1/2" colSpan="3">Document Name</th>
                                                    <td colSpan="3" className="bg-gray-100">{item.ld_name}</td>
                                                </tr>
                                                <tr>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white w-1/2" colSpan="3">Detailed Description</th>
                                                    <td colSpan="3">{item.ld_description}</td>
                                                </tr>
                                                <tr>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white w-1/2" colSpan="3">Attachments</th>
                                                    <td colSpan="3" className="bg-gray-100">
                                                        {createFileLinks(item.ld_attachement, reportObject.rpt_ld_attmnt_dest_path)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )}
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">
                                            {vaPerfStand && vaPerfStand._id ?
                                                (<Link to={{ pathname: `/performance-standards/view/${vaPerfStand._id}` }}
                                                    className="hover:underline">
                                                    Performance Standard Criteria
                                                </Link>) :
                                                (<span>Performance Standard Criteria</span>)
                                            }
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                {vaPerfStand.ps_criteria && vaPerfStand.ps_criteria !== "" ?
                                                    <Sanitiser html={vaPerfStand.ps_criteria} /> : "N/A"
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Details of Verification Completed <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {reportObject.report_verification_details && reportObject.report_verification_details !== "" ?
                                                <Sanitiser html={reportObject.report_verification_details} /> :
                                                "N/A"
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Conclusive Statement</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="mt-5">
                                                {statement.sysvar_title ? statement.sysvar_title : "N/A"}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Findings Raised</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="w-full px-8 pb-8">
                                                <div className="mb-4 pb-6 border-b-2 border-gray-200">
                                                </div>
                                                {findingList.length > 0 ?
                                                    <Table columns={columns} data={shortenFndSynopsis(findingList)} pagination={false} filter={false} />
                                                    : "No Data To Display"
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Attachments <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {createFileLinks(reportAttachmentList, reportObject.rpt_attmnt_dest_path)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="3">Background Activities <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    {bckGrdQuestionList.map((question, index) =>
                                        <tr key={question._id}>
                                            <th className={((index + 1) % 2) !== 0 ? "bg-blue-800 font-sans font-semibold text-white w-1/2" : "bg-blue-900 font-sans font-semibold text-white w-1/2"}>{question.sysvar_title}</th>
                                            <td className={((index + 1) % 2) !== 0 ? "" : "bg-gray-100"}>
                                                <div className="mt-5">
                                                    {getAnswerValue(answerOptionList, backGroundActivityList, question._id)}
                                                </div>
                                            </td>
                                            <td className={((index + 1) % 2) !== 0 ? "bg-gray-100" : ""}>
                                                {createFileLinks(getBckGrndEvidence(backGroundActivityList, question._id), reportObject.rpt_ba_attmnt_dest_path)}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="7">Directly Linked Activities</th>
                                    </tr>
                                    {selectedLinkedActivityList.map((act, index) =>
                                        <React.Fragment key={index}>
                                            {((index + 1) % 2) !== 0 ?
                                                <tr>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">Verification Activity Ref</th>
                                                    <td><Link to={{
                                                        pathname: act.activityNo !== "Not Available" ? `/reporting/view-linked-activity/${act.id}` : "#",
                                                        state: { formMode: formMode, actId: actId, verId: verId }
                                                    }}
                                                        onClick={() => { }}
                                                        className="hover:underline">{act.va_client_ref ? act.va_client_ref : "N/A"}</Link></td>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">Activity Short Description</th>
                                                    <td>{act.va_short_desc}</td>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">Current Status</th>
                                                    <td className="capitalize">{act.va_status ? act.va_status : "N/A"}</td>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white"></th>
                                                </tr>
                                                :
                                                <tr>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">Activity No</th>
                                                    <td className="bg-gray-100"><Link to="#" className="hover:underline">{act.va_client_ref ? act.va_client_ref : "N/A"}</Link></td>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">Activity Short Description</th>
                                                    <td className="bg-gray-100">{act.va_short_desc}</td>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">Current Status</th>
                                                    <td className="capitalize">{act.va_status ? act.va_status : "N/A"}</td>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white"></th>
                                                </tr>
                                            }
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>

                            <table className="table-report w-full border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="7">Interacting SECEs</th>
                                    </tr>

                                    {selectedInteractingSeceList.map((sece, index) =>
                                        <React.Fragment key={index}>
                                            {((index + 1) % 2) !== 0 ?
                                                <tr>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">SECE No</th>
                                                    <td>{sece.sece_ref ? sece.sece_ref : "N/A"}</td>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">SECE Title</th>
                                                    <td>{sece.sece_title ? sece.sece_title : "N/A"}</td>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">Current Status</th>
                                                    <td>{sece.sece_status ? sece.sece_status : "N/A"}</td>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white"></th>
                                                </tr>
                                                :
                                                <tr>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">SECE No</th>
                                                    <td className="bg-gray-100">{sece.sece_ref ? sece.sece_ref : "N/A"}</td>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">SECE Name</th>
                                                    <td className="bg-gray-100">{sece.sece_title ? sece.sece_title : "N/A"}</td>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">Current Status</th>
                                                    <td className="bg-gray-100">{sece.sece_status ? sece.sece_status : "N/A"}</td>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white"></th>
                                                </tr>
                                            }
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        
                        <div className="my-4 flex">
                            {/*navOrigin !== "" && navOrigin !== "view-activity" ?
                                <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>
                                : <></>
                                        */}
                            {/*<Link to={`/reporting/report-search`} className="button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>*/}
                        </div>
                    </form>
                )}
            </Formik>

            {/* HELP TIP */}
            <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

                    <h3 className="mb-2 text-xl text-blue-900 font-bold">Help Tip</h3>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultricies nunc id vehicula pulvinar. Sed vitae luctus purus. Integer feugiat nisi porta lacus cursus, sed sollicitudin orci mattis. Praesent euismod ornare tortor eget aliquam. Vivamus fermentum convallis erat, at ultrices odio. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>

                </div>
            </div>
            {/* HELP TIP END */}
        </div>
    );
}

function formatSysVarDataAndUpdateStates(list, setNatureList, setPeriodicityList, setPhaseList, setStatementList, setActivityStatusList, setBckGrdQuestionList, setAnswerOptionList, natureTypeId, periodicityId, phaseTypeId, statementTypeId, activityStatusTypeId, questionTypeId, answerTypeId) {
    let natureList = [];
    let periodicityList = [];
    let phaseList = [];
    let statementList = [];
    let activityStatusList = [];
    let questionList = [];
    let answerList = [];

    natureList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === natureTypeId);
    periodicityList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === periodicityId);
    phaseList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === phaseTypeId);
    statementList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === statementTypeId);
    activityStatusList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === activityStatusTypeId);
    questionList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === questionTypeId);
    answerList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === answerTypeId);

    setNatureList(natureList);
    setPeriodicityList(periodicityList);
    setPhaseList(phaseList);
    setStatementList(statementList);
    setActivityStatusList(activityStatusList);
    setBckGrdQuestionList(questionList);
    setAnswerOptionList(answerList);
}

//function formatVsAndUpdateState(verificationSchedule, setVerScheduleObject, setLinkedActivityList, setInteractingSeceList) {
//   let linkedActList = [];
//   let interactingSeceList = [];
//
//    let vaList = verificationSchedule.va_ids ? verificationSchedule.va_ids : [];
//    let seceList = verificationSchedule.sece_ids ? verificationSchedule.sece_ids : [];
//
//    vaList.forEach(va => {
//        let verAct = {
//            id: va._id,
//            activityNo: va.va_client_ref ? va.va_client_ref : "Not Available",
//            activityShortDescription: va.va_short_desc,
//            currentStatus: va.va_status ? va.va_status : "Not Available"
//       }
//        linkedActList.push(verAct);
//    });
//
//  seceList.forEach(se => {
//    let sece = {
//      id: se._id,
//    seceNo: se.sece_ref ? se.sece_ref : "Not Available",
//           seceClientRef: se.sece_client_ref ? se.sece_client_ref : "Not Available",
//            seceTitle: se.sece_title,
//            currentStatus: se.sece_status ? se.sece_status : "Not Available"
//        }
//        interactingSeceList.push(sece);
//    });
//
//    setVerScheduleObject(verificationSchedule);
//   setLinkedActivityList(linkedActList);
//    setInteractingSeceList(interactingSeceList);
//}

//function createBackgndActForEdit(backgndAct) {
//    let bckgndActObject = {};
//   if (backgndAct && backgndAct.length) {
//       backgndAct.forEach((bckAct, index) => {
//            bckgndActObject[`report_bck_acts_${index}`] = bckAct.answer_id
//        });
//    }
//    return bckgndActObject;
//}

//function getDiscrepancy(priorNorm, timeTaken) {   
//    let discrepancy = 0;
//    if (timeTaken > priorNorm) {
//        discrepancy = (priorNorm + timeTaken);
//    }
//    if (priorNorm > timeTaken) {
//        discrepancy = (priorNorm - timeTaken);
//    }
//    return discrepancy;
//}

//function createSelectedLinkedVas(vaIdList, linkedVaList) {
//    let selectedVaList = [];
//    if (vaIdList && vaIdList.length && linkedVaList && linkedVaList.length) {
//        vaIdList.forEach(vaOb => {
//            let linkedVa = linkedVaList.find(va => va.id === vaOb._id);
//            if (linkedVa) {
//                selectedVaList.push(linkedVa);
//            }
//        })
//    }
//    return selectedVaList;
//}

//function createSelectedInteractingSeces(seceIdList, interSeceList) {
//    let selectedInterSeceList = [];
//    if (seceIdList && seceIdList.length && interSeceList && interSeceList.length) {
//        seceIdList.forEach(seceOb => {
//            let interSece = interSeceList.find(sece=> sece.id === seceOb._id);
//            if (interSece) {
//                selectedInterSeceList.push(interSece);
//            }
//        })
//    }
//    return selectedInterSeceList;
//}

function createFileLinks(attachments, filePath) {
    let linkList = "";
    let fileDestPath = filePath ? filePath : "";
    if (attachments && attachments.length > 0 && fileDestPath !== "") {
        let fullPath = `${REACT_APP_FILE_MANAGER_API}/download-file?path=/${fileDestPath}/`;
        let numberOfAttachments = attachments.length;
        let newAttachments = <React.Fragment>{attachments.map((att, index) =>
            <React.Fragment key={index}>
                <div className="text-blue-900 font-sans text-sm font-bold">File Name:</div>
                <a href={fullPath + att.name} className="mb-1 hover:underline" key={index} target={"_blank"} rel="noopener noreferrer" download={true}>
                    {att.name}{((index + 1) < numberOfAttachments) ? <br /> : ""}
                </a>
                <div className="text-blue-900 font-sans text-sm font-bold">File Description:</div>
                <div>
                    {att.description}
                </div>
                {((index + 1) < numberOfAttachments) ? <br /> : ""}
            </React.Fragment>
        )}
        </React.Fragment>
        linkList = newAttachments;
    } else {
        linkList = <Link to="#" className="hover:underline">No File Attachment</Link>
    }
    return linkList;
}

function getBckGrndEvidence(list, id) {
    let evidence = [];
    if (list && list.length && id) {
        let tempEvidence = list.find(li => li.question_id === id);
        evidence = tempEvidence ? tempEvidence.evidence : [];
    }
    return evidence;
}

function getAnswerValue(answerOptionList, backGroundActivityList, questionId) {
    let value = "N/A";
    if (answerOptionList && backGroundActivityList && questionId) {
        var answerObj = backGroundActivityList.find(b => b.question_id === questionId);
        const { answer_id = "" } = answerObj ? answerObj : {};
        let answerOption = answerOptionList.find(a => a._id === answer_id)
        const { sysvar_title = "N/A" } = answerOption ? answerOption : {};
        value = sysvar_title;
    }
    return value;
}

function shortenFndSynopsis(fndList) {
    let newFndList = [];

    if (fndList && fndList.length) {

        fndList.forEach(element => {

            const { finding_synopsis = "" } = element ? element : {};
            let fndSynopsis = truncateString(finding_synopsis, 100);
            let fnd = {
                ...element,
                finding_synopsis: fndSynopsis
            }

            newFndList.push(fnd);
        });
    }

    return newFndList;
}

export default ViewReport;