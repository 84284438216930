/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useContext} from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import Sanitiser from "../global/sanitiser";
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';
import {parseJSONSafely} from "../../utils";
import {NavContext} from '../../context/navContext';
var htmlToPdfmake = require("html-to-pdfmake");

const {REACT_APP_FILE_MANAGER_API} = process.env;

const PS_QUERY = gql`
query PerformanceStandard($psId: ID!){
    performanceStandard(_id: $psId) {
        dh_id{
            _id
            dh_name
        }
        ps_ref
        ps_title
        ps_desc 
        ps_criteria
        ag_id{
            _id
            ag_title
        }
        installation_id{
          _id
          installation_title
        }
        bar_id{
          _id
          bar_title
        }
        sece_id{
            _id
            sece_title
        }
        ps_standard_basis 
        ps_attachments
        ps_source
        ps_assurance
        ps_attmnt_dest_path
        ps_scope
        ps_criticality
        ps_status
    }
}`;

    const VA_BY_PS_ID_QUERY = gql`
    query VerificationActivitiesByPSId ($psId: ID!){
        verificationActivitiesByPSId (psId: $psId){
            _id
            sece_id{
                sece_title
            }
            ps_id{
                ps_title
            }
            va_client_ref
            va_short_desc
            va_long_desc
            va_type
            va_onshore_offshore
            va_freq
            va_baseline_sample
            va_class
            va_legislation
            va_base_path
            va_archived
            va_archived_id
            va_archived_date
            va_status
        }
    }`;

const ViewPerformanceStandard = () => {
    const [ps, setPs] = useState({});
    const [psAttachments, setPsAttachments] = useState([]);
    const [standardBasesAttachments, setstandardBasesAttachments] = useState([]);
    const [vaList, setVaList] = useState([]);

    const [drDnInstallation, setDrDnInstallation] = useState({});

    const { psId = "" } = useParams();

    const navContext = useContext(NavContext);
    const history = useHistory();

    const [performanceStandard, {loading, error, data }] = useLazyQuery(PS_QUERY);
    const [verificationActivitiesByPSId, {data: vaData }] = useLazyQuery(VA_BY_PS_ID_QUERY);

    useEffect(() => {
        if (psId && psId !== "") {
            performanceStandard({ variables: { psId: psId }, errorPolicy: 'all' });
            verificationActivitiesByPSId({ variables: { psId: psId }, errorPolicy: 'all' });
        }
    }, []);

    useEffect(() => {
        const { installation } = navContext;
        const { id: prevId } = drDnInstallation;
    
        if (prevId && installation.id && prevId !== installation.id) {
          history.push('/');
        }
        setDrDnInstallation(installation);
    }, [navContext]);
    
    useEffect(() => {
        if (data && data.performanceStandard) {
            let perfStand = data.performanceStandard;
            let attachments = perfStand.ps_attachments && perfStand.ps_attachments !== "" ? perfStand.ps_attachments : "[]";
            let attachmentList = parseJSONSafely(attachments) ? parseJSONSafely(attachments) : [];

            let sbAttachments = perfStand.ps_standard_basis && perfStand.ps_standard_basis !== "" ? perfStand.ps_standard_basis : "[]";
            let sbAttachmentList = parseJSONSafely(sbAttachments) ? parseJSONSafely(sbAttachments) : [];

            setPs(perfStand);
            setPsAttachments(attachmentList);
            setstandardBasesAttachments(sbAttachmentList);
        }
    }, [data]);
 
    useEffect(() => {
        if (vaData && vaData.verificationActivitiesByPSId) {
            setVaList(vaData.verificationActivitiesByPSId);
        }
    }, [vaData]);

    var docDefinition = {
        pageSize: 'A4',
        pageMargins: [40, 100, 40, 24],
        header: function (currentPage, pageCount) {

            var headerObj;
            if (currentPage % 2) {
                headerObj = {
                    table: {
                        headerRows: 0,
                        widths: ['75%', '25%'],
                        body: [
                            [
                                {
                                    border: [false, false, true, false],
                                    fillColor: '#002a4e',
                                    stack: [
                                        { text: ps.dh_id && ps.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                                        [{
                                            canvas: [
                                                {
                                                    type: 'rect',
                                                    x: 0,
                                                    y: 0,
                                                    w: 30,
                                                    h: 0.5,
                                                    r: 0,
                                                    lineWidth: 1,
                                                    lineColor: 'red',
                                                }
                                            ]
                                        }],
                                        { text: "Performance Standard Details", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                                    ]
                                },
                                {
                                    border: [true, false, false, false],
                                    fillColor: '#b40c1e',
                                    image: logoImage,
                                    width: 100
                                },
                            ]
                        ],
                    },
                    layout: {
                        vLineColor: function (i, node) { return 'white'; },
                        vLineWidth: function (i) { return 4; },
                        paddingLeft: function (i, node) { return 30; },
                        paddingRight: function (i, node) { return 10; },
                        paddingTop: function (i, node) { return 20; },
                        paddingBottom: function (i, node) { return 10; },
                    }
                };
            } else {
                headerObj = {
                    table: {
                        headerRows: 0,
                        widths: ['25%', '75%'],
                        body: [
                            [
                                {
                                    border: [false, false, true, false],
                                    fillColor: '#b40c1e',
                                    image: logoImage,
                                    width: 100
                                },
                                {
                                    border: [true, false, false, false],
                                    fillColor: '#002a4e',
                                    stack: [
                                        { text: ps.dh_id && ps.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                                        [{
                                            canvas: [
                                                {
                                                    type: 'rect',
                                                    x: 0,
                                                    y: 0,
                                                    w: 30,
                                                    h: 0.5,
                                                    r: 0,
                                                    lineWidth: 1,
                                                    lineColor: 'red',
                                                }
                                            ]
                                        }],
                                        { text: "Performance Standard Details", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                                    ]
                                },
                            ]
                        ],
                    },
                    layout: {
                        vLineColor: function (i, node) { return 'white'; },
                        vLineWidth: function (i) { return 4; },
                        paddingLeft: function (i, node) { return 30; },
                        paddingRight: function (i, node) { return 10; },
                        paddingTop: function (i, node) { return 20; },
                        paddingBottom: function (i, node) { return 10; },
                    }
                };
            }

            return headerObj;
        },
        footer: function (currentPage, pageCount) {

            var footerObj;

            if (currentPage % 2) {
                footerObj = {
                    table: {
                        widths: [30, '*', 100, 28],
                        body: [
                            [
                                { text: ' ', border: [false, false, false, false] },
                                { text: 'PERFORMANCE STANDARD DETAILS', style: 'footerText', border: [false, false, false, false] },
                                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                                { text: ' ', border: [true, false, false, false] }
                            ]
                        ]
                    },
                    layout: {
                        vLineColor: function (i, node) { return '#b40c1e'; },
                    }
                }
            } else {
                footerObj = {
                    table: {
                        widths: [30, 100, '*', 28],
                        body: [
                            [
                                { text: ' ', border: [false, false, true, false] },
                                { text: currentPage.toString(), border: [true, false, false, false] },
                                { text: `Copyright © by ABS. All Rights Reserved. All rights reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                                { text: ' ', border: [false, false, false, false] }
                            ]
                        ]
                    },
                    layout: {
                        vLineColor: function (i, node) { return '#b40c1e'; },
                    }
                }
            }

            return footerObj;

        },
        info: {
            title: ps.dh_id && ps.dh_id.dh_name + ' - ' + ps.ps_title,
        },
        content: [

            { text: ps.ps_title, style: 'title' },
            { text: ps.ps_ref, style: 'redTitle', bold: true },
            {
                table: {
                    widths: ['25%', '25%', '25%', '25%'],
                    headerRows: 0,
                    body: [
                        [{ text: 'Installation', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, ps.installation_id && ps.installation_id.installation_title, { text: 'Barrier', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, ps.bar_id && ps.bar_id.bar_title],
                        [{ text: 'SECE', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, ps.sece_id && ps.sece_id.sece_title, { text: 'Criticality', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, ps.ps_criticality],
                        [{ text: 'Function', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 4 }, {}, {}, {}],
                        [{ stack: [htmlToPdfmake(ps.ps_desc)], colSpan: 4 }, {}, {}, {}],
                        [{ text: 'Criteria', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 4 }, {}, {}, {}],
                        [{ stack: [htmlToPdfmake(ps.ps_criteria)], colSpan: 4 }, {}, {}, {}],
                        [{ text: 'Source', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 4 }, {}, {}, {}],
                        [{ stack: [(ps.ps_source ? htmlToPdfmake(ps.ps_source) : "N/A")], colSpan: 4 }, {}, {}, {}],
                        
                        [{ text: 'Scope/Boundry', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 4 }, {}, {}, {}],
                        [{ stack: [htmlToPdfmake(ps.ps_scope)], colSpan: 4 }, {}, {}, {}],
                        [{ text: 'Assurance', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 4 }, {}, {}, {}],
                        [{ stack: [(ps.ps_assurance ? htmlToPdfmake(ps.ps_assurance) : "N/A")], colSpan: 4 }, {}, {}, {}],

                        [{ text: 'Standard Basis', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 4 }, {}, {}, {}],
                        [{
                            text: [
                                {
                                    text: standardBasesAttachments && standardBasesAttachments.length ? standardBasesAttachments.map(stanBasis => {
                                        return (
                                            "File Name: \n" +
                                            stanBasis.name + '\n' +
                                            "File Description: \n" +
                                            stanBasis.description + '\n \n'
                                        )
                                    }) : "No Attachments to Display"
                                },
                            ], colSpan: 4
                        }, {}, {}, {}],
                        
                        [{ text: 'Attachments', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 4 }, {}, {}, {}],
                        [{
                            text: [
                                {
                                    text: psAttachments && psAttachments.length ? psAttachments.map(att => {
                                        return (
                                            "File Name: \n" +
                                            att.name + '\n' +
                                            "File Description: \n" +
                                            att.description + '\n \n'
                                        )
                                    }) : "No Attachments to Display"
                                },
                            ], colSpan: 4
                        }, {}, {}, {}],

                        [{ text: 'Verification Activities', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 4 }, {}, {}, {}],
                        [{
                            text: [
                                {
                                    text: vaList.length ? vaList.map(va => {
                                        return (va.va_client_ref + " - " + va.va_short_desc + '\n')
                                    }) : "No Verification Activities to Display"
                                },
                            ], colSpan: 4
                        }, {}, {}, {}],

                        [{ text: 'Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'], colSpan: 2 }, {}, {text: ps.ps_status, colSpan: 2}, {}],
                        
                    ]
                },
                style: 'standardTable',
            },

        ],
        defaultStyle: {
            font: 'Tinos',
            fontSize: 10,
            color: '#231f20',
            lineHeight: 1.4
        },
        styles: {
            title: {
                marginTop: 10,
                font: 'Montserrat',
                fontSize: 14,
                bold: true,
                color: '#002a4e'
            },
            redTitle: {
                marginBottom: 10,
                font: 'Montserrat',
                color: '#b40c1e'
            },
            standardTable: {
                margin: [0, 5, 0, 20],
                lineHeight: 1.2
            },
            StandardTableHeader: {
                fillColor: '#002a4e',
                color: '#fff',
                lineHeight: 1
            },
            footerTitle: {
                font: 'Montserrat',
                color: '#002a4e',
            },
            footerText: {
                font: 'Montserrat',
                fontSize: 10
            }
        }
    }
  
      const pdfTab = () => {
          pdfMake.createPdf(docDefinition).open();
      }

    if (loading) {
      return (
        <PageSpinner/>
      )
    }

    if (error) {
      return (
        <span>Something went wrong retrieving the Performance Standard data</span>
      )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="mb-6 pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Performance Standard Details</h1>
            </div>

            <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>
            
            <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

            <div className="w-full lg:w-1/2">

                <table className="w-full my-5 table-auto">
                    <tbody>
                        <tr>
                            <th className="p-3 text-left bg-white border">Duty Holder</th>
                            <td className="p-3 border">{ps.dh_id && ps.dh_id.dh_name}</td>
                        </tr>
                        <tr>
                            <th className="p-3 text-left bg-white border">Performance Standard Name</th>
                            <td className="p-3 border">{ps.ps_title}</td>
                        </tr>
                        <tr>
                            <th className="p-3 text-left bg-white border">Performance Standard Ref</th>
                            <td className="p-3 border">{ps.ps_ref}</td>
                        </tr>
                        <tr>
                            <th className="p-3 text-left bg-white border">Installation</th>
                            <td className="p-3 border">{ps.installation_id && ps.installation_id.installation_title}</td>
                        </tr>
                        <tr>
                            <th className="p-3 text-left bg-white border">Barrier</th>
                            <td className="p-3 border">{ps.bar_id && ps.bar_id.bar_title}</td>
                        </tr>
                        <tr>
                            <th className="p-3 text-left bg-white border">SECE</th>
                            <td className="p-3 border">{ps.sece_id && ps.sece_id.sece_title}</td>
                        </tr>
                        <tr>
                            <th className="p-3 text-left bg-white border">Criticality</th>
                            <td className="p-3 border font-bold">
                                <span
                                    className={
                                        ps.ps_criticality === 'High' || ps.ps_criticality === '3' || ps.ps_criticality === 'C' ? 'text-green-900' :
                                            ps.ps_criticality === 'Medium' || ps.ps_criticality === '2' || ps.ps_criticality === 'B' ? 'text-yellow-900' :
                                                ps.ps_criticality === 'Low' || ps.ps_criticality === '1' || ps.ps_criticality === 'A' ? 'text-red-900' : null
                                    }
                                >
                                    {ps.ps_criticality}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="table-finding mb-6 pb-6 border-b-2 border-gray-200">
                    <h2 className="mb-3 font-bold text-xl text-blue-900">Function</h2>
                    <Sanitiser html={ps.ps_desc} />
                </div>

                <div className="table-finding mb-6 pb-6 border-b-2 border-gray-200">
                    <h2 className="mb-3 font-bold text-xl text-blue-900">Criteria</h2>
                    <Sanitiser html={ps.ps_criteria} />
                </div>

                <div className="mb-6 pb-6 border-b-2 border-gray-200">
                    <h2 className="mb-3 font-bold text-xl text-blue-900">Verification Activities</h2>
                    <div>
                        {vaList.length ? vaList.map(va =>
                            <Link key={va._id}
                                to={{ pathname: `/verification-activities/view/${va._id}` }}
                                className="block hover:underline">{va.va_client_ref} - {va.va_short_desc}</Link>
                        ) : <p>No Verification Activities to Display</p>
                        }
                    </div>
                </div>

                <div className="table-finding mb-6 pb-6 border-b-2 border-gray-200">
                    <h2 className="mb-3 font-bold text-xl text-blue-900">Source</h2>
                    <Sanitiser html={ps.ps_source ? ps.ps_source : "N/A"} />
                </div>

                <div className="table-finding mb-6 pb-6 border-b-2 border-gray-200">
                    <h2 className="mb-3 font-bold text-xl text-blue-900">Scope/Boundry</h2>
                    <Sanitiser html={ps.ps_scope}/>
                </div>

                <div className="table-finding mb-6 pb-6 border-b-2 border-gray-200">
                    <h2 className="mb-3 font-bold text-xl text-blue-900">Assurance</h2>
                    <Sanitiser html={ps.ps_assurance ? ps.ps_assurance : "N/A"} />
                </div>

                <div className="mb-6 pb-6 border-b-2 border-gray-200">
                    <h2 className="mb-3 font-bold text-xl text-blue-900">Standard Basis</h2>
                    {createFileLinks(standardBasesAttachments, ps)}
                </div>

                <div className="mb-6 pb-6 border-b-2 border-gray-200">
                    <h2 className="mb-3 font-bold text-xl text-blue-900">Attachments</h2>
                    {createFileLinks(psAttachments, ps)}
                </div>

                <div className="mb-6 pb-6 border-b-2 border-gray-200">
                    <h2 className="mb-3 font-bold text-xl text-blue-900">Status</h2>
                    <p>{ps.ps_status}</p>
                </div>

                <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>
                <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

            </div>

        </div>
    )

}

function createFileLinks(attachments, ps) {
    let linkList = "";
    let fileDestPath = ps.ps_attmnt_dest_path ? ps.ps_attmnt_dest_path : "";
    if (attachments && attachments.length && fileDestPath !== "") {
        let fullPath = `${REACT_APP_FILE_MANAGER_API}/download-file?path=/${fileDestPath}/`;
        let numberOfAttachments = attachments.length;
        let newAttachments = <React.Fragment>{attachments.map((att, index) =>
            <div className="font-sans text-blue-900" key={index}>
                <React.Fragment>
                    <div className="text-blue-900 font-sans text-sm font-bold">File Name:</div>
                    <a href={fullPath + att.name} className="mb-1 hover:underline" key={index} target={"_blank"} rel="noopener noreferrer" download={true}>
                        {att.name}{((index + 1) < numberOfAttachments) ? <br /> : ""}
                    </a>
                    <div className="text-blue-900 font-sans text-sm font-bold">File Description:</div>
                    <div>
                        {att.description}
                    </div>
                    {((index + 1) < numberOfAttachments) ? <br /> : ""}
                </React.Fragment>
            </div>
        )}
        </React.Fragment>
        linkList = newAttachments;
    } else {
        linkList = <p className="font-sans text-blue-900">No File Attachments</p>
    }
    return linkList;
}

export default ViewPerformanceStandard;
