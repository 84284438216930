import React, {Component} from 'react';
import dompurify from 'dompurify';
import parse from 'html-react-parser';

class Sanitiser extends Component{

    render(){
        const html = this.props.html;
        const sanitizer = dompurify.sanitize;
        const sanitisedHTML = sanitizer(html);

        return <div>{parse(sanitisedHTML)}</div>;
    }

}

export default Sanitiser;