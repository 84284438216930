/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useState, useContext} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from "../global/pageSpinner";
import ReactDiffViewer from 'react-diff-viewer';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {omit} from "lodash";
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils";

const DUTY_HOLDER_HISTORY_DIFF_QUERY = gql`
      query DutyHolderHistoryDiff($id: String!, $parentId: String!){
          duty_holder_history_diff(_id: $id, parentId: $parentId) {
              dh_code
              dh_name
              dh_description
              dh_child_id
              dh_added_date
              dh_added_id
              dh_archived
              dh_status
              history_parent_record_id
              history_modifying_user_id {
                  _id
                  user_fname
                  user_lname
                  user_email
              }
              history_modified_date
              history_modified_date_timestamp
          }
      }
  `;

  const USER_QUERY = gql`
      query User($modifyingUserId: ID!){
          user(_id: $modifyingUserId) {
              user_location
              user_ms_oid
              user_email
              user_fname
              user_job_title
              user_lname
              user_mobile
              user_notes
              user_office_tel
              user_profile_image
              user_type {
                  _id
                  user_type_display_name
              }
          }
      }
  `;

const DutyHolderHistoryDiff = () => {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [dutyHolderHistoryDiff, setDutyHolderHistoryDiff] = useState([{}, {}]);
  const [modifyingUserId, setModifyingUserId] = useState('');
  const [userObj, setUserObj] = useState({})

  const { hisId = '', id: parentId = '' } = useParams();

  const [duty_holder_history_diff, { loading, error, data}] = useLazyQuery(DUTY_HOLDER_HISTORY_DIFF_QUERY);
  const [user, {data: userData }] = useLazyQuery(USER_QUERY);

  useEffect(() => {
  if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
  }
  }, [authContext]);

  useEffect(() => {
  if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
  }
  }, [userTypeAndRoleObject]);


  useEffect(() => {
    if (hisId !== "" && parentId !== "") {
      duty_holder_history_diff({ variables: { id: hisId, parentId: parentId }, errorPolicy: 'all' });
    }
  }, [])

  useEffect(() => {
    if (data && data.duty_holder_history_diff) {
      let hisDiff = data.duty_holder_history_diff;
      let firstHisDiff = hisDiff[0];
      let modUserId = firstHisDiff && firstHisDiff.history_modifying_user_id ? firstHisDiff.history_modifying_user_id._id : "";
      setDutyHolderHistoryDiff(hisDiff);
      setModifyingUserId(modUserId)
    }
  }, [data])

  useEffect(() => {
    if (modifyingUserId !== "") {
      user({ variables: { modifyingUserId }, errorPolicy: 'all' });
    }
  }, [modifyingUserId])

  useEffect(() => {
    if (userData && userData.user) {
      let tempUser = formatUserData(userData.user)
      setUserObj(tempUser)
    }
  }, [userData])

  if (loading) {
    return (
      <PageSpinner/>
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the diff data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Duty Holder History Change</h1>
      </div>
      <button onClick={() => {window.history.back()}} className="mr-2 button-red"><FontAwesomeIcon
        icon={['fas', 'angle-left']}/> Back</button>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Modifying User</h3>

      <img src={userObj.userProfileImage&&userObj.userProfileImage} alt={userObj.fullName&&userObj.fullName}
           className="mt-6 rounded-full w-24 max-w-full h-auto align-middle"/>

      <div className="w-full lg:w-1/2">

        <div className="my-6 pb-6 border-b-2 border-gray-200">
          <h2 className="font-bold text-2xl text-blue-900">{userObj.fullName&&userObj.fullName}</h2>
          <h3 className="mb-3 font-bold text-red-900">{userObj.userJobTitle&&userObj.userJobTitle}</h3>

          <p>Email: {userObj.email&&userObj.email}</p>
          <p>Tel: {userObj.tel&&userObj.tel}</p>
          <p>Mobile: {userObj.mobile&&userObj.mobile}</p>
        </div>
      </div>

      <div className="mt-6">
        <ReactDiffViewer
          oldValue={JSON.stringify(omit(dutyHolderHistoryDiff[1], ['__typename', 'history_modifying_user_id']), null, 2)}
          newValue={JSON.stringify(omit(dutyHolderHistoryDiff[0], ['__typename', 'history_modifying_user_id']), null, 2)}
          splitView={true}
        />
      </div>

    </div>
  )

}

function formatUserData(user) {
  let formatedUser = {};
  if (user) {

    const { user_profile_image, user_job_title, user_email, user_office_tel, user_mobile, user_fname, user_lname } = user;
    let fullName = (user_fname !== "" && user_lname !== "") ? `${user_fname} ${user_lname}` : "N/A";

    formatedUser = {
      userProfileImage: user_profile_image ? user_profile_image : "N/A",
      userJobTitle: user_job_title ? user_job_title : "N/A",
      email: user_email ? user_email : "N/A",
      tel: user_office_tel ? user_office_tel : "N/A",
      mobile: user_mobile ? user_mobile : "N/A",
      fullName: fullName
    }

  }

  return formatedUser
}

export default DutyHolderHistoryDiff;
