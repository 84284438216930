import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import ViewAssetVerification from "./viewAssetVerification";
import ViewPeriodicVerification from "./viewPeriodicVerification";
//import ViewAssetVerification from "./viewAssetVerificationAlt";
//import ViewPeriodicVerification from "./viewPeriodicVerificationAlt";
import {useQuery, stringToBoolean} from "../../utils";

function ViewVerificationScheduleReport(props) {
    const [periodic, setPeriodic] = useState("");
    var query = useQuery();
    var parameter = query.get("periodic");
    
    const { vsRptId = "" } = useParams();
    //const { location: { state: { isPeriodic = "" } = {} } = {} } = props ? props : {};
    const { location: { search = "" } } = props ? props : {};

    useEffect(() => {
        let searchQueryParams = new URLSearchParams(search);
        let isPeriodic = searchQueryParams.get("isPeriodic");
        setPeriodic(isPeriodic);
    }, [search]);

    useEffect(() => {
        if (parameter && parameter !== "") {
            var boolValue = stringToBoolean(parameter);
            setPeriodic(boolValue);
        }
    }, [parameter]);

    if (periodic === false) {
        return (
            <ViewAssetVerification vsRptId={vsRptId} />
        );
    }

    if (periodic === true) {
        return (
            <ViewPeriodicVerification vsRptId={vsRptId} /> 
        );
    }

    return (
        <div>
            <div></div>
            {/* <div>There is a problem displaying the page. Refresh the page to continue.</div> */}
        </div>
    );
}

export default ViewVerificationScheduleReport;