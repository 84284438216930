/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';
import {parseJSONSafely} from "../../utils";

const {REACT_APP_FILE_MANAGER_API} = process.env;

const VA_QUERY = gql`
query VerificationActivity($vaId: ID!){
    verificationActivity(_id: $vaId) {
        dh_id{
            _id
            dh_name
        }
        sece_id{
            _id
            sece_title
        }
        installation_id{
          _id
          installation_title
        }
        ps_id{
            _id
            ps_title
        }
        va_short_desc
        va_client_ref
        va_long_desc
        va_base_path
        va_type
        va_onshore_offshore
        va_freq
        va_baseline_sample
        va_class
        va_legislation
        va_class_attachments
        va_attmnt_dest_path
        va_status
    }
}`;

const ViewVerificationActivity = (props) => {
  const [va, setVa] = useState({});
  const [vaLegislationDocs, setVaLegislationDocs] = useState([]);
  const [vaClassAttachments, setVaClassAttachments] = useState([]);

  const { vaId = "" } = useParams();
  const { location: { state : { fromReport } = {} } = {} } = props ? props : {};

  const [verificationActivity, {loading, error, data: vaData }]  = useLazyQuery(VA_QUERY);

  useEffect(() => {
    if (fromReport) {
      localStorage.setItem('toView', "No");
    }

    if (vaId !== "") {
      verificationActivity({ variables: { vaId: vaId }, errorPolicy: 'all' });
    }
  }, []);

  useEffect(() => {
    if (vaData && vaData.verificationActivity) {
      let tempVaData = vaData.verificationActivity;
      let vaLegislations = tempVaData.va_legislation && tempVaData.va_legislation !== "" ? tempVaData.va_legislation : "[]";
      let vaLegisAttachments = parseJSONSafely(vaLegislations);

      let vaClassDocs = tempVaData.va_class_attachments && tempVaData.va_class_attachments !== "" ? tempVaData.va_class_attachments : "[]";
      let vaClassAttachList = parseJSONSafely(vaClassDocs);

      setVa(vaData.verificationActivity);
      setVaLegislationDocs(vaLegisAttachments);
      setVaClassAttachments(vaClassAttachList);
    }
  }, [vaData]);

  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 100, 40, 24],
    header: function (currentPage, pageCount) {

      var headerObj;
      if (currentPage % 2) {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['75%', '25%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: va.dh_id && va.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: "Verification Activity Details", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      } else {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['25%', '75%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: va.dh_id && va.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: "Verification Activity Details", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      }

      return headerObj;
    },
    footer: function (currentPage, pageCount) {

      var footerObj;

      if (currentPage % 2) {
        footerObj = {
          table: {
            widths: [30, '*', 100, 28],
            body: [
              [
                { text: ' ', border: [false, false, false, false] },
                { text: 'VERIFICATION ACTIVITY DETAILS', style: 'footerText', border: [false, false, false, false] },
                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                { text: ' ', border: [true, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      } else {
        footerObj = {
          table: {
            widths: [30, 100, '*', 28],
            body: [
              [
                { text: ' ', border: [false, false, true, false] },
                { text: currentPage.toString(), border: [true, false, false, false] },
                { text: `Copyright © by ABS. All Rights Reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                { text: ' ', border: [false, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      }

      return footerObj;

    },
    info: {
      title: va.dh_id && va.dh_id.dh_name + ' - ' + va.va_short_desc,
    },
    content: [

      { text: va.va_short_desc, style: 'title' },
      {
        table: {
          widths: ['30%', '70%'],
          headerRows: 0,
          body: [
            [{ text: 'VA Client Ref', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, va.va_client_ref],
            [{ text: 'SECE', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, va.sece_id && va.sece_id.sece_title],
            [{ text: 'Installation', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, va.installation_id && va.installation_id.installation_title],
            [{ text: 'Performance Standard', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, va.ps_id && va.ps_id.ps_title],
            [{ text: 'Description', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{ text: va.va_long_desc, alignment: 'left', colSpan: 2 }, {}],
            [{ text: 'Nature', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, va.va_type],
            [{ text: 'Onshore/Offshore', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, va.va_onshore_offshore],
            [{ text: 'Frequency', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, va.va_freq],
            [{ text: 'Baseline Sample', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, va.va_baseline_sample],
            [{ text: 'Class', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, va.va_class],
            [{ text: 'Class Attachments', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{
              text: [
                {
                  text: vaClassAttachments.length ? vaClassAttachments.map(att => {
                    return (
                      "File Name: \n" +
                      att.name + '\n' +
                      "File Description: \n" +
                      att.description + '\n \n'
                    )
                  }) : "No Documents to Display"
                },
              ], alignment: 'left', colSpan: 2
            }, {}],
            [{ text: 'Linked Documentation', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{
              text: [
                {
                  text: vaLegislationDocs.length ? vaLegislationDocs.map(att => {
                    return (
                      "File Name: \n" +
                      att.name + '\n' +
                      "File Description: \n" +
                      att.description + '\n \n'
                    )
                  }) : "No Documents to Display"
                },
              ], alignment: 'left', colSpan: 2
            }, {}],
            [{ text: 'Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'] }, va.va_status],
          ]
        },
        style: 'standardTable',
      },

    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      title: {
        marginTop: 10,
        font: 'Montserrat',
        fontSize: 14,
        bold: true,
        color: '#002a4e'
      },
      redTitle: {
        marginBottom: 10,
        font: 'Montserrat',
        color: '#b40c1e'
      },
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1.2
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }

  const pdfTab = () => {
    pdfMake.createPdf(docDefinition).open();
  }

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the Verification Activity data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Verification Activity Details</h1>
      </div>

      <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>
      <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      <div className="w-full lg:w-1/2">

        <div className="my-6 pb-6 border-b-2 border-gray-200">
          <h2 className="font-bold text-2xl text-blue-900">{va.va_short_desc}</h2>
        </div>

        <div className="mb-6">
          <h2 className="mb-3 font-bold text-xl text-blue-900">Description</h2>

          <p>{va.va_long_desc}</p>
        </div>

        <table className="w-full mb-5 table-auto">
          <tbody>
            <tr>
              <th className="p-3 text-left bg-white border">VA Ref.</th>
              <td className="p-3 border">{va.va_client_ref}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">SECE</th>
              <td className="p-3 border">{va.sece_id && va.sece_id.sece_title}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Installation</th>
              <td className="p-3 border">{va.installation_id && va.installation_id.installation_title}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Performance Standard</th>
              <td className="p-3 border">{va.ps_id && va.ps_id.ps_title}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Nature</th>
              <td className="p-3 border">{va.va_type}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Onshore/Offshore</th>
              <td className="p-3 border">{va.va_onshore_offshore}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Frequency</th>
              <td className="p-3 border">{va.va_freq}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Baseline Sample</th>
              <td className="p-3 border">{va.va_baseline_sample}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Class</th>
              <td className="p-3 border">{va.va_class}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border" colSpan="2">Class Attachments</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">
                {createFileLinks(vaClassAttachments, va)}
              </td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border" colSpan="2">Linked Documentation</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">
                {createFileLinks(vaLegislationDocs, va)}
              </td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">VA Status</th>
              <td className="p-3 border">{va.va_status}</td>
            </tr>            
          </tbody>
        </table>

        <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>
        <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      </div>

    </div>
  )

}

function createFileLinks(attachments, va) {
  let linkList = "";
  let fileDestPath = va.va_attmnt_dest_path ? va.va_attmnt_dest_path : "";
  if (attachments && attachments.length && fileDestPath !== "") {
    let fullPath = `${REACT_APP_FILE_MANAGER_API}/download-file?path=/${fileDestPath}/`;
    let numberOfAttachments = attachments.length;

    let newAttachments = <React.Fragment>{attachments.map((att, index) =>
      <React.Fragment key={index}>
        <div className="text-blue-900 font-sans text-sm font-bold">File Name:</div>
        <a href={fullPath + att.name} className="mb-1 hover:underline" key={index} target={"_blank"} rel="noopener noreferrer" download={true}>
          {att.name}{((index + 1) < numberOfAttachments) ? <br /> : ""}
        </a>
        <div className="text-blue-900 font-sans text-sm font-bold">File Description:</div>
        <div>
          {att.description}
        </div>
        {((index + 1) < numberOfAttachments) ? <br /> : ""}
      </React.Fragment>
    )}
    </React.Fragment>
    linkList = newAttachments;
  } else {
    linkList = <Link to="#" className="hover:underline">No File Attachment</Link>
  }

  return linkList;
}

export default ViewVerificationActivity;
