/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import {Link, useParams, useHistory} from 'react-router-dom';
import {gql, useMutation, useLazyQuery} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {omit} from 'lodash';
import {AuthContext} from "../../context/authContext";
import {NavContext} from '../../context/navContext';
import FileUpload from '../global/FileUpload';
import PageSpinner from '../global/pageSpinner';
import {parseJSONSafely, updateAttachmentData, createUserTypeAndRoleObject} from "../../utils";

const {REACT_APP_FILE_MANAGER_API} = process.env;

const Validation = Yup.object().shape({
    ag_title: Yup.string().label("ag_title").required("Asset Group Name is required"),
});

const ASSETGROUP_QUERY = gql`
query AssetGroup($assetGroupId: ID!){
    assetGroup(_id: $assetGroupId) {
        dh_id{
            _id
            dh_name
        }
        ag_title
        ag_notes
        ag_attachments
    }
}
`;

const DUTYHOLDER_QUERY = gql`
query DutyHolder($id: ID!){
    dutyHolder(_id: $id) {
        _id
        dh_name
    }
}
`;

const CREATE_ASSETGROUP = gql`
    mutation AddAssetGroup($ag: CreateAssetGroup!) {
        createAssetGroup(ag: $ag) {
            _id
            ag_title
            ag_notes
            success
            status_code
            message
        }
    }
`;

const UPDATE_ASSETGROUP = gql`
    mutation UpdateAssetGroup($id: String!, $ag: UpdateAssetGroupInput!, $userId: String) {
        updateAssetGroup(_id: $id, ag: $ag, userId: $userId) {
            ag_title
            ag_notes
            success
            status_code
            message
        }
    }
`;

const AddEditAssetGroup = (props) => {
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
    const [showHelp, setShowHelp] = useState(false);
    const [dutyHolderName, setDutyHolderName] = useState('');
    const [drDnInstallation, setDrDnInstallation] = useState({});

    const [formInitValues, setFormInitValues] = useState({
        dh_id: "",
        ag_title: "",
        ag_notes: "",
        ag_attachments: "[]"
    });

    const { formMode, id, assetGroupId = "" } = useParams();
    const { location: { state = "" } } = props ? props : {};

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);
    const history = useHistory();

    const [dutyHolder, { data: dutyHolderData }] = useLazyQuery(DUTYHOLDER_QUERY);
    const [assetGroup, {error:AssetGroupError, data: AssetGroupData }] = useLazyQuery(ASSETGROUP_QUERY);

    const [createAssetGroup, {loading: loadingCreateAg, error: errorCreateAg, data: createAssetGroupData }] = useMutation(CREATE_ASSETGROUP);
    const [updateAssetGroup, {loading: loadingUpdateAg, error: errorUpdateAg, data: updateAssetGroupData }] = useMutation(UPDATE_ASSETGROUP);

    useEffect(() => {
        if (authContext && authContext.user) {
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        const { dutyHolder, installation } = navContext;
        const { id: dutyHolderId } = dutyHolder ?? {};
        const { id: prevId } = drDnInstallation;

        if (prevId && installation.id && prevId !== installation.id) {

            if (dutyHolderId && dutyHolderId !== "") {
                history.push(`/dutyholder/${dutyHolderId}/asset-groups`);
            } else {
                history.push('/');
            }
        }

        setDrDnInstallation(installation);
    }, [navContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);

    useEffect(() => {
        dutyHolder({ variables: { id: id }, errorPolicy: 'all' });
        if (formMode === "edit" && assetGroupId !== "") {
            assetGroup({ variables: { assetGroupId: assetGroupId }, errorPolicy: 'all' });
        }
    }, []);

    useEffect(() => {
        if (dutyHolderData) {
            setDutyHolderName(dutyHolderData.dutyHolder.dh_name);
        }
    }, [dutyHolderData]);

    useEffect(() => {
        if (AssetGroupData && AssetGroupData.assetGroup) {
            let agGroup = AssetGroupData.assetGroup;
            setFormInitValues({
                ag_title: agGroup.ag_title,
                ag_notes: agGroup.ag_notes,
                ag_attachments: agGroup.ag_attachments ? agGroup.ag_attachments : "[]",
            })
        }
    }, [AssetGroupData]);

    useEffect(() => {
        if (createAssetGroupData && createAssetGroupData.createAssetGroup) {
            const {success, message} = createAssetGroupData.createAssetGroup;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push(`/dutyholder/${id}/asset-groups/`);
            }    
        }
    }, [createAssetGroupData]);

    useEffect(() => {
        if (updateAssetGroupData && updateAssetGroupData.updateAssetGroup) {
            const { success, message } = updateAssetGroupData.updateAssetGroup;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push(`/dutyholder/${id}/asset-groups/`);
            }
        }
    }, [updateAssetGroupData]);

    const displayBackLink = (state, id) => {
        return (
            <Link to={{
                pathname: `/dutyholder/${id}/asset-groups` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    }

    let tempAgAttachFileList = [];
    const handleAgAttachFilesDescription = (item) => {
        let index = tempAgAttachFileList.findIndex(f => f.name === item.name);
        if (index >= 0) {
            tempAgAttachFileList[index] = item;
        } else {
            tempAgAttachFileList.push(item);
        }
    };  

    if (AssetGroupError) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    if (loadingCreateAg || loadingUpdateAg) {
        return (
            <PageSpinner displayText={"submitting form"}/>
        )
    }

    if (errorCreateAg || errorUpdateAg ) {
        return (
            <span>Something went wrong saving asset group</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} {dutyHolderName} Asset Group <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                <div>
                    <p>Enter the details into the required fields, including adding attachments by clicking on 'Attach Files'.</p>
                    <p>Once complete, click '<span className="capitalize">{formMode}</span> Asset Group' which will initiate a notification for review.</p>
                </div>
                }                
            </div>

            <Formik
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={Validation}
                onSubmit={(values, actions) => {
                    const tempValues = { ...values };

                    tempValues.ag_attachments = updateAttachmentData(tempValues.ag_attachments, tempAgAttachFileList);
                    tempValues.ag_archived = false;
                    tempValues.ag_archived_date = new Date();
                    tempValues.dh_id = id;

                    if (formMode === 'add') {
                        tempValues.ag_added_id = authContext.user._id;
                        createAssetGroup({ variables: { ag: tempValues } })
                    }

                    if (formMode === 'edit') {
                        updateAssetGroup({ variables: { id: assetGroupId, ag: tempValues, userId: authContext.user._id} })
                    }

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="my-4">
                            {displayBackLink(state, id)}
                        </div>

                        <div>
                            <label htmlFor="ag_title" className="block mb-1 text-blue-900 font-semibold">Asset Group Name*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="ag_title"
                                id="ag_title"
                                placeholder="Asset Group Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ag_title}
                            />
                            <FormError touched={touched.ag_title} message={errors.ag_title} />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="ag_notes" className="block mb-1 text-blue-900 font-semibold">Notes</label>
                            <textarea
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="ag_notes"
                                id="ag_notes"
                                placeholder="Notes"
                                onChange={handleChange}
                                value={values.ag_notes}
                            />
                        </div>

                        <div>
                            <label htmlFor="ag_attachments" className="block mb-1 text-blue-900 font-semibold">Attachments</label>
                            {authContext.user && authContext.user.user_home_dir &&
                                <FileUpload
                                    onAttach={(files) => setFieldValue('ag_attachments', JSON.stringify(files))}
                                    id='fileManager'
                                    buttonText='Attach Files'
                                    apiURL={REACT_APP_FILE_MANAGER_API}
                                    toolbarText='Attach'
                                    homeDir={`/${authContext.user.user_home_dir}/`}
                                    showDescription={true}
                                    handleFileDescription={handleAgAttachFilesDescription}
                                    tempFileList={tempAgAttachFileList}
                                    {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.ag_attachments) ? parseJSONSafely(values.ag_attachments) : [] })}
                                />
                            }
                            <FormError touched={touched.ag_attachments} message={errors.ag_attachments} />
                        </div>

                        <button type="submit" className="mt-3 button-red capitalize">{formMode} Asset Group</button>

                    </form>
                )}
            </Formik>
        </div>
    )
}

export default AddEditAssetGroup;
