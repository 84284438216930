/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageSpinner from '../global/pageSpinner';
import {NavContext} from '../../context/navContext';
import { AuthContext } from "../../context/authContext";
import { createUserTypeAndRoleObject, getBreadCrumbHeader } from "../../utils/index";

const SECE_STATUS_QUERY = gql`
query SeceStatus($dhId: String, $instId: String){
    seceStatus(dhId: $dhId, instId: $instId) {
    sece_order
    sece_ref
    sece_title
    barrier_title
    offshore
    onshore
    remote
    level1
    level2
    level3
  }
}`;

function SeceStatus() {
    const [showHelp, setShowHelp] = useState(false);
    const [seceStatusList, setSeceStatusList] = useState([]); 
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [drDnDutyholder, setDrDnDutyholder] = useState({});
    const [drDnInstallation, setDrDnInstallation] = useState({});
    const [drDnSece, setDrDnSece] = useState({});

    const navContext = useContext(NavContext);
    const authContext = useContext(AuthContext);
  
    const [seceStatus, { loading: seceStatusLoading, error: seceStatusError, data: seceStatusData}] = useLazyQuery(SECE_STATUS_QUERY);

    useEffect(() => {
        if (authContext && authContext.user) {
          let result = createUserTypeAndRoleObject(authContext);
          setUserTypeAndRoleObject(result);
        }
      }, [authContext]);

    useEffect(() => {
        const { dutyHolder, installation, sece } = navContext;
        setDrDnDutyholder(dutyHolder);
        setDrDnInstallation(installation);
        setDrDnSece(sece);
    }, [navContext]);

    useEffect(() => {
        if (drDnDutyholder.id || drDnInstallation.id || drDnSece.id) {
            setSeceStatusList([]);
            seceStatus({ variables: { dhId: drDnDutyholder.id, instId: drDnInstallation.id }, errorPolicy: 'all' });
        }
    }, [drDnDutyholder, drDnInstallation, drDnSece]);

    useEffect(() => {
        if (seceStatusData && seceStatusData.seceStatus) {
            setSeceStatusList(seceStatusData.seceStatus);
        }
    }, [seceStatusData]);

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                id: 'SECEorder',
                columns:[{
                    Header: 'SECE Order',
                    accessor: 'sece_order',
                }]
            },
            {
                Header: '',
                id: 'SECEref',
                columns:[{
                    Header: 'SECE Ref.',
                    accessor: 'sece_ref',
                }]
            },
            {
                Header: '',
                id: 'SECEtitle',
                columns:[{
                    Header: 'SECE Title',
                    accessor: 'sece_title',
                }]
            },
            {
                Header: '',
                id: 'SECEtitle',
                columns:[{
                    Header: 'Barrier',
                    accessor: 'barrier_title',
                }]
            },
            {
                Header: 'Completion',
                id: 'completion',
                columns:[
                    {
                        Header: 'Offshore',
                        accessor: 'offshore',
                        Cell: off => (
                            <span className={`flex py-1 px-2 justify-center ${[off.value === 0 ? "bg-red-900 text-white" : off.value <= 50 ? "bg-blue-300 text-white" : off.value <= 75 ? "bg-yellow-900 text-black" : off.value <= 100 ? "bg-green-900 text-black" : null]}`}>
                              {off.value}% 
                            </span>
                          ),
                    },{
                        Header: 'Onshore',
                        accessor: 'onshore',
                        Cell: on => (
                            <span className={`flex py-1 px-2 justify-center ${[on.value === 0 ? "bg-red-900 text-white" : on.value <= 50 ? "bg-blue-300 text-white" : on.value <= 75 ? "bg-yellow-900 text-black" : on.value <= 100 ? "bg-green-900 text-black" : null]}`}>
                              {on.value}% 
                            </span>
                        ),
                    },{
                        Header: 'Remote',
                        accessor: 'remote',
                        Cell: rem => (

                            <span className={`flex py-1 px-2 justify-center ${[rem.value === 0 ? "bg-red-900 text-white" : rem.value <= 50 ? "bg-blue-300 text-white" : rem.value <= 75 ? "bg-yellow-900 text-black" : rem.value <= 100 ? "bg-green-900 text-black" : null]}`}>
                              {rem.value}% 
                            </span>
                        ),
                    }
                ]
            },
            {
                Header: 'Findings',
                id: 'findings',
                columns:[
                    {
                        Header: 'Level 1',
                        accessor: 'level1',
                        Cell: lev1 => (
                            <span className={"flex py-1 px-2 justify-center bg-blue-300 text-black"}>
                              {lev1.value}
                            </span>
                        ),
                    },
                    {
                        Header: 'Level 2',
                        accessor: 'level2',
                        Cell: lev2 => (
                            <span className={"flex py-1 px-2 justify-center bg-yellow-900"}>
                              {lev2.value}
                            </span>
                        ),
                    },
                    {
                        Header: 'Level 3',
                        accessor: 'level3',
                        Cell: lev3 => (
                            <span className={"flex py-1 px-2 justify-center bg-red-900 text-white"}>
                              {lev3.value}
                            </span>
                        ),
                    }
                ]
            },
            ],
        []
    )

    if (seceStatusLoading) {
        return (
            <PageSpinner displayText = {"generating sece status"}/>
        )
    }

    if (seceStatusError) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8 wide-table">

            <div className="pb-6 border-b-2 border-gray-200">
                <h1 className="text-blue-900 font-sans text-2xl font-bold">SECE STATUS <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                    <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam condimentum a lectus non condimentum. Aliquam rhoncus, enim vitae condimentum pharetra, urna magna elementum sapien, non vehicula turpis tellus nec velit. Vivamus tristique venenatis neque, eget tristique mauris suscipit aliquet. Pellentesque et massa nunc.</p>
                }
            </div>

            <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

            {getBreadCrumbHeader({
                userTypeAndRoleObject,
                drDnDutyholder,
                drDnInstallation,
                drDnSece,
                section: "Dashboard",
                title: "SECE Status"
            })
            }
            {/* {(userTypeAndRoleObject &&
              userTypeAndRoleObject.userType === "ABS" && 
              drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - SECE Status - Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
            
            {(userTypeAndRoleObject &&
              userTypeAndRoleObject.userType === "ABS" && 
              !drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - SECE Status - Installation: {drDnInstallation.name}</h6>}
            
            {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - SECE Status - Duty Holder / Installation : All</h6>} */}

            {(drDnDutyholder.id || drDnInstallation.id || drDnSece.id) ? (seceStatusList.length ? <Table
                columns={columns}
                data={seceStatusList}
            /> : "No Data To Display") : (userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" ? "Please Select Duty Holder" : "Please Select Installation") }

        </div>
    )

}

export default SeceStatus;