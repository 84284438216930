/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import * as d3 from "d3";
import './bowtie.css';
import { useParams } from 'react-router-dom';
import PageSpinner from "../global/pageSpinner";
import ErrorBoundary from '../global/ErrorBoundary';
import { useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavContext } from "../../context/navContext";
import {truncateString} from "../../utils/index";
const { REACT_APP_URL } = process.env;

const bowtie_props = {
    colours: {
        "lightgrey": "#C5C7C9",
        "grey": "#898B8D",
        "red": "#DA1F33",
        "yellow": "#FFAD0A",
        "green": "#4CA342",
        "blue": "#5E8AB4",
        "pink": "#E0C3C4"
    },
    key_colours: ["#4CA342", "#FFAD0A", "#da1f33", "#231F20"],
    key_labels: ["Low", "Medium", "High", "Critical"],
    transition_time: 1000
};

const BOWTIE_SEARCH_QUERY = gql` 
query BowtieSearch($param: BowtieSearchInput!){ 
    bowtieSearch(param: $param){ 
        _id
        dh_id
        installation_id
        mah_id
        major_Accident_Hazard_bowtie{
            mah_id
            mah_client_ref
            mah_title
            mah_subtitle_1
            mah_subtitle_2
            mah_bowtie_status{
                color
            }
            seces_left {
                sece_id
                sece_ref
                sece_title
                sece_link
                sece_completion_status{
                    color
                }
                sece_finding_status{
                    color
                }
            }
            seces_right {
                sece_id
                sece_ref
                sece_title
                sece_link
                sece_completion_status{
                    color
                }
                sece_finding_status{
                    color
                }
            }
        }
        created_date  
    } 
}`;

function BowtieAlt(props) {
    const [showHelp, setShowHelp] = useState(false);
    const [bowtieProps, setBowtieProps] = useState(null);
    const [bowtieData, setBowtieData] = useState(null);
    const [shouldSpin, setShouldSpin] = useState(false);

    //const myRef = React.createRef();
    const {mahId = "" } = useParams();

    let history = useHistory();

    const navContext = useContext(NavContext);

    const [bowtieSearch, { loading: bowtieLoading, error: bowtieError, data: mahBowtieData }] = useLazyQuery(BOWTIE_SEARCH_QUERY);

    useEffect(() => {
        setBowtieProps(bowtie_props);
        //setBowtieData(my_data);
        if (mahId && mahId !== "") {
            bowtieSearch({ variables: { param: { mah_id: mahId } }, errorPolicy: 'all' });
            setShouldSpin(true); 
        }
    }, []);

    useEffect(() => {
        const { dutyHolder, installation, sece } = navContext;
        if (!dutyHolder.id && !installation.id && !sece.id) {
            //history.push("/bowtie-dashboard");
            history.push("/");
        }
    }, [navContext]);

    useEffect(() => {
        if (bowtieData && bowtieProps) {
            //console.log(bowtieData, myRef);
            //draw map
            var svg = draw_svg("chart_div");
            // var my_data = format_data(my_data);
            draw_bowtie(svg, bowtieData, "bowtie_chart", bowtieProps);
        }
    }, [bowtieData, bowtieProps]);

    useEffect(() => {
        if (mahBowtieData && mahBowtieData.bowtieSearch) {
            const mahBowtieList = mahBowtieData.bowtieSearch;
            const { major_Accident_Hazard_bowtie: mahBowtie = [] } = mahBowtieList[0] ? mahBowtieList[0] : []
            setBowtieData(mahBowtie);
            setShouldSpin(false);
            //draw map
            //var svg = draw_svg("chart_div");
            // var my_data = format_data(my_data);
            //draw_bowtie(svg, bowtieData, "bowtie_chart", bowtieProps);
        }
    }, [mahBowtieData]);

    if (shouldSpin || bowtieLoading) {
        return (
            <PageSpinner displayText = {"generating bowtie"} />
        )
      }
    
      if (bowtieError) {
        return (
          <span>Something went wrong retrieving the table data</span>
        )
      }

    return (
        <div className="w-full px-8 pb-8">
            <div className="pb-6 border-b-2 border-gray-200">
                <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Bowtie <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>
                <p className={!showHelp ? "hidden" : "mt-3"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam condimentum a lectus non condimentum. Aliquam rhoncus, enim vitae condimentum pharetra, urna magna elementum sapien, non vehicula turpis tellus nec velit. Vivamus tristique venenatis neque, eget tristique mauris suscipit aliquet. Pellentesque et massa nunc.</p>
            </div>

            {/*
            <div className="my-4 flex">
                <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>
            </div>
            */}

            <ErrorBoundary>
                <div id="chart_div" className="mt-3"></div>
            </ErrorBoundary>    
        </div>
    );
}

function open_link(my_link) {
    window.open(my_link, 'noopener noreferrer');
}

function wrap(text, width, font_size) {
    text.each(function () {
        var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = font_size, // ems
            y = text.attr("y"),
            x = text.attr("x"),
            dy = parseFloat(text.attr("dy")),
            tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy);
        /* eslint-disable-next-line */
        while (word = words.pop()) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width) {
                line.pop();
                tspan.text(line.join(" "));
                if (line.join(" ") === "") {
                    tspan.remove();
                }
                line = [word];
                tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", lineHeight + dy).text(word);
                lineNumber += 1
            }
        }
        if (lineNumber > 0) {
            var tspans = d3.select(this).selectAll("tspan")._groups[0].length;
            var extra = 0;
            if (tspans > 2) {
                extra = font_size;
            }
            text.attr("y", y - (font_size / 4) - extra)
        } else {
            text.attr("y", y + (font_size / 2))
        }
    });
}

function bowtie_chart(bowtie_props) {

    var width = 0,
        height = 0,
        my_data = [],
        my_class = "",
        margins = {};

    function my(svg) {
        //console.log(my_data);
        //saving for next phase
        const central_circle_radius = Math.min(width / 10, 100);

        const rect_height = 70;
        const starter_dx = rect_height * 1.25;

        //min rect width 230
        const rect_width = Math.min(220, (width - margins.left - margins.right - (central_circle_radius * 4) / 2));

        const starter_dy = (width - (central_circle_radius / 2) - margins.left - margins.right - (rect_width / 2)) / 4;
        const results_rect_width = Math.min(rect_width - 80, 150);
        const results_rect_height = 20;

        //const completion_colour = d3.scaleThreshold().domain([0.5, 2]).range(["#DA1F33", "#FFAD0A", "#4CA342"]);

        let zoom_y_extra = 0;
        const side_max = Math.max(my_data.seces_left.length, my_data.seces_right.length);
        const max_height = margins.top + margins.bottom + (side_max * starter_dx);
        if (max_height > height) {
            zoom_y_extra = (max_height - height) / 2;
        }
        //initialise zoom
        const zoom = d3.zoom()
            .extent([[0, 0], [width, height]])
            .translateExtent([[0, -zoom_y_extra], [width, height + zoom_y_extra]])
            .scaleExtent([1, 10])
            .on("zoom", zoomed);

        svg.call(zoom);

        //draw non-data elements
        if (d3.select(".tree_group" + my_class)._groups[0][0] === null) {
            var tree_svg = svg.append("g").attr("class", "map_svg" + my_class);
            svg.append("rect").attr("class", "background_rect zoom_background_rect" + my_class);
            svg.append("rect").attr("class", "zoom_rect zoom_rect" + my_class).attr("id", "in");
            svg.append("text").attr("class", "zoom_text" + my_class).attr("id", "in");
            svg.append("rect").attr("class", "zoom_rect zoom_rect" + my_class).attr("id", "out");
            svg.append("text").attr("class", "zoom_text" + my_class).attr("id", "out");

            svg.append("rect").attr("class", "background_rect key_background_rect" + my_class);
            svg.append("text").attr("class", "key_title" + my_class);
            tree_svg.append("g").attr("class", "tree_group" + my_class);
            var central_group = tree_svg.append("g").attr("class", "central_group" + my_class);
            central_group.append("circle").attr("class", "name_circle name_circle" + my_class);
            central_group.append("text").attr("class", "name_circle_label name_circle_label" + my_class);
            central_group.append("text").attr("class", "title title_label_1" + my_class);
            central_group.append("text").attr("class", "title title_label_2" + my_class);
        }

        d3.select(".zoom_background_rect" + my_class)
            .attr("x", width - 35)
            .attr("y", height - 62)
            .attr("width", 30)
            .attr("height", 55);

        //draw zoom buttons
        d3.selectAll(".zoom_rect" + my_class)
            .attr("width", 20)
            .attr("height", 20)
            .attr("x", width - 30)
            .attr("y", function () { return (height - 32) + (this.id === "in" ? -25 : 0) })
            .on("mouseover", function () { d3.select(this).attr("cursor", "pointer") })
            .on("mouseout", function () { d3.select(this).attr("cursor", "default") })
            .on("click", function () { (this.id === "in" ? zoom_in() : zoom_out()) });

        d3.selectAll(".zoom_text" + my_class)
            .attr("text-anchor", "middle")
            .attr("pointer-events", "none")
            .attr("x", width - 20)
            .attr("y", function () { return (height - 17) + (this.id === "in" ? -25 : 0) })
            .text(function () { return (this.id === "in" ? "+" : "-") });

        d3.select(".name_circle" + my_class)
            .attr("fill", (my_data.mah_bowtie_status && my_data.mah_bowtie_status.color))
            .attr("r", central_circle_radius)
            .attr("cx", width / 2)
            .attr("transform", "translate(0," + (height / 2) + ")");

        d3.select(".name_circle_label" + my_class)
            .attr("x", width / 2)
            .attr("dy", 0)
            .attr("transform", "translate(0," + (height / 2) + ")")
            .text(my_data.mah_title)
            .call(wrap, central_circle_radius * 1.5, 22);

        d3.select(".title_label_1" + my_class)
            .attr("x", width / 2)
            .attr("y", (height / 2) + central_circle_radius + 15)
            .text(my_data.mah_subtitle_1);

        d3.select(".title_label_2" + my_class)
            .attr("fill", "blue")
            .attr("x", width / 2)
            .attr("y", (height / 2) + central_circle_radius + 30)
            .text(my_data.mah_subtitle_2);

        const starter_tree = d3.tree()
                               .nodeSize([starter_dx, starter_dy]);

        my_data.seces_left.map(m => m.name = m.sece_ref.replace(/ /g, '_'));
        my_data.seces_right.map(m => m.name = m.sece_ref.replace(/ /g, '_'));

        let left_tree = starter_tree(d3.hierarchy({ "name": my_data.name, "children": my_data.seces_left }));
        left_tree.children && left_tree.children.map(m => m.y = -m.y);
        left_tree.children && left_tree.children.map(m => m.position = "left");
        let right_tree = starter_tree(d3.hierarchy({ "name": my_data.name, "children": my_data.seces_right }));
        right_tree.children && right_tree.children.map(m => m.position = "right");

        let rightTreeChildren = right_tree.children ? right_tree.children : [];
        const tree_data = left_tree.children ? left_tree.children.concat(rightTreeChildren) : [...(rightTreeChildren)];

        //data join for maps
        const my_group = d3.select(".tree_group" + my_class).selectAll('.starter_tree' + my_class)
            .data(tree_data)
            .join(function (group) {
                var enter = group.append("g").attr("class", "starter_tree" + my_class);
                enter.append("rect").attr("class", "tree_rect");
                enter.append("text").attr("class", "rect_code");
                enter.append("text").attr("class", "rect_title");
                enter.append("line").attr("class", "rect_line");
                enter.append("rect").attr("class", "completion_rect");
                enter.append("text").attr("class", "results_title completion_title");
                enter.append("rect").attr("class", "findings_rect");
                enter.append("text").attr("class", "results_title findings_title");
                return enter;
            });

        my_group.select(".tree_rect")
            .attr("x", d => d.y - (d.position === "right" ? 0 : rect_width))
            .attr("y", d => d.x - (rect_height / 2))
            .attr("width", rect_width)
            .attr("height", rect_height)
            .attr("transform", "translate(" + ((width / 2)) + "," + (height / 2) + ")");

        my_group.select(".rect_code")
            .attr("x", d => d.y + 5 + (d.position === "right" ? 0 : -rect_width))
            .attr("y", d => d.x - (rect_height / 2) + 18)
            .text(d => d.data.sece_ref)
            .attr("transform", "translate(" + (width / 2) + "," + (height / 2) + ")")
            .on("mouseover", function (d) { d3.select(this).attr("cursor", "pointer") })
            .on("mouseout", function (d) { d3.select(this).attr("cursor", "default") })
            .on("click", (event, d) => open_link(`${REACT_APP_URL}/${d.data.sece_link}`));

        my_group.select(".rect_title")
            .attr("x", d => d.y + 90 + (d.position === "right" ? 0 : -rect_width))
            .attr("y", d => d.x - (rect_height / 2) + 18)
            .text(d => truncateString(d.data.sece_title, 18))
            .attr("transform", "translate(" + (width / 2) + "," + (height / 2) + ")")
            .on("mouseover", function (d) { d3.select(this).attr("cursor", "pointer") })
            .on("mouseout", function (d) { d3.select(this).attr("cursor", "default") })
            .on("click", (event, d) => open_link(`${REACT_APP_URL}/${d.data.sece_link}`));

        my_group.select(".completion_rect")
            //.attr("fill", d => completion_colour(d.data.sece_completion))
            .attr("fill", d => d.data.sece_completion_status.color)
            .attr("x", d => d.y + (rect_width - 5 - results_rect_width) + (d.position === "right" ? 0 : -rect_width))
            .attr("y", d => d.x - (rect_height / 2) + 25)
            .attr("width", results_rect_width)
            .attr("height", results_rect_height)
            .attr("transform", "translate(" + ((width / 2)) + "," + (height / 2) + ")");

        my_group.select(".completion_title")
            .attr("x", d => d.y + (rect_width - 10 - results_rect_width) + (d.position === "right" ? 0 : -rect_width))
            .attr("y", d => d.x - (rect_height / 2) + 28 + (results_rect_height / 2))
            .text("Completion")
            .attr("transform", "translate(" + ((width / 2)) + "," + (height / 2) + ")");

        my_group.select(".findings_rect")
            .attr("fill", d => d.data.sece_finding_status.color)
            .attr("x", d => d.y + (rect_width - 5 - results_rect_width) + (d.position === "right" ? 0 : -rect_width))
            .attr("y", d => d.x - (rect_height / 2) + 25 + results_rect_height)
            .attr("width", results_rect_width)
            .attr("height", results_rect_height)
            .attr("transform", "translate(" + ((width / 2)) + "," + (height / 2) + ")");

        my_group.select(".findings_title")
            .attr("x", d => d.y + (rect_width - 10 - results_rect_width) + (d.position === "right" ? 0 : -rect_width))
            .attr("y", d => d.x - (rect_height / 2) + 28 + (results_rect_height / 2) + results_rect_height)
            .text("Findings")
            .attr("transform", "translate(" + ((width / 2)) + "," + (height / 2) + ")");

        my_group.select(".rect_line")
            .attr("x1", d => d.position === "left" ? -central_circle_radius : central_circle_radius)
            .attr("x2", d => d.y)
            .attr("y2", d => d.x)
            .attr("width", rect_width)
            .attr("height", rect_height)
            .attr("transform", "translate(" + (width / 2) + "," + (height / 2) + ")");


        const key_start = height - margins.bottom - (bowtie_props.key_colours.length * 20);

        d3.select(".key_background_rect" + my_class)
            .attr("x", margins.left - 5)
            .attr("y", key_start - 27)
            .attr("width", 75)
            .attr("height", height - key_start + 17);

        d3.select(".key_title" + my_class)
            .attr("x", margins.left)
            .attr("y", key_start - 12)
            .text("KEY");

        //data join for maps
        const key_group = svg.selectAll('.key_group' + my_class)
            .data(bowtie_props.key_colours)
            .join(function (group) {
                var enter = group.append("g").attr("class", "key_group" + my_class);
                enter.append("rect").attr("class", "key_rect");
                enter.append("text").attr("class", "key_label");
                return enter;
            });

        key_group.select(".key_rect")
            .attr("fill", d => d)
            .attr("width", 15)
            .attr("height", 15)
            .attr("x", margins.left)
            .attr("y", (d, i) => key_start + (i * 20));

        key_group.select(".key_label")
            .attr("x", margins.left + 20)
            .attr("y", (d, i) => key_start + (i * 20) + 12)
            .text((d, i) => bowtie_props.key_labels[i]);

        //zoom functions
        function zoomed(event) {
            const { transform } = event;
            tree_svg.attr("transform", transform);
            tree_svg.attr("stroke-width", 0.5 / transform.k / 2);
        }

        function zoom_in() {
            svg.transition().duration(750).call(zoom.scaleBy, 1.5)
        }

        function zoom_out() {
            svg.transition().duration(750).call(zoom.scaleBy, 0.5)
        }
    }

    my.width = function (value) {
        if (!arguments.length) return width;
        width = value;
        return my;
    };

    my.height = function (value) {
        if (!arguments.length) return height;
        height = value;
        return my;
    };

    my.my_data = function (value) {
        if (!arguments.length) return my_data;
        my_data = value;
        return my;
    };

    my.my_class = function (value) {
        if (!arguments.length) return my_class;
        my_class = value;
        return my;
    };

    my.margins = function (value) {
        if (!arguments.length) return margins;
        margins = value;
        return my;
    };


    return my;
}

function draw_bowtie(svg, my_data, my_class, bowtie_props) {

    var width = +svg.attr("width");
    var height = +svg.attr("height");
    var margins = { "left": 10, "right": 10, "top": 10, "bottom": 10 };

    var my_chart = bowtie_chart(bowtie_props)
        .width(width)
        .height(height)
        .my_class(my_class)
        .my_data(my_data)
        .margins(margins);

    my_chart(svg);
}

function draw_svg(div_id) {

    var chart_div = document.getElementById(div_id);
    var width = chart_div.clientWidth;
    var height = chart_div.clientHeight;
    var svg = "";

    if (d3.select("." + div_id + "_svg")._groups[0][0] === null) {
        svg = d3.select("#" + div_id)
            .append("svg")
            .attr("class", div_id + "_svg")
            .attr("id", div_id + "_svg")
            .attr("width", width)
            .attr("height", height);

    } else {
        svg = d3.select("." + div_id + "_svg");
    }
    return svg;
}

export default BowtieAlt;