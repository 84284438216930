/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Table from '../global/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery } from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import { useQueryString, formatDateToLocaleDateString, capitaliseFirstCase } from '../../utils';

const INSTALLATION_HISTORIES_QUERY = gql`
query InstallationHistory($dhId: String, $filter: String){
    installation_history_by_duty_holder_id(dhId: $dhId, filter: $filter) {
        _id
        installation_code
        dh_id {
            _id
        }
        ag_id {
            _id
            ag_title
        }
        installation_title
        installation_class_no
        installation_imo_no
        installation_flag_state
        installation_type
        installation_business_state
        installation_abs_class_notations
        installation_additional_notations
        installation_other_services
        installation_notes
        installation_status
        history_parent_record_id
        history_modifying_user_id {
            _id
            user_fname
            user_lname
            user_email
        }
        history_modified_date
        history_modified_date_timestamp
    }
}
`;

function InstallationHistories() {
  const [showHelp, setShowHelp] = useState(false);
  const [installationHistories, setInstallationHistories] = useState([]);

  const { id: dhId, assetGroupId, installationId: instId} = useParams();
  
  const queryString = useQueryString();
  const filters = queryString.get('filters');

  const [installation_history_by_duty_holder_id, {loading, error, data}] = useLazyQuery(INSTALLATION_HISTORIES_QUERY);

  useEffect(() => {
    if (dhId !== "" && assetGroupId !== "") {
      let localFilter = filters ? filters : JSON.stringify({ ag_id: assetGroupId, ...(instId && { history_parent_record_id: instId }) });
      installation_history_by_duty_holder_id({ variables: { dhId, ...({ filter: localFilter }) }, errorPolicy: 'all' });
    }
  }, [])

  useEffect(() => {
    if (data && data.installation_history_by_duty_holder_id) {
      let hisList = data.installation_history_by_duty_holder_id;
      let formatedHisList = formatInstHistoryData(hisList);
      let reversedHistoryList = formatedHisList.reverse();
      return setInstallationHistories(reversedHistoryList);
    }
  }, [data])


  const columns = React.useMemo(
    () => [
      {
        Header: 'Installation code',
        accessor: 'installation_code',
      },
      {
        Header: 'Installation',
        accessor: 'installation_title',
      },
      {
        Header: 'Modified Date',
        accessor: "history_modified_date"
      },
      {
        Header: 'Modifying User',
        accessor: "modifying_user"
      },
      {
        Header: 'Status',
        accessor: "status",
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: inst => (
          <div className="text-right">
            <Link
              to={{
                pathname: `/dutyholder/${inst.value.dhId}/installation/view-diff/${inst.value.id}/${inst.value.parentId}`,
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              View Change
            </Link>
          </div>
        )
      },
    ],
    []
  )


  if (loading) {
    return (
      <PageSpinner/>
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 mb-6 border-b-2 border-gray-200">

        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Installation History<FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)}/></h1>

        {showHelp &&
        <p className="mt-3">A table detailing changes to Installations.</p>
        }
      </div>

      <Link to={`/dutyholder/${dhId}/asset-group/${assetGroupId}/installations`} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>


      <Table columns={columns} data={installationHistories}/>

    </div>
  )
}

function formatInstHistoryData(histories) {
  let historyList = []

  if (histories && histories.length) {

    histories.forEach(his => {

      const {_id, installation_code, installation_title, installation_status, dh_id, history_modified_date, history_modifying_user_id, history_parent_record_id } = his ? his : {};
      const { _id:dhId = ""} = dh_id ? dh_id : {};
      const { user_fname = "", user_lname = "" } = history_modifying_user_id ? history_modifying_user_id : {};
      let userFullName = (user_fname !== "" && user_lname !== "") ? `${user_fname} ${user_lname}` : "N/A";

      let newHistory = {
        id: _id,
        parentId: history_parent_record_id,
        dhId: dhId,
        installation_code: installation_code && installation_code !== "" ? installation_code : "N/A",
        installation_title: installation_title && installation_title !== "" ? installation_title : "N/A",
        history_modified_date: history_modified_date && history_modified_date !== "" ? formatDateToLocaleDateString(history_modified_date) : "N/A",
        modifying_user: userFullName,
        status: installation_status && installation_status !== "" ? capitaliseFirstCase(installation_status) : "N/A"
      }

      historyList.push(newHistory);
    });
  }

  return historyList;
}

export default InstallationHistories;