/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest, b2cPolicies } from '../../auth/authProvider';

function LoginError(props) {
    const [error, setError] = useState(true);
    const { instance, inProgress } = useMsal();

    useEffect(() => {
        if (props) {
            const { error } = props
            if (error) {
                setError(error);
            }

            console.log("loginRedirectError>>>>", error);

            //Handles Reset Password error
            if (error && error.errorMessage.indexOf('AADB2C90118') > -1) {
                console.log("loginRedirectError>>>>", error);
                instance.loginRedirect(b2cPolicies.authorities.forgotPassword).catch(e => {
                    console.log("loginRedirectError>>>>", e);
                });
            } else {
                instance.loginRedirect(loginRequest).catch(e => {
                    console.log("loginRedirectError>>>>", e);
                });
            } 
        }
    }, [props])

    const handleLogin = async () => {
        if (inProgress === InteractionStatus.None) {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log("loginRedirectError>>>>", e);
            });
        }
    }

    return (
        <div>
            {error &&
                <p><span>An error occured during authentication, please try again!</span></p>
            }
            <p>
                <button onClick={() => handleLogin()}>Login</button>
            </p>
        </div>
    );
}

export default LoginError;