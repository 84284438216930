/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import Table from '../global/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import PageSpinner from "../global/pageSpinner";
import Comment from '../global/Comment';
import { sysObjects } from "../../constants/sysObjectList";
import { AuthContext } from "../../context/authContext";
import { NavContext } from '../../context/navContext';

import {
  capitaliseFirstCase,
  isJsonParsable,
  parseJSONSafely,
  createUserTypeAndRoleObject,
  concatenateProperty,
  getBreadCrumbHeader
} from "../../utils/index";

import {
  NOTIFICATION_STATUS_APPROVED,
  NOTIFICATION_STATUS_REJECT,
  NOTIFICATION_STATUS_UNAPPROVED,
  NOTIFICATION_STATUS_REVISION,
  NOTIFICATION_ACTION_CREATE,
  NOTIFICATION_TYPE_DUTYHOLDER,
  NOTIFICATION_TYPE_DUTYHOLDER_UPDATE,
  NOTIFICATION_TYPE_ASSETGROUP,
  NOTIFICATION_TYPE_ASSETGROUP_UPDATE,
  NOTIFICATION_TYPE_INSTALLATION,
  NOTIFICATION_TYPE_INSTALLATION_UPDATE,
  NOTIFICATION_TYPE_BARRIER,
  NOTIFICATION_TYPE_BARRIER_UPDATE,
  NOTIFICATION_TYPE_SECE,
  NOTIFICATION_TYPE_SECE_UPDATE,
  NOTIFICATION_TYPE_MAJOR_ACCIDENT_HAZARD,
  NOTIFICATION_TYPE_MAJOR_ACCIDENT_HAZARD_UPDATE,
  NOTIFICATION_TYPE_PERFORMANCE_STANDARD,
  NOTIFICATION_TYPE_PERFORMANCE_STANDARD_UPDATE,
  NOTIFICATION_TYPE_VERIFICATION_ACTIVITY,
  NOTIFICATION_TYPE_VERIFICATION_ACTIVITY_UPDATE,
  NOTIFICATION_TYPE_REFERENCE_ACTIVITY,
  NOTIFICATION_TYPE_REFERENCE_ACTIVITY_UPDATE,
  NOTIFICATION_TYPE_USER,
  NOTIFICATION_TYPE_USER_UPDATE,
  NOTIFICATION_TYPE_VERIFICATION_SCHEDULE,
  NOTIFICATION_TYPE_REPORT,
  NOTIFICATION_TYPE_FINDING,
  NOTIFICATION_TYPE_FINDING_UPDATE,
  NOTIFICATION_TYPE_LOCATION,
  NOTIFICATION_TYPE_LOCATION_UPDATE,
  NOTIFICATION_TYPE_INFOMATRIX,
  NOTIFICATION_TYPE_INFOMATRIX_UPDATE,
  NOTIFICATION_TYPE_USER_COMPETENCY,
  NOTIFICATION_TYPE_USER_COMPETENCY_UPDATE,

  NOTIFICATION_TYPE_PERIODIC_VERIFICATION_REPORT,
  NOTIFICATION_TYPE_PERIODIC_VERIFICATION_REPORT_UPDATE,

  NOTIFICATION_TYPE_ASSET_VERIFICATION_REPORT,
  NOTIFICATION_TYPE_ASSET_VERIFICATION_REPORT_UPDATE,
  USER_ROLE_APPROVER,
  USER_ROLE_ADMIN
} from '../../constants/';

const NOTIFICATIONS_SEARCH_QUERY = gql`
    query NotificationSearch($searchParam: NotificationSearchInput!){
      notificationSearch(searchParam: $searchParam) {
            _id
            type
            action
            raised_by {
                _id
                user_fname
                user_lname
            }
            title
            date_added
            priority
            meta
            metadata
            view_path
            status
        }
    }
`;

const SYSTEM_OBJECT_SEARCH_QUERY = gql`
    query SystemObjects{
      systemObjects{
            _id
            so_name
            so_name_alt
        }
    }
`;

const APPROVE_NOTIFICATION = gql`
    mutation ApproveNotification($_id: String!, $notification: NotificationInput!) {
        approveNotification(_id: $_id, notification: $notification) {
            _id
            type
            raised_by {
                _id
                user_fname
                user_lname
            }
            title
            date_added
            priority
            meta
            view_path
            status
            success
            status_code
            message
        }
    }
`;

const REVISION_NOTIFICATION = gql`
    mutation RevisionNotification($_id: String!, $notification: NotificationInput!) {
      revisionNotification(_id: $_id, notification: $notification) {
            _id
            type
            raised_by {
                _id
                user_fname
                user_lname
            }
            title
            date_added
            priority
            meta
            view_path
            status
            success
            status_code
            message
        }
    }
`;

const REJECT_NOTIFICATION = gql`
    mutation RejectNotification($_id: String!, $notification: NotificationInput!) {
        rejectNotification(_id: $_id, notification: $notification) {
            _id
            type
            raised_by {
                _id
                user_fname
                user_lname
            }
            title
            date_added
            priority
            meta
            view_path
            status
            success
            status_code
            message
        }
    }
`;

const formDefaultValue = {
  not_type: ""
}

function Notifications() {
  const [showHelp, setShowHelp] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationStatus, setNotificationStatus] = useState(NOTIFICATION_STATUS_UNAPPROVED);
  const [userId, setUserId] = useState("");

  const[userRole, setUserRole] = useState('');

  const [drDnDutyholder, setDrDnDutyholder] = useState({});
  const [drDnInstallation, setDrDnInstallation] = useState({});
  const [drDnSece, setDrDnSece] = useState({});

  const [formInitValues, setFormInitValues] = useState({});
  const [notificationTypeList, setNotificationTypeList] = useState([]);
  const [filterModalParams, setFilterModalParams] = useState({});
  const [hasFilterParam, setHasFilterParam] = useState(false);

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const history = useHistory();

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const [notificationSearch, { loading, error, data, refetch }] = useLazyQuery(NOTIFICATIONS_SEARCH_QUERY);
  const [approveNotification, { data: approveNotificationData }] = useMutation(APPROVE_NOTIFICATION);
  const [revisionNotification, { data: revisionNotificationData }] = useMutation(REVISION_NOTIFICATION);
  const [rejectNotification, { data: rejectNotificationData }] = useMutation(REJECT_NOTIFICATION);
  const [systemObjects, { data: systemObjectData }] = useLazyQuery(SYSTEM_OBJECT_SEARCH_QUERY);

  useEffect(() => {
    setFormInitValues(formDefaultValue);
    systemObjects({ variables: {}, errorPolicy: 'all' });
  }, []);

  useEffect(() => {
    if (authContext && authContext.user) {
      if (authContext.user.user_role) {
        const { user_role_display = '' } = authContext.user.user_role;      
        setUserRole(user_role_display);
      }

      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
      const { _id: tempUserId = "" } = authContext.user;
      setUserId(tempUserId);
    }
  }, [authContext]);

  useEffect(() => {
    if (userRole !== "" && !(userRole === USER_ROLE_APPROVER || userRole === USER_ROLE_ADMIN)) {
      history.push('/access-permissions');
    }
  }, [userRole]); 

  useEffect(() => {
    const { dutyHolder, installation, sece } = navContext;
    setDrDnDutyholder(dutyHolder);
    setDrDnInstallation(installation);
    setDrDnSece(sece);
  }, [navContext]);

  useEffect(() => {
    if ((userRole === USER_ROLE_APPROVER || userRole === USER_ROLE_ADMIN)) {
      const { type } = filterModalParams;
      let payload = {
        ...(type && type !== "" ? { type: type } : {}),
        status: notificationStatus,
        dh_id: drDnDutyholder.id ? drDnDutyholder.id : "",
        installation_id: drDnInstallation.id ? drDnInstallation.id : "",
      }
      setNotificationList([]);
      notificationSearch({ variables: { searchParam: { ...payload } }, errorPolicy: 'all' });
    }
  }, [userRole, drDnDutyholder, drDnInstallation, drDnSece, notificationStatus, filterModalParams]);

  useEffect(() => {
    if (data && data.notificationSearch) {
      let tempNotification = formatNotificationDate(data.notificationSearch)
      setNotificationList(tempNotification);
    }
  }, [data])

  useEffect(() => {
    if (systemObjectData && systemObjectData.systemObjects) {
      setNotificationTypeList(systemObjectData.systemObjects);
    }
  }, [systemObjectData])

  useEffect(() => {
    if (approveNotificationData && approveNotificationData.approveNotification) {
      refetch();
    }
  }, [approveNotificationData])

  useEffect(() => {
    if (revisionNotificationData && revisionNotificationData.revisionNotification) {
      refetch();
    }
  }, [revisionNotificationData])

  useEffect(() => {
    if (rejectNotificationData && rejectNotificationData.rejectNotification) {
      refetch();
    }
  }, [rejectNotificationData])

  const clearFilter = () => {
    setFormInitValues({ ...formDefaultValue });
    setHasFilterParam(false);
    setFilterModalParams({});
    toggleModal();
  }

  const handleApproval = (notification, status, approverId) => {
    const payload = {
      status: NOTIFICATION_STATUS_APPROVED,
      meta: notification.meta,
      metadata: notification.metadata,
      type: notification.type,
      approved_by: approverId
    }
    approveNotification({ variables: { _id: notification.id, notification: payload } })
  }

  const handleRevision = (notification, approverId) => {
    const payload = {
      status: NOTIFICATION_STATUS_REVISION,
      meta: notification.meta,
      metadata: notification.metadata,
      type: notification.type,
      approved_by: approverId
    }
    revisionNotification({ variables: { _id: notification.id, notification: payload } })
  }

  const handleReject = (notification, approverId) => {
    const payload = {
      status: NOTIFICATION_STATUS_REJECT,
      meta: notification.meta,
      metadata: notification.metadata,
      type: notification.type,
      approved_by: approverId
    };
    rejectNotification({ variables: { _id: notification.id, notification: payload } })
  }

  const handleViewOperation = (notification, notificationList) => {
    const { id, soName } = notification;
    let notList = notificationList;
    if (soName === sysObjects.verificationScheduleReport) {
      let viewedNotIndex = notList.findIndex(not => not.id === id);
      if (viewedNotIndex > -1) {
        let viewedNot = notList[viewedNotIndex];
        viewedNot.isViewed = true;
        notList[viewedNotIndex] = viewedNot
        setNotificationList([...notList])
      }
    }
  }

  function toggleModal() {
    const modal = document.querySelector('.modal')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Raised By',
        accessor: 'user_name',
      },
      {
        Header: 'Date',
        accessor: 'date_added',
      },
      {
        Header: 'Duty Holder',
        accessor: 'duty_holder',
      },
      {
        Header: 'Installation',
        accessor: 'installation',
      },
      {
        Header: 'SECE',
        accessor: 'sece',
      },
      {
        Header: 'Short Description',
        accessor: 'va_short_description',
      },
      {
        Header: 'Severity',
        accessor: 'priority',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: notification }) => (
          <div className="text-right">
            {notification.showView &&
              (notification.createUpdateViewPage ?
                <Link
                  to={{
                    pathname: notification.view_path,
                    state: { origin: "notifications" }
                  }}
                  className="button-red mb-1 mr-1 text-xs"
                  target={"_blank"}
                  onClick={() => { handleViewOperation(notification, notificationList) }}
                >
                  View
                </Link> :
                <Link
                  to={{
                    pathname: notification.action === NOTIFICATION_ACTION_CREATE ? notification.view_path : `/notification/change/${notification.id}`,
                    state: { origin: "notifications" }
                  }}
                  className="button-red mb-1 mr-1 text-xs"
                >
                  View
                </Link>
              )
            }
            {notification.recordId && <Comment sysObjName={notification.soName} id="comment"
              sysObjRecordId={notification.recordId}
              buttonName={"Comments"}
              buttonClassName={"button-red mb-1 mr-1 text-xs"} />}

            {notificationStatus !== NOTIFICATION_STATUS_APPROVED &&
              notificationStatus !== NOTIFICATION_STATUS_REVISION &&
              notificationStatus !== NOTIFICATION_STATUS_REJECT &&
              <>
                {userTypeAndRoleObject && 
                 userTypeAndRoleObject.userType === "ABS" && 
                 userRole === USER_ROLE_APPROVER &&

                  <div className="inline">
                    <button
                      disabled={!notification.isViewed}
                      style={{
                        display: (notification.status === "Approved" ? 'none' : ''),
                        cursor: (notification.isViewed ? "pointer" : "not-allowed")
                      }}
                      title={notification.isViewed ? "" : "Report need to be reviewed"}
                      className="button-red mb-1 mr-1 text-xs"
                      type="button"
                      onClick={() => { notification.isViewed && handleApproval(notification, 'approve', userId) }}
                    >
                      Approve
                    </button>
                    {notification.meta && <>
                      <button
                        disabled={!notification.isViewed}
                        style={{
                          display: (notification.status === "Approved" ? 'none' : ''),
                          cursor: (notification.isViewed ? "pointer" : "not-allowed")
                        }}
                        className="button-red mb-1 mr-1 text-xs"
                        title={notification.isViewed ? "" : "Report need to be reviewed"}
                        type="button"
                        onClick={() => { notification.isViewed && handleRevision(notification, userId) }}
                      >
                        Revision
                      </button>
                      <button
                        disabled={!notification.isViewed}
                        style={{
                          display: (notification.status === "Approved" ? 'none' : ''),
                          cursor: (notification.isViewed ? "pointer" : "not-allowed")
                        }}
                        className="button-red mb-1 mr-1 text-xs"
                        title={notification.isViewed ? "" : "Report need to be reviewed"}
                        type="button"
                        onClick={() => { notification.isViewed && handleReject(notification, userId) }}
                      >
                        Reject
                      </button>
                    </>}
                  </div>
                }
              </>
            }
          </div>
        )
      },
    ],
    [notificationStatus, userId, notificationList, userRole]
  )

  if (loading) {
    return (
      <PageSpinner displayText={"loading notifications"} />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  if (!(userRole === USER_ROLE_APPROVER || userRole === USER_ROLE_ADMIN)) {
    return (
      <span></span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Notifications <FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3">A list of items which require review.  Click ‘View’ for full details, ‘Comments’ to enter comments followed by Approval / Revision / Reject to update an item’s status.</p>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>    

      {getBreadCrumbHeader({
        userTypeAndRoleObject: userTypeAndRoleObject,
        drDnDutyholder: drDnDutyholder,
        drDnInstallation: drDnInstallation,
        drDnSece: drDnSece,
        section: "Dashboard",
        title: "Notifications"
      })}

      {/* <button onClick={() => { window.history.back() }} className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</button> */}

      <Link to="#" className="button-red mr-2 mb-2 py-3 px-5"
        onClick={() => { setNotificationStatus(NOTIFICATION_STATUS_UNAPPROVED) }}
        style={{ ...(notificationStatus === NOTIFICATION_STATUS_UNAPPROVED && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}
      >{"Unapproved Notifications"}</Link>
      <Link to="#" className="button-red mr-2 mb-2 py-3 px-5"
        onClick={() => { setNotificationStatus(NOTIFICATION_STATUS_APPROVED) }}
        style={{ ...(notificationStatus === NOTIFICATION_STATUS_APPROVED && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}
      >{"Approved Notifications"}</Link>
      <Link to="#" className="button-red mr-2 mb-2 py-3 px-5"
        onClick={() => { setNotificationStatus(NOTIFICATION_STATUS_REJECT) }}
        style={{ ...(notificationStatus === NOTIFICATION_STATUS_REJECT && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}
      >{"Rejected Notifications"}</Link>

      <Link to="#" className="button-red mr-2 mb-2 py-3 px-5"
        onClick={() => { setNotificationStatus(NOTIFICATION_STATUS_REVISION) }}
        style={{ ...(notificationStatus === NOTIFICATION_STATUS_REVISION && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}
      >{"Revision Notifications"}</Link>

      <button className="button-red mr-2 mb-2 py-3 px-5 modal-button"
        onClick={() => toggleModal()}
        style={{ ...(hasFilterParam && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}>Filter Notifications</button>

      <Table
        columns={columns}
        data={notificationList}
        getRowProps={row => ({
          style: {
            background: row.original.priority === 'High' ? '#ffe7ea' : row.original.priority === 'Medium' ? '#fff3db' : row.original.priority === 'Low' ? '#e2ffd9' : null,
          },
        })}
      />

      <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
        <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
        <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

          <Formik
            initialValues={formInitValues}
            onSubmit={(values, action) => {
              let filter = {
                type: values.not_type,
              }

              setFilterModalParams(filter)
              setFormInitValues({ ...values });
              setHasFilterParam(true);
              toggleModal();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>

                <div className="mb-4 pb-2 border-b-2 border-gray-200">
                  <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">Filter Notifications</h2>
                </div>

                <div className="mb-4">
                  <label htmlFor="not_type" className="block mb-1 text-blue-900 font-semibold">Notification Type</label>
                  <select
                    className="form-control block w-full"
                    name="not_type"
                    id="not_type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.not_type}
                  >
                    <option key={0} value={""}>Select Notification Type</option>
                    {notificationTypeList.map(not =>
                      <option key={not._id} value={not._id}>{not.so_name_alt}</option>
                    )}
                  </select>
                </div>

                <button type="submit" className="mt-3 mb-4 button-red capitalize mr-4">Apply Filter</button>
                <button type="button" onClick={() => { clearFilter() }} className="mt-3 mb-4 button-red capitalize">Clear Filter</button>

              </form>
            )}
          </Formik>

        </div>
      </div>

    </div>
  )
}

function formatNotificationDate(notifications) {
  let newNotificationList = [];
  let dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

  if (notifications && notifications.length) {

    notifications.forEach(tempNotif => {
      let dateAdded = new Date(tempNotif.date_added);
      let dateAddedString = dateAdded.toLocaleDateString('en-GB', dateOptions);
      let formatedStatus = capitaliseFirstCase(tempNotif.status);
      let viewPath = tempNotif.view_path;
      
      if(tempNotif.type === NOTIFICATION_TYPE_FINDING  ||
        tempNotif.type ===  NOTIFICATION_TYPE_FINDING_UPDATE){
          viewPath = `${tempNotif.view_path}?notification_id=${tempNotif._id}` 
      }

      const { user_fname: firstName = "", user_lname: lastName = "" } = tempNotif.raised_by ? tempNotif.raised_by : {};

      const metadata = parseJSONSafely(tempNotif.metadata) ? parseJSONSafely(tempNotif.metadata) : {};
      const { dutyHolder = "", installation = "", sece = "", vaShortDesc = "", recordId = "", soName = "", showView = true, createUpdateViewPage = false } = additionalColumnData(tempNotif, metadata);

      let newNotification = {
        id: tempNotif._id,
        type: tempNotif.type,
        action: tempNotif.action,
        title: tempNotif.title,
        user_name: (firstName + " " + lastName),
        date_added: dateAddedString,
        priority: tempNotif.priority,
        status: formatedStatus,
        view_path: viewPath,
        meta: tempNotif.meta,
        metadata: tempNotif.metadata,
        duty_holder: dutyHolder,
        installation: installation,
        sece: sece,
        va_short_description: vaShortDesc,
        recordId: recordId,
        soName: soName,
        showView: showView,
        isViewed: (soName !== sysObjects.verificationScheduleReport),
        createUpdateViewPage: createUpdateViewPage
      };

      newNotificationList.push(newNotification);
    });
  }

  return newNotificationList;
}

function additionalColumnData(notification, metadata) {
  let additionalData = { dutyHolder: "N/A", installation: "N/A", sece: "N/A", vaShortDesc: "N/A", recordId: "", soName: "", showView: true };
  if (notification && notification !== "" && metadata) {
    const { dh_name: dutyHolder = "N/A" } = metadata.dh_id ? metadata.dh_id : {};
    const installationString = metadata.installation_id ? metadata.installation_id : "{}";
    const isParsable = isJsonParsable(installationString);
    const installationId = isParsable ? parseJSONSafely(installationString) : {};
    switch (notification.type) {
      case NOTIFICATION_TYPE_DUTYHOLDER:
      case NOTIFICATION_TYPE_DUTYHOLDER_UPDATE:
        additionalData = { ...additionalData, dutyHolder: metadata.dh_name, recordId: metadata._id, soName: "DutyHolder" };
        break;
      case NOTIFICATION_TYPE_ASSETGROUP:
      case NOTIFICATION_TYPE_ASSETGROUP_UPDATE:
        additionalData = { ...additionalData, dutyHolder: dutyHolder, recordId: metadata._id, soName: "AssetGroup" };
        break;
      case NOTIFICATION_TYPE_INSTALLATION:
      case NOTIFICATION_TYPE_INSTALLATION_UPDATE:
        additionalData = { ...additionalData, dutyHolder: dutyHolder, installation: metadata.installation_title, recordId: metadata._id, soName: sysObjects.installation };
        break;
      case NOTIFICATION_TYPE_BARRIER:
      case NOTIFICATION_TYPE_BARRIER_UPDATE:
        const { installation_title: insatllBar = "N/A" } = installationId !== null ? installationId : {};
        additionalData = { ...additionalData, dutyHolder: dutyHolder, installation: insatllBar, recordId: metadata._id, soName: sysObjects.barrier };
        break;
      case NOTIFICATION_TYPE_SECE:
      case NOTIFICATION_TYPE_SECE_UPDATE:
        const { installation_title: installForSece = "N/A" } = metadata.installation_id ? metadata.installation_id : {};
        additionalData = { ...additionalData, dutyHolder: dutyHolder, installation: installForSece, sece: metadata.sece_title, recordId: metadata._id, soName: sysObjects.sece };
        break;
      case NOTIFICATION_TYPE_MAJOR_ACCIDENT_HAZARD:
      case NOTIFICATION_TYPE_MAJOR_ACCIDENT_HAZARD_UPDATE:
        const { installation_title: installForMah = "N/A" } = installationId;
        additionalData = { ...additionalData, dutyHolder: dutyHolder, installation: installForMah, recordId: metadata._id, soName: sysObjects.majorAccidentHazard };
        break;
      case NOTIFICATION_TYPE_PERFORMANCE_STANDARD:
      case NOTIFICATION_TYPE_PERFORMANCE_STANDARD_UPDATE:
        const { sece_title: seceForPs = "N/A" } = metadata.sece_id ? metadata.sece_id : {};
        const { installation_title: installForPs = "N/A" } = metadata.installation_id ? metadata.installation_id : {};
        additionalData = { ...additionalData, dutyHolder: dutyHolder, installation: installForPs, sece: seceForPs, recordId: metadata._id, soName: sysObjects.performanceStandard };
        break;
      case NOTIFICATION_TYPE_VERIFICATION_ACTIVITY:
      case NOTIFICATION_TYPE_VERIFICATION_ACTIVITY_UPDATE:
        const { sece_title: seceForVa = "N/A", installation_id = {} } = metadata.sece_id ? metadata.sece_id : {};
        const { installation_title: installForVa = "N/A" } = installation_id;
        additionalData = { ...additionalData, dutyHolder: dutyHolder, installation: installForVa, sece: seceForVa, vaShortDesc: metadata.va_short_desc, recordId: metadata._id, soName: sysObjects.verificationActivity };
        break;
      case NOTIFICATION_TYPE_REFERENCE_ACTIVITY:
      case NOTIFICATION_TYPE_REFERENCE_ACTIVITY_UPDATE:
        const { installation_title: installForRa = "N/A" } = metadata.installation_id ? metadata.installation_id : {};
        const { sece_title: seceForRa = "N/A" } = metadata.sece_id ? metadata.sece_id : {};
        const { va_short_desc: vaShortDesc = "N/A" } = metadata.va_id ? metadata.va_id : {};
        additionalData = { ...additionalData, dutyHolder: dutyHolder, installation: installForRa, sece: seceForRa, vaShortDesc: vaShortDesc, recordId: metadata._id, soName: sysObjects.referenceActivity };
        break;
      case NOTIFICATION_TYPE_USER:
      case NOTIFICATION_TYPE_USER_UPDATE:
        additionalData = { ...additionalData, recordId: metadata._id, soName: sysObjects.user };
        break;
      case NOTIFICATION_TYPE_VERIFICATION_SCHEDULE:
        break;
      case NOTIFICATION_TYPE_REPORT:
        const { sece_title: seceForRpt = "N/A" } = metadata.sece_id ? metadata.sece_id : {};
        const { installation_title: installForRpt = "N/A" } = metadata.installation_id ? metadata.installation_id : {};
        const { va_short_desc: vaShortDescForRpt = "N/A" } = metadata.va_id ? metadata.va_id : {};
        additionalData = { ...additionalData, dutyHolder: dutyHolder, installation: installForRpt, sece: seceForRpt, vaShortDesc: vaShortDescForRpt, recordId: metadata._id, soName: sysObjects.report };
        break;
      case NOTIFICATION_TYPE_FINDING:
      case NOTIFICATION_TYPE_FINDING_UPDATE:
        const secesForFnd = concatenateProperty(metadata.sece_ids, "sece_title");
        const vaShortDescsForFnd = concatenateProperty(metadata.va_ids, "va_short_desc");
        const { installation_title: installForFnd = "N/A" } = metadata.installation_id ? metadata.installation_id : {};
        additionalData = { 
          ...additionalData, 
          dutyHolder: dutyHolder, 
          installation: installForFnd, 
          sece: secesForFnd, 
          vaShortDesc: vaShortDescsForFnd, 
          recordId: metadata._id, 
          soName: sysObjects.finding, 
          createUpdateViewPage: true };
        break;
      case NOTIFICATION_TYPE_LOCATION:
      case NOTIFICATION_TYPE_LOCATION_UPDATE:
        additionalData = { ...additionalData, dutyHolder: dutyHolder, recordId: metadata._id, soName: sysObjects.location };
        break;
      case NOTIFICATION_TYPE_INFOMATRIX:
      case NOTIFICATION_TYPE_INFOMATRIX_UPDATE:
        const { installation_title: installForInfoMax = "N/A" } = metadata.installation_id ? metadata.installation_id : {};
        additionalData = { ...additionalData, dutyHolder: dutyHolder, installation: installForInfoMax, recordId: metadata._id, soName: sysObjects.infoMatrix, showView: false };
        break;
      case NOTIFICATION_TYPE_USER_COMPETENCY:
      case NOTIFICATION_TYPE_USER_COMPETENCY_UPDATE:
        additionalData = { ...additionalData, recordId: metadata._id, soName: sysObjects.userCompetency };
        break;
      case NOTIFICATION_TYPE_PERIODIC_VERIFICATION_REPORT:
      case NOTIFICATION_TYPE_PERIODIC_VERIFICATION_REPORT_UPDATE:
      case NOTIFICATION_TYPE_ASSET_VERIFICATION_REPORT:
      case NOTIFICATION_TYPE_ASSET_VERIFICATION_REPORT_UPDATE:
        let seceForVsRpt = metadata.sece_ids ? formatSeces(metadata.sece_ids) : "N/A";
        let installForVsRpt = metadata.installation_ids ? formatInstallations(metadata.installation_ids) : "N/A";
        additionalData = { ...additionalData, dutyHolder: dutyHolder, installation: installForVsRpt, sece: seceForVsRpt, recordId: metadata._id, soName: sysObjects.verificationScheduleReport, createUpdateViewPage: true };
        break;
      default:
        break;
    }
  }
  return additionalData;
}

function formatSeces(seceList) {
  let newSeceList = "N/A";
  if (seceList && seceList.length) {
    let numberOfSeces = seceList.length;
    let tempSeces = "";
    seceList.forEach((sece, index) => {
      tempSeces += sece.sece_title + ((numberOfSeces > index + 1) ? "," : "") + "\n";
    });
    newSeceList = tempSeces;
  }
  return newSeceList;
}

function formatInstallations(installationList) {
  let newInstallationList = "N/A";
  if (installationList && installationList.length) {
    let numberOfInstallations = installationList.length;
    let tempInstallations = "";
    installationList.forEach((installation, index) => {
      tempInstallations += installation.installation_title + ((numberOfInstallations > index + 1) ? "," : "") + "\n";
    });
    newInstallationList = tempInstallations;
  }
  return newInstallationList;
}

export default Notifications;