/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext} from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";

const COMPETENCY_CATEGORY_QUERY = gql`
query CompetencyCategory($compCatId: ID!){
    competencyCategory(_id: $compCatId) {
        comp_cat_code
        comp_cat_title
        comp_cat_notes
        comp_cat_archived
    }
}
`;

const COMPETENCY_QUERY = gql`
query CompetenciesByCatId($isArchived:Boolean!, $catId: String!){
    competenciesByCatId(isArchived: $isArchived, catId: $catId) {
        _id
        comp_code
        comp_title
        comp_notes
    }
}`;

const ViewCompetencyCat = (props) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const [category, setCategory] = useState({});
  const [competencies, setCompetencies] = useState([]);
  const {compCatId = "" } = useParams();

  const { location: { state = "" }} = props ? props : {};

    const [competencyCategory, {data: competencyCategoryData}] = useLazyQuery(COMPETENCY_CATEGORY_QUERY);
    const [competenciesByCatId, {data: competenciesData}] = useLazyQuery(COMPETENCY_QUERY);

    useEffect(() => {
        if (authContext && authContext.user){
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);

    
    useEffect(() => {
      competencyCategory({variables: {compCatId}, errorPolicy: 'all'});
      competenciesByCatId({variables: {isArchived: false, catId:compCatId}, errorPolicy: 'all'});
    }, [])
    
    useEffect(() => {
        if (competencyCategoryData && competencyCategoryData.competencyCategory) {
          setCategory(competencyCategoryData.competencyCategory)
        }
    
      }, [competencyCategoryData])

    useEffect(() => {
        if (competenciesData && competenciesData.competenciesByCatId) {
          setCompetencies(competenciesData.competenciesByCatId)
        }
    
    }, [competenciesData])

    const displayBackLink = (state) => {
      return(
        <Link to={{
          pathname:"/user-competency/competencies/competency-cats" + (state&&state.archivedStatus !==""? `/${state.archivedStatus}`:""),
          state: state&&state.archivedStatus
        }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
      )
    }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Category Detail</h1>
      </div>

      {displayBackLink(state)}

      <div className="w-full lg:w-1/2">

        <div className="my-6">
          <h2 className="font-bold text-2xl text-blue-900">{category.comp_cat_title}</h2>
          <h3 className="font-bold text-red-900">{category.comp_cat_code}</h3>
          <p className="mt-3">{category.comp_cat_notes}</p>
        </div>

        <hr />

        <div className="my-6">
          <h2 className="mb-4 font-bold text-2xl text-blue-900">Competencies</h2>
          {competencies.length ? competencies.map(comp =>
            <p className="font-sans text-blue-900" key={comp._id}>
              <Link to={`/user-competency/competency/view/${comp._id}`}
                    className="hover:underline">{comp.comp_title}</Link>
            </p>
          ) :
            <p className="font-sans text-blue-900">No Competencies to dislay</p>
          }
        </div>      

      </div>

      {displayBackLink(state)}

    </div>
  )
  

}

export default ViewCompetencyCat;
