/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import FormError from "../global/FormError";
import TableDropDown from '../global/TableDropDown';
import { gql, useLazyQuery } from '@apollo/client';
import "react-datepicker/dist/react-datepicker.css";
import Table from '../global/Table';
import { orderBy, kebabCase } from 'lodash';
import PageSpinner from '../global/pageSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createUserTypeAndRoleObject, formatDateToShortMonthString, getBreadCrumbHeader } from "../../utils/index";
import { AuthContext } from "../../context/authContext";
import { NavContext } from '../../context/navContext';

import {
  NATURE_SYSTEM_VARIABLE_TYPE_ID,
  ACTIVITY_STATUS_SYSTEM_VARIABLE_TYPE_ID,
  VA_CATEGORY_ORININAL,
  VA_CATEGORY_PLANNED,
  VA_CATEGORY_OVERDUE_OR_MISSED,
  TEMP_NOT_SCHEDULED_ID,
  TEMP_OVERDUE_ID,
  TEMP_IN_DATE_ID,
  //VA_CATEGORY_MY_SCHEDULE,
  //VA_CATEGORY_CURRENT,
} from '../../constants/';

const dropdownListItems = ({ vaId, completionStatus, overallStatus }) => {

  let modifiedCompletionStatus = kebabCase(completionStatus);
  let modifiedOverallStatus = kebabCase(overallStatus);

  let listItems = [
    /*
    {
      id: 1,
      name: 'PDF',
      link: ""
    }, */
    {
      id: 1,
      name: 'View',
      link: `/reporting/activity-search/view/${vaId}`,
      state: { vaId: vaId },
      search: `?completion-status=${modifiedCompletionStatus}&overall-status=${modifiedOverallStatus}`
    }
  ];

  return listItems;
}

const ACTIVITY_SEARCH_QUERY = gql`
query ActivitySearch($param: ActivitySearchParam!){
  activitySearch(param: $param){
    id
    va_id
    schedule_title
    schedule_start_date
    schedule_end_date
    activity_ref
    activity_short_desc
    sece_title
    activity_nature
    activity_freq
    activity_onshore_offshore
    activity_abs_ivb
    activity_current_status
    activity_report_current_status
    is_activity_overdue
    }
}`;

const DUTY_HOLDERS_QUERY = gql`
    query {
        dutyHolders {
            _id
            dh_name
            dh_status
            dh_added_date
            dh_archived
        }
}`;

const INSTALLATIONS_BY_DH_ID_QUERY = gql`
query installationsByDutyHolderId($dhId: ID){
    installationsByDutyHolderId(dhId: $dhId) {
      _id
      installation_title
      installation_archived
      installation_status
  }
}`;

const SECES_BY_INSTAL_IDS_QUERY = gql`
query SecesByInstallationId($installationId: ID){
  secesByInstallationId(installationId: $installationId) {
       _id
       sece_title
       sece_order_no
       sece_archived
       sece_status
       sece_client_ref
    }
}`;

const VERIFICATION_SCHEDULE_SEARCH = gql`
query VerificationScheduleSearch($param: VerificationScheduleSearch!){
  verificationScheduleSearch(param: $param){
        _id
        vs_title
      
        vs_start_date_time
        vs_end_date_time
        vs_archived
        vs_status
    }
}`;

const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
            _id
            sysvar_title
            sysvar_archived
            sysvartype_id{
                _id
                sysvartype_title
            }
        }
}`;

const filterFormDefaultValues = {
  schedule_title: "",
  dh_id: "",
  installation_id: "",
  sece_id: "",
  schedule_type: "",
  periodicity: "",
  verifier: "",
  schedule_start_date: "",
  schedule_end_date: "",
  schedule_status: "",
  activity_status: "",
  schedule_id: "",

  nature_id: "",
  onshore_offshore: "",
  va_completion_status: "",
  overall_status: "",
}

const EMPTY_LIST_MESSAGE = "There are currently no Activities";
const SELECT_DH_MESSAGE = "Please Select Duty Holder";
const SELECT_INST_MESSAGE = "Please Select Installation";

const displayNameList = [
  { id: "1", category: "planned", completionStatus: "", displayName: "Scheduled Activities" },
  { id: "2", category: "overdue-or-missed", completionStatus: "", displayName: "Overdue Or Missed Activities" },
  { id: "3", category: "current", completionStatus: "overdue-or-missed", displayName: "Live Activities: Overdue Or Missed" },
  { id: "4", category: "current", completionStatus: "completed", displayName: "Live Activities: Completed" },
  { id: "5", category: "current", completionStatus: "commenced", displayName: "Live Activities: Commenced" },
  { id: "6", category: "current", completionStatus: "not-commenced", displayName: "Live Activities: Not Commenced" },
  { id: "7", category: "current", completionStatus: "scheduled", displayName: "Live Activities: Scheduled" },
  { id: "8", category: "my-schedule", completionStatus: "", displayName: "My Scheduled Activities" },
  { id: "9", category: "my-schedule", completionStatus: "completed", displayName: "My Scheduled Activities: Completed" },
  { id: "10", category: "my-schedule", completionStatus: "within-30-days", displayName: "My Scheduled Activities: Within 30 Days" },
  { id: "11", category: "my-schedule", completionStatus: "within-90-days", displayName: "My Scheduled Activities: Within 90 Days" }
]

const onOffshoreList = [
  { id: "1", value: "Onshore" },
  { id: "2", value: "Offshore" }
]

const overallStatusList = [
  { id: TEMP_IN_DATE_ID, title: "In Date" },
  { id: TEMP_OVERDUE_ID, title: "Overdue" },
  { id: TEMP_NOT_SCHEDULED_ID, title: "Not On Schedule" }
]

function ActivitySearchAlt(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [emptyListMessage, setEmptyListMessage] = useState("");
  const [selectDhInstMessage, setSelectDhInstMessage] = useState("");

  const [drDnDutyholder, setDrDnDutyholder] = useState({});
  const [drDnInstallation, setDrDnInstallation] = useState({});
  const [drDnSece, setDrDnSece] = useState({});
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  //Filter Section Start
  const [dhList, setDhList] = useState([]);
  const [installationList, setInstallationList] = useState([]);
  const [seceList, setSeceList] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);

  const [natureList, setNatureList] = useState([]);
  const [onshoreOffshoreList, setOnshoreOffshoreList] = useState([]);
  const [activityCompletionStatusList, setActivityCompletionStatusList] = useState([]);
  const [activityOverallStatusList, setActivityOverallStatusList] = useState([]);

  const [selectedDutyHolderId, setSelectedDutyHolderId] = useState("");
  const [selectedInstallationId, setSelectedInstallationId] = useState("");
  const [selectedSeceId, setSelectedSeceId] = useState("");

  const [hasFilterParam, setHasFilterParam] = useState(false);
  //Filter Section End

  const [displayName, setDisplayName] = useState("Activity Search");
  const [vsCategory, setVsCategory] = useState(null);

  const [searchParams, setSearchParams] = useState({});
  const [filterModalParams, setFilterModalParams] = useState({});

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);

  const [filterFormInitValue, setFilterFormInitValue] = useState({});
  const { location: { pathname = "", search = "" } } = props ? props : {};

  const [activitySearch, { loading: actLoading, error: actError, data: actSearchData }] = useLazyQuery(ACTIVITY_SEARCH_QUERY);

  const [dutyHolders, { loading: dutyHoldersLoading, data: dutyHoldersData }] = useLazyQuery(DUTY_HOLDERS_QUERY);
  const [installationsByDutyHolderId, { loading: installsLoading, data: installsData }] = useLazyQuery(INSTALLATIONS_BY_DH_ID_QUERY);
  const [verificationScheduleSearch, { loading: vsSearchLoading, data: vsSearchData }] = useLazyQuery(VERIFICATION_SCHEDULE_SEARCH);
  const [secesByInstallationId, { loading: secesLoading, data: secesData }] = useLazyQuery(SECES_BY_INSTAL_IDS_QUERY);
  const [systemVariablesBySysVarTypeIds, {data: sysVarsData}] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);

  const sysVarTypeIds = [
      NATURE_SYSTEM_VARIABLE_TYPE_ID, 
      ACTIVITY_STATUS_SYSTEM_VARIABLE_TYPE_ID
  ];

  useEffect(() => {
    console.log("selectedSeceId>>>>", selectedSeceId);
    dutyHolders({ variables: {}, errorPolicy: 'all' });
    setFilterFormInitValue(filterFormDefaultValues);
    setOnshoreOffshoreList(onOffshoreList);
    setActivityOverallStatusList(overallStatusList);
    systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });
  }, []);

  useEffect(() => {
    if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
        formatSysVarDataAndUpdateStates(sysVarsData.systemVariablesBySysVarTypeIds,
            setNatureList,
            setActivityCompletionStatusList,
            NATURE_SYSTEM_VARIABLE_TYPE_ID,
            ACTIVITY_STATUS_SYSTEM_VARIABLE_TYPE_ID);
    }
  }, [sysVarsData]);

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    const { dutyHolder, installation, sece } = navContext;

    const { id: dh_id = "" } = dutyHolder ? dutyHolder : {};
    const { id: inst_id = "" } = installation ? installation : {};

    if (dh_id !== "" && inst_id !== "") {
      setFilterFormInitValue(prevState => ({
        ...prevState,
        dh_id: dh_id,
        installation_id: inst_id,
      }));
      setSelectedDutyHolderId(dh_id);
      setSelectedInstallationId(inst_id);

      let filterParams = {
        dh_id: dh_id,
        installation_id: inst_id,
      }

      setFilterModalParams(filterParams);
      setHasFilterParam(true);

    } else {
      setFilterModalParams({});
      setHasFilterParam(false);
      setSelectedDutyHolderId("");
      setSelectedInstallationId("");
      setFilterFormInitValue(filterFormDefaultValues);
    }

    setDrDnDutyholder(dutyHolder);
    setDrDnInstallation(installation);
    setDrDnSece(sece);

  }, [navContext]);

  useEffect(() => {
    searchScheduledActivities();
  }, [search, drDnDutyholder, drDnInstallation, drDnSece]);

  const searchScheduledActivities = () => {
    let searchQueryParams = new URLSearchParams(search);

    let category = searchQueryParams.get("category");
    let completionStatus = searchQueryParams.get("completion-status") ?? "";

    let displayNameObj = displayNameList.find(d => d.category === category && d.completionStatus === completionStatus);

    setVsCategory(category);

    if (displayNameObj && displayNameObj.displayName) {
      setDisplayName(displayNameObj.displayName);
    } else {
      setDisplayName("Activity Search");
    }

    const dpDnSearchParams = {
      ...(drDnDutyholder.id && drDnDutyholder.id !== "" && { dh_id: drDnDutyholder.id }),
      ...(drDnInstallation.id && drDnInstallation.id !== "" && { installation_id: drDnInstallation.id }),
      ...(drDnSece.id && drDnSece.id !== "" && { sece_id: drDnSece.id })
    }

    let generalSearchParams = {
      ...(completionStatus && { completion_status: completionStatus })
    }

    let searchParams = {
      ...dpDnSearchParams,
      ...generalSearchParams
    }

    let payload = {
      ...filterModalParams,
      ...searchParams
    }

    setActivityList([]);
    setEmptyListMessage("");
    setSearchParams(searchParams);

    if (drDnDutyholder.id && drDnDutyholder.id !== "" &&
      drDnInstallation.id && drDnInstallation.id !== "") {
      const vsSearchPayload = {
        dutyholderIds: [drDnDutyholder.id],
        installationIds: [drDnInstallation.id]
      };
      setScheduleList([]);
      verificationScheduleSearch({ variables: { param: vsSearchPayload }, errorPolicy: 'all' });
    }

    if (
      (!drDnDutyholder.id &&
        !drDnInstallation.id &&
        selectedDutyHolderId === "" &&
        selectedInstallationId === ""
      ) &&
      (category === VA_CATEGORY_ORININAL ||
        category === VA_CATEGORY_PLANNED ||
        category === VA_CATEGORY_OVERDUE_OR_MISSED)) {

      userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "ABS" ?
        setSelectDhInstMessage(SELECT_DH_MESSAGE) :
        setSelectDhInstMessage(SELECT_INST_MESSAGE);

    } else {
      activitySearchManager({ payload, category });
    }
  }

  useEffect(() => {
    fetchMultiSelectData(selectedDutyHolderId, selectedInstallationId)
  }, [selectedDutyHolderId, selectedInstallationId]);

  useEffect(() => {
    if (dutyHoldersData && dutyHoldersData.dutyHolders) {
      let list = dutyHoldersData.dutyHolders;
      let newDutyHolderList = list.filter(dh => dh.dh_status === "approved" && dh.dh_archived !== true);
      let sortedList = orderBy(newDutyHolderList, ['dh_name'], ['asc']);
      setDhList(sortedList);
    }
  }, [dutyHoldersData]);

  useEffect(() => {
    if (installsData && installsData.installationsByDutyHolderId) {
      let instList = installsData.installationsByDutyHolderId ?? [];
      let sortedList = orderBy(instList, ['installation_title'], ['asc']);
      setInstallationList(sortedList);
    }
  }, [installsData]);

  useEffect(() => {
    if (secesData && secesData.secesByInstallationId) {
      let seceList = secesData.secesByInstallationId ?? [];
      let sortedList = orderBy(seceList, ['sece_order_no'], ['asc']);
      setSeceList(sortedList)
    }
  }, [secesData]);

  useEffect(() => {
    if (vsSearchData && vsSearchData.verificationScheduleSearch) {
      let searchResult = vsSearchData.verificationScheduleSearch;
      setScheduleList(searchResult);
    }
  }, [vsSearchData]);

  useEffect(() => {
    if (actSearchData && actSearchData.activitySearch) {
      let actSearchResult = actSearchData.activitySearch;
      if (actSearchResult.length === 0) {
        setEmptyListMessage(EMPTY_LIST_MESSAGE);
      }
      setActivityList(actSearchResult);
    }
  }, [actSearchData]);

  const columns = React.useMemo(
    () => [
      ...(vsCategory !== VA_CATEGORY_ORININAL ? [
        {
          Header: 'Schedule Title',
          accessor: 'schedule_title', // accessor is the "key" in the data
        },
        {
          Header: 'Schedule Start Date',
          accessor: 'schedule_start_date', // accessor is the "key" in the data
          sortType: (a, b) => {
            const { values: { schedule_start_date: aDate = "" } } = a ? a : {};
            const { values: { schedule_start_date: bDate = "" } } = b ? b : {};

            if (aDate === "N/A" && bDate === "N/A") {
              return 0; // Both are "N/A," consider them equal
            } else if (aDate === "N/A") {
              return 1; // a is "N/A," place it after b
            } else if (bDate === "N/A") {
              return -1; // b is "N/A," place it after a
            }

            var a1 = new Date(aDate);
            var b1 = new Date(bDate);
            if (a1 < b1)
              return 1;
            else if (a1 > b1)
              return -1;
            else
              return 0;
          }
        },
        {
          Header: 'Schedule End Date',
          accessor: 'schedule_end_date', // accessor is the "key" in the data
          sortType: (a, b) => {
            const { values: { schedule_end_date: aDate = "" } } = a ? a : {};
            const { values: { schedule_end_date: bDate = "" } } = b ? b : {};

            if (aDate === "N/A" && bDate === "N/A") {
              return 0; // Both are "N/A," consider them equal
            } else if (aDate === "N/A") {
              return 1; // a is "N/A," place it after b
            } else if (bDate === "N/A") {
              return -1; // b is "N/A," place it after a
            }

            var a1 = new Date(aDate);
            var b1 = new Date(bDate);
            if (a1 < b1)
              return 1;
            else if (a1 > b1)
              return -1;
            else
              return 0;
          }
        },
      ] : []),
      {
        Header: 'Activity Ref.',
        accessor: 'activity_ref', // accessor is the "key" in the data
      },
      {
        Header: 'Activity Short Desc.',
        accessor: 'activity_short_desc', // accessor is the "key" in the data
      },
      // {
      //   Header: 'Full Verification Activity',
      //   accessor: 'activity_full_verification', // accessor is the "key" in the data
      // },
      {
        Header: 'SECE Title',
        accessor: 'sece_title', // accessor is the "key" in the data
      },
      {
        Header: 'Nature',
        accessor: 'activity_nature', // accessor is the "key" in the data
      },
      {
        Header: 'Frequency',
        accessor: 'activity_freq', // accessor is the "key" in the data
      },
      {
        Header: 'Onshore/Offshore',
        accessor: 'activity_onshore_offshore', // accessor is the "key" in the data
      },
      {
        Header: 'ABS IVB',
        accessor: 'activity_abs_ivb', // accessor is the "key" in the data
      },
      {
        Header: 'Activity Status – Completion',
        accessor: 'activity_report_current_status', // accessor is the "key" in the data
      },
      {
        Header: 'Overall Status',
        accessor: 'activity_current_status', // accessor is the "key" in the data
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { va_id, activity_report_current_status, activity_current_status } }) => (
          <div className="text-right" style={{ minWidth: '75px' }}>
            { /* <Link to="/reporting/asset-verification-pdf" className="button-red mb-1 mr-1 text-xs">PDF</Link> */}
            <TableDropDown title="More" items={dropdownListItems({ vaId: va_id, completionStatus: activity_report_current_status, overallStatus: activity_current_status })} />
          </div>
        )
      },
    ],
    [vsCategory]
  )

  const clearFilter = (category) => {
    setSelectedSeceId("");
    setActivityList([]);
    setEmptyListMessage("");

    const payload = {
      ...(drDnDutyholder.id && drDnDutyholder.id !== "" && { dh_id: drDnDutyholder.id }),
      ...(drDnInstallation.id && drDnInstallation.id !== "" && { installation_id: drDnInstallation.id }),
      ...(drDnSece.id && drDnSece.id !== "" && { sece_id: drDnSece.id }),
      ...searchParams
    }

    const { id: dh_id = "" } = drDnDutyholder ? drDnDutyholder : {};
    const { id: inst_id = "" } = drDnInstallation ? drDnInstallation : {};

    if (dh_id !== "" && inst_id !== "") {
      setFilterFormInitValue({
        ...filterFormDefaultValues,
        dh_id: dh_id,
        installation_id: inst_id,
      });
      setSelectedDutyHolderId(dh_id);
      setSelectedInstallationId(inst_id);

      let filterParams = {
        dh_id: dh_id,
        installation_id: inst_id,
      }

      setFilterModalParams(filterParams);
      setHasFilterParam(true);
    } else {
      setSeceList([]);
      setInstallationList([]);
      setFilterModalParams({});
      setHasFilterParam(false);
      setSelectedDutyHolderId("");
      setSelectedInstallationId("");
      setFilterFormInitValue(filterFormDefaultValues);
    }

    if (
      (!drDnDutyholder.id &&
        !drDnInstallation.id
      ) &&
      (category === VA_CATEGORY_ORININAL ||
        category === VA_CATEGORY_PLANNED ||
        category === VA_CATEGORY_OVERDUE_OR_MISSED)) {
      userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "ABS" ?
        setSelectDhInstMessage(SELECT_DH_MESSAGE) :
        setSelectDhInstMessage(SELECT_INST_MESSAGE);
    } else {
      activitySearchManager({ payload, category });
    }

    if (drDnDutyholder.id && drDnDutyholder.id !== "" &&
      drDnInstallation.id && drDnInstallation.id !== "") {
      const vsSearchPayload = {
        dutyholderIds: [drDnDutyholder.id],
        installationIds: [drDnInstallation.id]
      };
      setScheduleList([]);
      verificationScheduleSearch({ variables: { param: vsSearchPayload }, errorPolicy: 'all' });
    }

    toggleFilterModal();
  }

  const activitySearchManager = ({ payload, category }) => {
    setActivityList([]);
    setSelectDhInstMessage("");
    let tempPayLoad = { ...payload, activity_category: category }
    activitySearch({ variables: { param: { ...tempPayLoad } }, errorPolicy: 'all' });
  }

  function toggleFilterModal() {
    const modal = document.querySelector('.filtermodal')
    if (modal && modal.classList) {
      modal.classList.toggle('opacity-0')
      modal.classList.toggle('pointer-events-none')
    }
  }

  const fetchMultiSelectData = (dutyHolderId, installationId) => {
    if (dutyHolderId && dutyHolderId !== "") {
      setScheduleList([]);
      setInstallationList([])
      installationsByDutyHolderId({ variables: { dhId: dutyHolderId }, errorPolicy: 'all' });
    }
    if (installationId && installationId !== "") {
      setSeceList([]);
      secesByInstallationId({ variables: { installationId: installationId }, errorPolicy: 'all' });
    }
  }

  const handleDhChange = (event) => {
    let value = event.target.value;
    setSelectedInstallationId("");
    setInstallationList([]);
    setSeceList([]);
    setScheduleList([]);
    setSelectedDutyHolderId(value);
  };

  const handleInstallationChange = (event) => {
    let value = event.target.value;
    setSelectedInstallationId(value);

    if (value && value !== "") {
      const vsSearchPayload = {
        dutyholderIds: [],
        installationIds: [value]
      };
      setScheduleList([]);
      verificationScheduleSearch({ variables: { param: vsSearchPayload }, errorPolicy: 'all' });
    }
  };

  const handleSeceChange = (event) => {
    let value = event.target.value;
    setSelectedSeceId(value);
  };

  if (actLoading) {
    return (
      <PageSpinner displayText={"loading activities"} />
    )
  }

  if (actError) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  //TITLE / INFO & MAIN BUTTON SECTION
  return (
    <div className="w-full px-8 pb-8 wide-table">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">{displayName} <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3">A table of active Verification Activities.  Click ‘More’ to view, edit and archive each activity.  To filter the Activities shown, click ‘Filter Activities’.</p>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      {getBreadCrumbHeader({
        userTypeAndRoleObject: userTypeAndRoleObject,
        drDnDutyholder: drDnDutyholder,
        drDnInstallation: drDnInstallation,
        drDnSece: drDnSece,
        section: "Dashboard",
        title: "Reporting - Activity Search"
      })
      }

      <Link
        to={{ pathname: pathname, search: search }}
        className="button-red mr-2 mb-2 py-3 px-5 modal-button"
        onClick={() => toggleFilterModal()}
        style={{ ...(hasFilterParam && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}>Filter Activities</Link>

      {emptyListMessage !== "" && <h4 className="mt-4">{emptyListMessage}</h4>}

      {selectDhInstMessage !== "" && <h4 className="mt-4">{selectDhInstMessage}</h4>}

      {activityList.length > 0 && <Table columns={columns} data={activityList} />}

      {/* SEARCH FILTER POP UP */}
      <div className="filtermodal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
        <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleFilterModal()}></div>
        <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
          <Formik
            initialValues={filterFormInitValue}
            enableReinitialize={true}
            //validationSchema={{}}
            onSubmit={(values, action) => {

              let dutyholderId = values.dh_id && values.dh_id !== "" ? values.dh_id : "";
              let installationId = values.installation_id && values.installation_id !== "" ? values.installation_id : "";
              let seceId = values.sece_id && values.sece_id !== "" ? values.sece_id : "";
              let vsIdFilter = values.schedule_id && values.schedule_id !== "" ? { vs_id: values.schedule_id } : {};

              let vaType = values.nature_id && values.nature_id !== "" ? values.nature_id : "";
              let vaOnshoreOffshore = values.onshore_offshore && values.onshore_offshore !== "" ? values.onshore_offshore : "";
              let vaCompletionStatus = values.va_completion_status && values.va_completion_status !== "" ? values.va_completion_status : "";
              let activityOverallStatus = values.overall_status && values.overall_status !== "" ? values.overall_status : "";

              let filterParams = {
                dh_id: dutyholderId,
                installation_id: installationId,
                sece_id: seceId,
                ...vsIdFilter,
                va_type: vaType,
                va_onshore_offshore: vaOnshoreOffshore,
                va_completion_status: vaCompletionStatus,
                activity_overall_status: activityOverallStatus,
              }

              let payload = {
                ...filterParams,
                ...searchParams
              }

              setFilterModalParams(filterParams);

              if (
                (!drDnDutyholder.id && !drDnInstallation.id &&
                  dutyholderId === "" && installationId === "") &&
                (vsCategory === VA_CATEGORY_ORININAL ||
                  vsCategory === VA_CATEGORY_PLANNED ||
                  vsCategory === VA_CATEGORY_OVERDUE_OR_MISSED)) {
                userTypeAndRoleObject &&
                  userTypeAndRoleObject.userType === "ABS" ?
                  setSelectDhInstMessage(SELECT_DH_MESSAGE) :
                  setSelectDhInstMessage(SELECT_INST_MESSAGE);
              } else {
                activitySearchManager({ payload, category: vsCategory });
              }

              setHasFilterParam(true);
              setFilterFormInitValue({ ...values });
              toggleFilterModal();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-4 pb-2 border-b-2 border-gray-200">
                  <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">Filter {displayName !== "Scheduled Activities" ? displayName : "Activities"}</h2>
                </div>
                <div className="mb-1">
                  <label htmlFor="dh_id" className="block mb-1 text-blue-900 font-semibold">Duty Holder</label>
                  <select
                    disabled={dutyHoldersLoading}
                    className="form-control block w-full"
                    name="dh_id"
                    id="dh_id"
                    onChange={(event) => {
                      handleChange(event);
                      handleDhChange(event);
                      setFieldValue("installation_id", "");
                      setFieldValue("sece_id", "");
                      setFieldValue("schedule_id", "");
                    }}
                    onBlur={handleBlur}
                    value={values.dh_id}
                  >
                    <option value={""}>{dutyHoldersLoading ? "loading..." : "Select Duty Holder"}</option>
                    {dhList.map(dh =>
                      <option key={dh._id} value={dh._id}>{dh.dh_name}</option>
                    )}
                  </select>
                  <FormError touched={touched.dh_id} message={errors.dh_id} />
                </div>

                <div className="mb-1">
                  <label htmlFor="installation_id" className="block mb-1 text-blue-900 font-semibold">Installation</label>
                  <select
                    disabled={installsLoading}
                    className="form-control block w-full"
                    name="installation_id"
                    id="installation_id"
                    onChange={(event) => {
                      handleChange(event);
                      handleInstallationChange(event);
                      setFieldValue("sece_id", "");
                      setFieldValue("schedule_id", "");
                    }}
                    onBlur={handleBlur}
                    value={values.installation_id}
                  >
                    <option value={""}>{installsLoading ? "loading..." : "Select Installation"}</option>
                    {installationList.map(inst =>
                      <option key={inst._id} value={inst._id}>{inst.installation_title}</option>
                    )}
                  </select>
                  <FormError touched={touched.installation_id} message={errors.installation_id} />
                </div>

                <div className="mb-1">
                  <label htmlFor="sece_id" className="block mb-1 text-blue-900 font-semibold">SECE</label>
                  <select
                    disabled={secesLoading}
                    className="form-control block w-full"
                    name="sece_id"
                    id="sece_id"
                    onChange={(event) => {
                      handleChange(event);
                      handleSeceChange(event);
                    }}
                    onBlur={handleBlur}
                    value={values.sece_id}
                  >
                    <option value={""}>{secesLoading ? "loading..." : "Select SECE"}</option>
                    {seceList.map(sece =>
                      <option key={sece._id} value={sece._id}>{`${sece.sece_client_ref ? sece.sece_client_ref : "N/A"} : ${sece.sece_title}`}</option>
                    )}
                  </select>
                  <FormError touched={touched.sece_id} message={errors.sece_id} />
                </div>

                <div className="mb-1">
                  <label htmlFor="schedule_id" className="block mb-1 text-blue-900 font-semibold">Schedule Title</label>
                  <select
                    disabled={vsSearchLoading}
                    className="form-control block w-full"
                    name="schedule_id"
                    id="schedule_id"
                    onChange={(event) => {
                      handleChange(event);
                      handleSeceChange(event);
                    }}
                    onBlur={handleBlur}
                    value={values.schedule_id}
                  >
                    <option value={""}>{vsSearchLoading ? "loading..." : "Select Schedule Title"}</option>
                    {scheduleList.map(schedule =>
                      <option key={schedule._id} value={schedule._id}>{formatVsTitle(schedule)}</option>
                    )}
                  </select>
                  <FormError touched={touched.schedule_id} message={errors.schedule_id} />
                </div>

                <div className="mb-4">
                  <label htmlFor="nature_id" className="block mb-1 text-blue-900 font-semibold">Nature</label>
                  <select
                    className="form-control block w-full"
                    name="nature_id"
                    id="nature_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nature_id}
                  >
                    <option value={""}>Select Nature</option>
                    {natureList.map(nature =>
                      <option key={nature.id} value={nature.title}>{nature.title}</option>
                    )}
                  </select>
                </div>

                <div className="mb-4">
                  <label htmlFor="onshore_offshore" className="block mb-1 text-blue-900 font-semibold">Onshore / Offshore</label>
                  <select
                    className="form-control block w-full"
                    name="onshore_offshore"
                    id="onshore_offshore"
                    onChange={(event) => { handleChange(event); }}
                    onBlur={handleBlur}
                    value={values.onshore_offshore}
                  >
                    <option value={""}>Select Onshore / Offshore</option>
                    {onshoreOffshoreList.map(onoff =>
                      <option key={onoff.id} value={onoff.value}>{onoff.value}</option>
                    )}
                  </select>
                </div>

                <div className="mb-4">
                  <label htmlFor="va_completion_status" className="block mb-1 text-blue-900 font-semibold">Activity Status Completion</label>
                  <select
                    className="form-control block w-full"
                    name="va_completion_status"
                    id="va_completion_status"
                    onChange={(event) => { handleChange(event); }}
                    onBlur={handleBlur}
                    value={values.va_completion_status}
                  >
                    <option value={""}>Select Activity Status Completion</option>
                    {activityCompletionStatusList.map(cl =>
                      <option key={cl.id} value={cl.id}>{cl.title}</option>
                    )}
                  </select>
                </div>

                <div className="mb-4">
                  <label htmlFor="overall_status" className="block mb-1 text-blue-900 font-semibold">Overall Status</label>
                  <select
                    className="form-control block w-full"
                    name="overall_status"
                    id="overall_status"
                    onChange={(event) => { handleChange(event); }}
                    onBlur={handleBlur}
                    value={values.overall_status}
                  >
                    <option value={""}>Select Overall Status</option>
                    {activityOverallStatusList.map(cl =>
                      <option key={cl.id} value={cl.id}>{cl.title}</option>
                    )}
                  </select>
                </div>

                <button type="submit" className="mt-3 mb-4 button-red capitalize mr-4">Apply Filter</button>
                <button type="button" onClick={() => { clearFilter(vsCategory) }} className="mt-3 mb-4 button-red capitalize">Clear Filter</button>
              </form>
            )}
          </Formik>

        </div>
      </div>
      {/* SEARCH FILTER POP UP END */}
    </div>
  )
  //TITLE / INFO & MAIN BUTTON SECTION - END
}


function formatVsTitle(schedule) {
  let scheduleTitle = "";
  if (schedule) {
    const { vs_title, vs_start_date_time, vs_end_date_time } = schedule ?? {};
    let formatedStartDate = formatDateToShortMonthString(vs_start_date_time);
    let formatedEndDate = formatDateToShortMonthString(vs_end_date_time);
    if (formatedStartDate !== "" && formatedEndDate !== "") {
      scheduleTitle = `${vs_title} (${formatedStartDate} - ${formatedEndDate})`;
    } else {
      scheduleTitle = vs_title;
    }
  }
  return scheduleTitle;
}

function formatSysVarDataAndUpdateStates(list, setNatureList, setActivityStatusList, natureTypeId, activityStatusTypeId) {
  let natureList = [];
  let activityStatusList = [];

  if (list && list.length) {
      let tempList = list.filter(sysVar => sysVar.sysvar_archived !== true);

      let tempNatureList = tempList.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === natureTypeId);
      let tempActivityStatusList = tempList.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === activityStatusTypeId);

      tempNatureList.forEach(nature => {
          let newNature = {
              id: nature._id,
              title: nature.sysvar_title
          }
          natureList.push(newNature);
      })

      tempActivityStatusList.forEach(status => {
          let newActivityStatus = {
              id: status._id,
              title: status.sysvar_title
          }
          activityStatusList.push(newActivityStatus);
      })
  }

  setNatureList(natureList);
  setActivityStatusList(activityStatusList);
}

export default ActivitySearchAlt;