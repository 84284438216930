/* eslint-disable react-hooks/exhaustive-deps */

import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect, useContext } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from '../global/pageSpinner';
import { AuthContext } from "../../context/authContext";
import { createUserTypeAndRoleObject } from "../../utils/index";
import { VerificationContext } from "../../context/verificationContext";
import { omit } from 'lodash';
import {
    SCHEDULE_FORM_ORIGIN_VS_PLANNER,
    SCHEDULE_FORM_ORIGIN_VS_REGISTER,
    SCHEDULE_FORM_ORIGIN_VS_AR_REGISTER
} from '../../constants/';

const Validation = Yup.object().shape({})

const OVERLAPPING_VS_SEARCH = gql`
query OverlappingVsSearch($param: VerificationScheduleSearch!){
    overlappingVsSearch(param: $param){
        _id
        verifier_ids{
          _id
          user_fname
          user_lname
        }
        vs_start_date_time
        vs_end_date_time
    }
}`;

const USERS_QUERY = gql`
query {
    users {
        _id
        user_fname
        user_lname
        user_archived
        user_type {
            _id
            user_type_display_name
        }     
        user_role{
            _id
            user_role_code
        }   
    }
}`;

const CREATE_VERIFICATION_SCHEDULE = gql`
mutation CreateVerificationSchedule($vs: CreateVerificationSchedule!){
    createVerificationSchedule(vs: $vs) {
            _id
            success
            status_code
            message
    }
}`;

const UPDATE_VERIFICATION_SCHEDULE = gql`
mutation UpdateVerificationSchedule($vsId: String!, $userId: String, $vs: UpdateVerificationScheduleInput!) {
    updateVerificationSchedule(_id: $vsId, userId: $userId, vs: $vs) {
          _id
          success
          status_code
          message
    }
}`;

const AddEditVerificationScheduleStep3 = () => {
    const [verifierList, setVerifierList] = useState([]);
    const [overlappingVerifierList, setOverlappingVerifierList] = useState([]);
    const [editingVerifierList, setEditingVerifierList] = useState([]);

    const [shouldNavigate, setShouldNavigate] = useState(false);
    const [shouldSpin, setShouldSpin] = useState(true);

    //form add or edit state passed from link
    const { formMode, vsId = "" } = useParams();

    const history = useHistory();
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const authContext = useContext(AuthContext);
    const verificationContext = useContext(VerificationContext);

    const [formInitValues, setFormInitValues] = useState({
        verifier_ids: []
    });

    const [users, { error: userError, data: usersData }] = useLazyQuery(USERS_QUERY);
    const [overlappingVsSearch, { error: verifierError, data: vsData }] = useLazyQuery(OVERLAPPING_VS_SEARCH);
    const [createVerificationSchedule, { loading: createVsLoading, error: createVsError,  data: createVsData }] = useMutation(CREATE_VERIFICATION_SCHEDULE);
    const [updateVerificationSchedule, { loading: updateVsLoading, error: updateVsError,  data: updateVsData }] = useMutation(UPDATE_VERIFICATION_SCHEDULE);

    useEffect(() => {
        if (authContext && authContext.user) {
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);

    useEffect(() => {
        const { step1FormData, step3FormData } = verificationContext;

        //Refreshing the browser causes the loss of context data. So at the moment it redirects to the Step 1 page
        if (!step1FormData.hasOwnProperty("vs_title")) {
            moveToStep1(formMode, vsId);
        }

        //Here I want to save the ids for the verifiers when editing, these values are used 
        //to support how the toggles function when editing
        if (formMode === "edit") {
            setEditingVerifierList(step3FormData.verifier_ids);
        }

        const payload = { vs_start_date_time: step1FormData.vs_start_date_time, vs_end_date_time: step1FormData.vs_end_date_time }
        overlappingVsSearch({ variables: { param: payload }, errorPolicy: 'all' });
        setInitFormValuesOnBackFromNav();
    }, []);

    useEffect(() => {
        if (vsData && vsData.overlappingVsSearch) {
            let tempOverlappingVerifiers = [];
            vsData.overlappingVsSearch.forEach(vs => {
                tempOverlappingVerifiers = [...tempOverlappingVerifiers, ...vs.verifier_ids]
            });
            setOverlappingVerifierList(tempOverlappingVerifiers);
            users({ variables: {}, errorPolicy: 'all' });
        }
    }, [vsData]);

    useEffect(() => {
        if (usersData && usersData.users) {
            let tempList = formatUserData(usersData.users, overlappingVerifierList);
            setVerifierList(tempList);
            setShouldSpin(false);
        }
    }, [usersData]);

    useEffect(() => {
        if (createVsData && createVsData.createVerificationSchedule && shouldNavigate) {
            const { success, message } = createVsData.createVerificationSchedule;
            if (!success) {
                alert(message);
            }
            if (success) {
                verificationContext.clearFormData();
                history.push("/verification");
            }
        }
    }, [createVsData]);

    useEffect(() => {
        if (updateVsData && updateVsData.updateVerificationSchedule) {
            const { success, message } = updateVsData.updateVerificationSchedule;
            if (!success) {
                alert(message);
            }
            if (success) {
                let originState = verificationContext.originStateData;
                const { pathname, state } = createMoveBackPath(originState, vsId);
                verificationContext.clearFormData();
                history.push(pathname, state);
            }
        }
    }, [updateVsData]);

    useEffect(() => {
        let step3FormData = {
            verifier_ids: formInitValues.verifier_ids,
        };
        verificationContext.updateStep3FormData(step3FormData);
    }, [formInitValues]);

    const setInitFormValuesOnBackFromNav = () => {
        const { step3FormData } = verificationContext;
        if (step3FormData.verifier_ids) {
            let initValues = {
                verifier_ids: step3FormData.verifier_ids
            }
            setFormInitValues(initValues);
        }
    }

    const handleVerifierChange = (event, initValues, verifier) => {
        let checked = event.target.checked;
        let verifierId = verifier.id;
        let verifierIdList = initValues.verifier_ids;
        let tempIdList = [];
        if (checked) {
            verifierIdList.push(verifierId);
            tempIdList = verifierIdList;
        } else {
            tempIdList = verifierIdList.filter(id => id !== verifierId)
        }
        setFormInitValues({ ...formInitValues, verifier_ids: tempIdList });
    }

    const isVerifierChecked = (initFormValues, verifier) => {
        let verifierIdList = initFormValues.verifier_ids;
        let result = verifierIdList.some(id => id === verifier.id)
        return result;
    };

    const moveToStep1 = (formMode, vsId) => {
        if (formMode === "add") {
            history.push(`/verification/${formMode}/step1`, { formMode: formMode });
        } else {
            history.push(`/verification/${formMode}/step1/${vsId}`, { formMode: formMode });
        }
    };

    if (shouldSpin ) {
        return (
            <PageSpinner />
        )
    }

    if (createVsLoading || updateVsLoading) {
        return (
            <PageSpinner displayText={"submitting form"} />
        )
    }

    if (userError || verifierError || createVsError || updateVsError) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Verification Schedule - Assign Verifier</h1>
            </div>

            <Formik
                enableReinitialize
                initialValues={formInitValues}
                validationSchema={Validation}
                onSubmit={(values, { setSubmitting }) => {
                    const { _id: userId = "" } = authContext.user;
                    const { step1FormData, step2FormData } = verificationContext;
                    let sharedId = uuidv4();

                    if (step1FormData.vs_title && step2FormData.va_ids) {

                        if (formMode === 'add') {

                            let newVs = {
                                ...step1FormData,
                                ...step2FormData,
                                verifier_ids: formInitValues.verifier_ids,
                                vs_shared_id: sharedId,
                                vs_added_id: userId,
                                vs_added_date: new Date()
                            };

                            let vsReCurrList = createReCurrVsList(newVs);

                            vsReCurrList.forEach(vsItem => {
                                createVerificationSchedule({ variables: { vs: vsItem } });
                            });

                            setShouldNavigate(true);
                        }

                        if (formMode === 'edit' && vsId !== "") {
                            let tempStep1FormData = omit(step1FormData, 'vs_periodicity_title');

                            let updatedVs = {
                                ...tempStep1FormData,
                                ...step2FormData,
                                verifier_ids: formInitValues.verifier_ids,
                            };

                            updateVerificationSchedule({ variables: { vsId: vsId, userId: userId, vs: updatedVs } });
                        }

                        setSubmitting(false);

                    } else {
                        moveToStep1(formMode, vsId)
                    }

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setSubmitting,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="my-4">
                            <Link to={{
                                pathname: `/verification/${formMode}/step2/${vsId}`,
                                state: { formMode: formMode }
                            }} className="button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>
                        </div>

                        <table className="w-full md:w-1/2 mb-5 table-auto">
                            <tbody>
                                <tr>
                                    <th className="p-3 text-left text-blue-900 font-semibold border">Verifier Name</th>
                                    <th className="p-3 text-left text-blue-900 font-semibold border">Availability</th>
                                    <th className="p-3 text-right text-blue-900 font-semibold border">Assign (N/Y)</th>
                                </tr>

                                {verifierList.length ? verifierList.map(verifier =>
                                    <tr key={verifier.id}>
                                        <td className="p-3 border">{verifier.full_name}</td>
                                        <td className="p-3 border">{(verifier.isAvailable || editingVerifierList.some(v => v === verifier.id)) ? "Yes" : "No"}</td>
                                        <td className="p-3 text-right border">
                                            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                <input type="checkbox"
                                                    disabled={!verifier.isAvailable && !editingVerifierList.some(v => v === verifier.id)}
                                                    name={"verifier-" + verifier.id}
                                                    id={"verifier-" + verifier.id}
                                                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                                                    checked={isVerifierChecked(formInitValues, verifier)}
                                                    onChange={(event) => { handleVerifierChange(event, formInitValues, verifier) }}
                                                />
                                                <label htmlFor={"verifier-" + verifier.id} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                                            </div>
                                        </td>
                                    </tr>
                                ) : noDataNotice()}
                            </tbody>
                        </table>

                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className={`${"mt-3 button-red capitalize" + (isSubmitting ? " cursor-not-allowed" : "")}`} 
                        >{formMode} Verification Schedule
                        </button>

                    </form>
                )}
            </Formik>

        </div>
    )

}

function createMoveBackPath(state, vsId) {
    let pathParam = { pathname: "", state: {} }
    if (state) {
        const { vs_status = "", origin = "" } = state;
        switch (origin) {
            case SCHEDULE_FORM_ORIGIN_VS_PLANNER:
                pathParam = { ...pathParam, pathname: "/verification" }
                break;
            case SCHEDULE_FORM_ORIGIN_VS_REGISTER:
                pathParam = { ...pathParam, pathname: "/verification/verification-register", state: { vs_status: vs_status } }
                break;
            case SCHEDULE_FORM_ORIGIN_VS_AR_REGISTER:
                pathParam = { ...pathParam, pathname: `/verification/${vsId}/activity-register`, state: { vs_status: vs_status } }
                break;
            default:
                break;
        }
    }
    return pathParam
}

function formatUserData(userList, overlappingVerifiers) {
    let newUserList = [];

    if (userList && userList.length) {
        let tempList = userList.filter(user => user.user_type && user.user_type.user_type_display_name === "ABS" && user.user_archived !== true)
        tempList.forEach(user => {
            let overlappingVerifier = overlappingVerifiers.find(v => v._id === user._id);
            let newUser = {
                id: user._id,
                full_name: user.user_fname + " " + user.user_lname,
                user_type: user.user_type ? user.user_type.user_type_display_name : "",
                isAvailable: overlappingVerifier ? false : true
            }
            newUserList.push(newUser);
        })
    }

    return newUserList;
}

function noDataNotice() {
    return (
        <tr>
            <td className="p-3 border">{"Data is not Available."}</td>
            <td className="p-3 text-right border">
            </td>
        </tr>
    )
}

function createReCurrVsList(vs) {
    let vsList = [];

    if (vs) {
        let recurrence = createRecurrence(vs);

        let numOfRecurrs = recurrence.recurr;
        let numOfMonths = recurrence.month;

        for (var i = 0; i < numOfRecurrs; i++) {
            let monthsToAdd = i * numOfMonths;
            let startDate = new Date(vs.vs_start_date_time);
            let endDate = new Date(vs.vs_end_date_time);

            startDate.setMonth(startDate.getMonth() + monthsToAdd);
            endDate.setMonth(endDate.getMonth() + monthsToAdd);

            let tempVs = omit(vs, 'vs_periodicity_title');

            let newVs = {
                ...tempVs,
                vs_start_date_time: startDate,
                vs_end_date_time: endDate
            };

            vsList.push(newVs);
        }

    }
    return vsList;
}

function createRecurrence(vs) {
    let periodicity = vs.vs_periodicity_title;
    let defaultRecurrence = { recurr: 1, month: 0 };

    switch (periodicity) {
        case "6 Months":
            return { recurr: Math.ceil(120 / 6), month: 6 };

        case "12 Months":
            return { recurr: Math.ceil(120 / 12), month: 12 };

        case "18 Months":
            return { recurr: Math.ceil(120 / 18), month: 18 };

        case "24 Months":
            return { recurr: Math.ceil(120 / 24), month: 24 };

        case "30 Months":
            return { recurr: Math.ceil(120 / 30), month: 30 };

        case "36 Months":
            return { recurr: Math.ceil(120 / 36), month: 36 };

        case "48 Months":
            return { recurr: Math.ceil(120 / 48), month: 48 };

        case "60 Months":
            return { recurr: Math.ceil(120 / 60), month: 60 };

        default:
            return defaultRecurrence;
    }
}

export default AddEditVerificationScheduleStep3;
