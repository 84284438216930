/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import Table from '../global/Table';
import { Link } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import TableDropDown from '../global/TableDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateToLocaleDateString } from "../../utils/index";
import { createUserTypeAndRoleObject } from "../../utils/index";
import PageSpinner from '../global/pageSpinner';
import { AuthContext } from "../../context/authContext";

import { NavContext } from '../../context/navContext';
import { DashboardContext } from '../../context/dashboardContext';

const dropdownListItems = ({ id }) => {
  let listItems = [
    {
      id: 1,
      name: 'PDF',
      link: ""
    },
    {
      id: 2,
      name: 'View',
      link: `/reporting/activity-search/view/${id}`,
      state: { actId: id }
    },
    {
      id: 3,
      name: 'Edit',
      link: "",
      state: { formMode: 'edit' }
    },
    {
      id: 4,
      name: 'Archive',
      link: ""
    }
  ];

  return listItems;
}

const REPORT_HISTORIES_BY_STATUS_QUERY = gql`
query ReportHistorisByStatus($status: String){
  reportHistorisByStatus(status: $status) {
      _id
      dh_id{
        _id
        dh_name
      }
      installation_id{
        _id
        installation_title
      }
      sece_id{
        _id
        sece_title
      }
      va_id{
        _id
        va_ref
        va_client_ref
        va_short_desc
        va_long_desc
        va_type
        va_onshore_offshore
        va_freq
        va_baseline_sample
        va_class
        va_legislation
        va_added_date
        va_added_id
        va_archived
        va_status
      } 
      report_ref
      report_archived
      report_status
      history_parent_record_id
      history_modifying_user_id{
        user_fname
        user_lname
      }
      history_modified_date
      history_modified_date_timestamp
  }
}`;

const REPORT_BY_CURRENT_STATUS_QUERY = gql`
query ReportByCurrentStatus($param: ReportSearchInput!){
  reportByCurrentStatus(param: $param) {
      _id
      dh_id{
        _id
        dh_name
      }
      installation_id{
        _id
        installation_title
      }
      sece_id{
        _id
        sece_title
      }
      va_id{
        _id
        va_ref
        va_client_ref
        va_short_desc
        va_long_desc
        va_type
        va_onshore_offshore 
        va_freq
        va_baseline_sample
        va_class
        va_legislation
        va_added_date
        va_added_id
        va_archived
        va_status
      } 
      report_ref
      report_archived
      report_status
  }
}`;

function ActivitySummary(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const [drDnDutyholder, setDrDnDutyholder] = useState({});
  const [drDnInstallation, setDrDnInstallation] = useState({});
  const [drDnSece, setDrDnSece] = useState({});
  const [displayName, setDisplayName] = useState("Activity Summary");
  const [completionStatus, setCompletionStatus] = useState("");
  const [activitySearchParam, setActivitySearchParam] = useState({});

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const dashboardContext = useContext(DashboardContext);

  const [{ loading: rptHistLoading, error: rptHistError, data: reportHistoriesData }] = useLazyQuery(REPORT_HISTORIES_BY_STATUS_QUERY);
  const [reportByCurrentStatus, { loading: rptListLoading, error: rptListError, data: reportListData }] = useLazyQuery(REPORT_BY_CURRENT_STATUS_QUERY);

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    const { dutyHolder, installation, sece } = navContext;
    setDrDnDutyholder(dutyHolder);
    setDrDnInstallation(installation);
    setDrDnSece(sece);
  }, [navContext]);

  useEffect(() => {
    if (dashboardContext && dashboardContext.activitySearchParam) {
      let activitySearchParam = dashboardContext.activitySearchParam;
      const {
        displayName = "Activity Summary",
        completionStatus = ""
      } = activitySearchParam;
      setDisplayName(displayName);
      setCompletionStatus(completionStatus);
      setActivitySearchParam(activitySearchParam);
    }
  }, [dashboardContext]);

  useEffect(() => {
    const { completionStatus = "" } = activitySearchParam;

    let dpDnSearchParams = {
      ...(drDnDutyholder.id && drDnDutyholder.id !== "" && { dh_id: drDnDutyholder.id }),
      ...(drDnInstallation.id && drDnInstallation.id !== "" && { installation_id: drDnInstallation.id }),
      ...(drDnSece.id && drDnSece.id !== "" && { sece_id: drDnSece.id })
    }

    let generalSearchParams = {
      status: completionStatus,
    }

    let searchParams = {
      ...dpDnSearchParams,
      ...generalSearchParams
    }
    reportByCurrentStatus({ variables: { param: { ...searchParams } }, errorPolicy: 'all' });
  }, [activitySearchParam, drDnDutyholder, drDnInstallation, drDnSece]);

  useEffect(() => {
    if (reportHistoriesData && reportHistoriesData.reportHistorisByStatus) {
      let formatedList = formatActivityData(reportHistoriesData.reportHistorisByStatus);
      setReportList(formatedList)
    }
  }, [reportHistoriesData]);

  useEffect(() => {
    if (reportListData && reportListData.reportByCurrentStatus) {
      let formatedList = formatActivityData(reportListData.reportByCurrentStatus);
      setReportList(formatedList)
    }
  }, [reportListData]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Activity Ref.',
        accessor: 'activity_ref', // accessor is the "key" in the data
      },
      {
        Header: 'Short Desc.',
        accessor: 'activity_short_desc', // accessor is the "key" in the data
      },
      {
        Header: 'SECE',
        accessor: 'sece', // accessor is the "key" in the data
      },
      {
        Header: 'Nature',
        accessor: 'activity_nature', // accessor is the "key" in the data
      },
      {
        Header: 'Frequency',
        accessor: 'activity_freq', // accessor is the "key" in the data
      },
      {
        Header: 'Onshore/Offshore',
        accessor: 'activity_onshore_offshore', // accessor is the "key" in the data
      },
      {
        Header: 'ABS IVB',
        accessor: 'activity_abs_ivb', // accessor is the "key" in the data
      },
      ...(completionStatus === "completed" ? [{
        Header: 'Completed Under Report',
        accessor: 'completed_under_report', // accessor is the "key" in the data
      },
      {
        Header: 'Completed By',
        accessor: 'completed_by', // accessor is the "key" in the data
      },
      {
        Header: 'Completed On',
        accessor: 'completed_date', // accessor is the "key" in the data
      }] : []),
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { id } }) => (
          <div className="text-right" style={{ minWidth: '75px' }}>
            <TableDropDown title="More" items={dropdownListItems({ id })} />
          </div>
        )
      },
    ],
    [completionStatus]
  )

  if (rptHistLoading || rptListLoading) {
    return (
      <PageSpinner />
    )
  }

  if (rptHistError || rptListError) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  //TITLE / INFO & MAIN BUTTON SECTION
  return (
    <div className="w-full px-8 pb-8 wide-table">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">{displayName}<FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam condimentum a lectus non condimentum. Aliquam rhoncus, enim vitae condimentum pharetra, urna magna elementum sapien, non vehicula turpis tellus nec velit. Vivamus tristique venenatis neque, eget tristique mauris suscipit aliquet. Pellentesque et massa nunc.</p>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "ABS" &&
        drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - {displayName} - Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "Duty HOlder" &&
        drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - {displayName} - Installation: {drDnInstallation.name}</h6>}
      {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - {displayName} - Duty Holder / Installation : All</h6>}

      {reportList.length ?
        <Table columns={columns} data={reportList} /> :
        <h4 className="mt-4">There are currently no Activities</h4>
      }
    </div>
  )
  //TITLE / INFO & MAIN BUTTON SECTION - END
}

function formatActivityData(list) {
  let formatedList = []
  if (list && list.length) {

    list.forEach(act => {
      const { sece_title = "N/A" } = act.sece_id ? act.sece_id : {};
      const {
        _id: vaId,
        va_client_ref = "N/A",
        va_short_desc = "N/A",
        va_type = "N/A",
        va_freq = "N/A",
        va_onshore_offshore = "N/A" } = act.va_id ? act.va_id : {};

      const { user_fname = "", user_lname = "" } = act.history_modifying_user_id ? act.history_modifying_user_id : {};
      const completedBy = (user_fname !== "" && user_lname !== "") ? `${user_fname} ${user_lname}` : "N/A";
      const completedDate = act.history_modified_date ? formatDateToLocaleDateString(act.history_modified_date) : "N/A";

      let activity = {
        id: vaId ? vaId : "N/A",
        activity_ref: va_client_ref ? va_client_ref : "N/A",
        activity_short_desc: va_short_desc,
        sece: sece_title,
        activity_nature: va_type,
        activity_freq: va_freq,
        activity_onshore_offshore: va_onshore_offshore,
        activity_abs_ivb: 'N/A',
        completed_under_report: act.report_ref ? act.report_ref : "N/A",
        completed_by: completedBy,
        completed_date: completedDate
      }
      formatedList.push(activity);
    });
  }
  return formatedList;
}

export default ActivitySummary;