/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useContext} from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Table from '../global/Table';
import TableDropDown from '../global/TableDropDown';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import {AuthContext} from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import {capitaliseFirstCase, createUserTypeAndRoleObject} from "../../utils/index";
import BreadCrumb from "../global/Breadcrumb";
import {NOTIFICATION_STATUS_APPROVED, NOTIFICATION_STATUS_REJECT, NOTIFICATION_STATUS_REVISION} from "../../constants";
import Comment from '../global/Comment';
import {sysObjects} from "../../constants/sysObjectList";

const dropdownListItems = ({dutyHolderId, assetGroupId, installationId, mahId}) => (
  [
    {
      id:1,
      name:'Reference Activities',
      link:`/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/mah/${mahId}/reference-activities`
    },
  ]
);

const MAH_BY_INST_ID_QUERY = gql`
query MajorAccidentHazardsByInstallationId ($instId: ID!){
    majorAccidentHazardsByInstallationId (instId: $instId){
      _id
      mah_ref
      mah_client_ref
      mah_title
      mah_archived
      mah_archived_id
      mah_archived_date
      mah_status
  }
}`;

const UPDATE_MAH = gql`
mutation updateMajorAccidentHazard($mahId: String!, $userId: String, $mah: UpdateMajorAccidentHazard!){
  updateMajorAccidentHazard(_id: $mahId, userId: $userId, mah: $mah){
      _id
  }
}`;

function MajorAccidentHazards(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [mahList, setMahList] = useState([]);
  const [showArchivedMahs, setShowArchivedMahs] = useState(false);
  const [drDnInstallation, setDrDnInstallation] = useState({});

  const { id, assetGroupId, installationId } = useParams();
  const { location: { state = "" }, match: { url = "" } } = props ? props : {};

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const history = useHistory();

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  useEffect(() => {
    if (state === "archived") {
      setShowArchivedMahs(true);
    };
  }, []);

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    const { installation } = navContext;
        const { id: prevId } = drDnInstallation;
    
        if (prevId && installation.id && prevId !== installation.id) {
          history.push('/');
        }
        setDrDnInstallation(installation);
  }, [navContext]);

  const [majorAccidentHazardsByInstallationId, { loading: mahsByInstallationIdLoading,
                                               error: mahsByInstallationIdError,
                                               data: mahsByInstallationIdData,
                                               refetch: refetchMahsByInstallationId }] = useLazyQuery(MAH_BY_INST_ID_QUERY);

  const [updateMajorAccidentHazard, { data: updateMahData }] = useMutation(UPDATE_MAH);

  useEffect(() => {
      majorAccidentHazardsByInstallationId({ variables: { instId: installationId }, errorPolicy: 'all' });
  }, [showArchivedMahs]);

  useEffect(() => {
    if (mahsByInstallationIdData && mahsByInstallationIdData.majorAccidentHazardsByInstallationId){
      const { _id: userId = "" } = authContext.user ? authContext.user : {};
      let tempMahList = formatMahsData(mahsByInstallationIdData.majorAccidentHazardsByInstallationId, showArchivedMahs, userId, state, userTypeAndRoleObject);
      setMahList(tempMahList);
    }
  }, [mahsByInstallationIdData]);

  useEffect(() => {
    if (updateMahData && updateMahData.updateMajorAccidentHazard) {
        refetchMahsByInstallationId();
    }
  }, [updateMahData]);

  const archiveManager = (mahId, isArchived, archivedId, archivedDate, userId) => {
    if (mahId !== "") {
      const archivePayload = {
        mah_archived: isArchived ? false : true,
        mah_archived_id: isArchived ? archivedId : userId,
        mah_archived_date: isArchived ? archivedDate : new Date()
      }
      updateMajorAccidentHazard({ variables: {mahId: mahId, userId:userId, mah: archivePayload } });
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Ref',
        accessor: 'client_ref',
      },
      {
      Header: 'MAH Title',
      accessor: 'mah_title',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { _id, mah_archived, mah_archived_id, mah_archived_date, user_id, state, mah_status, userTypeAndRoleObject } }) => {
          return (
            <div className="text-right">

              <Comment sysObjName={sysObjects.majorAccidentHazard}
                sysObjRecordId={_id}
                buttonName={"Comments"}
                buttonClassName={"button-red mb-1 mr-1 text-xs"} />

                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mah/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {mah_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" && userTypeAndRoleObject.userRole !== "Verifier" &&
                    (mah_status === NOTIFICATION_STATUS_APPROVED ||
                      mah_status === NOTIFICATION_STATUS_REJECT ||
                      mah_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mah/edit/${_id}`,
                        state: { formMode: 'edit', archivedStatus: state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>

              {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" && userTypeAndRoleObject.userRole !== "Verifier" &&
                (mah_status === NOTIFICATION_STATUS_APPROVED ||
                  mah_status === NOTIFICATION_STATUS_REJECT ||
                  mah_status === NOTIFICATION_STATUS_REVISION) &&
                <Link
                  to="#"
                  className="button-red mr-1 text-xs"
                  onClick={() => {
                    archiveManager(_id, mah_archived, mah_archived_id, mah_archived_date, user_id)
                  }}
                >
                  {mah_archived ? 'Unarchive' : 'Archive'}
                </Link>
              }

              {mah_status !== NOTIFICATION_STATUS_REJECT && <TableDropDown title="More" items={dropdownListItems({
                dutyHolderId: id,
                assetGroupId: assetGroupId,
                installationId: installationId,
                mahId: _id
              })} />
              }

              {mah_status === NOTIFICATION_STATUS_REJECT && <Link
                to="#"
                className="button-red mr-1 text-xs"
                style={{ textDecorationLine: 'line-through' }}
              >
                More
              </Link>
              }
            </div>
          )
        }
      },
      ],
    []
  )

  if (mahsByInstallationIdLoading) {
    return (
      <PageSpinner/>
    )
  }

  if (mahsByInstallationIdError) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return(
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Major Accident Hazards <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
        <div>
          {showArchivedMahs === false ?
          <div>
            <p className="mt-3">A table of active major accident hazards (MAHs), each entry can be viewed, edited and archived.  Click 'More' for additional options.</p>
            <p>Add a new MAH by clicking 'Add Major Accident Hazard'.</p>
          </div>
          :
          <div>
            <p className="mt-3">A table of archived MAHs, each entry can be viewed and unarchived.  Click 'More' for additional options.</p>
          </div>
          }
        </div>
        }
        
      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <BreadCrumb payload={JSON.stringify({
        dh_id: id,
        ag_id: assetGroupId,
        installation_id: installationId,
        last_crumb: 'Major Accident Hazards'
      })}/>

      <div className="inline">
        <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installations`} className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>

        {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" && userTypeAndRoleObject.userRole !=="Verifier" &&
        <Link 
          to={{
            pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mah/add`,
            state: { formMode:'add', archivedStatus: state}
          }}      
          className="button-red mr-2 mb-2 py-3 px-5"
        >
          Add Major Accident Hazard
        </Link>
        }
      </div>

      <Link to={{
                  pathname: showArchivedMahs ? url : (url + "/archived"),
                  state: showArchivedMahs ? "" : "archived"
               }}
            className="button-red mr-2 mb-2 py-3 px-5"
            onClick={() => {setShowArchivedMahs(!showArchivedMahs)}}
            style={{ backgroundColor: showArchivedMahs ? '#a70000' : '' }}
      >
        {showArchivedMahs?"Active Major Accident Hazards":"Archived Major Accident Hazards"} 
      </Link>

      {mahList.length > 0 &&
        <Link to={`${url}/histories`} className="button-red mb-2 mr-2 py-3 px-5">History</Link>
      }

      {mahList.length !== 0 ?
        <Table columns={columns} data={mahList} />
      : <h4 className="mt-4">There are currently no Major Accident Hazards</h4> }

    </div>
  )
}

function formatMahsData(mahList, showArchivedMahs, userId, state, userTypeAndRoleObject) {
  let newMahList = [];

  if (mahList && mahList.length && userId !== "") {
    let tempMahList = mahList.filter(mah => showArchivedMahs ? mah.mah_archived === true : mah.mah_archived !== true);
    tempMahList.forEach(mah => {
      let formatedStatus = capitaliseFirstCase(mah.mah_status);
      let newRef = mah.mah_client_ref ? mah.mah_client_ref : "N/A"
      let newMah = { ...mah, client_ref: newRef, status: formatedStatus, user_id: userId, state: state, userTypeAndRoleObject: userTypeAndRoleObject };
      newMahList.push(newMah);
    })
  };
  return newMahList;
}

export default MajorAccidentHazards;