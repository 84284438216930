/* eslint-disable react-hooks/exhaustive-deps */

import React, {useContext, useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";
import moment from "moment";
import { NavContext } from '../../context/navContext';

const DOC_SEARCH_QUERY = gql`
    query DocumentSearch($param: DocumentSearchInput!){
      documentSearch(param: $param) {
            _id
            doc_type
            doc_desc
            doc_is_file
            doc_file_name
            doc_added_date
            doc_modified_by {
                user_fname
                user_lname
            }
            doc_path
            doc_path_encoded
            doc_parent_id
            doc_parent_id_encoded
        }
    }
`;

function Documentation() {
  const {parentId = 'Lw=='} = useParams();

  const [documentData, setDocumentData] = useState([])

  const [drDnDutyholder, setDrDnDutyholder] = useState({});
  const [drDnInstallation, setDrDnInstallation] = useState({});
  const [drDnSece, setDrDnSece] = useState({});

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);

  const [documentSearch, {data: docData}] = useLazyQuery(DOC_SEARCH_QUERY);

  useEffect(() => {
    documentSearch({ variables: { param: { doc_parent_id_encoded: parentId } }, errorPolicy: 'all' });
  }, []);

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    console.log("parentId>>>>", parentId);
  }, [parentId])

  useEffect(() => {
    const { dutyHolder, installation, sece } = navContext;
      setDrDnDutyholder(dutyHolder);
      setDrDnInstallation(installation);
      setDrDnSece(sece);
  }, [navContext]);

  useEffect(() => {
    let payload = {
      ...(drDnDutyholder.id && {dh_id : drDnDutyholder.id}),
      doc_parent_id_encoded: parentId 
    }
     documentSearch({ variables: { param: { ...payload} }, errorPolicy: 'all' });
  }, [parentId, drDnDutyholder, drDnInstallation, drDnSece]);

  useEffect(() => {
    console.log('docData====>', docData)
    if (docData && docData.documentSearch) {
      setDocumentData(docData.documentSearch)
    }

  }, [docData])

  const columns = React.useMemo(
    () => [
      {
        Header: <FontAwesomeIcon icon={['far', 'file']} className="text-2xl"/>,
        id: 'doc_is_file',
        accessor: originalRow => originalRow,
        Cell: ({value: {doc_is_file}}) => {

          if (doc_is_file) {
            return <FontAwesomeIcon icon={['far', 'file-word']} className="text-2xl"/>
          }

          return <FontAwesomeIcon icon={['far', 'folder']} className="text-2xl"/>
        }
      },
      {
        Header: 'Name',
        accessor: originalRow => originalRow,
        Cell: ({value: {doc_file_name, doc_path_encoded}}) => (
          <Link to={`/documentation/${doc_path_encoded}`}>
            {doc_file_name}
          </Link>
        )
      },
      {
        Header: 'Last Edited',
        accessor: originalRow => originalRow,
        Cell: ({value: {doc_added_date}}) => (
          <>{moment(doc_added_date).format('DD MMMM YYYY')}</>
        )
      },
      {
        Header: 'Edited By',
        accessor: originalRow => originalRow,
        Cell: ({value}) => (
          <>{formatName(value)}</>
        )
      },
      {
        Header: '',
        id: 'buttons',
        Cell: info => (
          <div className="text-right">

            {/* ONLY SHOW VIEW BUTTON IF FILE */}
            <Link
              to="#"
              className="button-red mb-1 mr-1 text-xs"
            >
              View
            </Link>

          </div>
        )
      },
    ],
    []
  )

  const [showHelp, setShowHelp] = useState(false);

  //TITLE / INFO & MAIN BUTTON SECTION
  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Documentation <FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)}/></h1>

        {showHelp &&
        <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam condimentum a lectus non
          condimentum. Aliquam rhoncus, enim vitae condimentum pharetra, urna magna elementum sapien, non vehicula
          turpis tellus nec velit. Vivamus tristique venenatis neque, eget tristique mauris suscipit aliquet.
          Pellentesque et massa nunc.</p>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>


      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "ABS" &&
        drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Documentation</Link> - Folder Name - Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "Duty Holder" && 
        drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Documentation</Link> - Folder Name - Installation: {drDnInstallation.name}</h6>}
      {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Documentation</Link> - Folder Name - Duty Holder / Installation : All</h6>}

      {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" &&
      <Link 
        to={{
          pathname: '/documentation/file-management',
        }}      
        className="button-red mr-2 mb-2 py-3 px-5"
      >
        File Manager
      </Link>
      }
      <Table columns={columns} data={documentData}/>
    </div>
  )
  //TITLE / INFO & MAIN BUTTON SECTION - END
}

function formatName(value) {
  let name = "N/A";
  if (value && value.doc_modified_by) {
    const { user_fname: userFname = "", user_lname: userLname = "" } = value.doc_modified_by ? value.doc_modified_by : {};
    if (userFname !== "" && userLname !== "") {
      name = `${userFname} ${userLname}`;
    }
  }
  return name;
}

export default Documentation;
