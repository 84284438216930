/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useContext} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {createUserTypeAndRoleObject} from "../../utils/index";
import { Formik } from 'formik';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';

import {AuthContext} from "../../context/authContext";
import { NavContext } from '../../context/navContext';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import {
    SCHEDULE_SYSTEM_VARIABLE_TYPE_ID, 
    SCHEDULE_FORM_ORIGIN_VS_PLANNER
} from '../../constants/';

const localizer = momentLocalizer(moment);

const formats = {

    dayRangeHeaderFormat: ({start, end}) => {
        return (moment(start).format('DD MMMM YYYY') + ' - ' + moment(end).format('DD MMMM YYYY') );
    },

    dayHeaderFormat: ({start}) => {
        return (moment(start).format('DD MMMM YYYY'));
    },

    agendaHeaderFormat: ({start, end}) => {
        return (moment(start).format('DD MMMM YYYY') + ' - ' + moment(end).format('DD MMMM YYYY') );
    }
};

const activityColorCode = [
      { name: "Onshore Verification", color:"#002a4e" },
      { name: "Offshore Verification", color:"#5E8AB4" },
      { name: "AdHoc Visit", color:"#da1f33" },
      { name: "In-Depth Audit", color:"#4CA342" },
      { name: "Meeting", color:"#FFAD0A" }
];

const progressData = [
    { name: "pending", label: "Pending", value: 1 },
    { name: "open", label: "Open", value: 2 },
    { name: "ongoing", label: "Ongoing", value: 3 },
    { name: "completed", label: "Completed", value: 4 },
    { name: "rejected", label: "Rejected", value: 5 }
];

const VERIFICATION_SCHEDULE_SEARCH = gql`
query VerificationScheduleSearch($param: VerificationScheduleSearch!) {
       verificationScheduleSearch(param: $param) {
          _id
          dh_id{
            _id
            dh_name
          }
          installation_ids{
            _id
            installation_title
          }
          vs_title
          vs_start_date_time
          vs_end_date_time
          vs_archived
          schedule_type_id{
              _id
              sysvar_title
          }
          vs_added_date
          vs_status
      }
}`;

const USERS_QUERY = gql`
query {
    users {
        _id
        user_fname
        user_lname
        user_archived
        user_type {
            _id
            user_type_display_name
        }     
        user_role{
            _id
            user_role_code
        }   
    }
}`;

const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
            _id
            sysvar_title
            sysvar_archived
            sysvartype_id{
                _id
                sysvartype_title
            }
        }
}`;

const DUTY_HOLDERS_QUERY = gql`
    query {
        dutyHolders {
            _id
            dh_name
            dh_status
            dh_added_date
            dh_archived
        }
}`;

const INSTALLATIONS_BY_DH_IDS_QUERY = gql`
query installationsByDutyHolderIds($dhIds: [ID]){
    installationsByDutyHolderIds(dhIds: $dhIds) {
      _id
      installation_title
      installation_archived
  }
}`;

function Verification (props) {
    const [HelpHidden, setHelpHidden] = useState(true);
    const [colorCodeList, setColorCodeList] = useState([]);
    const [scheduleList, setScheduleList] = useState([]);
    const [verifierList, setVerifierList] = useState([]);
    const [progressList, setProgressList] = useState([]);
    const [scheduleTypeList, setScheduleTypeList] = useState([]);
    const [dutyHolderList, setDutyHolderList] = useState([]);
    const [installationList, setInstallationList] = useState([]);
    const [selectedDutyHolderIdList, setSelectedDutyHolderIdList] = useState([]);

    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [drDnDutyholder, setDrDnDutyholder] = useState({});
    const [drDnInstallation, setDrDnInstallation] = useState({});
    const [drDnSece, setDrDnSece] = useState({});
  
    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);

    const history = useHistory();

    const [verificationScheduleSearch, {data: vsSearchData}] = useLazyQuery(VERIFICATION_SCHEDULE_SEARCH);
    const [users, {data: usersData}] = useLazyQuery(USERS_QUERY);
    const [systemVariablesBySysVarTypeIds, {data: sysVarsData}] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);
    const [dutyHolders, {data: dutyHoldersData}] = useLazyQuery(DUTY_HOLDERS_QUERY);
    const [installationsByDutyHolderIds, {data: installsData}] = useLazyQuery(INSTALLATIONS_BY_DH_IDS_QUERY);

    const sysVarTypeIds = [SCHEDULE_SYSTEM_VARIABLE_TYPE_ID];

    useEffect(() => {
        setColorCodeList(activityColorCode);
        setProgressList(progressData);
        const payload = {verifierIds: [], progressList: [], scheduleTypeIds: []};
        verificationScheduleSearch({ variables: { param: payload }, errorPolicy: 'all' });
        users({ variables: {}, errorPolicy: 'all' });
        dutyHolders({ variables: {}, errorPolicy: 'all' });
        systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });
    }, []);

    useEffect(() => {
        if (authContext && authContext.user){
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        const { dutyHolder, installation, sece } = navContext;
        setDrDnDutyholder(dutyHolder);
        setDrDnInstallation(installation);
        setDrDnSece(sece);
      }, [navContext]);

    useEffect(() => {
        const payload = {
            dutyholderIds: drDnDutyholder.id ? [drDnDutyholder.id] : [],
            installationIds: drDnInstallation.id ? [drDnInstallation.id] : [],
            seceIds: drDnSece.id ? [drDnSece.id] : [],
        };
        verificationScheduleSearch({ variables: { param: payload }, errorPolicy: 'all' });
    }, [drDnDutyholder, drDnInstallation, drDnSece]);

    useEffect(() => {
        if (vsSearchData && vsSearchData.verificationScheduleSearch) { 
            let tempList = formatScheduleList(vsSearchData.verificationScheduleSearch, colorCodeList);
            setScheduleList(tempList);
        }
    }, [vsSearchData]);

    useEffect(() => {
        if (dutyHoldersData && dutyHoldersData.dutyHolders) {
            let list = dutyHoldersData.dutyHolders;
            let newDutyHolderList = list.filter(dh => dh.dh_status === "approved" && dh.dh_archived !== true);
            let tempList = formatDutyHolderData(newDutyHolderList);
            setDutyHolderList(tempList);
        }
    }, [dutyHoldersData]);

    useEffect(() => {
        if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
            formatSysVarDataAndUpdateStates(sysVarsData.systemVariablesBySysVarTypeIds, 
                                            setScheduleTypeList, 
                                            SCHEDULE_SYSTEM_VARIABLE_TYPE_ID);
        }
    }, [sysVarsData]);

    useEffect(() => {
        if (installsData && installsData.installationsByDutyHolderIds) {
            let tempList = formatInstallationData(installsData.installationsByDutyHolderIds);
            setInstallationList(tempList);
        }
    }, [installsData]);

    useEffect(() => {
        if (usersData && usersData.users) {
            let tempList = formatUserData(usersData.users);
            setVerifierList(tempList);
        }
    }, [usersData]);

    useEffect(() => {
        if (selectedDutyHolderIdList) {
            installationsByDutyHolderIds({ variables: { dhIds: selectedDutyHolderIdList }, errorPolicy: 'all' });
        }
    }, [selectedDutyHolderIdList]);

    const handleDutyHolderChange = (dutyHolders) => {
        if (dutyHolders) {
            let dutyHolderIds = dutyHolders.map(i => i.value);
            setSelectedDutyHolderIdList(dutyHolderIds);
        }else {
            setSelectedDutyHolderIdList([]);
        }
    }

    const onEventClick = (event) => {
        history.push({
            pathname: `/verification/${event.id}/activity-register`
        })
    }

    function toggleModal() {
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }
    
    return(
    <div className="w-full px-8 pb-8">

        <div className="pb-6 border-b-2 border-gray-200">
            <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Verification Planner <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setHelpHidden(!HelpHidden)} /></h1>

            <p className={ HelpHidden ? "hidden" : "mt-3" }>Calendar showing verification schedules relating to the Duty Holder and / or Installation selected on the drop-down menu.</p>
        </div>

        <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

        {(userTypeAndRoleObject &&
          userTypeAndRoleObject.userType === "ABS" && 
          drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Verification - Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
        {(userTypeAndRoleObject &&
          userTypeAndRoleObject.userType === "Duty Holder" && 
          drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Verification - Installation: {drDnInstallation.name}</h6>}
        {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Verification - Duty Holder / Installation : All</h6>}
        
        {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" &&
        <Link 
            to={{
                pathname: `/verification/add/step1`,
                state: { formMode:'add', origin: SCHEDULE_FORM_ORIGIN_VS_PLANNER}
            }}      
            className="button-red mr-2 mb-2 py-3 px-5"
            >
            Add Schedule
        </Link>
        }

        <Link to="#" className="button-red mb-2 py-3 px-5" onClick={() => toggleModal()}>Filter Schedule</Link>

        <div className="py-4">
            <p><FontAwesomeIcon icon={['fas', 'circle']} className="text-blue-900" /> Onshore Verification</p>
            <p><FontAwesomeIcon icon={['fas', 'circle']} className="text-blue-300" /> Offshore Verification</p>
            <p><FontAwesomeIcon icon={['fas', 'circle']} className="text-red-900" /> Adhoc Visit</p>
            <p><FontAwesomeIcon icon={['fas', 'circle']} className="text-green-900" /> In-Depth Audit</p>
            <p><FontAwesomeIcon icon={['fas', 'circle']} className="text-yellow-900" /> Meeting</p>
        </div>

        <div style={{ height: '100vh'}}>
          <Calendar
            events={scheduleList}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={event => onEventClick(event)}
            defaultDate={moment().toDate()}
            localizer={localizer}
            formats={formats}
            eventPropGetter={event => ({
                style: {
                  backgroundColor: event.color,
                },
            })}
          />
        </div>

            {/* VERIFIER FILTER SCHEDULE POP UP */}
            <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

                    <Formik
                        initialValues={{}}
                        onSubmit={(values, {actions}) => {
                    
                            let filteredVerifierIds = formatBackToIdList(values.verifier_ids);
                            let progressList = formatBackToProgressList(values.progress);
                            let filteredScheduleTypeIds = formatBackToIdList(values.schedule_type);
                            let filteredDutyholderIds = formatBackToIdList(values.duty_holder);
                            let filteredInstallationIds = formatBackToIdList(values.installation);
                            const payload = {
                                verifierIds: filteredVerifierIds,
                                progressList: progressList,
                                scheduleTypeIds : filteredScheduleTypeIds,
                                dutyholderIds : filteredDutyholderIds,
                                installationIds : filteredInstallationIds,
                            };
                            verificationScheduleSearch({ variables: {param: payload}, errorPolicy: 'all' }); 

                            toggleModal();
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <form onSubmit={handleSubmit}>

                                <div className="mb-4 pb-2 border-b-2 border-gray-200">
                                    <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">Filter By Verifiers and Progress</h2>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="verifier_ids" className="block mb-1 text-blue-900 font-semibold">Verifiers</label>
                                    <Select
                                        id="verifier_ids"
                                        name="verifier_ids"
                                        aria-label="verifiers"
                                        className="w-full text-gray-900"
                                        classNamePrefix="react-select"
                                        options={verifierList}
                                        isMulti
                                        isSearchable
                                        components={makeAnimated()}
                                        closeMenuOnSelect={false}
                                        placeholder="Select Verifiers"
                                        onChange={verifiers => setFieldValue('verifier_ids', verifiers)}
                                        onBlur={verifiers => setFieldTouched('verifier_ids', verifiers)}
                                        value={values.verifier_ids}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="progress" className="block mb-1 text-blue-900 font-semibold">Progress</label>
                                    <Select
                                        id="progress"
                                        name="progress"
                                        aria-label="progress"
                                        className="w-full text-gray-900"
                                        classNamePrefix="react-select"
                                        options={progressList}
                                        isMulti
                                        isSearchable
                                        components={makeAnimated()}
                                        closeMenuOnSelect={false}
                                        placeholder="Select Progress"
                                        onChange={progress => setFieldValue('progress', progress)}
                                        onBlur={progress => setFieldTouched('progress', progress)}
                                        value={values.progress}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="schedule_type" className="block mb-1 text-blue-900 font-semibold">Schedule Type</label>
                                    <Select
                                        id="schedule_type"
                                        name="schedule_type"
                                        aria-label="schedule type"
                                        className="w-full text-gray-900"
                                        classNamePrefix="react-select"
                                        options={scheduleTypeList}
                                        isMulti
                                        isSearchable
                                        components={makeAnimated()}
                                        closeMenuOnSelect={false}
                                        placeholder="Select Schedule Type"
                                        onChange={schedule_type => setFieldValue('schedule_type', schedule_type)}
                                        onBlur={schedule_type => setFieldTouched('schedule_type', schedule_type)}
                                        value={values.schedule_type}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="duty_holder" className="block mb-1 text-blue-900 font-semibold">Duty Holder</label>
                                    <Select
                                        id="duty_holder"
                                        name="duty_holder"
                                        aria-label="dutyholder"
                                        className="w-full text-gray-900"
                                        classNamePrefix="react-select"
                                        options={dutyHolderList}
                                        isMulti
                                        isSearchable
                                        components={makeAnimated()}
                                        closeMenuOnSelect={false}
                                        placeholder="Select Duty Holder"
                                        onChange={duty_holder => {
                                            setFieldValue('duty_holder', duty_holder);
                                            handleDutyHolderChange(duty_holder);
                                            setFieldValue('installation', []);
                                        }}
                                        onBlur={duty_holder => setFieldTouched('duty_holder', duty_holder)}
                                        value={values.duty_holder}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="installation" className="block mb-1 text-blue-900 font-semibold">Installation</label>
                                    <Select
                                        id="installation"
                                        name="installation"
                                        aria-label="installation"
                                        className="w-full text-gray-900"
                                        classNamePrefix="react-select"
                                        options={installationList}
                                        isMulti
                                        isSearchable
                                        components={makeAnimated()}
                                        closeMenuOnSelect={false}
                                        placeholder="Select Installation"
                                        onChange={installation => setFieldValue('installation', installation)}
                                        onBlur={installation => setFieldTouched('installation', installation)}
                                        value={values.installation}
                                    />
                                </div>

                                <button type="submit" className="mt-3 mb-4 button-red capitalize">Apply Filter</button>

                            </form>
                        )}
                    </Formik>

                </div>
            </div>
            {/* VERIFIER FILTER POP UP END */}
    </div>
    )
}

function formatScheduleList(scheduleList, colorCodeList) {
    let formatedList = [];
    if (scheduleList && scheduleList.length && colorCodeList && colorCodeList.length) {
        let newVsList = scheduleList.filter(schedule => schedule.vs_archived !== true);
        newVsList.forEach(vs => {
            let dutyHolder = vs.dh_id ? vs.dh_id.dh_name : "";
            let installations = vs.installation_ids ? vs.installation_ids.map(install => install.installation_title) : "";
            let title = vs.schedule_type_id ? vs.schedule_type_id.sysvar_title : "";
            let formatedTitle = generateCalenderTitle(title, dutyHolder, installations);
            let colorCode = colorCodeList.find(c => c.name === title);
            let newVs = {
                id: vs._id,
                title: formatedTitle,
                start: new Date(vs.vs_start_date_time),
                end: new Date(vs.vs_end_date_time),
                color: colorCode ? colorCode.color : "#c0c5ce",
            }
            formatedList.push(newVs);
        });
    }
    return formatedList;
}

function generateCalenderTitle(title, dutyHolder, installations) {
    let tempTitle = "";
    if (title && dutyHolder && installations) {
        tempTitle += title;
        tempTitle += " - ";
        tempTitle += dutyHolder;
        if (installations.length) {
            tempTitle += " - ";
        }
        installations.forEach((install, index) => {
            tempTitle += install;
            if (index < (installations.length - 1)) {
                tempTitle += ", ";
            }
        })
    }
    return tempTitle;
}

function formatUserData(userList) {
    let newUserList = [];
    if (userList && userList.length) {
        let tempList = userList.filter(user => user.user_type && user.user_type.user_type_display_name === "ABS" && user.user_archived !== true)
        tempList.forEach(user => {
            let newUser = {
                value: user._id,
                label: user.user_fname + " " + user.user_lname,
            }
            newUserList.push(newUser);
        })
    }
    return newUserList;
}

 function formatBackToIdList(items) {
    let formatedList = [];
    if (items && items.length) {
        items.forEach(item => {
            let id = item.value;
            formatedList.push(id);
        });
    }
    return formatedList;
}

function formatBackToProgressList(items) {
    let formatedList = [];
    if (items && items.length) {
        items.forEach(item => {
            let name = item.name;
            formatedList.push(name);
        });
    }
    return formatedList;
}

function formatSysVarDataAndUpdateStates(list, setScheduleTypeList, scheduleTypeId) {
    let scheduleTypes = []
    scheduleTypes = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === scheduleTypeId);
    let tempList = formatScheduleTypeData(scheduleTypes);
    setScheduleTypeList(tempList);
}

function formatInstallationData(installations) {
    let formatedList = [];
    if (installations && installations.length) {
        let newInstallationList = installations.filter(installation => installation.installation_archived !== true);
        newInstallationList.forEach(installation => {
            let newInstallation = {
                value : installation._id,
                label: installation.installation_title
            }
            formatedList.push(newInstallation);
        });
    }

    return formatedList;
}

function formatDutyHolderData(dutyHolders) {
    let formatedList = [];
    if (dutyHolders && dutyHolders.length) {
        let newDutyHolderList = dutyHolders.filter(dutyHolder => dutyHolder.dh_archived !== true);
        newDutyHolderList.forEach(dutyHolder => {
            let newDutyHolder = {
                value: dutyHolder._id,
                label: dutyHolder.dh_name
            }
            formatedList.push(newDutyHolder);
        });
    }
    return formatedList;
}

function formatScheduleTypeData(scheduleTypes) {
    let formatedList = [];
    if (scheduleTypes && scheduleTypes.length) {
        let newScheduleTypeList = scheduleTypes.filter(scheduleType => scheduleType.sysvar_archived !== true);
        newScheduleTypeList.forEach(scheduleType => {
            let newScheduleType = {
                value: scheduleType._id,
                label: scheduleType.sysvar_title
            }
            formatedList.push(newScheduleType);
        });
    }
    return formatedList;
}

export default Verification;