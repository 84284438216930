/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Table from '../global/Table';
import {gql, useMutation, useLazyQuery} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from '../global/pageSpinner';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";

const COMPETENCYCATEGORY_QUERY = gql`
query CompetencyCategories($isArchived:Boolean!){
    competencyCategories(isArchived: $isArchived){
        _id
        comp_cat_code
        comp_cat_title
        comp_cat_notes
        comp_cat_archived
        comp_cat_archived_id
        comp_cat_archived_date
    }
}`;

const UPDATE_COMPETENCYCATEGORY = gql`
mutation UpdateCompetencyCategory($compCatId: String!, $userId: String, $compCat: UpdateCompetencyCategoryInput!) {
  updateCompetencyCategory(_id: $compCatId, userId: $userId, compCat: $compCat) {
         _id
    }
}`;

function CompetencyCats(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [categories, setCategories] = useState([]);
  const [showArchivedCategories, setShowArchivedCategories] = useState(false);

  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const { location: { state = "" }, match: { url = "" } } = props ? props : {};

  const [competencyCategories, {loading, error, data:compCatData, refetch}] = useLazyQuery(COMPETENCYCATEGORY_QUERY);
  const [updateCompetencyCategory, {data:updateCompCatData}] = useMutation(UPDATE_COMPETENCYCATEGORY);

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
  }, [userTypeAndRoleObject]);


  useEffect(() => {
    if (state === "archived"){
      setShowArchivedCategories(true);
    }
  }, []);

  useEffect(() => {
     competencyCategories({variables: {isArchived:showArchivedCategories}, errorPolicy: 'all'});
  }, [showArchivedCategories]);

  useEffect(() => {
    if (compCatData && compCatData.competencyCategories) {
      const { _id: userId = "" } = authContext.user ? authContext.user : {};
      let formatedCompCatList = formatCompCatsData(compCatData.competencyCategories, userId, state);
      setCategories(formatedCompCatList)
    }
  }, [compCatData]);

  useEffect(() => {
    if (updateCompCatData && updateCompCatData.updateCompetencyCategory) {
      refetch();
    }
  }, [updateCompCatData]);

  const archiveManager = (compCatId, isArchived, archivedId, archivedDate, userId) => {
    if (compCatId !== "") {
      const payload = {
        comp_cat_archived: isArchived ? false : true,
        comp_cat_archived_id: isArchived ? archivedId : userId,
        comp_cat_archived_date: isArchived ? archivedDate : new Date()
      }
      updateCompetencyCategory({ variables: { compCatId: compCatId, userId: userId, compCat: payload } });
    }
  }

  const columns = React.useMemo(() => [
      {
        Header: 'Code',
        accessor: 'comp_cat_code',
      },
      {
        Header: 'Name',
        accessor: 'comp_cat_title',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({value:{_id, comp_cat_archived, comp_cat_archived_id, comp_cat_archived_date, user_id, state}}) => (
          <div className="text-right">
            <Link
              to={{
                pathname: `/user-competency/competencies/competency-cat/view/${_id}`,
                state: {archivedStatus: state}
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              View
            </Link>
            {comp_cat_archived ? "" : <Link
              to={{
                pathname: `/user-competency/competencies/competency-cat/edit/${_id}`,
                state: { formMode:'edit', archivedStatus: state}
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              Edit
            </Link>}
            <Link
              to="#"
              className="button-red mr-1 text-xs"
              type="button"
              onClick={() => {archiveManager(_id, comp_cat_archived, comp_cat_archived_id, comp_cat_archived_date, user_id)}}
            >
              {comp_cat_archived? 'Unarchive':'Archive'}
            </Link>          
          </div>
        )
      },
      ],
    []
  )
  

  if (loading) {
    return (
      <PageSpinner/>
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return(
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Competency Categories <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
        <div>
          {showArchivedCategories === false ?
          <div>
            <p className="mt-3">A table of the active competency categories, each entry can be viewed, edited and archived.</p>
            <p>Competency categories can be added, and past changes viewed by clicking 'Add' and 'History'.</p>
          </div>
          :
            <p className="mt-3">A table of the archived competency categories, each entry can be viewed and unarchived.</p>
          }
        </div>
        }
        
      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <h6 className="mb-6 font-bold text-sm text-red-900">Dashboard - User Competency - Competencies - Competency Categories</h6>

      <Link to={{pathname: '/user-competency/competencies'}} className="button-red mr-2 mb-2 py-3 px-5">
        <FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back
      </Link>

      <Link 
        to={{
          pathname: '/user-competency/competencies/competency-cat/add',
          state: { formMode:'add', archivedStatus: state}
        }}      
        className="button-red mr-2 mb-2 py-3 px-5"
      >
        Add
      </Link>

      <Link to={{
        pathname: showArchivedCategories ? url : (url + "/archived"),
        state: showArchivedCategories ? "" : "archived"
      }}
      className="button-red mr-2 mb-2 py-3 px-5" 
              onClick={()=>{setShowArchivedCategories(!showArchivedCategories)}}
            >{showArchivedCategories?"Active Categories":"Archived Categories"}</Link>

      <Link to="/user-competency/competencies/competency-cats/histories" className="button-red mr-2 mb-2 py-3 px-5">History</Link>

      <Table 
        columns={columns} 
        data={categories} 
      />

    </div>
  )

}

function formatCompCatsData(compCatList, userId, state) {
  let newCompCatList= [];
  if (compCatList && compCatList.length && userId !== "") {
    compCatList.forEach(compCat => {
      let newCompCat = { ...compCat, user_id: userId, state: state };
      newCompCatList.push(newCompCat);
    })
  };

  return newCompCatList;
}

export default CompetencyCats;