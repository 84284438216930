/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Table from '../global/Table';
import TableDropDown from '../global/TableDropDown2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import PageSpinner from "../global/pageSpinner";
import { AuthContext } from "../../context/authContext";
import { capitaliseFirstCase, createUserTypeAndRoleObject } from "../../utils/index";
import BreadCrumb from "../global/Breadcrumb";
import Comment from '../global/Comment';
import { NavContext } from '../../context/navContext';

import {
  NOTIFICATION_STATUS_APPROVED,
  NOTIFICATION_STATUS_REJECT,
  NOTIFICATION_STATUS_REVISION,
  NOTIFICATION_STATUS_PENDING
} from "../../constants";
import { sysObjects } from "../../constants/sysObjectList";

const dropdownListItems = ({ dutyHolderId, assetGroupId }) => ([
  {
    id: 1,
    name: 'Installations',
    link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installations`
  },
  {
    id: 2,
    name: 'Reference Activities',
    link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/reference-activities`
  },
]);

const UPDATE_ASSETGROUP = gql`
    mutation UpdateAssetGroup($assetGroupId: String!, $userId: String, $ag: UpdateAssetGroupInput!){
        updateAssetGroup(_id:$assetGroupId, userId:$userId, ag:$ag){
            _id
        }
    }`;

const DUTYHOLDER_QUERY = gql`
    query DutyHolder($id: ID!){
        dutyHolder(_id: $id) {
            _id
            dh_name
        }
    }`;

const ASSETGROUP_SEARCH = gql`
query AssetGroupSearch ($searchParam: AssetGroupSearchInput!){
  assetGroupSearch (searchParam: $searchParam){
      _id
      ag_ref
      ag_title
      ag_archived
      ag_archived_id
      ag_archived_date
      dh_id {
          dh_name
      }
      ag_status   
    }
}`;

function AssetGroups(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [dutyHolderName, setDutyHolderName] = useState("");
  const [assetGroupList, setAssetGroupList] = useState([]);
  const [showArchivedAssetGroups, setShowArchivedAssetGroups] = useState(false);

  const [emptyListMessage, setEmptyListMessage] = useState("");

  const [drDnDutyholder, setDrDnDutyholder] = useState({});
  const [drDnInstallation, setDrDnInstallation] = useState({});
  const [drDnSece, setDrDnSece] = useState({});

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const history = useHistory();

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const { id = "" } = useParams();
  const { location: { state = "" }, match: { url = "" } } = props ? props : {};

  const [dutyHolder, { data: dutyHolderData }] = useLazyQuery(DUTYHOLDER_QUERY);
  const [assetGroupSearch, { loading, error, data: assetGroupListSearchData, refetch }] = useLazyQuery(ASSETGROUP_SEARCH);
  const [updateAssetGroup, { data: assetGroupData }] = useMutation(UPDATE_ASSETGROUP);

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (id !== "") {
      dutyHolder({ variables: { id }, errorPolicy: 'all' });
    };
    if (state === "archived") {
      setShowArchivedAssetGroups(true);
    };
  }, []);

  useEffect(() => {
    const { dutyHolder: tempDutyHolder, installation, sece } = navContext;
    const { id: dhId} = tempDutyHolder ?? {};

    if (dhId && dhId !== "" && dhId !== id) {
      history.push(`/dutyholder/${dhId}/asset-groups`);
    }

    setDrDnDutyholder(tempDutyHolder);
    setDrDnInstallation(installation);
    setDrDnSece(sece);
  }, [navContext]);

  useEffect(() => {
    setAssetGroupList([]);

    let dutyHolder = "";
    if (id !== "") {
      dutyHolder = id;
    }

    const payload = {
      dh_id: drDnDutyholder.id ? drDnDutyholder.id : dutyHolder,
      installation_id: drDnInstallation.id ? drDnInstallation.id : "",
      archived: showArchivedAssetGroups
    };

    assetGroupSearch({ variables: { searchParam: { ...payload } }, errorPolicy: 'all' });
  }, [drDnDutyholder, drDnInstallation, drDnSece, showArchivedAssetGroups]);

  useEffect(() => {
    if (dutyHolderData && dutyHolderData.dutyHolder) {
      const { dh_name = "N/A" } = dutyHolderData.dutyHolder ? dutyHolderData.dutyHolder : {};
      setDutyHolderName(dh_name);
    }
  }, [dutyHolderData]);

  useEffect(() => {
    if (assetGroupListSearchData && assetGroupListSearchData.assetGroupSearch) {
      let agList = assetGroupListSearchData.assetGroupSearch;
      const { _id: userId = "" } = authContext.user ? authContext.user : {};
      let tempAssetGroupList = formatAssetGroupsData(agList, showArchivedAssetGroups, userId, state, userTypeAndRoleObject);
      setAssetGroupList(tempAssetGroupList);
      agList.length ? setEmptyListMessage("") : setEmptyListMessage("There are currently no Asset Groups");
    }
  }, [assetGroupListSearchData]);

  useEffect(() => {
    if (assetGroupData && assetGroupData.updateAssetGroup) {
      refetch();
    }
  }, [assetGroupData]);

  const archiveManager = (assetGroupId, isArchived, archivedId, archivedDate, userId) => {
    if (assetGroupId !== "" && userId !== "") {
      const payload = {
        ag_archived: isArchived ? false : true,
        ag_archived_id: isArchived ? archivedId : userId,
        ag_archived_date: isArchived ? archivedDate : new Date()
      }
      updateAssetGroup({ variables: { assetGroupId: assetGroupId, userId: userId, ag: payload } });
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Asset Group',
        accessor: 'ag_title',
      },
      {
        Header: 'Duty Holder',
        accessor: 'dh_id.dh_name',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { _id, ag_archived, ag_archived_id, ag_archived_date, user_id, state, ag_status, userTypeAndRoleObject } }) => {
          return (
            <div className="text-right">
              <Comment sysObjName={sysObjects.assetGroup}
                sysObjRecordId={_id}
                buttonName={"Comments"}
                buttonClassName={"button-red mb-1 mr-1 text-xs"} />

              <Link
                to={{
                  pathname: `/dutyholder/${id}/asset-group/view/${_id}`,
                  state: { archivedStatus: state }
                }}
                className="button-red mb-1 mr-1 text-xs"
              >
                View
              </Link>

              {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                (
                  ag_status === NOTIFICATION_STATUS_APPROVED ||
                  ag_status === NOTIFICATION_STATUS_REJECT ||
                  ag_status === NOTIFICATION_STATUS_REVISION
                ) &&
                <div className="inline">
                  {ag_archived ? "" : <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/edit/${_id}`,
                      state: { formMode: 'edit', archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    Edit
                  </Link>}
                  <Link
                    to="#"
                    className="button-red mb-1 mr-1 text-xs"
                    onClick={() => {
                      archiveManager(_id, ag_archived, ag_archived_id, ag_archived_date, user_id)
                    }}
                  >
                    {ag_archived ? 'Unarchive' : 'Archive'}
                  </Link>
                </div>
              }

              {(ag_status !== NOTIFICATION_STATUS_REJECT &&
                ag_status !== NOTIFICATION_STATUS_REVISION &&
                ag_status !== NOTIFICATION_STATUS_PENDING) &&
                <TableDropDown title="More" items={dropdownListItems({ dutyHolderId: id, assetGroupId: _id })} />
              }

            </div>
          )
        }
      },
    ],
    []
  )

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

      {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">{dutyHolderName} Asset
          Groups <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
            onClick={() => setShowHelp(!showHelp)} /></h1>
      }

      {userTypeAndRoleObject && userTypeAndRoleObject.userType === "Duty Holder" &&
        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Duty Holder Hierarchy <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
            onClick={() => setShowHelp(!showHelp)} /></h1>
      }

        {showHelp &&
          <div>
            {showArchivedAssetGroups === false ?
              <div>
                <p className="mt-3">A table of active Asset Groups, each entry can be viewed, edited and archived.  Click 'More' for additional options.</p>
                <p>Add a new Asset Group by clicking 'Add Asset Group'.</p>
              </div>
              :
              <div>
                <p className="mt-3">A table of archived Asset Groups, each entry can be viewed and unarchived.</p>
                <p>Click 'More' for additional options.</p>
              </div>
            }
          </div>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <BreadCrumb payload={JSON.stringify({
        dh_id: id,
        last_crumb: 'Asset Groups'
      })} />


      {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
        <>
          <Link to="/dutyholders" className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
            className="text-white" /> Back</Link>
          <Link
            to={{
              pathname: `/dutyholder/${id}/asset-group/add`,
              state: { formMode: 'add', archivedStatus: state }
            }}
            className="button-red mr-2 mb-2 py-3 px-5"
          >
            Add Asset Group
          </Link>
        </>
      }

      <Link to={{
        pathname: showArchivedAssetGroups ? url : (url + "/archived"),
        state: showArchivedAssetGroups ? "" : "archived"
      }}
        className="button-red mr-2 mb-2 py-3 px-5"
        onClick={() => {
          setShowArchivedAssetGroups(!showArchivedAssetGroups)
        }}
      >{showArchivedAssetGroups ? "Active Asset Groups" : "Archived Asset Groups"}</Link>

      {assetGroupList.length > 0 &&
        <Link to={`/dutyholder/${id}/asset-groups/histories`} className="button-red mb-2 mr-2 py-3 px-5">History</Link>
      }

      {assetGroupList.length ?
        <Table columns={columns} data={assetGroupList} />
        : <h4 className="mt-4">{emptyListMessage}</h4>}

    </div>
  )
}

function formatAssetGroupsData(assetGroupList, showArchivedAssetGroups, userId, state, userTypeAndRoleObject) {
  let newAssetGroupList = [];

  if (assetGroupList && assetGroupList.length && userId !== "") {
    let tempAssetGroupList = assetGroupList.filter(asstGrp => showArchivedAssetGroups ? asstGrp.ag_archived === true : asstGrp.ag_archived !== true);
    tempAssetGroupList.forEach(asstGrp => {
      let formatedStatus = capitaliseFirstCase(asstGrp.ag_status);
      let newAssetGroup = { ...asstGrp, status: formatedStatus, user_id: userId, state: state, userTypeAndRoleObject: userTypeAndRoleObject };
      newAssetGroupList.push(newAssetGroup);
    })
  };

  return newAssetGroupList;
}

export default AssetGroups;