/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Table from '../global/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery } from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import { useQueryString, formatDateToLocaleDateString, capitaliseFirstCase } from '../../utils';

const SECE_HISTORIES_QUERY = gql`
query SeceHistory($dhId: String!, $filter: String){
    sece_histories_by_duty_holder_id(dhId: $dhId, filter: $filter) {
        _id
        dh_id {
            _id
        }
        ag_id {
            _id
        }
        sece_title
        sece_status
        history_parent_record_id
        history_modifying_user_id {
            _id
            user_fname
            user_lname
            user_email
        }
        history_modified_date
        history_modified_date_timestamp
    }
}
`;

function SeceHistories() {
  const [showHelp, setShowHelp] = useState(false);
  const [seceHistories, setSeceHistories] = useState([]);

  const { id: dhId, assetGroupId, installationId, barrierId } = useParams();
  const queryString = useQueryString();
  const filters = queryString.get('filters');

  const [sece_histories_by_duty_holder_id, { loading, error, data }] = useLazyQuery(SECE_HISTORIES_QUERY);

  useEffect(() => {
    sece_histories_by_duty_holder_id({ variables: { ...(dhId && dhId !== "" && { dhId: dhId }), ...(filters && { filter: filters }) }, errorPolicy: 'all' });
  }, [])

  useEffect(() => {
    if (data && data.sece_histories_by_duty_holder_id) {
      let hisList = data.sece_histories_by_duty_holder_id;
      let formatedHisList = formatSeceHistoryData(hisList);
      let reversedHistoryList = formatedHisList.reverse();
      return setSeceHistories(reversedHistoryList);
    }
  }, [data])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Sece Name',
        accessor: 'sece_title',
      },
      {
        Header: 'Modified Date',
        accessor: "history_modified_date"
      },
      {
        Header: 'Modifying User',
        accessor: "modifying_user"
      },
      {
        Header: 'Status',
        accessor: "status",
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: sece => (
          <div className="text-right">
            <Link
              to={{
                pathname: `/dutyholder/${sece.value.dhId}/asset-group/${assetGroupId}/installation/${installationId}/seces/view-diff/${sece.value.id}/${sece.value.parentId}`,
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              View Change
            </Link>
          </div>
        )
      },
    ],
    [assetGroupId, installationId]
  )

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 mb-6 border-b-2 border-gray-200">

        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Sece History<FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3">A table detailing changes to SECEs.</p>
        }

      </div>

      {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined &&
          <Link to={`/dutyholder/${dhId}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/seces`} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        }
        {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined &&
          <Link to={`/dutyholder/${dhId}/asset-group/${assetGroupId}/installation/${installationId}/seces`} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        }


      <Table columns={columns} data={seceHistories} />

    </div>
  )
}

function formatSeceHistoryData(histories) {
  let historyList = []

  if (histories && histories.length) {

    histories.forEach(his => {

      const { _id, sece_title, sece_status, dh_id, history_modified_date, history_modifying_user_id, history_parent_record_id } = his ? his : {};
      const { _id: dhId = "" } = dh_id ? dh_id : {};
      const { user_fname = "", user_lname = "" } = history_modifying_user_id ? history_modifying_user_id : {};
      let userFullName = (user_fname !== "" && user_lname !== "") ? `${user_fname} ${user_lname}` : "N/A";

      let newHistory = {
        id: _id,
        parentId: history_parent_record_id,
        dhId: dhId,
        sece_title: sece_title && sece_title !== "" ? sece_title : "N/A",
        history_modified_date: history_modified_date && history_modified_date !== "" ? formatDateToLocaleDateString(history_modified_date) : "N/A",
        modifying_user: userFullName,
        status: sece_status && sece_status !== "" ? capitaliseFirstCase(sece_status) : "N/A"
      }

      historyList.push(newHistory);
    });
  }

  return historyList;
}

export default SeceHistories;