import React,  {createContext, useState} from 'react';

const NavContext = createContext();

function NavContextProvider({children}) {
    const [dutyHolder, setDutyHolder] = useState({});
    const [installation, setInstallation] = useState({});
    const [sece, setSece] = useState({});

    const updateDutyHolder = (dutyHolder) => {
        if (dutyHolder) {
            setDutyHolder(dutyHolder);
        }
    }

    const updateInstallation = (installation) => {
        if (installation) {
            setInstallation(installation);
        }
    }

    const updateSece = (sece) => {
        if (sece) {
            setSece(sece);
        }
    }

    const clearContext = () => {
        setDutyHolder({});
        setInstallation({});
        setSece({});
    }
  
    return (
        <NavContext.Provider value = {{
            dutyHolder:dutyHolder, 
            installation:installation, 
            sece:sece, 
            updateDutyHolder:updateDutyHolder, 
            updateInstallation:updateInstallation, 
            updateSece:updateSece,
            clearContext:clearContext
        }}>
              {children}
        </NavContext.Provider>
    );
}

export {NavContext, NavContextProvider};