/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useContext} from 'react';
import {
  useTable,
  useFilters,
  useSortBy,
  useGlobalFilter,
  usePagination
} from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {PageContext} from "../../context/pageContext";

//FOR GLOBAL TABLE SEARCH
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <div className="w-full">
      <label htmlFor="table-filter-search" className="sr-only">Search</label>
      <input
        value={globalFilter || ""}
        onChange={e => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
        className="form-control w-full sm:w-48"
        id="table-filter-search"
      />
    </div>
  );
};
  
const filterTypes = {
  text: (rows, id, filterValue) => {
    return rows.filter(row => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue)
          .toLowerCase()
          .startsWith(String(filterValue).toLowerCase())
        : true;
    });
  }
};

//FOR GLOBAL TABLE SEARCH END

// Create a default prop getter
const defaultPropGetter = () => ({})

//const defaultColumn = {
//  Cell: ({ value }) => value || 'N/A',
//};
  
function Table({ columns, data, pagination, filter, currentPage, pageName, parentId, getRowProps = defaultPropGetter }) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      //defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, pageSize : 50 },
    },
    // hook for filters & search
    useFilters,
    useGlobalFilter,
    // hook for sorting
    useSortBy,
    usePagination,
  )

  const pageContext = useContext(PageContext);

  useEffect(() => {
    if (currentPage) {
      gotoPage(currentPage - 1);
    }
  }, [currentPage])

  useEffect(() => {
    if (pageIndex) {
      let page = pageIndex + 1;
      setContextData(pageContext, page, pageName, parentId);
    }
  }, [pageIndex])

  useEffect(() => {
    if (!canPreviousPage) {
      let page = 0;
      setContextData(pageContext, page, pageName, parentId);
    }
  }, [canPreviousPage])

  useEffect(() => {
    if (!canNextPage) {
      let page = pageIndex + 1;
      setContextData(pageContext, page, pageName, parentId);
    }
  }, [canNextPage])

  const handlePageMin = () => {
    let page = 0;
    setContextData(pageContext, page, pageName, parentId);
  }

  const handlePageMax = () => {
    let page = pageIndex - 1;
    setContextData(pageContext, page, pageName, parentId);
  }

  return (
    <div className="mt-5">

      {filter !== false &&
        <div className="flex flex-col sm:flex-row">

          <div className="w-full sm:w-1/2">
            <label htmlFor="table-show-page-amount" className="sr-only">Show</label>
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
              className="form-control w-32 mb-2 sm:mb-0"
              id="table-show-page-amount"
            >
              {[50, 100, 150, 200, 250].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <span className="form-control w-35 ml-2 mb-0 sm:mb-0">
              {`Total: ${data.length}`}
            </span>
          </div>   

          <div className="w-full sm:w-1/2 sm:text-right">
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>

        </div>
      }

      <div className="block w-full overflow-x-auto">
        <table {...getTableProps()} className="w-full mt-3 table-auto register">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index, arr) => (
                  column.id === "buttons" ?
                  <td {...column.getHeaderProps(index !== arr.length - 1 && column.getSortByToggleProps())} className="px-4 py-3 bg-gray-200 font-semibold text-blue-900 text-left">
                    {column.render('Header')}
                    <span>
                      {index !== arr.length - 1 ? column.isSorted
                        ? column.isSortedDesc
                          ? <FontAwesomeIcon icon={['fas', 'caret-down']} className="ml-2 text-gray-500" />
                          : <FontAwesomeIcon icon={['fas', 'caret-up']} className="ml-2 text-gray-500" />
                        : column.canSort ? <FontAwesomeIcon icon={['fas', 'sort']} className="ml-2 text-gray-500" /> : ''
                        : null}
                    </span>
                    <div>{column.defaultCanFilter ? column.render('Filter') : null}</div>
                  </td>
                  :
                  <th {...column.getHeaderProps(index !== arr.length - 1 && column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {index !== arr.length - 1 ? column.isSorted
                        ? column.isSortedDesc
                          ? <FontAwesomeIcon icon={['fas', 'caret-down']} className="ml-2 text-gray-500" />
                          : <FontAwesomeIcon icon={['fas', 'caret-up']} className="ml-2 text-gray-500" />
                        : column.canSort ? <FontAwesomeIcon icon={['fas', 'sort']} className="ml-2 text-gray-500" /> : ''
                        : null}
                    </span>
                    <div>{column.defaultCanFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps([{
                      className: cell.column.className,
                      style: cell.column.style
                    }])}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {pagination !== false &&
        <div className="flex flex-col sm:flex-row mt-4">
          <div className="w-full sm:w-1/2 mb-3 sm:mb-0">
            <button type='button' onClick={() => {handlePageMin(); gotoPage(0)}} disabled={!canPreviousPage} title="Go to Start" className="button-pag">
              <FontAwesomeIcon icon={['fas', 'angle-double-left']} className="text-blue-900" />
            </button>{' '}
            <button type='button' onClick={() => previousPage()} disabled={!canPreviousPage} title="Previous" className="button-pag">
              <FontAwesomeIcon icon={['fas', 'angle-left']} className="text-blue-900" />
            </button>{' '}
            <button type='button' onClick={() => nextPage()} disabled={!canNextPage} title="Next" className="button-pag">
              <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-blue-900" />
            </button>{' '}
            <button type='button' onClick={() => {gotoPage(pageCount - 1); handlePageMax()}} disabled={!canNextPage} title="Go to End" className="button-pag">
              <FontAwesomeIcon icon={['fas', 'angle-double-right']} className="text-blue-900" />
            </button>{' '}
            <span className="text-blue-900">
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
          </div>

          <div className="w-full sm:w-1/2 sm:text-right">
            <label htmlFor="table-show-page-goto" className="sr-only">Go to</label>
            <span className="text-blue-900">
              Go to{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                className="form-control w-20"
                id="table-show-page-goto"
              />
            </span>{' '}
          </div>

        </div>
      }

    </div>
  )
}

function setContextData(pageContext, page, pageName, parentId) {
  if (pageContext && page >= 0 && pageName) {
    switch (pageName) {
      case "ActionList":
        let actionList = { page: page, name: "ActionList" }
        pageContext.updateActionListPage(actionList);
        break;
      case "ActivityRegister":
        let activityRegister = { page: page, name: "ActivityRegister", parentId: parentId }
        pageContext.updateActivityRegisterPage(activityRegister);
        break;
      default:
        break;
    }
  }
  return true;
}

export default Table;