import React, {Component} from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import Table from '../global/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ComparisonEngine extends Component {

    constructor(props) {
        super(props);
        this.state = {
          HelpActive: true,
          //comparison engine 1st col
          CE1InstallationId: '',
          CE1SeceId: '',
          CE1Type: '',
          CE1OpenFindingsPieData:{
            labels: ['In Date', 'Overdue'],
            datasets:[{
              label: 1,
              data:[
                97, 12
              ],
              backgroundColor:[
                '#58b23d',
                '#b40c1e',
              ]
            }],
          },
          CE1FindingsBarrierBar:{
            labels:['Barrier 1', 'Barrier 2', 'Barrier 3'],
            datasets: [
                {
                  label: 'In Date',
                  data: [60, 44, 30],
                  backgroundColor:'#58b23d'
                },
                {
                  label: 'Expiring Soon',
                  data: [50, 29, 37],
                  backgroundColor:'#f47e43' 
                },
                {
                  label: 'Overdue',
                  data: [12, 0, 7],
                  backgroundColor:'#b40c1e'  
                }
              ],
          },
          CE1FindingsActionParty:{
            labels:['Action Party 1', 'Action Party 2', 'Action Party 3', 'Action Party 4'],
            datasets: [
                {
                  label: 'In Date',
                  data: [33, 44, 30, 20],
                  backgroundColor:'#58b23d'
                },
                {
                  label: 'Expiring Soon',
                  data: [22, 29, 37, 19],
                  backgroundColor:'#f47e43' 
                },
                {
                  label: 'Overdue',
                  data: [27, 0, 7, 11],
                  backgroundColor:'#b40c1e'  
                }
              ],
          },
          CE1Flagdata:[
            {
              flag_sece: 'SECE 1',
              flag_compat: 'UK',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 2',
              flag_compat: 'UK',
              flag_class: 'No'
            },  
            {   
              flag_sece: 'SECE 3',
              flag_compat: 'UK/DK',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 4',
              flag_compat: 'UK',
              flag_class: 'No'
            },
            {
              flag_sece: 'SECE 5',
              flag_compat: 'UK / Nor',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 6',
              flag_compat: 'Global',
              flag_class: 'No'
            },
            {
              flag_sece: 'SECE 7',
              flag_compat: 'Global',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 8',
              flag_compat: 'Global',
              flag_class: 'No'
            },
            {
              flag_sece: 'SECE 9',
              flag_compat: 'Global',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 10',
              flag_compat: 'Global',
              flag_class: 'No'
            },
            {
              flag_sece: 'SECE 11',
              flag_compat: 'Global',
              flag_class: 'No'
            }
          ],
          //comparison engine 2nd col
          CE2InstallationId: '',
          CE2SeceId: '',
          CE2Type: '',
          CE2OpenFindingsPieData:{
            labels: ['In Date', 'Overdue'],
            datasets:[{
              label: 1,
              data:[
                40, 40
              ],
              backgroundColor:[
                '#58b23d',
                '#b40c1e',
              ]
            }],
          },
          CE2FindingsBarrierBar:{
            labels:['Barrier 1', 'Barrier 2', 'Barrier 3'],
            datasets: [
                {
                  label: 'In Date',
                  data: [20, 30, 40],
                  backgroundColor:'#58b23d'
                },
                {
                  label: 'Expiring Soon',
                  data: [10, 0, 20],
                  backgroundColor:'#f47e43' 
                },
                {
                  label: 'Overdue',
                  data: [0, 10, 20],
                  backgroundColor:'#b40c1e'  
                }
              ],
          },
          CE2FindingsActionParty:{
            labels:['Action Party 1', 'Action Party 2', 'Action Party 3', 'Action Party 4'],
            datasets: [
                {
                  label: 'In Date',
                  data: [10, 20, 30, 40],
                  backgroundColor:'#58b23d'
                },
                {
                  label: 'Expiring Soon',
                  data: [40, 30, 20, 10],
                  backgroundColor:'#f47e43' 
                },
                {
                  label: 'Overdue',
                  data: [5, 0, 10, 15],
                  backgroundColor:'#b40c1e'  
                }
              ],
          },
          CE2Flagdata:[
            {
              flag_sece: 'SECE 1',
              flag_compat: 'UK/DK',
              flag_class: 'No'
            },
            {
              flag_sece: 'SECE 2',
              flag_compat: 'UK',
              flag_class: 'No'
            },  
            {   
              flag_sece: 'SECE 3',
              flag_compat: 'UK/DK',
              flag_class: 'No'
            },
            {
              flag_sece: 'SECE 4',
              flag_compat: 'UK',
              flag_class: 'No'
            },
            {
              flag_sece: 'SECE 5',
              flag_compat: 'UK / Nor',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 6',
              flag_compat: 'Global',
              flag_class: 'No'
            },
            {
              flag_sece: 'SECE 7',
              flag_compat: 'Global',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 8',
              flag_compat: 'Global',
              flag_class: 'No'
            },
            {
              flag_sece: 'SECE 9',
              flag_compat: 'Global',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 10',
              flag_compat: 'Global',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 11',
              flag_compat: 'Global',
              flag_class: 'No'
            }
          ],
          //comparison engine 3rd col
          CE3InstallationId: '',
          CE3SeceId: '',
          CE3Type: '',
          CE3OpenFindingsPieData:{
            labels: ['In Date', 'Overdue'],
            datasets:[{
              label: 1,
              data:[
                20, 40
              ],
              backgroundColor:[
                '#58b23d',
                '#b40c1e',
              ]
            }],
          },
          CE3FindingsBarrierBar:{
            labels:['Barrier 1', 'Barrier 2', 'Barrier 3'],
            datasets: [
                {
                  label: 'In Date',
                  data: [75, 50, 25],
                  backgroundColor:'#58b23d'
                },
                {
                  label: 'Expiring Soon',
                  data: [50, 25, 75],
                  backgroundColor:'#f47e43' 
                },
                {
                  label: 'Overdue',
                  data: [5, 10, 10],
                  backgroundColor:'#b40c1e'  
                }
              ],
          },
          CE3FindingsActionParty:{
            labels:['Action Party 1', 'Action Party 2', 'Action Party 3', 'Action Party 4'],
            datasets: [
                {
                  label: 'In Date',
                  data: [5, 10, 15, 20],
                  backgroundColor:'#58b23d'
                },
                {
                  label: 'Expiring Soon',
                  data: [20, 15, 10, 5],
                  backgroundColor:'#f47e43' 
                },
                {
                  label: 'Overdue',
                  data: [10, 15, 20, 5],
                  backgroundColor:'#b40c1e'  
                }
              ],
          },
          CE3Flagdata:[
            {
              flag_sece: 'SECE 1',
              flag_compat: 'Nor',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 2',
              flag_compat: 'DK',
              flag_class: 'Yes'
            },  
            {   
              flag_sece: 'SECE 3',
              flag_compat: 'UK/DK',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 4',
              flag_compat: 'UK',
              flag_class: 'No'
            },
            {
              flag_sece: 'SECE 5',
              flag_compat: 'UK / Nor',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 6',
              flag_compat: 'Global',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 7',
              flag_compat: 'Global',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 8',
              flag_compat: 'Global',
              flag_class: 'No'
            },
            {
              flag_sece: 'SECE 9',
              flag_compat: 'Global',
              flag_class: 'Yes'
            },
            {
              flag_sece: 'SECE 10',
              flag_compat: 'Global',
              flag_class: 'No'
            },
            {
              flag_sece: 'SECE 11',
              flag_compat: 'Global',
              flag_class: 'No'
            }
          ],          
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

  render(){
      
        //class/flag table headers
        const columns = [
            {
              Header: 'SECE',
              accessor: 'flag_sece', // accessor is the "key" in the data
            },
            {
              Header: 'COMPAT',
              accessor: 'flag_compat', // accessor is the "key" in the data
            },
            {
              Header: 'CLASS',
              accessor: 'flag_class', // accessor is the "key" in the data
            }
        ]

    return(
        <div className="w-full px-8 pb-8">

            <div className="pb-6 border-b-2 border-gray-200">

            <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Comparison Engine <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => this.setState({ HelpActive: !this.state.HelpActive })} /></h1>
            
            <p className={this.state.HelpActive ? "hidden" : "mt-3 block"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam condimentum a lectus non condimentum. Aliquam rhoncus, enim vitae condimentum pharetra, urna magna elementum sapien, non vehicula turpis tellus nec velit. Vivamus tristique venenatis neque, eget tristique mauris suscipit aliquet. Pellentesque et massa nunc.</p>

            </div>

            {/* 6 COL GRID */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 pt-6">

                {/* COL 1 */}
                <div>

                    <div className="flex flex-col h-full">

                        <div className="mb-4">
                            <select 
                                className="form-control block w-full" 
                                name="CE1InstallationId" 
                                id="CE1InstallationId"
                                onChange={this.handleChange}
                                //value={values.CE1InstallationId} 
                            >
                                <option>Select Installation</option>
                                <option value="1">Installation 1</option>
                                <option value="2">Installation 2</option>
                                <option value="3">Installation 3</option>
                            </select>
                        </div>

                        {this.state.CE1InstallationId >= 1 ?
                        <div className="mb-4">
                            <select 
                                className="form-control block w-full" 
                                name="CE1SeceId" 
                                id="CE1SeceId"
                                onChange={this.handleChange}
                                //value={values.CE1SeceId} 
                            >
                                <option>Select SECE</option>
                                <option value="1">SECE 1</option>
                                <option value="2">SECE 2</option>
                                <option value="3">SECE 3</option>
                            </select>
                        </div>
                        : null }

                        {this.state.CE1SeceId >= 1 ?
                        <div className="mb-4">
                            <select 
                                className="form-control block w-full" 
                                name="CE1Type" 
                                id="CE1Type"
                                onChange={this.handleChange}
                                //value={values.ce1_sece_id} 
                            >
                                <option>Select Type</option>
                                <option value="OpenFindings">All Open Findings</option>
                                <option value="BarrierFindings">Findings By Barrier</option>
                                <option value="ActionFindings">Findings By Action Party</option>
                                <option value="Performance">Performance Standards</option>
                                <option value="Verification">Verification Activities</option>
                                <option value="ClassFlag">Class / Flag</option>
                            </select>
                        </div>
                        : null }

                        {this.state.CE1Type === 'OpenFindings' ?
                        <div className="p-4 bg-gray-100">
                            <h3 className="mb-3 font-semibold text-blue-900 uppercase">All Open Findings</h3>

                            <div style={{minHeight: '250px'}}>
                              <Pie
                                  data={this.state.CE1OpenFindingsPieData}
                                  options={{
                                  title:{display:false, text:'All Open Findings', fontColor:'#002a4e'},
                                  legend: {align:'start'},
                                  maintainAspectRatio: false
                                  }}
                              />
                            </div>

                        </div>
                        : null }

                        {this.state.CE1Type === 'BarrierFindings' ?
                        <div className="p-4 bg-gray-100">
                            <h3 className="font-semibold text-blue-900 uppercase">Open Findings by Barrier</h3>

                            <div style={{minHeight: '250px'}}>
                              <Bar
                                  data={this.state.CE1FindingsBarrierBar}
                                  options={{
                                      scales: {
                                          xAxes: [{
                                              stacked: true
                                          }],
                                          yAxes: [{
                                              stacked: true
                                          }]
                                      },
                                      legend: {align:'start'},
                                      maintainAspectRatio: false
                                  }}
                              />
                            </div>

                        </div>
                        : null }

                        {this.state.CE1Type === 'ActionFindings' ? 
                        <div className="p-4 bg-gray-100">
                            <h3 className="font-semibold text-blue-900 uppercase">Findings By Action Party</h3>

                            <div style={{minHeight: '250px'}}>
                              <Bar
                                  data={this.state.CE1FindingsActionParty}
                                  options={{
                                      scales: {
                                          xAxes: [{
                                              stacked: true
                                          }],
                                          yAxes: [{
                                              stacked: true
                                          }]
                                      },
                                      legend: {align:'start'},
                                      maintainAspectRatio: false
                                  }}
                              />
                            </div>

                        </div>
                        : null }

                        {this.state.CE1Type === 'Performance' ? 
                        <div className="p-4 border">
                            <h3 className="mb-2 font-semibold text-blue-900">Performance Standards</h3>
                            <p className="mb-3">Visually inspect earth bonding to ensure it is free from significant corrosion &amp; mechanical damage. Confirm there is no significant recordable fault in the earth bonding system as described below:</p>
                            <ul>
                                <li>Earth continuity above maximum (1 ohm)</li>
                                <li>Earthing terminations insecure</li>
                                <li>Earthing device/cable damaged</li>
                            </ul>
                        </div>
                        : null }

                        {this.state.CE1Type === 'Verification' ? 
                        <div className="p-4 border">
                            <h3 className="mb-2 font-semibold text-blue-900">Verification Activities</h3>
                            <p>Verification inspection of critical bonding</p>
                        </div>
                        : null }

                        {this.state.CE1Type === 'ClassFlag' ? 
                        <div className="-mt-8">
                          <Table columns={columns} data={this.state.CE1Flagdata} pagination={false} filter={false}/>
                        </div>
                        : null }


                    </div>

                </div>
                {/* COL 1 END */}

                {/* COL 2 */}
                <div>

                  <div className="flex flex-col h-full">

                      <div className="mb-4">
                          <select 
                              className="form-control block w-full" 
                              name="CE2InstallationId" 
                              id="CE2InstallationId"
                              onChange={this.handleChange}
                              //value={values.CE1InstallationId} 
                          >
                              <option>Select Installation</option>
                              <option value="1">Installation 1</option>
                              <option value="2">Installation 2</option>
                              <option value="3">Installation 3</option>
                          </select>
                      </div>

                      {this.state.CE2InstallationId >= 1 ?
                      <div className="mb-4">
                          <select 
                              className="form-control block w-full" 
                              name="CE2SeceId" 
                              id="CE2SeceId"
                              onChange={this.handleChange}
                              //value={values.CE1SeceId} 
                          >
                              <option>Select SECE</option>
                              <option value="1">SECE 1</option>
                              <option value="2">SECE 2</option>
                              <option value="3">SECE 3</option>
                          </select>
                      </div>
                      : null }

                      {this.state.CE2SeceId >= 1 ?
                      <div className="mb-4">
                          <select 
                              className="form-control block w-full" 
                              name="CE2Type" 
                              id="CE2Type"
                              onChange={this.handleChange}
                              //value={values.ce1_sece_id} 
                          >
                              <option>Select Type</option>
                              <option value="OpenFindings">All Open Findings</option>
                              <option value="BarrierFindings">Findings By Barrier</option>
                              <option value="ActionFindings">Findings By Action Party</option>
                              <option value="Performance">Performance Standards</option>
                              <option value="Verification">Verification Activities</option>
                              <option value="ClassFlag">Class / Flag</option>
                          </select>
                      </div>
                      : null }

                      {this.state.CE2Type === 'OpenFindings' ?
                      <div className="p-4 bg-gray-100">
                          <h3 className="mb-3 font-semibold text-blue-900 uppercase">All Open Findings</h3>

                          <div style={{minHeight: '250px'}}>
                            <Pie
                                data={this.state.CE2OpenFindingsPieData}
                                options={{
                                title:{display:false, text:'All Open Findings', fontColor:'#002a4e'},
                                legend: {align:'start'},
                                maintainAspectRatio: false
                                }}
                            />
                          </div>
                      </div>
                      : null }

                      {this.state.CE2Type === 'BarrierFindings' ?
                      <div className="p-4 bg-gray-100">
                          <h3 className="font-semibold text-blue-900 uppercase">Open Findings by Barrier</h3>

                          <div style={{minHeight: '250px'}}>
                            <Bar
                                data={this.state.CE2FindingsBarrierBar}
                                options={{
                                    scales: {
                                        xAxes: [{
                                            stacked: true
                                        }],
                                        yAxes: [{
                                            stacked: true
                                        }]
                                    },
                                    legend: {align:'start'},
                                    maintainAspectRatio: false
                                }}
                            />
                          </div>

                      </div>
                      : null }

                      {this.state.CE2Type === 'ActionFindings' ? 
                      <div className="p-4 bg-gray-100">
                          <h3 className="font-semibold text-blue-900 uppercase">Findings By Action Party</h3>

                          <div style={{minHeight: '250px'}}>
                            <Bar
                                data={this.state.CE2FindingsActionParty}
                                options={{
                                    scales: {
                                        xAxes: [{
                                            stacked: true
                                        }],
                                        yAxes: [{
                                            stacked: true
                                        }]
                                    },
                                    legend: {align:'start'},
                                    maintainAspectRatio: false
                                }}
                            />
                          </div>

                      </div>
                      : null }

                      {this.state.CE2Type === 'Performance' ? 
                      <div className="p-4 border">
                          <h3 className="mb-2 font-semibold text-blue-900">Performance Standards</h3>
                          <p className="mb-3">Visually inspect earth bonding to ensure it is free from significant corrosion &amp; mechanical damage. Confirm there is no significant recordable fault in the earth bonding system as described below:</p>
                          <ul>
                              <li>Earth continuity above maximum (1 ohm)</li>
                              <li>Earthing terminations insecure</li>
                              <li>Earthing device/cable damaged</li>
                          </ul>
                      </div>
                      : null }

                      {this.state.CE2Type === 'Verification' ? 
                      <div className="p-4 border">
                          <h3 className="mb-2 font-semibold text-blue-900">Verification Activities</h3>
                          <p>Verification inspection of critical bonding</p>
                      </div>
                      : null }

                      {this.state.CE2Type === 'ClassFlag' ? 
                      <div className="-mt-8">
                        <Table columns={columns} data={this.state.CE2Flagdata} pagination={false} filter={false}/>
                      </div>
                      : null }

                    </div>

                </div>
                {/* COL 2 END */}

                {/* COL 3 */}
                <div>

                <div className="flex flex-col h-full">

                    <div className="mb-4">
                        <select 
                            className="form-control block w-full" 
                            name="CE3InstallationId" 
                            id="CE3InstallationId"
                            onChange={this.handleChange}
                            //value={values.CE1InstallationId} 
                        >
                            <option>Select Installation</option>
                            <option value="1">Installation 1</option>
                            <option value="2">Installation 2</option>
                            <option value="3">Installation 3</option>
                        </select>
                    </div>

                    {this.state.CE3InstallationId >= 1 ?
                    <div className="mb-4">
                        <select 
                            className="form-control block w-full" 
                            name="CE3SeceId" 
                            id="CE3SeceId"
                            onChange={this.handleChange}
                            //value={values.CE1SeceId} 
                        >
                            <option>Select SECE</option>
                            <option value="1">SECE 1</option>
                            <option value="2">SECE 2</option>
                            <option value="3">SECE 3</option>
                        </select>
                    </div>
                    : null }

                    {this.state.CE3SeceId >= 1 ?
                    <div className="mb-4">
                        <select 
                            className="form-control block w-full" 
                            name="CE3Type" 
                            id="CE3Type"
                            onChange={this.handleChange}
                            //value={values.ce1_sece_id} 
                        >
                            <option>Select Type</option>
                            <option value="OpenFindings">All Open Findings</option>
                            <option value="BarrierFindings">Findings By Barrier</option>
                            <option value="ActionFindings">Findings By Action Party</option>
                            <option value="Performance">Performance Standards</option>
                            <option value="Verification">Verification Activities</option>
                            <option value="ClassFlag">Class / Flag</option>
                        </select>
                    </div>
                    : null }

                    {this.state.CE3Type === 'OpenFindings' ?
                    <div className="p-4 bg-gray-100">
                        <h3 className="mb-3 font-semibold text-blue-900 uppercase">All Open Findings</h3>

                        <div style={{minHeight: '250px'}}>
                          <Pie
                              data={this.state.CE3OpenFindingsPieData}
                              options={{
                              title:{display:false, text:'All Open Findings', fontColor:'#002a4e'},
                              legend: {align:'start'},
                              maintainAspectRatio: false
                              }}
                          />
                        </div>

                    </div>
                    : null }

                    {this.state.CE3Type === 'BarrierFindings' ?
                    <div className="p-4 bg-gray-100">
                        <h3 className="font-semibold text-blue-900 uppercase">Open Findings by Barrier</h3>

                        <div style={{minHeight: '250px'}}>
                          <Bar
                              data={this.state.CE3FindingsBarrierBar}
                              options={{
                                  scales: {
                                      xAxes: [{
                                          stacked: true
                                      }],
                                      yAxes: [{
                                          stacked: true
                                      }]
                                  },
                                  legend: {align:'start'},
                                  maintainAspectRatio: false
                              }}
                          />
                        </div>

                    </div>
                    : null }

                    {this.state.CE3Type === 'ActionFindings' ? 
                    <div className="p-4 bg-gray-100">
                        <h3 className="font-semibold text-blue-900 uppercase">Findings By Action Party</h3>

                        <div style={{minHeight: '250px'}}>
                          <Bar
                              data={this.state.CE3FindingsActionParty}
                              options={{
                                  scales: {
                                      xAxes: [{
                                          stacked: true
                                      }],
                                      yAxes: [{
                                          stacked: true
                                      }]
                                  },
                                  legend: {align:'start'},
                                  maintainAspectRatio: false
                              }}
                          />
                        </div>

                    </div>
                    : null }

                    {this.state.CE3Type === 'Performance' ? 
                    <div className="p-4 border">
                        <h3 className="mb-2 font-semibold text-blue-900">Performance Standards</h3>
                        <p className="mb-3">Visually inspect earth bonding to ensure it is free from significant corrosion &amp; mechanical damage. Confirm there is no significant recordable fault in the earth bonding system as described below:</p>
                        <ul>
                            <li>Earth continuity above maximum (1 ohm)</li>
                            <li>Earthing terminations insecure</li>
                            <li>Earthing device/cable damaged</li>
                        </ul>
                    </div>
                    : null }

                    {this.state.CE3Type === 'Verification' ? 
                    <div className="p-4 border">
                        <h3 className="mb-2 font-semibold text-blue-900">Verification Activities</h3>
                        <p>Verification inspection of critical bonding</p>
                    </div>
                    : null }

                    {this.state.CE3Type === 'ClassFlag' ? 
                    <div className="-mt-8">
                      <Table columns={columns} data={this.state.CE3Flagdata} pagination={false} filter={false}/>
                    </div>
                    : null }

                </div>


              </div>
              {/* COL 3 END */}


            </div>

        </div>
    )
  }

}

export default ComparisonEngine;
