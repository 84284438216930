/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import {formatDateToLocaleDateString, capitaliseFirstCase} from "../../utils/index";
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils";

const USER_HISTORIES_QUERY = gql`
query UserHistories($dhId: ID){
    user_histories(dhId: $dhId) {
        _id
        user_ms_oid
        user_duty_holder
        user_type {
            _id
            user_type_display_name
        }
        user_duty_holder_location
        user_email
        user_fname
        user_lname
        user_job_title
        user_location
        user_office_tel
        user_mobile
        user_child_id
        user_added_date
        user_added_id
        user_notes
        user_status
        history_parent_record_id
        history_modifying_user_id {
            _id
            user_fname
            user_lname
            user_email
        }
        history_modified_date
        history_modified_date_timestamp
    }
}
`;


function UserHistories() {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [showHelp, setShowHelp] = useState(false);
  const [userHistories, setUserHistories] = useState([]);

  const { dhId = "" } = useParams();

  const [user_histories, { loading, error, data}] = useLazyQuery(USER_HISTORIES_QUERY);

  useEffect(() => {
  if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
  }
  }, [authContext]);

  useEffect(() => {
  if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
  }
  }, [userTypeAndRoleObject]);
 
  useEffect(() => {
    user_histories({ variables: { dhId: dhId }, errorPolicy: 'all' });
  }, [])

  useEffect(() => {
    console.log('userHistory data===>', data)
    if (data && data.user_histories) {
      let historyList = data.user_histories;
      let activeHistoryList = historyList.splice(1, historyList.length).reverse();
      let formatedHistoryList = formatUserHistoryData(activeHistoryList);
      return setUserHistories(formatedHistoryList);
    }
  }, [data])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: "name",
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'User Type',
        accessor: 'userType',
      },
      {
        Header: 'Modified Date',
        accessor: "modifiedDate"
      },
      {
        Header: 'Modifying User',
        accessor: "modifyingUserName",
      },
      {
        Header: 'Status',
        accessor: "status",
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: user => (
          <div className="text-right">
            <Link
              to={{
                pathname: dhId && dhId !== "" ? `/dutyHolder/${dhId}/user/view-diff/${user.value.id}/${user.value.parentId}` : `/user/view-diff/${user.value.id}/${user.value.parentId}`,
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              View Change
            </Link>

          </div>
        )
      },
    ],
    []
  )

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className=" text-blue-900 font-sans text-2xl font-bold uppercase">User History<FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3">A table of updates to ABS and Duty Holder user accounts.</p>
        }

        {/* <Link to={dhId && dhId !== "" ? `/dutyHolder/${dhId}/users` : "/users"} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link> */}

      </div>


      <Table columns={columns} data={userHistories} />

    </div>
  )
}

function formatUserHistoryData(list) {
  let formatedHistoryList = [];

  if (list && list.length) {

    list.forEach(userHis => {
      const { _id, user_email, user_fname, user_lname, user_type, user_status, history_modified_date, history_modifying_user_id, history_parent_record_id } = userHis ? userHis : {};
      const { user_type_display_name } = user_type ? user_type : {};
      const {user_fname: modUserFName, user_lname: modUserLName, } = history_modifying_user_id ? history_modifying_user_id : {};

      let formatedHistory = {
        id: _id,
        name: (user_fname && user_lname) ? `${user_fname} ${user_lname}` : "N/A",
        email: user_email ? user_email : "N/A",
        userType: user_type_display_name ? user_type_display_name : "N/A",
        modifiedDate: history_modified_date ? formatDateToLocaleDateString(history_modified_date) : "N/A",
        modifyingUserName: (modUserFName && modUserLName) ? `${modUserFName} ${modUserLName}` : "N/A",
        parentId: history_parent_record_id,
        status: user_status && user_status !== "" ? capitaliseFirstCase(user_status) : "N/A"
      };

      formatedHistoryList.push(formatedHistory);
    });

  }

  return formatedHistoryList;
}

export default UserHistories;