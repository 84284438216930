import React, {useState} from 'react';

const AuthContext = React.createContext({});

const AuthContextProvider = ({children}) => {

  const [user, setUser] = useState();

  const [updateUser, setUpdateUser] = useState(false)

  return <AuthContext.Provider value={{
    user: user,
    setUser: setUser,
    updateUser: updateUser,
    updateUserDetails: () => setUpdateUser(!updateUser)
  }}>{children}</AuthContext.Provider>
}

export {AuthContext, AuthContextProvider}