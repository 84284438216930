/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useState, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import Table from '../global/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";
import {parseJSONSafely} from "../../utils";
import Sanitiser from "../global/sanitiser";

const {REACT_APP_FILE_MANAGER_API} = process.env;

const NOTICES_QUERY = gql`
query{
  notices{
    _id
    not_type
    user_type
    dh_id
    installation_ids{
      _id
    }
    bar_ids{
      _id
    }
    sece_ids{
      _id
    }
    not_title
    not_description
    not_file
    not_file_dest_path
  }
}`;

function Notices() {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();
  
  const [showHelp, setShowHelp] = useState(false);
  const [noticeList, setNoticeList] = useState([]);
  const [notice, setNotice] = useState({});

  const [notices, { loading, error, data: noticeData }] = useLazyQuery(NOTICES_QUERY);

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
  }, [userTypeAndRoleObject]);


  useEffect(() => {
    notices({errorPolicy: 'all'});
  }, [])

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
       notices({errorPolicy: 'all'});
    }
  }, [authContext]);

  useEffect(() => {
    if (noticeData && noticeData.notices) {
      let tempNoticesList = formatNoticeData(noticeData.notices, userTypeAndRoleObject);
      setNoticeList(tempNoticesList);
    }
  }, [noticeData])

  const toggleModal = () => {
    const modal = document.querySelector('.modal')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
  }

  const updateModalData = (notice) => {
     setNotice(notice);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'not_title',
      },
      {
        Header: 'Type',
        accessor: 'not_type',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: notice }) => (
          <div className="text-right">

            <Link
              to="#"
              className="button-red mb-1 mr-1 text-xs"
              onClick={() => { updateModalData(notice); toggleModal() }}
            >
              View
            </Link>

            {notice.userTypeAndRoleObject && notice.userTypeAndRoleObject.userType === "ABS" && notice.userTypeAndRoleObject.userRole !== "Verifier" &&
              <Link
                to={{
                  pathname: `/notice/edit/${notice._id}`,
                  state: { formMode: 'edit' }
                }}
                className="button-red mb-1 mr-1 text-xs"
              >
                Edit
              </Link>
            }

          </div>
        ),
      },
    ],
    []
  )

  if (loading) {
    return (
      <PageSpinner/>
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return(
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Notices <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
        <p className="mt-3">A table of Notices.  Add, view and edit a notice using the buttons provided.</p>
        }
        
      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Notices</h6>

      {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" && userTypeAndRoleObject.userRole !=="Verifier" &&
      <Link 
        to={{
          pathname: '/notice/add',
          state: { formMode:'add'}
        }}      
        className="button-red mr-2 mb-2 py-3 px-5"
      >
        Add Notice
      </Link>
      }

      <Table 
        columns={columns} 
        data={noticeList} 
      />


      <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-10">
        <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-30" onClick={() => toggleModal()}></div>
        <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-40" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

          <div className="mb-4 pb-2 border-b-2 border-gray-200">
            {notice.not_title &&
              <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">{notice.not_title}</h2>
            }
          </div>

          <Sanitiser html={notice.not_description && notice.not_description} />

          <div>
            {createFileLinks(notice)}
          </div>
          <button className="mt-3 mb-4 button-red capitalize" onClick={() => toggleModal()}>Close</button>
        </div>
      </div>


    </div>
  )
}

function formatNoticeData(noticeData, userTypeAndRoleObject) {
  let newNoticeList = [];

  if (noticeData && noticeData.length) {
    let tempNoticeList = noticeData;
    tempNoticeList.forEach(noticeData => {
      let newNotice = { ...noticeData, userTypeAndRoleObject: userTypeAndRoleObject };
      newNoticeList.push(newNotice);
    })
  };

  return newNoticeList;
}

function createFileLinks(notice) {
  let linkList = "";

  let attachment = notice && notice.not_file && notice.not_file !== "" ? notice.not_file : "[]";
  let attachments = parseJSONSafely(attachment);
  let fileDestPath = notice && notice.not_file_dest_path ? notice.not_file_dest_path : "";

  if (attachments && attachments.length && fileDestPath !== "") {
    let fullPath = `${REACT_APP_FILE_MANAGER_API}/download-file?path=/${fileDestPath}/`;
    let numberOfAttachments = attachments.length;

    let newAttachments = <React.Fragment>{attachments.map((att, index) =>
      <React.Fragment key={index}>
        <div className="text-blue-900 font-sans text-sm font-bold">File Name:</div>
        <a href={fullPath + att.name} className="mb-1 hover:underline" key={index} target={"_blank"} rel="noopener noreferrer" download={true}>
          {att.name}{((index + 1) < numberOfAttachments) ? <br /> : ""}
        </a>
        <div className="text-blue-900 font-sans text-sm font-bold">File Description:</div>
        <div>
          {att.description}
        </div>
        {((index + 1) < numberOfAttachments) ? <br /> : ""}
      </React.Fragment>
    )}
    </React.Fragment>
    linkList = newAttachments;
  }

  return linkList;
}

export default Notices;