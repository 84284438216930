/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Table from '../global/Table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";

const COMPETENCY_CATEGORY_HISTORIES_QUERY = gql`
query {
    competencyCategoryHistories {
        _id
        comp_cat_code
        comp_cat_title
        history_parent_record_id
        history_modifying_user_id {
            _id
            user_fname
            user_lname
            user_email
        }
        history_modified_date
        history_modified_date_timestamp
    }
}
`;

function CompCategoryHistory(props) {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const [showHelp, setShowHelp] = useState(false);
  const [compCatHistories, setCompCatHistories] = useState([]);

  const [competencyCategoryHistories, { loading, error, data: compCatHistoriesData }] = useLazyQuery(COMPETENCY_CATEGORY_HISTORIES_QUERY);

  useEffect(() => {
      if (authContext && authContext.user){
          let result = createUserTypeAndRoleObject(authContext);
          setUserTypeAndRoleObject(result);
      }
  }, [authContext]);

  useEffect(() => {
      if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
          history.push('/access-permissions');
      }
  }, [userTypeAndRoleObject]);


  useEffect(() => {
    competencyCategoryHistories({ variables: {}, errorPolicy: 'all' });
  }, []);

  useEffect(() => {
    if (compCatHistoriesData && compCatHistoriesData.competencyCategoryHistories) {
      let histories = formatCompCatHistories(compCatHistoriesData.competencyCategoryHistories);
      let reversedHistoryList = histories.reverse();
      setCompCatHistories(reversedHistoryList);
    }
  }, [compCatHistoriesData]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'code'
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Modified Date',
        accessor: 'modified_date',
      },
      {
        Header: 'Modified By',
        accessor: 'modified_by'
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { id, parentId } }) => (
          <div className="text-right">
            <Link
              to={{
                pathname: `/user-competency/competencies/competency-cats/history-diff/${id}/${parentId}`,
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              View Change
            </Link>

          </div>
        )
      },
    ],
    []
  )

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">COMPETENCY CATEGORIES Histories<FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3">A table detailing changes to competency categories.</p>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <h6 className="mb-6 font-bold text-sm text-red-900">Dashboard - User Competency - Competencies - Competency Categories - Histories</h6>

      <Link
        to={{
          pathname: '/user-competency/competencies/competency-cats',
        }}
        className="button-red mr-2 mb-2 py-3 px-5"
      >
        <FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back
      </Link>

      <Table columns={columns} data={compCatHistories} />

    </div>
  )

}

function formatCompCatHistories(compCatHistories) {

  let newCompCatHistories = [];
  if (compCatHistories && compCatHistories.length) {
    compCatHistories.forEach(compCatHis => {

      const { user_fname: userFname = "", user_lname: userLname = "" } = compCatHis.history_modifying_user_id ? compCatHis.history_modifying_user_id : {};

      let modifiedDate = new Date(compCatHis.history_modified_date);
      let options = { year: 'numeric', month: 'long', day: 'numeric' };
      let modifiedDateString = modifiedDate.toLocaleDateString('en-GB', options);

      let compCatHistory = {
        id: compCatHis._id,
        code: compCatHis.comp_cat_code,
        name: compCatHis.comp_cat_title,
        parentId: compCatHis.history_parent_record_id,
        modified_date: modifiedDateString,
        modified_by: (userFname + " " + userLname),
      };

      newCompCatHistories.push(compCatHistory);
    })
  }
  return newCompCatHistories;
}

export default CompCategoryHistory;
