/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Table from '../global/Table';
import {gql, useMutation, useLazyQuery} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from '../global/pageSpinner';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils";

const COMPETENCIES_QUERY = gql`
query competencies($isArchived:Boolean!){
  competencies(isArchived: $isArchived){
      _id
      comp_code
      comp_title       
      comp_level
      comp_levels{
        _id
        sysvar_title
      }
      comp_notes
      comp_archived
      comp_archived_id
      comp_archived_date
      comp_cat_id{ 
        _id
        comp_cat_title,
        comp_cat_archived
      }
    }
}`;

const UPDATE_COMPETENCY = gql`
mutation UpdateCompetency($compId: String!, $userId: String, $comp: UpdateCompetencyInput!) {
  updateCompetency(_id: $compId, userId: $userId, comp: $comp) {
       _id
    }
}`;

function CompetencyManager(props) {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [showHelp, setShowHelp] = useState(false);
  const [comps, setCompetencies] = useState([]);
  const [showArchivedCompetencies, setShowArchivedCompetencies] = useState(false);

  const { location: { state = "" }, match: { url = "" } } = props ? props : {};

  const [competencies, {loading, error, data:competenciesData, refetch}] = useLazyQuery(COMPETENCIES_QUERY);
  const [updateCompetency, {data: updateCompData}] = useMutation(UPDATE_COMPETENCY);
  
  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
  }, [userTypeAndRoleObject]);

  useEffect(() => {
    if (state === "archived"){
      setShowArchivedCompetencies(true);
    }
  }, []);

  useEffect(() => {
      competencies({variables: {isArchived:showArchivedCompetencies}, errorPolicy: 'all'});
  }, [showArchivedCompetencies]);

  useEffect(() => {
    if (competenciesData && competenciesData.competencies) {
      const { _id: userId = "" } = authContext.user ? authContext.user : {};
      let formatedCompetencies = formatCompetenciesData(competenciesData.competencies, userId, state);
      setCompetencies(formatedCompetencies);   
    }
  }, [competenciesData]);

  useEffect(() => {
    if (updateCompData && updateCompData.updateCompetency) {
      refetch();
    }
  }, [updateCompData]);

  const archiveManager = (compId, isArchived, archivedId, archivedDate, userId) => {
    if (compId !== "") {
      const payload = {
        comp_archived: isArchived ? false : true,
        comp_archived_id: isArchived ? archivedId : userId,
        comp_archived_date: isArchived ? archivedDate : new Date()
      }
      updateCompetency({ variables: { compId: compId, userId: userId, comp: payload } });
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'comp_code',
      },
      {
        Header: 'Name',
        accessor: 'comp_title',
      },
      {
        Header: 'Levels',
        accessor: 'comp_levels',
        style : {whiteSpace: "pre-wrap"}
      },
      {
        Header: 'Category',
        accessor: 'comp_cat_id.comp_cat_title',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({value:{_id, comp_archived, comp_archived_id, comp_archived_date, user_id, state}}) => (
          <div className="text-right">
            <Link
              to={{
                pathname: `/user-competency/competency/view/${_id}`,
                state: {archivedStatus: state}
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              View
            </Link>
            {comp_archived ? "" : <Link
              to={{
                pathname: `/user-competency/competency/edit/${_id}`,
                state: { formMode:'edit', archivedStatus: state}
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              Edit
            </Link>}
            <Link
              to="#"
              className="button-red mr-1 text-xs"
              type="button"
              onClick={() => {archiveManager(_id, comp_archived, comp_archived_id, comp_archived_date, user_id)}}
            >
              {comp_archived?'Unarchive':'Archive'}
            </Link>
          </div>
        )
      },
      ],
    []
  )

  if (loading) {
    return (
      <PageSpinner/>
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return(
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Competencies <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
        <div>
          {showArchivedCompetencies === false ?
          <div>
            <p className="mt-3">A table of the active users' competencies, each entry can be viewed, edited and archived.</p>
            <p>Competencies can be added, and past changes viewed by clicking 'Add' and 'History'.</p>
          </div>
          :
            <p className="mt-3">A table of the archived users' competencies.  Each entry can be viewed and unarchived.</p>
          }
        </div>
        }
        
      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <h6 className="mb-6 font-bold text-sm text-red-900">Dashboard - User Competencies - Competency Manager</h6>

      <Link 
        to={{
          pathname: '/user-competencies',
        }}      
        className="button-red mr-2 mb-2 py-3 px-5"
      >
        <FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back
      </Link>

      <Link 
        to={{
          pathname: '/user-competency/competency/add',
          state: { formMode:'add', archivedStatus: state}
        }}      
        className="button-red mr-2 mb-2 py-3 px-5"
      >
        Add
      </Link>

      <Link to={{
                  pathname: showArchivedCompetencies ? url : (url + "/archived"),
                  state: showArchivedCompetencies ? "" : "archived"
                }} 
            type ="button" 
            className="button-red mr-2 mb-2 py-3 px-5" 
            onClick={()=>{setShowArchivedCompetencies(!showArchivedCompetencies)}}
      >
        {showArchivedCompetencies?"Active Competencies":"Archived Competencies"}
      </Link>

      <Link to="/user-competency/competencies/histories" className="button-red mr-2 mb-2 py-3 px-5">History</Link>
      <Link to="/user-competency/competencies/competency-cats" className="button-red mr-2 mb-2 py-3 px-5">Categories</Link>

      <Table 
        columns={columns} 
        data={comps} 
      />

    </div>
  )
}

function formatCompetenciesData(compList, userId, state) {
  let newCompList = [];

  if (compList && compList.length && userId !== "") {
    compList.forEach(comp => {
      let compsLevels = comp.comp_levels;
      let formatedLevels = formatLevels(compsLevels);
      let newComp = { ...comp, comp_levels: formatedLevels, user_id: userId, state: state };
      newCompList.push(newComp);
    })
  };

  return newCompList;
}

function formatLevels(levels) {
  let newLevelList = "";
  if (levels && levels.length) {
    let numberOfLevels = levels.length;
    if (numberOfLevels >= 1) {
      let tempLevels = "";
      levels.forEach((level, index) => {
        tempLevels += level.sysvar_title + "\n";
      });
      newLevelList = tempLevels;
    }
    if (numberOfLevels === 0) {
      newLevelList = "N/A";
    }
  }
  return newLevelList;
}

export default CompetencyManager;