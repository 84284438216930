import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {

  render(){
    return(
      <div className="lg:flex bg-blue-900 py-6 px-4 text-sm">
        <div className="w-full lg:w-4/12">
            <p className="font-sans font-bold text-white">Copyright &copy; by ABS. All Rights Reserved.</p>
        </div>
        <div className="w-full lg:w-8/12 mt-3 lg:mt-0 lg:text-right">
            <ul className="lg:inline-flex text-white font-light font-sans list-none">
                <li className="mr-3"><Link to={{ pathname: "https://ww2.eagle.org/en/terms-of-use.html" }} target="_blank" rel="noopener noreferrer">Terms Of Use</Link></li>
                <li className="mr-3"><Link to={{ pathname: "https://ww2.eagle.org/en/legal-privacy.html" }} target="_blank" rel="noopener noreferrer">Legal/Privacy</Link></li>
                <li className="mr-3"><Link to={{ pathname: "https://ww2.eagle.org/en/certifications.html" }} target="_blank" rel="noopener noreferrer">IACS &amp; ISO Certification</Link></li>
                <li className="mr-3"><Link to={{ pathname: "https://secure.ethicspoint.com/domain/media/en/gui/16502/index.html" }} target="_blank" rel="noopener noreferrer">EthicsPoint</Link></li>
                <li className="mr-3"><Link to={{ pathname: "https://ww2.eagle.org/content/dam/eagle/company-policies/Code%20of%20Ethics.pdf" }} target="_blank" rel="noopener noreferrer">Code of Ethics</Link></li>
            </ul>
        </div>
      </div>
    )
  }
}

export default Footer;
