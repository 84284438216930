/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Table from '../global/Table';
import {gql, useLazyQuery} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";

const SYSTEM_VARIABLE_TYPES_QUERY = gql`
query SystemVariableTypes($isArchived:Boolean!){
    systemVariableTypes(isArchived: $isArchived){
      _id
      sysvartype_title
      sysvartype_section
    }
}`;

function SystemSettings() {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [showHelp, setShowHelp] = useState(false);
  const [sysVarTypes, setSysVarTypes] = useState([]);

  const [systemVariableTypes, {data:sysVarTypesData}] = useLazyQuery(SYSTEM_VARIABLE_TYPES_QUERY);

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
  }, [userTypeAndRoleObject]);


  useEffect(() => {
    systemVariableTypes({variables: {isArchived:false}, errorPolicy: 'all'});
  }, []);

  useEffect(() => {
    if (sysVarTypesData && sysVarTypesData.systemVariableTypes) {
      setSysVarTypes(sysVarTypesData.systemVariableTypes)
    }
  }, [sysVarTypesData]);
  

  const columns = React.useMemo(
    () => [
      {
        Header: 'Variable Name',
        accessor: 'sysvartype_title',
      },
      {
        Header: 'Section',
        accessor: 'sysvartype_section',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({value:{_id}})=> (
          <div className="text-right">
            <Link
              to={{
                pathname:`/system-settings/variables/${_id}`
              }}
              className="button-red mb-1 mr-1 text-xs">
              Variables
            </Link>
          </div>
        )
      },
      ],
    []
  )
  
  return(
    <div className="w-full px-8 pb-8">

      <div className="mb-4 pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">System Settings <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
        <p className="mt-3">A list of editable system variables.  Click 'Variables' to view the variables contained within each category.</p>
        }
        
      </div>

      {/* <Link to="/" className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link> */}

      <h2 className="mt-4 text-blue-900 font-sans text-2xl font-bold uppercase">System Variables</h2>

      <Table columns={columns} data={sysVarTypes} />

    </div>
  )
}

export default SystemSettings;