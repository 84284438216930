import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {gql, useQuery} from '@apollo/client';
import PageSpinner from "../global/pageSpinner";
import ReactDiffViewer from 'react-diff-viewer';
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {omit} from "lodash";
import {parseJSONSafely} from "../../utils";

const NotificationUpdateDiff = () => {

  const {notificationId} = useParams();

  const NOTIFICATION_QUERY = gql`
      query Notification($notificationId: ID!){
          notification(_id: $notificationId) {
              meta
          }
      }
  `;

  const [notificationDiff, setNotificationDiff] = useState([{}, {}]);

  const {loading, error, data} = useQuery(NOTIFICATION_QUERY, {
    variables: {notificationId},
    errorPolicy: 'all'
  });

  useEffect(() => {
    if (data && data.notification && data.notification.meta) {

      const parseMeta = parseJSONSafely(data.notification.meta);
      setNotificationDiff([parseMeta.current, parseMeta.update])

    }
  }, [data])


  if (loading) {
    return (
      <PageSpinner/>
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the diff data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Update Change</h1>
      </div>
      {/*
      <Link to="/notifications" className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']}/> Back</Link> */}

      <div className="mt-6">
        <ReactDiffViewer
          oldValue={JSON.stringify(omit(notificationDiff[0], ['__typename', 'history_modifying_user_id']), null, 2)}
          newValue={JSON.stringify(omit(notificationDiff[1], ['__typename', 'history_modifying_user_id']), null, 2)}
          splitView={true}
        />
      </div>

    </div>
  )

}

export default NotificationUpdateDiff;
