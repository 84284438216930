/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { useParams, useHistory } from 'react-router-dom';
import {gql, useMutation, useLazyQuery} from '@apollo/client';
import TextEditor from '../../components/global/TextEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {omit} from 'lodash';
import {AuthContext} from "../../context/authContext";
import FileUpload from '../global/FileUpload';
import PageSpinner from '../global/pageSpinner';
import {parseJSONSafely, updateAttachmentData, removeLastNthParamFrmUrl, createUserTypeAndRoleObject} from "../../utils";
import { ASSETGROUP_NULL, BARRIER_NULL} from '../../constants';
import {NavContext} from '../../context/navContext';

const {REACT_APP_FILE_MANAGER_API} = process.env;

const Validation = Yup.object().shape({
    ps_ref: Yup.string().label("ps_ref").required("Ref. is required"),
    ps_title: Yup.string().label("ps_title").required("Name is required"),
    ps_criteria: Yup.string().label("ps_criteria").required("Criteria is required"),
    ps_criticality: Yup.string().label("ps_criticality").required("Criticality is required"),
});

const PS_QUERY = gql`
query PerformanceStandard($psId: ID!){
    performanceStandard(_id: $psId) {
        dh_id{
            _id
            dh_name
        }
        ps_ref
        ps_title
        ps_desc 
        ps_criteria
        ag_id{
            _id
            ag_title
        }
        installation_id{
          _id
          installation_title
        }
        bar_id{
          _id
          bar_title
        }
        sece_id{
            _id
            sece_title
        }
        ps_standard_basis 
        ps_source
        ps_scope
        ps_assurance
        ps_criticality
        ps_attachments
    }
}
`;

const CREATE_PERFORMANCE_STANDARD = gql`
    mutation AddPerformanceStandard($ps: CreatePerformanceStandard!) {
        createPerformanceStandard(ps: $ps) {
            _id
            success
            status_code
            message
        }
    }
`;

const UPDATE_PERFORMANCE_STANDARD = gql`
    mutation UpdatePerformanceStandard($id: String!, $userId: String, $ps: UpdatePerformanceStandardInput!) {
        updatePerformanceStandard(_id: $id, userId: $userId, ps: $ps) {
            dh_id{
                dh_name
            }
            ps_ref
            ps_title
            ps_desc 
            ps_criteria 
            ag_id{
                ag_title
            }
            installation_id{
                installation_title
            }
            bar_id{
                bar_title
            }
            ps_standard_basis 
            ps_source 
            ps_scope
            ps_assurance
            ps_criticality 
            ps_attachments  
            success
            status_code
            message             
        }
    }
`;

const SECE_QUERY = gql`
query Sece($seceId: ID!){
    sece(_id: $seceId) {
        sece_title
        sece_group
        dh_id{
            dh_name
        }
        ag_id{
            _id
            ag_title
        }
        installation_id{
            _id
            installation_title
        }
        bar_id{
            _id
            bar_title
        }    
    }
}
`;

const SYSTEM_VARIABLES_TYPE_QUERY = gql`
query SystemVariableTypes($isArchived:Boolean!){
    systemVariableTypes(isArchived:$isArchived) {
        _id
        sysvartype_title
    }
}`;

const SYSTEM_VARIABLES_QUERY = gql`
query SystemVariablesBySysVarTypeId($isArchived:Boolean!, $sysVarTypeId: String!){
    systemVariablesBySysVarTypeId(isArchived:$isArchived, sysVarTypeId: $sysVarTypeId) {
        _id
        sysvar_title
    }
}`;

const AddEditPerformanceStandards = (props) => {
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
    const [showHelp, setShowHelp] = useState(false);
    const [criticality, setCriticality] = useState([]);
    const [seceName, setSeceName] = useState("N/A");
    const [seceGroup, setSeceGroup] = useState("N/A");
    const [barrier, setBarrier] = useState("N/A");
    const [drDnInstallation, setDrDnInstallation] = useState({});
    
    const [formInitValues, setFormInitValues] = useState({
           dh_id: "",
           ps_ref: "",
           ps_title: "",
           ps_desc: "",
           ps_criteria: "",
           ag_id: "",
           installation_id: "",
           bar_id: "",
           sece_id: "",
           ps_standard_basis: "[]",
           ps_source: "",
           ps_assurance: "",
           ps_attachments: "[]",
           ps_scope: "",
           ps_criticality: ""
    });

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);
    const history = useHistory();

    const { formMode, id, assetGroupId, installationId, barrierId, seceId, mahId, raId, psId } = useParams();
    const { match: {url = ""}} = props ? props : {};

    const [sece, { data: seceData }] = useLazyQuery(SECE_QUERY);
    const [performanceStandard, { data: psData }] = useLazyQuery(PS_QUERY);
    const [createPerformanceStandard, {loading: loadingCreatePs, error: errorCreatePs, data: createPerformanceStandardData }] = useMutation(CREATE_PERFORMANCE_STANDARD);
    const [updatePerformanceStandard, {loading: loadingUpdatePs, error: errorUpdatePs, data: updatePerformanceStandardData }] = useMutation(UPDATE_PERFORMANCE_STANDARD);
    const [systemVariableTypes, { data: sysVarTypesData }] = useLazyQuery(SYSTEM_VARIABLES_TYPE_QUERY);
    const [systemVariablesBySysVarTypeId, { data: sysVarsData }] = useLazyQuery(SYSTEM_VARIABLES_QUERY);
    
    useEffect(() => {
        systemVariableTypes({ variables: { isArchived: false }, errorPolicy: 'all' });
        if (seceId && seceId !=="") {
            sece({ variables: { seceId: seceId }, errorPolicy: 'all' });
        }
        if (formMode === "edit" && psId && psId !=="") {
            performanceStandard({ variables: { psId: psId }, errorPolicy: 'all' });
        }
    }, []); 

    useEffect(() => {
        if (authContext && authContext.user) {
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        const { installation } = navContext;
        const { id: prevId } = drDnInstallation;

        if (prevId && installation.id && prevId !== installation.id) {
            history.push('/');
        }
        setDrDnInstallation(installation);
      }, [navContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);
    
    useEffect(() => {
        if (seceData && seceData.sece) {
            let tempSeceData = seceData.sece;
            if(formMode === 'add'){
                let barrierId = tempSeceData.bar_id ? tempSeceData.bar_id._id : BARRIER_NULL;
                let assetGroupId = tempSeceData.ag_id ? tempSeceData.ag_id._id : ASSETGROUP_NULL;
                let installationId = tempSeceData.installation_id ? tempSeceData.installation_id._id : "";
                let tempInitValues = {
                    dh_id: id,
                    ag_id: assetGroupId,
                    installation_id: installationId,
                    bar_id: barrierId,
                    sece_id: seceId,
                }
                setFormInitValues({ ...formInitValues, ...tempInitValues })
            } 

            let barrier_title = tempSeceData.bar_id && tempSeceData.bar_id.bar_title ? tempSeceData.bar_id.bar_title : "N/A";
            let sece_name = tempSeceData.sece_title ? tempSeceData.sece_title : "N/A";
            let sece_group = tempSeceData.sece_group ? tempSeceData.sece_group : "N/A";

            setBarrier(barrier_title);
            setSeceName(sece_name);
            setSeceGroup(sece_group);
        }
    }, [seceData]);

    useEffect(() => {
        if (sysVarTypesData && sysVarTypesData.systemVariableTypes) {
            let sysVarTypeId = sysVarTypesData.systemVariableTypes.find(sys => sys.sysvartype_title === "Criticality / Finding Level")
            if(sysVarTypeId && sysVarTypeId._id){
                systemVariablesBySysVarTypeId({ variables: {isArchived:false, sysVarTypeId:sysVarTypeId._id}, errorPolicy: 'all'});
            }
        }
    }, [sysVarTypesData]);

    useEffect(() => {
        if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeId) {
            let criticalityData = [];
            sysVarsData.systemVariablesBySysVarTypeId.forEach(c => {
               let temVal = {id:c._id, title:c.sysvar_title};
               criticalityData.push(temVal);
            });
            setCriticality(criticalityData)
        }
    }, [sysVarsData]);

    useEffect(() => {

        if (psData && formMode === 'edit') {
            let perfStanData = psData.performanceStandard;

            var barrier_id;
            if (perfStanData.bar_id === null) {
                barrier_id = BARRIER_NULL;
            } else {
                barrier_id = perfStanData.bar_id._id;
            }
            var asset_id;
            if (perfStanData.ag_id === null) {
                asset_id = ASSETGROUP_NULL;
            } else {
                asset_id = perfStanData.ag_id._id;
            }

            setFormInitValues({
                dh_id: perfStanData.dh_id._id,
                ps_ref: perfStanData.ps_ref,
                ps_title: perfStanData.ps_title,
                ps_desc: perfStanData.ps_desc,
                ps_criteria: perfStanData.ps_criteria,
                ag_id: asset_id,
                installation_id: perfStanData.installation_id._id,
                bar_id: barrier_id,
                sece_id: perfStanData.sece_id._id,
                ps_standard_basis: perfStanData.ps_standard_basis && perfStanData.ps_standard_basis !== "" ? perfStanData.ps_standard_basis : "[]",
                ps_source: perfStanData.ps_source,
                ps_scope: perfStanData.ps_scope, 
                ps_assurance: perfStanData.ps_assurance,
                ps_criticality: perfStanData.ps_criticality,
                ps_attachments: perfStanData.ps_attachments && perfStanData.ps_attachments !== "" ? perfStanData.ps_attachments : "[]",
            })
        }
    }, [psData]);

    useEffect(() => {
        if (createPerformanceStandardData) {
            const {success, message} = createPerformanceStandardData.createPerformanceStandard;
            if (!success) {
                alert(message);
            }
            if (success) {
                if(assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && raId === undefined){
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standards`);
                }
                if(assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && raId === undefined){
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standards`);
                }
            } 
        }
    }, [createPerformanceStandardData]);

    useEffect(() => {
        if (updatePerformanceStandardData) {
            const {success, message} = updatePerformanceStandardData.updatePerformanceStandard;
            if (!success) {
                alert(message);
            }
            if (success) {
                if(assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && raId === undefined){
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standards`);
                }
                if(assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && raId === undefined){
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standards`);
                }
            }      
        }
    }, [updatePerformanceStandardData]);

    let tempStandardBasisFileList = [];
    const handleStandardBasisFilesDescription = (item) => {
        let index = tempStandardBasisFileList.findIndex(f => f.name === item.name);
        if (index >= 0) {
            tempStandardBasisFileList[index] = item;
        }else{
            tempStandardBasisFileList.push(item);
        }
    };

    let tempPsFileList = [];
    const handlePsFilesDescription = (item) => {
        let index = tempPsFileList.findIndex(f => f.name === item.name);
        if (index >= 0) {
            tempPsFileList[index] = item;
        }else{
            tempPsFileList.push(item);
        }
    };

    if (loadingCreatePs || loadingUpdatePs) {
        return (
            <PageSpinner displayText={"submitting form"}/>
        )
    }

    if (errorCreatePs || errorUpdatePs ) {
        return (
            <span>Something went wrong saving performance standard</span>
        )
    }

    return(
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Performance Standard <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>  

                {showHelp &&
                <div>
                    <p>Enter the details into the required fields, including adding attachments by clicking on the two 'Attach Files'.</p>
                    <p>Once complete, click '<span className="capitalize">{formMode}</span> Performance Standard' which will initiate a notification for review.</p>
                </div>
                }
                         
            </div>

            <Formik 
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={Validation}
                onSubmit={(values, actions) => {
                    const { _id: userId = "" } = authContext.user;
                    let tempValues = { ...values };

                    tempValues.ps_standard_basis = updateAttachmentData(tempValues.ps_standard_basis, tempStandardBasisFileList);
                    tempValues.ps_attachments = updateAttachmentData(tempValues.ps_attachments, tempPsFileList);

                    if (formMode === 'add') {
                        tempValues.ps_added_id = userId;
                        tempValues.ps_base_path = removeLastNthParamFrmUrl(url, 1);
                        createPerformanceStandard({ variables: { ps: tempValues } })
                    }

                    if (formMode === 'edit') {
                        tempValues.ps_base_path = removeLastNthParamFrmUrl(url, 2);
                        updatePerformanceStandard({ variables: { id: psId, userId: userId, ps: tempValues } })
                    }
                }}
            >
            {({
                values, 
                errors, 
                touched, 
                handleChange, 
                handleBlur, 
                setFieldValue, 
                setFieldTouched,
                handleSubmit, 
                isSubmitting
            }) => (
                <form onSubmit={handleSubmit}>

                    <div className="my-4">
                        <button type="button" onClick={() => {window.history.back()}} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>    
                    </div>

                    <div className="my-4">
                        <label htmlFor="barrier" className="block mb-1 text-blue-900 font-semibold">Barrier</label>
                        <input 
                            type="text" 
                            readOnly
                            disabled
                            className="form-control block w-full md:w-1/2 bg-gray-100" 
                            name="barrier"         
                            id="barrier" 
                            value={barrier} 
                        />
                    </div> 

                    <div className="my-4">
                        <label htmlFor="sece_name" className="block mb-1 text-blue-900 font-semibold">SECE Name</label>
                        <input 
                            type="text" 
                            readOnly
                            disabled
                            className="form-control block w-full md:w-1/2 bg-gray-100" 
                            name="sece_name"         
                            id="sece_name" 
                            value={seceName}
                        />
                    </div> 

                    <div className="my-4">
                        <label htmlFor="sece_group" className="block mb-1 text-blue-900 font-semibold">SECE Group</label>
                        <input 
                            type="text"
                            readOnly
                            disabled 
                            className="form-control block w-full md:w-1/2 bg-gray-100" 
                            name="sece_group"         
                            id="sece_group" 
                            value={seceGroup} 
                        />
                    </div> 

                    <div>
                        <label htmlFor="ps_ref" className="block mb-1 text-blue-900 font-semibold">Client Ref.*</label>
                        <input 
                            type="text" 
                            className="form-control block w-full md:w-1/2" 
                            name="ps_ref"         
                            id="ps_ref" 
                            placeholder="Ref."
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ps_ref} 
                        />
                        <FormError touched={touched.ps_ref} message={errors.ps_ref} />
                    </div>  

                    <div>
                        <label htmlFor="ps_title" className="block mb-1 text-blue-900 font-semibold">Name*</label>
                        <input 
                            type="text" 
                            className="form-control block w-full md:w-1/2" 
                            name="ps_title"         
                            id="ps_title" 
                            placeholder="Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ps_title} 
                        />
                        <FormError touched={touched.ps_title} message={errors.ps_title} />
                    </div>    

                    <div className="mb-4">
                        <label htmlFor="ps_desc" className="block mb-1 text-blue-900 font-semibold">Function</label>
                        <TextEditor 
                            name="ps_desc" 
                            id="ps_desc" 
                            onChange={desc => setFieldValue('ps_desc', desc)}
                            onBlur={touched => setFieldTouched('ps_desc', touched)}
                            value={values.ps_desc}
                            wrapper="w-full md:w-1/2"
                        />    
                    </div>

                    <div>
                        <label htmlFor="ps_criteria" className="block mb-1 text-blue-900 font-semibold">Criteria*</label>
                        <TextEditor 
                            name="ps_criteria" 
                            id="ps_criteria" 
                            onChange={crit => setFieldValue('ps_criteria', crit)}
                            onBlur={touched => setFieldTouched('ps_criteria', touched)}
                            value={values.ps_criteria}
                            wrapper="w-full md:w-1/2"
                        />
                        <FormError touched={touched.ps_criteria} message={errors.ps_criteria} />
                    </div>

                    <div>
                        <label htmlFor="ps_source" className="block mb-1 text-blue-900 font-semibold">Source</label>
                        <TextEditor 
                            name="ps_source" 
                            id="ps_source" 
                            onChange={source => setFieldValue('ps_source', source)}
                            onBlur={touched => setFieldTouched('ps_source', touched)}
                            value={values.ps_source}
                            wrapper="w-full md:w-1/2"
                        />
                        <FormError touched={touched.ps_source} message={errors.ps_source} />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="ps_standard_basis" className="block mb-1 text-blue-900 font-semibold">Standard Basis</label>

                        {authContext.user && authContext.user.user_home_dir &&
                            <FileUpload
                              onAttach={(files) => setFieldValue('ps_standard_basis', JSON.stringify(files))}
                              id='fileManager'
                              buttonText='Attach Files'
                              apiURL={REACT_APP_FILE_MANAGER_API}
                              toolbarText='Attach'
                              homeDir={`/${authContext.user.user_home_dir}/`}
                              showDescription = {true}
                              handleFileDescription = {handleStandardBasisFilesDescription}
                              tempFileList ={tempStandardBasisFileList}
                              {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.ps_standard_basis) ? parseJSONSafely(values.ps_standard_basis) : [] })}
                            />
                        }
                    </div>  

                    <div className="mb-4">
                        <label htmlFor="ps_scope" className="block mb-1 text-blue-900 font-semibold">Scope / Boundary*</label>
                        <TextEditor 
                            name="ps_scope" 
                            id="ps_scope" 
                            onChange={scope => setFieldValue('ps_scope', scope)}
                            onBlur={touched => setFieldTouched('ps_scope', touched)}
                            value={values.ps_scope}
                            wrapper="w-full md:w-1/2"
                        />
                    </div>

                    <div>
                        <label htmlFor="ps_assurance" className="block mb-1 text-blue-900 font-semibold">Assurance</label>
                        <TextEditor 
                            name="ps_assurance" 
                            id="ps_assurance" 
                            onChange={assurance => setFieldValue('ps_assurance', assurance)}
                            onBlur={touched => setFieldTouched('ps_assurance', touched)}
                            value={values.ps_assurance}
                            wrapper="w-full md:w-1/2"
                        />
                        <FormError touched={touched.ps_assurance} message={errors.ps_assurance} />
                    </div>

                    <div>
                        <label htmlFor="ps_criticality" className="block mb-1 text-blue-900 font-semibold">Criticality*</label>
                        <select 
                            className="form-control block w-full md:w-1/2" 
                            name="ps_criticality" 
                            id="ps_criticality"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ps_criticality} 
                        >
                            <option value = {""}>Select Criticality</option>
                            {criticality.map(crit => 
                               <option value={crit.title} key={crit.id}>{crit.title}</option>
                            )}
                        </select>
                        <FormError touched={touched.ps_criticality} message={errors.ps_criticality} />
                    </div>

                    <div>
                        <label htmlFor="ps_attachments" className="block mb-1 text-blue-900 font-semibold">Additional Attachments</label>

                        {authContext.user && authContext.user.user_home_dir &&
                            <FileUpload
                              onAttach={(files) => setFieldValue('ps_attachments', JSON.stringify(files))}
                              id='fileManager'
                              buttonText='Attach Files'
                              apiURL={REACT_APP_FILE_MANAGER_API}
                              toolbarText='Attach'
                              homeDir={`/${authContext.user.user_home_dir}/`}
                              showDescription = {true}
                              handleFileDescription = {handlePsFilesDescription}
                              tempFileList ={tempPsFileList}
                              {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.ps_attachments) ? parseJSONSafely(values.ps_attachments) : [] })}
                            />
                        }
                        <FormError touched={touched.ps_attachments} message={errors.ps_attachments} />
                    </div> 

                    <button type="submit" className="mt-3 button-red capitalize">{formMode} Performance Standard</button>

                </form>
                )}
            </Formik>

        </div>
    )

}
export default AddEditPerformanceStandards;
