import React from 'react';
import {useQuery} from '@apollo/client';

const Select = (props) => {
  const {title, query, name, onChange, onBlur, value, dataKey, accessor, customClassName, multiSelect, queryOptions, disabled, ariaLabel} = props;
  const {loading, data} = useQuery(query, queryOptions);

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
  }

  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  }

  return (
    <select
      disabled = {disabled}
      className={customClassName ? customClassName : 'form-control block w-full'}
      name={name}
      id={name}
      aria-label={ariaLabel}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      {...(multiSelect ? {multiple: true} : {})}
    >
      <option value={''}>{loading ? 'Loading...' : title}</option>
      {
        data && data[dataKey] && data[dataKey].map((item) => <option key={item._id}
                                                                     value={item._id}>{item[accessor]}</option>)
      }
    </select>
  )

}

export default Select;

