/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {omit} from 'lodash';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";

const validation = (catByCodeCount) => {
    const validation = Yup.object().shape({
        cat_code_exist: Yup.boolean().default(catByCodeCount > 0),
        comp_cat_code: Yup.string()
        .matches(/^[A-Z]{3}$/, 'Must be exactly 3 Uppercase Characters')
        .label("comp_cat_code")
        .required("Code is required")
        .when('cat_code_exist', {
                is: true,
                then: Yup.string().test("comp_cat_code", "Code already exists", function (value) {
                    const { path, createError } = this;
                    return createError({ path, message: "Code already exists" });
                }),
            }),
        comp_cat_title: Yup.string().label("comp_cat_title").required("Name is required"),
    })
    return validation;
}

const CREATE_COMPETENCYCATEGORY = gql`
    mutation CreateCompetencyCategory($compCat: CreateCompetencyCategory!) {
        createCompetencyCategory(compCat: $compCat) {
            _id
            comp_cat_code
            comp_cat_title
            comp_cat_notes
            success
            status_code
            message
        }
    }
`;

const UPDATE_COMPETENCYCATEGORY = gql`
    mutation UpdateCompetencyCategory($compCatId:String!, $userId:String, $compCat:UpdateCompetencyCategoryInput!) {
        updateCompetencyCategory(_id: $compCatId, userId:$userId, compCat: $compCat) {
            comp_cat_code
            comp_cat_title
            comp_cat_notes
            success
            status_code
            message
        }
    }
`;

const COMPETENCYCATEGORY_QUERY = gql`
query CompetencyCategory($compCatId: ID!){
    competencyCategory(_id: $compCatId) {
        comp_cat_code
        comp_cat_title
        comp_cat_notes
    }
}
`;

const CATEGORIES_BY_CODE_QUERY = gql`
query CategoriesByCode($code: String!){
    categoriesByCode(code: $code)
}`;

const AddEditCompetencyCat = (props) => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [showHelp, setShowHelp] = useState(false);

    const [catByCodeCount, setCatByCodeCount] = useState(undefined);
    const [enteredCode, setEnteredCode] = useState(undefined);

    const [formInitValues, setFormInitValues] = useState({
        comp_cat_code: "",
        comp_cat_title: "",
        comp_cat_notes: ""
    });

    const { formMode, compCatId = "" } = useParams();
    const { location: { state = "" } } = props ? props : {};

    const [competencyCategory, { data: competencyCategoryData }] = useLazyQuery(COMPETENCYCATEGORY_QUERY);
    const [categoriesByCode, { data: categoriesByCodeData }] = useLazyQuery(CATEGORIES_BY_CODE_QUERY);

    const [createCompetencyCategory, { data: createCompetencyCategoryData }] = useMutation(CREATE_COMPETENCYCATEGORY);
    const [updateCompetencyCategory, { data: updateCompetencyCategoryData }] = useMutation(UPDATE_COMPETENCYCATEGORY);

    useEffect(() => {
        if (authContext && authContext.user){
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);
       
    useEffect(() => {
        if (formMode === "edit" && compCatId !== "") {
            competencyCategory({ variables: { compCatId }, errorPolicy: 'all' });
        }
    }, [])

    useEffect(() => {
        if (competencyCategoryData && competencyCategoryData.competencyCategory) {
            setFormInitValues(competencyCategoryData.competencyCategory)
        }

    }, [competencyCategoryData])

    useEffect(() => {
        if (categoriesByCodeData) {
            if (formMode === "edit" && enteredCode === formInitValues.comp_cat_code) {
                setCatByCodeCount(0);
            } else {
                setCatByCodeCount(categoriesByCodeData.categoriesByCode);
            }
        }
    }, [categoriesByCodeData]);

    useEffect(() => {
        if (createCompetencyCategoryData && createCompetencyCategoryData.createCompetencyCategory) {
            const { success, message } = createCompetencyCategoryData.createCompetencyCategory;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push('/user-competency/competencies/competency-cats');
            }
        }
    }, [createCompetencyCategoryData, history]);

    useEffect(() => {
        if (updateCompetencyCategoryData && updateCompetencyCategoryData.updateCompetencyCategory) {
            const { success, message } = updateCompetencyCategoryData.updateCompetencyCategory;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push('/user-competency/competencies/competency-cats');
            }      
        }
    }, [updateCompetencyCategoryData, history]);

    const displayBackLink = (state) => {
        return (
            <Link to={{
                pathname: "/user-competency/competencies/competency-cats" + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    }

    const handleCatCodeChange = (event) => {
        let code = event.target.value;
        setEnteredCode(code);
        categoriesByCode({ variables: { code: code }, errorPolicy: 'all' });
    }

    return (
        <div className="w-full px-8 pb-8">
            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Competency Category <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                <div>
                    <p>Enter the details into the required fields.</p>
                    <p>Once complete, click '<span className="capitalize">{formMode}</span> Competency Category'.</p>
                </div>
                }                
            </div>

            <Formik
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={validation(catByCodeCount)}
                onSubmit={(values, actions) => {
                    const { _id: userId = "" } = authContext.user;

                    if (formMode === 'add') {
                        const tempValues = { ...values };
                        tempValues.comp_cat_added_id = userId;
                        tempValues.comp_cat_added_date = new Date();
                        createCompetencyCategory({ variables: { compCat: tempValues } })
                    }

                    if (formMode === 'edit') {
                        updateCompetencyCategory({ variables: { compCatId, userId: userId, compCat: values } })
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="my-4">
                            {displayBackLink(state)}
                        </div>

                        <div>
                            <label htmlFor="comp_cat_code" className="block mb-1 text-blue-900 font-semibold">Code (3 letters)*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="comp_cat_code"
                                id="comp_cat_code"
                                placeholder="Code"
                                onChange={(event) => { handleCatCodeChange(event); handleChange(event) }}
                                onBlur={handleBlur}
                                value={values.comp_cat_code}
                            />
                            <FormError touched={touched.comp_cat_code} message={errors.comp_cat_code} />
                        </div>

                        <div>
                            <label htmlFor="comp_cat_title" className="block mb-1 text-blue-900 font-semibold">Name*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="comp_cat_title"
                                id="comp_cat_title"
                                placeholder="Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.comp_cat_title}
                            />
                            <FormError touched={touched.comp_cat_title} message={errors.comp_cat_title} />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="comp_cat_notes" className="block mb-1 text-blue-900 font-semibold">Notes*</label>
                            <textarea
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="comp_cat_notes"
                                id="comp_cat_notes"
                                placeholder="Notes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.comp_cat_notes}
                            />
                        </div>

                        <button type="submit" className="mt-3 button-red capitalize">{formMode} Competency Category</button>

                    </form>
                )}
            </Formik>

        </div>
    )

}

export default AddEditCompetencyCat;
