import React from "react";

const FormError = ({ touched, message }) => {
  if (!touched) {
    return<small className="mb-0">&nbsp;</small>;
  }
  if (message) {
    return <small className="mb-0 text-red-900">{message}</small>;
  }
  return <small className="mb-0 text-green-900">&nbsp;</small>;
};

export default FormError;