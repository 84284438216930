import React from 'react';

const AccessPermissions = (props) => {

  return (
    <div className="w-full px-8 pb-8 wide-table">
      <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Access Denied</h1>
      <p className="mt-3">You don't have permissions to access this page.</p>
    </div>
  )
}

export default AccessPermissions;