/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Pie, Bar, HorizontalBar } from 'react-chartjs-2';
import { Link, useHistory} from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
import {} from 'lodash';
import * as d3 from "d3";
import * as venn from "venn.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import ErrorBoundary from '../global/ErrorBoundary';
import {AuthContext} from "../../context/authContext";
import {NavContext} from '../../context/navContext';
import {createUserTypeAndRoleObject, formatDate} from "../../utils/index";
import {DH_FINDING_TYPE, INST_FINDING_TYPE} from "../../constants";

export const FindingIssues = (props) => {
  const [fndByIssueList, setFndByIssueList] = useState([]);

  const [setList, setSetList] = useState([]);
  const { data } = props;

  let history = useHistory();

  useEffect(() => {
    setFndByIssueList(data);
  }, [data]);

  useEffect(() => {
    if (fndByIssueList && fndByIssueList.length) {
      let vennList = createVennSets(fndByIssueList);
      setSetList(vennList);
    }
  }, [fndByIssueList]);

  useEffect(() => {
    if (setList && setList.length > 0) {

      let chart = venn.VennDiagram();

      let vennDiv = d3.select("#venn");

      var children =  vennDiv.selectChildren()

      children.nodes().forEach(n => {
        d3.select(n).remove()
      })

      var size = children.size();

      if (size === 0) {
        var div = vennDiv.append("div");

        vennDiv.append("div")
          .classed("svg-container", true)
          .datum(setList)
          .call(chart);

        d3.select("#venn svg")
          .attr("preserveAspectRatio", "xMinYMin meet")
          .attr("viewBox", "0 0 600 350")
          .classed("svg-content-responsive", true);

        var colours = ['#002A4E', '#626366', '#5E8AB4', '#FFAD0A', '#DA1F33'];
        var sets = ['A', 'B', 'C', 'D', 'E'];

        d3.selectAll("#venn text")
          .style("fill", "#fff")
          .style("font-size", "12px");

        d3.selectAll("#venn .venn-circle path")
          .style("fill", function (d, i) { return colours[sets.indexOf(d.sets[0])] })
          .style("fill-opacity", .8);

        var tooltip = div.attr("class", "venntooltip").style("z-index", 1000);

        d3.selectAll("g")
          .on("mouseover", function (d, i) {
            // Display a tooltip with the current size
            if (i.label) {
              tooltip.transition().duration(400).style("opacity", .9);
              tooltip.text(i.label);
            }
          })
          .on("mousemove", function (x, y) {
            tooltip.style("left", (x.pageX) + "px")
              .style("top", (x.pageY - 28) + "px");
          })
          .on("mouseout", function (d, i) {
            tooltip.transition().duration(400).style("opacity", 0);
          });

        d3.selectAll("#venn .venn-circle path")
          .on("click", function (d, i) {
            let searchObject = {
              issueCode: "",
              issueName: i.label,
              displayName: `Findings By Issue: ${i.label}`
            }
            let search = new URLSearchParams(searchObject).toString()
            let location = {
              pathname: i.link,
              search: search
            }
            history.push(location);
          });
      }
    }
  }, [setList, history]);

  return (
    <div id="venn"></div>
  );
};

const OPEN_FINDINGS_QUERY = gql`
    query FindingStats($instId: ID){
        findingStats(instId: $instId) {
            date {
                labels,
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
            level {
                labels,
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
        }
    }`;

const BARRIER_FINDINGS_QUERY = gql`
    query FindingStatsGroupByBarrier($instId: ID){
        findingStatsGroupByBarrier(instId: $instId) {
            date {
                labels
                values
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
            level {
                labels
                values
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
        }
    }`;

const DH_INST_FINDINGS_QUERY = gql`
    query FindingStatsGroupByDhInst($dhId: ID, $instId: ID){
        findingStatsGroupByDhInst(dhId: $dhId, instId: $instId) {
            date {
                labels
                values
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
            level {
                labels
                values
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
        }
    }`;

const ACTION_PARTY_FINDINGS_QUERY = gql`
    query FindingStatsGroupByActionParty($instId: ID){
        findingStatsGroupByActionParty(instId: $instId)  {
            date {
                labels
                values
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
            level {
                labels
                values
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
        }
    }`;

const EXAM_DATE_FINDINGS_QUERY = gql`
    query FindingStatsGroupByExamDate($instId: ID){
        findingStatsGroupByExamDate(instId: $instId) {
            date {
                labels
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
            level {
                labels
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
        }
    }`;

const NATURE_FINDINGS_QUERY = gql`
    query FindingStatsGroupByNature($instId: ID) {
        findingStatsGroupByNature(instId: $instId) {
            date {
                labels
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
            level {
                labels
                datasets {
                    label           
                    data
                    backgroundColor
                    linkHref
                }
            }
        }
    }`;

const SECE_FINDINGS_QUERY = gql`
    query findingStatsGroupBySece($instId: ID){
        findingStatsGroupBySece(instId: $instId) {
            date {
                labels
                values
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
            level {
                labels
                values
                datasets {
                    label
                    data
                    backgroundColor
                    linkHref
                }
            }
        }
    }`;

const ISSUES_FINDINGS_QUERY = gql`
    query findingStatsGroupByIssues($instId: ID){
        findingStatsGroupByIssues(instId: $instId)  {
            label
            count
            backgroundColor
            linkHref
        }
}`;

const Statistics = (props) => {
  const [showHelp, setShowHelp] = useState(false);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const [drDnDutyholder, setDrDnDutyholder] = useState({});
  const [drDnInstallation, setDrDnInstallation] = useState({});
  const [drDnSece, setDrDnSece] = useState({});

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);

  let history = useHistory();

  const defaultState = {
    date: {datasets: []},
    level: {datasets: []}
  }

  const [openFindingsStats, setOpenFindingsStats] = useState(defaultState);
  const [barrierFindingsStats, setBarrierFindingsStats] = useState(defaultState);
  const [dhInstFindingsStats, setDhInstFindingsStats] = useState(defaultState);
  const [actionPartyFindingsStats, setActionPartyFindingsStats] = useState(defaultState);
  const [examDateFindingsStats, setExamDateFindingsStats] = useState(defaultState);
  const [natureFindingsStats, setNatureFindingsStats] = useState(defaultState);
  const [seceFindingsStats, setSeceFindingsStats] = useState(defaultState);
  const [issuesFindingsStats, setIssuesFindingsStats] = useState([]);
  const [issuesFindingsStatsBarChart, setIssuesFindingsStatsBarChart] = useState({});

  const [toggleOpenFindingsStats, setToggleOpenFindingsStats] = useState(false);
  const [toggleBarrierFindingsStats, setToggleBarrierFindingsStats] = useState(false);
  const [toggleActionPartyFindingsStats, setToggleActionPartyFindingsStats] = useState(false);
  const [toggleExamDateFindingsStats, setToggleExamDateFindingsStats] = useState(false);
  const [toggleNatureFindingsStats, setToggleNatureFindingsStats] = useState(false);
  const [toggleIssueFindingsStats, setToggleIssueFindingsStats] = useState(false);
  const [toggleSeceFindingsStats, setToggleSeceFindingsStats] = useState(false);
  const [toggleDhInstFindings, setToggleDhInstFindings] = useState(false);

  const [findingStats, {loading: openFindingsLoading, error: openFindingsError, data: openFindingsData}] = useLazyQuery(OPEN_FINDINGS_QUERY);
  const [findingStatsGroupByBarrier, {loading: barrierFindingsLoading, error: barrierFindingsError, data: barrierFindingsData}] = useLazyQuery(BARRIER_FINDINGS_QUERY);
  const [findingStatsGroupByDhInst, {loading: dhInstFindingsLoading, error: dhInstFindingsError, data: dhInstFindingsData}] = useLazyQuery(DH_INST_FINDINGS_QUERY);
  const [findingStatsGroupByActionParty, {loading: actionPartyFindingsLoading, error: actionPartyFindingsError, data: actionPartyFindingsData}] = useLazyQuery(ACTION_PARTY_FINDINGS_QUERY);
  const [findingStatsGroupByExamDate, {loading: examDateFindingsLoading, error: examDateFindingsError, data: examDateFindingsData}] = useLazyQuery(EXAM_DATE_FINDINGS_QUERY);
  const [findingStatsGroupByNature, {loading: natureFindingsLoading, error: natureFindingsError, data: natureFindingsData}] = useLazyQuery(NATURE_FINDINGS_QUERY);
  const [findingStatsGroupBySece , {loading: seceFindingsLoading, error: seceFindingsError, data: seceFindingsData}] = useLazyQuery(SECE_FINDINGS_QUERY);
  const [findingStatsGroupByIssues, {loading: issuesFindingsLoading, error: issuesFindingsError, data: issuesFindingsData}] = useLazyQuery(ISSUES_FINDINGS_QUERY);

  useEffect(() => {
    findingStats({ variables: {}, errorPolicy: 'all' });
    findingStatsGroupByBarrier({ variables: {}, errorPolicy: 'all' });
    findingStatsGroupByDhInst({ variables: {}, errorPolicy: 'all' });
    findingStatsGroupByActionParty({ variables: {}, errorPolicy: 'all' });
    findingStatsGroupByExamDate({ variables: {}, errorPolicy: 'all' });
    findingStatsGroupByNature({ variables: {}, errorPolicy: 'all' });
    findingStatsGroupBySece({ variables: {}, errorPolicy: 'all' });
    findingStatsGroupByIssues({ variables: {}, errorPolicy: 'all' });
  }, []);

  useEffect(() => {
    if (authContext && authContext.user) {
        let result = createUserTypeAndRoleObject(authContext);
        setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    const { dutyHolder, installation, sece } = navContext;
    setDrDnDutyholder(dutyHolder);
    setDrDnInstallation(installation);
    setDrDnSece(sece);

  }, [navContext]);

  useEffect(() => {
    if (drDnInstallation) {
      let dhIdParam = { ...(drDnDutyholder.id && { dhId: drDnDutyholder.id }) };
      let instIdParam = { ...(drDnInstallation.id && { instId: drDnInstallation.id }) };

      findingStats({ variables: { ...instIdParam }, errorPolicy: 'all' });
      findingStatsGroupByBarrier({ variables: { ...instIdParam }, errorPolicy: 'all' });
      findingStatsGroupByDhInst({ variables: { ...dhIdParam, ...instIdParam }, errorPolicy: 'all' });
      findingStatsGroupByActionParty({ variables: { ...instIdParam }, errorPolicy: 'all' });
      findingStatsGroupByExamDate({ variables: { ...instIdParam }, errorPolicy: 'all' });
      findingStatsGroupByNature({ variables: { ...instIdParam }, errorPolicy: 'all' });
      findingStatsGroupBySece({ variables: { ...instIdParam }, errorPolicy: 'all' });
      findingStatsGroupByIssues({ variables: { ...instIdParam }, errorPolicy: 'all' });
    }
  }, [drDnDutyholder, drDnInstallation]);

  useEffect(() => {
    if (openFindingsData && openFindingsData.findingStats) {
      setOpenFindingsStats({
        date: openFindingsData.findingStats.date,
        level: openFindingsData.findingStats.level,
      })
    }
  }, [openFindingsData]);

  useEffect(() => {
    if (barrierFindingsData && barrierFindingsData.findingStatsGroupByBarrier) {
      setBarrierFindingsStats({
        date: barrierFindingsData.findingStatsGroupByBarrier.date,
        level: barrierFindingsData.findingStatsGroupByBarrier.level,
      })
    }
  }, [barrierFindingsData]);

  useEffect(() => {
    if (dhInstFindingsData && dhInstFindingsData.findingStatsGroupByDhInst) {
      setDhInstFindingsStats({
        date: dhInstFindingsData.findingStatsGroupByDhInst.date,
        level: dhInstFindingsData.findingStatsGroupByDhInst.level,
      })
    }
  }, [dhInstFindingsData]);

  useEffect(() => {
    if (actionPartyFindingsData && actionPartyFindingsData.findingStatsGroupByActionParty) {
      setActionPartyFindingsStats({
        date: actionPartyFindingsData.findingStatsGroupByActionParty.date,
        level: actionPartyFindingsData.findingStatsGroupByActionParty.level,
      })
    }
  }, [actionPartyFindingsData]);

  useEffect(() => {
    if (examDateFindingsData && examDateFindingsData.findingStatsGroupByExamDate) {
      setExamDateFindingsStats({
        date: examDateFindingsData.findingStatsGroupByExamDate.date,
        level: examDateFindingsData.findingStatsGroupByExamDate.level,
      })
    }
  }, [examDateFindingsData]);

  useEffect(() => {
    if (natureFindingsData && natureFindingsData.findingStatsGroupByNature) {
      setNatureFindingsStats({
        date: natureFindingsData.findingStatsGroupByNature.date,
        level: natureFindingsData.findingStatsGroupByNature.level,
      })
    }
  }, [natureFindingsData]);

  useEffect(() => {
    if(seceFindingsData && seceFindingsData.findingStatsGroupBySece) {
      setSeceFindingsStats({
        date: seceFindingsData.findingStatsGroupBySece.date,
        level: seceFindingsData.findingStatsGroupBySece.level,
      })
    }
  }, [seceFindingsData]);

  useEffect(() => {

    if (issuesFindingsData && issuesFindingsData.findingStatsGroupByIssues) {
      const findingsByIssueData = issuesFindingsData.findingStatsGroupByIssues;
      let filteredList = filterFindingsByIssue(findingsByIssueData);
      setIssuesFindingsStats(filteredList)
      let findingsByIssueBarChart = createDataForFindingsByIssueBarChart(findingsByIssueData);
      setIssuesFindingsStatsBarChart(findingsByIssueBarChart);
    }

  }, [issuesFindingsData]);

  return(
    <div className="w-full px-8 pb-8 wide-table">
      <div className="pb-6 border-b-2 border-gray-200">
        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Statistics <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>
        {showHelp &&
          <div>
            <p className="mt-3 mb-3">Charts allowing assessment, against a range of parameters, of open Findings relating to the Duty Holder and / or Installation selected in the drop-down menu.  Use the toggle switch to between Finding Status and Level.</p>

            <p>The Findings by issue chart can be presented as a Venn diagram (showing quantities of legislative, integrity and paperwork / process issues) or a bar chart, breaking down types of cumulative issues.</p>
          </div>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>
      
      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "ABS" && 
        drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Statistics- Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "Duty Holder" && 
        drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Statistics- Installation: {drDnInstallation.name}</h6>}
      {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Statistics- Duty Holder / Installation : All</h6>}
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

        <div className="bg-gray-100">

          <div className="flex flex-col h-full">

            <div className="p-4">

              <div className="flex flex-row justify-between">
                <h3 className="mb-3 font-semibold text-blue-900 uppercase">All Open Findings</h3>

                <div>
                  <div className="text-xs text-gray-900 inline">Status</div>
                  <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                    <input type="checkbox" className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer" id="AllOpenFindings" name="AllOpenFindings" onChange={() => setToggleOpenFindingsStats(!toggleOpenFindingsStats)} checked={toggleOpenFindingsStats} />
                    <label htmlFor="AllOpenFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                  </div>
                  <div className="text-xs text-gray-900 inline">Level</div>
                </div>
              </div>

              {openFindingsLoading && (
                <PageSpinner/>
              )}

              {openFindingsError && (
                <span>Something went wrong retrieving the data</span>
              )}

              {!openFindingsLoading && !openFindingsError && toggleOpenFindingsStats === false && openFindingsStats.date.datasets.length &&
                <ErrorBoundary>
                  <Pie
                    data={openFindingsStats.date}
                    options={{
                      title: { display: false, text: 'All Open Findings', fontColor: '#002a4e' },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let statusKey = element[0]._chart.data.labels[element[0]._index];
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: `completionStatus=${statusKey}&displayName=Open Findings By Status: ${statusKey}&findingStatus=open`
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary>      
              }

              {!openFindingsLoading && !openFindingsError && toggleOpenFindingsStats === true && openFindingsStats.level.datasets.length &&
                <ErrorBoundary>
                  <Pie
                    data={openFindingsStats.level}
                    options={{
                      title: { display: false, text: 'All Open Findings', fontColor: '#002a4e' },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let fndKey = element[0]._chart.data.labels[element[0]._index];
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: `level=${fndKey}&displayName=Open Findings By Level: ${fndKey}&findingStatus=open`
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary>     
              }
            </div>

          </div>

        </div>

        <div className="bg-gray-100">

          <div className="flex flex-col h-full">

            <div className="p-4">

              <div className="flex flex-row justify-between">
                <h3 className="font-semibold text-blue-900 uppercase">Open Findings by Barrier</h3>

                <div>
                  <div className="text-xs text-gray-900 inline">Status</div>
                  <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                    <input type="checkbox" className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer" id="BarrierFindings" name="BarrierFindings" onChange={() => setToggleBarrierFindingsStats(!toggleBarrierFindingsStats)} checked={toggleBarrierFindingsStats} />
                    <label htmlFor="BarrierFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                  </div>
                  <div className="text-xs text-gray-900 inline">Level</div>
                </div>
              </div>

              {barrierFindingsLoading && (
                <PageSpinner/>
              )}
              {barrierFindingsError && (
                <span>Something went wrong retrieving the data</span>
              )}

              {!barrierFindingsLoading && !barrierFindingsError && toggleBarrierFindingsStats === false && barrierFindingsStats.date.datasets.length &&
                <ErrorBoundary>
                  <Bar
                    data={barrierFindingsStats.date}
                    options={{
                      scales: {
                        xAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }],
                        yAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }]
                      },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let bar = this.getElementAtEvent(evt)[0];
                          let index = bar._index;
                          let datasetIndex = bar._datasetIndex;

                          const { date: { values = [] } = {} } = barrierFindingsStats ? barrierFindingsStats : {};
                          let selectedBarId = values[index];

                          let statusKey = element[datasetIndex]._model.datasetLabel;
                          let barrierName = element[datasetIndex]._model.label;

                          let searchObject = {
                            completionStatus: statusKey,
                            //responseStatus: statusKey,
                            barrierTitle: barrierName,
                            barrierId: selectedBarId ? selectedBarId : "",
                            findingStatus: "open",
                            displayName: `Findings By Barrier & Status: ${barrierName} & ${statusKey}`,
                          }
                          let search = new URLSearchParams(searchObject).toString()
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: search
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary> 
              }

              {!barrierFindingsLoading && !barrierFindingsError && toggleBarrierFindingsStats === true  && barrierFindingsStats.level.datasets.length &&
                <ErrorBoundary>
                  <Bar
                    data={barrierFindingsStats.level}
                    options={{
                      scales: {
                        xAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }],
                        yAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }]
                      },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let bar = this.getElementAtEvent(evt)[0];
                          let index = bar._index;
                          let datasetIndex = bar._datasetIndex;

                          const { level: { values = [] } = {} } = barrierFindingsStats ? barrierFindingsStats : {};
                          let selectedBarId = values[index];

                          let levelKey = element[datasetIndex]._model.datasetLabel;
                          let barrierName = element[datasetIndex]._model.label;

                          let searchObject = {
                            level: levelKey,
                            barrierTitle: barrierName,
                            barrierId: selectedBarId ? selectedBarId : "",
                            findingStatus: "open",
                            displayName: `Findings By Barrier & Level: ${barrierName} & ${levelKey}`,
                          };
                          let search = new URLSearchParams(searchObject).toString()
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: search
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary>   
              }
            </div>

          </div>

        </div>

        <div className="bg-gray-100">

          <div className="flex flex-col h-full">

            <div className="p-4">

              <div className="flex flex-row justify-between">
                <h3 className="font-semibold text-blue-900 uppercase">Findings By Action Party</h3>

                <div>
                  <div className="text-xs text-gray-900 inline">Status</div>
                  <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                    <input type="checkbox" className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer" id="ActionPartyFindings" name="ActionPartyFindings" onChange={() => setToggleActionPartyFindingsStats(!toggleActionPartyFindingsStats)} checked={toggleActionPartyFindingsStats} />
                    <label htmlFor="ActionPartyFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                  </div>
                  <div className="text-xs text-gray-900 inline">Level</div>
                </div>
              </div>

              {actionPartyFindingsLoading && (
                <PageSpinner/>
              )}
              {actionPartyFindingsError && (
                <span>Something went wrong retrieving the data</span>
              )}

              {!actionPartyFindingsLoading && !actionPartyFindingsError && toggleActionPartyFindingsStats === false && actionPartyFindingsStats.date.datasets.length &&
                <ErrorBoundary>
                  <Bar
                    data={actionPartyFindingsStats.date}
                    options={{
                      scales: {
                        xAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }],
                        yAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }]
                      },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let bar = this.getElementAtEvent(evt)[0];
                          let index = bar._index;
                          let datasetIndex = bar._datasetIndex;

                          const { date: { values = [] } = {} } = actionPartyFindingsStats ? actionPartyFindingsStats : {};
                          let selectedApId = values[index];

                          let statusKey = element[datasetIndex]._model.datasetLabel;
                          let actionPartyName = element[datasetIndex]._model.label;

                          let searchObject = {
                            responseStatus: statusKey,
                            actionPartyId: selectedApId,
                            findingStatus: "open",
                            displayName: `Findings By Status & Action Party: ${statusKey} & ${actionPartyName}`
                          }
                          let search = new URLSearchParams(searchObject).toString()
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: search
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary>   
              }

              {!actionPartyFindingsLoading && !actionPartyFindingsError && toggleActionPartyFindingsStats=== true  && actionPartyFindingsStats.level.datasets.length &&
                <ErrorBoundary>
                  <Bar
                    data={actionPartyFindingsStats.level}
                    options={{
                      scales: {
                        xAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }],
                        yAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }]
                      },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let bar = this.getElementAtEvent(evt)[0];
                          let index = bar._index;
                          let datasetIndex = bar._datasetIndex;

                          const { level: { values = [] } = {} } = actionPartyFindingsStats ? actionPartyFindingsStats : {};
                          let selectedApId = values[index];

                          let levelKey = element[datasetIndex]._model.datasetLabel;
                          let actionPartyName = element[datasetIndex]._model.label;

                          let searchObject = {
                            level: levelKey,
                            actionPartyId: selectedApId ? selectedApId : "",
                            findingStatus: "open",
                            displayName: `Findings By Level & Action Party: ${levelKey} & ${actionPartyName}`
                          }
                          let search = new URLSearchParams(searchObject).toString()
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: search
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary>
              }
            </div>
          </div>
        </div>

        <div className="bg-gray-100">

          <div className="flex flex-col h-full">

            <div className="p-4">

              <div className="flex flex-row justify-between">
                <h3 className="font-semibold text-blue-900 uppercase">Findings By Exam Date</h3>

                <div>
                  <div className="text-xs text-gray-900 inline">Status</div>
                  <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                    <input type="checkbox"
                      className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer"
                      id="ExamDateFindings"
                      name="ExamDateFindings"
                      onChange={() => setToggleExamDateFindingsStats(!toggleExamDateFindingsStats)}
                      checked={toggleExamDateFindingsStats} />
                    <label htmlFor="ExamDateFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                  </div>
                  <div className="text-xs text-gray-900 inline">Level</div>
                </div>
              </div>

              {examDateFindingsLoading && (
                <PageSpinner/>
              )}

              {examDateFindingsError && (
                <span>Something went wrong retrieving the data</span>
              )}

              {!examDateFindingsLoading && !examDateFindingsError && toggleExamDateFindingsStats === false  && examDateFindingsStats.date.datasets.length &&
                <ErrorBoundary>
                  <Bar
                    data={examDateFindingsStats.date}
                    options={{
                      scales: {
                        xAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }],
                        yAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }]
                      },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let bar = this.getElementAtEvent(evt)[0];
                          //let index = bar._index;
                          let datasetIndex = bar._datasetIndex;

                          let statusKey = element[datasetIndex]._model.datasetLabel;
                          let dateKey = element[datasetIndex]._model.label;

                          let searchObject = {
                            responseStatus: statusKey,
                            examDate: dateKey,
                            findingStatus: "open",
                            displayName: `Findings By Status & Exam Date: ${statusKey} & ${formatDate(dateKey)}`
                          }
                          let search = new URLSearchParams(searchObject).toString()
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: search
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary>  
              }

              {!examDateFindingsLoading && !examDateFindingsError && toggleExamDateFindingsStats === true  && examDateFindingsStats.level.datasets.length &&
                <ErrorBoundary>
                  <Bar
                    data={examDateFindingsStats.level}
                    options={{
                      scales: {
                        xAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }],
                        yAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }]
                      },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let bar = this.getElementAtEvent(evt)[0];
                          //let index = bar._index;
                          let datasetIndex = bar._datasetIndex;

                          let levelKey = element[datasetIndex]._model.datasetLabel;
                          let dateKey = element[datasetIndex]._model.label;

                          let searchObject = {
                            level: levelKey,
                            examDate: dateKey,
                            findingStatus: "open",
                            displayName: `Findings By Level & Exam Date: ${levelKey} & ${formatDate(dateKey)}`
                          }
                          let search = new URLSearchParams(searchObject).toString()
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: search
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary>  
              }
            </div>

          </div>

        </div>

        <div className="bg-gray-100">

          <div className="flex flex-col h-full">

            <div className="p-4">

              <div className="flex flex-row justify-between">
                <h3 className="font-semibold text-blue-900 uppercase">Findings By Nature</h3>

                <div>
                  <div className="text-xs text-gray-900 inline">Status</div>
                  <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                    <input type="checkbox" className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer" id="NatureFindings" name="NatureFindings" 
                    onChange={() => setToggleNatureFindingsStats(!toggleNatureFindingsStats)} 
                    checked={toggleNatureFindingsStats} />
                    <label htmlFor="NatureFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                  </div>
                  <div className="text-xs text-gray-900 inline">Level</div>
                </div>
                
              </div>

              {natureFindingsLoading && (
                <PageSpinner/>
              )}

              {natureFindingsError && (
                <span>Something went wrong retrieving the data</span>
              )}

              {!natureFindingsLoading && !natureFindingsError && toggleNatureFindingsStats === false && natureFindingsStats.date.datasets.length &&
                <ErrorBoundary>
                  <Bar
                    data={natureFindingsStats.date}
                    options={{
                      scales: {
                        xAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }],
                        yAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }]
                      },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let bar = this.getElementAtEvent(evt)[0];
                          //let index = bar._index;
                          let datasetIndex = bar._datasetIndex;

                          let statusKey = element[datasetIndex]._model.datasetLabel;
                          let natureKey = element[datasetIndex]._model.label;

                          let searchObject = {
                            responseStatus: statusKey,
                            nature: natureKey,
                            findingStatus: "open",
                            origin: "Statistic",
                            section: "Findings By Nature",
                            displayName: `Findings By Status & Nature: ${statusKey} & ${natureKey}`,
                          }
                          let search = new URLSearchParams(searchObject).toString()
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: search
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary>  
              }

              {!natureFindingsLoading && !natureFindingsError && toggleNatureFindingsStats === true  && natureFindingsStats.level.datasets.length &&
                <ErrorBoundary>
                  <Bar
                    data={natureFindingsStats.level}
                    options={{
                      scales: {
                        xAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }],
                        yAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }]
                      },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let bar = this.getElementAtEvent(evt)[0];
                          //let index = bar._index;
                          let datasetIndex = bar._datasetIndex;

                          let levelKey = element[datasetIndex]._model.datasetLabel;
                          let natureKey = element[datasetIndex]._model.label;
                          let searchObject = {
                            level: levelKey,
                            nature: natureKey,
                            findingStatus: "open",
                            origin: "Statistic",
                            section: "Findings By Nature",
                            displayName: `Findings By Level & Nature: ${levelKey} & ${natureKey}`,
                          }
                          let search = new URLSearchParams(searchObject).toString()
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: search
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary>
              }
            </div>

          </div>

        </div>

        <div className="bg-gray-100">

          <div className="flex flex-col h-full">

            <div className="p-4">

              <div className="flex flex-row justify-between">
                <h3 className="font-semibold text-blue-900 uppercase pb-4">Findings By Issue</h3>

                <div>
                  <div className="text-xs text-gray-900 inline">Venn Diagram</div>
                  <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                    <input type="checkbox"
                      className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer"
                      id="IssueFindings"
                      key = {"IssueFindings"}
                      name="IssueFindings"
                      onChange={() => setToggleIssueFindingsStats(!toggleIssueFindingsStats)}
                      checked={toggleIssueFindingsStats} />
                    <label htmlFor="IssueFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                  </div>
                  <div className="text-xs text-gray-900 inline">Bar Chart</div>
                </div>
              </div>

              {issuesFindingsLoading && (
                <PageSpinner/>
              )}

              {issuesFindingsError && (
                <span>Something went wrong retrieving the data</span>
              )}

              {!issuesFindingsLoading && !issuesFindingsError && toggleIssueFindingsStats === false && (
                <>
                  <div className="flex">
                    <div className="mt-2 w-full flex flex-row justify-between">
                      {issuesFindingsStats.map((issue, index) => (
                        <Link
                          to={{
                            pathname: `/reporting/findings`,
                            search: ` issueCode=${index}&issueName=${issue.label}&displayName=Findings By Issue: ${issue.label}`,
                          }}
                          key={index}
                        >
                          <div key={issue.label} className={`p-2 text-center ${issue.label === "Paperwork / Process Issue" || issue.label === "Document Update Required" ? "text-black" : "text-white"}`} style={{ backgroundColor: `${issue.backgroundColor}` }}>
                            <span className="block text-sm">{issue.count}</span>
                            <span style={{ fontSize: '0.6rem' }}>{issue.label}</span>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                  <ErrorBoundary>
                    <div className="w-full xl:w-4/5 mx-auto">
                      <FindingIssues data={issuesFindingsStats} key={10} />
                    </div>
                  </ErrorBoundary>
                </>
              )}

              {!issuesFindingsLoading && !issuesFindingsError && toggleIssueFindingsStats === true && (
                <ErrorBoundary>
                  <Bar
                    data={issuesFindingsStatsBarChart}
                    options={{
                      scales: {
                        xAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }],
                        yAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }]
                      },
                      legend: {
                        display: false,
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let issueCode = element[0]._index;
                          let issueName = element[0]._model.label;
                          let searchObject = {
                            issueCode: issueCode,
                            issueName: issueName,
                            displayName: `Findings By Issue: ${issueName}`
                          };
                          let search = new URLSearchParams(searchObject).toString()
                          let location = {
                            pathname: "/reporting/findings",
                            search: search
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary> 
              )}
            </div>

          </div>

        </div>

        <div className="my-4 bg-gray-100">

          <div className="flex flex-col h-full">

            <div className="p-4">

              <div className="flex flex-row justify-between">
                <h3 className="font-semibold text-blue-900 uppercase">Findings By SECE</h3>

                <div>
                  <div className="text-xs text-gray-900 inline">Status</div>
                  <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                    <input type="checkbox" className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer" id="SeceFindings" name="SeceFindings" onChange={() => setToggleSeceFindingsStats(!toggleSeceFindingsStats)} checked={toggleSeceFindingsStats} />
                    <label htmlFor="SeceFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                  </div>
                  <div className="text-xs text-gray-900 inline">Level</div>
                </div>
              </div>

              {seceFindingsLoading && (
                <PageSpinner/>
              )}

              {seceFindingsError && (
                <span>Something went wrong retrieving the data</span>
              )}

              {!seceFindingsLoading && !seceFindingsError && toggleSeceFindingsStats === false && seceFindingsStats.date.datasets.length &&
                <ErrorBoundary>
                  <HorizontalBar
                    data={seceFindingsStats.date}
                    options={{
                      scales: {
                        xAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }],
                        yAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }]
                      },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let bar = this.getElementAtEvent(evt)[0];
                          let index = bar._index;
                          let datasetIndex = bar._datasetIndex;

                          const { date: { labels = [] } = {} } = seceFindingsStats ? seceFindingsStats : {};
                          let selectedSeceTitle = labels[index];

                          let statusKey = element[datasetIndex]._model.datasetLabel;
                          let seceName = element[datasetIndex]._model.label;

                          let searchObject = {
                            responseStatus: statusKey,
                            seceTitle: selectedSeceTitle,
                            findingStatus: "open",
                            displayName: `Findings By Status & SECE: ${statusKey} & ${seceName}`
                          }
                          let search = new URLSearchParams(searchObject).toString()
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: search
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary>
              }

              {!seceFindingsLoading && !seceFindingsError && toggleSeceFindingsStats === true  &&
                <ErrorBoundary>
                  <HorizontalBar
                    data={seceFindingsStats.level}
                    options={{
                      scales: {
                        xAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }],
                        yAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }]
                      },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let bar = this.getElementAtEvent(evt)[0];
                          let index = bar._index;
                          let datasetIndex = bar._datasetIndex;

                          const { level: {  labels = [] } = {} } = seceFindingsStats ? seceFindingsStats : {};
                          let selectedSeceTitle = labels[index];

                          let levelKey = element[datasetIndex]._model.datasetLabel;
                          let seceName = element[datasetIndex]._model.label;

                          let searchObject = {
                            level: levelKey,
                            seceTitle: selectedSeceTitle,
                            findingStatus: "open",
                            displayName: `Findings By Level & SECE: ${levelKey} & ${seceName}`
                          }

                          let search = new URLSearchParams(searchObject).toString()
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: search
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary> 
              }
            </div>
          </div>

        </div>

        <div className="my-4 bg-gray-100">

          <div className="flex flex-col h-full">

            <div className="p-4">

              <div className="flex flex-row justify-between">
                <h3 className="font-semibold text-blue-900 uppercase">Dutyholder &amp; Installation Findings</h3>

                <div>
                  <div className="text-xs text-gray-900 inline">Status</div>
                  <div className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in">
                    <input type="checkbox" className="toggle-checkbox-stats absolute block w-6 h-6 rounded-full bg-white border-4 border-blue-300 appearance-none cursor-pointer" id="DhInstFindings" name="DhInstFindings" onChange={() => setToggleDhInstFindings(!toggleDhInstFindings)} checked={toggleDhInstFindings} />
                    <label htmlFor="DhInstFindings" className="toggle-label block overflow-hidden h-6 rounded-full bg-blue-300 cursor-pointer"></label>
                  </div>
                  <div className="text-xs text-gray-900 inline">Level</div>
                </div>
              </div>

              {dhInstFindingsLoading && (
                <PageSpinner/>
              )}

              {dhInstFindingsError && (
                <span>Something went wrong retrieving the data</span>
              )}

              {!dhInstFindingsLoading && !dhInstFindingsError && toggleDhInstFindings === false && dhInstFindingsStats.date.datasets.length &&
                <ErrorBoundary>
                  <Bar
                    data={dhInstFindingsStats.date}
                    options={{
                      scales: {
                        xAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }],
                        yAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }]
                      },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let finding = this.getElementAtEvent(evt)[0];
                          //let index = finding._index;
                          let datasetIndex = finding._datasetIndex;

                          let statusKey = element[datasetIndex]._model.datasetLabel;
                          let findingTypeKey = element[datasetIndex]._model.label;

                          let searchObject = {
                            responseStatus: statusKey,
                            findingType: mapToFindingsTypeKey(findingTypeKey),
                            findingStatus: "open",
                            displayName: `${findingTypeKey} By Status: ${statusKey}`,
                          }
                          let search = new URLSearchParams(searchObject).toString()
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: search
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary>
              }

              {!dhInstFindingsLoading && !dhInstFindingsError && toggleDhInstFindings === true && dhInstFindingsStats.level.datasets.length &&
                <ErrorBoundary>
                  <Bar
                    data={dhInstFindingsStats.level}
                    options={{
                      scales: {
                        xAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }],
                        yAxes: [{
                          stacked: true,
                          ticks: {
                            fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                          },
                        }]
                      },
                      legend: {
                        align: 'start',
                        labels: {
                          fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                        }
                      },
                      onClick: function (evt, element) {
                        if (element.length > 0) {
                          let finding = this.getElementAtEvent(evt)[0];
                          //let index = bar._index;
                          let datasetIndex = finding._datasetIndex;

                          let statusKey = element[datasetIndex]._model.datasetLabel;
                          let findingTypeKey = element[datasetIndex]._model.label;

                          let searchObject = {
                            level: statusKey,
                            findingType: mapToFindingsTypeKey(findingTypeKey),
                            findingStatus: "open",
                            displayName: `${findingTypeKey} By Level: ${statusKey}`,
                          }
                          let search = new URLSearchParams(searchObject).toString()
                          let location = {
                            pathname: openFindingsStats.date.datasets[element[0]._datasetIndex].linkHref,
                            search: search
                          }
                          history.push(location);
                        }
                      }
                    }}
                  />
                </ErrorBoundary>
              }

            </div>

          </div>

        </div>

      </div>

    </div>
  )

}

export const filterFindingsByIssue = (findingsByIssueData) => {
  let newFindingsByIssues = [];

  if (findingsByIssueData && findingsByIssueData.length) {

    let exclusionList = [
      'Integrity Issue & Legislative Issue',
      'Integrity Issue & Paperwork / Process Issue',
      'Legislative Issue & Paperwork / Process Issue',
      'Integrity Issue & Legislative Issue & Paperwork / Process Issue'
    ]

    for (let index = 0; index < findingsByIssueData.length; index++) {
      const element = findingsByIssueData[index];

      let exist = exclusionList.includes(element.label);
      if (!exist) {
        newFindingsByIssues.push(element);
      }
    }
  }

  return newFindingsByIssues;
}

export const createDataForFindingsByIssueBarChart = (findingsByIssueData) =>{
  let defaultData = {
    labels: [
      'Integrity Issue', 
      'Legislative Issue', 
      'Paperwork / Process Issue',

      'Document Update Required', 
      'ROGI Findings',

      'Integrity Issue & Legislative Issue', 
      'Integrity Issue & Paperwork / Process Issue', 
      'Legislative Issue & Paperwork / Process Issue', 
      'Integrity Issue & Legislative Issue & Paperwork / Process Issue', 
    ],
    datasets: [{
      data: [12, 19, 3, 5, 2, 3, 5, 7, 10],
      backgroundColor: [
        '#002A4E',
        '#626366',
        '#5E8AB4',

        '#FFAD0A',
        '#DA1F33',

        '#DBDCDD',
        '#B1B3B5',
        '#898B8D',
        '#333333'
      ]
    }]
  }

  if (findingsByIssueData && findingsByIssueData.length) {
    let integrityIssue = findingsByIssueData.find(fnd => fnd.label === 'Integrity Issue');
    let legislativeIssue = findingsByIssueData.find(fnd => fnd.label === 'Legislative Issue');
    let paperworkProcessIssue = findingsByIssueData.find(fnd => fnd.label === 'Paperwork / Process Issue');
    let documentUpdateRequired = findingsByIssueData.find(fnd => fnd.label === 'Document Update Required');
    let rOGIFindings = findingsByIssueData.find(fnd => fnd.label === 'ROGI Findings');

    let intIssueAndLegIssue = findingsByIssueData.find(fnd => fnd.label === 'Integrity Issue & Legislative Issue');
    let intIssueAndPwIssue = findingsByIssueData.find(fnd => fnd.label === 'Integrity Issue & Paperwork / Process Issue');
    let legIssueAndPwIssue = findingsByIssueData.find(fnd => fnd.label === 'Legislative Issue & Paperwork / Process Issue');
    let intIssueAndlegIssueAndPwIssue = findingsByIssueData.find(fnd => fnd.label === 'Integrity Issue & Legislative Issue & Paperwork / Process Issue');

    let integrityIssueCount = integrityIssue ? integrityIssue.count : 0;
    let legislativeIssueCount = legislativeIssue ? legislativeIssue.count : 0;
    let paperworkProcessIssueCount = paperworkProcessIssue ? paperworkProcessIssue.count : 0;
    let documentUpdateRequiredCount = documentUpdateRequired ? documentUpdateRequired.count : 0;
    let rOGIFindingsCount = rOGIFindings ? rOGIFindings.count : 0;

    let intIssueAndLegIssueCount = intIssueAndLegIssue ? intIssueAndLegIssue.count : 0;
    let intIssueAndPwIssueCount = intIssueAndPwIssue ? intIssueAndPwIssue.count : 0;
    let legIssueAndPwIssueCount = legIssueAndPwIssue ? legIssueAndPwIssue.count : 0;
    let intIssueAndlegIssueAndPwIssueCount = intIssueAndlegIssueAndPwIssue ? intIssueAndlegIssueAndPwIssue.count : 0;

    let dataSets = [{
      data: [
        integrityIssueCount,
        legislativeIssueCount,
        paperworkProcessIssueCount,

        documentUpdateRequiredCount,
        rOGIFindingsCount,

        intIssueAndLegIssueCount,
        intIssueAndPwIssueCount,
        legIssueAndPwIssueCount,
        intIssueAndlegIssueAndPwIssueCount
      ],
      backgroundColor: [
        '#002A4E', 
        '#626366', 
        '#5E8AB4',
        
        '#FFAD0A', 
        '#DA1F33',

        '#DBDCDD', 
        '#B1B3B5', 
        '#898B8D', 
        '#333333'
      ]
    }]
    defaultData = { ...defaultData, datasets: dataSets };
  }

  return defaultData;
}

function  createVennSets(fndByIssueList) {
  let vennSet = [];

  if (fndByIssueList && fndByIssueList.length > 0) {
    let keyList = ["A", "B", "C", "D", "E"];

    let issueList = fndByIssueList;
    let countTotal = 0;
    issueList.map(fnd => countTotal += fnd.count);
    let tempSetList = [];

    issueList.forEach((fnd, index) => {
      let count = fnd.count ? fnd.count : 0;
      let scaledCount = 0;

      try {
        let result = (count / countTotal) * 10;
        let formatedScaledCount = parseFloat(parseFloat(result).toFixed(2));
        scaledCount = formatedScaledCount;
      } catch (error) {
        scaledCount = 0;
      }

      let keyItem = keyList[index];
      let label = fnd.label && fnd.label !== "" ? fnd.label : "";
      let link = fnd.linkHref && fnd.linkHref !== "" ? `/${fnd.linkHref}` : "/";

      let setItem = {
        sets: [keyItem],
        size: scaledCount,
        label: label,
        link: link
      }
      tempSetList.push(setItem);
    })

    let intesections = [
      { sets: ['A', 'B'], size: .4 },
      { sets: ['A', 'C'], size: .4 },
      { sets: ['B', 'C'], size: .4 }
    ];

    vennSet = [...tempSetList, ...intesections];
  }
  return vennSet;
}

function mapToFindingsTypeKey(label) {
  let key = ""
  if (label && label !== "") {
    switch (label) {
      case "Dutyholder Findings":
        key = DH_FINDING_TYPE;
        break;
      case "Installation Findings":
        key = INST_FINDING_TYPE;
        break;
      default:
        break;
    }
  }

  return key;
}

export default Statistics;
