import React from 'react';
import {Route} from "react-router-dom";

import {VerificationContextProvider} from '../../context/verificationContext';

import Verification from './Verification';
import AddEditVerificationScheduleStep1 from './addEditVerificationScheduleStep1';
import AddEditVerificationScheduleStep2 from './addEditVerificationScheduleStep2';
import AddEditVerificationScheduleStep3 from './addEditVerificationScheduleStep3';
import ActivityRegister from './activityRegister';

function VerificationIndex(props) {
    const {match: {url} } = props;

    return (
        <React.Fragment>
            <VerificationContextProvider>
                <Route exact path={`${url}/`} component={Verification} /> 
                <Route exact path={`${url}/:verId/activity-register`} component={ActivityRegister} /> 
                <Route exact path={`${url}/:formMode/step1/:vsId`} component={AddEditVerificationScheduleStep1} />
                <Route exact path={`${url}/:formMode/step1`} component={AddEditVerificationScheduleStep1} />
                <Route exact path={`${url}/:formMode/step2/:vsId`} component={AddEditVerificationScheduleStep2} />
                <Route exact path={`${url}/:formMode/step2`} component={AddEditVerificationScheduleStep2} />
                <Route exact path={`${url}/:formMode/step3/:vsId`} component={AddEditVerificationScheduleStep3} />
                <Route exact path={`${url}/:formMode/step3`} component={AddEditVerificationScheduleStep3} />
            </VerificationContextProvider>
        </React.Fragment>
    );
}

export default VerificationIndex;
