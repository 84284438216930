/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useState, useEffect} from 'react';
import Table from '../global/Table';
import { Link } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from '../global/pageSpinner';
import InfoModal from '../global/infoModal';
import {AuthContext} from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import {capitaliseFirstCase} from "../../utils/index";
import {createUserTypeAndRoleObject} from "../../utils/index";
import Comment from '../global/Comment';
import {NOTIFICATION_STATUS_UNAPPROVED, NOTIFICATION_STATUS_PENDING} from "../../constants";
import {sysObjects} from "../../constants/sysObjectList";

const INFO_MATRIX_QUERY = gql`
query InfoMatrixes($param: InfoMatrixSearch!){
  infoMatrixes(param: $param){
    _id
    dh_id{
      _id
      dh_name
    }
    installation_id{
      _id
      installation_title
    }
    im_stage1
    im_stage2
    im_stage3
    im_stage4
    im_stage5
    im_rogi
    im_status
    im_archived
    im_archived_id
    im_archived_date
    }
}`;

const ARCHIVE_INFO_MATRIX = gql`
    mutation ArchiveInfoMatrix($imId: String!, $userId: String!) {
      archiveInfoMatrix(_id: $imId, userId: $userId) {
            _id
        }
}`;

const UN_ARCHIVE_INFO_MATRIX = gql`
    mutation UnArchiveInfoMatrix($imId: String!, $userId: String!, $instId: String) {
      unArchiveInfoMatrix(_id: $imId, userId: $userId, instId: $instId) {
            _id
            success
      }
}`;

function InfoMatrix(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [showArchivedInfoMatrixes, setShowArchivedInfoMatrixes] = useState(false);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const { location: { state = "" }, match: { url = "" } } = props ? props : {};

  const [drDnDutyholder, setDrDnDutyholder] = useState({});
  const [drDnInstallation, setDrDnInstallation] = useState({});
  const [drDnSece, setDrDnSece] = useState({});

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);

  const [infoMatrixes, {loading, error, data: imData, refetch}] = useLazyQuery(INFO_MATRIX_QUERY);
  const [archiveInfoMatrix, {data: archiveImData}] = useMutation(ARCHIVE_INFO_MATRIX);
  const [unArchiveInfoMatrix, {data: unArchiveImData}] = useMutation(UN_ARCHIVE_INFO_MATRIX);

  useEffect(() => {
    if (state === "archived") {
      setShowArchivedInfoMatrixes(true);
    }

    const payload = {
      dh_id: drDnDutyholder.id ? drDnDutyholder.id : "",
      installation_id: drDnInstallation.id ? drDnInstallation.id : "",
    };

    infoMatrixes({ variables: {param: {...payload}}, errorPolicy: 'all' });
  }, []);

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    const { dutyHolder, installation, sece } = navContext;
    setDrDnDutyholder(dutyHolder);
    setDrDnInstallation(installation);
    setDrDnSece(sece);
  }, [navContext]);

  useEffect(() => {
    const payload = {
      dh_id: drDnDutyholder.id ? drDnDutyholder.id : "",
      installation_id: drDnInstallation.id ? drDnInstallation.id : "",
    };

    infoMatrixes({ variables: {param: {...payload}}, errorPolicy: 'all' });
  }, [showArchivedInfoMatrixes]);

  useEffect(() => {
    const payload = {
      dh_id: drDnDutyholder.id ? drDnDutyholder.id : "",
      installation_id: drDnInstallation.id ? drDnInstallation.id : "",
    };

    infoMatrixes({ variables: {param: {...payload}}, errorPolicy: 'all' });
  }, [drDnDutyholder, drDnInstallation, drDnSece]);

  useEffect(() => {
    if (archiveImData && archiveImData.archiveInfoMatrix) {
      refetch();
    }
  }, [archiveImData]);

  useEffect(() => {
    if (unArchiveImData && unArchiveImData.unArchiveInfoMatrix) {
      const { success } = unArchiveImData.unArchiveInfoMatrix;
      if (!success) {
        toggleModal()
      }
      refetch();
    }
  }, [unArchiveImData]);

  useEffect(() => {
    if (imData && imData.infoMatrixes) {
      const {_id: userId = ""} = authContext.user ? authContext.user : {};
      let infoMatrixList = formatInfoMatrixData(imData.infoMatrixes, userTypeAndRoleObject, userId, showArchivedInfoMatrixes);
      setDataList(infoMatrixList);
    }
  }, [imData]);
  
  const archiveManager = (imId, isArchived, userId, installationId) => {
    if (isArchived) {
      unArchiveInfoMatrix({ variables: { imId: imId, userId: userId, instId: installationId } });
    } else {
      archiveInfoMatrix({ variables: { imId: imId, userId: userId } });
    }
  }

  const toggleModal = () => {
    const modal = document.querySelector('.modal')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Reference',
        id: 'DH',
        columns: [
          {
            Header: 'Duty Holder',
            accessor: 'dh_name',
          },
          {
            Header: 'Installation',
            accessor: 'installation_title',
          },
          {
            Header: 'Status',
            accessor: 'formated_status',
          },
        ],
      },
      {
        Header: <div><span className="mr-2">SCiS Level 1</span>
        <InfoModal
          theme="dark"
        >
          <h3 className="mb-2 text-xl text-blue-900 font-bold">Level 1</h3>
          <p className="font-normal">Performance Standard satisfied, but Verifier may suggest an improvement to the system or may request additional information to demonstrate compliance with a Performance Standard.</p>
        </InfoModal></div>,
        id: 'LVL1',
        columns: [
          {
            Header: 'Stage 1',
            accessor: 'im_stage1',
            style : {whiteSpace: "pre-wrap"}
          }
        ],
      },
      {
        Header: <div><span className="mr-2">SCiS Level 2</span>
        <InfoModal
          theme="dark"
        >
          <h3 className="mb-2 text-xl text-blue-900 font-bold">Level 2</h3>
          <p className="font-normal">Single Performance Standard failure with no significant threat to the installation.</p>
        </InfoModal></div>,
        id: 'LVL2',
        columns: [
          {
            Header: 'Stage 2',
            accessor: 'im_stage2',
            style : {whiteSpace: "pre-wrap"}
          }
        ],
      },
      {
        Header: <div><span className="mr-2">SCiS Level 3</span>
        <InfoModal
          theme="dark"
        >
          <h3 className="mb-2 text-xl text-blue-900 font-bold">Level 3</h3>
          <p className="font-normal">Fundamental weakness of the SECE Assurance system that involves multiple failures of a Performance Standard (s); or presents a significant threat to the integrity of the installation</p>
        </InfoModal></div>,
        id: 'LVL3',
        columns: [
          {
            Header: 'Stage 3',
            accessor: 'im_stage3',
            style : {whiteSpace: "pre-wrap"}
          }
        ],
      },
      {
        Header: <div><span className="mr-2">SCiS LoR</span>
        <InfoModal
          theme="dark"
        >
          <h3 className="mb-2 text-xl text-blue-900 font-bold">Letter of Reservation</h3>
          <p className="font-normal">A letter of reservation will be issued by the Verifier when there is a Regulatory compliance concern with regards to the 'Verification scheme' that is required to be brought to the attention of the duty holder's senior management. Examples include poor identification of SECEs, inadequate Performance Standards, Assurance processes or Verification activities (nature and frequency).</p>
        </InfoModal></div>,
        id: 'LVL4',
        columns: [
          {
            Header: 'Stage 4',
            accessor: 'im_stage4',
            style : {whiteSpace: "pre-wrap"}
          }
        ],
      },
      {
        Header: <div><span className="mr-2">SCiS LoC</span>
        <InfoModal
          theme="dark"
        >
          <h3 className="mb-2 text-xl text-blue-900 font-bold">Letter of Concern</h3>
          <p className="font-normal">A letter of concern will be issued by the Verifier when there is a fundamental concern with regards to the 'management of Verification' that is required to be brought to the attention of the duty holder's senior management. Examples include mismanagement for findings, the cumulative effect of open or overdue findings, failing to assist or provide access to allow the Verifier to complete the scheme activity requirements.</p>
        </InfoModal></div>,
        id: 'LOC',
        columns: [
          {
            Header: 'Stage 5',
            accessor: 'im_stage5',
            style : {whiteSpace: "pre-wrap"}
          }
        ],
      },
      {
        Header: <div><span className="mr-2">ROGI</span>
        <InfoModal
          theme="dark"
        >
          <h3 className="mb-2 text-xl text-blue-900 font-bold">ROGI</h3>
          <p className="font-normal">Note that certain findings may be reportable by the duty holder to the Competent Authority under Implementing Regulation No 1112/2014. Refer to ROGI.</p>
        </InfoModal></div>,
        id: 'ROG',
        columns: [
          {
            Header: 'ROGI',
            accessor: 'im_rogi',
            style : {whiteSpace: "pre-wrap"}
          }
        ],
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { id, im_archived, installation_id, im_status, user_id, userTypeAndRoleObject } }) => (          
          <div className="text-right">

            {(im_status === NOTIFICATION_STATUS_UNAPPROVED || im_status === NOTIFICATION_STATUS_PENDING)
              ?
              <></>
              :
              <>
                {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/info-matrix/edit/${id}`,
                      state: { formMode: 'edit' }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    Edit
                  </Link>

                  <Link
                    to="#"
                    className="button-red mb-1 mr-1 text-xs"
                    onClick={() => {
                      archiveManager(id, im_archived, user_id, installation_id)
                    }}
                  >
                    {im_archived ? 'Unarchive' : 'Archive'}
                  </Link>
                  </div>
                }
              </>
            }

            <Comment sysObjName={sysObjects.infoMatrix}
              sysObjRecordId={id}
              buttonName={"Comments"}
              buttonClassName={"button-red mb-1 mr-1 text-xs"} />
          </div>
        )
      },
    ],
    []
  )
  
  if (loading) {
    return (
      <PageSpinner/>
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  } 

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Info Matrix <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3">For each Installation, the Duty Holder's Finding levels are presented against the Step Change in Safety (industry standard) Finding level definitions.</p>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      
      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "ABS" && 
        drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Info Matrix - Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "Duty Holder" && 
        drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Info Matrix - Installation: {drDnInstallation.name}</h6>}
      {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Verification - Duty Holder / Installation : All</h6>}

      {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
        <Link to={{
          pathname: "/info-matrix/add",
          state: { formMode: 'add' }
        }}
          className="button-red mr-2">Add Info Matrix
        </Link>
      }

      <Link to={{
        pathname: showArchivedInfoMatrixes ? url : (url + "/archived"),
        state: showArchivedInfoMatrixes ? "" : "archived"
      }}
        className="button-red"
        onClick={() => { setShowArchivedInfoMatrixes(!showArchivedInfoMatrixes) }}
        style={{ backgroundColor: showArchivedInfoMatrixes ? '#a70000' : '' }}
      >
        {showArchivedInfoMatrixes ? "Active Info Matrixes" : "Archived Info Matrixes"}
      </Link>

      <Table
        columns={columns}
        data={dataList}
      />

      <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-10">
        <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-30" onClick={() => toggleModal()}></div>
        <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-40" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

          <div className="mb-4 pb-2 border-b-2 border-gray-200">
            Message
          </div>
          <p className="mb-4"> An Info Matrix with the same installation is already active.</p>
          <button className="mt-3 mb-4 button-red capitalize" onClick={() => toggleModal()}>Close</button>
        </div>
      </div>

    </div>
  )
}

function formatInfoMatrixData(list, userTypeAndRoleObject, userId, showArchivedInfoMatrixes) {
  let newInfoMatrixList = [];
  if (list && list.length) {
    let tempInfoMatrixList = list.filter(im => showArchivedInfoMatrixes ? im.im_archived === true : im.im_archived !== true);
    tempInfoMatrixList.forEach(info => {

      let status = info.im_status ? info.im_status : "unknown";
      let formatedStatus = capitaliseFirstCase(status);

      let newInfoMatrix = {
        id: info._id,
        dh_name: info.dh_id ? info.dh_id.dh_name : "N/A",
        installation_id: info.installation_id ? info.installation_id._id : "",
        installation_title: info.installation_id ? info.installation_id.installation_title : "N/A",
        im_stage1: formatStage(info.im_stage1),
        im_stage2: formatStage(info.im_stage2),
        im_stage3: formatStage(info.im_stage3),
        im_stage4: formatStage(info.im_stage4),
        im_stage5: formatStage(info.im_stage5 ? info.im_stage5 : "N/A"),
        im_rogi: formatStage(info.im_rogi ? info.im_rogi : "N/A"),
        im_status: info.im_status ? info.im_status : "N/A",
        im_archived: info.im_archived,
        im_archived_id: info.im_archived_id,
        im_archived_date: info.im_archived_date,
        user_id: userId,
        formated_status: formatedStatus,
        userTypeAndRoleObject: userTypeAndRoleObject
      }
      newInfoMatrixList.push(newInfoMatrix);
    })
  }
  return newInfoMatrixList;
}

function formatStage(stage) {
  let newStageList = "";

  if (stage && stage.length) {
    let stageList = stage.split(" ");
    let numberOfStages = stageList.length;

    if (numberOfStages > 1) {
      let newStages = "";
      stageList.forEach((stage, index) => {
        newStages += stage + "\n";
      })
      newStageList = newStages;
    } else {
      let stage = stageList[0]
      newStageList = stage ? stage : "";
    }

  }
  return newStageList;
}

export default InfoMatrix;